// PL
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "Jestem już zarejestrowany",
    ACCEDICOMM2 : "Kliknij tutaj i zaloguj się na swoje konto",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    REGISTRATICOMM1 : "Chcę być częścią społeczności",
    REGISTRATICOMM2 : "Kliknij tutaj, jeśli chcesz zarejestrować się na BiPart za pomocą swojego adresu e-mail i utworzyć profil.",
    SOLOVOTO : "Chcę tylko zagłosować",
    SOLOVOTO2 : "Kliknij tutaj, jeśli chcesz się zarejestrować w BiPart jedynie za pomocą telefonu komórkowego"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "Attention",
    INDIETRO : "Wróć",
    INSERISCINUOVONUMERO : "Insert<br /> a new number",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    PH1 : "Telefon komórkowy",
    PHONEERROR1 : "Zapomniałeś podać kod pocztowy",
    PHONEERROR2 : "Zapomniałeś wpisać swój numer telefonu komórkowego?",
    PHONEERROR3 : "Numer, który wpisałeś jest trochę dziwny... sprawdź to! :)",
    PRIVACY : "Zapoznałem się i akceptuję <a href=\"https://bipart.it/privacy\" target=\"_blank\">politykę prywatności</a>.",
    PROCEDI : "Zarejestruj się",
    RECUPERAPASSWORD : "Retrieve<br/> password",
    TERMINIECONDIZIONI : "Zapoznałem się i zgadzam się z <a href=\"https://bipart.it/terms\" target=\"_blank\">zasadami i warunkami</a>.",
    VERIFICA1 : "Sprawdź swoje dane, aby wziąć udział",
    VERIFICA2 : "Wpisz swój telefon komórkowy, aby otrzymać kod OPT przez SMS",
    EXIT: "Logout",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "<h4>Wysłaliśmy kod weryfikacyjny SMS na Twój telefon komórkowy.</h4>.",
    ALERT1TITLE : "Info",
    ERROROTPMAXCHAR : "Maximum number of digits allowed: 6",
    INDIETRO : "Wróć",
    OTPDIMENTICATO : "Zapomniałeś wpisać jednorazowe hasło",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    PH1 : "Kod uwierzytelniający",
    PROCEDI : "Proceduj",
    RICHIEDINUOVOCODICE1 : "<b>Did you not receive the code?</b> <br>Go back and make sure you have correctly entered your mobile number. Otherwise,",
    RICHIEDINUOVOCODICE2 : "request a new code",
    RICHIEDINUOVOCODICE3 : "or write to",
    VERIFICA1 : "Sprawdź swoje dane, aby wziąć udział",
    VERIFICA2 : "Wysłaliśmy kod uwierzytelniający na Twój numer telefonu komórkowego. Wprowadź go, aby kontynuować"
  },
  ADVAUTHROLE : {
    CITTADINO : "Obywatel",
    INDIETRO : "Wróć",
    INFOREGISTER : "Oprócz Twojego <b>telefonu komórkowego</b>, na który otrzymasz hasło do logowania, poprosimy Cię o podanie Twojego <b>NIP-u</b>.",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    PROCEDI : "Dalej",
    VERIFICA1 : "Sprawdź swoje dane, aby wziąć udział",
    VERIFICA2 : "Wybierz sposób uwierzytelniania"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "Ups... coś poszło nie tak; kod statusu:",
    ALERT1TITLE : "Błąd",
    CFDIVERSO : "Czy wprowadziłeś NIP inny niż ten, który jest już przypisany do Twojego konta?",
    CHIUDI : "Zamknij",
    DIMENTICATOCF : "Zapomniałeś wpisać swój kod podatkowy",
    ERRORECF : "Kod podatkowy nie został wprowadzony poprawnie.",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    PH1 : "Kod podatkowy*",
    PROCEDI : "Następny",
    VERIFICA1 : "Sprawdź swoje dane, aby wziąć udział",
    VERIFICA2 : "<b>Wpisz swój NUMER PODATKOWY, aby kontynuować</b><br>Dbamy o to, aby tylko członkowie społeczności brali udział w konkursie!"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "Z tym NIP-em nie możesz wziąć udziału w procesie",
    CFNONABILIATO1 : "Sprawdź zasady procesu, zaś jeśli dostrzegasz błąd, to napisz na adres support@bipart.it",
    CHIUDI : "Zamknij",
    UTENTENONAUTORIZZATO : "Nieuprawniony użytkownik"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "Dziękujemy za rejestrację w serwisie BiPart.",
    CHIUDI : "Zamknij",
    COMPLIMENTI : "Gratulacje*!",
    UTENTEABILITATO : "Zachowaj przy sobie dane uwierzytelniające wysłane SMS-em, aby ponownie uzyskać dostęp do BiPart!",
    UTENTEINFO : "Konto zweryfikowane - możesz już korzystać z najbardziej zaawansowanych i ważnych funkcji!"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "Uwaga",
    ALERTINFO : "Nie wprowadziłeś wymaganych danych. Proszę spróbować ponownie lub napisać do <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    PARTECIPA : "Dołącz do procesu",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    PASSWORDERRATA : "Hasło nieprawidłowe",
    PH1 : "nazwa użytkownika*",
    PH2 : "hasło*",
    SUPPORT : "<i class=\"fas fa-info-circle\"></i> Jeśli nie pamiętasz swoich danych uwierzytelniających, napisz do <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    USERNONESISTE : "Nazwa użytkownika nie istnieje"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "Nieprawidłowe dane uwierzytelniające",
    ALERT1TITLE : "Uwaga",
    CAMPIOBBLIGATORI : "Pola oznaczone gwiazdką (*) są wymagane.",
    COGNOMEDIMENTICATO : "Zapomniałeś wpisać swoje nazwisko.",
    INDIETRO : "WSTECZ",
    MAILDIMENTICATA : "Zapomniałeś wpisać swój e-mail",
    MAILNONVALIDA : "Nieprawidłowy adres e-mail.",
    NEWSLETTER : "Informuj mnie na bieżąco o inicjatywach BiPart",
    NOMEDIMENTICATO : "Zapomniałeś wpisać swoje imię",
    PARTECIPA : "Dołącz do procesu",
    PARTECIPAPROCESSO : "Znajdujesz się w obszarze roboczym następującej organizacji:",
    PASSWORDDIMENTICATA : "Zapomniałeś wpisać hasło",
    PH1 : "Imię",
    PH2 : "Nazwisko*",
    PH3 : "Nazwa użytkownika*",
    PH4 : "Email",
    PH5 : "Hasło*",
    PRIVACY : "Zapoznałem się i akceptuję <a href=\"https://bipart.it/privacy\" target=\"_blank\">politykę prywatności</a>.",
    REGISTRA : "ZAREJESTRUJ SIĘ",
    TERMINIECONDIZIONI : "Zapoznałem się i zgadzam się z <a href=\"https://bipart.it/terms\" target=\"_blank\">zasadami i warunkami</a>.",
    USERNAMEDIMENTICATO : "Zapomniałeś wpisać swoją nazwę użytkownika"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "<h4>Proposal nie może być obserwowany</h4>",
    ALERTCOMMENTEMPTY : "<h4>Wstaw tekst komentarza</h4>",
    ALERTCOMMENTSENDED : "<h4>Dziękujemy za wpisanie komentarza i Twój wkład w proposal!</h4>",
    ALERTEMAILBLANK : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't your email address yet</h6><div>",
    ALERTEMAILCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Nie potwierdziłeś jeszcze swojego adresu e-mail</h6><div>Potwierdź go, aby dołączyć do organizacji i zapisać się do procesów partycypacyjnych</div><div>Nie otrzymałeś e-maila? <br><strong>Kliknij przycisk w czerwonej ramce, aby go ponownie wysłać</strong></div>.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "Stwórz i ujawnij swój profil, by wziąć udział w działaniach swojej społeczności.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "Witatmy w BiPart!",
    ALERTEMAILMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the community and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    ALERTEMAILRESENDCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Email z prośbą o potwierdzenie odesłany na Twoją skrzynkę</h5><div>Potwierdź go, aby dołączyć do organizacji<br>i zapisać się do procesów partycypacyjnych</div>.",
    ALERTERROR : "Ups, coś poszło nie tak!",
    ALERTFORMDESCRIPTION : "<h4>Oczekuje na aktywację</h4>.",
    ALERTFORMTITLE : "<b>Kwestionariusz nie jest jeszcze dostępny</b>",
    ALERTHELPDESCRIPTION : "<h4>Jeśli potrzebujesz pomocy, skontaktuj się z nami pod adresem <a href=\"mailto:support@bipart.it\">support@bipart.it</a></h4>.",
    ALERTHELPTITLE : "<b>Helpdesk</b>",
    ALERTJAMBOARDDESCRIPTION : "<h4>Oczekuje na aktywację</h4>.",
    ALERTJAMBOARDTITLE : "<b>Jamboard nie jest jeszcze dostępny</b>",
    ALERTMEETDESCRIPTION : "<h4>Oczekuje na aktywację</h4>.",
    ALERTMEETTITLE : "<b>Webinar jest jeszcze niedostępny</b>",
    ALERTMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the community and sign up for participatory processes</div><div>You didn't receive the message? <br><strong>Click on the button in the red band to resend it</strong></div>",
    ALERTPERMISSIONDENIED : "<h4>Odmowa dostępu</h4>.",
    ALERTUSERMODULENOTENABLED : "<h4>Cześć! Być może nie masz odpowiednich uprawnień do udziału. Przeczytaj regulamin lub napisz do <a href=\"mailto:support@bipart.it\">support@bipart.it</a> lub skontaktuj się z innymi dostępnymi kontaktami.</h4>.",
    ALERTUSERMODULENOTENABLEDBITMASK : "click <a href=\"/process-guidelines/{process_id}\" target=\"_blank\">here</a> to find out how you can participate, or",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "<h4>Ops,<br>apparently you don't have permission to leave a comment;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "<h4>Ops,<br>apparently you don't have permission to make {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "<h4>Ops,<br>apparently you don't have permission to delete {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "<h4>Ops,<br>apparently do not follow this proposal;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "<h4>Ops,<br>apparently you don't have permission to send a message;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "<h4>Ops,<br>apparently you don't have permission to edit {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "<h4>Ops,<br>apparently you don't have permission to version {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERREGISTEREDTOORGANIZATION : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Dziękujemy za dołączenie do naszej społeczności</h5><div>Wysłaliśmy wiadomość e-mail na Twoją skrzynkę.<br>Potwierdź ją, aby dołączyć do organizacji</div><div>Uwaga! Sprawdź również swój folder spamu.</div>.",
    ALERTUSERREGISTEREDTOPROCESS : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Dziękujemy za dołączenie do naszej społeczności</h5><div>Wysłaliśmy wiadomość e-mail na Twoją skrzynkę.<br>Potwierdź ją, aby dołączyć do organizacji</div><div>Uwaga! Sprawdź również swój folder spamu.</div>.",
    PROPOSAL : "a proposal",
    SERVICE : "a service",
    STORY : "a story",
    TACTICS : "a tactic"
  },
  AUTHORITIES : {
    ASSESSOR : "Oceniający",
    EXPERT : "Ekspert"
  },
  BCPROCESSI : {
    CHALLENGE : "Tematy",
    COMMUNITY : "Społeczność",
    ELENCOPROCESSI : "Lista procesów",
    PROCESSO : "Proces",
    SCENARI : "Scenariusze"
  },
  COMMUNITY : {
    CERCA : "Szukaj...",
    CHIPARTECIPA : "Uczestnicy",
    GRUPPI : "Grupy",
    ISCRITTI : "Subskrybujący",
    PROFILI : "Profile",
    SOTTOTITOLO : "Zobacz, kto jeszcze utworzył swój profil: stwórz własny!",
    TITOLO : "Społeczność"
  },
  COMMUNITYSPACE : {
    BOZZA : "Projekt",
    CS : "Przestrzeń wspólna",
    DESCRIZIONEIDEA : "Opis proposala",
    IDEE : "20 proposali",
    NOMEAUTORE : "Imię autora",
    TITOLOIDEA : "Tytuł proposala",
    VISUALIZZAELENCO : "Wyświetl listę",
    VISUALIZZAMAPPA : "Zobacz mapę"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "Czy na pewno nie chcesz, aby ten proposal został przetłumaczony?",
    ALERT1TITLE : "Uwaga",
    ALERT2 : "Czy chcesz potwierdzić ukończenie tłumaczenia:",
    CANCEL : "ANULUJ"
  },
  COOKIE : {
    ANONIMI : "3. Anonymous analytics cookies:",
    ANONIMIDESCR1 : "This site uses Google Analytics cookies with anonymized IP addresses lasting for one year. They are considered anonymous as they cannot be used to identify specific individuals. Specifically, the site nowhow.it uses Google Analytics (Google, Inc.), which generates cookies according to the methods outlined in the document regarding General Privacy Policies and the specific document regarding the use of information collected with Google Analytics. Google will not associate your IP address with any other data owned by Google. The terms of service that all program clients must adhere to prohibit the monitoring or collection of personal information (personal identification data, such as name, email address, billing information, or other data reasonably linked to such information) through the use of Google Analytics or the association of such information with web data analysis information. These cookies serve exclusively for statistical purposes and collect information in aggregate form only.",
    COLLEGAMENTI : "6. Useful links",
    COLLEGAMENTIDESCR1 : "For more information about cookies and their use on the internet, you may find the following links helpful:<br><br>www.allaboutcookies.org<br>www.microsoft.com/info/cookies.mspx<br>www.google.com/intl/it_it/analytics/privacyoverview.html",
    CONTENUTIESTERNI : "O4. Displaying content from external platforms",
    CONTENUTIESTERNIDESCR1 : "These services allow the display of content hosted on external platforms directly from the pages of this Application and interaction with them. In case these services are installed, even if Users do not use the service, it may still collect traffic data related to the pages where it is installed. For more information on the logic and methods of processing data collected by social networks, you can read the privacy disclosures provided by the entities that provide the services:<br><br>Youtube Video Widget (Google Inc.) - Terms - Privacy<br>Facebook - Terms and Conditions - Privacy<br>Twitter - Terms and Conditions - Privacy<br>Vimeo - Terms and Conditions - Privacy<br>Slideshare - Terms and Conditions - Privacy<br>Linkedin - Terms and Conditions - Privacy<br>Instagram - Terms and Conditions - Privacy<br><br>Remember that you can manage your cookie preferences through the browser. If you do not know the type and version of the browser you are using, click on \"Help\" in the browser window above, where you can access all the necessary information.<br><br>If you know your browser, click on the one you are using to access the cookie management page: Google Chrome, Mozilla Firefox, Apple Safari, and Microsoft Windows Explorer.",
    COSASONO : "1. What are cookies",
    COSASONODESCR1 : "Cookies are small text files that websites visited by users send to their terminals, where they are stored to be transmitted to the same sites on subsequent visits. Cookies are used for various purposes, have different characteristics, and can be used by both the owner of the site being visited and third parties. The Site may contain links to other websites that have their own privacy policy, which may differ from that adopted by nowhow.it and is not responsible for these sites. Below, you will find all the information about the cookies installed through this site, and the necessary indications on how to manage your preferences regarding them.",
    DATIRACCOLTI : "O4. Personal data collected: Cookies and Usage Data.O",
    DATIRACCOLTIDESCR1 : "All technical cookies do not require consent, so they are automatically installed following access to the site.",
    SOTTOTITOLO : "Cookie management",
    TITOLO : "Cookie Policy",
    UTILIZZATI : "2. Cookies used by this site",
    UTILIZZATIDESCR1 : "Cookies related to activities strictly necessary for the operation of the site and the provision of the service. This site uses the PHPSESSIONID session cookie generated by applications based on the PHP language. This identifier is used to maintain user session variables."
  },
  DRIVERS : {
    ACTIVEPROCESSES : "W trakcie",
    CHALLENGE : "Tematy",
    CLOSEPROCESSES : "Zakończone",
    CLUSTER : "Klaster",
    DESCRIZIONE : "Opis",
    ELENCOCHALLENGE : "Lista wyzwań",
    LINK : "Link",
    PROCESSES : "Procesy",
    TUTTITEMI : "Wszystkie tematy w podziale na klastry"
  },
  EMAILCONFIRM : {
    AUTOK : "Uwierzytelnienie pomyślne",
    AUTOKDETAIL : "Możesz się teraz zalogować i uczestniczyć w naszej społeczności",
    BLOGIN : "Login",
    TITOLO : "BiPart"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "Aby aktywnie włączyć się w poprawę funkcjonowania swojej gminy możesz zaproponować swój pomysł lub zagłosować na ten, który uznasz za najciekawszy. Proponowane pomysły muszą być konkretne.",
    DETTAGLIOGLOSSARIO : "",
    FAQ : "FAQ",
    FAQ1 : "",
    FAQ10 : "",
    FAQ11 : "",
    FAQ12 : "",
    FAQ13 : "",
    FAQ14 : "",
    FAQ15 : "",
    FAQ16 : "",
    FAQ17 : "",
    FAQ18 : "",
    FAQ19 : "",
    FAQ2 : "",
    FAQ20 : "",
    FAQ21 : "",
    FAQ22 : "",
    FAQ23 : "",
    FAQ24 : "",
    FAQ25 : "",
    FAQ3 : "",
    FAQ4 : "",
    FAQ5 : "",
    FAQ6 : "",
    FAQ7 : "",
    FAQ8 : "",
    FAQ9 : "",
    GLOSSARIO : "Słowniczek",
    PERCHEPARTECIPARE : "Dlaczego warto dołączyć do budżetu partycypacyjnego?",
    QUALITEMIPROGETTI : "",
    R1 : "",
    R10 : "",
    R11 : "",
    R12 : "",
    R13 : "",
    R14 : "",
    R15 : "",
    R16 : "",
    R17 : "",
    R18 : "",
    R19 : "",
    R2 : "",
    R20 : "",
    R21 : "",
    R22 : "",
    R23 : "",
    R24 : "",
    R25 : "",
    R3 : "",
    R4 : "",
    R5 : "",
    R6 : "",
    R7 : "",
    R8 : "",
    R9 : "",
    TEMI : ""
  },
  GENERALLABEL : {
    AL : "do",
    ALTERMINEDELLAFASE : "na końcu fazy",
    DAL : "od",
    FASE : "faza",
    MANCANOANCORA : "Wciąż brakuje",
    TERMINAIL : "Do"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "Przeczytałem i wyrażam zgodę",
    ACCETTOPROCESSOLINEEGUIDA2 : "Wytyczne i zasady procesu partycypacyjnego"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "Lista grup",
    F : "Kobieta",
    GRUPPO : "Grupa",
    M : "Mężczyzna",
    MEMBRI : "Członkowie",
    PUBBLICA : "Zamieść swój profil i przedstaw się społeczności!"
  },
  GROUPLIST : {
    COMMUNITY : "Społeczność",
    DDC : "Data utworzenia",
    DUE : "Dwa",
    GRUPPI : "Grupy",
    TRE : "Trzy",
    UNO : "Jeden"
  },
  HOME : {
    ALERT1 : "<h4>Sprawdź sekcję <a href=\"/how-it-works\">how it works</a>, aby dowiedzieć się, czym jest budżet partycypacyjny</h4>.",
    ALERT1TITLE : "<b>Zanim zaczniemy...</b>.",
    ALERT2 : "<h4>Czy coś nie działa, czy masz jakiś techniczny problem, czy po prostu chcesz się przywitać? :) isz do nas teraz na <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ASSISTENZA : "Pomoc",
    DATIGRAFICI : "Dane organizacji",
    GETSTARTED : "Rozpocznij",
    GRUPPICOM : "Grupy i Społeczności",
    OBIETTIVI : "Cele i wyzwania",
    ORGANIZZAZIONE : "Organizacja",
    ORGANIZZAZIONI : "Organizacje",
    PROCESSIATTIVI : "Procesy w trakcie",
    PROFILO : "Profil",
    PROFILODETT : "Tutaj możesz zarządzać profilem i znajdziesz aktywności",
    SCENARI : "Scenariusze",
    UTENTIREG : "Zarejestrowani użytkownicy",
    VOTANTI : "Głosujący",
    VOTIRACCOLTI : "Zebrane głosy"
  },
  IDEADETAIL : {
    ALERT1 : "Wypełnij przynajmniej jedno z obowiązkowych pól pomiędzy statusem lub punktacją przypisaną proposalowi.",
    ALERT10TITLE : "Nie usuwaj",
    ALERT11 : "Po usunięciu nie można go już odzyskać.",
    ALERT11TITLE : "Czy na pewno chcesz usunąć ten komentarz?",
    ALERT12TITLE : "Komentarz nie mógł zostać usunięty",
    ALERT13 : "Szczegółowa ewaluacja tego proposala została już przez Ciebie wprowadzona",
    ALERT13TITLE : "Uwaga",
    ALERT14 : "Wniosek został już poddany ocenie",
    ALERT14TITLE : "Uwaga",
    ALERT15 : "Po opublikowaniu edycja nie będzie już możliwa",
    ALERT15B1 : "Potwierdzenie",
    ALERT15B2 : "Anuluj",
    ALERT15TITLE : "Czy na pewno chcesz podać ten proposal ewaluacji?",
    ALERT16 : "Wprowadzona liczba nie może zawierać części dziesiętnych",
    ALERT16TITLE : "Uwaga",
    ALERT17 : "Wprowadzony numer nie może zawierać więcej niż 6 cyfr.",
    ALERT17TITLE : "Uwaga",
    ALERT1TITLE : "Uwaga",
    ALERT2 : "<h4>Czy coś nie działa, czy masz jakiś techniczny problem, czy po prostu chcesz się przywitać? Napisz do nas teraz na <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ALERT3 : "Kliknij na symbol gwiazdki <span class=\"symbol-rounded\">\n<span class=\"symbol-label font-size-h6 font-weight-bold bg-warning text-white\" style=\"width: 25px;height: 25px;\">\n<i class=\"fas fa-star text-white pr-0 pb-1\"></i>\n</span>\n</span> z boku, jeśli chcesz zobaczyć podsumowanie swoich głosów",
    ALERT3TITLE : "Dziękujemy za oddanie głosu na ten proposal",
    ALERT4 : "Zapoznaj się z proposalami i wybierz, który z nich zasługuje na Twój głos",
    ALERT4TITLE : "Twój głos został wycofany!",
    ALERT5 : "Możesz nadal przeznaczać środki na inne propozycje, wybierając sposób rozdysponowania swojego budżetu",
    ALERT5TITLE : "Dziękujemy za poparcie proposala!",
    ALERT6TITLE : "Obserwujesz ten proposal!",
    ALERT7TITLE : "Już nie obserwujesz tego proposala",
    ALERT8 : "Usunięcia nie można będzie cofnąć",
    ALERT8B1 : "Tak, usuń!",
    ALERT8B2 : "Nie, nie usuwaj!",
    ALERT8TITLE : "Czy na pewno chcesz skasować ten proposal?",
    ALERT9 : "Proposal został anulowany",
    ALERT9TITLE : "Anuluj proposal",
    ALERTBOZZA : "Masz już szkic swojego proposala. Pamiętaj, żeby go opublikować",
    ALERTEMPTYVOTE1USERLEFTHTML : "<h4> Nie masz już głosów do wykorzystania.<br /> Pamiętaj, że do zakończenia głosowania wciąż możesz zmienić swój głos. </h4>",
    ALERTEMPTYVOTE1USERLEFTTITLE : "Ups!",
    ALERTOWNERMAILEDHTML1 : "<p>Wysłaliśmy Twoją wiadomość do",
    ALERTOWNERMAILEDHTML2 : "Odpowiemy przy użyciu podanych przez Ciebie danych kontaktowych. <br />W międzyczasie przyłącz się do aktywności na platformie.</p>",
    ALERTOWNERMAILEDTITLE : "WIADOMOŚĆ WYSŁANA",
    ALERTPUBBLICATA : "Proposal w recenzji. Dołącz, by go poprawić",
    APPROFONDISCI : "Czytaj więcej",
    APPROVATA : "Zatwierdzony",
    AUTORE : "Published by",
    BUDGET : "Szacowany koszt:",
    CAMPOMANCANTE : "The field \"{field}\" is missing",
    CANCELLAIDEA : "Usuń proposal",
    CANVERSION : "Wciąż masz wersję roboczą. <br> Opublikuj ją, abyś mógł stworzyć nowy proposal.",
    CANVERSION2 : "Tworzysz {n} wersję swojej propozycji",
    CANVERSIONTITLE : "Uwaga",
    CANVERSIONTITLE2 : "{count} to maksymalna liczba wersji, które mogą być opublikowane",
    CHALLENGE : "Tematy",
    CIFRAALTA : "Przekazujesz za dużo środków",
    CIFRABASSA : "Dajesz za mało.",
    CIFRADELBUDGET : "Wciąż możesz przeznaczyć środki",
    CIFRAMODIFICATA : "Zmieniłeś swoją alokację środków",
    CIFRARIMOSSA : "Alokacja środków została usunięta",
    CIFRASUPERIOREALBUDGET : "Nie możesz już przeznaczyć środków, ponieważ kwota, którą wpisałeś, przekracza Twoje możliwości finansowe.",
    CLICCAILPULSANTE : "Kliknij przycisk, jeśli chcesz zagłosować na ten wniosek.",
    COMMENTAIDEA : "Twoje uwagi",
    COMMENTI : "Uwagi",
    COMMENTIALERTEMAILCONFIRM : "<h6 class=\"text-primary\">Nie potwierdziłeś jeszcze swojego adresu e-mail</h6><div>Potwierdź go, dołącz do naszej organizacji i zapisz się do procesu partypacyjnego</div><div>Nie otrzymałeś maila?<br><strong>Kliknij na przycisk w czerwonym pasku, aby wysłać go ponownie</strong></div>",
    COMMENTIALERTEMAILMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the organization and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    COMMENTIALERTMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the organization and sign up for participatory processes</div><div>You didn't receive the message?<br><strong>Click on the button in the red band to resend it</strong></div>",
    COMMENTICONTRIBUISCI : "Twoje uwagi",
    COMMENTIDISATTIVATI : "Komentarze są wyłączone",
    COMMENTIENTRA : "Zaloguj się",
    COMMENTIREGISTRATI : "ZAREJESTRUJ SIĘ",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "Zaloguj się, żeby zostawić komentarz",
    COMPLETABOZZA : "Uzupełnij szkic",
    CONDIVIDI : "Udostępnij",
    COSTOIDEA : "Koszt",
    CREATAIL : "Utworzony w dniu",
    DESCRIZIONE : "Opis",
    DONA : "Przeznacz środki",
    DONAREDA : "od minimalnie",
    DONAREFINOA : "do maksymalnie",
    DONRACCOLTE : "Zgromadzone środki",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the proposal does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the proposal because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    FOLLOWERS : "Społeczność",
    FONDIRACCOLTI : "Zebrane fundusze",
    GRAZIESCELTAIDEA : "Dziękujemy za wybór tego proposala!",
    GRAZIESOSTEGNO : "Dziękujemy za poparcie proposala!",
    GRAZIESOSTEGNOANCORA : "Dziękuję za dalsze wspieranie proposala!",
    HAIANCORA : "Zostało",
    HAIANCORA2 : "Zostało",
    HASCRITTO : "napisał/a/li/ły",
    IDEAVOTATA : "Wybrany proposal",
    INBOZZA : "Szkic",
    INDICACIFRA : "Wskaż kwotę, którą chcesz przeznaczyć",
    INSERISCICIFRA : "Proszę wpisać kwotę, którą chciałbyś przekazać na ten proposal.",
    INSERISCICOMMENTO : "Dodaj komentarz",
    INVIA : "wyślij",
    MACCETTODIESSERERICONTATTATO : "Wyrażam zgodę na kontakt za pośrednictwem podanych przeze mnie danych kontaktowych.",
    MAPPE : "Kontekst",
    MBCHIUDI : "Zamknij",
    MBINVIA : "Wyślij",
    MCONTATTAILPROPONENTE : "Skontaktuj się z autorem/autorką",
    MEMAIL : "Twój mail",
    MESSAGEFIELDNAME : "Message",
    METTIPREF : "Zagłosuj",
    MINSERISCIUNMESSAGGIO : "Twój komunikat",
    MMESSAGGIOOBBLIGATORIO : "Komunikat jest wymagany",
    MMOBILE : "Twój numer telefonu",
    MNOMINATIVO : "Twoje imię",
    MODIFICAIDEA : "Zmień proposal",
    MTIPOLOGIACOLLABORAZIONELABEL : "Pomóż rozwinąć proposal",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "Wybierz typ komunikatu",
    NARRAZIONE : "Proposal",
    NOCOMMENTI : "Nie ma żadnych komentarzy",
    NOCOMMUNITY : "Nie ma żadnych obserwujących",
    NONAPPROVATA : "Nie zatwierdzony",
    NONSEGUIRE : "Przestań obserwować",
    NONVALUTATA : "Nie oceniono",
    NUOVAVERSIONE : "Utwórz nową wersję",
    PH1 : "Wstaw wynik",
    PH2 : "Wstaw ocenę",
    PREFERENZEPERPREMIARE : "głosów. Nadal możesz wybrać najlepsze proposale!",
    PREFRACCOLTE : "Zgromadzone preferencje",
    PUBBLICATAIL : "on",
    PUOIDONARE : "Możesz przeznaczyć środki",
    RICHIEDIASSISTENZA : "Poproś o wsparcie!",
    RICORDATI3 : "Pamiętaj, że możesz zmienić alokację środków aż do zamknięcia głosowania.",
    RIMUOVIPREF : "Wycofaj swój głos",
    SEGUIIDEA : "Obserwuj",
    SOSTENITORI : "Popierający",
    SOSTIENIALTREIDEE : "Wciąż możesz zagłosować; wybierz proposale na które przekażesz swój głos",
    SOTTOCATEGORIA : "Tipologia",
    TOGLIEREPREFERENZA : "Jeśli chcesz zmienić swoją dezycję, możesz wycofać swój głos i oddać go na inne pomysły.",
    TUOIVOTI : "Twoje głosy",
    URLSITO : "Website",
    VAIALSITO : "Go to the website",
    VALUTAIDEA : "Oceń proposal",
    VALUTARIDUZIONEDONAZIONI : "Rozważ zmniejszenie alokacji środków na inne proposale",
    VALUTAZIONEINSERITA : "Wprowadzono rating",
    VALUTAZIONETITLE1 : "Wstaw ocenę końcową",
    VALUTAZIONETITLE2 : "Wstaw szczegółową ewaluację",
    VALUTAZIONETITLE3 : "Ocena końcowa",
    VALUTAZIONETITLE4 : "Szczegółowe ewaluacje",
    VALUTAZIONETITLE5 : "Końcowa ewaluacja została już wstawiona",
    VALUTAZIONETITLE6 : "Szczegółowa ocena została już wprowadzona",
    VALUTAZIONI : "Ewaluacje",
    VERSIONIPROPOSTA : "Versions of the proposal:",
    VOTAZIONECHIUSA : "Głosowanie zostało zamknięte. Wyniki zostaną opublikowane wkrótce",
    WALLETSOSTIENIIDEA : "Wspieraj proposal. Przeznacz na niego środki!"
  },
  IDEALIST : {
    ALERTBOZZA : "To szkic Twojego proposala. Możesz go znaleźć w swoim",
    ALERTVERSIONING1 : "Wersjonowanie jest aktywne!",
    ALERTVERSIONING2 : "możliwe jest poprawienie proposala poprzez dodanie lub uzupełnienie go o kolejne pomysły oraz przez aktualizowanie go o nowe wersje",
    ALERTVERSIONING3 : "Maksymalna liczba wersji",
    ALERTVERSIONING4 : "Limit czasu",
    ALERTVERSIONING5 : "Można utworzyć {n} wersji",
    ASSISTENZA : "Poproś o wsparcie!",
    CLASSIFICA : "Ranking",
    CREAIDEA : "Napisz proposal",
    ELENCOIDEE : "Wszystkie proposale",
    FILTRAPERCHALLENGE : "FILTRUJ PO TEMACIE",
    FILTRAPERMODULO : "FILTRUJ PO OBSZARACH",
    FILTRAPERSCENARIO : "FILTRUJ PO KONTEKŚCIE",
    IDEAVOTATA : "Zagłosowałeś/aś na ten proposal!",
    IDEE : "proposale",
    IDEECOSTOSE : "Najbardziej kosztowny",
    IDEEMENOCOSTOSE : "Najmniej kosztowny",
    IDEEMENORECENTI : "Najstarszy",
    IDEEMENOSEGUITE : "Najmniej popularny",
    IDEEPIUSEGUITE : "Najpopularniejszy",
    INFORMAZIONI : "Więcej informacji",
    INFORMAZIONISULLAFASE : "Jak uczestniczyć",
    JAMBOARD : "Dołącz do Jamboard",
    NESSUNO : "Brak",
    NONCISONOIDEE : "Nie ma jeszcze żadnych proposali",
    ORDINA : "SORTUJ",
    PREFERENZE : "oddanych głosów",
    PS : "Przestrzeń prywatna",
    TUOIVOTI : "Wasze głosy",
    ULTIMEIDEE : "Najnowsze",
    VOTANTI : "Głosujący",
    VOTAZIONEATTIVA : "Głosowanie jest aktywne",
    VOTAZIONEATTIVA2 : "Przeglądaj proposale i głosuj na najlepsze",
    VOTAZIONEATTIVA3 : "Możesz przeznaczyć środki",
    VOTAZIONEATTIVABUDG : "przekazując środki",
    VOTAZIONEATTIVAMAX : "up to",
    VOTAZIONEATTIVAMIN : "od",
    VOTAZIONEATTIVAPREF : "Wciąż możesz oddać głos",
    VOTAZIONEATTIVAPREF2 : "wciąż są dostępne",
    VOTAZIONEATTIVAPREF3 : "preferencje",
    VOTAZIONEATTIVAPREF4 : "preferencja",
    VOTAZIONEATTIVAUTENTEANONIMO : "Zarejestruj się, jeśli jesteś uprawniony do głosowania, będziesz mógł oddać głos na swoje ulubione propozycje.",
    VOTAZIONECHIUSA : "Głosowanie zostało zamknięte. Wyniki zostaną opublikowane wkrótce",
    WEBINAR : "Dołącz do webinaru"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "Ranking końcowy",
    COMEFUNZIONA : "Jak to działa?",
    COSTOIDEA : "Szacowany koszt",
    DONATORI : "Donatorzy",
    ELENCOPROCESSI : "Lista procesów",
    FASE : "Faza:",
    FILTRAPERBUDGET : "Filtruj według budżetu",
    FILTRAPERPREFERENZE : "Filtruj według preferencji",
    FINANZIATO : "Środki",
    PREFERENZE : "Głosy",
    PROCESSO : "Proces",
    RISULTATI : "Wyniki",
    RISULTATIVOTAZIONE : "Wyniki głosowania",
    TUTTOSUPROCESSO : "Wszystko, co musisz wiedzieć o partycypacij"
  },
  INTRO : {
    ASSISTENZA : "Pomoc"
  },
  INTROBASE : {
    ASSISTENZA : "Pomoc",
    COSA : "Co możesz zrobić",
    PARTECIPA : "Dołącz",
    PARTECIPADESC : "Weź udział w procesie w swojej szkole",
    SUBIPART : "w sprawie Bipart"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "Pamiętaj, aby potwierdzić swój adres malikowy. Jeśli nie otrzymałeś żadnej wiadomości od BiPart",
    CONFIRMEMAIL2 : "kliknij tutaj",
    CONFIRMEMAIL3 : "aby wysłać ponownie.",
    CONFIRMMOBILE1 : "Remember to confirm your mobile. If you have not received any SMS from BiPArt",
    CONFIRMMOBILE2 : "click here",
    CONFIRMMOBILE3 : "to resend it."
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Zamknij",
    CONFERMA : "Potwierdź rejestrację do procesu",
    ERR1 : "Ups... Wystąpił nieoczekiwany błąd.",
    ISCRIVITIPROCESSO : "Dołącz do procesu",
    PARTECIPAPROCESSO : "Czy chcesz być częścią tego procesu?",
    PROPOSTE : "Aby zgłaszać, komentować, prosić o ocenę, głosować, musisz się zarejestrować.",
    TITLE1 : "Błąd"
  },
  LOGIN : {
    ACCEDI : "Zaloguj się",
    ACCETTO1 : "Przeczytałem i akceptuję",
    ACCETTO2 : "Przeczytałem i akceptuję",
    BACKPORTAL : "Back to portal",
    CAMBIAPASS : "Zmiana hasła",
    INSERISCIMAIL : "Wpisz swój adres e-mail",
    MEX1 : "Rejestracja i akceptacja \"Polityki prywatności\" i \"Regulaminu\".",
    MEX2 : "Wprowadzone przez Ciebie dane uwierzytelniające są nieprawidłowe. Proszę je sprawdzić lub napisać do nas na <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Uwierzytelnienie pomyślne",
    MEX4 : "Wystąpił błąd",
    MEX5 : "Nieprawidłowy e-mail",
    MEXLOGIN : "<b>Witamy w BiPart,</b><br>Społecznej sieci obywatelskiej partycypacji.<br>Twoje dane będą traktowane zgodnie z polskim i europejskim prawem i z dbałością o ochronę Twoich opinii i decyzji.<br><b>Jeśli chcesz kontynuować, przeczytaj i zaakceptuj dwa poniższe dokumenty.</b>",
    NONRICORDIPASS : "Nie pamiętasz hasła?",
    NOTIFICHE : "*Jeśli chcesz otrzymywać powiadomienia o postępach w realizacji złożonych proposali.",
    PASSWORDERRATA : "Hasło nieprawidłowe",
    PRIVACY : "Polityka prywatności",
    PROSEGUI : "Kontynuuj",
    TERMINI : "Warunki świadczenia usług",
    TERMINICONDIZIONI : "Aby kontynuować, prosimy o zapoznanie się z <a href=\"https://bipart.it/terms\" target=\"blank\">Warunkami świadczenia usług</a><br> oraz <a href=\"https://bipart.it/privacy\" target=\"blank\">Polityką prywatności.</a>.",
    TITLE1 : "Gratulacje",
    TITLE2 : "Uwaga",
    TITLE3 : "Gratulacje",
    TITLE4 : "Błąd",
    TITLE5 : "Błąd",
    USERNAMENONESISTE : "Użytkownik o tej nazwie nie istnieje",
    UTENTEAUTENTICATO : "-- Użytkownik uwierzytelniony --"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "Zarejestuj się",
    ACCETTO : "Akceptuję",
    ACCETTOLA : "Akceptuję",
    BPROSEGUI : "Kontynuuj",
    CAMBIAPASS : "Zmiana hasła",
    INSERISCIMAIL : "Wpisz swój adres e-mail",
    MEX1 : "Rejestracja i akceptacja \"Polityki prywatności\" i \"Regulaminu\".",
    MEX2 : "Wprowadzone przez Ciebie dane uwierzytelniające są nieprawidłowe. Sprawdź je proszę lub napisz do nas na <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Uwierzytelnienie pomyślne",
    MEX4 : "Wystąpił błąd",
    MEX5 : "Nieprawidłowy adres e-mail",
    MEXLOGIN : "<b>Witamy w BiPart,</b><br>Sieci społecznej zaangażowania obywatelskiego.<br>W celu ochrony Twoich opinii i decyzji Twoje dane będą przetwarzane z zachowaniem należytej staranności i w pełnej zgodności z przepisami włoskimi oraz europejskimi. <br><b>Jeśli chcesz kontynuować, przeczytaj i zaakceptuj dwa poniższe dokumenty.</b>",
    NIENTEACCOUNT : "Nie masz jeszcze konta?",
    NONRICORDIPASS : "Nie pamiętasz swojego hasła?",
    NOTIFICHE : "*Jeśli chcesz otrzymywać powiadomienia o postępach w realizacji złożonych proposali.",
    PRIVACY : "Polityka prywatności",
    REGISTRATI : "Rejestracja",
    TERMINI : "Warunki świadczenia usług",
    TERMINIECONDIZIONI : "Aby kontynuować, proszę zapoznać się z <a href=\"https://bipart.it/terms\" target=\"blank\">Warunkami świadczenia usług </a><br> oraz <a href=\"https://bipart.it/privacy\" target=\"blank\">Polityką prywatności.</a>.",
    TITLE1 : "Gratulacje",
    TITLE2 : "Uwaga",
    TITLE3 : "Gratulacje",
    TITLE4 : "Błąd",
    TITLE5 : "Błąd",
    TITOLO : "BiPart",
    UTENTEAUTENTICATO : "-- Użytkownik uwierzytelniony --"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Zamknij",
    CONFERMA : "Zaloguj się",
    ISCRIVITIPROCESSO : "Zaloguj się za pomocą swoich danych uwierzytelniających i weź udział w procesie",
    MEX1 : "Nie wprowadziłeś wymaganych danych. Spróbuj ponownie lub napisz do <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX2 : "Wprowadzone przez Ciebie dane uwierzytelniające są nieprawidłowe. Sprawdź je lub napisz do nas na <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Ups... Wystąpił błąd.",
    NONFAIPARTE : "Nie masz zarejestrowanego konta?",
    NUOVOACCOUNT : "Zarejestruj się, aby oddać swój głos!",
    ORGANIZATION : "Witamy w Bipart",
    PASSWORDERRATA : "Hasło nieprawidłowe",
    REGISTRATI : "ZAREJESTRUJ SIĘ",
    TITLE2 : "Uwaga",
    TITLE3 : "Błąd",
    USERNAMENONESISTE : "Nazwa użytkownika nie istnieje"
  },
  MAINMENU : {
    ABOUT : "O",
    ACCESSIBILITY : "Dostępność",
    COMMUNITY : "Społeczność",
    COOKIE : "Ciasteczka",
    DEMO : "Tworzenie przestrzeni dla społeczności",
    DS : "Przestrzeń deliberacji",
    EXPLORE : "Poznaj stronę",
    FAQ : "FAQ",
    FEATURE : "Samouczek i wskazówki",
    GIOCHI : "Gry edukacyjne",
    HOME : "Strona główna",
    HOWITWORKS : "Jak to działa?",
    HOWTOJOIN : "Jak dołączyć",
    JOINCOMMUNITY : "Przestrzeń społeczności",
    LISTCHALLENGES : "Tematy",
    LISTGROUPS : "Lista grup",
    LISTPROCESSES : "Procesy",
    LISTSCENARIOS : "Kontekst",
    LOGIN : "Zaloguj się",
    LOGOUT : "Wyloguj się",
    PORTAL : "Portal",
    PRIVACY : "Prywatność",
    PROJECT : "Projekt",
    PROJECTS : "Studium przypadku",
    PS : "Przestrzeń osobista",
    PSACTIVITIES : "Działania",
    PSPROFILE : "Profil osobisty",
    PSSETTINGS : "Ustawienia",
    REGISTER : "Zarejestruj się",
    SERVICES : "Co oferujemy",
    TERMS : "Zasady i warunki",
    WORKSPACE : "Przestrzeń pracy"
  },
  MANDATORYFLAGS : {
    ACCETTO : "Przeczytałem/łam i akceptuję",
    ACCETTOLA : "Przeczytałem/łam i akceptuję",
    BPROSEGUI : "Kontynuuj",
    DETTWELCOME : "W celu ochrony Twoich opinii i decyzji twoje dane będą przetwarzane z zachowaniem należytej staranności i w pełnej zgodności z przepisami włoskimi oraz europejskimi. Jeśli chcesz kontynuować, przeczytaj i zaakceptuj dwa poniższe dokumenty.",
    INSERISCIMAIL : "Wpisz swój adres e-mail",
    MEX2 : "Wprowadzone przez Ciebie dane uwierzytelniające są nieprawidłowe. Sprawdź je proszę lub napisz do nas na <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Uwierzytelnienie pomyślne",
    MEX5 : "Podany adres e-mail jest nieprawidłowy",
    NOTIFICHE : "*Jeśli chcesz otrzymywać powiadomienia o postępach w realizacji złożonych proposali.",
    PRIVACY : "Polityka prywatności",
    TERMINIECONDIZIONI : "Aby kontynuować, proszę zapoznać się z <a href=\"https://bipart.it/terms\" target=\"blank\">Warunkami świadczenia usług </a><br> oraz <a href=\"https://bipart.it/privacy\" target=\"blank\">Polityką prywatności.</a>.",
    TITLE1 : "Gratulacje",
    TITLE2 : "Uwaga",
    TITLE3 : "Gratulacje",
    TITOLO : "BiPart",
    TITOLOWELCOME : "Witamy na BiPart, portalu służącym partycypacji obywatelskiej.",
    UTENTEAUTENTICATO : "-- Użytkownik uwierzytelniony --"
  },
  MESSAGEAUTH : {
    0 : "Wystąpił ogólny błąd",
    1 : "Wprowadzone dane uwierzytelniające są błędne. Sprawdź ponownie lub napisz do nas na adres<a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    100 : "Podany atrybut \"organizationDomain\" nie pasuje do żadnej organizacji.",
    105 : "Brakuje atrybutu \"processId\"",
    110 : "Podany atrybut \"processId\" nie pasuje do żadnego procesu.",
    115 : "Proces nie należy do określonej organizacji.",
    120 : "Cześć!\nProces jest prywatny: aby wziąć w nim udział, musisz poprosić o kod dostępu.",
    125 : "Podany atrybut \"groupId\" nie pasuje do żadnej grupy.",
    130 : "Ups!\nTa grupa jest prywatna i nie ma możliwości rejestracji.",
    135 : "Żądana grupa nie należy do wybranego procesu.",
    140 : "Cześć!\nAby wziąć udział, musisz wskazać do jakiej grupy należysz lub chcesz dołączyć. Wybierz ją z rozwijanego menu. Dziękujemy!",
    145 : "Ups!\nWybrany Proces zawiera tylko grupy prywatne, do których nie można się zarejestrować.",
    150 : "Ups!\n Zapomniałeś o podaniu ważnych danych w czasie rejestracji. Uzupełnij jeszcze pola wyróżnione na czerwono.",
    155 : "Ups!\n Nazwa użytkownika, którą wybrałeś jest już używana: może zapomniałeś, że jesteś już zarejestrowany. \nSpróbuj zalogować się lub wpisać inną nazwę.\nDziękujemy!",
    160 : "Ups!\nUżytkownik jest już zarejestrowany na platformie, ale nie jest jeszcze powiązany z tym procesem.\nUżyj przycisku \"Zaloguj się\" i wprowadź swoje dane uwierzytelniające, aby zakończyć ten proces.",
    165 : "Ups!\n Nazwa użytkownika, którą wybrałeś jest już używana: może zapomniałeś, że jesteś już zarejestrowany. \nSpróbuj zalogować się lub wpisać inną nazwę.\nDziękujemy!",
    170 : "O nie! Ktoś był szybszy i już wybrał tę nazwę użytkownika. :( Spróbuj wprowadzić inną, może jeszcze fajniejszą!",
    172 : "Adres e-mail, który wpisałeś już jest używany\nSprawdź, czy nie masz konta w BiPart lub zgłoś problem na adres: support@bipart.it",
    175 : "Ups!\nAby przejść do rejestracji musisz przeczytać i wyrazić zgodę na naszą politykę prywatności oraz regulamin serwisu!",
    180 : "Cześć!\nOrganizacja jest prywatna: aby dołączyć należy poprosić o kod dostępu.",
    182 : "Żądana grupa nie należy do wybranej organizacji.",
    185 : "Cześć!\nAby wziąć udział, musisz wskazać do jakiej grupy należysz lub chcesz dołączyć. Wybierz ją z rozwijanego menu. Dziękujemy!",
    187 : "Ups!\nWybrana organizacja zawiera tylko prywatne grupy, do których nie można się zarejestrować.",
    190 : "Ups!\n Nazwa użytkownika, którą wybrałeś jest już używana: może zapomniałeś, że jesteś już zarejestrowany. \nSpróbuj zalogować się lub wpisać inną nazwę.\nDziękujemy!",
    192 : "Ups!\nUżytkownik jest już zarejestrowany na platformie, ale nie jest jeszcze powiązany z tym Procesem.\nUżyj przycisku \"Zaloguj się\" i wprowadź swoje dane uwierzytelniające, aby zakończyć ten proces.",
    2 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Dziękujemy za zapisanie się do<br>{value}</h5>",
    200 : "Ups!\nMinęło za dużo czasu!\nAbyśmy mogli zweryfikować Twój adres e-mail, musisz poprosić o wysłanie nowej wiadomości i jak najszybciej kliknąć zawarty w niej przycisk potwierdzający.",
    205 : "Ups!\nOperacja nie powiodła się: spróbuj ponownie!",
    210 : "Ups!\nTwoje konto nie odpowiada żadnemu użytkownikowi w naszym archiwum!",
    215 : "Ups!\nNie otrzymaliśmy żadnego wniosku o rejestrację odpowiadającego Twojemu adresowi e-mail.\nCzy jesteś pewien, że wypełniłeś odpowiednią procedurę?\nSpróbuj zarejestrować się ponownie.\nDziękujemy!",
    220 : "Ups!\nAdres e-mail, który próbujesz potwierdzić jest już zweryfikowany.\nSpróbuj się zalogować.\nDziękujemy!",
    225 : "Ups!\nOperacja nie powiodła się: spróbuj ponownie!",
    3 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Uczestniczysz w procesie<br>{value}</h5>",
    300 : "Ups!\nYTwoje konto nie jest jeszcze zarejestrowane na platformie\nCzy jesteś pewien, że wypełniłeś odpowiednią procedurę?\nSpróbuj zarejestrować się ponownie.\nDziękujemy!",
    305 : "Ups!\nTwoje konto jest już zarejestrowane na platformie, ale nie jest jeszcze powiązane z tą organizacją.\nSkontaktuj się z nami, aby poprosić o dostęp.",
    310 : "Ups!\n Nazwa użytkownika, którą wybrałeś jest już używana: może zapomniałeś, że jesteś już zarejestrowany. \nSpróbuj zalogować się lub wpisać inną nazwę.\nDziękujemy!",
    4 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><div>Witamy w {organization_name} </div><h5 class=\"text-primary\">Potwierdzamy Twoją rejestrację do {process_name} </h5><div>Od tej pory jesteś częścią społeczności i możesz uczestniczyć w procesach, do których jesteś zapisany.</div>",
    400 : "Brakuje atrybutu \"mobile\".",
    405 : "Ups!\nPodany numer telefonu jest już używany: może już się zarejestrowałeś i nie pamiętasz.\nSpróbuj się zalogować lub wprowadź inny numer.\nDziękujemy!",
    410 : "Ups!\nPodany numer telefonu jest już używany: może już się zarejestrowałeś i nie pamiętasz.\nSpróbuj się zalogować lub wprowadź inny numer.\nDziękujemy!",
    420 : "Ups!\nPodany numer telefonu jest już używany.\nCzy na pewno należy do Ciebie? Spróbuj wprowadź inny numer.\nDziękujemy!",
    425 : "Ops!\nL’attributo “mobile” non compare nel database Utenti\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    500 : "Ups!\nZ Twoim profilem nie jest powiązany żaden numer telefonu komórkowego.\nPowróć do poprzedniego panelu i wprowadź go.\nDziękujemy!",
    502 : "Wystąpił problem techniczny i wysłaliśmy Ci nowy kod weryfikacyjny SMS-em.\nPowtórz operację po wpisaniu go w tym samym panelu.\nDziękujemy!",
    510 : "Ups!\nWprowadzony kod weryfikacyjny jest nieprawidłowy.\nSprawdź go lub poproś o przesłanie nowego kodu.\nDziękujemy!",
    515 : "Ups\nWprowadzony kod weryfikacyjny jest już nieważny\nPoproś o przesłanie nowego kodu\nDziękujemy!",
    605 : "Ups!\nNumer telefonu jest już powiązany z Twoim profilem.",
    700 : "Podany atrybut \"process2ModuleId\" nie pasuje do żadnego modułu.",
    705 : "Moduł docelowy nie należy do procesu docelowego.",
    710 : "Kod podatkowy nie jest zadeklarowany ani nie jest już przypisany do wnioskującego użytkownika aplikacji.",
    715 : "Ups!\nWprowadzony kod podatkowy jest nieprawidłowy: sprawdź go.\nDziękujemy!",
    720 : "Ups!\nWprowadzony kod podatkowy jest już używany.\nJesteś pewien, że jest Twój? Spróbuj wpisać inny.\nDziękujemu!",
    725 : "Ups!\nKod podatkowy jest już powiązany z Twoim profilem.",
    ATTENZIONE : "Uwaga"
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "Insert or copy and paste the URL of the official website of the organization providing the service",
    MAXLENGTHPART1 : "Uwaga! Nadal masz",
    MAXLENGTHPART2 : "dostępnych znaków",
    PH0 : "Max. 60 znaków",
    PH5 : "----",
    SELEZIONASOTTOCATEGORIA : "Indicate the type of service you are publishing by selecting one of the options that appear by clicking the input field",
    SOTTOCATEGORIA : "Type"
  },
  MODULEIDEACREATE : {
    ALERT1 : "Upewnij się, że wypełniłeś obowiązkowe pola: Tytuł i Opis",
    ALERT1TITLE : "Uwaga",
    ALERT2 : "Proposal został poprawnie wprowadzony!",
    ALERT3 : "Wpisz zakładaną wartość, aby wskazać szacunkowy koszt proposala",
    ALTROMATERIALE : "Jeśli masz inne przydatne materiały, które lepiej zilustrują Twój proposal (rysunki, spostrzeżenia, specyfikacje techniczne...), prześlij je tutaj",
    ANNULLA : "Anuluj",
    AVANTI : "Dalej",
    BOZZA : "Zapisz jako draft",
    CHALLENGE : "Tematy",
    COSTOIPOTIZZATO : "Szacowany koszt",
    CREAIDEA : "Napisz proposal",
    DAIBREVETITOLO : "Nadaj swojemu proposalowi krótki, lecz chwytliwy tytuł",
    DESCRIVI : "Podsumuj zwięźle swój proposal. W kolejnej sekcji możesz go szczegółowo opisać.",
    DESCRIZIONE : "Podsumowanie",
    DOCALLEGATI : "Dokumenty i załączniki.",
    FILEACCETTATI : "Maksymalna wielkość pliku: 10 MB. Obsługiwane formaty: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Maksymalna wielkość pliku: 10 MB. Obsługiwane formaty: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximum file size: 10 MB. Supported formats: JPG, JPEG, PNG",
    IMMAGINERAPPRESENTATIVA : "Wstaw obraz, który przedstawia Twój proposal",
    IMMAGINI : "Zamieść więcej zdjęć, aby pomóc ludziom lepiej zrozumieć Twój proposal.",
    INDICACOSTO : "Oszacuj koszty swojego proposala",
    ISTRUZIONI : "Instrukcje",
    LOGO : "Ilustracja",
    MAPPEEINFO : "Mapy i infografiki",
    NARRA : "Opisz szczegółowo swój proposal",
    NARRAZIONE : "Proposal",
    OVERVIEW : "Informacje ogólne",
    PH1 : "Maks. 600 znaków",
    PH2 : "Kod osadzjący (tylko youtube)",
    PH3 : "Zacznij wpisywać adres i kliknij na najlepszy wynik, aby umieścić pinezkę",
    PH4 : "URL",
    PUBBLICA : "Publikuj",
    SCEGLISFIDE : "Wybierzcie wyzwanie (wyzwania), których dotyczy Wasz proposal.",
    SELEZIONAMAPPA : "Wskaż, gdzie znajduje się Twój proposal",
    SELEZIONATRA : "Wybierz spośród",
    TITOLO : "Tytuł",
    TRASCINA : "Przeciągnij obraz tutaj lub kliknij tutaj, aby go przesłać",
    TRASCINADOC : "Przeciągnij pliki tutaj lub kliknij tutaj, aby je przesłać.",
    TRASCINAIMMAGINI : "Przeciągnij zdjęcia tutaj lub kliknij tutaj, aby je załadować",
    URLSITO : "Website",
    VIDEO : "Jeśli istnieje materiał wideo opisujący Twój proposal, zamieść go poprzez skopiowanie kodu tutaj (nie adresu URL), aby był widoczny dla wszystkich.",
    VIDEOHINT : "Filmik pomaga opowiedzieć historię i dotrzeć do większej grupy odbiorców."
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "Zaktualizuj tytuł",
    AGGIUNGIALTREIMMAGINI : "Dodaj więcej zdjęć",
    ALERT1 : "Upewnij się, że wypełniłeś obowiązkowe pola: Tytuł i Opis",
    ALERT1TITLE : "Uwaga",
    ALERT2 : "Proposal został poprawnie wprowadzony!",
    ALERT2TITLE : "Zamierzasz opublikować nową wersję proposala",
    ALERT3 : "Wybierz język, w którym Twój proposal ma być opublikowany. Musisz wybrać co najmniej jeden język",
    ALERT4 : "Pamiętaj, że po opublikowaniu nie będzie można go zmienić.",
    ALERT5 : "Wpisz poprawną wartość, aby wskazać szacunkowy koszt proposala",
    ALTROMATERIALE : "Jeśli masz inne przydatne materiały, które lepiej zilustrują Twój proposal (rysunki, spostrzeżenia, specyfikacje techniczne...), prześlij je tutaj",
    ANNULLA : "Anuluj",
    AVANTI : "Dalej",
    BOZZA : "Zapisz jako szkic",
    CANCELLA : "Usuń",
    CHALLENGE : "Wyzwanie",
    COSTO : "Koszt",
    COSTOIPOTIZZATO : "Zaktualizuj szacowane koszty",
    DESCRIVI : "Zaktualizuj opis",
    DESCRIZIONE : "Abstrakt",
    DOCALLEGATI : "Dokumenty i załączniki.",
    DOCTECNICA : "Dokumentacja techniczna",
    EDITRESOURCESMODALTITLE : "Zmień tytuł",
    ERR1 : "Pole wymagane",
    FILEACCETTATI : "Maksymalna wielkość pliku: 10 MB. Obsługiwane formaty: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Maksymalna wielkość pliku: 10 MB. Obsługiwane formaty: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maksymalna wielkość pliku: 10 MB. Obsługiwane formaty: DOC, XLS, PDF, PPT",
    IMMAGINI : "Zdjęcia",
    ISTRUZIONI : "Instrukcje",
    LINKVIDEO : "Linki do wideo",
    LOGO : "Logo",
    MAPPEEINFO : "Mapy i infografiki",
    MODIFICA : "Zmień",
    MODIFICAIDEA : "Edytuj proposal",
    MOSTRA : "Pokaż",
    NARRA : "Szczegółowo opisz taktykę",
    NARRAZIONE : "Proposal",
    OVERVIEW : "Informacje ogólne",
    PH1 : "Maks. 600 znaków",
    PH2 : "kod do osadzenia (tylko youtube)",
    PH3 : "Zacznij wpisywać adres i kliknij na najlepszy wynik, aby umieścić pinezkę",
    PH4 : "URL",
    PUBBLICA : "Publikuj",
    SCEGLISFIDE : "Wybierzcie wyzwanie (wyzwania), których dotyczy Wasz proposal.",
    SELEZIONAMAPPA : "Pinpoint where your proposal is located",
    SELEZIONATRA : "Wybierz spośród",
    TITLE : "Tytuł",
    TITOLO : "Tytuł",
    TRASCINA : "Przeciągnij obraz tutaj lub kliknij tutaj, aby go przesłać",
    TRASCINADOC : "Przeciągnij pliki tutaj lub kliknij tutaj, aby je przesłać.",
    TRASCINAIMMAGINI : "Przeciągnij zdjęcia tutaj lub kliknij tutaj, aby je załadować",
    URLSITO : "Sito web",
    VIDEO : "Zaktualizuj wideo",
    VIDEOHINT : "Wideo (wstaw kod osadzający)"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "Dopasuj kontekst",
    AGGIORNATITOLO : "Zaktualizuj tytuł",
    AGGIUNGIALTREIMMAGINI : "Dodaj więcej obrazów",
    ALERT1 : "Upewnij się, że wypełniłeś_aś obowiązkowe pola: Tytuł i Opis",
    ALERT1TITLE : "Uwaga",
    ALERT2 : "Proposal został poprawnie wprowadzony!",
    ALERT2TITLE : "Zamierzasz opublikować nową wersję proposala",
    ALERT3 : "Wybierz język, w którym chcesz, aby Twój proposal został opublikowany. Musisz wybrać co najmniej jeden język",
    ALERT4 : "Pamiętaj, że po publikacji nie będzie można dokonać już zmian",
    ALERT5 : "Wpisz prawidłową wartość wskazującą na zakładany koszt proposala",
    ALTROMATERIALE : "Jeśli masz inne przydatne materiały, które lepiej zilustrują Twoją propozycję (rysunki, spostrzeżenia, specyfikacje techniczne...), prześlij je tutaj",
    ANNULLA : "Anuluj",
    AVANTI : "Dalej",
    BOZZA : "Zapisz jako draft",
    CANCELLA : "Anuluj",
    CHALLENGE : "Wyzwania",
    COSTO : "Koszt",
    COSTOIPOTIZZATO : "Zaktualizuj szacowany koszt",
    DESCRIVI : "Zaktualizuj opis",
    DESCRIZIONE : "Krótki opis",
    DOCALLEGATI : "Dokumenty i załączniki.",
    DOCTECNICA : "Dokumentacja techniczna",
    EDITRESOURCESMODALTITLE : "Edytuj tytuł",
    ELENCOIDEE : "Wszystkie proposale",
    ERR1 : "Wymagane pole",
    FILEACCETTATI : "Maksymalny rozmiar pliku: 10 MB. Obsługiwane formaty: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Maksymalny rozmiar pliku: 10 MB. Obsługiwane formaty: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximum file size: 10 MB. Supported formats: JPG, JPEG, PNG",
    IMMAGINI : "Obrazy",
    ISTRUZIONI : "Instrukcje",
    LINKVIDEO : "Link do video",
    LOGO : "Logo",
    MAPPEEINFO : "Kontekst",
    MODIFICA : "Zmień",
    MODIFICAIDEA : "Edytuj proposal",
    MOSTRA : "Pokaż",
    NARRA : "Dokładnie opisz swój proposal",
    NARRAZIONE : "Proposal",
    NUOVAVERSIONE : "Stwórz nową wersję",
    NUOVAVERSIONEMESSAGGIO1 : "Tworzysz",
    NUOVAVERSIONEMESSAGGIO2 : "WERSJA",
    NUOVAVERSIONEMESSAGGIO3 : "Twojego projektu",
    OVERVIEW : "Opis",
    PH1 : "Maksymalnie 600 znaków",
    PH2 : "URL (tylko Youtube)",
    PH3 : "Zacznij wpisywać adres i kliknij najlepszy wynik, aby umieścić pinezkę",
    PH4 : "URL",
    PROCESSO : "Proces",
    PUBBLICA : "Publikuj",
    SCEGLISFIDE : "Wybierz tematy, których dotyczy Twój proposal",
    SELEZIONAMAPPA : "Wskaż, gdzie ma miejsce Twój proposal",
    SELEZIONATRA : "Wybierz z",
    TITLE : "Tytuł",
    TITOLO : "Tytuł",
    TRASCINA : "Przeciągnij obrazy tutaj lub kliknij aby przesłać",
    TRASCINADOC : "Upuść pliki tutaj lub kliknij aby przesłać",
    TRASCINAIMMAGINI : "Przeciągnij obrazy tutaj lub kliknij aby przesłać",
    URLSITO : "Website",
    VIDEO : "Zaktualizuj wideo",
    VIDEOHINT : "Wideo (wstaw URL)"
  },
  MODULEINFO : {
    ELENCOPROCESSI : "Lista procesów",
    INFO : "Informacja o procesie",
    INPROGRESS : "W trakcie...",
    PROCESSO : "Proces"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "Lista procesów",
    INFO : "Moduł w budowie",
    INPROGRESS : "W trakcie realizacji...",
    PROCESSO : "Proces"
  },
  MODULESERVICECREATE : {
    ALERT1 : "Check that you have entered the mandatory data: Title, Summary, and Type",
    ALERT2 : "The service has been entered correctly!",
    ALERT3 : "Enter the cost of the service",
    ALTROMATERIALE : "If you have other material useful to detail your service (drawings, insights, technical specifications, ...), upload it here",
    CREAIDEA : "Describe your service",
    DAIBREVETITOLO : "Give a short but catchy title to your service",
    DESCRIVI : "Describe your service in a few lines. You can detail it in the next step",
    IMMAGINERAPPRESENTATIVA : "Insert an image representing your service",
    IMMAGINI : "Publish additional images that help better understand your service",
    INDICACOSTO : "Indicate how much your service might cost",
    NARRA : "Describe your service in detail",
    NARRAZIONE : "The service",
    SCEGLISFIDE : "Choose to which service or services yours refers to",
    SELEZIONAMAPPA : "Indicate where your service is located",
    URLSITO : "Website",
    VIDEO : "If there is or you have created a YouTube video that explains your service, make it visible to everyone by copying the URL here",
    VIDEOHINT : "A video helps to better describe a service and reach more people"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "The service has been loaded correctly!",
    ALERT2TITLE : "You are about to publish a new version of the service",
    ALERT3 : "Select the language in which you want to publish your service. You must select at least one",
    ALTROMATERIALE : "If you have other material useful to detail your service (drawings, insights, technical specifications, ...), upload it here",
    MODIFICAIDEA : "Edit the contents",
    NARRA : "Describe your service in detail",
    NARRAZIONE : "The service",
    SCEGLISFIDE : "Choose which theme(s) your service relates to",
    SELEZIONAMAPPA : "Indicate where the service is located",
    URLSITO : "Website"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "Make sure you have entered the required data: Title, Summary, and Type",
    ALERT2 : "Your service has been successfully added!",
    ALERT2TITLE : "You are about to publish an updated version of your service",
    ALERT3 : "Select the language or languages in which you want to publish your service. You must select at least one.",
    ALERT5 : "Enter a correct value to indicate the hypothetical cost of the service",
    ALTROMATERIALE : "If you have other useful materials to detail your service (drawings, insights, technical specifications, ...), upload them here",
    ELENCOIDEE : "All services",
    MODIFICAIDEA : "Edit the contents of your service",
    NARRA : "Describe your service in detail",
    NARRAZIONE : "The service",
    NUOVAVERSIONEMESSAGGIO3 : "of your service",
    SCEGLISFIDE : "Choose which or which themes your service wants to address",
    SELEZIONAMAPPA : "Indicate where the service is located",
    URLSITO : "Website"
  },
  MODULESTORYCREATE : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "Wpis został opublikowany!",
    ALERT3 : "Wpisz zakładaną wartość, aby wskazać szacunkowy koszt taktyki",
    ALTROMATERIALE : "Jeśli masz materiały. które jeszcze lepiej zobrazują Twoją historię, umieść je tutaj.",
    CREAIDEA : "Opowiedz swoją historię",
    DAIBREVETITOLO : "Przypisz swojej historii krótki i znaczący tytuł",
    DESCRIVI : "Opisz swoją historię w kilku linijkach",
    IMMAGINERAPPRESENTATIVA : "Wstaw zdjęcie, które opisze Twoją historię",
    IMMAGINI : "Zamieść inne zdjęcia, które lepiej zilustrują Twoją historię",
    INDICACOSTO : "",
    NARRA : "Szczegółowo opisz swoją historię",
    NARRAZIONE : "Historia",
    SCEGLISFIDE : "Wybierz tematy, których dotyczy Twoja historia",
    SELEZIONAMAPPA : "Indicate where your story took place",
    URLSITO : "Website",
    VIDEO : "Jeśli ktoś inny lub Ty zrobiłeś film, w którym opowiadasz swoją historię na YouTube , podziel się nim, kopiując adres URL tutaj.",
    VIDEOHINT : "Filmik pomaga opowiedzieć historię i dotrzeć do większej grupy odbiorców."
  },
  MODULESTORYEDIT : {
    ALERT2 : "Historia została opublikowana!",
    ALERT2TITLE : "Masz zamiar zamieścić nową wersję historii",
    ALERT3 : "Zdecyduj, w którym języku chcesz opublikować swoją historię. Musisz wybrać przynajmniej jeden",
    ALTROMATERIALE : "Jeśli masz materiały. które jeszcze lepiej zobrazują Twoją historię, umieść je tutaj",
    MODIFICAIDEA : "Edytuj historię",
    NARRA : "Szczegółowo opisz swoją historię",
    NARRAZIONE : "Opowieść",
    SCEGLISFIDE : "Wybierz tematy, których dotyczy Twoja historia",
    SELEZIONAMAPPA : "Wskaż miejsce, w którym rozgrywa się historia",
    URLSITO : "Sito web"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "Story posted successfully!",
    ALERT2TITLE : "Masz zamiar zamieścić nową wersję historii",
    ALERT3 : "Zdecyduj, w którym języku chcesz opublikować swoją historię. Musisz wybrać przynajmniej jeden",
    ALERT5 : "",
    ALTROMATERIALE : "Jeśli masz materiały. które jeszcze lepiej zobrazują Twoją historię, umieść je tutaj",
    ELENCOIDEE : "Wszystkie historie",
    MODIFICAIDEA : "Edytuj historię",
    NARRA : "Szczegółowo opisz swoją historię",
    NARRAZIONE : "Historia",
    NUOVAVERSIONEMESSAGGIO3 : "Twojej opowieści",
    SCEGLISFIDE : "Wybierz temat(y), których dotyczy Twoja historia",
    SELEZIONAMAPPA : "Wskaż miejsce, w którym rozgrywa się historia",
    URLSITO : "Website"
  },
  MODULETACTICCREATE : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "The tactic is correctly entered!",
    ALERT3 : "Wpisz zakładaną wartość, aby wskazać szacunkowy koszt taktyki",
    ALTROMATERIALE : "Jeśli masz inne przydatne materiały, które lepiej zilustrują Twoją propozycję (rysunki, spostrzeżenia, specyfikacje techniczne...), prześlij je tutaj",
    CREAIDEA : "Wpisz taktykę",
    DAIBREVETITOLO : "Nadaj taktyce krótki i chwytliwy tytuł",
    DESCRIVI : "Podsumuj taktykę w kilku słowach. Następnie możesz ją szczegółowo opisać w kolejnej części.",
    IMMAGINERAPPRESENTATIVA : "Wstaw zdjęcie, które prezentuje taktykę",
    IMMAGINI : "Zamieść więcej zdjęć, aby pomóc innym lepiej zrozumieć taktykę.",
    INDICACOSTO : "Wskazuje, ile może kosztować taktyka",
    NARRA : "Szczegółowo opisz taktykę",
    NARRAZIONE : "Taktyka",
    SCEGLISFIDE : "Wybierz tematy, których dotyczy taktyka",
    SELEZIONAMAPPA : "Wskaż miejsce, gdzie rozgrywa się taktyka",
    URLSITO : "Website",
    VIDEO : "Jeśli ktoś inny lub Ty zrobiłeś film, w którym opisujesz taktykę, podziel się nim, wklejając kod w tym miejscu (nie adres URL). Dzięki temu będą mogli zobaczyć go wszyscy",
    VIDEOHINT : "Filmik pomaga w lepszym zilustrowaniu taktyki i angażuje więcej osób"
  },
  MODULETACTICEDIT : {
    ALERT2 : "Taktyka została poprawnie wprowadzona!",
    ALERT2TITLE : "Zamierzasz opublikować nową wersję taktyki",
    ALERT3 : "Wybierz język, w którym zostanie opublikowana taktyka. Musisz wybrać przynajmniej jeden język.",
    ALTROMATERIALE : "Jeśli masz inne przydatne materiały, które lepiej zilustrują Twoją taktykę (rysunki, spostrzeżenia, specyfikacje techniczne...), prześlij je tutaj",
    MODIFICAIDEA : "Edytuj taktykę",
    NARRA : "Szczegółowo opisz taktykę",
    NARRAZIONE : "Taktyka",
    SCEGLISFIDE : "Wybierz tematy, których dotyczy taktyka",
    SELEZIONAMAPPA : "Wskaż miejsce, gdzie rozgrywa się taktyka",
    URLSITO : "Website"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Taktyka została poprawnie wprowadzona!",
    ALERT2TITLE : "Zamierzasz opublikować nową wersję taktyki",
    ALERT3 : "Wybierz język, w którym zostanie opublikowana taktyka. Musisz wybrać przynajmniej jeden język.",
    ALERT5 : "Wpisz zakładaną wartość, aby wskazać szacunkowy koszt taktyki",
    ALTROMATERIALE : "Jeśli masz inne przydatne materiały, które lepiej zilustrują Twoją taktykę (rysunki, spostrzeżenia, specyfikacje techniczne...), prześlij je tutaj",
    ELENCOIDEE : "Wszystkie taktyki",
    MODIFICAIDEA : "Edytuj taktykę",
    NARRA : "Szczegółowo opisz taktykę",
    NARRAZIONE : "Taktyka",
    NUOVAVERSIONEMESSAGGIO3 : "taktyki",
    SCEGLISFIDE : "Wybierz tematy, których dotyczy taktyka",
    SELEZIONAMAPPA : "",
    URLSITO : "Website"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "Społeczność",
    ISCRITTI : "{subscribers} użytkownicy ({profiles} profile)"
  },
  PARTIALSIDEE : {
    APPROVED : "Zatwierdzony",
    BUDGET : "Budżet: zł",
    CREATADA : "Stworzony przez",
    RANK01 : "Ranga 01",
    VOTATA : "PRZEGŁOSOWANY"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "<h4>Nie możesz zalogować się na swoje konto. Poproś o uwierzytelniony dostęp pod adresem <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>.",
    ALERT1TITLE : "<b>Grupa jest prywatna</b>",
    ALERT2 : "Będzie można się zalogować, gdy będzie działać!",
    ALERT2TITLE : "Faza w toku.",
    ALERT3 : "<h4>Nie możesz zalogować się na swoje konto. Poproś o uwierzytelniony dostęp pod adresem <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>.",
    ALERT3TITLE : "<b>Proces jest prywatny</b>",
    COMMENTI : "Komentarze",
    COMMUNITY : "Społeczność",
    CONCLUSA : "Zakończone",
    CONCLUSO : "Zakończone",
    DAATTIVARE : "Do uruchomienia",
    DRIVERAMBIENTE : "Czynniki: Środowisko",
    FASE : "Faza",
    INATTIVAZIONE : "W aktywacji",
    INCORSO : "W trakcie",
    PARTECIPA : "Dołącz do",
    PRIVATO : "Prywatny",
    PUBBLICO : "Publiczny",
    RICHIEDIACCESSO : "Poproś o dostęp",
    VALUTAZIONI : "Ewaluacje",
    VISUALIZZA : "Zagłosuj",
    VOTA : "Głosuj"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "<h4>We have sent a verification SMS code to your mobile phone.</h4>",
    ALERT1TITLE : "Info",
    ALERTPHONE1HTML : "The provided number is not registered with the organization.",
    ALERTPHONE1TITLE : "Unknown user",
    CAMBIAPASSWORD : "Change the password",
    CODICEOTP : "OTP code*",
    CONFERMA : "Confirm",
    CONFERMAPASSWORD : "Confirm the password",
    ERROROTPMAXCHAR : "Maximum number of digits enterable 6",
    ERRORPWEQUAL : "Passwords must be the same",
    ERRORPWLENGTH : "The password must be at least 6 characters long.",
    INSERISCICELLULARE : "Enter mobile",
    INSERISCIPASSWORD : "Enter the password",
    METODOCELLULARE : "Mobile",
    METODORECUPERO : "Recovery method",
    OTPDIMENTICATO : "You forgot to enter the OTP code.",
    PHONEERROR1 : "You forgot to enter the prefix.",
    PHONEERROR2 : "Have you by any chance forgotten to enter your mobile number?",
    PHONEERROR3 : "The number you entered is a bit strange... double-check it! :)",
    PROCEDI : "proceed",
    RECUPEROPASSWORD : "Recover your password",
    RECUPERORIUSCITO : "Password change completed successfully.",
    RICHIEDINUOVOCODICE1 : "<b>Didn't receive the code?</b> <br>Go back and make sure you entered your mobile number correctly. Otherwise,",
    RICHIEDINUOVOCODICE2 : "request a new code",
    RICHIEDINUOVOCODICE3 : "or write to us at",
    SCELTAMETODO : "or choose how to recover it",
    SCELTAMETODONECESSARIA : "The choice of the method is mandatory",
    VERIFICA1 : "Verify your phone number",
    VERIFICA2 : "We have sent you an SMS with a verification code OTP. Enter it to access your account and create your new password."
  },
  PHASEMODULECARDIDEA : {
    IDEE : "Proposale"
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "services"
  },
  PHASEMODULECARDSTORY : {
    IDEE : "Historie"
  },
  PHASEMODULECARDTACTIC : {
    IDEE : "Taktyki"
  },
  PORTAL : {
    CONTACTUS : "Contact us",
    DELIBERATIVESPACE : "Deliberative space",
    HOME : "Home",
    INSTRUMENTS : "The instruments",
    PROJECT : "The project",
    SUBSCRIBE : "Subscribe"
  },
  PRIVACY : {
    DIRITTO : "6. Right of access and control of personal data, right of objection",
    DIRITTODESCRIZIONE1 : "At any time, you have the right to confirm the existence of personal data concerning you, even if not yet recorded, and their communication in an intelligible form; you can obtain the indication of the origin of your personal data, the purposes and methods of their processing, the logic applied in case of processing carried out with the aid of electronic tools, the identification details of the Data Controller, the processors (if appointed), and the types of subjects to whom your personal data may be communicated, transferred, or who may become aware of it as designated or appointed representatives.",
    DIRITTODESCRIZIONE2 : "You also have the right to access any of your personal data processed by BiPart and may request the modification, correction, copy, restriction, or extension of sharing, anonymization, portability, or deletion (even partial) of your personal data. You also have the right to withdraw your consent to the processing of your data by the Owner at any time. You can exercise the aforementioned rights by sending a written request to the registered office of BiPart Impresa sociale srl (formerly BiPart Srls), located in Milan, via Metauro, 4 by registered mail or email to the following PEC address bipart@casellapec.com. With communication to be sent to the same address, you can also exercise the right to object at any time, for reasons related to your particular situation, to the processing (even only partial) of personal data concerning you, even if relevant to the purpose of collecting and processing personal data for sending advertising or direct sales material or for carrying out market research or commercial communication. From that date onwards, the Owner will refrain from further processing your personal data, saving the rights granted to him by law. For further information on your rights, we invite you to visit the website of the Privacy Guarantor at www.garanteprivacy.it.",
    FINALITA : "3. Purposes of processing your data",
    FINALITADESCRIZIONE1 : "The processing of your data is intended to allow the use of the Platform and the provision of all services connected and/or instrumental to it or all activities related to the development and implementation of participatory budgeting projects and participation in general with the subjects, public and private, involved in such projects managed through the Platform by BiPart; the processing therefore finds its legal basis in the agreements concluded from time to time by the Owner with the subjects (public and private) involved in the participatory budgeting projects managed by BiPart, also through the Platform.",
    FINALITADESCRIZIONE2 : "For the achievement of these purposes, and merely by way of example, your personal data may be used and communicated to third parties for registration processing, for the verification of the correctness of the entered personal data, for the execution and management of the service, for data entry, for the statistical processing of participation in the Platform (use of socio-demographic and geographical data of the registered users). In addition, your personal data will be processed to fulfill legal, accounting, and tax obligations. \nTherefore, please note that the failure to provide personal data for these purposes may imply the impossibility – total or partial – for BiPart to provide the requested service and/or perform the related connected and/or instrumental services.",
    FINALITADESCRIZIONE3 : "Your personal data will be kept by BiPart until your account is open. BiPart, from the deactivation of the account, will delete your personal data, having the right to make them anonymous and keep them only for the creation of a user model (which takes into account only gender, age, and, if available, geographical area) useful for scientific and statistical research related to the dissemination and development of participatory budgeting practices.",
    INTRO : "1. Introduction",
    INTRODESCRIZIONE1 : "BiPart Impresa sociale srl (formerly BiPart Srls), VAT and tax code 094444700968, with registered office in Milan, via Metauro n. 4, as data controller (hereinafter also \"BiPart\" or \"Holder\"), pursuant to the provisions of Legislative Decree 30 June 2003 n. 196 and subsequent amendments (hereinafter \"Privacy Code\") and EU Regulation no. 2016/679 (hereinafter \"GDPR\"), intends to inform you about the methods and purposes of the processing of personal data provided by you.",
    INTRODESCRIZIONE2 : "Registered users (hereinafter \"Members\") have the opportunity to participate in activities made possible by the Platform itself (reports, proposals, priorities, votes, comments, sharing) as well as various participatory budgeting projects, also interacting with each other through the exchange of knowledge, proposals, and ideas, both privately and publicly. The content and data on some of the services offered with the Platform are therefore viewable by both Members and non-registered individuals (the \"Visitors\"). By using the Platform, you give your specific, prior, free, and optional consent to the processing of your data as indicated in this notice.",
    MANIFESTO : "7. Expression of consent",
    MANIFESTODESCRIZIONE1 : "If you agree with the terms of this notice, BiPart asks you to authorize and consent to the processing of your personal data in the ways and for the purposes indicated here, by express approval. As a clear sign of your consent, please check this box.",
    MODALITA : "4. Processing methods and security of your data",
    MODALITADESCRIZIONE1 : "Your personal data will be collected and processed primarily with electronic and computerized tools and stored on both electronic and paper supports and any other suitable support by subjects expressly authorized by the Data Controller, all duly trained in the security of personal data and the right to privacy. According to the regulations indicated by the Privacy Code and the GDPR, this processing will be based on the principles of fairness, lawfulness, transparency, and the protection of your confidentiality and rights.",
    MODALITADESCRIZIONE2 : "To prevent unauthorized access or disclosure and ensure the appropriate use of your personal data, BiPart has implemented reasonable and appropriate administrative, technical, and physical procedures that will be constantly adapted to the regulations in force regarding the protection of personal data. In order to ensure compliance with the standards of the Privacy Code and the GDPR, the processing of your personal data will be carried out exclusively in countries belonging to the European Union.",
    MODALITADESCRIZIONE3 : "BiPart undertakes to adapt its privacy policy to legal and regulatory provisions, therefore, it is possible that this information may undergo changes over time. In the event of significant changes to our privacy policy that significantly alter our privacy practices, BiPart will take care to notify you through other means, such as sending an email or posting a notice on our website and/or social media pages before the changes take effect.",
    SOGGETTI : "5. Entities to whom personal data may be communicated",
    SOGGETTIDESCRIZIONE1 : "Your personal data may be subject to communication or dissemination:",
    SOGGETTIDESCRIZIONE2 : "<li>to Public Administrations, Local Authorities and more generally to subjects, even private, who adhere to or take part in participatory budgeting projects managed by BiPart also through the Platform;</li><li>to authorized subjects who provide administrative, technical/informatics, tax, accounting, financial, and legal assistance and consultancy;</li><li>to third parties in compliance with legal obligations.</li>",
    SOGGETTIDESCRIZIONE3 : "The aforementioned subjects will operate as Data Processors or, in any case, as subjects authorized to process your personal data within the limits authorized by you. To the authorized subjects and Data Processors, if appointed, BiPart will provide appropriate operational instructions to ensure the confidentiality and security of your personal data.",
    SOTTOTITOLO : "Processing of personal data",
    TIPOLOGIA : "2. Type of data collected",
    TIPOLOGIADESCRIZIONE1 : "To create an active account on the platform, you must provide some personal data, including your name, surname, email address, and password (essential data). The Platform allows you to use the profile implementation options by integrating and providing additional information about yourself that may include, for example, but not limited to, indicating the geographical area or specifying the location where you reside, the title of study, or a photo of yourself. Similarly, it is possible that in using the Platform, you may be allowed to share your address book and contacts. It is not necessary for you to publish or enter this personal data; however, please note that it is your choice to expressly choose whether, once entered, this data may be publicly visible to other Members or not.",
    TIPOLOGIADESCRIZIONE2 : "It is therefore exclusively up to you to choose to communicate and enter into the Platform, and in your profile, sensitive information (suitable for revealing racial and ethnic origin, religious, philosophical, or other beliefs, political opinions, membership in parties, unions, associations or organizations of a religious, philosophical, political, or union nature, as well as personal data suitable for revealing health status and sexual life), as well as to make them public. We therefore invite you not to publish data that you do not want to make publicly available, specifying that by entering such data into your account and in the Platform, you authorize its processing.",
    TITOLO : "Privacy Policy"
  },
  PROCESSABOUT : {
    FASE : "Faza",
    TITOLO : "Proces partycypacyjny"
  },
  PROCESSCONTATTI : {
    CONTATTI : "Kontakty"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "Dostępny budżet:",
    BUDGETPROPOSTO : "Proponowany budżet:",
    CONCLUSA : "Zakończone",
    DAATTIVARE : "Do uruchomienia",
    FASIPROCESSO : "Etapy procesu",
    INCORSO : "W trakcie realizacji",
    ITUOIVOTI : "Twoje wybory",
    PROGRESS : "Progres"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : "Pytania i odpowiedzi"
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : "Wytyczne"
  },
  PROCESSLIST : {
    BUDGET : "Budżet",
    DATAINIZIO : "Data rozpoczęcia",
    DATAINS : "Data wejścia",
    PARTECIPANTI : "Liczba uczestników",
    PROCESSI : "procesy",
    TITOLO : "Procesy partycypacyjne"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "Wszystkie tematy, które wymagają dyskusji i znalezienia rozwiązań w ramach społeczności",
    NOTEMATICHE : "Nie ma żadnych tematów",
    OBIETTIVI : "Cele"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "Społeczność",
    GRUPPI : "grupy",
    TUTTI : "Wszystkie grupy/klasy społeczne."
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "Kontekst organizacji",
    INFO : "Informacje na temat obszaru(ów), na który(e) proces ma wpływ",
    NOSCENARI : "Nie ma żadnych scenariuszy"
  },
  PROCESSMENU : {
    ABOUT : "Proces partycypacyjny",
    ASSISTENZA : "Help Desk",
    BACK : "Wstecz",
    COMMUNITY : "Społeczność",
    FAQ : "FAQ",
    ISTRUZIONI : "Wytyczne",
    NARRAZIONE : "Więcej informacji",
    POP1 : "Proces",
    POP1DESCRIPTION : "Informacja o procesie"
  },
  PROCESSPHASE : {
    ALERTBOZZA : "Pracujesz nad wersją roboczą (draftem). Znajdziesz do niej dostęp w przestrzeni osobistej",
    BREADCRUMBTITLE : "Phase",
    COMMENTIMENONUMEROSI : "Less commented",
    COMMENTIPIUNUMEROSI : "Più commentate",
    CONTATOREMODULI : "obszary",
    IDEE : "Proposali",
    INFORMAZIONI : "informacja",
    NAVIGATUTTELEPROPOSTE : "Przejrzyj proposale i zagłosuj na swoje ulubione.",
    NONCISONOPROPOSTE : "Nie ma żadnych proposali",
    PS : "Przestrzeń osobista",
    SCEGLIDOVEPARTECIPARE : "Wybierz, w co się włączasz"
  },
  PS : {
    ALERTMODIFICADISABILITATA : "<h4>Nie ma możliwości edycji profilu.</h4>.",
    ALERTMODIFICADISABILITATATITLE : "<b>Edytowanie Twojego profilu jest wyłączone</b>.",
    ANNUALINCOME : "Roczny dochód",
    ATTEGGIAMENTO : "Postawa",
    BIOGRAPHY : "Biografia",
    BUDGET : "Budżet",
    BUDGETTODONATE : "Środki do dyspozycji",
    COMPORTAMENTO : "Zachowanie",
    EDU : "Edukacja",
    EDULAV : "Edukacja i praca",
    FEMMINA : "Kobieta",
    LAV : "Praca",
    MASCHIO : "Mężczyzna",
    MODIFICA : "Edit profile",
    NS : "Nie określono",
    POLVIS : "Wizja polityczna",
    PROFILE : "Twój profil osobisty",
    PS : "Przestrzeń osobista"
  },
  PSACCOUNT : {
    ANONIMO : "Użytkownik anonimowy",
    AS : "Ustawienia konta",
    CITIZENSHIP : "obywatelstwo",
    COGNOME : "Nazwisko",
    COGNOMENONPRESENTE : "Nazwisko nie występuje",
    ELIMINAACCOUNT : "<h4> W celu usunięcia informacji o Twoim koncie, napisz do nas na adres: <a href=\"mailto:support@bipart.it\">support@bipart.it</a>.</h4>",
    ELIMINAACCOUNTTITLE : "<b>Czy chcesz usunąć swoje konto? </b>.",
    EMAIL : "E-mail",
    EMAILNONPRESENTE : "E-mail nie występuje",
    NOME : "Imię",
    NOMENONPRESENTE : "Imię nie występuje",
    PASSWORD : "Hasło",
    PHONE : "Telefon komórkowy",
    PHONENONPRESENTE : "Nr telefonu nie występuje",
    PRIVATO : "prywatny",
    PS : "Strefa użytkownika",
    PUBBLICO : "publiczne",
    RESIDENCECITY : "Miasto zamieszkania",
    TAXCODE : "NIP",
    TAXCODENONPRESENTE : "NIP nie występuje",
    TUOPROFILO : "Twój profil jest",
    USERNAME : "Nazwa użytkownika",
    USERNAMENONPRESENTE : "Nazwa użytkownika nie występuje"
  },
  PSACTIVITIES : {
    ALERTDETAIL : "<h4>W tej sekcji znajdziesz swoje proposale oraz proposale, na które głosowałeś.</h4>.",
    ALERTINFO : "<b>Informacje</b>",
    ATTIVITA : "Działania",
    BUDGETDONATO : "Przekazany budżet",
    FOLLOWERS : "Obserwujący",
    IDEESEGUITE : "Obserwowane proposale",
    IDEEVOTATE : "Przegłosowane wnioski/proposale",
    INBOZZA : "Szkic",
    MODIFICAVOTO : "Zmień głos",
    NOPROCESSI : "Nie przystąpiłeś jeszcze do żadnego procesu partycypacyjnego!",
    NOPROCESSIATTIVI : "No active processes",
    PS : "Przestrzeń osobista",
    PUBBLICATA : "Opublikowany",
    TUEPROPOSTE : "Twój wkład",
    TUOIPROCESSI : "Twoje procesy partycypacyjne",
    TUOIVOTI : "Twoje głosy",
    VISUALIZZA : "Widok",
    VOTIESPRESSI : "Oddane głosy"
  },
  PSEDIT : {
    AS : "Ustawienia konta",
    CONFERMAPASSWORD : "Potwierdź hasło",
    DRAGANDDROP : "Przeciągnij i upuść, aby załadować swój awatar (maksymalny rozmiar 5 MB)",
    ERRORE : "Uwaga!",
    ERROREPASS : "Niezgodność hasła",
    FORMSUPPORTATI : "Obsługiwane formaty to JPG, GIF, PNG",
    MODAVATAR : "Edytuj avatar",
    MODIFICADATI : "Edytuj swoje dane",
    MODOK : "Profil użytkownika został pomyślnie edytowany",
    MODPASS : "Zmień hasło",
    NUOVAPASSWORD : "Nowe hasło",
    PRIVACY : "Polityka prywatności",
    PROFILOPUBBLICO : "Profil publiczny",
    PS : "Przestrzeń prywatna",
    SALVA : "Zapisz",
    SUCCESSO : "Zmiana hasła zakończona sukcesem"
  },
  PSEDIT2 : {
    AS : "Ustawienia Konta",
    ATTEGGIAMENTO : "Nastawienie",
    BIOGRAFIA : "Biografia",
    BUDGET : "Budżet",
    BUDGETDADONARE : "Budżet do dyspozycji",
    COMPORTAMENTO : "Zachowanie",
    EDU : "Edukacja",
    EDULAV : "Edukacja i praca",
    INFOVARIE : "Dodatkowe informacje",
    LAV : "Praca",
    PS : "Przestrzeń osobista",
    REDDITO : "Dochód roczny",
    SALVA : "Zapisz",
    VISIONEPOLITICA : "Wizja polityczna"
  },
  QUICKPANEL : {
    VOTE: "Głos {n}",
    VOTEDON: "Data zagłosowania {n}",
    EDITVOTE: "Wycofaj swój głos",
    DONATION: "Donation"
  },
  QUICKUSER : {
    ACCOUNT : "Konto",
    AGGIORNAPROFILOEPRIVACY : "Zaktualizuj swój profil i politykę prywatności",
    IDEEVOTATE : "Wszystkie pomysły, na które zagłosowałeś",
    PROCESSIPARTECIPATIVI : "Procesy partycypacyjne",
    SALUTO : "Cześć",
    SIGNOUT : "Wyloguj się",
    VOTI : "Twoje głosy"
  },
  REGISTER : {
    ACCETTOLA : "Przeczytałem i akceptuję",
    ATTENZIONE : "Uwaga",
    BACCEDI : "Zarejestruj się",
    BACCETTO : "Przeczytałem i akceptuję",
    BREGISTRA : "Rejestracja",
    COGNOMEDIMENTICATO : "Zapomniałeś wpisać swoje nazwisko",
    GIAACCOUNT : "Masz już konto?",
    MAILDIMENTICATA : "Zapomniałeś wpisać swój e-mail",
    MAILNONVALIDA : "Nieprawidłowy adres e-mail.",
    NEWSLETTER : "Informuj mnie na bieżąco informowany o projektach w BiPart.",
    NOMEDIMENTICATO : "Zapomniałeś wpisać swoje imię",
    PASSWORDDIMENTICATA : "Zapomniałeś wpisać hasło",
    PLACEHOLDERCOGNOME : "Nazwisko",
    PLACEHOLDEREMAIL : "E-mail",
    PLACEHOLDERNOME : "Imię",
    PLACEHOLDERPASSWORD : "Hasło",
    PLACEHOLDERUTENTE : "Nazwa użytkownika",
    PRIVACY : "Polityka prywatności",
    TERMINICONDIZIONI : "Zasady i warunki użytkowania",
    USERNAMEDIMENTICATO : "Zapomniałeś wpisać swoją nazwę użytkownika",
    USERNAMEFORBIDDENCHARACTERS : "Nazwa użytkownika nie może zawierać następujących znaków: @",
    USERNAMEMINLENGTH : "Nazwa użytkownika musi zawierać przynajmniej 3 znaki"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "Akceptuję",
    ACCETTOLA : "Zgoda",
    BCONFERMA : "Potwierdź",
    DETTMEX : "Wysłaliśmy wiadomość* na Twój e-mail.<br><span class=\"font-weight-bolder\">Potwierdź ją</span>, aby dołączyć do organizacji.</p><p class=\"font-weight-bolder\">* Uwaga! Sprawdź również folder spamu.",
    ERR1 : "Nie wprowadziłeś wymaganych danych. Proszę spróbować ponownie lub napisać do <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR2 : "Ups... coś nie zadziałało; Kod statusu:",
    GIAACCOUNT : "Masz już konto?",
    GRAZIE : "Dziękujemy za dołączenie do naszej społeczności",
    ISCRIVITIPROCESSO : "Zapisz się do procesu",
    NEWSLETTER : "Powiadamiaj mnie o projektach w BiPart.",
    PRIVACY : "Polityka prywatności",
    REQUIREDFIELD : "Pole wymagane",
    TERMINI : "Zasady i warunki",
    TITLE2 : "Uwaga",
    TITLE3 : "Błąd",
    VALIDEMAIL : "Musi to być prawidłowy adres e-mail"
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "Akceptuję",
    BACCEDI : "ZALOGUJ SIĘ",
    BACCETTO : "Akceptuję",
    BCHIUDI : "Zamknij",
    BREGISTRA : "ZAREJESTRUJ SIĘ",
    COGNOMEDIMENTICATO : "Zapomniałeś wpisać swoje nazwisko.",
    ERR1 : "Ups... coś nie zadziałało; Kod statusu:",
    FAIGIAPARTE : "Masz już założone konto?",
    ISCRIVITIPROCESSO : "Zapisz się do organizacji i do procesu",
    MAILNONVALIDA : "Nieprawidłowy adres e-mail.",
    NEWSLETTER : "Chcę być na bieżąco informowany o projektach i inicjatywach BiPart.",
    NOMEDIMENTICATO : "Zapomniałeś wpisać swoje imię",
    PASSWORDDIMENTICATA : "Zapomniałeś wpisać hasło",
    PRIVACY : "Polityka prywatności",
    TERMINICONDIZIONI : "Zasady i warunki użytkowania",
    TITLE1 : "Błąd",
    USERNAMEDIMENTICATO : "Zapomniałeś wpisać swoją nazwę użytkownika"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "Udane logowanie",
    ACCETTO : "Przeczytałem i akceptuję",
    ACCETTOLA : "Przeczytałem i akceptuję",
    AUTORIZZATO : "Masz upoważnienie do partycypacji  w naszej społeczności",
    BCONFERMA : "Potwierdzenie",
    DETTMEX : "Wysłaliśmy wiadomość* na Twoją skrzynkę e-mail.<br><span class=\"font-weight-bolder\">Potwierdź ją</span>, aby dołączyć do organizacji.</p><p class=\"font-weight-bolder\">* Bądź ostrożny! Sprawdź również folder spamu.",
    ERR1 : "Nie wprowadzono wymaganych danych. Proszę spróbować ponownie lub napisać do <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR3 : "Ups... Wystąpił nieoczekiwany błąd.",
    ERR4 : "Nieprawidłowy e-mail",
    ERR5 : "Rejestracja i akceptacja \"Polityki prywatności\" i \"Regulaminu\".",
    ES : "Ex: Faenza Uczestnictwo 2020/21",
    GIAACCOUNT : "Masz już konto?",
    GRAZIE : "Dziękujemy za dołączenie do naszej społeczności",
    HTMLMAIL : "Tutaj znajdziesz pocztę html",
    ISCRIVITIPROCESSO : "Dołącz do procesu",
    NEWSLETTER : "Chcę być na bieżąco z projektami BiPart.",
    NIENTEACCOUNT : "Nie masz jeszcze konta?",
    NOME : "Nazwa (opcjonalnie)",
    ORGANIZATION : "BiPart",
    PARTECIPAPROCESSO : "Czy chcesz wziąć udział w tym procesie?",
    PRIVACY : "Polityka prywatności",
    PROPOSTE : "Aby zgłaszać, komentować, mieć dostęp do oceny, głosować, musisz się zarejestrować.",
    QUIMAIL : "Tutaj znajdziesz wysłany email html",
    REGISTRATI : "Rejestracja i akceptacja \"Polityki prywatności\" oraz \"Regulaminu\"",
    TERMINI : "Zasady i warunki",
    TITLE1 : "Gratulacje",
    TITLE2 : "Uwaga",
    TITLE3 : "Błąd",
    TITOLO : "BiPart"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "Zarejestruj się",
    ACCETTO : "Przeczytałem i akceptuję",
    ACCETTOLA : "Przeczytałem i akceptuję",
    BPROSEGUI : "Kontynuuj",
    CAMBIAPASS : "Zmiana hasła",
    ERR3 : "Ups... Wystąpił nieoczekiwany błąd.",
    ERR4 : "Nieprawidłowy adres e-mail",
    ERR5 : "Nieprawidłowe dane uwierzytelniające",
    INSERISCIMAIL : "Wpisz swój adres e-mail",
    ISCRIVITIPROCESSO : "Dołącz do procesu",
    MEX1 : "Rejestracja i akceptacja \"Polityki prywatności\" oraz \"Regulaminu\"",
    MEX2 : "Uwierzytelnienie pomyślne",
    MEXLOGIN : "<b>Witamy w BiPart,</b><br>Sieci społecznej zaangażowania obywatelskiego.<br>Twoje dane będą traktowane z pełnym poszanowaniem włoskich i europejskich przepisów i z jeszcze większą dbałością o ochronę Twoich opinii i decyzji.<br><b>Jeśli chcesz kontynuować, przeczytaj i zaakceptuj dwa poniższe dokumenty.</b>",
    NIENTEACCOUNT : "Nie masz jeszcze konta?",
    NONRICORDIPASS : "Nie pamiętasz swojego hasła?",
    NOTIFICHE : "*Jeśli chcesz otrzymywać powiadomienia o postępach w realizacji złożonych proposali.",
    PRIVACY : "Polityka prywatności",
    REGISTRATI : "Rejestracja",
    TERMINI : "Warunki świadczenia usług",
    TERMINICONDIZIONI : "Aby kontynuować, prosimy o zapoznanie się z <a href=\"https://bipart.it/terms\" target=\"blank\">Warunkami świadczenia usług </a><br> oraz <a href=\"https://bipart.it/privacy\" target=\"blank\">Polityką prywatności.</a>.",
    TITLE1 : "Sukces",
    TITLE2 : "Uwaga",
    TITLE3 : "Błąd",
    UTENTEAUTENTICATO : "-- Użytkownik uwierzytelniony --"
  },
  SERVICEDETAIL : {
    ALERT1 : "You must enter at least one mandatory field among the state or the score assigned to the service",
    ALERT13 : "You have already entered a detailed evaluation for this service",
    ALERT14 : "The service has already been evaluated",
    ALERT3TITLE : "Thank you for voting for this service",
    ALERT4 : "Browse through services and choose your other favorites",
    ALERT5 : "You can continue to donate for other services by choosing how to distribute your available budget.",
    ALERT5TITLE : "Thank you for supporting the service!",
    ALERT6TITLE : "You are following this service!",
    ALERT7TITLE : "You are no longer following this service",
    ALERT8TITLE : "Are you sure you want to delete this service?",
    ALERT9 : "The service has been deleted",
    ALERT9TITLE : "Service deletion",
    ALERTBOZZA : "You have a draft version of your service. Remember to publish it",
    ALERTPUBBLICATA : "Service in the review phase. Collaborate in its development",
    AUTORE : "Contact",
    CANVERSION2 : "You are creating version {n} of your service",
    CLICCAILPULSANTE : "Click the button if you want to rate this service",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the service does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the service because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Thank you for choosing this service!",
    GRAZIESOSTEGNO : "Thank you for supporting this service!",
    GRAZIESOSTEGNOANCORA : "Thank you for continuing to support this service!",
    IDEAVOTATA : "Voted service",
    INSERISCICIFRA : "Enter the amount you want to donate for this service",
    NARRAZIONE : "The service",
    PREFERENZEPERPREMIARE : "preferences to reward the best services!",
    SOSTIENIALTREIDEE : "You can continue to donate for other services, choosing how to distribute your budget.",
    TOGLIEREPREFERENZA : "If you change your mind, you can remove your preference and vote for other services.",
    URLSITO : "Website",
    VALUTARIDUZIONEDONAZIONI : "Evaluate whether to reduce donations already made for other services",
    VERSIONIPROPOSTA : "Service versions:",
    WALLETSOSTIENIIDEA : "Support this service. Make a donation!"
  },
  SERVICELIST : {
    ALERTBOZZA : "You have a draft version of your service. You can retrieve it from your",
    ALERTVERSIONING2 : "You can improve a service by providing or collecting insights and describing it through new, increasingly updated versions",
    CREAIDEA : "Publish your service",
    ELENCOIDEE : "All services",
    IDEAVOTATA : "You have voted for this service!",
    IDEE : "Services",
    NONCISONOIDEE : "There is still no service. Post yours!",
    VOTAZIONEATTIVA2 : "View all services and express your preferences",
    VOTAZIONEATTIVAUTENTEANONIMO : "Sign up, if you are enabled, you can vote for your favorite services"
  },
  STORYDETAIL : {
    ALERT1 : "Musisz wprowadzić przynajmniej jedno z obowiązkowych pól pomiędzy statusem lub oceną przypisaną do historii",
    ALERT13 : "Wprowadziłeś już szczegółową ocenę dla tej historii",
    ALERT14 : "Ta historia została już oceniona",
    ALERT3TITLE : "Dziękujemy za oddanie głosu na tę historię",
    ALERT4 : "Przeglądaj historie i wybieraj te, które najbardziej Ci odpowiadają",
    ALERT5 : "Możesz nadal przekazywać środki na inne historie, wybierając sposób rozdysponowania swojego budżetu.",
    ALERT5TITLE : "Dziękujemy za wsparcie dla tej historii",
    ALERT6TITLE : "Obserwujesz tę historię",
    ALERT7TITLE : "Już nie obserwujesz tej historii",
    ALERT8TITLE : "Jesteś pewien, że chcesz usunąć tę historię?",
    ALERT9 : "Historia została usunięta",
    ALERT9TITLE : "Usuwanie historii",
    ALERTBOZZA : "Zapisałeś szkic swojej historii. Nie zapomnij go opublikować",
    ALERTPUBBLICATA : "Historia w trakcie poprawiania. Pomóż ją ulepszyć",
    AUTORE : "Written by",
    CANVERSION2 : "Tworzysz wersję numer {n} swojej historii.",
    CLICCAILPULSANTE : "Kliknij na przycisk, jeśli chcesz zagłosować na tę historię.",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the story does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the story because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Dziękujemy za wybranie tej historii!",
    GRAZIESOSTEGNO : "Dziękujemy za wsparcie dla tej historii",
    GRAZIESOSTEGNOANCORA : "Dziękujemy za stałe wsparcie dla tej historii!",
    IDEAVOTATA : "Wybrana historia",
    INSERISCICIFRA : "Wpisz kwotę, którą chcesz przekazać na tę historię.",
    NARRAZIONE : "Historia",
    PREFERENZEPERPREMIARE : "zagłosuj, żeby nagrodzić najlepsze historie!",
    SOSTIENIALTREIDEE : "Możesz nadal wspierać inne historie, wybierając sposób rozdysponowania pozostałego budżetu.",
    TOGLIEREPREFERENZA : "Jeśli zmieniłeś zdanie, możesz usunąć swoje preferencje i zagłosować na inne historie.",
    URLSITO : "Website",
    VALUTARIDUZIONEDONAZIONI : "Rozważ zmniejszenie już przekazanych darowizn na inne historie",
    VERSIONIPROPOSTA : "Wersje historii",
    WALLETSOSTIENIIDEA : "Wesprzyj tę historię. Przekaż darowiznę!"
  },
  STORYLIST : {
    ALERTBOZZA : "Masz zapisany szkic swojej historii. Możesz go odzyskać",
    ALERTVERSIONING2 : "Możesz uaktualnić historie o nowe doświadczenia i wspomnienia",
    CREAIDEA : "Opowiedz swoją historię",
    ELENCOIDEE : "Wszystkie historie",
    IDEAVOTATA : "Zagłosowałeś na tę historię",
    IDEE : "Historie",
    NONCISONOIDEE : "Żadna historia nie została jeszcze opublikowana. Opowiedz nam swoją!",
    VOTAZIONEATTIVA2 : "Przejrzyj wszystkie historie, na które możesz zagłosować i wybierz te, które najbardziej Ci odpowiadają.",
    VOTAZIONEATTIVAUTENTEANONIMO : "Zarejestruj się, po uzyskaniu uprawnień będziesz mógł głosować na swoje ulubione historie."
  },
  TACTICDETAIL : {
    ALERT1 : "Wypełnij przynajmniej jedno z obowiązkowych pól pomiędzy statusem lub punktacją przypisaną do taktyki.",
    ALERT13 : "Wprowadziłeś już szczegółową ocenę dla tej taktyki",
    ALERT14 : "Taktyka została już oceniona",
    ALERT3TITLE : "Dziękujemy za oddanie głosu na taktykę",
    ALERT4 : "Przejrzyj taktyki i wybierz tę, na którą chcesz zagłosować",
    ALERT5 : "Możesz nadal przekazywać darowizny na rzecz innych taktyk, wybierając sposób alokacji swojego budżetu",
    ALERT5TITLE : "Dziękujemy za wspieranie tej taktyki",
    ALERT6TITLE : "Śledzisz tę taktykę!",
    ALERT7TITLE : "Nie śledzisz już tej taktyki",
    ALERT8TITLE : "Jesteś pewien, że chcesz usunąć tę taktykę?",
    ALERT9 : "Ta taktyka została usunięta",
    ALERT9TITLE : "Usuwanie taktyki",
    ALERTBOZZA : "Masz zapisany szkic tej taktyki. Pamiętaj, aby go opublikować",
    ALERTPUBBLICATA : "Taktyka w trakcie oceny. Pomóż ją ulepszyć",
    AUTORE : "",
    CANVERSION2 : "Tworzysz wersję {n} taktyki",
    CLICCAILPULSANTE : "Kliknij przycisk, jeśli chcesz oddać głos na tę taktykę",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the tactic does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the tactic because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Dziękujemy za wybranie tej taktyki!",
    GRAZIESOSTEGNO : "Dziękujemy za wsparcie dla tej taktyki!",
    GRAZIESOSTEGNOANCORA : "Dziękujemy za stałe wsparcie dla tej taktyki!",
    IDEAVOTATA : "Wybrane taktyki",
    INSERISCICIFRA : "Wpisz kwotę, którą chciałbyś przekazać na rzecz tej taktyki",
    NARRAZIONE : "Taktyka",
    PREFERENZEPERPREMIARE : "preferencje w zakresie nagradzania najlepszych taktyk",
    SOSTIENIALTREIDEE : "Możesz kontynuować przekazywanie środków na inne taktyki, wybierając sposób alokacji budżetu",
    TOGLIEREPREFERENZA : "Jeśli zmieniłeś zdanie, możesz usunąć swoją preferencję i przypisać ją do innej taktyki",
    URLSITO : "Website",
    VALUTARIDUZIONEDONAZIONI : "Rozważ zmniejszenie już przekazanych darowizn na inne taktyki",
    VERSIONIPROPOSTA : "Wersje taktyk:",
    WALLETSOSTIENIIDEA : "Wesprzyj tę taktykę. Przekaż darowiznę!"
  },
  TACTICLIST : {
    ALERTBOZZA : "Masz zapisany szkic swojej taktyki. Możesz go odzyskać",
    ALERTVERSIONING2 : "możliwe jest ulepszanie taktyki poprzez dostarczanie lub zbieranie pomysłów oraz publikowanie nowych i zaktualizowanych wersji",
    CREAIDEA : "Wpisz taktykę",
    ELENCOIDEE : "Wszystkie taktyki",
    IDEAVOTATA : "Zagłosowałeś na tę taktykę!",
    IDEE : "Taktyki",
    NONCISONOIDEE : "Żadna taktyka nie została jeszcze opublikowana. Bądź pierwszy!",
    VOTAZIONEATTIVA2 : "Przeglądaj taktyki i głosuj na swoich faworytów",
    VOTAZIONEATTIVAUTENTEANONIMO : "Zarejestruj się, jeśli jesteś uprawniony do głosowania, będziesz mógł oddać głos na swoją ulubioną taktykę."
  },
  TERMS : {
    ATTIVITA : "3. ACTIVITIES",
    ATTIVITADESCR1 : "Subject of the User's activities is any initiative aimed at contributing to the life, development, and well-being of their city or organization;\nBy way of example, activities may include: inserting ideas and reports, discussion, planning, sharing ideas, links, and documents, voting on ideas and projects, and public themes, etc.\nThe activity carried out must adhere to topics of public relevance and comply with current regulations.\nThe User declares to be the owner of every right possibly connected to their activity (by way of example and not exhaustive: ideas, projects, photographs, audio/video recordings, etc.).\nBiPart reserves the right to unilaterally remove any element introduced by the User from the BiPart Platform, as well as to modify or obscure non-essential elements if it could harm the rights of third parties or a community of people.",
    COMUNICAZIONI : "7. COMMUNICATIONS",
    COMUNICAZIONIDESCR1 : "The user acknowledges and accepts that all eventual communications, notifications, information, and any documentation related to operations carried out and referred to the use of the \"BiPart Platform\" will be sent to the email address or to any additional contact details provided by the user during the registration procedure. BiPart is not responsible for direct, indirect, or consequential damages suffered by the user or third parties as a result of activities within the \"BiPart Platform\" and/or the use of Communication Services and/or damages of any kind or nature connected with said activities. For this purpose, the user declares to indemnify BiPart from any form of liability. The material sent by the user will not be returned. BiPart reserves the right to modify the terms, conditions, and communications under which the \"BiPart Platform\" is offered, and users who are already registered are required to comply with them.",
    LIMITAZIONI : "6. LIMITATIONS OF LIABILITY",
    LIMITAZIONIDESCR1 : "BiPart disclaims any responsibility for direct, indirect, or consequential damages suffered by the user or third parties due to activities within the \"BiPart Platform\" and/or the use of Communication Services and/or damages of any kind or nature connected with said activities. For this purpose, the user declares to indemnify BiPart from any form of liability. The material sent by the user will not be returned.",
    MODIFICA : "8. MODIFICATION OF USAGE TERMS",
    MODIFICADESCR1 : "BiPart reserves the right to modify the terms, conditions, and communications under which the \"BiPart Platform\" is offered, and users who are already registered are required to comply with them.",
    OGGETTO : "1. OBJECT AND DEFINITIONS",
    OGGETTODESCR1 : "These Terms of Use (ToU) regulate and govern the use of the BiPart Platform by the user as well as the user's responsibilities regarding the use of the aforementioned platform. BiPart refers to a platform through which the user can participate in activities made possible by the platform itself (reports, proposals, priorities, votes, comments, sharing, etc.). This platform is the exclusive property of BiPart Impresa sociale srl (formerly BiPart Srls) (BiPart) with headquarters in Milan, Via Metauro, 4 (tax code and VAT number: 09444470968). The term \"User\" refers to the individual registered under Article 2 who interacts with the participatory tools made available on the BiPart Platform. These usage clauses apply to every type of activity carried out by the user.",
    REGISTRAZIONE : "2. REGISTRATION",
    REGISTRAZIONEDESCR1 : "To participate as indicated in the previous article, the user must necessarily register through the appropriate registration procedure. The user assumes all civil and criminal liability for any falsity or incorrectness of the information and data communicated.",
    RESPONSABILITA : "4. USER'S RESPONSIBILITY",
    RESPONSABILITADESCR1 : "Platform usage conditions",
    RESPONSABILITADESCR2 : "<li>covered by copyright and of which it is not itself the owner;</li><li>contrary to morality and public order, or capable of disturbing public or private peace or causing offense, or direct or indirect harm to anyone or to a specific category of people (for example, the insertion of materials or extracts of materials that may offend the sensibility of ethnic or religious groups, etc.);</li><li>contrary to the right to privacy of third parties; • harmful to the honor, decorum, or reputation of third parties; \n</li><li> in any case contrary to the law.</li>",
    SOTTOTITOLO : "Terms and conditions",
    TITOLO : "Terms and conditions",
    UTILIZZO : "USE",
    UTILIZZODESCR1 : "The \"BiPart Platform\" may contain additional communication services such as bulletin boards, chat areas, newsgroups, personal web pages, calendars, and/or other services created to enable the user to communicate with other subjects or with a group (collectively, \"Communication Services\"). The User agrees to use the Communication Services only to send, submit, and receive appropriate messages and material related to the particular Communication Service. For example, and not as a limitation, during the use of a Communication Service, the user agrees to, and undertakes to, not:",
    UTILIZZODESCR2 : "<li>publish, send, distribute, or disseminate names, materials, or information that are inappropriate, profane, defamatory, violate the rights of others, obscene, indecent, or illegal;</li><li>publish files containing software or other material protected by intellectual property laws (or privacy or publicity rights) unless the user owns those rights or has control over them or has received all necessary consents;</li><li>publish files that contain viruses, damaged files, or other similar software or programs that could damage the operation of other computers;</li><li>advertise or offer for sale or buy any product or service for any commercial purpose, unless the Communication Service specifically allows such messages; </li><li>conduct or forward surveys, content, pyramid schemes, or chain letters;</li><li>download any file sent by another user of a Communication Service that the user knows or reasonably should know cannot be legally distributed in that manner;</li><li>falsify or delete any author attribution, legal content communications, or other appropriate communications or designations of ownership or origin or source of software or other material contained in a file that is uploaded;</li><li>restrict or inhibit other users from using and enjoying Communication Services;</li><li>violate codes of conduct or other usage terms that may apply to any particular Communication Service;</li><li>collect or compile information about others, including email addresses, without their consent; </li><li>violate any applicable law or regulation.</li>",
    UTILIZZODESCR3 : "BiPart is not obliged to monitor the Communication Services. However, BiPart reserves the right to view materials sent through any of the Communication Services and to remove any material at its sole and absolute discretion. BiPart reserves the right to suspend the user's access to one, more, or all Communication Services at any time without notice for any reason. Materials posted on any of the Communication Services may be subject to limitations set on their use, reproduction, and/or dissemination. The user is responsible for adhering to such limitations when downloading the materials."
  },
  TRANSLATOR : {
    SELECT : "Wybierz swój język"
  },
  USERDETAIL : {
    BIOGRAFIA : "Biografia",
    COMMUNITY : "Społeczność",
    EDUCAZIONE : "Edukacja",
    EDULAVORO : "Edukacja i praca",
    ETA : "Wiek",
    F : "Kobieta",
    LAVORO : "Praca",
    M : "Mężczyzna",
    PROPOSTEEFFETTUATE : "Mój wkład",
    UTENTE : "Użytkownik"
  }
};
