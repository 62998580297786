import ApiService from "@/core/services/api.service";

export const validationConditions = {
    computed: {
        $canFollow() {
            return this.module && this.module?.canFollow;
        },
        $activeFollowing() {
            return this.module && this.module.activeFollowing;
        }
    },
    methods: {
        async $endpointCanFollow(moduleId) {
            return await new Promise((resolve, reject) => {
                let endpoint = this.generateUrl('/process-modules/' + moduleId + '/can-follow', false, false);
                ApiService.get(this.$apiResourceOpen + endpoint)
                .then(res => {
                    resolve(res?.data?.canFollow);
                })
                .catch(() => {
                    reject(0);
                });
            });
        }
    }
};