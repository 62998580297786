import Vue from "vue";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import jwt_decode from "jwt-decode";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const VERIFIY_MANDATORY_FLAGS = "verifyMandatoryFlags";
export const SET_HEADER_TOKEN = "setHeaderToken";
export const GET_REFRESHED_TOKEN = "getRefreshedToken";
export const REFRESH_TOKEN = "refreshToken";
export const LOGIN = "login";
export const ANONYMOUS_LOGIN = "anonymousLogin";

export const REGISTER_TO_PROCESS = "registerToProcess";

export const LOGOUT = "logout";
export const REGISTER = "register";
export const REGISTER_TO_ORGANIZATION = "registerToOrganization";
export const UPDATE_USER = "updateUser";
export const GET_USER = "getUser";
export const UPDATE_CHECKER_MOBILE = "updateCheckerMobile";

export const REGISTER_PROCESS_BY_MOBILE = "registerProcessByMobile";
export const ASSIGN_PROCESS_BY_MOBILE = "assignProcessByMobile";
export const VERIFY_MOBILE = "verifyMobile";
export const RESEND_OTP_CODE = "resendOtpCode";
export const ASSIGN_TAXCODE_TO_USER = "assigTaxcodeToUser";
export const VERIFY_MOBILE_PUBLIC = "verifyMobilePublic";
export const RESEND_OTP_CODE_PUBLIC = "resendOtpCodePublic";

export const RECOVER_ACCOUNT_BY_MOBILE = "recoverAccountByMobile";
export const CHANGE_PASSWORD = "changePassword";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_TOKEN = "setToken";
export const SET_CHECKER_MOBILE = "setCheckerMobile";

const USER_DATA = "user_data";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  checkerMobile: null,
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  canOperate(state) {
    let result = false;
    let operativeRoles = [
      "ADMIN",
      "DELEGATE",
      "EXPERT",
      "ASSESSOR",
      "FACILITATOR",
      "AUTH_CITIZEN",
    ];
    state.user.authorities.forEach(function (value) {
      if (operativeRoles.includes(value)) {
        result = true;
      }
    });
    return result;
  },
  checkerMobile(state) {
    return state.checkerMobile;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    // console.log(credentials);
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl("login", false, false);
      //generateUrl is done
      ApiService.post(endpoint, credentials)
        .then((resp) => {
          if (resp.headers.token !== "BadCredentialsException") {
            // console.log('Dati corretti: ',resp);
            const token = resp.headers.token;
            const token_decode = jwt_decode(token);
            const user = {
              token: token,
              id: token_decode.id,
              name: token_decode.name,
              surname: token_decode.surname,
              username: token_decode.sub,
              termsConditionsAccepted: token_decode.termsConditionsAccepted,
              privacyPolicyAccepted: token_decode.privacyPolicyAccepted,
              emailRegStatus: token_decode.emailRegStatus,
              mobileRegStatus: token_decode.mobileRegStatus,
              authorities: token_decode.authorities,
              assignedProcesses: token_decode.assignedProcesses,
              joinedGroups: token_decode.joinedGroups,
              selectedOrganizationPublic:
                token_decode.selectedOrganizationPublic,
              selectedOrganizationRegAllowed:
                token_decode.selectedOrganizationRegAllowed,
              selectedOrganization2LevelLogin:
                token_decode.selectedOrganization2LevelLogin,
              avatar:
                token_decode && token_decode.avatar
                  ? token_decode.avatar
                  : "/assets/media/bipart/avatar-default2.svg",
            };
            // console.log(JSON.stringify(user));
            context.commit(SET_AUTH, user);

            // Salvo i dati dell'utente nel local storage
            window.localStorage.setItem(USER_DATA, JSON.stringify(user));
          }
          resolve(resp);
        })
        .catch(({ response }) => {
          console.log("Errore nella chiamata", response);
          context.commit(SET_ERROR, [response.data.error]);
          reject(0);
        });
    });
  },

  [ANONYMOUS_LOGIN](context) {
    let credentials = {
      username: "anonymous",
      password: "scou1U53r",
      selectedOrganizationDomain: Vue.prototype.$currentDomain,
      fetchEnabledOnly: true,
    };
    // console.log('Credenziali login anonimo: ', credentials);

    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl("login", false, false);
      //generateUrl is done
      ApiService.post(endpoint, credentials)
        .then((resp) => {
          console.log("Dati corretti: ", resp);
          if (resp.headers.token !== "BadCredentialsException") {
            const token = resp.headers.token;
            const token_decode = jwt_decode(token);
            const user = {
              token: token,
              id: token_decode.id,
              name: token_decode.name,
              surname: token_decode.surname,
              username: token_decode.sub,
              termsConditionsAccepted: token_decode.termsConditionsAccepted,
              privacyPolicyAccepted: token_decode.privacyPolicyAccepted,
              emailRegStatus: token_decode.emailRegStatus,
              mobileRegStatus: token_decode.mobileRegStatus,
              authorities: token_decode.authorities,
              assignedProcesses: token_decode.assignedProcesses,
              joinedGroups: token_decode.joinedGroups,
              selectedOrganizationPublic:
                token_decode.selectedOrganizationPublic,
              selectedOrganizationRegAllowed:
                token_decode.selectedOrganizationRegAllowed,
              selectedOrganization2LevelLogin:
                token_decode.selectedOrganization2LevelLogin,
            };
            // console.log(JSON.stringify(user));
            context.commit(SET_AUTH, user);

            // Salvo i dati dell'utente nel local storage
            window.localStorage.setItem(USER_DATA, JSON.stringify(user));
            console.log("User salvato in LS");
          }
          resolve(resp);
        })
        .catch(({ response }) => {
          console.log("Errore nella chiamata", response);
          context.commit(SET_ERROR, [response.data.error]);
          window.locale.reload();
          reject(0);
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  [REGISTER](context, credentials) {
    // console.log('credentials in modulo auth: ', credentials);
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl(
        "/public/users/register-to-process-by-email",
        false,
        false
      );
      //generateUrl is done
      ApiService.post(Vue.prototype.$apiResource + endpoint, credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },

  [REGISTER_TO_ORGANIZATION](context, credentials) {
    // console.log('credentials in modulo auth: ', credentials);
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl(
        "/public/users/register-to-organization-by-email",
        false,
        false
      );
      //generateUrl is done
      ApiService.post(Vue.prototype.$apiResource + endpoint, credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [REGISTER_PROCESS_BY_MOBILE](context, credentials) {
    // console.log('credentials in modulo auth: ', credentials);
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl(
        "/public/users/register-to-process-by-mobile",
        false,
        false
      );
      //generateUrl is done
      ApiService.post(Vue.prototype.$apiResource + endpoint, credentials)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [ASSIGN_PROCESS_BY_MOBILE](context, data) {
    // /api/v1/users/{id}/assign-mobile
    return new Promise((resolve, reject) => {
      ApiService.put(
        Vue.prototype.$apiResource + "/users/" + data.id + "/assign-mobile",
        data.credentials
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [VERIFY_MOBILE](context, data) {
    // /api/v1/users/confirm-user-mobile-by-otp
    return new Promise((resolve, reject) => {
      ApiService.put(
        Vue.prototype.$apiResource +
          "/users/" +
          data.id +
          "/confirm-user-mobile-by-otp",
        data.verify
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [VERIFY_MOBILE_PUBLIC](context, data) {
    // /api/v1/public/users/confirm-user-mobile-by-otp
    return new Promise((resolve, reject) => {
      ApiService.put(
        Vue.prototype.$apiResource + "/public/users/confirm-user-mobile-by-otp",
        data
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [RESEND_OTP_CODE](context, userId) {
    // /api/v1/users/{id}/resend-mobile-validation-otp
    return new Promise((resolve, reject) => {
      ApiService.put(
        Vue.prototype.$apiResource +
          "/users/" +
          userId +
          "/resend-mobile-validation-otp"
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [RESEND_OTP_CODE_PUBLIC](context, data) {
    // /api/v1/public/users/resend-mobile-validation-otp
    return new Promise((resolve, reject) => {
      ApiService.put(
        Vue.prototype.$apiResource +
          "/public/users/resend-mobile-validation-otp",
        data
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [ASSIGN_TAXCODE_TO_USER](context, taxcodeData) {
    // /api/v1/users/{id}/validate-user-taxcode-against-p2m
    return new Promise((resolve, reject) => {
      ApiService.put(
        Vue.prototype.$apiResource +
          "/users/" +
          taxcodeData.userId +
          "/validate-user-taxcode-against-p2m",
        taxcodeData.assignData
      )
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [RECOVER_ACCOUNT_BY_MOBILE](context, data) {
    // console.log('credentials in modulo auth: ', credentials);
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl(
        "/public/users/recover-account-by-mobile",
        false,
        false
      );
      context.commit(SET_CHECKER_MOBILE, data.mobile || null);
      //generateUrl is done
      ApiService.put(Vue.prototype.$apiResource + endpoint, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [CHANGE_PASSWORD](context, data) {
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl(
        "/users/change-password",
        false,
        false
      );
      ApiService.put(Vue.prototype.$apiResource + endpoint, data)
        .then((resp) => {
          resolve(resp);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },
  [VERIFIY_MANDATORY_FLAGS](context) {
    const token = JwtService.getToken();
    if (token) {
      if (
        !JwtService.decodeToken().privacyPolicyAccepted ||
        !JwtService.decodeToken().termsConditionsAccepted
      ) {
        context.commit(PURGE_AUTH);
        window.location.href = "login";
      }
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      //   ApiService.get("verify")
      //     .then(({ data }) => {
      //       context.commit(SET_AUTH, data);
      //     })
      //     .catch(({ response }) => {
      //       console.log(response);
      //       context.commit(SET_ERROR, [response.data.error]);
      //     });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [SET_HEADER_TOKEN](context) {
    const token = JwtService.getToken();
    if (token) {
      ApiService.setHeader();
      // Leggo i dati dell'utente dal localstorage per valorizzare lo stato
      const user = window.localStorage.getItem("user_data");
      context.commit(SET_AUTH, JSON.parse(user));
    } else {
      context.commit(PURGE_AUTH);
      // console.log('Purged from SET_HEADER_TOKEN');
    }
  },

  [GET_REFRESHED_TOKEN](context) {
    return new Promise((resolve, reject) => {
      var endpoint = Vue.prototype.generateUrl("/users/renewed-jwt", false);
      //generateUrl is done
      ApiService.get(Vue.prototype.$apiResource + endpoint)
        .then((res) => {
          resolve(res);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, [response.data.errors]);
          reject(response);
        });
    });
  },

  [REFRESH_TOKEN](context, token) {
    const token_decode = jwt_decode(token);
    const user = {
      token: token,
      id: token_decode.id,
      name: token_decode.name,
      surname: token_decode.surname,
      username: token_decode.sub,
      termsConditionsAccepted: token_decode.termsConditionsAccepted,
      privacyPolicyAccepted: token_decode.privacyPolicyAccepted,
      emailRegStatus: token_decode.emailRegStatus,
      mobileRegStatus: token_decode.mobileRegStatus,
      authorities: token_decode.authorities,
      assignedProcesses: token_decode.assignedProcesses,
      joinedGroups: token_decode.joinedGroups,
      selectedOrganizationPublic: token_decode.selectedOrganizationPublic,
      selectedOrganizationRegAllowed:
        token_decode.selectedOrganizationRegAllowed,
      selectedOrganization2LevelLogin:
        token_decode.selectedOrganization2LevelLogin,
      avatar:
        token_decode && token_decode.avatar
          ? token_decode.avatar
          : "/assets/media/bipart/avatar-default2.svg",
    };
    context.commit(SET_AUTH, user);

    // Salvo i dati dell'utente nel local storage
    window.localStorage.setItem(USER_DATA, JSON.stringify(user));

    // context.commit(SET_TOKEN, token);
  },
  [UPDATE_USER](context, payload) {
    context.commit(SET_AUTH, payload);
  },
  [UPDATE_CHECKER_MOBILE](context, mobile) {
    context.commit(SET_CHECKER_MOBILE, mobile);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    state.checkerMobile = null;
    JwtService.destroyToken();
    // Elimina tutto il localstorage
    // window.localStorage.clear();
    window.localStorage.removeItem(USER_DATA);
  },
  [SET_TOKEN](state, token) {
    state.user.token = token;
    JwtService.saveToken(token);
  },
  [SET_CHECKER_MOBILE](state, mobile) {
    state.checkerMobile = mobile;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
