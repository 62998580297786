import i18n from "../plugins/vue-i18n";

const i18nService = {
  defaultLanguage: 'en',

  languages: [
    {
      lang: "en",
      name: "English",
      flag: process.env.BASE_URL + "media/svg/flags/226-united-states.svg"
    },
    {
      lang: "el",
      name: "Greek",
      flag: process.env.BASE_URL + "media/svg/flags/170-greece.svg"
    },
    {
      lang: "es",
      name: "Spanish",
      flag: process.env.BASE_URL + "media/svg/flags/128-spain.svg"
    },
    {
      lang: "pl",
      name: "Polish",
      flag: process.env.BASE_URL + "media/svg/flags/211-poland.svg"
    },
    {
      lang: "de",
      name: "German",
      flag: process.env.BASE_URL + "media/svg/flags/162-germany.svg"
    },
    {
      lang: "fr",
      name: "French",
      flag: process.env.BASE_URL + "media/svg/flags/195-france.svg"
    }
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    i18n.locale = lang;
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {

    let organization = localStorage.getItem("organization") || null;
    var control_unique_language = null;

    if(organization) {
      let organization_parse = JSON.parse(organization);
      control_unique_language = (organization_parse && organization_parse.currentOrganization && organization_parse.currentOrganization.data && organization_parse.currentOrganization.data.languages && organization_parse.currentOrganization.data.languages.length == 1) ? organization_parse.currentOrganization.data.languages[0].locale : null;
      //Se l'organizzazione ha una sola lingua
      if(control_unique_language) {

        localStorage.setItem("language", control_unique_language);
        return control_unique_language;

      //Se la lingua scelta esiste nell'organizzazione
      } else if(localStorage.getItem("language") && (organization_parse && organization_parse.currentOrganization && organization_parse.currentOrganization.data && organization_parse.currentOrganization.data.languages && organization_parse.currentOrganization.data.languages.find((x) => x.locale == localStorage.getItem("language")))) {

        return localStorage.getItem("language");

      } else {

        //Se la lingua del browser esiste nell'organizzazione
        const browserLang = navigator.language.split("-")[0].toLowerCase();
        if(organization_parse && organization_parse.currentOrganization && organization_parse.currentOrganization.data && organization_parse.currentOrganization.data.languages && organization_parse.currentOrganization.data.languages.find((x) => x.locale == browserLang)) {
          localStorage.setItem("language", browserLang);
          return  browserLang;

        //Ritorno la prima lingua gestita dall'organizzazione
        } else if(organization_parse && organization_parse.currentOrganization && organization_parse.currentOrganization.data && organization_parse.currentOrganization.data.languages) {
          localStorage.setItem("language", organization_parse.currentOrganization.data.languages[0].locale);
          return organization_parse.currentOrganization.data.languages[0].locale;
        }
      }
    }

    return  localStorage.getItem("language") || navigator.language.split("-")[0].toLowerCase();
  }
};

export default i18nService;
