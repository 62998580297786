// IT
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "ACCEDI",
    ACCEDICOMM2 : "Clicca qui se sei già iscritto a BiPart",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    REGISTRATICOMM1 : "ISCRIVITI VIA EMAIL",
    REGISTRATICOMM2 : "Clicca qui se non sei iscritto e vuoi farlo con il tuo indirizzo email",
    SOLOVOTO : "ISCRIVITI VIA CELLULARE",
    SOLOVOTO2 : "Clicca qui se vuoi iscriverti solo con il telefono cellulare o devi recuperare le credenziali"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "Attenzione",
    INDIETRO : "indietro",
    INSERISCINUOVONUMERO : "Inserisci<br /> nuovo numero",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    PH1 : "Cellulare",
    PHONEERROR1 : "Hai dimenticato di inserire il prefisso",
    PHONEERROR2 : "Hai per caso dimenticato di inserire il tuo numero di cellulare?",
    PHONEERROR3 : "Il numero che hai inserito è un po’ strano … controlla bene! :)",
    PRIVACY : "Per proseguire prendi visione dell'<a href=\"https://bipart.it/privacy\" target=\"blank\">Informativa sulla privacy</a> e dei <a href=\"https://bipart.it/terms\" target=\"blank\">Termini e delle condizioni d'uso</a>",
    PROCEDI : "ISCRIVITI",
    RECUPERAPASSWORD : "Recupera<br/> password",
    TERMINIECONDIZIONI : "Ho letto e accetto i <a href=\"https://bipart.it/terms\" target=\"_blank\">Termini e le condizioni d'uso</a>",
    VERIFICA1 : "Verifica i tuoi dati per partecipare",
    VERIFICA2 : "Inserisci il tuo cellulare per ricevere il codice OPT via SMS",
    EXIT: "Esci",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "<h4>Abbiamo inviato un codice SMS di verifica al tuo cellulare.</h4>",
    ALERT1TITLE : "Info",
    ERROROTPMAXCHAR : "Numero massimo di cifre inseribili 6",
    INDIETRO : "indietro",
    OTPDIMENTICATO : "Hai dimenticato di inserire il codice OTP",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    PH1 : "Codice OTP*",
    PROCEDI : "procedi",
    RICHIEDINUOVOCODICE1 : "<b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti",
    RICHIEDINUOVOCODICE2 : "richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : "o scrivici a",
    VERIFICA1 : "Verifica i tuoi dati per partecipare",
    VERIFICA2 : "Abbiamo inviato il codice OTP di verifica al tuo numero di cellulare. Inseriscilo per proseguire"
  },
  ADVAUTHROLE : {
    CITTADINO : "Cittadino",
    INDIETRO : "indietro",
    INFOREGISTER : "Segui la procedura a step e inserisci i dati richiesti per partecipare",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    PROCEDI : "procedi",
    VERIFICA1 : "Verifica i tuoi dati per partecipare",
    VERIFICA2 : "Scegli come autenticarti"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "Ops... something went wrong; Stauts code:",
    ALERT1TITLE : "Error",
    CFDIVERSO : "Hai inserito un codice fiscale diverso da quello già associato al tuo account?",
    CHIUDI : "Chiudi",
    DIMENTICATOCF : "Hai dimenticato di inserire il codice fiscale",
    ERRORECF : "Il codice fiscale non è inserito correttamente.",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    PH1 : "Codice fiscale*",
    PROCEDI : "procedi",
    VERIFICA1 : "Verifica i tuoi dati per partecipare",
    VERIFICA2 : "<b>Inserisci il tuo CODICE FISCALE per proseguire</b><br>In questo modo potremo garantire una partecipazione più sicura!"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "Ci spiace ma il tuo codice fiscale non è abilitato e sembra che tu non possa partecipare.",
    CFNONABILIATO1 : "Verifica le linee guida e se pensi sia un errore scrivici a support@bipart.it",
    CHIUDI : "Chiudi",
    UTENTENONAUTORIZZATO : "Ops!"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "Grazie per esserti iscritto su BiPart.",
    CHIUDI : "Chiudi",
    COMPLIMENTI : "BENVENUTA/BENVENUTO!",
    UTENTEABILITATO : "Conserva con te le credenziali inviate al tuo cellulare tramite SMS per accedere nuovamente alla piattaforma",
    UTENTEINFO : "Da questo momento in poi potrai partecipare con un account verificato e abilitato all'utilizzo delle funzionalità più avanzate ed importanti!"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "Attenzione",
    ALERTINFO : "Non hai inserito i dati obbligatori. Riprova per favore oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    PARTECIPA : "Entra e partecipa al processo",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    PASSWORDERRATA : "Password errata",
    PH1 : "username*",
    PH2 : "password*",
    SUPPORT : "<i class=\"fas fa-info-circle\"></i> Se non ricordi le tue credenziali, scrivi a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    USERNONESISTE : "Lo username non esiste"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "Credenziali errate",
    ALERT1TITLE : "Attenzione",
    CAMPIOBBLIGATORI : "* I campi con l’asterisco sono obbligatori",
    COGNOMEDIMENTICATO : "Hai dimenticato di inserire il cognome",
    INDIETRO : "INDIETRO",
    MAILDIMENTICATA : "Hai dimenticato di inserire l'email",
    MAILNONVALIDA : "Indirizzo email non valido",
    NEWSLETTER : "Voglio rimanere aggiornato sui progetti e sulle iniziative attive su BiPart",
    NOMEDIMENTICATO : "Hai dimenticato di inserire il nome",
    PARTECIPA : "Iscriviti e partecipa al processo",
    PARTECIPAPROCESSO : "Sei nello spazio dell’organizzazione:",
    PASSWORDDIMENTICATA : "Hai dimenticato di inserire la password",
    PH1 : "Nome*",
    PH2 : "Cognome*",
    PH3 : "Username*",
    PH4 : "Email*",
    PH5 : "Password*",
    PRIVACY : "Ho letto e accetto l'<a href=\"https://bipart.it/privacy\" target=\"_blank\"> Informativa sulla privacy</a>",
    REGISTRA : "ISCRIVITI",
    TERMINIECONDIZIONI : "Ho letto e accetto i <a href=\"https://bipart.it/terms\" target=\"_blank\">Termini e le condizioni d'uso</a>",
    USERNAMEDIMENTICATO : "Hai dimenticato di inserire lo username"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "<h4>La proposta non può essere seguita</h4>",
    ALERTCOMMENTEMPTY : "<h4>Inserisci il testo del commento</h4>",
    ALERTCOMMENTSENDED : "<h4>Grazie per aver inserito il tuo commento e contribuito alla proposta!</h4>",
    ALERTEMAILBLANK : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Non hai ancora il tuo indirizzo email</h6></div>",
    ALERTEMAILCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Non hai ancora confermato il tuo indirizzo email</h6><div>Confermalo per entrare nell’organizzazione e iscriverti ai processi partecipativi</div><div>Non hai ricevuto la mail?<br><strong>Clicca il pulsante nella banda rossa per reinviarla</strong></div>",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "Crea e pubblica il tuo profilo per partecipare attivamente ai processi della tua community",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "Benvenuta, benvenuto su BiPart!",
    ALERTEMAILMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Non hai ancora confermato il tuo cellulare e/o il tuo indirizzo email</h6><div>Confermali per entrare nell’organizzazione e iscriverti ai processi partecipativi</div><div>Non hai ricevuto i messagi?<br><strong>Clicca i pulsanti nella banda rossa per reinviarli</strong></div>",
    ALERTEMAILRESENDCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Email di richiesta conferma reinviata alla tua casella di posta</h5><div>Confermala per entrare a far parte dell’organizzazione<br>e iscriverti ai processi partecipativi</div>",
    ALERTERROR : "Ops... qualcosa è andato storto!",
    ALERTFORMDESCRIPTION : "<h4>In attesa di attivazione</h4>",
    ALERTFORMTITLE : "<b>Questionario non ancora disponibile</b>",
    ALERTHELPDESCRIPTION : "<h4>Se hai bisogno di aiuto, contattaci presso <a href=\"mailto:support@bipart.it\">support@bipart.it</a></h4>",
    ALERTHELPTITLE : "<b>Help Desk</b>",
    ALERTJAMBOARDDESCRIPTION : "<h4>In attesa di attivazione</h4>",
    ALERTJAMBOARDTITLE : "<b>Jamboard non ancora disponibile</b>",
    ALERTMEETDESCRIPTION : "<h4>In attesa di attivazione</h4>",
    ALERTMEETTITLE : "<b>Webinar non ancora disponibile</b>",
    ALERTMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Non hai ancora confermato il tuo cellulare</h6><div>Confermalo per entrare nell’organizzazione e iscriverti ai processi partecipativi</div><div>Non hai ricevuto il messagio?<br><strong>Clicca il pulsante nella banda rossa per reinviarlo</strong></div>",
    ALERTPERMISSIONDENIED : "<h4>Non hai i diritti per operare</h4>",
    ALERTUSERMODULENOTENABLED : "<h4>Ciao! Forse non disponi delle necessarie autorizzazioni per partecipare. Leggi le regole oppure scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> o agli altri contatti disponibili.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASK : "clicca <a href=\"/process-guidelines/{process_id}\" target=\"_blank\">qui</a> per scoprire come puoi partecipare, oppure",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "<h4>Ops,<br>a quanto pare non hai i permessi per lasciare un commento;<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "<h4>Ops,<br>a quanto pare non hai i permessi per fare {type};<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "<h4>Ops,<br>a quanto pare non hai i permessi per eliminare {type};<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "<h4>Ops,<br>a quanto pare non seguire questa proposta;<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "<h4>Ops,<br>a quanto pare non hai i permessi per inviare un messaggio;<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "<h4>Ops,<br>a quanto pare non hai i permessi per modificare {type};<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "<h4>Ops,<br>a quanto pare non hai i permessi per versionare {type};<br>{exist_guidelines}scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> per avere maggiori informazioni.</h4>",
    ALERTUSERREGISTEREDTOORGANIZATION : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Grazie per esserti unito alla nostra community</h5><div>Abbiamo inviato una mail alla tua casella di posta.<br>Confermala per entrare a far parte dell'organizzazione</div><div>Attenzione! Controlla anche la cartella spam.</div>",
    ALERTUSERREGISTEREDTOPROCESS : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Grazie per esserti unito alla nostra community</h5><div>Abbiamo inviato una mail alla tua casella di posta.<br>Confermala per entrare a far parte dell'organizzazione</div><div>Attenzione! Controlla anche la cartella spam.</div>",
    PROPOSAL : "una proposta",
    SERVICE : "un servizio",
    STORY : "una storia",
    TACTICS : "una tattica"
  },
  AUTHORITIES : {
    ASSESSOR : "Valutatore",
    EXPERT : "Esperto"
  },
  BCPROCESSI : {
    CHALLENGE : "Temi",
    COMMUNITY : "Community",
    ELENCOPROCESSI : "Elenco Processi",
    PROCESSO : "Processo",
    SCENARI : "Contesto"
  },
  COMMUNITY : {
    CERCA : "Cerca...",
    CHIPARTECIPA : "Chi partecipa",
    GRUPPI : "Gruppi",
    ISCRITTI : "Iscritti",
    PROFILI : "Profili pubblici",
    SOTTOTITOLO : "Ecco chi ha pubblicato il proprio profilo: unisciti anche tu!",
    TITOLO : "Community"
  },
  COMMUNITYSPACE : {
    BOZZA : "Bozza",
    CS : "Community Space",
    DESCRIZIONEIDEA : "Descrizione della proposta",
    IDEE : "20 proposte",
    NOMEAUTORE : "Nome autrice/autore",
    TITOLOIDEA : "Titolo della proposta",
    VISUALIZZAELENCO : "Visualizza elenco",
    VISUALIZZAMAPPA : "Visualizza mappa"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "Vuoi revocare il completamento della traduzione in lingua:",
    ALERT1TITLE : "Attenzione",
    ALERT2 : "Vuoi confermare il completamento della traduzione in lingua:",
    CANCEL : "ANNULLA"
  },
  COOKIE : {
    ANONIMI : "3. Cookie di analisi anonimi:",
    ANONIMIDESCR1 : "Questo sito utilizza cookie di Google Analytics con IP anonimizzato della durata di un anno. Sono definiti anonimi in quanto non possono essere utilizzati per identificare individui specifici. Più in particolare il sito nowhow.it utilizza GoogleAnalytics (Google, Inc.) che genera cookie secondo le modalità illustrate dal documento relativo alle Norme generiche sulla privacy e dal documento specifico in merito all’utilizzo delle informazioni raccolte con Google Analytics. Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da Google stessa. I termini di servizio ai quali tutti i clienti del programma devono aderire, vietano il monitoraggio o la raccolta di informazioni personali (dati di identificazione personale, come ad esempio nome, indirizzo email, dati di fatturazione o altri dati che possono essere ragionevolmente collegati a tali informazioni) mediante l’uso di Google Analytics o l’associazione di queste alle informazioni sull’analisi dei dati web. Questi cookie perseguono esclusivamente scopi statistici e raccolgono informazioni in sola forma aggregata.",
    COLLEGAMENTI : "6. Collegamenti utili",
    COLLEGAMENTIDESCR1 : "Per maggiori informazioni sui cookie ed il loro utilizzo su internet, potrete trovare utili i seguenti collegamenti:<br><br>www.allaboutcookies.org<br>www.microsoft.com/info/cookies.mspx<br>www.google.com/intl/it_it/analytics/privacyoverview.html",
    CONTENUTIESTERNI : "5. Visualizzazione di contenuti da piattaforme esterne",
    CONTENUTIESTERNIDESCR1 : "Questi servizi permettono di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi. Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato. Per maggiori informazioni sulle logiche e le modalità di trattamento dei dati raccolti dai social network, puoi leggere le note informative sulla privacy fornite dai soggetti che forniscono I servizi in questione:<br><br>Widget Video Youtube (Google Inc.) - Terms - Privacy<br>Facebook - Termini e condizioni - Privacy<br>Twitter - Termini e condizioni - Privacy<br>Vimeo - Termini e condizioni - Privacy<br>Slideshare - Termini e condizioni - Privacy<br>Linkedin - Termini e condizioni - Privacy<br>Instagram - Termini e condizioni - Privacy<br><br>Ricordati che puoi gestire le tue preferenze sui cookie anche attraverso il browser Se non conosci il tipo e la versione di browser che stai utilizzando, clicca su “Aiuto” nella finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.<br><br>Se invece conosci il tuo browser clicca su quello che stai utilizzando per accedere alla pagina di gestione dei cookie. Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Windows Explorer.",
    COSASONO : "1. Cosa sono i cookie",
    COSASONODESCR1 : "I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, dove vengono memorizzati per essere ritrasmessi agli stessi siti in occasione di visite successive. I cookie sono utilizzati per diverse finalità, hanno caratteristiche diverse, e possono essere utilizzati sia dal titolare del sito che si sta visitando, sia da terze parti. Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diversa da quella adottata da nowhow.it e che quindi non risponde di questi siti. Di seguito troverai tutte le informazioni sui cookie installati attraverso questo sito, e le indicazioni necessarie su come gestire le tue preferenze riguardo ad essi.",
    DATIRACCOLTI : "O4. Dati personali raccolti: Cookie e Dati di utilizzo.O",
    DATIRACCOLTIDESCR1 : "Tutti i cookie tecnici non richiedono consenso, perciò vengono installati automaticamente a seguito dell’accesso al sito.",
    SOTTOTITOLO : "Gestione dei cookie",
    TITOLO : "Cookie policy",
    UTILIZZATI : "2. Cookie utilizzati da questo sito",
    UTILIZZATIDESCR1 : "Cookie relativi ad attività strettamente necessarie al funzionamento del sito e all’erogazione del servizio. Questo sito utilizza il cookie di sessione PHPSESSIONID generato dalle applicazioni basate sul linguaggio PHP. Questo identificatore viene utilizzato per mantenere le variabili di sessione utente."
  },
  DRIVERS : {
    ACTIVEPROCESSES : "In corso",
    CHALLENGE : "Temi",
    CLOSEPROCESSES : "Conclusi",
    CLUSTER : "Cluster",
    DESCRIZIONE : "Descrizione",
    ELENCOCHALLENGE : "Temi",
    LINK : "Link",
    PROCESSES : "Processi",
    TUTTITEMI : "Tutti i temi della partecipazione suddivisi in Cluster"
  },
  EMAILCONFIRM : {
    AUTOK : "Autenticazione riuscita",
    AUTOKDETAIL : "Ora puoi accedere e partecipare alla nostra community",
    BLOGIN : "ACCEDI",
    TITOLO : "BiPart"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "Per contribuire in maniera attiva al miglioramento della vita nel tuo Comune. Potrai farlo proponendo una tua idea o limitandoti a votare quella che ritieni più interessante. Le idee proposte devono essere concrete, realizzabili, innovative, localizzate nel tuo Comune e aderenti e orientate all’interesse pubblico. Il bilancio partecipativo 2021, “Idee Ricostituenti” vuole offrire una prima opportunità di protagonismo ai cittadini, chiedendo loro di avanzare proposte per progetti che rispondano a criticità e bisogni emersi con la pandemia.\n",
    DETTAGLIOGLOSSARIO : "Obiettivi e temi per l’organizzazione",
    FAQ : "FAQ",
    FAQ1 : "Cosa vuol dire che l’idea progettuale deve riguardare una attività di “interesse pubblico”?",
    FAQ10 : "Come e quando posso presentare una idea progettuale?",
    FAQ11 : "Se sono membro di una associazione, può l’associazione stessa presentare l’idea progettuale?",
    FAQ12 : "Come e quando posso votare una idea progettuale?",
    FAQ13 : "Posso presentare più di una idea progettuale?",
    FAQ14 : "Quali sono gli elementi che costituiscono l’idea progettuale che devo caricare sulla piattaforma partecipativa?",
    FAQ15 : "È possibile apportare modifiche alla mia idea progettuale, una volta presentata?",
    FAQ16 : "Posso votare più di una volta?",
    FAQ17 : "Posso votare sul bilancio partecipativo di un Comune in cui non risiedo ma magari lavoro o studio?",
    FAQ18 : "Se sono un cittadino straniero, residente in Italia, posso partecipare?",
    FAQ19 : "Se ho bisogno di assistenza per presentare una domanda oppure votare, chi posso contattare?",
    FAQ2 : "Cosa vuol dire che l’idea progettuale deve essere “concreta, realizzabile e senza costi ripetuti nel tempo”?",
    FAQ20 : "Chi organizza il bilancio partecipativo?",
    FAQ21 : "Chi e sulla base di cosa decide sull’ammissibilità preliminare delle idee progettuali?",
    FAQ22 : "È possibile che il Gruppo di lavoro dell’Unione proponga una modifica alla mia idea progettuale, se è fra le più votate?",
    FAQ23 : "Le idee progettuali più votate quando saranno effettivamente realizzate?",
    FAQ24 : "C’è un organo di garanzia del processo?",
    FAQ25 : "Se sono interessato a partecipare all’organizzazione del percorso, posso farne parte in qualche modo?",
    FAQ3 : "Cosa vuol dire che l’idea progettuale deve essere “innovativa”?",
    FAQ4 : "Dove deve essere localizzata la mia idea progettuale?",
    FAQ5 : "Quanto può costare la mia idea progettuale?",
    FAQ6 : "Posso avere benefici economici diretti dalla mia idea progettuale?",
    FAQ7 : "Quali altri vincoli ci sono per la presentazione delle idee progettuali?",
    FAQ8 : "Quali altri vincoli ci sono per la presentazione delle idee progettuali?",
    FAQ9 : "Chi può presentare una idea progettuale e chi può votare?",
    GLOSSARIO : "Glossario",
    PERCHEPARTECIPARE : "Perchè partecipare al bilancio partecipativo?",
    QUALITEMIPROGETTI : "Su quali temi posso presentare idee progettuali?",
    R1 : "Le idee progettuali devono portare un reale beneficio a tutta la collettività, ovvero la platea dei beneficiari deve essere aperta e la loro fruizione non deve essere esclusa o limitata a nessun potenziale fruitore (ad esempio sono incompatibili interventi di cui beneficiano solo soci di una determinata realtà organizzata).\n",
    R10 : "Le idee progettuali si possono presentare esclusivamente on line accedendo alla piattaforma partecipativa www.partecipo-romagnafaentina.it e compilando, dopo essersi accreditati, la Scheda Progetto. Le idee progettuali potranno essere presentate a partire dal 15 marzo fino al 9 maggio 2021, salvo proroghe.",
    R11 : "No. Le persone giuridiche (associazioni, imprese, ecc.) non possono presentare direttamente un’idea progettuale, ma potrà farlo un cittadino ad esse aderente o simpatizzante.",
    R12 : "Le idee progettuali si votano esclusivamente on line accedendo alla piattaforma partecipativa www.partecipo-romagnafaentina.it e seguendo le indicazioni in essa riportate. Le idee progettuali potranno essere votate dal <b>24 maggio al 27 giugno 2021.</b>",
    R13 : "Sì, si può presentare più di una idea progettuale.",
    R14 : "Gli elementi dell’idea progettuale sono: un titolo, una descrizione, un costo ipotizzato. Puoi aggiungere altri elementi per descriverla meglio: un logo, un video, delle immagini, dei documenti allegati. Puoi anche georeferenziare l’idea, localizzandola in una mappa del comune.",
    R15 : "Sì, è possibile modificare l’idea progettuale per tutta la durata della fase di raccolta idee. Terminata la fase di raccolta, le idee non sono più modificabili.",
    R16 : "Sì, è possibile votare quante idee si vuole. E’ inoltre possibile modificare i propri voti fino allo scadere del tempo dedicato alla votazione. Naturalmente non è possibile votare più volte la stessa idea.",
    R17 : "No.",
    R18 : "Sì, l’importante è essere in possesso dei requisiti necessari per il voto: residenza in uno dei Comuni che hanno attivato il bilancio partecipativo (Casola Valsenio, Castel Bolognese, Faenza, Riolo Terme, Solarolo), aver compiuto i 16 anni di età, essere dotati di codice fiscale.",
    R19 : "In caso di bisogno di assistenza si può scrivere a <a href=\"mailto:partecipo@romagnafaentina.it\">partecipo@romagnafaentina.it</a>. A questa mail è possibile porre quesiti ma anche prenotare un appuntamento per ricevere aiuto in maniera più interattiva: per tutta la fase di raccolta idee è infatti attivo uno sportello di assistenza ogni giovedì dalle 10 alle 13.\nPer quesiti di carattere tecnico relativi al funzionamento della piattaforma è attivo anche l’indirizzo <a href=\"mailto:support@bipart.it\">support@bipart.it</a>\n",
    R2 : "L’idea progettuale deve essere: sufficientemente precisa per poter essere vagliata a livello tecnico, economico e giuridico (non deve essere quindi un’idea astratta); deve poter essere realizzabile rapidamente, entro dicembre 2022 (non deve comportare ulteriori fasi progettuali e/o acquisizioni di pareri e nulla osta di particolare complessità); non deve comportare costi ricorrenti, negli anni successivi a quello di attivazione, per il suo mantenimento.",
    R20 : "Il bilancio partecipativo è organizzato dall’Unione della Romagna Faentina, e per l’edizione 2021 hanno aderito i Comuni di Casola Valsenio, Castel Bolognese, Faenza, Riolo Terme, Solarolo.",
    R21 : "L’ammissibilità preliminare delle idee progettuali presentate è stabilita da un Gruppo di lavoro interdisciplinare di dipendenti dell’Unione della Romagna Faentina, affiancato dal Tavolo di Negoziazione del percorso “Idee Ricostituenti”. L’ammissibilità preliminare è verificata sul rispetto dei requisiti minimi dell'idea progettuale, oltre che sull'attinenza ad almeno una delle tre sfide specifiche dell’anno 2021 (digitale, giovani, sociale).",
    R22 : "Sì, qualora per rendere ammissibile tecnicamente una idea progettuale si necessiti di adeguamenti, il Gruppo di lavoro interdisciplinare di dipendenti dell’Unione (coadiuvato dal tavolo di Negoziazione e dal Quartiere o Frazione) può richiedere alcune modifiche al proponente dell’idea progettuale più votata. Qualora il proponente non accolga la proposta di modifica, l’idea progettuale è da considerarsi inammissibile e si procederà a scorrere la graduatoria.",
    R23 : "Le idee progettuali più votate saranno realizzate fra la fine del 2021 e dicembre 2022, salvo ritardi non dipendenti dall’Unione della Romagna Faentina.",
    R24 : "Sì. Al fine di assicurare l’imparzialità e l'adeguatezza del processo del bilancio partecipativo, l’Unione della Romagna Faentina ha istituito un Comitato di garanzia del percorso, composto da dipendenti dell’ente e da amministratori di maggioranza e di opposizione.",
    R25 : "Sì, puoi far parte del Tavolo di Negoziazione oppure del Comitato di Garanzia. I riferimenti dei coordinatori sono i seguenti: Andrea Venturelli - Responsabile del progetto, mail: andrea.venturelli@romagnafaentina.it; Andrea Piazza – Coordinatore del Comitato di Garanzia, mail: andrea.piazza@romagnafaentina.it.",
    R3 : "L’idea progettuale non può essere presentata qualora faccia riferimento a progetti già in essere e/o già finanziati in tutto o in parte dall’Unione della Romagna Faentina, dai Comuni aderenti, dalla Regione Emilia-Romagna e/o dallo Stato. L’unica eccezione è valida per le attività che sviluppano nuove ed ulteriori potenzialità di un progetto già esistente e/o già finanziato.",
    R4 : "Le idee progettuali devono avere ricaduta locale, nel Comune in cui risiede il proponente e che finanzia l’intervento. Le idee potranno avere anche una ricaduta che va oltre il Comune di residenza.",
    R5 : "L’idea progettuale deve comportare costi diretti complessivi a carico dell’Unione della Romagna Faentina pari a un massimo di 5.000 euro (3.000 euro per il Comune di Casola Valsenio), oneri fiscali inclusi. Nei costi sono comprese tutte le spese derivanti dalla sua realizzazione: acquisti di beni e attrezzature, affidamenti di servizi, realizzazione di investimenti, ecc. Saranno finanziate le idee progettuali più votate in ciascun Comune, fino all’esaurimento del budget disponibile.",
    R6 : "No, l’idea progettuale è presentata all’insegna del principio di gratuità. Le somme in palio non possono essere corrisposte direttamente al proponente o alla realtà organizzata cui è riconducibile il proponente (gruppo informale, associazione, impresa, ecc.). Da ciò deriva che l’Unione della Romagna Faentina per l’implementazione delle idee progettuali si avvarrà di fornitori e realizzatori individuati in autonomia, nelle forme previste dalla legge.",
    R7 : "In linea generale le idee progettuali devono essere coerenti con tutti vincoli posti dalla legge, dagli atti delle amministrazioni comunali, dai termini di utilizzo della piattaforma partecipativa. In particolare, sono vietati contenuti discriminatori, offensivi, contrari all’ordine pubblico o pubblicitari/propagandistici. Eventuali idee progettuali, presentate sulla piattaforma partecipativa, dal contenuto inappropriato, saranno insindacabilmente rimosse dall’amministrazione.",
    R8 : "In linea generale le idee progettuali devono essere coerenti con tutti vincoli posti dalla legge, dagli atti delle amministrazioni comunali, dai termini di utilizzo della piattaforma partecipativa. In particolare, sono vietati contenuti discriminatori, offensivi, contrari all’ordine pubblico o pubblicitari/propagandistici. Eventuali idee progettuali, presentate sulla piattaforma partecipativa, dal contenuto inappropriato, saranno insindacabilmente rimosse dall’amministrazione.",
    R9 : "Possono presentare idee progettuali e possono votare i cittadini residenti nei Comuni che hanno attivato il bilancio partecipativo (Casola Valsenio, Castel Bolognese, Faenza, Riolo Terme, Solarolo) e che: hanno compiuto 16 anni di età, sono dotati di codice fiscale, risiedono in uno dei comuni sopracitati.",
    TEMI : "Le idee progettuali devono riguardare almeno uno dei tre seguenti temi:\n1) trasformazione e transizione digitale (quali, ad esempio: diffusione dell’ICT nelle politiche locali, iniziative di alfabetizzazione digitale, strumenti per garantire il diritto di accesso alle reti tecnologiche, modalità per sviluppare il diritto all'informazione, alla conoscenza, all’apertura e all’accesso ai dati, ecc.);\n2) politiche giovanili (ovvero che si rivolgano come target di riferimento alla popolazione fra 16 e 35 anni, quali, ad esempio: progetti che coinvolgano i giovani come principali beneficiari dell’intervento e/o che ne facciano i protagonisti diretti dell’intervento);\n3) inclusione sociale e contrasto alle marginalità (con particolare riferimento ai primi 5 obiettivi di sviluppo sostenibile dell’Agenda 2030: 1 – povertà zero; 2 – fame zero; 3 – buona salute e benessere per le persone; 4 – educazione paritaria e di qualità; 5 – parità di genere).\n"
  },
  GENERALLABEL : {
    AL : "al",
    ALTERMINEDELLAFASE : "al termine della fase",
    DAL : "dal",
    FASE : "fase",
    MANCANOANCORA : "Mancano ancora",
    TERMINAIL : "Termina il"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "Ho letto e accetto",
    ACCETTOPROCESSOLINEEGUIDA2 : "le linee guida e le regole del processo"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "Elenco Gruppi",
    F : "femminile",
    GRUPPO : "Gruppo",
    M : "maschile",
    MEMBRI : "Profili pubblici",
    PUBBLICA : "Pubblica il tuo profilo e presentati alla comunità!"
  },
  GROUPLIST : {
    COMMUNITY : "Community",
    DDC : "data di creazione",
    DUE : "due",
    GRUPPI : "gruppi",
    TRE : "tre",
    UNO : "uno"
  },
  HOME : {
    ALERT1 : "<h4>Consulta la sezione <a href=\"/how-it-works\">come funziona</a> per conoscere cos'è un bilancio partecipativo</h4>",
    ALERT1TITLE : "<b>Prima di iniziare...</b>",
    ALERT2 : "<h4>Qualcosa non va, hai qualche difficoltà o vuoi semplicemente salutarci? :) Scrivici subito a <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>",
    ALERT2TITLE : "<b>Help Desk</b>",
    ASSISTENZA : "Assistenza",
    DATIGRAFICI : "Dati e grafici dell'organizzazione",
    GETSTARTED : "Get Started",
    GRUPPICOM : "Gruppi/Community",
    OBIETTIVI : "Temi",
    ORGANIZZAZIONE : "L’organizzazione in pillole",
    ORGANIZZAZIONI : "Organizzazioni",
    PROCESSIATTIVI : "Processi attivi",
    PROFILO : "Gestisci il tuo profilo personale",
    PROFILODETT : "Qui trovi le tue attività e puoi gestire il tuo profilo",
    SCENARI : "Contesto",
    UTENTIREG : "Utenti iscritti",
    VOTANTI : "Votanti",
    VOTIRACCOLTI : "Voti raccolti"
  },
  IDEADETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato alla proposta",
    ALERT10TITLE : "Non cancellare",
    ALERT11 : "Una volta cancellato non potrai più recuperarlo",
    ALERT11TITLE : "Sei sicuro di voler cancellare questo commento?",
    ALERT12TITLE : "Non è stato possibile cancellare il commento",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questa proposta",
    ALERT13TITLE : "Attenzione",
    ALERT14 : "La proposta è già stata valutata",
    ALERT14TITLE : "Attenzione",
    ALERT15 : "Una volta pubblicata non potrai più modificarla",
    ALERT15B1 : "Conferma",
    ALERT15B2 : "Annulla",
    ALERT15TITLE : "Sei sicuro di voler inserire questa valutazione?",
    ALERT16 : "Il numero inserito non può contenere decimali",
    ALERT16TITLE : "Attenzione",
    ALERT17 : "Il numero inserito non può contenere più di 6 cifre",
    ALERT17TITLE : "Attenzione",
    ALERT1TITLE : "Attenzione",
    ALERT2 : "<h4>Qualcosa non va, hai qualche difficoltà o vuoi semplicemente salutarci? :) Scrivici subito a <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>",
    ALERT2TITLE : "<b>Help Desk</b>",
    ALERT3 : "Clicca la stellina <span class=\"symbol symbol-rounded\">\n<span class=\"symbol-label font-size-h6 font-weight-bold bg-warning text-white\" style=\"width: 25px;height: 25px;\">\n<i class=\"fas fa-star text-white pr-0 pb-1\"></i>\n</span>\n</span> qui a lato se vuoi vedere il riepilogo dei tuoi voti",
    ALERT3TITLE : "Grazie per aver votato questa proposta",
    ALERT4 : "Naviga tra le proposte e scegli quali altre preferisci di più",
    ALERT4TITLE : "Hai rimosso la tua preferenza!",
    ALERT5 : "Puoi continuare a donare anche per altre proposte scegliendo come distribuire il tuo budget a disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto la proposta!",
    ALERT6TITLE : "Stai seguendo questa proposta!",
    ALERT7TITLE : "Non stai più seguendo questa proposta",
    ALERT8 : "Una volta cancellata non potrai più recuperarla",
    ALERT8B1 : "Si, cancella!",
    ALERT8B2 : "No, annulla!",
    ALERT8TITLE : "Sei sicuro di voler cancellare questa proposta?",
    ALERT9 : "La proposta è stata cancellata",
    ALERT9TITLE : "Eliminazione proposta",
    ALERTBOZZA : "Hai una versione della tua proposta in bozza. Ricordati di pubblicarla",
    ALERTEMPTYVOTE1USERLEFTHTML : "<h4>Hai esaurito il numero di preferenze disponibili.<br />Ricordati che puoi modificare le tue scelte fino al termine della votazione</h4>",
    ALERTEMPTYVOTE1USERLEFTTITLE : "Ops!",
    ALERTOWNERMAILEDHTML1 : "<p>Abbiamo inviato il tuo messaggio a",
    ALERTOWNERMAILEDHTML2 : "che potrà risponderti tramite i contatti che hai inserito.<br />Nel frattempo continua a partecipare sulla piattaforma</p>",
    ALERTOWNERMAILEDTITLE : "MESSAGGIO INVIATO",
    ALERTPUBBLICATA : "Proposta in fase di revisione. Collabora al suo sviluppo",
    APPROFONDISCI : "Materiali",
    APPROVATA : "Approvata",
    AUTORE : "Pubblicata da",
    BUDGET : "Costo stimato:",
    CAMPOMANCANTE : "Il campo \"{field}\" è mancante",
    CANCELLAIDEA : "Cancella",
    CANVERSION : "Il numero massimo di versioni è stato raggiunto",
    CANVERSION2 : "Stai creando la versione {n} della tua proposta",
    CANVERSIONTITLE : "Attenzione",
    CANVERSIONTITLE2 : "{count} è il numero massimo di versioni pubblicabili",
    CHALLENGE : "Temi",
    CIFRAALTA : "Stai donando una cifra troppo alta",
    CIFRABASSA : "Stai donando una cifra troppo bassa",
    CIFRADELBUDGET : "Puoi ancora donare",
    CIFRAMODIFICATA : "Hai modificato la tua donazione",
    CIFRARIMOSSA : "Hai annullato la tua donazione",
    CIFRASUPERIOREALBUDGET : "Non puoi più donare perchè la cifra che hai inserito è superiore alle tue capacità finanziarie.",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questa proposta.",
    COMMENTAIDEA : "Commenta",
    COMMENTI : "Commenti",
    COMMENTIALERTEMAILCONFIRM : "<h6 class=\"text-primary\">Non hai ancora confermato il tuo indirizzo email</h6><div>Confermalo per iscriverti all'organizzazione ed ai suoi processi partecipativi</div><div>Non hai ricevuto la mail?<br><strong>Clicca il pulsante nella banda rossa per reinviarla</strong></div>",
    COMMENTIALERTEMAILMOBILECONFIRM : "<h6 class=\"text-primary\">Non hai ancora confermato il tuo cellulare e/o il tuo indirizzo email</h6><div>Confermali per iscriverti all'organizzazione ed ai suoi processi partecipativi</div><div>Non hai ricevuto i messaggi?<br><strong>Clicca i pulsanti nella banda rossa per reinviarli</strong></div>",
    COMMENTIALERTMOBILECONFIRM : "<h6 class=\"text-primary\">Non hai ancora confermato il tuo cellulare</h6><div>Confermalo per iscriverti all'organizzazione ed ai suoi processi partecipativi</div><div>Non hai ricevuto il messaggio?<br><strong>Clicca il pulsante nella banda rossa per reinviarlo</strong></div>",
    COMMENTICONTRIBUISCI : "Lascia un commento",
    COMMENTIDISATTIVATI : "I commenti sono disattivati",
    COMMENTIENTRA : "ACCEDI",
    COMMENTIREGISTRATI : "ISCRIVITI",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "Iscriviti per lasciare un commento",
    COMPLETABOZZA : "Completa la bozza",
    CONDIVIDI : "Condividi",
    COSTOIDEA : "Costo",
    CREATAIL : "Creata il",
    DESCRIZIONE : "Informazioni generali",
    DONA : "DONA",
    DONAREDA : "da un minimo di",
    DONAREFINOA : "fino ad un massimo di",
    DONRACCOLTE : "Donazioni raccolte",
    EMAILAUTORENONDISPONIBILE : "Non è possibile inviare un messaggio perché l’autore o l’autrice della proposta non ha un indirizzo email",
    EMAILUTENTENONDISPONIBILE : "Non puoi inviare messaggi all’autore o all’autrice della proposta poiché il tuo account non ha un indirizzo email associato. Contatta <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> per maggiori informazioni. Grazie!",
    FOLLOWERS : "Community",
    FONDIRACCOLTI : "Fondi raccolti",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questa proposta!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questa proposta!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questa proposta!",
    HAIANCORA : "Hai ancora a disposizione",
    HAIANCORA2 : "Hai ancora a disposizione",
    HASCRITTO : "ha scritto",
    IDEAVOTATA : "Proposta votata",
    INBOZZA : "Bozza",
    INDICACIFRA : "Indica la cifra che vuoi donare",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questa proposta.",
    INSERISCICOMMENTO : "Inserisci un commento",
    INVIA : "invia",
    MACCETTODIESSERERICONTATTATO : "Accetto di essere ricontattato tramite i contatti forniti",
    MAPPE : "Contesto",
    MBCHIUDI : "Chiudi",
    MBINVIA : "Invia",
    MCONTATTAILPROPONENTE : "Invia un messaggio",
    MEMAIL : "Il tuo contatto email",
    MESSAGEFIELDNAME : "Messaggio",
    METTIPREF : "Vota",
    MINSERISCIUNMESSAGGIO : "Il tuo messaggio",
    MMESSAGGIOOBBLIGATORIO : "Il messaggio è obbligatorio",
    MMOBILE : "Il tuo contatto telefonico",
    MNOMINATIVO : "Il tuo nome",
    MODIFICAIDEA : "Modifica",
    MTIPOLOGIACOLLABORAZIONELABEL : "Invia un tuo messaggio all'autrice o all'autore",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "Scegli la tipologia di messaggio",
    NARRAZIONE : "La proposta",
    NOCOMMENTI : "Non sono presenti commenti",
    NOCOMMUNITY : "Non sono presenti followers",
    NONAPPROVATA : "Non approvata",
    NONSEGUIRE : "Non seguire",
    NONVALUTATA : "Non valutata",
    NUOVAVERSIONE : "Crea una nuova versione",
    PH1 : "Inserisci un punteggio",
    PH2 : "Inserisci una valutazione",
    PREFERENZEPERPREMIARE : "preferenze per premiare le proposte migliori!",
    PREFRACCOLTE : "Preferenze raccolte",
    PUBBLICATAIL : "il",
    PUOIDONARE : "Puoi donare",
    RICHIEDIASSISTENZA : "Chiedici aiuto!",
    RICORDATI3 : "Ricordati che puoi modificare la tua donazione fino all’ultimo.",
    RIMUOVIPREF : "Rimuovi preferenza",
    SEGUIIDEA : "Segui",
    SOSTENITORI : "Sostenitori",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altre proposte, scegliendo come distribuire il tuo budget.",
    SOTTOCATEGORIA : "Tipologia",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altre proposte.",
    TUOIVOTI : "I tuoi voti",
    URLSITO : "Sito web",
    VAIALSITO : "Vai al sito",
    VALUTAIDEA : "Valuta",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per le altre proposte",
    VALUTAZIONEINSERITA : "Valutazione inserita",
    VALUTAZIONETITLE1 : "Inserisci la valutazione finale",
    VALUTAZIONETITLE2 : "Inserisci la valutazione di dettaglio",
    VALUTAZIONETITLE3 : "Valutazione finale",
    VALUTAZIONETITLE4 : "Valutazioni di dettaglio",
    VALUTAZIONETITLE5 : "Valutazione finale già inserita",
    VALUTAZIONETITLE6 : "Valutazione di dettaglio già inserita",
    VALUTAZIONI : "Valutazioni",
    VERSIONIPROPOSTA : "Versioni della proposta:",
    VOTAZIONECHIUSA : "La votazione è chiusa. A breve saranno pubblicati i risultati",
    WALLETSOSTIENIIDEA : "Sostieni questa proposta. Fai una donazione!"
  },
  IDEALIST : {
    ALERTBOZZA : "Hai una versione della tua proposta in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING1 : "Versioning attivo!",
    ALERTVERSIONING2 : "È possibile migliorare le proposte fornendo o raccogliendo spunti e pubblicando nuove versioni sempre più aggiornate",
    ALERTVERSIONING3 : "Numero massimo di versioni",
    ALERTVERSIONING4 : "Tempo massimo",
    ALERTVERSIONING5 : "Puoi creare fino a {n} versioni",
    ASSISTENZA : "Chiedici aiuto",
    CLASSIFICA : "Graduatoria",
    CREAIDEA : "Fai la tua proposta",
    ELENCOIDEE : "Tutte le proposte",
    FILTRAPERCHALLENGE : "FILTRA PER TEMA",
    FILTRAPERMODULO : "FILTRA PER AREA",
    FILTRAPERSCENARIO : "FILTRA PER CONTESTO",
    IDEAVOTATA : "Hai votato questa proposta!",
    IDEE : "proposte",
    IDEECOSTOSE : "Meno economiche",
    IDEEMENOCOSTOSE : "Più economiche",
    IDEEMENORECENTI : "Meno recenti",
    IDEEMENOSEGUITE : "Meno seguite",
    IDEEPIUSEGUITE : "Più seguite",
    INFORMAZIONI : "Approfondisci",
    INFORMAZIONISULLAFASE : "Come partecipare",
    JAMBOARD : "Partecipa alla Jamboard",
    NESSUNO : "Nessuno",
    NONCISONOIDEE : "Non vi sono ancora proposte. Fai la tua!",
    ORDINA : "Ordina per",
    PREFERENZE : "preferenze",
    PS : "Area personale",
    TUOIVOTI : "I tuoi voti",
    ULTIMEIDEE : "Più recenti",
    VOTANTI : "votanti",
    VOTAZIONEATTIVA : "La votazione è attiva",
    VOTAZIONEATTIVA2 : "Guarda tutte le proposte ed esprimi le tue preferenze",
    VOTAZIONEATTIVA3 : "Puoi donare",
    VOTAZIONEATTIVABUDG : "e un budget di",
    VOTAZIONEATTIVAMAX : "fino a",
    VOTAZIONEATTIVAMIN : "da",
    VOTAZIONEATTIVAPREF : "Puoi votarne",
    VOTAZIONEATTIVAPREF2 : "ed hai ancora a disposizione",
    VOTAZIONEATTIVAPREF3 : "preferenze",
    VOTAZIONEATTIVAPREF4 : "preferenza",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare le tue proposte preferite.",
    VOTAZIONECHIUSA : "La votazione è  chiusa. A breve saranno pubblicati i risultati",
    WEBINAR : "Partecipa al Webinar"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "Graduatoria",
    COMEFUNZIONA : "Come funziona",
    COSTOIDEA : "Costo stimato",
    DONATORI : "Donatrici/Donatori",
    ELENCOPROCESSI : "Tutti i processi partecipativi",
    FASE : "Fase:",
    FILTRAPERBUDGET : "Filtra per budget raccolto",
    FILTRAPERPREFERENZE : "Filtra per preferenze",
    FINANZIATO : "Donazioni",
    PREFERENZE : "Preferenze",
    PROCESSO : "Processo",
    RISULTATI : "Risultati",
    RISULTATIVOTAZIONE : "Risultati della votazione",
    TUTTOSUPROCESSO : "Tutto quello che devi sapere sul processo partecipativo"
  },
  INTRO : {
    ASSISTENZA : "Chiedici aiuto!"
  },
  INTROBASE : {
    ASSISTENZA : "Assistenza",
    COSA : "Cosa puoi fare",
    PARTECIPA : "Partecipa",
    PARTECIPADESC : "Partecipa al processo della tua scuola",
    SUBIPART : "su BiPart"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "Ricordati di confermare il tuo indirizzo email. Se non hai ricevuto nessuna email da BiPArt",
    CONFIRMEMAIL2 : "clicca qui",
    CONFIRMEMAIL3 : "per inviarla nuovamente.",
    CONFIRMMOBILE1 : "Ricordati di confermare il tuo cellulare. Se non hai ricevuto nessun SMS da BiPArt",
    CONFIRMMOBILE2 : "clicca qui",
    CONFIRMMOBILE3 : "per inviarlo nuovamente."
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Chiudi",
    CONFERMA : "Conferma l'iscrizione al processo",
    ERR1 : "Ops... si è verificato un errore inatteso",
    ISCRIVITIPROCESSO : "Iscriviti al processo",
    PARTECIPAPROCESSO : "Vuoi partecipare al processo?",
    PROPOSTE : "Per fare proposte, commentare, richiedere valutazioni, votare devi iscriverti al processo.",
    TITLE1 : "Errore"
  },
  LOGIN : {
    ACCEDI : "Accedi",
    ACCETTO1 : "Ho letto e accetto i",
    ACCETTO2 : "Ho letto e accetto l'",
    BACKPORTAL : "Torna al portale",
    CAMBIAPASS : "Cambia password",
    INSERISCIMAIL : "Inserisci la tua mail",
    MEX1 : "Iscrizione e accettazione dell'Informativa sulla privacy e dei Termini e delle condizioni d'uso",
    MEX2 : "Le credenziali inserite sono sbagliate. Controlla oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Autenticazione riuscita",
    MEX4 : "Si è verificato un errore",
    MEX5 : "Email non valida",
    MEXLOGIN : "<b>Benvenut* su BiPart,</b><br>la piattaforma della partecipazione.<br>I tuoi dati saranno trattati nel pieno rispetto delle normative italiane ed europee e con ancora più cura, a tutela delle tue opinioni e delle tue decisioni.<br><b>Se vuoi proseguire, leggi e accetta le privacy policy e i termini e condizioni del servizio.</b>",
    NONRICORDIPASS : "Non ricordi la password?",
    NOTIFICHE : "",
    PASSWORDERRATA : "Password errata",
    PRIVACY : "Informativa sulla privacy",
    PROSEGUI : "Prosegui",
    TERMINI : "Termini e condizioni del servizio",
    TERMINICONDIZIONI : "Per proseguire prendi visione dell'<a href=\"https://bipart.it/privacy\" target=\"blank\">Informativa sulla privacy</a> e dei <a href=\"https://bipart.it/terms\" target=\"blank\">Termini e delle condizioni d'uso</a>",
    TITLE1 : "Congratulazioni",
    TITLE2 : "Attenzione",
    TITLE3 : "Congratulazioni",
    TITLE4 : "Errore",
    TITLE5 : "Errore",
    USERNAMENONESISTE : "Lo username non esiste",
    UTENTEAUTENTICATO : "-- Utente autenticato --"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "Accedi",
    ACCETTO : "Ho letto e accetto i",
    ACCETTOLA : "Ho letto e accetto l'",
    BPROSEGUI : "Prosegui",
    CAMBIAPASS : "Cambia password",
    INSERISCIMAIL : "Inserisci la tua mail",
    MEX1 : "Iscrizione e accettazione dell'Informativa sulla privacy e dei Termini e delle condizioni d'uso",
    MEX2 : "Le credenziali inserite sono sbagliate. Controlla oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Autenticazione riuscita",
    MEX4 : "Si è verificato un errore",
    MEX5 : "Email non valida",
    MEXLOGIN : "<b>Benvenut* su BiPart,</b><br>la piattaforma della partecipazione.<br>I tuoi dati saranno trattati nel pieno rispetto delle normative italiane ed europee e con ancora più cura, a tutela delle tue opinioni e delle tue decisioni.<br><b>Se vuoi proseguire, leggi e accetta le privacy policy e i termini e condizioni del servizio.</b>",
    NIENTEACCOUNT : "Non hai ancora un account?",
    NONRICORDIPASS : "Non ricordi la password?",
    NOTIFICHE : "",
    PRIVACY : "Informativa sulla privacy",
    REGISTRATI : "Iscriviti",
    TERMINI : "Termini e condizioni del servizio",
    TERMINIECONDIZIONI : "Per proseguire prendi visione dell'<a href=\"https://bipart.it/privacy\" target=\"blank\">Informativa sulla privacy</a> e dei <a href=\"https://bipart.it/terms\" target=\"blank\">Termini e delle condizioni d'uso</a>",
    TITLE1 : "Congratulazioni",
    TITLE2 : "Attenzione",
    TITLE3 : "Congratulazioni",
    TITLE4 : "Errore",
    TITLE5 : "Errore",
    TITOLO : "BiPart",
    UTENTEAUTENTICATO : "-- Utente autenticato --"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Chiudi",
    CONFERMA : "Accedi",
    ISCRIVITIPROCESSO : "Accedi con le tue credenziali e partecipa!",
    MEX1 : "Non hai inserito i dati obbligatori. Riprova per favore oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX2 : "Le credenziali inserite sono sbagliate. Controlla oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Ops... si è verificato un errore",
    NONFAIPARTE : "Non sei ancora iscritta o iscritto?",
    NUOVOACCOUNT : "Crea un account per partecipare",
    ORGANIZATION : "Benvenuta, benvenuto su BiPart!",
    PASSWORDERRATA : "Password errata",
    REGISTRATI : "ISCRIVITI",
    TITLE2 : "Attenzione",
    TITLE3 : "Errore",
    USERNAMENONESISTE : "Lo username non esiste"
  },
  MAINMENU : {
    ABOUT : "About",
    ACCESSIBILITY : "Accessibilità",
    COMMUNITY : "Community",
    COOKIE : "Cookie",
    DEMO : "Crea community space",
    DS : "Deliberative space",
    EXPLORE : "Esplora",
    FAQ : "FAQ",
    FEATURE : "Tutorial e guide",
    GIOCHI : "Giochi educativi",
    HOME : "Home",
    HOWITWORKS : "Come funziona",
    HOWTOJOIN : "Come partecipare",
    JOINCOMMUNITY : "Area personale",
    LISTCHALLENGES : "Temi",
    LISTGROUPS : "Elenco gruppi",
    LISTPROCESSES : "Processi",
    LISTSCENARIOS : "Contesto",
    LOGIN : "Accedi",
    LOGOUT : "Esci",
    PORTAL : "Portale",
    PRIVACY : "Privacy",
    PROJECT : "Il progetto",
    PROJECTS : "Progetti e buone pratiche",
    PS : "Area personale",
    PSACTIVITIES : "Attività",
    PSPROFILE : "Profilo personale",
    PSSETTINGS : "Configurazioni",
    REGISTER : "Iscriviti",
    SERVICES : "Cosa offriamo",
    TERMS : "Termini e condizioni",
    WORKSPACE : "Workspace"
  },
  MANDATORYFLAGS : {
    ACCETTO : "Ho letto e accetto i",
    ACCETTOLA : "Ho letto e accetto l'",
    BPROSEGUI : "Prosegui",
    DETTWELCOME : "I tuoi dati saranno trattati nel pieno rispetto delle normative italiane ed europee sulla privacy ed ancora con più cura, a tutela delle tue opinioni e delle tue decisioni. Se vuoi proseguire leggi e accetta la nostra informativa e le nostre condizioni.",
    INSERISCIMAIL : "Inserisci la tua mail",
    MEX2 : "Le credenziali inserite sono sbagliate. Controlla oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Autenticazione riuscita",
    MEX5 : "La mail inserita non è valida",
    NOTIFICHE : "",
    PRIVACY : "Informativa sulla privacy",
    TERMINIECONDIZIONI : "Per proseguire prendi visione dell'<a href=\"https://bipart.it/privacy\" target=\"blank\">Informativa sulla privacy</a> e dei <a href=\"https://bipart.it/terms\" target=\"blank\">Termini e delle condizioni d'uso</a>",
    TITLE1 : "Congratulazioni",
    TITLE2 : "Attenzione",
    TITLE3 : "Congratulazioni",
    TITOLO : "BiPart",
    TITOLOWELCOME : "Benvenuta, benvenuto su BiPart, la piattaforma della partecipazione.",
    UTENTEAUTENTICATO : "-- Utente autenticato --"
  },
  MESSAGEAUTH : {
    0 : "Si è verificato un errore generico",
    1 : "Le credenziali inserite sono sbagliate. Controlla oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    100 : "L'attributo “organizationDomain” specificato non corrisponde ad alcuna Organizzazione",
    105 : "L'attributo “processId” risulta mancante",
    110 : "L'attributo “processId” specificato non corrisponde ad alcun Processo",
    115 : "Il processo non appartiene all'organizzazione specificata",
    120 : "Ciao!\nIl Processo è privato: per partecipare devi richiedere un codice di accesso",
    125 : "L'attributo “groupId” specificato non corrisponde ad alcun Gruppo",
    130 : "Ops!\nQuesto Gruppo è privato e non è possibile farne parte",
    135 : "Il Gruppo richiesto non appartiene al Processo prescelto",
    140 : "Ciao!\nPer partecipare devi indicarci a quale gruppo appartieni o vuoi iscriverti.\nSelezionalo dal menù a tendina, per favore. Grazie!",
    145 : "Ops!\nNon è possibile iscriversi a questo processo. Per maggiori informazioni scrivici a support@bipart.it",
    150 : "Ops!\nHai dimenticato qualche dato importante per l'iscrizione. Completa i campi evidenziati in rosso e il gioco è fatto.",
    155 : "Ops!\nLo username che hai inserito è già in uso: magari ti sei già iscritto e non ricordi.\nProva a effettuare il login oppure inseriscine uno differente. \nGrazie!",
    160 : "Ops!\nIl tuo Utente risulta già iscritto alla piattaforma ma non ancora associato a questo Processo.\nUtilizza il pulsante “Accedi” e inserisci le tue credenziali per completare l'associazione!",
    165 : "Ops!\nLo username che hai inserito è già in uso: magari ti sei già iscritto e non ricordi. \nProva a effettuare il login oppure inseriscine uno differente. Grazie!",
    170 : "Peccato! Qualcuno è stato più veloce di te e ha già scelto questo username. :( Prova con un altro ancora più carino!",
    172 : "L'email che hai inserito è già in uso.\nVerifica che non ti sei già iscritto, altrimenti segnalaci il problema a support@bipart.it",
    175 : "Ops!\nPer procedere con l'iscrizione devi leggere e farci sapere che sei d'accordo con la nostra Informativa sulla privacy e con i Termini e le condizioni d'uso",
    180 : "Ciao!\nL'Organizzazione è privata: per partecipare devi richiedere un codice di accesso.",
    182 : "Il Gruppo richiesto non appartiene all'Organizzazione prescelta.",
    185 : "Ciao!\nPer partecipare devi indicarci a quale Gruppo appartieni o vuoi iscriverti.\nSelezionalo dal menù a tendina, per favore. Grazie!",
    187 : "Ops!\nL'Organizzazione prescelta contiene esclusivamente Gruppi privati e non è possibile farne parte.",
    190 : "Lo username che hai inserito è già in uso: magari ti sei già iscritto e non ricordi.\nProva a effettuare il login oppure inseriscine uno differente. \nGrazie!",
    192 : "Ops!\nIl tuo Utente risulta già iscritto alla piattaforma ma non ancora associato a questa Organizzazione.\nUtilizza il pulsante “Accedi” e inserisci le tue credenziali per completare l'associazione!",
    2 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Grazie per esserti iscritto a<br>{value}</h5>",
    200 : "Ops!\nHai lasciato trascorrere troppo tempo!\nAffinché possiamo verificare il tuo indirizzo e-mail, devi richiedere l'invio di un nuovo messaggio e cliccare quanto prima sul pulsante di conferma contenuto nel messaggio stesso.",
    205 : "Ops!\nL'operazione non è andata a buon fine: riprova!",
    210 : "Ops!\nIl tuo account non corrisponde a nessun utente presente nei nostri archivi!",
    215 : "Ops!\nNon ci risulta pervenuta alcuna richiesta di iscrizione corrispondente al tuo indirizzo e-mail.\nSei sicuro di avere completato la relativa procedura?\nProva a registrarti di nuovo, per favore.\nGrazie!",
    220 : "Ops!\nL'indirizzo e-mail che stai cercando di confermare risulta già verificato.\nProva a effettuare il login.\nGrazie!",
    225 : "Ops!\nL'operazione non è andata a buon fine: riprova!",
    3 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Stai partecipando al processo<br>{value}</h5>",
    300 : "Ops!\nIl tuo Utente non risulta ancora iscritto alla piattaforma.\nSei sicuro di avere completato la procedura?\nProva ad effettuarla di nuovo, per favore.\nGrazie!",
    305 : "Ops!\nIl tuo Utente risulta già iscritto alla piattaforma, ma non ancora associato a questa Organizzazione.\nContattaci per richiedere l'accesso.",
    310 : "Ops!\nLo username che hai inserito è già in uso: magari ti sei già iscritto e non ricordi.\nProva a effettuare il login oppure inseriscine uno differente. \nGrazie!",
    4 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><div>Benvenuto su {organization_name}</div><h5 class=\"text-primary\">Confermiamo la tua iscrizione a {process_name}</h5><div>Da adesso fai parte della community e puoi operare nei processi a cui sei iscritto.</div>",
    400 : "L'attributo “mobile” risulta mancante.",
    405 : "Ops!\nIl numero di cellulare che hai inserito è già in uso: magari ti sei già iscritto e non ricordi.\nProva ad accedere oppure inserisci un numero differente.\nGrazie!",
    410 : "Ops!\nIl numero di cellulare che hai inserito è già in uso: magari ti sei già registrato e non ricordi.\nProva a effettuare il login oppure inseriscine uno differente.\nGrazie!",
    420 : "Ops!\nIl numero di telefono che hai inserito è già in uso.\n Se hai partecipato altre volte, clicca sul pulsante ESCI e recupera le tue vecchie credenziali ISCRIVENDOTI VIA CELLULARE.\nGrazie!",
    425 : "Ops!\nL’attributo “mobile” non compare nel database Utenti\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    500 : "Ops!\nAl tuo profilo non risulta associato alcun numero di cellulare.\nTorna al pannello precedente e inseriscilo.\nGrazie!",
    502 : "Si è verificato un problema tecnico e ti abbiamo inviato un nuovo codice di verifica via SMS.\nPer favore, ripeti l'operazione dopo averlo digitato in questo stesso pannello.\nGrazie!",
    510 : "Ops!\nIl codice di verifica che hai inserito non è valido.\nControllalo oppure richiedi l'invio di un nuovo codice.\nGrazie!",
    515 : "Ops!\nIl codice di verifica che hai inserito è scaduto.\nPer favore, richiedi l'invio di un nuovo codice.\nGrazie!",
    605 : "Ops!\nAl tuo profilo risulta già associato un numero di cellulare.",
    700 : "",
    705 : "Il Modulo destinazione non appartiene al Processo target.",
    710 : "Il codice fiscale non risulta dichiarato e neppure risulta già attribuito all'AppUser richiedente.",
    715 : "Ops!\nIl codice fiscale che hai inserito non è valido. Controllalo, per favore.\nGrazie!",
    720 : "Ops!\nIl codice fiscale che hai inserito è già in uso.\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    725 : "Ops!\nAl tuo profilo risulta già associato un codice fiscale.",
    ATTENZIONE : "Attenzione"
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "Inserisci oppure copia e incolla l'URL del sito ufficiale dell'organizzazione che fornisce il servizio",
    MAXLENGTHPART1 : "Attenzione! Hai ancora",
    MAXLENGTHPART2 : "caratteri a disposizione",
    PH0 : "Hai a disposizione 60 caratteri",
    PH5 : "----",
    SELEZIONASOTTOCATEGORIA : "Indica che tipo di servizio stai pubblicando, selezionando una delle voci che ti compaiono cliccando il campo di inserimento",
    SOTTOCATEGORIA : "Tipologia"
  },
  MODULEIDEACREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT1TITLE : "Attenzione",
    ALERT2 : "La proposta è stata inserita correttamente!",
    ALERT3 : "Inserisci un valore per indicare il costo ipotizzato della tattica",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare la tua proposta (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui.",
    ANNULLA : "Annulla",
    AVANTI : "Avanti",
    BOZZA : "Salva in bozza",
    CHALLENGE : "Temi",
    COSTOIPOTIZZATO : "Costo ipotizzato",
    CREAIDEA : "Fai la tua proposta",
    DAIBREVETITOLO : "Dai un titolo breve ma accattivante alla tua proposta",
    DESCRIVI : "Descrivi in poche righe la tua proposta. Puoi dettagliarla al prossimo step",
    DESCRIZIONE : "Sintesi",
    DOCALLEGATI : "Documenti e allegati.",
    FILEACCETTATI : "Dimensione massima del file: 10 MB. Formati supportati: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Dimensione massima del file: 10 MB. Formati supportati: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Dimensione massima del file: 10 MB. Formati supportati: JPG, JPEG, PNG",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine che rappresenti la tua proposta",
    IMMAGINI : "Pubblica altre immagini utili a far comprendere meglio la tua proposta.",
    INDICACOSTO : "Indica quanto potrebbe costare la tua proposta",
    ISTRUZIONI : "Istruzioni",
    LOGO : "Immagine di copertina",
    MAPPEEINFO : "Contesto",
    NARRA : "Descrivi in modo dettagliato la tua proposta",
    NARRAZIONE : "La proposta",
    OVERVIEW : "Informazioni generali",
    PH1 : "Hai a disposizione 600 caratteri",
    PH2 : "URL (solo YouTube)",
    PH3 : "Inizia a digitare l'indirizzo e clicca il risultato migliore per posizionare il pinpoint",
    PH4 : "URL",
    PUBBLICA : "Pubblica",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda la tua proposta",
    SELEZIONAMAPPA : "Indica dove si colloca la tua proposta",
    SELEZIONATRA : "Scegli tra i",
    TITOLO : "Titolo",
    TRASCINA : "Trascina qui l'immagine o clicca qui per caricarla",
    TRASCINADOC : "Trascina qui i file o clicca qui per caricarli",
    TRASCINAIMMAGINI : "Trascina qui le immagini o clicca qui per caricarle",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube che spiega la tua proposta, rendilo visibile a tutti copiando qui l’URL.",
    VIDEOHINT : "Un video aiuta a descrivere meglio una proposta e a raggiungere più persone."
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "Aggiorna il titolo",
    AGGIUNGIALTREIMMAGINI : "Aggiungi altre immagini",
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT1TITLE : "Attenzione",
    ALERT2 : "La proposta è stata inserita correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione della proposta",
    ALERT3 : "Seleziona in quale lingua vuoi pubblicare la tua proposta. Devi selezionarne almeno una",
    ALERT4 : "Ricordati che una volta pubblicata non potrai più modificarla",
    ALERT5 : "Inserisci un valore corretto per indicare il Costo ipotizzato della proposta",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare la tua proposta (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui.",
    ANNULLA : "Annulla",
    AVANTI : "Avanti",
    BOZZA : "Salva in bozza",
    CANCELLA : "Cancella",
    CHALLENGE : "Temi",
    COSTO : "Costo",
    COSTOIPOTIZZATO : "Aggiorna costo ipotizzato",
    DESCRIVI : "Aggiorna la tua sintesi",
    DESCRIZIONE : "Sintesi",
    DOCALLEGATI : "Documenti e allegati",
    DOCTECNICA : "Documentazione tecnica",
    EDITRESOURCESMODALTITLE : "Modifica titolo",
    ERR1 : "Campo obbligatorio",
    FILEACCETTATI : "Dimensione massima del file: 10 MB. Formati supportati: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Dimensione massima del file: 10 MB. Formati supportati: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Dimensione massima del file: 10 MB. Formati supportati: JPG, JPEG, PNG",
    IMMAGINI : "Immagini",
    ISTRUZIONI : "Istruzioni",
    LINKVIDEO : "Link video",
    LOGO : "Immagine di copertina",
    MAPPEEINFO : "Contesto",
    MODIFICA : "Modifica",
    MODIFICAIDEA : "Modifica proposta",
    MOSTRA : "Mostra",
    NARRA : "Descrivi in modo dettagliato la tua proposta",
    NARRAZIONE : "La proposta",
    OVERVIEW : "Informazioni generali",
    PH1 : "Max 600 caratteri",
    PH2 : "URL (solo YouTube)",
    PH3 : "Inizia a digitare l'indirizzo e clicca il risultato migliore per posizionare il pinpoint",
    PH4 : "URL",
    PUBBLICA : "Pubblica",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda la tua proposta",
    SELEZIONAMAPPA : "Indica dove si colloca la proposta",
    SELEZIONATRA : "Seleziona tra le",
    TITLE : "Titolo",
    TITOLO : "Titolo",
    TRASCINA : "Trascina qui l'immagine o clicca qui per caricarla",
    TRASCINADOC : "Trascina qui i file o clicca qui per caricarli",
    TRASCINAIMMAGINI : "Trascina qui le immagini o clicca qui per caricarle",
    URLSITO : "Sito web",
    VIDEO : "Aggiorna video",
    VIDEOHINT : "Video (inserire l'URL)"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "Abbina un contesto",
    AGGIORNATITOLO : "Aggiorna titolo",
    AGGIUNGIALTREIMMAGINI : "Aggiungi altre immagini",
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT1TITLE : "Attenzione",
    ALERT2 : "La tua proposta è stata inserita correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione della proposta",
    ALERT3 : "Seleziona la lingua o le lingue in cui vuoi pubblicare la tua proposta. Devi selezionarne almeno una",
    ALERT4 : "Ricordati che una volta pubblicata non potrai più modificarla",
    ALERT5 : "Inserisci un valore corretto per indicare il costo ipotizzato della proposta",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare la tua proposta (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui.",
    ANNULLA : "Annulla",
    AVANTI : "Avanti",
    BOZZA : "Salva in bozza",
    CANCELLA : "Cancella",
    CHALLENGE : "Temi",
    COSTO : "Costo",
    COSTOIPOTIZZATO : "Aggiorna costo ipotizzato",
    DESCRIVI : "Aggiorna la tua sintesi",
    DESCRIZIONE : "Sintesi",
    DOCALLEGATI : "Documenti e allegati",
    DOCTECNICA : "Documentazione tecnica",
    EDITRESOURCESMODALTITLE : "Modifica il titolo",
    ELENCOIDEE : "Tutte le Proposte",
    ERR1 : "Campo obbligatorio",
    FILEACCETTATI : "Dimensione massima del file: 10 MB. Formati supportati: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Dimensione massima del file: 10 MB. Formati supportati: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Dimensione massima del file: 10 MB. Formati supportati: JPG, JPEG, PNG",
    IMMAGINI : "Immagini",
    ISTRUZIONI : "Istruzioni",
    LINKVIDEO : "Link video",
    LOGO : "Immagine di copertina",
    MAPPEEINFO : "Contesto",
    MODIFICA : "Modifica",
    MODIFICAIDEA : "Modifica la proposta",
    MOSTRA : "Mostra",
    NARRA : "Descrivi in modo dettagliato la tua proposta",
    NARRAZIONE : "La proposta",
    NUOVAVERSIONE : "Crea una nuova versione",
    NUOVAVERSIONEMESSAGGIO1 : "Stai creando la",
    NUOVAVERSIONEMESSAGGIO2 : "VERSIONE",
    NUOVAVERSIONEMESSAGGIO3 : "della tua proposta",
    OVERVIEW : "Descrizione",
    PH1 : "Hai a disposizione 600 caratteri",
    PH2 : "URL (solo YouTube)",
    PH3 : "Inizia a digitare l'indirizzo e clicca il risultato migliore per posizionare il pinpoint",
    PH4 : "URL",
    PROCESSO : "Processo",
    PUBBLICA : "Pubblica",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda la tua proposta",
    SELEZIONAMAPPA : "Indica dove si colloca la tua proposta",
    SELEZIONATRA : "Seleziona tra le",
    TITLE : "Titolo",
    TITOLO : "Titolo",
    TRASCINA : "Trascina qui l'immagine o clicca qui per caricarla",
    TRASCINADOC : "Trascina qui i file o clicca qui per caricarli",
    TRASCINAIMMAGINI : "Trascina qui le immagini o clicca qui per caricarle",
    URLSITO : "Sito web",
    VIDEO : "Aggiorna video",
    VIDEOHINT : "Video (inserire l'URL)"
  },
  MODULEINFO : {
    ELENCOPROCESSI : "Elenco Processi",
    INFO : "Modulo info del processo",
    INPROGRESS : "In progress ...",
    PROCESSO : "Processo"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "Elenco Processi",
    INFO : "Modulo in costruzione",
    INPROGRESS : "In progress ...",
    PROCESSO : "Processo"
  },
  MODULESERVICECREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il servizio è stato inserito correttamente!",
    ALERT3 : "Inserisci il costo del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    CREAIDEA : "Descrivi il tuo servizio",
    DAIBREVETITOLO : "Dai un titolo breve ma accattivante al tuo servizio",
    DESCRIVI : "Descrivi in poche righe il tuo servizio. Puoi dettagliarlo al prossimo step",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine che rappresenti il tuo servizio",
    IMMAGINI : "Pubblica altre immagini utili a far comprendere meglio il tuo servizio",
    INDICACOSTO : "Indica quanto potrebbe costare il tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli a quale o a quali si riferisce il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube che spiega il tuo servizio, rendilo visibile a tutti copiando qui l’URL",
    VIDEOHINT : "Un video aiuta a descrivere meglio un servizio e a raggiungere più persone"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "Il servizio è stato caricato correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione del servizio",
    ALERT3 : "Seleziona la lingua in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    MODIFICAIDEA : "Modifica i contenuti",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il servizio",
    URLSITO : "Sito web"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il tuo servizio è stato inserito correttamente!",
    ALERT2TITLE : "Stai per pubblicare una versione aggiornata del tuo servizio",
    ALERT3 : "Seleziona la lingua o le lingue in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALERT5 : "Inserisci un valore corretto per indicare il costo ipotizzato del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    ELENCOIDEE : "Tutte i servizi",
    MODIFICAIDEA : "Modifica i contenuti del tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    NUOVAVERSIONEMESSAGGIO3 : "del tuo servizio",
    SCEGLISFIDE : "Scegli quale o quali tematiche vuole affrontare il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web"
  },
  MODULESTORYCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "La storia è stata pubblicata correttamente!",
    ALERT3 : "Inserisci un valore per indicare il costo ipotizzato della tattica",
    ALTROMATERIALE : "Se hai altro materiale utile a raccontare la tua storia caricalo qui.",
    CREAIDEA : "Racconta la tua storia",
    DAIBREVETITOLO : "Dai un titolo breve e significativo alla tua storia",
    DESCRIVI : "Descrivi in poche righe la tua storia",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine rappresentativa della tua storia",
    IMMAGINI : "Pubblica altre immagini che raccontino meglio la tua storia.",
    INDICACOSTO : "",
    NARRA : "Racconta in dettaglio la tua storia",
    NARRAZIONE : "La storia",
    SCEGLISFIDE : "Scegli quale o quali temi tratta la tua storia",
    SELEZIONAMAPPA : "Indica dove è avvenuta la tua storia",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube dove racconti la tua storia, rendilo visibile a tutti copiando qui l’URL.",
    VIDEOHINT : "Un video aiuta a raccontare meglio una storia e a raggiungere più persone."
  },
  MODULESTORYEDIT : {
    ALERT2 : "La storia è stata inserita correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione della storia",
    ALERT3 : "Seleziona in quale lingua vuoi pubblicare la tua storia. Devi selezionarne almeno una",
    ALTROMATERIALE : "Se hai altro materiale utile a raccontare la tua storia caricalo qui.",
    MODIFICAIDEA : "Modifica la storia",
    NARRA : "Racconta in dettaglio la tua storia",
    NARRAZIONE : "La storia",
    SCEGLISFIDE : "Scegli quale o quali temi tratta la tua storia",
    SELEZIONAMAPPA : "Indica dove è avvenuta la tua storia",
    URLSITO : "Sito web"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "La storia è stata pubblicata correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione della tua storia",
    ALERT3 : "Seleziona in quale lingua vuoi pubblicare la tua storia. Devi selezionarne almeno una",
    ALERT5 : "",
    ALTROMATERIALE : "Se hai altro materiale utile a raccontare la tua storia caricalo qui.",
    ELENCOIDEE : "Tutte le storie",
    MODIFICAIDEA : "Modifica la storia",
    NARRA : "Racconta in dettaglio la tua storia",
    NARRAZIONE : "La storia",
    NUOVAVERSIONEMESSAGGIO3 : "della tua storia",
    SCEGLISFIDE : "Scegli quale o quali temi tratta la tua storia",
    SELEZIONAMAPPA : "Indica dove è avvenuta la tua storia",
    URLSITO : "Sito web"
  },
  MODULETACTICCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "La tattica è stata inserita correttamente!",
    ALERT3 : "Inserisci un valore per indicare il costo ipotizzato della tattica",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare la tattica (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui.",
    CREAIDEA : "Inserisci la tattica",
    DAIBREVETITOLO : "Dai un titolo breve ma accattivante alla tattica",
    DESCRIVI : "Descrivi in poche righe la tattica. Puoi dettagliarla al prossimo step",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine che rappresenti la tattica",
    IMMAGINI : "Pubblica altre immagini utili a far comprendere meglio la tattica.",
    INDICACOSTO : "Indica quanto potrebbe costare la tattica",
    NARRA : "Descrivi in modo dettagliato la tattica",
    NARRAZIONE : "La tattica",
    SCEGLISFIDE : "Scegli quale o quali temi tratta la tattica",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube che spiega la tattica, rendilo visibile a tutti copiando qui l’URL.",
    VIDEOHINT : "Un video aiuta a descrivere meglio una tattica e a raggiungere più persone."
  },
  MODULETACTICEDIT : {
    ALERT2 : "La tattica è stata inserita correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione della tattica",
    ALERT3 : "Seleziona in quale lingua vuoi pubblicare la tattica. Devi selezionarne almeno una",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare la tua tattica (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui.",
    MODIFICAIDEA : "Modifica tattica",
    NARRA : "Descrivi in modo dettagliato la tattica",
    NARRAZIONE : "La tattica",
    SCEGLISFIDE : "Scegli quale o quali temi tratta la tattica",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "La tattica è stata inserita correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione della tattica",
    ALERT3 : "Seleziona la lingua o le lingue in cui vuoi pubblicare la tattica. Devi selezionarne almeno una",
    ALERT5 : "Inserisci un valore corretto per indicare il costo ipotizzato della tattica",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare la tattica(disegni, approfondimenti, specifiche tecniche, ...), caricalo qui.",
    ELENCOIDEE : "Tutte le tattiche",
    MODIFICAIDEA : "Modifica la tattica",
    NARRA : "Descrivi in modo dettagliato la tattica",
    NARRAZIONE : "La tattica",
    NUOVAVERSIONEMESSAGGIO3 : "della tattica",
    SCEGLISFIDE : "Scegli a quale o a quali temi è legata la tattica",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "Community",
    ISCRITTI : "Users"
  },
  PARTIALSIDEE : {
    APPROVED : "Approved",
    BUDGET : "Budget: €",
    CREATADA : "Creata da",
    RANK01 : "Rank 01",
    VOTATA : "VOTATA"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "<h4>Non puoi accedere con il tuo account. Richiedi un accesso autenticato a <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>",
    ALERT1TITLE : "<b>Il gruppo è privato</b>",
    ALERT2 : "Potrai accedere quando sarà avviato!",
    ALERT2TITLE : "La fase è in attivazione.",
    ALERT3 : "<h4>Non puoi accedere con il tuo account. Richiedi un accesso autenticato a <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>",
    ALERT3TITLE : "<b>Il processo è privato</b>",
    COMMENTI : "Commenti",
    COMMUNITY : "Community",
    CONCLUSA : "Conclusa",
    CONCLUSO : "Concluso",
    DAATTIVARE : "Da attivare",
    DRIVERAMBIENTE : "Driver: Ambiente",
    FASE : "Fase",
    INATTIVAZIONE : "In attivazione",
    INCORSO : "In corso",
    PARTECIPA : "Partecipa",
    PRIVATO : "Privato",
    PUBBLICO : "Pubblico",
    RICHIEDIACCESSO : "Richiedi accesso",
    VALUTAZIONI : "Valutazioni",
    VISUALIZZA : "Visualizza",
    VOTA : "Entra e vota"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "<h4>Abbiamo inviato un codice SMS di verifica al tuo cellulare.</h4>",
    ALERT1TITLE : "Info",
    ALERTPHONE1HTML : "Il numero fornito non risulta registrato presso l'organizzazione",
    ALERTPHONE1TITLE : "Utente sconosciuto",
    CAMBIAPASSWORD : "Cambia la password",
    CODICEOTP : "Codice OTP*",
    CONFERMA : "Conferma",
    CONFERMAPASSWORD : "Conferma la password",
    ERROROTPMAXCHAR : "Numero massino di cifre inseribili 6",
    ERRORPWEQUAL : "Le password devono essere uguali",
    ERRORPWLENGTH : "La password deve essere lunga almeno 6 caratteri",
    INSERISCICELLULARE : "Inserisci cellulare",
    INSERISCIPASSWORD : "Inserisci la password",
    METODOCELLULARE : "Cellulare",
    METODORECUPERO : "Metodo di recupero",
    OTPDIMENTICATO : "Hai dimenticato di inserire il codice OTP",
    PHONEERROR1 : "Hai dimenticato di inserire il prefisso",
    PHONEERROR2 : "Hai per caso dimenticato di inserire il tuo numero di cellulare?",
    PHONEERROR3 : "Il numero che hai inserito è un po’ strano … controlla bene! :)",
    PROCEDI : "procedi",
    RECUPEROPASSWORD : "Recupera la tua password",
    RECUPERORIUSCITO : "Cambio password completato con successo",
    RICHIEDINUOVOCODICE1 : "<b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti",
    RICHIEDINUOVOCODICE2 : "richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : "o scrivici a",
    SCELTAMETODO : "Scegli come recuperarla",
    SCELTAMETODONECESSARIA : "La scelta del metodo è obbligatoria",
    VERIFICA1 : "Verifica il tuo numero di telefono",
    VERIFICA2 : "Ti abbiamo inviato un SMS con un codice di verifica OTP. Inseriscilo per accedere al tuo account e creare la tua nuova password."
  },
  PHASEMODULECARDIDEA : {
    IDEE : "proposte"
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "servizi"
  },
  PHASEMODULECARDSTORY : {
    IDEE : "storie"
  },
  PHASEMODULECARDTACTIC : {
    IDEE : "tattiche"
  },
  PORTAL : {
    CONTACTUS : "Contattaci",
    DELIBERATIVESPACE : "Spazio deliberativo",
    HOME : "Home",
    INSTRUMENTS : "Gli strumenti",
    PROJECT : "Il progetto",
    SUBSCRIBE : "Abbonati"
  },
  PRIVACY : {
    DIRITTO : "6. Diritto di accesso e controllo dei dati personali, diritto di opposizione",
    DIRITTODESCRIZIONE1 : "In ogni momento Lei ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile; può ottenere l’indicazione dell’origine dei Suoi dati personali, delle finalità e modalità del loro trattamento, della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici, degli estremi identificativi del Titolare, dei responsabili (se nominati) e della tipologia dei soggetti ai quali i Suoi dati personali possono essere comunicati, trasferiti, o che possono venirne a conoscenza in qualità di responsabili o incaricati.",
    DIRITTODESCRIZIONE2 : "Lei ha altresì il diritto di accedere a qualsiasi Suo dato personale trattato da BiPart e potrà, chiedere la modifica, correzione, copia, restrizione o estensione della condivisione, anonimizzazione, portabilità o eliminazione (anche parziale) dei Suoi dati personali. Lei ha altresì il diritto di revocare in qualsiasi momento il consenso al trattamento dei Suoi dati da parte del Titolare.<br>Potrà esercitare i predetti diritti inviando una richiesta scritta alla sede legale di BiPart Impresa sociale srl (già BiPart Srls), sita in Milano, via Metauro, 4 mediante lettera raccomandata ovvero email al seguente indirizzo PEC bipart@casellapec.com.\nCon comunicazione da inviarsi al medesimo recapito Lei potrà altresì esercitare il diritto di opporsi in qualsiasi momento, per motivi connessi alla Sua situazione particolare, al trattamento (anche soltanto parziale) dei dati personali che La riguardano, ancorché pertinenti allo scopo della raccolta e al trattamento di dati personali a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale. Da tale data in avanti il Titolare si asterrà dal trattare ulteriormente i Suoi dati personali salvi i diritti riconosciutigli ex lege.\nPer avere ulteriori informazioni in ordine ai Suoi diritti, La invitiamo a visitare il sito web dell’Autorità Garante per la protezione dei dati personali all’indirizzo www.garanteprivacy.it.",
    FINALITA : "3. Finalità del trattamento dei suoi dati",
    FINALITADESCRIZIONE1 : "Il trattamento dei Suoi dati ha la finalità di consentire l’utilizzo della Piattaforma e l’erogazione di tutti i servizi connessi e/o strumentali alla stessa ovvero di tutte le attività legate allo sviluppo e realizzazione dei progetti di bilancio partecipativo e di partecipazione in generale con i soggetti, pubblici e privati, coinvolti in tali progetti gestiti mediante la Piattaforma da BiPart; il trattamento trova quindi la propria base giuridica negli accordi di volta in volta conclusi dal Titolare con i soggetti (pubblici e privati) coinvolti nei progetti di bilancio partecipativo gestiti da BiPart, anche mediante la Piattaforma.",
    FINALITADESCRIZIONE2 : "Per il conseguimento di tali finalità, ed a titolo meramente esemplificativo, i Suoi dati personali potranno essere utilizzati e comunicati a soggetti terzi per l’evasione dell'iscrizione, per la verifica della correttezza dei dati anagrafici inseriti, per l’esecuzione e gestione del servizio, per il data entry, per l’elaborazione in forma statistica della partecipazione alla Piattaforma (utilizzo dei dati socio-demografici e geografici degli iscritti). Inoltre, i Suoi dati personali saranno trattati per assolvere ad obblighi di legge, contabili e fiscali. \nPrecisiamo pertanto che il mancato conferimento dei dati personali a tali fini potrebbe implicare l’impossibilità – totale o parziale – per BiPart di fornire la prestazione richiesta e/o svolgere i relativi servizi connessi e/o strumentali.",
    FINALITADESCRIZIONE3 : "I Suoi dati personali saranno conservati da BiPart fino a quando il Suo account sarà aperto. BiPart, a decorrere dalla disattivazione dell’account, provvederà alla cancellazione dei Suoi dati personali, avendo comunque la facoltà di renderli anonimi e conservarli esclusivamente per la creazione di un modello di utenza (che tenga conto esclusivamente del sesso, dell’età e, se disponibile, dell’area geografica) utile per la ricerca scientifica e statistica legata alla diffusione e sviluppo della prassi di bilancio partecipativo.",
    INTRO : "1. Introduzione",
    INTRODESCRIZIONE1 : "BiPart Impresa sociale srl (già BiPart Srls), C.F. e P.IVA 094444700968, con sede in Milano, via Metauro n. 4, in qualità di titolare del trattamento dei dati (di seguito anche l’“BiPart” o anche il “Titolare”), ai sensi di quanto previsto dal D.Lgs. 30 giugno 2003 n. 196 e ss. modifiche (di seguito “Codice Privacy”) e dal Regolamento UE n. 2016/679 (di seguito “GDPR”), intende informarLa di quali saranno le modalità e le finalità del trattamento dei dati personali da Lei forniti.",
    INTRODESCRIZIONE2 : "Gli utenti iscritti (di seguito i “Membri”) hanno la possibilità di partecipare alle attività rese possibili dalla Piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione) nonché ai vari progetti di bilancio partecipativo, anche interagendo tra loro, mediante scambio di conoscenze, proposte ed idee, sia in modalità privata che pubblica. I contenuti ed i dati su alcuni dei servizi offerti con la Piattaforma sono pertanto visualizzabili dai Membri così come pure da soggetti non iscritti (i “Visitatori”). Lei utilizzando la Piattaforma presta dunque il Suo proprio specifico, preventivo, libero e facoltativo consenso al trattamento dei Suoi dati secondo quanto indicato nella presente informativa.",
    MANIFESTO : "7. Manifesto del consenso",
    MANIFESTODESCRIZIONE1 : "Qualora i termini della presente informativa siano da Lei condivisi, BiPart Le chiede di autorizzazione e acconsentire al trattamento dei Suoi dati personali nelle modalità e finalità qui indicate, mediante approvazione espressa. In segno inequivocabile del Suo consenso Le chiediamo quindi di barrare la presente casella.",
    MODALITA : "4. Modalità di trattamento e sicurezza dei suoi dati",
    MODALITADESCRIZIONE1 : "I Suoi dati personali saranno raccolti e trattati principalmente con strumenti elettronici ed informatici e memorizzati sia su supporti informatici che su supporti cartacei e su ogni altro tipo di supporto idoneo da parte dei soggetti a ciò espressamente autorizzati dal Titolare, tutti debitamente istruiti in materia di sicurezza dei dati personali e del diritto alla privacy. Secondo la normativa indicata dal Codice Privacy e dal GDPR, tale trattamento sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.",
    MODALITADESCRIZIONE2 : "Per evitare l’accesso non autorizzato o la divulgazione e assicurare l’utilizzo appropriato dei Suoi dati personali, BiPart ha messo in atto procedure amministrative, tecniche e fisiche ragionevoli e appropriate che verranno costantemente adeguate alla normativa tempo per tempo vigente in materia di protezione dei dati personali. Al fine di garantire il rispetto degli standard di cui al Codice Privacy e al GDPR il trattamento dei Suoi dati personali sarà svolto esclusivamente in Paesi appartenenti all’Unione Europea.",
    MODALITADESCRIZIONE3 : "BiPart si impegna ad adeguare la propria privacy policy alle disposizioni di legge e regolamentari, pertanto è possibile che la presente informativa subisca, nel corso del tempo, delle modifiche di aggiornamento. In caso di modifiche significative alla nostra informativa sulla privacy, che alterino notevolmente le nostre prassi in materia di privacy, BiPart avrà cura di avvisarLa anche attraverso altri mezzi, ad esempio inviando un'e-mail o pubblicando un avviso sul nostro sito web e/o sulle pagine dei social media prima che le modifiche entrino in vigore.",
    SOGGETTI : "5. Soggetti a cui potranno essere comunicati i dati personali",
    SOGGETTIDESCRIZIONE1 : "I Suoi dati personali potranno essere oggetto di comunicazione o diffusione:",
    SOGGETTIDESCRIZIONE2 : "<li>alle Amministrazioni Pubbliche, agli Enti Locali e più in generale ai soggetti, anche privati, che aderiscono o prendono parte ai progetti di bilancio partecipativo gestiti da BiPart anche mediante la Piattaforma;</li><li>ai soggetti autorizzati che prestino attività di assistenza e consulenza in materia amministrativa, tecnico/informatica, tributaria, contabile, finanziaria e legale;</li><li>a soggetti terzi in adempimento ad obblighi di legge.</li>",
    SOGGETTIDESCRIZIONE3 : "I predetti soggetti opereranno in qualità di Responsabili o comunque di soggetti autorizzati al trattamento dei Suoi dati personali nei limiti qui da Lei autorizzati. Ai soggetti autorizzati e ai Responsabili, ove nominati, BiPart impartirà adeguate istruzioni operative, al fine di poter garantire la riservatezza e la sicurezza dei Suoi dati personali.",
    SOTTOTITOLO : "Trattamento dei dati personali",
    TIPOLOGIA : "2. Tipologia dei dati raccolti",
    TIPOLOGIADESCRIZIONE1 : "Per creare un account attivo nella piattaforma Lei deve fornire alcuni dati personali che includono il Suo nome, cognome, indirizzo email e password (dati essenziali). La Piattaforma Le consente la possibilità di utilizzare le opzioni di implementazione del Suo profilo integrando e fornendo informazioni ulteriori sulla Sua persona che possono includere, a titolo esemplificativo e non esaustivo, l’indicazione dell’area geografica o la specificazione della località in cui risiede, il titolo di studio, o una Sua fotografia. Parimenti, è possibile che nell’utilizzo della Piattaforma Le sia consentito di condividere la Sua rubrica ed i Suoi contatti. Non è necessario che pubblichi o inserisca tali dati personali, precisiamo comunque che è Sua facoltà scegliere espressamente se, una volta inseriti, tali dati possano essere pubblicamente visibili da altri Membri o meno.",
    TIPOLOGIADESCRIZIONE2 : "E’ dunque rimessa esclusivamente a Lei la scelta di comunicare e inserire nella Piattaforma, e nel Suo profilo, informazioni di natura sensibile (idonei a rivelare l’origine razziale ed etnica, le convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l’adesione a partiti, sindacati, associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale, nonché i dati personali idonei a rivelare lo stato di salute e la vita sessuale), così come pure di renderle pubbliche. La invitiamo quindi a non pubblicare dati che non vuole rendere pubblicamente disponibili, dovendosi precisare che con l’inserimento di tali dati nel Suo account e nella Piattaforma, ne autorizza il trattamento.",
    TITOLO : "Informativa sulla privacy"
  },
  PROCESSABOUT : {
    FASE : "Fase",
    TITOLO : "Il processo partecipativo"
  },
  PROCESSCONTATTI : {
    CONTATTI : "Contatti"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "Budget disponibile:",
    BUDGETPROPOSTO : "Budget proposto:",
    CONCLUSA : "Conclusa",
    DAATTIVARE : "Da attivare",
    FASIPROCESSO : "Le fasi del processo",
    INCORSO : "In corso",
    ITUOIVOTI : "I tuoi voti",
    PROGRESS : "Progress"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : "Domande & Risposte"
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : "Linee guida"
  },
  PROCESSLIST : {
    BUDGET : "Budget",
    DATAINIZIO : "Data di inizio",
    DATAINS : "Data di inserimento",
    PARTECIPANTI : "N partecipanti",
    PROCESSI : "processi",
    TITOLO : "Processi partecipativi"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "Tutti gli argomenti su cui riflettere e trovare soluzioni all’interno della propria comunità",
    NOTEMATICHE : "Non ci sono temi",
    OBIETTIVI : "Obiettivi e temi per l’organizzazione"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "Community",
    GRUPPI : "gruppi",
    TUTTI : "Tutti i gruppi della comunità."
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "Contesto dell’organizzazione",
    INFO : "Informazioni sul contesto",
    NOSCENARI : "Non è presente un contesto"
  },
  PROCESSMENU : {
    ABOUT : "Il processo",
    ASSISTENZA : "Help Desk",
    BACK : "Indietro",
    COMMUNITY : "Community",
    FAQ : "Domande & Risposte",
    ISTRUZIONI : "Linee guida",
    NARRAZIONE : "Altre informazioni",
    POP1 : "Processo",
    POP1DESCRIPTION : "Informazioni sul processo"
  },
  PROCESSPHASE : {
    ALERTBOZZA : "Hai una versione in bozza. Puoi trovarla nel tuo",
    BREADCRUMBTITLE : "Fase",
    COMMENTIMENONUMEROSI : "Meno commentate",
    COMMENTIPIUNUMEROSI : "Più commentate",
    CONTATOREMODULI : "aree",
    IDEE : "proposte",
    INFORMAZIONI : "informazioni",
    NAVIGATUTTELEPROPOSTE : "Naviga tra tutte le proposte",
    NONCISONOPROPOSTE : "Non ci sono proposte",
    PS : "Area personale",
    SCEGLIDOVEPARTECIPARE : "Scegli dove partecipare"
  },
  PS : {
    ALERTMODIFICADISABILITATA : "<h4>Non è possibile modificare il profilo.</h4>",
    ALERTMODIFICADISABILITATATITLE : "<b>La modifica del tuo profilo è disabilitata</b>",
    ANNUALINCOME : "Reddito annuale",
    ATTEGGIAMENTO : "Atteggiamento",
    BIOGRAPHY : "Biografia",
    BUDGET : "Budget",
    BUDGETTODONATE : "Budget da donare",
    COMPORTAMENTO : "Comportamento",
    EDU : "Educazione",
    EDULAV : "Educazione e lavoro",
    FEMMINA : "Femmina",
    LAV : "Lavoro",
    MASCHIO : "Maschio",
    MODIFICA : "Modifica profilo",
    NS : "Non specificato",
    POLVIS : "Visione politica",
    PROFILE : "Il tuo profilo personale",
    PS : "Area personale"
  },
  PSACCOUNT : {
    ANONIMO : "Utente anonimo",
    AS : "Configurazioni",
    CITIZENSHIP : "Cittadinanza",
    COGNOME : "Cognome",
    COGNOMENONPRESENTE : "Cognome non presente",
    ELIMINAACCOUNT : "<h4> Scrivici a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> e rimuoveremo i dati del tuo account.</h4>",
    ELIMINAACCOUNTTITLE : "<b>Vuoi eliminare il tuo account?</b>",
    EMAIL : "Email",
    EMAILNONPRESENTE : "Email non presente",
    NOME : "Nome",
    NOMENONPRESENTE : "Nome non presente",
    PASSWORD : "Password",
    PHONE : "Cellulare",
    PHONENONPRESENTE : "Cellulare non presente",
    PRIVATO : "privato",
    PS : "Area personale",
    PUBBLICO : "pubblico",
    RESIDENCECITY : "Città di residenza",
    TAXCODE : "Codice fiscale/Partita IVA",
    TAXCODENONPRESENTE : "Codice fiscale/Partita IVA non presente",
    TUOPROFILO : "Il tuo profilo è",
    USERNAME : "Username",
    USERNAMENONPRESENTE : "Username non presente"
  },
  PSACTIVITIES : {
    ALERTDETAIL : "<h4>In questa sezione trovi l’elenco delle tue proposte e le proposte che hai votato.</h4>",
    ALERTINFO : "<b>Informazioni</b>",
    ATTIVITA : "Tutte le tue attività",
    BUDGETDONATO : "Budget donato",
    FOLLOWERS : "Community",
    IDEESEGUITE : "Proposte seguite",
    IDEEVOTATE : "Proposte votate",
    INBOZZA : "Bozza",
    MODIFICAVOTO : "Modifica voto",
    NOPROCESSI : "Non fai ancora parte di nessun processo!",
    NOPROCESSIATTIVI : "Non ci sono processi attivi",
    PS : "Area personale",
    PUBBLICATA : "Pubblicata",
    TUEPROPOSTE : "I tuoi contributi",
    TUOIPROCESSI : "I tuoi processi",
    TUOIVOTI : "I tuoi voti",
    VISUALIZZA : "Visualizza",
    VOTIESPRESSI : "Voti espressi"
  },
  PSEDIT : {
    AS : "Configurazioni",
    CONFERMAPASSWORD : "Conferma password",
    DRAGANDDROP : "Drag and drop per caricare  il tuo avatar (Max size 5 MB)",
    ERRORE : "Attenzione!",
    ERROREPASS : "Le due password devono coincidere e non possono essere vuote",
    FORMSUPPORTATI : "I formati supportati sono JPG, GIF, PNG",
    MODAVATAR : "Modifica avatar",
    MODIFICADATI : "Modifica dei dati di",
    MODOK : "L'utente è stato modificato correttamente",
    MODPASS : "Modifica Password",
    NUOVAPASSWORD : "Nuova password",
    PRIVACY : "Privacy & Policy",
    PROFILOPUBBLICO : "Profilo Pubblico",
    PS : "Area personale",
    SALVA : "Salva",
    SUCCESSO : "Successo"
  },
  PSEDIT2 : {
    AS : "Il tuo profilo personale",
    ATTEGGIAMENTO : "Atteggiamento",
    BIOGRAFIA : "Biografia",
    BUDGET : "Budget",
    BUDGETDADONARE : "Budget da donare",
    COMPORTAMENTO : "Comportamento",
    EDU : "Educazione",
    EDULAV : "Educazione e Lavoro",
    INFOVARIE : "Informazioni varie",
    LAV : "Lavoro",
    PS : "Area personale",
    REDDITO : "Reddito annuo",
    SALVA : "Salva",
    VISIONEPOLITICA : "Visione politica"
  },
  QUICKPANEL : {
    VOTE: "Voto {n}",
    VOTEDON: "Votata il {n}",
    EDITVOTE: "Modifica voto",
    DONATION: "Donazione"
  },
  QUICKUSER : {
    ACCOUNT : "Account",
    AGGIORNAPROFILOEPRIVACY : "Aggiorna profilo e privacy",
    IDEEVOTATE : "I tuoi contributi e le tue interazioni su BiPart",
    PROCESSIPARTECIPATIVI : "Processi partecipativi",
    SALUTO : "Ciao",
    SIGNOUT : "Esci",
    VOTI : "Attività"
  },
  REGISTER : {
    ACCETTOLA : "Ho letto e accetto l'",
    ATTENZIONE : "Attenzione",
    BACCEDI : "Accedi",
    BACCETTO : "Ho letto e accetto i",
    BREGISTRA : "registra",
    COGNOMEDIMENTICATO : "Hai dimenticato di inserire il cognome",
    GIAACCOUNT : "Hai già un account?",
    MAILDIMENTICATA : "Hai dimenticato di inserire l'email",
    MAILNONVALIDA : "Indirizzo email non valido.",
    NEWSLETTER : "Voglio rimanere aggiornato sui progetti e sulle iniziative attive su BiPart",
    NOMEDIMENTICATO : "Hai dimenticato di inserire il nome",
    PASSWORDDIMENTICATA : "Hai dimenticato di inserire la password",
    PLACEHOLDERCOGNOME : "Cognome",
    PLACEHOLDEREMAIL : "Email",
    PLACEHOLDERNOME : "Nome",
    PLACEHOLDERPASSWORD : "Password",
    PLACEHOLDERUTENTE : "Username",
    PRIVACY : "Informativa sulla privacy",
    TERMINICONDIZIONI : "Termini e condizioni",
    USERNAMEDIMENTICATO : "Hai dimenticato di inserire lo username",
    USERNAMEFORBIDDENCHARACTERS : "Lo username non può contenere i seguenti caratteri: @",
    USERNAMEMINLENGTH : "Lo username deve essere lungo almeno 3 caratteri"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "Ho letto e accetto i",
    ACCETTOLA : "Ho letto e accetto l'",
    BCONFERMA : "conferma",
    DETTMEX : "Abbiamo inviato un’email* alla tua casella di posta.<br><span class=\"font-weight-bolder\">Confermala</span> per entrare a far parte dell’organizzazione.</p><p class=\"font-weight-bolder\">* Attenzione! Controlla anche la cartella spam.",
    ERR1 : "Non hai inserito i dati obbligatori. Riprova per favore oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    ERR2 : "Ops... qualcosa non ha funzionato; Status code:",
    GIAACCOUNT : "Hai già un account?",
    GRAZIE : "Grazie per esserti unito alla nostra community",
    ISCRIVITIPROCESSO : "Iscriviti al processo",
    NEWSLETTER : "Voglio rimanere aggiornato sui progetti e sulle iniziative attive su BiPart",
    PRIVACY : "Informativa sulla privacy",
    REQUIREDFIELD : "Campo richiesto",
    TERMINI : "termini e condizioni",
    TITLE2 : "Attenzione",
    TITLE3 : "Errore",
    VALIDEMAIL : "Indirizzo email non valido."
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "Ho letto e accetto l'",
    BACCEDI : "Accedi",
    BACCETTO : "Ho letto e accetto i",
    BCHIUDI : "Chiudi",
    BREGISTRA : "ISCRIVITI",
    COGNOMEDIMENTICATO : "Hai dimenticato di inserire il cognome",
    ERR1 : "Ops... qualcosa non ha funzionato; Status code:",
    FAIGIAPARTE : "Ti sei già iscritta o iscritto o hai le credenziali per partecipare?",
    ISCRIVITIPROCESSO : "Iscriviti all'organizzazione ed al processo",
    MAILNONVALIDA : "Indirizzo email non valido.",
    NEWSLETTER : "Voglio rimanere aggiornato sui progetti e sulle iniziative attive su BiPart",
    NOMEDIMENTICATO : "Hai dimenticato di inserire il nome",
    PASSWORDDIMENTICATA : "Hai dimenticato di inserire la password",
    PRIVACY : "Informativa sulla privacy",
    TERMINICONDIZIONI : "Termini e condizioni",
    TITLE1 : "Errore",
    USERNAMEDIMENTICATO : "Hai dimenticato di inserire lo username"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "Accesso riuscito",
    ACCETTO : "Ho letto e accetto i",
    ACCETTOLA : "Ho letto e accetto l'",
    AUTORIZZATO : "Sei autorizzato a partecipare alla nostra community",
    BCONFERMA : "conferma",
    DETTMEX : "Abbiamo inviato un’email* alla tua casella di posta.<br><span class=\"font-weight-bolder\">Confermala</span> per entrare a far parte dell’organizzazione.</p><p class=\"font-weight-bolder\">* Attenzione! Controlla anche la cartella spam.",
    ERR1 : "Non hai inserito i dati obbligatori. Riprova per favore oppure scrivici a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    ERR3 : "Ops... si è verificato un errore inatteso",
    ERR4 : "Email non valida",
    ERR5 : "Iscrizione e accettazione dell'Informativa sulla privacy e dei Termini e delle condizioni d'uso",
    ES : "Es: Faenza Partecipa 2020/21",
    GIAACCOUNT : "Hai già un account?",
    GRAZIE : "Grazie per esserti unito alla nostra community",
    HTMLMAIL : "Qui trovi html mail",
    ISCRIVITIPROCESSO : "Iscriviti al processo",
    NEWSLETTER : "Voglio rimanere aggiornato sui progetti e sulle iniziative attive su BiPart",
    NIENTEACCOUNT : "Non hai ancora un account?",
    NOME : "Nome (facoltativo)",
    ORGANIZATION : "BiPart",
    PARTECIPAPROCESSO : "Vuoi partecipare al processo?",
    PRIVACY : "Informativa sulla privacy",
    PROPOSTE : "Per fare proposte, commentare, richiedere valutazioni, votare devi iscriverti al processo.",
    QUIMAIL : "Qui trovi html email inviata",
    REGISTRATI : "Iscrizione e accettazione dell'Informativa sulla privacy e dei Termini e delle condizioni d'uso",
    TERMINI : "termini e condizioni",
    TITLE1 : "Congratulazioni",
    TITLE2 : "Attenzione",
    TITLE3 : "Errore",
    TITOLO : "BiPart"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "Accedi",
    ACCETTO : "Ho letto e accetto i",
    ACCETTOLA : "Ho letto e accetto l'",
    BPROSEGUI : "Prosegui",
    CAMBIAPASS : "Cambia password",
    ERR3 : "Ops... si è verificato un errore inatteso",
    ERR4 : "Email non valida",
    ERR5 : "Credenziali errate",
    INSERISCIMAIL : "Inserisci la tua mail",
    ISCRIVITIPROCESSO : "Iscriviti al processo",
    MEX1 : "Iscrizione e accettazione dell'Informativa sulla privacy e dei Termini e delle condizioni d'uso",
    MEX2 : "Autenticazione riuscita",
    MEXLOGIN : "<b>Benvenut* su BiPart,</b><br>la piattaforma della partecipazione.<br>I tuoi dati saranno trattati nel pieno rispetto delle normative italiane ed europee e con ancora più cura, a tutela delle tue opinioni e delle tue decisioni.<br><b>Se vuoi proseguire, leggi e accetta le privacy policy e i termini e condizioni del servizio.</b>",
    NIENTEACCOUNT : "Non hai ancora un account?",
    NONRICORDIPASS : "Non ricordi la password?",
    NOTIFICHE : "",
    PRIVACY : "Informativa sulla privacy",
    REGISTRATI : "Iscriviti",
    TERMINI : "Termini e condizioni del servizio",
    TERMINICONDIZIONI : "Per proseguire prendi visione dell'<a href=\"https://bipart.it/privacy\" target=\"blank\">Informativa sulla privacy</a> e dei <a href=\"https://bipart.it/terms\" target=\"blank\">Termini e delle condizioni d'uso</a>",
    TITLE1 : "Successo",
    TITLE2 : "Attenzione",
    TITLE3 : "Errore",
    UTENTEAUTENTICATO : "-- Utente autenticato --"
  },
  SERVICEDETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato al servizio",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questo servizio",
    ALERT14 : "Il servizio è già stato valutato",
    ALERT3TITLE : "Grazie per aver votato questo servizio",
    ALERT4 : "Naviga tra i servizi e scegli quali altre preferisci di più",
    ALERT5 : "Puoi continuare a donare anche per altri servizi scegliendo come distribuire il tuo budget a disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto il servizio!",
    ALERT6TITLE : "Stai seguendo questo servizio!",
    ALERT7TITLE : "Non stai più seguendo questo servizio",
    ALERT8TITLE : "Sei sicuro di voler cancellare questo servizio?",
    ALERT9 : "Il servizio è stato eliminato",
    ALERT9TITLE : "Eliminazione servizio",
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Ricordati di pubblicarla",
    ALERTPUBBLICATA : "servizio in fase di revisione. Collabora al suo sviluppo",
    AUTORE : "Referente",
    CANVERSION2 : "Stai creando la versione {n} del tuo servizio",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questo servizio",
    EMAILAUTORENONDISPONIBILE : "Non è possibile inviare un messaggio perché l’autore o l’autrice del servizio non ha un indirizzo email",
    EMAILUTENTENONDISPONIBILE : "Non puoi inviare messaggi all’autore o all’autrice del servizio poiché il tuo account non ha un indirizzo email associato. Contatta <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> per maggiori informazioni. Grazie!",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questo servizio!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questo servizio!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questo servizio!",
    IDEAVOTATA : "servizio votato",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questo servizio",
    NARRAZIONE : "Il servizio",
    PREFERENZEPERPREMIARE : "preferenze per premiare i servizi migliori!",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altri servizi, scegliendo come distribuire il tuo budget.",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altri servizi.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per gli altri servizi",
    VERSIONIPROPOSTA : "Versioni del servizio:",
    WALLETSOSTIENIIDEA : "Sostieni questo servizio. Fai una donazione!"
  },
  SERVICELIST : {
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING2 : "È possibile migliorare un servizio fornendo o raccogliendo spunti e descrivendolo tramite nuove versioni sempre più aggiornate",
    CREAIDEA : "Pubblica il tuo servizio",
    ELENCOIDEE : "Tutti i servizi",
    IDEAVOTATA : "Hai votato questo servizio!",
    IDEE : "servizi",
    NONCISONOIDEE : "Non è presente ancora alcun servizio. Pubblica il tuo!",
    VOTAZIONEATTIVA2 : "Guarda tutti i servizi ed esprimi le tue preferenze",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare i tuoi servizi preferiti"
  },
  STORYDETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato alla storia",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questa storia",
    ALERT14 : "La storia è già stata valutata",
    ALERT3TITLE : "Grazie per aver votato questa storia",
    ALERT4 : "Naviga tra le storie e scegli quali altre preferisci di più",
    ALERT5 : "Puoi continuare a donare anche per altre storie scegliendo come distribuire il budget a tua disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto questa storia!",
    ALERT6TITLE : "Stai seguendo questa storia!",
    ALERT7TITLE : "Non stai più seguendo questa storia",
    ALERT8TITLE : "Sei sicuro di voler cancellare questa storia?",
    ALERT9 : "La storia è stata cancellata",
    ALERT9TITLE : "Eliminazione storia",
    ALERTBOZZA : "Hai una versione della tua storia in bozza. Ricordati di pubblicarla",
    ALERTPUBBLICATA : "Storia in fase di revisione. Collabora al suo sviluppo",
    AUTORE : "Scritta da",
    CANVERSION2 : "Stai creando la versione numero {n} della tua storia",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questa storia.",
    EMAILAUTORENONDISPONIBILE : "Non è possibile inviare un messaggio perché l’autore o l’autrice della storia non ha un indirizzo email",
    EMAILUTENTENONDISPONIBILE : "Non puoi inviare messaggi all’autore o all’autrice della storia poiché il tuo account non ha un indirizzo email associato. Contatta <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> per maggiori informazioni. Grazie!",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questa storia!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questa storia!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questa storia!",
    IDEAVOTATA : "Storia votata",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questa storia.",
    NARRAZIONE : "La storia",
    PREFERENZEPERPREMIARE : "preferenze per premiare le storie migliori!",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altre storie, scegliendo come distribuire il tuo budget.",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altre storie.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per le altre storie",
    VERSIONIPROPOSTA : "Versioni della storia:",
    WALLETSOSTIENIIDEA : "Sostieni questa storia. Fai una donazione!"
  },
  STORYLIST : {
    ALERTBOZZA : "Hai una versione della tua storia in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING2 : "È possibile aggiornare le storie con nuove esperienze e ricordi",
    CREAIDEA : "Racconta la tua storia",
    ELENCOIDEE : "Tutte le storie",
    IDEAVOTATA : "Hai votato questa storia!",
    IDEE : "storie",
    NONCISONOIDEE : "Non è stata pubblicata ancora nessuna storia. Raccontaci la tua!",
    VOTAZIONEATTIVA2 : "Guarda tutte le storie ed esprimi le tue preferenze",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare le tue storie preferite."
  },
  TACTICDETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato alla tattica",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questa tattica",
    ALERT14 : "La tattica è già stata valutata",
    ALERT3TITLE : "Grazie per aver votato questa tattica",
    ALERT4 : "Scopri le altre tattiche e scegli quali preferisci di più",
    ALERT5 : "Puoi continuare a donare anche per altre tattiche scegliendo come distribuire il tuo budget a disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto la tattica!",
    ALERT6TITLE : "Stai seguendo questa tattica!",
    ALERT7TITLE : "Non stai più seguendo questa tattica",
    ALERT8TITLE : "Sei sicuro di voler cancellare questa tattica?",
    ALERT9 : "La tattica è stata cancellata",
    ALERT9TITLE : "Eliminazione tattica",
    ALERTBOZZA : "Hai una versione della tattica in bozza. Ricordati di pubblicarla",
    ALERTPUBBLICATA : "Proposta in fase di revisione. Collabora al suo sviluppo",
    AUTORE : "",
    CANVERSION2 : "Stai creando la versione {n} della tua tattica",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questa tattica.",
    EMAILAUTORENONDISPONIBILE : "Non è possibile inviare un messaggio perché l’autore o l’autrice della tattica non ha un indirizzo email",
    EMAILUTENTENONDISPONIBILE : "Non puoi inviare messaggi all’autore o all’autrice della tattica poiché il tuo account non ha un indirizzo email associato. Contatta <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> per maggiori informazioni. Grazie!",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questa tattica!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questa tattica!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questa tattica!",
    IDEAVOTATA : "Tattica votata",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questa tattica.",
    NARRAZIONE : "La tattica",
    PREFERENZEPERPREMIARE : "preferenze per premiare le tattiche migliori!",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altre tattiche, scegliendo come distribuire il tuo budget.",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altre tattiche.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per le altre tattiche",
    VERSIONIPROPOSTA : "Versioni della tattica:",
    WALLETSOSTIENIIDEA : "Sostieni questa tattica. Fai una donazione!"
  },
  TACTICLIST : {
    ALERTBOZZA : "Hai una versione della tua tattica in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING2 : "è possibile migliorare le tattiche fornendo o raccogliendo spunti e pubblicando nuove versioni sempre più aggiornate",
    CREAIDEA : "Inserisci una tattica",
    ELENCOIDEE : "Tutte le tattiche",
    IDEAVOTATA : "Hai votato questa tattica!",
    IDEE : "tattiche",
    NONCISONOIDEE : "Non vi sono ancora tattiche. Inserisci la prima!",
    VOTAZIONEATTIVA2 : "Guarda tutte le tattiche ed esprimi le tue preferenze",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare le tue tattiche preferite."
  },
  TERMS : {
    ATTIVITA : "3. ATTIVITA’",
    ATTIVITADESCR1 : "Oggetto delle attività dell'Utente è qualsiasi iniziativa volta a contribuire alla vita, allo sviluppo e al benessere della propria città o ente di appartenenza;\nA titolo esemplificativo, le attività possono riguardare: l’inserimento di idee e segnalazioni, la discussione, la progettazione, la condivisione di idee, di link e di documenti, la votazione di idee e progetti e temi pubblici, eccetera.\nL’attività svolta deve attenersi ad argomenti di pubblica rilevanza e svolgersi nel pieno rispetto della normativa vigente.\nL'Utente dichiara di essere titolare di ogni diritto eventualmente connesso alla propria attività (a titolo meramente esemplificativo e non esaustivo: idee, progetti, fotografie, filmati audio/ video etc.).\nBiPart si riserva il diritto di eliminare dalla Piattaforma BiPart in maniera insindacabile qualsiasi elemento introdotto dall’Utente, come pure modificare o oscurare elementi non essenziali dello stesso qualora essa possa ledere il diritto di soggetti terzi o di una collettività di persone.\nL'Utente registrato accetta di partecipare anche alla eventuale realizzazione di classifiche inerenti alle attività, nel pieno rispetto della normativa sulla privacy (a titolo meramente esemplificativo e non esaustivo: classifiche per numero, tipologia e qualità delle proposte, commenti, etc.).\nL'Utente registrato accetta che le proprie attività possano essere pubblicate da BiPart anche su altri siti web non necessariamente di proprietà di BiPart (a titolo esemplificativo non esaustivo: Facebook, Twitter etc.) ma sempre e solo per le finalità legate al progetto culturale alla base della “Piattaforma BiPart”.",
    COMUNICAZIONI : "7. COMUNICAZIONI",
    COMUNICAZIONIDESCR1 : "L'Utente prende atto ed accetta che tutte le eventuali comunicazioni, notificazioni, informazioni e comunque ogni documentazione relativa alle operazioni eseguite e riferite all'utilizzo della “Piattaforma BiPart” verranno inviate all’indirizzo di posta elettronica ovvero agli eventuali ulteriori recapiti indicati dall'Utente durante la procedura di registrazione.",
    LIMITAZIONI : "6. LIMITAZIONI DI RESPONSABILITA'",
    LIMITAZIONIDESCR1 : "BiPart non risponde dei danni diretti, indiretti o consequenziali subiti dall'Utente o da terzi in dipendenza delle attività svolte all’interno della “Piattaforma BiPart” e/o per l'utilizzo dei Servizi di Comunicazione e/o per danni di qualsiasi genere o a qualsiasi titolo connessi con dette attività e a tal fine l'Utente dichiara di manlevare BiPart da ogni forma di responsabilità. Il materiale inviato dall'Utente non sarà più restituito.",
    MODIFICA : "8. MODIFICA DELLE CLAUSOLE DI UTILIZZO",
    MODIFICADESCR1 : "BiPart si riserva il diritto di modificare i termini, le condizioni, e le comunicazioni ai sensi dei quali viene offerta la “Piattaforma BiPart” ed a cui saranno tenuti a conformarsi anche gli Utenti già registrati.",
    OGGETTO : "1. OGGETTO E DEFINIZIONI",
    OGGETTODESCR1 : "Le presenti Condizioni d'Uso (CdU) regolano e disciplinano l'utilizzo della Piattaforma BiPart da parte dell'Utente nonché le responsabilità di quest'ultimo relativamente all'utilizzo della piattaforma anzidetta.Per BiPart si intende una piattaforma tramite la quale l'Utente può partecipare alle attività rese possibili dalla piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione, eccetera). Detta piattaforma è di esclusiva proprietà di BiPart Impresa sociale srl (già BiPart Srls) (BiPart) con sede in Milano, Via Metauro, 4 (codice fiscale e partita IVA: 09444470968).\nPer Utente si intende la persona fisica registrata ai sensi dell'art. 2 che interagisce con gli strumenti partecipativi messi a disposizione sulla Piattaforma BiPart.<br>Le presenti clausole di utilizzo si applicano ad ogni tipo di attività svolta dall’Utente.",
    REGISTRAZIONE : "2. REGISTRAZIONE",
    REGISTRAZIONEDESCR1 : "Per poter partecipare secondo quanto indicato all'articolo precedente, l'Utente deve necessariamente registrarsi tramite l'apposita procedura di registrazione;\nL'Utente si assume ogni responsabilità civile e penale per l’eventuale falsità o non correttezza delle informazioni e dei dati comunicati.",
    RESPONSABILITA : "4. RESPONSABILITA' DELL'UTENTE",
    RESPONSABILITADESCR1 : "L'Utente si assume ogni responsabilità nonché ogni conseguenza diretta o indiretta derivante dalle attività svolte e pubblicate sulla piattaforma web, nonché riconosce la propria esclusiva responsabilità per eventuali danni cagionati a terzi (a titolo meramente esemplificativo e non esaustivo, per la violazione di diritti d’autore o altri diritti di proprietà, diritti relativi alla riservatezza delle persone, etc.).\nL'Utente si impegna non inserire materiale o estratti di materiale:",
    RESPONSABILITADESCR2 : "<li>coperti da diritto d'autore e di cui non sia esso stesso titolare;</li><li>contrari alla morale e l'ordine pubblico, ovvero in grado di turbare la quiete pubblica o privata o di recare offesa, o danno diretto o indiretto a chiunque o ad una specifica categoria di persone (per esempio è vietato l’inserimento di materiali o estratti di materiale che possano ledere la sensibilità di gruppi etnici o religiosi, etc.);</li><li>contrario al diritto alla riservatezza di soggetti terzi; • lesive dell'onore, del decoro o della reputazione di soggetti terzi; \n</li><li> comunque contrari alla legge.</li>",
    SOTTOTITOLO : "Condizioni d'uso della piattaforma",
    TITOLO : "Termini e condizioni",
    UTILIZZO : "UTILIZZO",
    UTILIZZODESCR1 : "La “Piattaforma BiPart” potrà contenere ulteriori servizi di comunicazione quali bacheche elettroniche, aree chat, newsgroup, pagine web personali, calendari, e/o altri servizi creati per abilitare l’utente a comunicare con altri soggetti o con un gruppo (collettivamente, \"Servizi di Comunicazione\"). L’Utente accetta di usare i Servizi di Comunicazione solamente per spedire, inviare e ricevere messaggi e materiale appropriato e relativo al particolare Servizio di Comunicazione. A titolo di esempio, e non come limitazione, durante l’uso di un Servizio di Comunicazione, l’utente accetta di, e si impegna a, non:",
    UTILIZZODESCR2 : "<li>pubblicare, inviare, distribuire o disseminare nomi, materiali o informazioni non appropriati, profanatori, diffamatori, che violino i diritti altrui, osceni, indecenti o illegali;</li><li>pubblicare file che contengono software o altro materiale protetto da leggi di proprietà intellettuale (o dai diritti di privacy di pubblicità) a meno che l’utente sia titolare di tali diritti o ne possieda il controllo o abbia ricevuto tutti i consensi necessari;</li><li>pubblicare file che contengono virus, file danneggiati, o altri software o programmi simili che potrebbero danneggiare l’operatività dei computer di altri;</li><li>pubblicizzare o offrire per la vendita o comprare qualsiasi prodotto o servizio per qualunque scopo commerciale, salvo il caso in cui tale Servizio di Comunicazione permetta specificamente tali messaggi; </li><li>condurre o inoltrare sondaggi, contenuti, diagrammi a piramide o lettere a catena;</li><li>scaricare qualunque file inviato da un altro utente di un Servizio di Comunicazione che l’utente sappia, o ragionevolmente dovrebbe sapere, che non può essere legalmente distribuito in tale maniera;</li><li>falsificare o cancellare qualunque attribuzione d’autore, le comunicazioni di contenuto legale o altre comunicazioni appropriate o designazioni di proprietà o denominazioni d’origine o fonte del software o altro materiale contenuto in un file che è caricato;</li><li>limitare o inibire ad altri utenti l’utilizzo e il godimento dei Servizi di Comunicazione;</li><li>violare codici di condotta o altre modalità d’utilizzo che potrebbero essere applicabili per qualunque particolare Servizio di Comunicazione;</li><li>raccogliere o mettere insieme informazioni di altri, incluso indirizzi e-mail, senza il loro consenso; </li><li>violare qualunque legge o regolamento applicabile.</li>",
    UTILIZZODESCR3 : "BiPart non ha l’obbligo di monitorare i Servizi di Comunicazione. Tuttavia, BiPart si riserva il diritto di visionare i materiali inviati tramite uno dei Servizi di Comunicazione e di togliere qualunque materiale a sua unica ed insindacabile discrezione. BiPart si riserva il diritto di sospendere l’accesso dell’utente ad uno, a più o atutti i Servizi di Comunicazione in qualunque momento senza preavviso per qualunque motivo. I materiali pubblicati su di uno dei Servizi di Comunicazione possono essere soggetti a limitazioni impostate sul loro utilizzo, riproduzione e/o disseminazione. L’Utente è responsabile per avere aderito a tali limitazioni qualora scarichi i materiali."
  },
  TRANSLATOR : {
    SELECT : "Seleziona la tua lingua"
  },
  USERDETAIL : {
    BIOGRAFIA : "Biografia",
    COMMUNITY : "Community",
    EDUCAZIONE : "Educazione",
    EDULAVORO : "Educazione e lavoro",
    ETA : "Età",
    F : "Femmina",
    LAVORO : "Lavoro",
    M : "Maschio",
    PROPOSTEEFFETTUATE : "I miei contributi",
    UTENTE : "Utente"
  }
};
