import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import process from "./processes.module";
import organization from "./organization.module";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "organization",
  storage: window.localStorage,
  reducer: state => ({
    // currentProcess: state.process.currentProcess,
    currentOrganization: state.organization.currentOrganization
  })
});

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    process,
    organization
  },
  plugins: [vuexLocal.plugin]
});
