// FR
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "ADVAUTHMETHOD.ACCEDICOMM1",
    ACCEDICOMM2 : "ADVAUTHMETHOD.ACCEDICOMM2",
    PARTECIPAPROCESSO : "ADVAUTHMETHOD.PARTECIPAPROCESSO",
    REGISTRATICOMM1 : "ADVAUTHMETHOD.REGISTRATICOMM1",
    REGISTRATICOMM2 : "ADVAUTHMETHOD.REGISTRATICOMM2",
    SOLOVOTO : "ADVAUTHMETHOD.SOLOVOTO",
    SOLOVOTO2 : "ADVAUTHMETHOD.SOLOVOTO2"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "ADVAUTHMOBILE.ATTENZIONE",
    INDIETRO : "ADVAUTHMOBILE.INDIETRO",
    INSERISCINUOVONUMERO : "ADVAUTHMOBILE.INSERISCINUOVONUMERO",
    PARTECIPAPROCESSO : "ADVAUTHMOBILE.PARTECIPAPROCESSO",
    PH1 : "ADVAUTHMOBILE.PH1",
    PHONEERROR1 : "ADVAUTHMOBILE.PHONEERROR1",
    PHONEERROR2 : "ADVAUTHMOBILE.PHONEERROR2",
    PHONEERROR3 : "ADVAUTHMOBILE.PHONEERROR3",
    PRIVACY : "ADVAUTHMOBILE.PRIVACY",
    PROCEDI : "ADVAUTHMOBILE.PROCEDI",
    RECUPERAPASSWORD : "ADVAUTHMOBILE.RECUPERAPASSWORD",
    TERMINIECONDIZIONI : "ADVAUTHMOBILE.TERMINIECONDIZIONI",
    VERIFICA1 : "ADVAUTHMOBILE.VERIFICA1",
    VERIFICA2 : "ADVAUTHMOBILE.VERIFICA2",
    EXIT: "ADVAUTHMOBILE.EXIT",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "ADVAUTHMOBILEVERIFY.ALERT1",
    ALERT1TITLE : "ADVAUTHMOBILEVERIFY.ALERT1TITLE",
    ERROROTPMAXCHAR : "ADVAUTHMOBILEVERIFY.ERROROTPMAXCHAR",
    INDIETRO : "ADVAUTHMOBILEVERIFY.INDIETRO",
    OTPDIMENTICATO : "ADVAUTHMOBILEVERIFY.OTPDIMENTICATO",
    PARTECIPAPROCESSO : "ADVAUTHMOBILEVERIFY.PARTECIPAPROCESSO",
    PH1 : "ADVAUTHMOBILEVERIFY.PH1",
    PROCEDI : "ADVAUTHMOBILEVERIFY.PROCEDI",
    RICHIEDINUOVOCODICE1 : "ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE1",
    RICHIEDINUOVOCODICE2 : "ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE2",
    RICHIEDINUOVOCODICE3 : "ADVAUTHMOBILEVERIFY.RICHIEDINUOVOCODICE3",
    VERIFICA1 : "ADVAUTHMOBILEVERIFY.VERIFICA1",
    VERIFICA2 : "ADVAUTHMOBILEVERIFY.VERIFICA2"
  },
  ADVAUTHROLE : {
    CITTADINO : "ADVAUTHROLE.CITTADINO",
    INDIETRO : "ADVAUTHROLE.INDIETRO",
    INFOREGISTER : "ADVAUTHROLE.INFOREGISTER",
    PARTECIPAPROCESSO : "ADVAUTHROLE.PARTECIPAPROCESSO",
    PROCEDI : "ADVAUTHROLE.PROCEDI",
    VERIFICA1 : "ADVAUTHROLE.VERIFICA1",
    VERIFICA2 : "ADVAUTHROLE.VERIFICA2"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "ADVAUTHTAXCODE.ALERT1",
    ALERT1TITLE : "ADVAUTHTAXCODE.ALERT1TITLE",
    CFDIVERSO : "ADVAUTHTAXCODE.CFDIVERSO",
    CHIUDI : "ADVAUTHTAXCODE.CHIUDI",
    DIMENTICATOCF : "ADVAUTHTAXCODE.DIMENTICATOCF",
    ERRORECF : "ADVAUTHTAXCODE.ERRORECF",
    PARTECIPAPROCESSO : "ADVAUTHTAXCODE.PARTECIPAPROCESSO",
    PH1 : "ADVAUTHTAXCODE.PH1",
    PROCEDI : "ADVAUTHTAXCODE.PROCEDI",
    VERIFICA1 : "ADVAUTHTAXCODE.VERIFICA1",
    VERIFICA2 : "ADVAUTHTAXCODE.VERIFICA2"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "ADVAUTHTAXCODENOTVALID.CFNONABILIATO",
    CFNONABILIATO1 : "ADVAUTHTAXCODENOTVALID.CFNONABILIATO1",
    CHIUDI : "ADVAUTHTAXCODENOTVALID.CHIUDI",
    UTENTENONAUTORIZZATO : "ADVAUTHTAXCODENOTVALID.UTENTENONAUTORIZZATO"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "ADVAUTHTAXCODEVERIFIED.ADESSOPUOIVOTARE",
    CHIUDI : "ADVAUTHTAXCODEVERIFIED.CHIUDI",
    COMPLIMENTI : "ADVAUTHTAXCODEVERIFIED.COMPLIMENTI",
    UTENTEABILITATO : "ADVAUTHTAXCODEVERIFIED.UTENTEABILITATO",
    UTENTEINFO : "ADVAUTHTAXCODEVERIFIED.UTENTEINFO"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "ADVLOGINONPUBLIC.ALERT",
    ALERTINFO : "ADVLOGINONPUBLIC.ALERTINFO",
    PARTECIPA : "ADVLOGINONPUBLIC.PARTECIPA",
    PARTECIPAPROCESSO : "ADVLOGINONPUBLIC.PARTECIPAPROCESSO",
    PASSWORDERRATA : "ADVLOGINONPUBLIC.PASSWORDERRATA",
    PH1 : "ADVLOGINONPUBLIC.PH1",
    PH2 : "ADVLOGINONPUBLIC.PH2",
    SUPPORT : "ADVLOGINONPUBLIC.SUPPORT",
    USERNONESISTE : "ADVLOGINONPUBLIC.USERNONESISTE"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "ADVREGISTERONPUBLIC.ALERT1",
    ALERT1TITLE : "ADVREGISTERONPUBLIC.ALERT1TITLE",
    CAMPIOBBLIGATORI : "ADVREGISTERONPUBLIC.CAMPIOBBLIGATORI",
    COGNOMEDIMENTICATO : "ADVREGISTERONPUBLIC.COGNOMEDIMENTICATO",
    INDIETRO : "ADVREGISTERONPUBLIC.INDIETRO",
    MAILDIMENTICATA : "ADVREGISTERONPUBLIC.MAILDIMENTICATA",
    MAILNONVALIDA : "ADVREGISTERONPUBLIC.MAILNONVALIDA",
    NEWSLETTER : "ADVREGISTERONPUBLIC.NEWSLETTER",
    NOMEDIMENTICATO : "ADVREGISTERONPUBLIC.NOMEDIMENTICATO",
    PARTECIPA : "ADVREGISTERONPUBLIC.PARTECIPA",
    PARTECIPAPROCESSO : "ADVREGISTERONPUBLIC.PARTECIPAPROCESSO",
    PASSWORDDIMENTICATA : "ADVREGISTERONPUBLIC.PASSWORDDIMENTICATA",
    PH1 : "ADVREGISTERONPUBLIC.PH1",
    PH2 : "ADVREGISTERONPUBLIC.PH2",
    PH3 : "ADVREGISTERONPUBLIC.PH3",
    PH4 : "ADVREGISTERONPUBLIC.PH4",
    PH5 : "ADVREGISTERONPUBLIC.PH5",
    PRIVACY : "ADVREGISTERONPUBLIC.PRIVACY",
    REGISTRA : "ADVREGISTERONPUBLIC.REGISTRA",
    TERMINIECONDIZIONI : "ADVREGISTERONPUBLIC.TERMINIECONDIZIONI",
    USERNAMEDIMENTICATO : "ADVREGISTERONPUBLIC.USERNAMEDIMENTICATO"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "ALERT.ALERTCANNOTFOLLOW",
    ALERTCOMMENTEMPTY : "ALERT.ALERTCOMMENTEMPTY",
    ALERTCOMMENTSENDED : "ALERT.ALERTCOMMENTSENDED",
    ALERTEMAILBLANK : "ALERT.ALERTEMAILBLANK",
    ALERTEMAILCONFIRM : "ALERT.ALERTEMAILCONFIRM",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "ALERT.ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "ALERT.ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE",
    ALERTEMAILMOBILECONFIRM : "ALERT.ALERTEMAILMOBILECONFIRM",
    ALERTEMAILRESENDCONFIRM : "ALERT.ALERTEMAILRESENDCONFIRM",
    ALERTERROR : "ALERT.ALERTERROR",
    ALERTFORMDESCRIPTION : "ALERT.ALERTFORMDESCRIPTION",
    ALERTFORMTITLE : "ALERT.ALERTFORMTITLE",
    ALERTHELPDESCRIPTION : "ALERT.ALERTHELPDESCRIPTION",
    ALERTHELPTITLE : "ALERT.ALERTHELPTITLE",
    ALERTJAMBOARDDESCRIPTION : "ALERT.ALERTJAMBOARDDESCRIPTION",
    ALERTJAMBOARDTITLE : "ALERT.ALERTJAMBOARDTITLE",
    ALERTMEETDESCRIPTION : "ALERT.ALERTMEETDESCRIPTION",
    ALERTMEETTITLE : "ALERT.ALERTMEETTITLE",
    ALERTMOBILECONFIRM : "ALERT.ALERTMOBILECONFIRM",
    ALERTPERMISSIONDENIED : "ALERT.ALERTPERMISSIONDENIED",
    ALERTUSERMODULENOTENABLED : "ALERT.ALERTUSERMODULENOTENABLED",
    ALERTUSERMODULENOTENABLEDBITMASK : "ALERT.ALERTUSERMODULENOTENABLEDBITMASK",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKCOMMENT",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKCREATE",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKDELETE",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKFOLLOW",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKMESSAGE",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKUPDATE",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "ALERT.ALERTUSERMODULENOTENABLEDBITMASKVERSION",
    ALERTUSERREGISTEREDTOORGANIZATION : "ALERT.ALERTUSERREGISTEREDTOORGANIZATION",
    ALERTUSERREGISTEREDTOPROCESS : "ALERT.ALERTUSERREGISTEREDTOPROCESS",
    PROPOSAL : "ALERT.PROPOSAL",
    SERVICE : "ALERT.SERVICE",
    STORY : "ALERT.STORY",
    TACTICS : "ALERT.TACTICS"
  },
  AUTHORITIES : {
    ASSESSOR : "Assessor",
    EXPERT : "Expert"
  },
  BCPROCESSI : {
    CHALLENGE : "BCPROCESSI.CHALLENGE",
    COMMUNITY : "BCPROCESSI.COMMUNITY",
    ELENCOPROCESSI : "BCPROCESSI.ELENCOPROCESSI",
    PROCESSO : "BCPROCESSI.PROCESSO",
    SCENARI : "BCPROCESSI.SCENARI"
  },
  COMMUNITY : {
    CERCA : "COMMUNITY.CERCA",
    CHIPARTECIPA : "COMMUNITY.CHIPARTECIPA",
    GRUPPI : "COMMUNITY.GRUPPI",
    ISCRITTI : "COMMUNITY.ISCRITTI",
    PROFILI : "COMMUNITY.PROFILI",
    SOTTOTITOLO : "COMMUNITY.SOTTOTITOLO",
    TITOLO : "COMMUNITY.TITOLO"
  },
  COMMUNITYSPACE : {
    BOZZA : "COMMUNITYSPACE.BOZZA",
    CS : "COMMUNITYSPACE.CS",
    DESCRIZIONEIDEA : "COMMUNITYSPACE.DESCRIZIONEIDEA",
    IDEE : "COMMUNITYSPACE.IDEE",
    NOMEAUTORE : "COMMUNITYSPACE.NOMEAUTORE",
    TITOLOIDEA : "COMMUNITYSPACE.TITOLOIDEA",
    VISUALIZZAELENCO : "COMMUNITYSPACE.VISUALIZZAELENCO",
    VISUALIZZAMAPPA : "COMMUNITYSPACE.VISUALIZZAMAPPA"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "CONFIRMCOMPLETELANGUAGES.ALERT1",
    ALERT1TITLE : "CONFIRMCOMPLETELANGUAGES.ALERT1TITLE",
    ALERT2 : "CONFIRMCOMPLETELANGUAGES.ALERT2",
    CANCEL : "CONFIRMCOMPLETELANGUAGES.CANCEL"
  },
  COOKIE : {
    ANONIMI : "COOKIE.ANONIMI",
    ANONIMIDESCR1 : "COOKIE.ANONIMIDESCR1",
    COLLEGAMENTI : "COOKIE.COLLEGAMENTI",
    COLLEGAMENTIDESCR1 : "COOKIE.COLLEGAMENTIDESCR1",
    CONTENUTIESTERNI : "COOKIE.CONTENUTIESTERNI",
    CONTENUTIESTERNIDESCR1 : "COOKIE.CONTENUTIESTERNIDESCR1",
    COSASONO : "COOKIE.COSASONO",
    COSASONODESCR1 : "COOKIE.COSASONODESCR1",
    DATIRACCOLTI : "COOKIE.DATIRACCOLTI",
    DATIRACCOLTIDESCR1 : "COOKIE.DATIRACCOLTIDESCR1",
    SOTTOTITOLO : "COOKIE.SOTTOTITOLO",
    TITOLO : "COOKIE.TITOLO",
    UTILIZZATI : "COOKIE.UTILIZZATI",
    UTILIZZATIDESCR1 : "COOKIE.UTILIZZATIDESCR1"
  },
  DRIVERS : {
    ACTIVEPROCESSES : "DRIVERS.ACTIVEPROCESSES",
    CHALLENGE : "DRIVERS.CHALLENGE",
    CLOSEPROCESSES : "DRIVERS.CLOSEPROCESSES",
    CLUSTER : "DRIVERS.CLUSTER",
    DESCRIZIONE : "DRIVERS.DESCRIZIONE",
    ELENCOCHALLENGE : "DRIVERS.ELENCOCHALLENGE",
    LINK : "DRIVERS.LINK",
    PROCESSES : "DRIVERS.PROCESSES",
    TUTTITEMI : "DRIVERS.TUTTITEMI"
  },
  EMAILCONFIRM : {
    AUTOK : "EMAILCONFIRM.AUTOK",
    AUTOKDETAIL : "EMAILCONFIRM.AUTOKDETAIL",
    BLOGIN : "EMAILCONFIRM.BLOGIN",
    TITOLO : "EMAILCONFIRM.TITOLO"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "FAQCUSTOM.DESCRIZIONE",
    DETTAGLIOGLOSSARIO : "FAQCUSTOM.DETTAGLIOGLOSSARIO",
    FAQ : "FAQCUSTOM.FAQ",
    FAQ1 : "FAQCUSTOM.FAQ1",
    FAQ10 : "FAQCUSTOM.FAQ10",
    FAQ11 : "FAQCUSTOM.FAQ11",
    FAQ12 : "FAQCUSTOM.FAQ12",
    FAQ13 : "FAQCUSTOM.FAQ13",
    FAQ14 : "FAQCUSTOM.FAQ14",
    FAQ15 : "FAQCUSTOM.FAQ15",
    FAQ16 : "FAQCUSTOM.FAQ16",
    FAQ17 : "FAQCUSTOM.FAQ17",
    FAQ18 : "FAQCUSTOM.FAQ18",
    FAQ19 : "FAQCUSTOM.FAQ19",
    FAQ2 : "FAQCUSTOM.FAQ2",
    FAQ20 : "FAQCUSTOM.FAQ20",
    FAQ21 : "FAQCUSTOM.FAQ21",
    FAQ22 : "FAQCUSTOM.FAQ22",
    FAQ23 : "FAQCUSTOM.FAQ23",
    FAQ24 : "FAQCUSTOM.FAQ24",
    FAQ25 : "FAQCUSTOM.FAQ25",
    FAQ3 : "FAQCUSTOM.FAQ3",
    FAQ4 : "FAQCUSTOM.FAQ4",
    FAQ5 : "FAQCUSTOM.FAQ5",
    FAQ6 : "FAQCUSTOM.FAQ6",
    FAQ7 : "FAQCUSTOM.FAQ7",
    FAQ8 : "FAQCUSTOM.FAQ8",
    FAQ9 : "FAQCUSTOM.FAQ9",
    GLOSSARIO : "FAQCUSTOM.GLOSSARIO",
    PERCHEPARTECIPARE : "FAQCUSTOM.PERCHEPARTECIPARE",
    QUALITEMIPROGETTI : "FAQCUSTOM.QUALITEMIPROGETTI",
    R1 : "FAQCUSTOM.R1",
    R10 : "FAQCUSTOM.R10",
    R11 : "FAQCUSTOM.R11",
    R12 : "FAQCUSTOM.R12",
    R13 : "FAQCUSTOM.R13",
    R14 : "FAQCUSTOM.R14",
    R15 : "FAQCUSTOM.R15",
    R16 : "FAQCUSTOM.R16",
    R17 : "FAQCUSTOM.R17",
    R18 : "FAQCUSTOM.R18",
    R19 : "FAQCUSTOM.R19",
    R2 : "FAQCUSTOM.R2",
    R20 : "FAQCUSTOM.R20",
    R21 : "FAQCUSTOM.R21",
    R22 : "FAQCUSTOM.R22",
    R23 : "FAQCUSTOM.R23",
    R24 : "FAQCUSTOM.R24",
    R25 : "FAQCUSTOM.R25",
    R3 : "FAQCUSTOM.R3",
    R4 : "FAQCUSTOM.R4",
    R5 : "FAQCUSTOM.R5",
    R6 : "FAQCUSTOM.R6",
    R7 : "FAQCUSTOM.R7",
    R8 : "FAQCUSTOM.R8",
    R9 : "FAQCUSTOM.R9",
    TEMI : "FAQCUSTOM.TEMI"
  },
  GENERALLABEL : {
    AL : "GENERALLABEL.AL",
    ALTERMINEDELLAFASE : "GENERALLABEL.ALTERMINEDELLAFASE",
    DAL : "GENERALLABEL.DAL",
    FASE : "GENERALLABEL.FASE",
    MANCANOANCORA : "GENERALLABEL.MANCANOANCORA",
    TERMINAIL : "GENERALLABEL.TERMINAIL"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA1",
    ACCETTOPROCESSOLINEEGUIDA2 : "GENERALMODAL.ACCETTOPROCESSOLINEEGUIDA2"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "GROUPDETAIL.ELENCOGRUPPI",
    F : "GROUPDETAIL.F",
    GRUPPO : "GROUPDETAIL.GRUPPO",
    M : "GROUPDETAIL.M",
    MEMBRI : "GROUPDETAIL.MEMBRI",
    PUBBLICA : "GROUPDETAIL.PUBBLICA"
  },
  GROUPLIST : {
    COMMUNITY : "GROUPLIST.COMMUNITY",
    DDC : "GROUPLIST.DDC",
    DUE : "GROUPLIST.DUE",
    GRUPPI : "GROUPLIST.GRUPPI",
    TRE : "GROUPLIST.TRE",
    UNO : "GROUPLIST.UNO"
  },
  HOME : {
    ALERT1 : "HOME.ALERT1",
    ALERT1TITLE : "HOME.ALERT1TITLE",
    ALERT2 : "HOME.ALERT2",
    ALERT2TITLE : "HOME.ALERT2TITLE",
    ASSISTENZA : "HOME.ASSISTENZA",
    DATIGRAFICI : "HOME.DATIGRAFICI",
    GETSTARTED : "HOME.GETSTARTED",
    GRUPPICOM : "HOME.GRUPPICOM",
    OBIETTIVI : "HOME.OBIETTIVI",
    ORGANIZZAZIONE : "HOME.ORGANIZZAZIONE",
    ORGANIZZAZIONI : "HOME.ORGANIZZAZIONI",
    PROCESSIATTIVI : "HOME.PROCESSIATTIVI",
    PROFILO : "HOME.PROFILO",
    PROFILODETT : "HOME.PROFILODETT",
    SCENARI : "HOME.SCENARI",
    UTENTIREG : "HOME.UTENTIREG",
    VOTANTI : "HOME.VOTANTI",
    VOTIRACCOLTI : "HOME.VOTIRACCOLTI"
  },
  IDEADETAIL : {
    ALERT1 : "IDEADETAIL.ALERT1",
    ALERT10TITLE : "IDEADETAIL.ALERT10TITLE",
    ALERT11 : "IDEADETAIL.ALERT11",
    ALERT11TITLE : "IDEADETAIL.ALERT11TITLE",
    ALERT12TITLE : "IDEADETAIL.ALERT12TITLE",
    ALERT13 : "IDEADETAIL.ALERT13",
    ALERT13TITLE : "IDEADETAIL.ALERT13TITLE",
    ALERT14 : "IDEADETAIL.ALERT14",
    ALERT14TITLE : "IDEADETAIL.ALERT14TITLE",
    ALERT15 : "IDEADETAIL.ALERT15",
    ALERT15B1 : "IDEADETAIL.ALERT15B1",
    ALERT15B2 : "IDEADETAIL.ALERT15B2",
    ALERT15TITLE : "IDEADETAIL.ALERT15TITLE",
    ALERT16 : "IDEADETAIL.ALERT16",
    ALERT16TITLE : "IDEADETAIL.ALERT16TITLE",
    ALERT17 : "IDEADETAIL.ALERT17",
    ALERT17TITLE : "IDEADETAIL.ALERT17TITLE",
    ALERT1TITLE : "IDEADETAIL.ALERT1TITLE",
    ALERT2 : "IDEADETAIL.ALERT2",
    ALERT2TITLE : "IDEADETAIL.ALERT2TITLE",
    ALERT3 : "IDEADETAIL.ALERT3",
    ALERT3TITLE : "IDEADETAIL.ALERT3TITLE",
    ALERT4 : "IDEADETAIL.ALERT4",
    ALERT4TITLE : "IDEADETAIL.ALERT4TITLE",
    ALERT5 : "IDEADETAIL.ALERT5",
    ALERT5TITLE : "IDEADETAIL.ALERT5TITLE",
    ALERT6TITLE : "IDEADETAIL.ALERT6TITLE",
    ALERT7TITLE : "IDEADETAIL.ALERT7TITLE",
    ALERT8 : "IDEADETAIL.ALERT8",
    ALERT8B1 : "IDEADETAIL.ALERT8B1",
    ALERT8B2 : "IDEADETAIL.ALERT8B2",
    ALERT8TITLE : "IDEADETAIL.ALERT8TITLE",
    ALERT9 : "IDEADETAIL.ALERT9",
    ALERT9TITLE : "IDEADETAIL.ALERT9TITLE",
    ALERTBOZZA : "IDEADETAIL.ALERTBOZZA",
    ALERTEMPTYVOTE1USERLEFTHTML : "IDEADETAIL.ALERTEMPTYVOTE1USERLEFTHTML",
    ALERTEMPTYVOTE1USERLEFTTITLE : "IDEADETAIL.ALERTEMPTYVOTE1USERLEFTTITLE",
    ALERTOWNERMAILEDHTML1 : "IDEADETAIL.ALERTOWNERMAILEDHTML1",
    ALERTOWNERMAILEDHTML2 : "IDEADETAIL.ALERTOWNERMAILEDHTML2",
    ALERTOWNERMAILEDTITLE : "IDEADETAIL.ALERTOWNERMAILEDTITLE",
    ALERTPUBBLICATA : "IDEADETAIL.ALERTPUBBLICATA",
    APPROFONDISCI : "IDEADETAIL.APPROFONDISCI",
    APPROVATA : "IDEADETAIL.APPROVATA",
    AUTORE : "IDEADETAIL.AUTORE",
    BUDGET : "IDEADETAIL.BUDGET",
    CAMPOMANCANTE : "IDEADETAIL.CAMPOMANCANTE",
    CANCELLAIDEA : "IDEADETAIL.CANCELLAIDEA",
    CANVERSION : "IDEADETAIL.CANVERSION",
    CANVERSION2 : "IDEADETAIL.CANVERSION2",
    CANVERSIONTITLE : "IDEADETAIL.CANVERSIONTITLE",
    CANVERSIONTITLE2 : "IDEADETAIL.CANVERSIONTITLE2",
    CHALLENGE : "IDEADETAIL.CHALLENGE",
    CIFRAALTA : "IDEADETAIL.CIFRAALTA",
    CIFRABASSA : "IDEADETAIL.CIFRABASSA",
    CIFRADELBUDGET : "IDEADETAIL.CIFRADELBUDGET",
    CIFRAMODIFICATA : "IDEADETAIL.CIFRAMODIFICATA",
    CIFRARIMOSSA : "IDEADETAIL.CIFRARIMOSSA",
    CIFRASUPERIOREALBUDGET : "IDEADETAIL.CIFRASUPERIOREALBUDGET",
    CLICCAILPULSANTE : "IDEADETAIL.CLICCAILPULSANTE",
    COMMENTAIDEA : "IDEADETAIL.COMMENTAIDEA",
    COMMENTI : "IDEADETAIL.COMMENTI",
    COMMENTIALERTEMAILCONFIRM : "IDEADETAIL.COMMENTIALERTEMAILCONFIRM",
    COMMENTIALERTEMAILMOBILECONFIRM : "IDEADETAIL.COMMENTIALERTEMAILMOBILECONFIRM",
    COMMENTIALERTMOBILECONFIRM : "IDEADETAIL.COMMENTIALERTMOBILECONFIRM",
    COMMENTICONTRIBUISCI : "IDEADETAIL.COMMENTICONTRIBUISCI",
    COMMENTIDISATTIVATI : "IDEADETAIL.COMMENTIDISATTIVATI",
    COMMENTIENTRA : "IDEADETAIL.COMMENTIENTRA",
    COMMENTIREGISTRATI : "IDEADETAIL.COMMENTIREGISTRATI",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "IDEADETAIL.COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI",
    COMPLETABOZZA : "IDEADETAIL.COMPLETABOZZA",
    CONDIVIDI : "IDEADETAIL.CONDIVIDI",
    COSTOIDEA : "IDEADETAIL.COSTOIDEA",
    CREATAIL : "IDEADETAIL.CREATAIL",
    DESCRIZIONE : "IDEADETAIL.DESCRIZIONE",
    DONA : "IDEADETAIL.DONA",
    DONAREDA : "IDEADETAIL.DONAREDA",
    DONAREFINOA : "IDEADETAIL.DONAREFINOA",
    DONRACCOLTE : "IDEADETAIL.DONRACCOLTE",
    EMAILAUTORENONDISPONIBILE : "IDEADETAIL.EMAILAUTORENONDISPONIBILE",
    EMAILUTENTENONDISPONIBILE : "IDEADETAIL.EMAILUTENTENONDISPONIBILE",
    FOLLOWERS : "IDEADETAIL.FOLLOWERS",
    FONDIRACCOLTI : "IDEADETAIL.FONDIRACCOLTI",
    GRAZIESCELTAIDEA : "IDEADETAIL.GRAZIESCELTAIDEA",
    GRAZIESOSTEGNO : "IDEADETAIL.GRAZIESOSTEGNO",
    GRAZIESOSTEGNOANCORA : "IDEADETAIL.GRAZIESOSTEGNOANCORA",
    HAIANCORA : "IDEADETAIL.HAIANCORA",
    HAIANCORA2 : "IDEADETAIL.HAIANCORA2",
    HASCRITTO : "IDEADETAIL.HASCRITTO",
    IDEAVOTATA : "IDEADETAIL.IDEAVOTATA",
    INBOZZA : "IDEADETAIL.INBOZZA",
    INDICACIFRA : "IDEADETAIL.INDICACIFRA",
    INSERISCICIFRA : "IDEADETAIL.INSERISCICIFRA",
    INSERISCICOMMENTO : "IDEADETAIL.INSERISCICOMMENTO",
    INVIA : "IDEADETAIL.INVIA",
    MACCETTODIESSERERICONTATTATO : "IDEADETAIL.MACCETTODIESSERERICONTATTATO",
    MAPPE : "IDEADETAIL.MAPPE",
    MBCHIUDI : "IDEADETAIL.MBCHIUDI",
    MBINVIA : "IDEADETAIL.MBINVIA",
    MCONTATTAILPROPONENTE : "IDEADETAIL.MCONTATTAILPROPONENTE",
    MEMAIL : "IDEADETAIL.MEMAIL",
    MESSAGEFIELDNAME : "IDEADETAIL.MESSAGEFIELDNAME",
    METTIPREF : "IDEADETAIL.METTIPREF",
    MINSERISCIUNMESSAGGIO : "IDEADETAIL.MINSERISCIUNMESSAGGIO",
    MMESSAGGIOOBBLIGATORIO : "IDEADETAIL.MMESSAGGIOOBBLIGATORIO",
    MMOBILE : "IDEADETAIL.MMOBILE",
    MNOMINATIVO : "IDEADETAIL.MNOMINATIVO",
    MODIFICAIDEA : "IDEADETAIL.MODIFICAIDEA",
    MTIPOLOGIACOLLABORAZIONELABEL : "IDEADETAIL.MTIPOLOGIACOLLABORAZIONELABEL",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "IDEADETAIL.MTIPOLOGIACOLLABORAZIONEPLACEHOLDER",
    NARRAZIONE : "IDEADETAIL.NARRAZIONE",
    NOCOMMENTI : "IDEADETAIL.NOCOMMENTI",
    NOCOMMUNITY : "IDEADETAIL.NOCOMMUNITY",
    NONAPPROVATA : "IDEADETAIL.NONAPPROVATA",
    NONSEGUIRE : "IDEADETAIL.NONSEGUIRE",
    NONVALUTATA : "IDEADETAIL.NONVALUTATA",
    NUOVAVERSIONE : "IDEADETAIL.NUOVAVERSIONE",
    PH1 : "IDEADETAIL.PH1",
    PH2 : "IDEADETAIL.PH2",
    PREFERENZEPERPREMIARE : "IDEADETAIL.PREFERENZEPERPREMIARE",
    PREFRACCOLTE : "IDEADETAIL.PREFRACCOLTE",
    PUBBLICATAIL : "IDEADETAIL.PUBBLICATAIL",
    PUOIDONARE : "IDEADETAIL.PUOIDONARE",
    RICHIEDIASSISTENZA : "IDEADETAIL.RICHIEDIASSISTENZA",
    RICORDATI3 : "IDEADETAIL.RICORDATI3",
    RIMUOVIPREF : "IDEADETAIL.RIMUOVIPREF",
    SEGUIIDEA : "IDEADETAIL.SEGUIIDEA",
    SOSTENITORI : "IDEADETAIL.SOSTENITORI",
    SOSTIENIALTREIDEE : "IDEADETAIL.SOSTIENIALTREIDEE",
    SOTTOCATEGORIA : "IDEADETAIL.SOTTOCATEGORIA",
    TOGLIEREPREFERENZA : "IDEADETAIL.TOGLIEREPREFERENZA",
    TUOIVOTI : "IDEADETAIL.TUOIVOTI",
    URLSITO : "IDEADETAIL.URLSITO",
    VAIALSITO : "IDEADETAIL.VAIALSITO",
    VALUTAIDEA : "IDEADETAIL.VALUTAIDEA",
    VALUTARIDUZIONEDONAZIONI : "IDEADETAIL.VALUTARIDUZIONEDONAZIONI",
    VALUTAZIONEINSERITA : "IDEADETAIL.VALUTAZIONEINSERITA",
    VALUTAZIONETITLE1 : "IDEADETAIL.VALUTAZIONETITLE1",
    VALUTAZIONETITLE2 : "IDEADETAIL.VALUTAZIONETITLE2",
    VALUTAZIONETITLE3 : "IDEADETAIL.VALUTAZIONETITLE3",
    VALUTAZIONETITLE4 : "IDEADETAIL.VALUTAZIONETITLE4",
    VALUTAZIONETITLE5 : "IDEADETAIL.VALUTAZIONETITLE5",
    VALUTAZIONETITLE6 : "IDEADETAIL.VALUTAZIONETITLE6",
    VALUTAZIONI : "IDEADETAIL.VALUTAZIONI",
    VERSIONIPROPOSTA : "IDEADETAIL.VERSIONIPROPOSTA",
    VOTAZIONECHIUSA : "IDEADETAIL.VOTAZIONECHIUSA",
    WALLETSOSTIENIIDEA : "IDEADETAIL.WALLETSOSTIENIIDEA"
  },
  IDEALIST : {
    ALERTBOZZA : "IDEALIST.ALERTBOZZA",
    ALERTVERSIONING1 : "IDEALIST.ALERTVERSIONING1",
    ALERTVERSIONING2 : "IDEALIST.ALERTVERSIONING2",
    ALERTVERSIONING3 : "IDEALIST.ALERTVERSIONING3",
    ALERTVERSIONING4 : "IDEALIST.ALERTVERSIONING4",
    ALERTVERSIONING5 : "IDEALIST.ALERTVERSIONING5",
    ASSISTENZA : "IDEALIST.ASSISTENZA",
    CLASSIFICA : "IDEALIST.CLASSIFICA",
    CREAIDEA : "IDEALIST.CREAIDEA",
    ELENCOIDEE : "IDEALIST.ELENCOIDEE",
    FILTRAPERCHALLENGE : "IDEALIST.FILTRAPERCHALLENGE",
    FILTRAPERMODULO : "IDEALIST.FILTRAPERMODULO",
    FILTRAPERSCENARIO : "IDEALIST.FILTRAPERSCENARIO",
    IDEAVOTATA : "IDEALIST.IDEAVOTATA",
    IDEE : "IDEALIST.IDEE",
    IDEECOSTOSE : "IDEALIST.IDEECOSTOSE",
    IDEEMENOCOSTOSE : "IDEALIST.IDEEMENOCOSTOSE",
    IDEEMENORECENTI : "IDEALIST.IDEEMENORECENTI",
    IDEEMENOSEGUITE : "IDEALIST.IDEEMENOSEGUITE",
    IDEEPIUSEGUITE : "IDEALIST.IDEEPIUSEGUITE",
    INFORMAZIONI : "IDEALIST.INFORMAZIONI",
    INFORMAZIONISULLAFASE : "IDEALIST.INFORMAZIONISULLAFASE",
    JAMBOARD : "IDEALIST.JAMBOARD",
    NESSUNO : "IDEALIST.NESSUNO",
    NONCISONOIDEE : "IDEALIST.NONCISONOIDEE",
    ORDINA : "IDEALIST.ORDINA",
    PREFERENZE : "IDEALIST.PREFERENZE",
    PS : "IDEALIST.PS",
    TUOIVOTI : "IDEALIST.TUOIVOTI",
    ULTIMEIDEE : "IDEALIST.ULTIMEIDEE",
    VOTANTI : "IDEALIST.VOTANTI",
    VOTAZIONEATTIVA : "IDEALIST.VOTAZIONEATTIVA",
    VOTAZIONEATTIVA2 : "IDEALIST.VOTAZIONEATTIVA2",
    VOTAZIONEATTIVA3 : "IDEALIST.VOTAZIONEATTIVA3",
    VOTAZIONEATTIVABUDG : "IDEALIST.VOTAZIONEATTIVABUDG",
    VOTAZIONEATTIVAMAX : "IDEALIST.VOTAZIONEATTIVAMAX",
    VOTAZIONEATTIVAMIN : "IDEALIST.VOTAZIONEATTIVAMIN",
    VOTAZIONEATTIVAPREF : "IDEALIST.VOTAZIONEATTIVAPREF",
    VOTAZIONEATTIVAPREF2 : "IDEALIST.VOTAZIONEATTIVAPREF2",
    VOTAZIONEATTIVAPREF3 : "IDEALIST.VOTAZIONEATTIVAPREF3",
    VOTAZIONEATTIVAPREF4 : "IDEALIST.VOTAZIONEATTIVAPREF4",
    VOTAZIONEATTIVAUTENTEANONIMO : "IDEALIST.VOTAZIONEATTIVAUTENTEANONIMO",
    VOTAZIONECHIUSA : "IDEALIST.VOTAZIONECHIUSA",
    WEBINAR : "IDEALIST.WEBINAR"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "IDEARESULT.CLASSIFICAFINALE",
    COMEFUNZIONA : "IDEARESULT.COMEFUNZIONA",
    COSTOIDEA : "IDEARESULT.COSTOIDEA",
    DONATORI : "IDEARESULT.DONATORI",
    ELENCOPROCESSI : "IDEARESULT.ELENCOPROCESSI",
    FASE : "IDEARESULT.FASE",
    FILTRAPERBUDGET : "IDEARESULT.FILTRAPERBUDGET",
    FILTRAPERPREFERENZE : "IDEARESULT.FILTRAPERPREFERENZE",
    FINANZIATO : "IDEARESULT.FINANZIATO",
    PREFERENZE : "IDEARESULT.PREFERENZE",
    PROCESSO : "IDEARESULT.PROCESSO",
    RISULTATI : "IDEARESULT.RISULTATI",
    RISULTATIVOTAZIONE : "IDEARESULT.RISULTATIVOTAZIONE",
    TUTTOSUPROCESSO : "IDEARESULT.TUTTOSUPROCESSO"
  },
  INTRO : {
    ASSISTENZA : "INTRO.ASSISTENZA"
  },
  INTROBASE : {
    ASSISTENZA : "INTROBASE.ASSISTENZA",
    COSA : "INTROBASE.COSA",
    PARTECIPA : "INTROBASE.PARTECIPA",
    PARTECIPADESC : "INTROBASE.PARTECIPADESC",
    SUBIPART : "INTROBASE.SUBIPART"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "LAYOUT.CONFIRMEMAIL1",
    CONFIRMEMAIL2 : "LAYOUT.CONFIRMEMAIL2",
    CONFIRMEMAIL3 : "LAYOUT.CONFIRMEMAIL3",
    CONFIRMMOBILE1 : "LAYOUT.CONFIRMMOBILE1",
    CONFIRMMOBILE2 : "LAYOUT.CONFIRMMOBILE2",
    CONFIRMMOBILE3 : "LAYOUT.CONFIRMMOBILE3"
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "LOGGEDREGISTERPROCESSONPUBLIC.CHIUDI",
    CONFERMA : "LOGGEDREGISTERPROCESSONPUBLIC.CONFERMA",
    ERR1 : "LOGGEDREGISTERPROCESSONPUBLIC.ERR1",
    ISCRIVITIPROCESSO : "LOGGEDREGISTERPROCESSONPUBLIC.ISCRIVITIPROCESSO",
    PARTECIPAPROCESSO : "LOGGEDREGISTERPROCESSONPUBLIC.PARTECIPAPROCESSO",
    PROPOSTE : "LOGGEDREGISTERPROCESSONPUBLIC.PROPOSTE",
    TITLE1 : "LOGGEDREGISTERPROCESSONPUBLIC.TITLE1"
  },
  LOGIN : {
    ACCEDI : "LOGIN.ACCEDI",
    ACCETTO1 : "LOGIN.ACCETTO1",
    ACCETTO2 : "LOGIN.ACCETTO2",
    BACKPORTAL : "Back to portal",
    CAMBIAPASS : "LOGIN.CAMBIAPASS",
    INSERISCIMAIL : "LOGIN.INSERISCIMAIL",
    MEX1 : "LOGIN.MEX1",
    MEX2 : "LOGIN.MEX2",
    MEX3 : "LOGIN.MEX3",
    MEX4 : "LOGIN.MEX4",
    MEX5 : "LOGIN.MEX5",
    MEXLOGIN : "LOGIN.MEXLOGIN",
    NONRICORDIPASS : "LOGIN.NONRICORDIPASS",
    NOTIFICHE : "LOGIN.NOTIFICHE",
    PASSWORDERRATA : "LOGIN.PASSWORDERRATA",
    PRIVACY : "LOGIN.PRIVACY",
    PROSEGUI : "LOGIN.PROSEGUI",
    TERMINI : "LOGIN.TERMINI",
    TERMINICONDIZIONI : "LOGIN.TERMINICONDIZIONI",
    TITLE1 : "LOGIN.TITLE1",
    TITLE2 : "LOGIN.TITLE2",
    TITLE3 : "LOGIN.TITLE3",
    TITLE4 : "LOGIN.TITLE4",
    TITLE5 : "LOGIN.TITLE5",
    USERNAMENONESISTE : "LOGIN.USERNAMENONESISTE",
    UTENTEAUTENTICATO : "LOGIN.UTENTEAUTENTICATO"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "LOGINFROMANONYMOUS.ACCEDI",
    ACCETTO : "LOGINFROMANONYMOUS.ACCETTO",
    ACCETTOLA : "LOGINFROMANONYMOUS.ACCETTOLA",
    BPROSEGUI : "LOGINFROMANONYMOUS.BPROSEGUI",
    CAMBIAPASS : "LOGINFROMANONYMOUS.CAMBIAPASS",
    INSERISCIMAIL : "LOGINFROMANONYMOUS.INSERISCIMAIL",
    MEX1 : "LOGINFROMANONYMOUS.MEX1",
    MEX2 : "LOGINFROMANONYMOUS.MEX2",
    MEX3 : "LOGINFROMANONYMOUS.MEX3",
    MEX4 : "LOGINFROMANONYMOUS.MEX4",
    MEX5 : "LOGINFROMANONYMOUS.MEX5",
    MEXLOGIN : "LOGINFROMANONYMOUS.MEXLOGIN",
    NIENTEACCOUNT : "LOGINFROMANONYMOUS.NIENTEACCOUNT",
    NONRICORDIPASS : "LOGINFROMANONYMOUS.NONRICORDIPASS",
    NOTIFICHE : "LOGINFROMANONYMOUS.NOTIFICHE",
    PRIVACY : "LOGINFROMANONYMOUS.PRIVACY",
    REGISTRATI : "LOGINFROMANONYMOUS.REGISTRATI",
    TERMINI : "LOGINFROMANONYMOUS.TERMINI",
    TERMINIECONDIZIONI : "LOGINFROMANONYMOUS.TERMINIECONDIZIONI",
    TITLE1 : "LOGINFROMANONYMOUS.TITLE1",
    TITLE2 : "LOGINFROMANONYMOUS.TITLE2",
    TITLE3 : "LOGINFROMANONYMOUS.TITLE3",
    TITLE4 : "LOGINFROMANONYMOUS.TITLE4",
    TITLE5 : "LOGINFROMANONYMOUS.TITLE5",
    TITOLO : "LOGINFROMANONYMOUS.TITOLO",
    UTENTEAUTENTICATO : "LOGINFROMANONYMOUS.UTENTEAUTENTICATO"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "LOGINREGISTERPROCESSONPUBLIC.CHIUDI",
    CONFERMA : "LOGINREGISTERPROCESSONPUBLIC.CONFERMA",
    ISCRIVITIPROCESSO : "LOGINREGISTERPROCESSONPUBLIC.ISCRIVITIPROCESSO",
    MEX1 : "LOGINREGISTERPROCESSONPUBLIC.MEX1",
    MEX2 : "LOGINREGISTERPROCESSONPUBLIC.MEX2",
    MEX3 : "LOGINREGISTERPROCESSONPUBLIC.MEX3",
    NONFAIPARTE : "LOGINREGISTERPROCESSONPUBLIC.NONFAIPARTE",
    NUOVOACCOUNT : "LOGINREGISTERPROCESSONPUBLIC.NUOVOACCOUNT",
    ORGANIZATION : "LOGINREGISTERPROCESSONPUBLIC.ORGANIZATION",
    PASSWORDERRATA : "LOGINREGISTERPROCESSONPUBLIC.PASSWORDERRATA",
    REGISTRATI : "LOGINREGISTERPROCESSONPUBLIC.REGISTRATI",
    TITLE2 : "LOGINREGISTERPROCESSONPUBLIC.TITLE2",
    TITLE3 : "LOGINREGISTERPROCESSONPUBLIC.TITLE3",
    USERNAMENONESISTE : "LOGINREGISTERPROCESSONPUBLIC.USERNAMENONESISTE"
  },
  MAINMENU : {
    ABOUT : "MAINMENU.ABOUT",
    ACCESSIBILITY : "MAINMENU.ACCESSIBILITY",
    COMMUNITY : "MAINMENU.COMMUNITY",
    COOKIE : "MAINMENU.COOKIE",
    DEMO : "MAINMENU.DEMO",
    DS : "MAINMENU.DS",
    EXPLORE : "MAINMENU.EXPLORE",
    FAQ : "MAINMENU.FAQ",
    FEATURE : "MAINMENU.FEATURE",
    GIOCHI : "MAINMENU.GIOCHI",
    HOME : "MAINMENU.HOME",
    HOWITWORKS : "MAINMENU.HOWITWORKS",
    HOWTOJOIN : "MAINMENU.HOWTOJOIN",
    JOINCOMMUNITY : "MAINMENU.JOINCOMMUNITY",
    LISTCHALLENGES : "MAINMENU.LISTCHALLENGES",
    LISTGROUPS : "MAINMENU.LISTGROUPS",
    LISTPROCESSES : "MAINMENU.LISTPROCESSES",
    LISTSCENARIOS : "MAINMENU.LISTSCENARIOS",
    LOGIN : "MAINMENU.LOGIN",
    LOGOUT : "MAINMENU.LOGOUT",
    PORTAL : "Portal",
    PRIVACY : "MAINMENU.PRIVACY",
    PROJECT : "MAINMENU.PROJECT",
    PROJECTS : "MAINMENU.PROJECTS",
    PS : "MAINMENU.PS",
    PSACTIVITIES : "MAINMENU.PSACTIVITIES",
    PSPROFILE : "MAINMENU.PSPROFILE",
    PSSETTINGS : "MAINMENU.PSSETTINGS",
    REGISTER : "MAINMENU.REGISTER",
    SERVICES : "MAINMENU.SERVICES",
    TERMS : "MAINMENU.TERMS",
    WORKSPACE : "MAINMENU.WORKSPACE"
  },
  MANDATORYFLAGS : {
    ACCETTO : "MANDATORYFLAGS.ACCETTO",
    ACCETTOLA : "MANDATORYFLAGS.ACCETTOLA",
    BPROSEGUI : "MANDATORYFLAGS.BPROSEGUI",
    DETTWELCOME : "MANDATORYFLAGS.DETTWELCOME",
    INSERISCIMAIL : "MANDATORYFLAGS.INSERISCIMAIL",
    MEX2 : "MANDATORYFLAGS.MEX2",
    MEX3 : "MANDATORYFLAGS.MEX3",
    MEX5 : "MANDATORYFLAGS.MEX5",
    NOTIFICHE : "MANDATORYFLAGS.NOTIFICHE",
    PRIVACY : "MANDATORYFLAGS.PRIVACY",
    TERMINIECONDIZIONI : "MANDATORYFLAGS.TERMINIECONDIZIONI",
    TITLE1 : "MANDATORYFLAGS.TITLE1",
    TITLE2 : "MANDATORYFLAGS.TITLE2",
    TITLE3 : "MANDATORYFLAGS.TITLE3",
    TITOLO : "MANDATORYFLAGS.TITOLO",
    TITOLOWELCOME : "MANDATORYFLAGS.TITOLOWELCOME",
    UTENTEAUTENTICATO : "MANDATORYFLAGS.UTENTEAUTENTICATO"
  },
  MESSAGEAUTH : {
    0 : "MESSAGEAUTH.0",
    1 : "MESSAGEAUTH.1",
    100 : "MESSAGEAUTH.100",
    105 : "MESSAGEAUTH.105",
    110 : "MESSAGEAUTH.110",
    115 : "MESSAGEAUTH.115",
    120 : "MESSAGEAUTH.120",
    125 : "MESSAGEAUTH.125",
    130 : "MESSAGEAUTH.130",
    135 : "MESSAGEAUTH.135",
    140 : "MESSAGEAUTH.140",
    145 : "MESSAGEAUTH.145",
    150 : "MESSAGEAUTH.150",
    155 : "MESSAGEAUTH.155",
    160 : "MESSAGEAUTH.160",
    165 : "MESSAGEAUTH.165",
    170 : "MESSAGEAUTH.170",
    172 : "MESSAGEAUTH.172",
    175 : "MESSAGEAUTH.175",
    180 : "MESSAGEAUTH.180",
    182 : "MESSAGEAUTH.182",
    185 : "MESSAGEAUTH.185",
    187 : "MESSAGEAUTH.187",
    190 : "MESSAGEAUTH.190",
    192 : "MESSAGEAUTH.192",
    2 : "MESSAGEAUTH.2",
    200 : "MESSAGEAUTH.200",
    205 : "MESSAGEAUTH.205",
    210 : "MESSAGEAUTH.210",
    215 : "MESSAGEAUTH.215",
    220 : "MESSAGEAUTH.220",
    225 : "MESSAGEAUTH.225",
    3 : "MESSAGEAUTH.3",
    300 : "MESSAGEAUTH.300",
    305 : "MESSAGEAUTH.305",
    310 : "MESSAGEAUTH.310",
    4 : "MESSAGEAUTH.4",
    400 : "MESSAGEAUTH.400",
    405 : "MESSAGEAUTH.405",
    410 : "MESSAGEAUTH.410",
    420 : "MESSAGEAUTH.420",
    425 : "MESSAGEAUTH.425",
    500 : "MESSAGEAUTH.500",
    502 : "MESSAGEAUTH.502",
    510 : "MESSAGEAUTH.510",
    515 : "MESSAGEAUTH.515",
    605 : "MESSAGEAUTH.605",
    700 : "MESSAGEAUTH.700",
    705 : "MESSAGEAUTH.705",
    710 : "MESSAGEAUTH.710",
    715 : "MESSAGEAUTH.715",
    720 : "MESSAGEAUTH.720",
    725 : "MESSAGEAUTH.725",
    ATTENZIONE : "MESSAGEAUTH.ATTENZIONE"
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "MODULEIDEACOMMONS.AGGIUNGIURL",
    MAXLENGTHPART1 : "MODULEIDEACOMMONS.MAXLENGTHPART1",
    MAXLENGTHPART2 : "MODULEIDEACOMMONS.MAXLENGTHPART2",
    PH0 : "MODULEIDEACOMMONS.PH0",
    PH5 : "MODULEIDEACOMMONS.PH5",
    SELEZIONASOTTOCATEGORIA : "MODULEIDEACOMMONS.SELEZIONASOTTOCATEGORIA",
    SOTTOCATEGORIA : "MODULEIDEACOMMONS.SOTTOCATEGORIA"
  },
  MODULEIDEACREATE : {
    ALERT1 : "MODULEIDEACREATE.ALERT1",
    ALERT1TITLE : "MODULEIDEACREATE.ALERT1TITLE",
    ALERT2 : "MODULEIDEACREATE.ALERT2",
    ALERT3 : "MODULEIDEACREATE.ALERT3",
    ALTROMATERIALE : "MODULEIDEACREATE.ALTROMATERIALE",
    ANNULLA : "MODULEIDEACREATE.ANNULLA",
    AVANTI : "MODULEIDEACREATE.AVANTI",
    BOZZA : "MODULEIDEACREATE.BOZZA",
    CHALLENGE : "MODULEIDEACREATE.CHALLENGE",
    COSTOIPOTIZZATO : "MODULEIDEACREATE.COSTOIPOTIZZATO",
    CREAIDEA : "MODULEIDEACREATE.CREAIDEA",
    DAIBREVETITOLO : "MODULEIDEACREATE.DAIBREVETITOLO",
    DESCRIVI : "MODULEIDEACREATE.DESCRIVI",
    DESCRIZIONE : "MODULEIDEACREATE.DESCRIZIONE",
    DOCALLEGATI : "MODULEIDEACREATE.DOCALLEGATI",
    FILEACCETTATI : "MODULEIDEACREATE.FILEACCETTATI",
    FILEACCETTATIDOC : "MODULEIDEACREATE.FILEACCETTATIDOC",
    FILEACCETTATIIMMAGINI : "MODULEIDEACREATE.FILEACCETTATIIMMAGINI",
    IMMAGINERAPPRESENTATIVA : "MODULEIDEACREATE.IMMAGINERAPPRESENTATIVA",
    IMMAGINI : "MODULEIDEACREATE.IMMAGINI",
    INDICACOSTO : "MODULEIDEACREATE.INDICACOSTO",
    ISTRUZIONI : "MODULEIDEACREATE.ISTRUZIONI",
    LOGO : "MODULEIDEACREATE.LOGO",
    MAPPEEINFO : "MODULEIDEACREATE.MAPPEEINFO",
    NARRA : "MODULEIDEACREATE.NARRA",
    NARRAZIONE : "MODULEIDEACREATE.NARRAZIONE",
    OVERVIEW : "MODULEIDEACREATE.OVERVIEW",
    PH1 : "MODULEIDEACREATE.PH1",
    PH2 : "MODULEIDEACREATE.PH2",
    PH3 : "MODULEIDEACREATE.PH3",
    PH4 : "MODULEIDEACREATE.PH4",
    PUBBLICA : "MODULEIDEACREATE.PUBBLICA",
    SCEGLISFIDE : "MODULEIDEACREATE.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULEIDEACREATE.SELEZIONAMAPPA",
    SELEZIONATRA : "MODULEIDEACREATE.SELEZIONATRA",
    TITOLO : "MODULEIDEACREATE.TITOLO",
    TRASCINA : "MODULEIDEACREATE.TRASCINA",
    TRASCINADOC : "MODULEIDEACREATE.TRASCINADOC",
    TRASCINAIMMAGINI : "MODULEIDEACREATE.TRASCINAIMMAGINI",
    URLSITO : "MODULEIDEACREATE.URLSITO",
    VIDEO : "MODULEIDEACREATE.VIDEO",
    VIDEOHINT : "MODULEIDEACREATE.VIDEOHINT"
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "MODULEIDEAEDIT.AGGIORNATITOLO",
    AGGIUNGIALTREIMMAGINI : "MODULEIDEAEDIT.AGGIUNGIALTREIMMAGINI",
    ALERT1 : "MODULEIDEAEDIT.ALERT1",
    ALERT1TITLE : "MODULEIDEAEDIT.ALERT1TITLE",
    ALERT2 : "MODULEIDEAEDIT.ALERT2",
    ALERT2TITLE : "MODULEIDEAEDIT.ALERT2TITLE",
    ALERT3 : "MODULEIDEAEDIT.ALERT3",
    ALERT4 : "MODULEIDEAEDIT.ALERT4",
    ALERT5 : "MODULEIDEAEDIT.ALERT5",
    ALTROMATERIALE : "MODULEIDEAEDIT.ALTROMATERIALE",
    ANNULLA : "MODULEIDEAEDIT.ANNULLA",
    AVANTI : "MODULEIDEAEDIT.AVANTI",
    BOZZA : "MODULEIDEAEDIT.BOZZA",
    CANCELLA : "MODULEIDEAEDIT.CANCELLA",
    CHALLENGE : "MODULEIDEAEDIT.CHALLENGE",
    COSTO : "MODULEIDEAEDIT.COSTO",
    COSTOIPOTIZZATO : "MODULEIDEAEDIT.COSTOIPOTIZZATO",
    DESCRIVI : "MODULEIDEAEDIT.DESCRIVI",
    DESCRIZIONE : "MODULEIDEAEDIT.DESCRIZIONE",
    DOCALLEGATI : "MODULEIDEAEDIT.DOCALLEGATI",
    DOCTECNICA : "MODULEIDEAEDIT.DOCTECNICA",
    EDITRESOURCESMODALTITLE : "MODULEIDEAEDIT.EDITRESOURCESMODALTITLE",
    ERR1 : "MODULEIDEAEDIT.ERR1",
    FILEACCETTATI : "MODULEIDEAEDIT.FILEACCETTATI",
    FILEACCETTATIDOC : "MODULEIDEAEDIT.FILEACCETTATIDOC",
    FILEACCETTATIIMMAGINI : "MODULEIDEAEDIT.FILEACCETTATIIMMAGINI",
    IMMAGINI : "MODULEIDEAEDIT.IMMAGINI",
    ISTRUZIONI : "MODULEIDEAEDIT.ISTRUZIONI",
    LINKVIDEO : "MODULEIDEAEDIT.LINKVIDEO",
    LOGO : "MODULEIDEAEDIT.LOGO",
    MAPPEEINFO : "MODULEIDEAEDIT.MAPPEEINFO",
    MODIFICA : "MODULEIDEAEDIT.MODIFICA",
    MODIFICAIDEA : "MODULEIDEAEDIT.MODIFICAIDEA",
    MOSTRA : "MODULEIDEAEDIT.MOSTRA",
    NARRA : "MODULEIDEAEDIT.NARRA",
    NARRAZIONE : "MODULEIDEAEDIT.NARRAZIONE",
    OVERVIEW : "MODULEIDEAEDIT.OVERVIEW",
    PH1 : "MODULEIDEAEDIT.PH1",
    PH2 : "MODULEIDEAEDIT.PH2",
    PH3 : "MODULEIDEAEDIT.PH3",
    PH4 : "MODULEIDEAEDIT.PH4",
    PUBBLICA : "MODULEIDEAEDIT.PUBBLICA",
    SCEGLISFIDE : "MODULEIDEAEDIT.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULEIDEAEDIT.SELEZIONAMAPPA",
    SELEZIONATRA : "MODULEIDEAEDIT.SELEZIONATRA",
    TITLE : "MODULEIDEAEDIT.TITLE",
    TITOLO : "MODULEIDEAEDIT.TITOLO",
    TRASCINA : "MODULEIDEAEDIT.TRASCINA",
    TRASCINADOC : "MODULEIDEAEDIT.TRASCINADOC",
    TRASCINAIMMAGINI : "MODULEIDEAEDIT.TRASCINAIMMAGINI",
    URLSITO : "MODULEIDEAEDIT.URLSITO",
    VIDEO : "MODULEIDEAEDIT.VIDEO",
    VIDEOHINT : "MODULEIDEAEDIT.VIDEOHINT"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "MODULEIDEAVERSIONING.ABBINACONTESTO",
    AGGIORNATITOLO : "MODULEIDEAVERSIONING.AGGIORNATITOLO",
    AGGIUNGIALTREIMMAGINI : "MODULEIDEAVERSIONING.AGGIUNGIALTREIMMAGINI",
    ALERT1 : "MODULEIDEAVERSIONING.ALERT1",
    ALERT1TITLE : "MODULEIDEAVERSIONING.ALERT1TITLE",
    ALERT2 : "MODULEIDEAVERSIONING.ALERT2",
    ALERT2TITLE : "MODULEIDEAVERSIONING.ALERT2TITLE",
    ALERT3 : "MODULEIDEAVERSIONING.ALERT3",
    ALERT4 : "MODULEIDEAVERSIONING.ALERT4",
    ALERT5 : "MODULEIDEAVERSIONING.ALERT5",
    ALTROMATERIALE : "MODULEIDEAVERSIONING.ALTROMATERIALE",
    ANNULLA : "MODULEIDEAVERSIONING.ANNULLA",
    AVANTI : "MODULEIDEAVERSIONING.AVANTI",
    BOZZA : "MODULEIDEAVERSIONING.BOZZA",
    CANCELLA : "MODULEIDEAVERSIONING.CANCELLA",
    CHALLENGE : "MODULEIDEAVERSIONING.CHALLENGE",
    COSTO : "MODULEIDEAVERSIONING.COSTO",
    COSTOIPOTIZZATO : "MODULEIDEAVERSIONING.COSTOIPOTIZZATO",
    DESCRIVI : "MODULEIDEAVERSIONING.DESCRIVI",
    DESCRIZIONE : "MODULEIDEAVERSIONING.DESCRIZIONE",
    DOCALLEGATI : "MODULEIDEAVERSIONING.DOCALLEGATI",
    DOCTECNICA : "MODULEIDEAVERSIONING.DOCTECNICA",
    EDITRESOURCESMODALTITLE : "MODULEIDEAVERSIONING.EDITRESOURCESMODALTITLE",
    ELENCOIDEE : "MODULEIDEAVERSIONING.ELENCOIDEE",
    ERR1 : "MODULEIDEAVERSIONING.ERR1",
    FILEACCETTATI : "MODULEIDEAVERSIONING.FILEACCETTATI",
    FILEACCETTATIDOC : "MODULEIDEAVERSIONING.FILEACCETTATIDOC",
    FILEACCETTATIIMMAGINI : "MODULEIDEAVERSIONING.FILEACCETTATIIMMAGINI",
    IMMAGINI : "MODULEIDEAVERSIONING.IMMAGINI",
    ISTRUZIONI : "MODULEIDEAVERSIONING.ISTRUZIONI",
    LINKVIDEO : "MODULEIDEAVERSIONING.LINKVIDEO",
    LOGO : "MODULEIDEAVERSIONING.LOGO",
    MAPPEEINFO : "MODULEIDEAVERSIONING.MAPPEEINFO",
    MODIFICA : "MODULEIDEAVERSIONING.MODIFICA",
    MODIFICAIDEA : "MODULEIDEAVERSIONING.MODIFICAIDEA",
    MOSTRA : "MODULEIDEAVERSIONING.MOSTRA",
    NARRA : "MODULEIDEAVERSIONING.NARRA",
    NARRAZIONE : "MODULEIDEAVERSIONING.NARRAZIONE",
    NUOVAVERSIONE : "MODULEIDEAVERSIONING.NUOVAVERSIONE",
    NUOVAVERSIONEMESSAGGIO1 : "MODULEIDEAVERSIONING.NUOVAVERSIONEMESSAGGIO1",
    NUOVAVERSIONEMESSAGGIO2 : "MODULEIDEAVERSIONING.NUOVAVERSIONEMESSAGGIO2",
    NUOVAVERSIONEMESSAGGIO3 : "MODULEIDEAVERSIONING.NUOVAVERSIONEMESSAGGIO3",
    OVERVIEW : "MODULEIDEAVERSIONING.OVERVIEW",
    PH1 : "MODULEIDEAVERSIONING.PH1",
    PH2 : "MODULEIDEAVERSIONING.PH2",
    PH3 : "MODULEIDEAVERSIONING.PH3",
    PH4 : "MODULEIDEAVERSIONING.PH4",
    PROCESSO : "MODULEIDEAVERSIONING.PROCESSO",
    PUBBLICA : "MODULEIDEAVERSIONING.PUBBLICA",
    SCEGLISFIDE : "MODULEIDEAVERSIONING.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULEIDEAVERSIONING.SELEZIONAMAPPA",
    SELEZIONATRA : "MODULEIDEAVERSIONING.SELEZIONATRA",
    TITLE : "MODULEIDEAVERSIONING.TITLE",
    TITOLO : "MODULEIDEAVERSIONING.TITOLO",
    TRASCINA : "MODULEIDEAVERSIONING.TRASCINA",
    TRASCINADOC : "MODULEIDEAVERSIONING.TRASCINADOC",
    TRASCINAIMMAGINI : "MODULEIDEAVERSIONING.TRASCINAIMMAGINI",
    URLSITO : "MODULEIDEAVERSIONING.URLSITO",
    VIDEO : "MODULEIDEAVERSIONING.VIDEO",
    VIDEOHINT : "MODULEIDEAVERSIONING.VIDEOHINT"
  },
  MODULEINFO : {
    ELENCOPROCESSI : "MODULEINFO.ELENCOPROCESSI",
    INFO : "MODULEINFO.INFO",
    INPROGRESS : "MODULEINFO.INPROGRESS",
    PROCESSO : "MODULEINFO.PROCESSO"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "MODULEINPROGRESS.ELENCOPROCESSI",
    INFO : "MODULEINPROGRESS.INFO",
    INPROGRESS : "MODULEINPROGRESS.INPROGRESS",
    PROCESSO : "MODULEINPROGRESS.PROCESSO"
  },
  MODULESERVICECREATE : {
    ALERT1 : "MODULESERVICECREATE.ALERT1",
    ALERT2 : "MODULESERVICECREATE.ALERT2",
    ALERT3 : "MODULESERVICECREATE.ALERT3",
    ALTROMATERIALE : "MODULESERVICECREATE.ALTROMATERIALE",
    CREAIDEA : "MODULESERVICECREATE.CREAIDEA",
    DAIBREVETITOLO : "MODULESERVICECREATE.DAIBREVETITOLO",
    DESCRIVI : "MODULESERVICECREATE.DESCRIVI",
    IMMAGINERAPPRESENTATIVA : "MODULESERVICECREATE.IMMAGINERAPPRESENTATIVA",
    IMMAGINI : "MODULESERVICECREATE.IMMAGINI",
    INDICACOSTO : "MODULESERVICECREATE.INDICACOSTO",
    NARRA : "MODULESERVICECREATE.NARRA",
    NARRAZIONE : "MODULESERVICECREATE.NARRAZIONE",
    SCEGLISFIDE : "MODULESERVICECREATE.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULESERVICECREATE.SELEZIONAMAPPA",
    URLSITO : "MODULESERVICECREATE.URLSITO",
    VIDEO : "MODULESERVICECREATE.VIDEO",
    VIDEOHINT : "MODULESERVICECREATE.VIDEOHINT"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "MODULESERVICEEDIT.ALERT2",
    ALERT2TITLE : "MODULESERVICEEDIT.ALERT2TITLE",
    ALERT3 : "MODULESERVICEEDIT.ALERT3",
    ALTROMATERIALE : "MODULESERVICEEDIT.ALTROMATERIALE",
    MODIFICAIDEA : "MODULESERVICEEDIT.MODIFICAIDEA",
    NARRA : "MODULESERVICEEDIT.NARRA",
    NARRAZIONE : "MODULESERVICEEDIT.NARRAZIONE",
    SCEGLISFIDE : "MODULESERVICEEDIT.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULESERVICEEDIT.SELEZIONAMAPPA",
    URLSITO : "MODULESERVICEEDIT.URLSITO"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "MODULESERVICEVERSIONING.ALERT1",
    ALERT2 : "MODULESERVICEVERSIONING.ALERT2",
    ALERT2TITLE : "MODULESERVICEVERSIONING.ALERT2TITLE",
    ALERT3 : "MODULESERVICEVERSIONING.ALERT3",
    ALERT5 : "MODULESERVICEVERSIONING.ALERT5",
    ALTROMATERIALE : "MODULESERVICEVERSIONING.ALTROMATERIALE",
    ELENCOIDEE : "MODULESERVICEVERSIONING.ELENCOIDEE",
    MODIFICAIDEA : "MODULESERVICEVERSIONING.MODIFICAIDEA",
    NARRA : "MODULESERVICEVERSIONING.NARRA",
    NARRAZIONE : "MODULESERVICEVERSIONING.NARRAZIONE",
    NUOVAVERSIONEMESSAGGIO3 : "MODULESERVICEVERSIONING.NUOVAVERSIONEMESSAGGIO3",
    SCEGLISFIDE : "MODULESERVICEVERSIONING.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULESERVICEVERSIONING.SELEZIONAMAPPA",
    URLSITO : "MODULESERVICEVERSIONING.URLSITO"
  },
  MODULESTORYCREATE : {
    ALERT1 : "MODULESTORYCREATE.ALERT1",
    ALERT2 : "MODULESTORYCREATE.ALERT2",
    ALERT3 : "MODULESTORYCREATE.ALERT3",
    ALTROMATERIALE : "MODULESTORYCREATE.ALTROMATERIALE",
    CREAIDEA : "MODULESTORYCREATE.CREAIDEA",
    DAIBREVETITOLO : "MODULESTORYCREATE.DAIBREVETITOLO",
    DESCRIVI : "MODULESTORYCREATE.DESCRIVI",
    IMMAGINERAPPRESENTATIVA : "MODULESTORYCREATE.IMMAGINERAPPRESENTATIVA",
    IMMAGINI : "MODULESTORYCREATE.IMMAGINI",
    INDICACOSTO : "MODULESTORYCREATE.INDICACOSTO",
    NARRA : "MODULESTORYCREATE.NARRA",
    NARRAZIONE : "MODULESTORYCREATE.NARRAZIONE",
    SCEGLISFIDE : "MODULESTORYCREATE.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULESTORYCREATE.SELEZIONAMAPPA",
    URLSITO : "MODULESTORYCREATE.URLSITO",
    VIDEO : "MODULESTORYCREATE.VIDEO",
    VIDEOHINT : "MODULESTORYCREATE.VIDEOHINT"
  },
  MODULESTORYEDIT : {
    ALERT2 : "MODULESTORYEDIT.ALERT2",
    ALERT2TITLE : "MODULESTORYEDIT.ALERT2TITLE",
    ALERT3 : "MODULESTORYEDIT.ALERT3",
    ALTROMATERIALE : "MODULESTORYEDIT.ALTROMATERIALE",
    MODIFICAIDEA : "MODULESTORYEDIT.MODIFICAIDEA",
    NARRA : "MODULESTORYEDIT.NARRA",
    NARRAZIONE : "MODULESTORYEDIT.NARRAZIONE",
    SCEGLISFIDE : "MODULESTORYEDIT.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULESTORYEDIT.SELEZIONAMAPPA",
    URLSITO : "MODULESTORYEDIT.URLSITO"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "MODULESTORYVERSIONING.ALERT1",
    ALERT2 : "MODULESTORYVERSIONING.ALERT2",
    ALERT2TITLE : "MODULESTORYVERSIONING.ALERT2TITLE",
    ALERT3 : "MODULESTORYVERSIONING.ALERT3",
    ALERT5 : "MODULESTORYVERSIONING.ALERT5",
    ALTROMATERIALE : "MODULESTORYVERSIONING.ALTROMATERIALE",
    ELENCOIDEE : "MODULESTORYVERSIONING.ELENCOIDEE",
    MODIFICAIDEA : "MODULESTORYVERSIONING.MODIFICAIDEA",
    NARRA : "MODULESTORYVERSIONING.NARRA",
    NARRAZIONE : "MODULESTORYVERSIONING.NARRAZIONE",
    NUOVAVERSIONEMESSAGGIO3 : "MODULESTORYVERSIONING.NUOVAVERSIONEMESSAGGIO3",
    SCEGLISFIDE : "MODULESTORYVERSIONING.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULESTORYVERSIONING.SELEZIONAMAPPA",
    URLSITO : "MODULESTORYVERSIONING.URLSITO"
  },
  MODULETACTICCREATE : {
    ALERT1 : "MODULETACTICCREATE.ALERT1",
    ALERT2 : "MODULETACTICCREATE.ALERT2",
    ALERT3 : "MODULETACTICCREATE.ALERT3",
    ALTROMATERIALE : "MODULETACTICCREATE.ALTROMATERIALE",
    CREAIDEA : "MODULETACTICCREATE.CREAIDEA",
    DAIBREVETITOLO : "MODULETACTICCREATE.DAIBREVETITOLO",
    DESCRIVI : "MODULETACTICCREATE.DESCRIVI",
    IMMAGINERAPPRESENTATIVA : "MODULETACTICCREATE.IMMAGINERAPPRESENTATIVA",
    IMMAGINI : "MODULETACTICCREATE.IMMAGINI",
    INDICACOSTO : "MODULETACTICCREATE.INDICACOSTO",
    NARRA : "MODULETACTICCREATE.NARRA",
    NARRAZIONE : "MODULETACTICCREATE.NARRAZIONE",
    SCEGLISFIDE : "MODULETACTICCREATE.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULETACTICCREATE.SELEZIONAMAPPA",
    URLSITO : "MODULETACTICCREATE.URLSITO",
    VIDEO : "MODULETACTICCREATE.VIDEO",
    VIDEOHINT : "MODULETACTICCREATE.VIDEOHINT"
  },
  MODULETACTICEDIT : {
    ALERT2 : "MODULETACTICEDIT.ALERT2",
    ALERT2TITLE : "MODULETACTICEDIT.ALERT2TITLE",
    ALERT3 : "MODULETACTICEDIT.ALERT3",
    ALTROMATERIALE : "MODULETACTICEDIT.ALTROMATERIALE",
    MODIFICAIDEA : "MODULETACTICEDIT.MODIFICAIDEA",
    NARRA : "MODULETACTICEDIT.NARRA",
    NARRAZIONE : "MODULETACTICEDIT.NARRAZIONE",
    SCEGLISFIDE : "MODULETACTICEDIT.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULETACTICEDIT.SELEZIONAMAPPA",
    URLSITO : "MODULETACTICEDIT.URLSITO"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "MODULETACTICVERSIONING.ALERT1",
    ALERT2 : "MODULETACTICVERSIONING.ALERT2",
    ALERT2TITLE : "MODULETACTICVERSIONING.ALERT2TITLE",
    ALERT3 : "MODULETACTICVERSIONING.ALERT3",
    ALERT5 : "MODULETACTICVERSIONING.ALERT5",
    ALTROMATERIALE : "MODULETACTICVERSIONING.ALTROMATERIALE",
    ELENCOIDEE : "MODULETACTICVERSIONING.ELENCOIDEE",
    MODIFICAIDEA : "MODULETACTICVERSIONING.MODIFICAIDEA",
    NARRA : "MODULETACTICVERSIONING.NARRA",
    NARRAZIONE : "MODULETACTICVERSIONING.NARRAZIONE",
    NUOVAVERSIONEMESSAGGIO3 : "MODULETACTICVERSIONING.NUOVAVERSIONEMESSAGGIO3",
    SCEGLISFIDE : "MODULETACTICVERSIONING.SCEGLISFIDE",
    SELEZIONAMAPPA : "MODULETACTICVERSIONING.SELEZIONAMAPPA",
    URLSITO : "MODULETACTICVERSIONING.URLSITO"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "PARTIALSCOMMUNITY.COMMUNITY",
    ISCRITTI : "PARTIALSCOMMUNITY.ISCRITTI"
  },
  PARTIALSIDEE : {
    APPROVED : "PARTIALSIDEE.APPROVED",
    BUDGET : "PARTIALSIDEE.BUDGET",
    CREATADA : "PARTIALSIDEE.CREATADA",
    RANK01 : "PARTIALSIDEE.RANK01",
    VOTATA : "PARTIALSIDEE.VOTATA"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "PARTIALSPROCESSI.ALERT1",
    ALERT1TITLE : "PARTIALSPROCESSI.ALERT1TITLE",
    ALERT2 : "PARTIALSPROCESSI.ALERT2",
    ALERT2TITLE : "PARTIALSPROCESSI.ALERT2TITLE",
    ALERT3 : "PARTIALSPROCESSI.ALERT3",
    ALERT3TITLE : "PARTIALSPROCESSI.ALERT3TITLE",
    COMMENTI : "PARTIALSPROCESSI.COMMENTI",
    COMMUNITY : "PARTIALSPROCESSI.COMMUNITY",
    CONCLUSA : "PARTIALSPROCESSI.CONCLUSA",
    CONCLUSO : "PARTIALSPROCESSI.CONCLUSO",
    DAATTIVARE : "PARTIALSPROCESSI.DAATTIVARE",
    DRIVERAMBIENTE : "PARTIALSPROCESSI.DRIVERAMBIENTE",
    FASE : "PARTIALSPROCESSI.FASE",
    INATTIVAZIONE : "PARTIALSPROCESSI.INATTIVAZIONE",
    INCORSO : "PARTIALSPROCESSI.INCORSO",
    PARTECIPA : "PARTIALSPROCESSI.PARTECIPA",
    PRIVATO : "PARTIALSPROCESSI.PRIVATO",
    PUBBLICO : "PARTIALSPROCESSI.PUBBLICO",
    RICHIEDIACCESSO : "PARTIALSPROCESSI.RICHIEDIACCESSO",
    VALUTAZIONI : "PARTIALSPROCESSI.VALUTAZIONI",
    VISUALIZZA : "PARTIALSPROCESSI.VISUALIZZA",
    VOTA : "PARTIALSPROCESSI.VOTA"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "PASSWORDRECOVERY.ALERT1",
    ALERT1TITLE : "PASSWORDRECOVERY.ALERT1TITLE",
    ALERTPHONE1HTML : "PASSWORDRECOVERY.ALERTPHONE1HTML",
    ALERTPHONE1TITLE : "PASSWORDRECOVERY.ALERTPHONE1TITLE",
    CAMBIAPASSWORD : "PASSWORDRECOVERY.CAMBIAPASSWORD",
    CODICEOTP : "PASSWORDRECOVERY.CODICEOTP",
    CONFERMA : "PASSWORDRECOVERY.CONFERMA",
    CONFERMAPASSWORD : "PASSWORDRECOVERY.CONFERMAPASSWORD",
    ERROROTPMAXCHAR : "PASSWORDRECOVERY.ERROROTPMAXCHAR",
    ERRORPWEQUAL : "PASSWORDRECOVERY.ERRORPWEQUAL",
    ERRORPWLENGTH : "PASSWORDRECOVERY.ERRORPWLENGTH",
    INSERISCICELLULARE : "PASSWORDRECOVERY.INSERISCICELLULARE",
    INSERISCIPASSWORD : "PASSWORDRECOVERY.INSERISCIPASSWORD",
    METODOCELLULARE : "PASSWORDRECOVERY.METODOCELLULARE",
    METODORECUPERO : "PASSWORDRECOVERY.METODORECUPERO",
    OTPDIMENTICATO : "PASSWORDRECOVERY.OTPDIMENTICATO",
    PHONEERROR1 : "PASSWORDRECOVERY.PHONEERROR1",
    PHONEERROR2 : "PASSWORDRECOVERY.PHONEERROR2",
    PHONEERROR3 : "PASSWORDRECOVERY.PHONEERROR3",
    PROCEDI : "PASSWORDRECOVERY.PROCEDI",
    RECUPEROPASSWORD : "PASSWORDRECOVERY.RECUPEROPASSWORD",
    RECUPERORIUSCITO : "PASSWORDRECOVERY.RECUPERORIUSCITO",
    RICHIEDINUOVOCODICE1 : "PASSWORDRECOVERY.RICHIEDINUOVOCODICE1",
    RICHIEDINUOVOCODICE2 : "PASSWORDRECOVERY.RICHIEDINUOVOCODICE2",
    RICHIEDINUOVOCODICE3 : "PASSWORDRECOVERY.RICHIEDINUOVOCODICE3",
    SCELTAMETODO : "PASSWORDRECOVERY.SCELTAMETODO",
    SCELTAMETODONECESSARIA : "PASSWORDRECOVERY.SCELTAMETODONECESSARIA",
    VERIFICA1 : "PASSWORDRECOVERY.VERIFICA1",
    VERIFICA2 : "PASSWORDRECOVERY.VERIFICA2"
  },
  PHASEMODULECARDIDEA : {
    IDEE : "PHASEMODULECARDIDEA.IDEE"
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "PHASEMODULECARDSERVICE.IDEE"
  },
  PHASEMODULECARDSTORY : {
    IDEE : "PHASEMODULECARDSTORY.IDEE"
  },
  PHASEMODULECARDTACTIC : {
    IDEE : "PHASEMODULECARDTACTIC.IDEE"
  },
  PORTAL : {
    CONTACTUS : "PORTAL.CONTACTUS",
    DELIBERATIVESPACE : "PORTAL.DELIBERATIVESPACE",
    HOME : "PORTAL.HOME",
    INSTRUMENTS : "PORTAL.INSTRUMENTS",
    PROJECT : "PORTAL.PROJECT",
    SUBSCRIBE : "PORTAL.SUBSCRIBE"
  },
  PRIVACY : {
    DIRITTO : "PRIVACY.DIRITTO",
    DIRITTODESCRIZIONE1 : "PRIVACY.DIRITTODESCRIZIONE1",
    DIRITTODESCRIZIONE2 : "PRIVACY.DIRITTODESCRIZIONE2",
    FINALITA : "PRIVACY.FINALITA",
    FINALITADESCRIZIONE1 : "PRIVACY.FINALITADESCRIZIONE1",
    FINALITADESCRIZIONE2 : "PRIVACY.FINALITADESCRIZIONE2",
    FINALITADESCRIZIONE3 : "PRIVACY.FINALITADESCRIZIONE3",
    INTRO : "PRIVACY.INTRO",
    INTRODESCRIZIONE1 : "PRIVACY.INTRODESCRIZIONE1",
    INTRODESCRIZIONE2 : "PRIVACY.INTRODESCRIZIONE2",
    MANIFESTO : "PRIVACY.MANIFESTO",
    MANIFESTODESCRIZIONE1 : "PRIVACY.MANIFESTODESCRIZIONE1",
    MODALITA : "PRIVACY.MODALITA",
    MODALITADESCRIZIONE1 : "PRIVACY.MODALITADESCRIZIONE1",
    MODALITADESCRIZIONE2 : "PRIVACY.MODALITADESCRIZIONE2",
    MODALITADESCRIZIONE3 : "PRIVACY.MODALITADESCRIZIONE3",
    SOGGETTI : "PRIVACY.SOGGETTI",
    SOGGETTIDESCRIZIONE1 : "PRIVACY.SOGGETTIDESCRIZIONE1",
    SOGGETTIDESCRIZIONE2 : "PRIVACY.SOGGETTIDESCRIZIONE2",
    SOGGETTIDESCRIZIONE3 : "PRIVACY.SOGGETTIDESCRIZIONE3",
    SOTTOTITOLO : "PRIVACY.SOTTOTITOLO",
    TIPOLOGIA : "PRIVACY.TIPOLOGIA",
    TIPOLOGIADESCRIZIONE1 : "PRIVACY.TIPOLOGIADESCRIZIONE1",
    TIPOLOGIADESCRIZIONE2 : "PRIVACY.TIPOLOGIADESCRIZIONE2",
    TITOLO : "PRIVACY.TITOLO"
  },
  PROCESSABOUT : {
    FASE : "PROCESSABOUT.FASE",
    TITOLO : "PROCESSABOUT.TITOLO"
  },
  PROCESSCONTATTI : {
    CONTATTI : "PROCESSCONTATTI.CONTATTI"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "PROCESSDETAIL.BUDGETDISPONIBILE",
    BUDGETPROPOSTO : "PROCESSDETAIL.BUDGETPROPOSTO",
    CONCLUSA : "PROCESSDETAIL.CONCLUSA",
    DAATTIVARE : "PROCESSDETAIL.DAATTIVARE",
    FASIPROCESSO : "PROCESSDETAIL.FASIPROCESSO",
    INCORSO : "PROCESSDETAIL.INCORSO",
    ITUOIVOTI : "PROCESSDETAIL.ITUOIVOTI",
    PROGRESS : "PROCESSDETAIL.PROGRESS"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : "PROCESSFAQ.DOMANDERISPOSTE"
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : "PROCESSGUIDELINES.LINEEGUIDA"
  },
  PROCESSLIST : {
    BUDGET : "PROCESSLIST.BUDGET",
    DATAINIZIO : "PROCESSLIST.DATAINIZIO",
    DATAINS : "PROCESSLIST.DATAINS",
    PARTECIPANTI : "PROCESSLIST.PARTECIPANTI",
    PROCESSI : "PROCESSLIST.PROCESSI",
    TITOLO : "PROCESSLIST.TITOLO"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "PROCESSLISTCHALLENGES.ARGOMENTI",
    NOTEMATICHE : "PROCESSLISTCHALLENGES.NOTEMATICHE",
    OBIETTIVI : "PROCESSLISTCHALLENGES.OBIETTIVI"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "PROCESSLISTGROUPS.COMMUNITY",
    GRUPPI : "PROCESSLISTGROUPS.GRUPPI",
    TUTTI : "PROCESSLISTGROUPS.TUTTI"
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "PROCESSLISTSCENARIOS.CONTESTO",
    INFO : "PROCESSLISTSCENARIOS.INFO",
    NOSCENARI : "PROCESSLISTSCENARIOS.NOSCENARI"
  },
  PROCESSMENU : {
    ABOUT : "PROCESSMENU.ABOUT",
    ASSISTENZA : "PROCESSMENU.ASSISTENZA",
    BACK : "PROCESSMENU.BACK",
    COMMUNITY : "PROCESSMENU.COMMUNITY",
    FAQ : "PROCESSMENU.FAQ",
    ISTRUZIONI : "PROCESSMENU.ISTRUZIONI",
    NARRAZIONE : "PROCESSMENU.NARRAZIONE",
    POP1 : "PROCESSMENU.POP1",
    POP1DESCRIPTION : "PROCESSMENU.POP1DESCRIPTION"
  },
  PROCESSPHASE : {
    ALERTBOZZA : "PROCESSPHASE.ALERTBOZZA",
    BREADCRUMBTITLE : "PROCESSPHASE.BREADCRUMBTITLE",
    COMMENTIMENONUMEROSI : "PROCESSPHASE.COMMENTIMENONUMEROSI",
    COMMENTIPIUNUMEROSI : "PROCESSPHASE.COMMENTIPIUNUMEROSI",
    CONTATOREMODULI : "PROCESSPHASE.CONTATOREMODULI",
    IDEE : "PROCESSPHASE.IDEE",
    INFORMAZIONI : "PROCESSPHASE.INFORMAZIONI",
    NAVIGATUTTELEPROPOSTE : "PROCESSPHASE.NAVIGATUTTELEPROPOSTE",
    NONCISONOPROPOSTE : "PROCESSPHASE.NONCISONOPROPOSTE",
    PS : "PROCESSPHASE.PS",
    SCEGLIDOVEPARTECIPARE : "PROCESSPHASE.SCEGLIDOVEPARTECIPARE"
  },
  PS : {
    ALERTMODIFICADISABILITATA : "PS.ALERTMODIFICADISABILITATA",
    ALERTMODIFICADISABILITATATITLE : "PS.ALERTMODIFICADISABILITATATITLE",
    ANNUALINCOME : "PS.ANNUALINCOME",
    ATTEGGIAMENTO : "PS.ATTEGGIAMENTO",
    BIOGRAPHY : "PS.BIOGRAPHY",
    BUDGET : "PS.BUDGET",
    BUDGETTODONATE : "PS.BUDGETTODONATE",
    COMPORTAMENTO : "PS.COMPORTAMENTO",
    EDU : "PS.EDU",
    EDULAV : "PS.EDULAV",
    FEMMINA : "PS.FEMMINA",
    LAV : "PS.LAV",
    MASCHIO : "PS.MASCHIO",
    MODIFICA : "PS.MODIFICA",
    NS : "PS.NS",
    POLVIS : "PS.POLVIS",
    PROFILE : "PS.PROFILE",
    PS : "PS.PS"
  },
  PSACCOUNT : {
    ANONIMO : "PSACCOUNT.ANONIMO",
    AS : "PSACCOUNT.AS",
    CITIZENSHIP : "PSACCOUNT.CITIZENSHIP",
    COGNOME : "PSACCOUNT.COGNOME",
    COGNOMENONPRESENTE : "PSACCOUNT.COGNOMENONPRESENTE",
    ELIMINAACCOUNT : "PSACCOUNT.ELIMINAACCOUNT",
    ELIMINAACCOUNTTITLE : "PSACCOUNT.ELIMINAACCOUNTTITLE",
    EMAIL : "PSACCOUNT.EMAIL",
    EMAILNONPRESENTE : "PSACCOUNT.EMAILNONPRESENTE",
    NOME : "PSACCOUNT.NOME",
    NOMENONPRESENTE : "PSACCOUNT.NOMENONPRESENTE",
    PASSWORD : "PSACCOUNT.PASSWORD",
    PHONE : "PSACCOUNT.PHONE",
    PHONENONPRESENTE : "PSACCOUNT.PHONENONPRESENTE",
    PRIVATO : "PSACCOUNT.PRIVATO",
    PS : "PSACCOUNT.PS",
    PUBBLICO : "PSACCOUNT.PUBBLICO",
    RESIDENCECITY : "PSACCOUNT.RESIDENCECITY",
    TAXCODE : "PSACCOUNT.TAXCODE",
    TAXCODENONPRESENTE : "PSACCOUNT.TAXCODENONPRESENTE",
    TUOPROFILO : "PSACCOUNT.TUOPROFILO",
    USERNAME : "PSACCOUNT.USERNAME",
    USERNAMENONPRESENTE : "PSACCOUNT.USERNAMENONPRESENTE"
  },
  PSACTIVITIES : {
    ALERTDETAIL : "PSACTIVITIES.ALERTDETAIL",
    ALERTINFO : "PSACTIVITIES.ALERTINFO",
    ATTIVITA : "PSACTIVITIES.ATTIVITA",
    BUDGETDONATO : "PSACTIVITIES.BUDGETDONATO",
    FOLLOWERS : "PSACTIVITIES.FOLLOWERS",
    IDEESEGUITE : "PSACTIVITIES.IDEESEGUITE",
    IDEEVOTATE : "PSACTIVITIES.IDEEVOTATE",
    INBOZZA : "PSACTIVITIES.INBOZZA",
    MODIFICAVOTO : "PSACTIVITIES.MODIFICAVOTO",
    NOPROCESSI : "PSACTIVITIES.NOPROCESSI",
    NOPROCESSIATTIVI : "PSACTIVITIES.NOPROCESSIATTIVI",
    PS : "PSACTIVITIES.PS",
    PUBBLICATA : "PSACTIVITIES.PUBBLICATA",
    TUEPROPOSTE : "PSACTIVITIES.TUEPROPOSTE",
    TUOIPROCESSI : "PSACTIVITIES.TUOIPROCESSI",
    TUOIVOTI : "PSACTIVITIES.TUOIVOTI",
    VISUALIZZA : "PSACTIVITIES.VISUALIZZA",
    VOTIESPRESSI : "PSACTIVITIES.VOTIESPRESSI"
  },
  PSEDIT : {
    AS : "PSEDIT.AS",
    CONFERMAPASSWORD : "PSEDIT.CONFERMAPASSWORD",
    DRAGANDDROP : "PSEDIT.DRAGANDDROP",
    ERRORE : "PSEDIT.ERRORE",
    ERROREPASS : "PSEDIT.ERROREPASS",
    FORMSUPPORTATI : "PSEDIT.FORMSUPPORTATI",
    MODAVATAR : "PSEDIT.MODAVATAR",
    MODIFICADATI : "PSEDIT.MODIFICADATI",
    MODOK : "PSEDIT.MODOK",
    MODPASS : "PSEDIT.MODPASS",
    NUOVAPASSWORD : "PSEDIT.NUOVAPASSWORD",
    PRIVACY : "PSEDIT.PRIVACY",
    PROFILOPUBBLICO : "PSEDIT.PROFILOPUBBLICO",
    PS : "PSEDIT.PS",
    SALVA : "PSEDIT.SALVA",
    SUCCESSO : "PSEDIT.SUCCESSO"
  },
  PSEDIT2 : {
    AS : "PSEDIT2.AS",
    ATTEGGIAMENTO : "PSEDIT2.ATTEGGIAMENTO",
    BIOGRAFIA : "PSEDIT2.BIOGRAFIA",
    BUDGET : "PSEDIT2.BUDGET",
    BUDGETDADONARE : "PSEDIT2.BUDGETDADONARE",
    COMPORTAMENTO : "PSEDIT2.COMPORTAMENTO",
    EDU : "PSEDIT2.EDU",
    EDULAV : "PSEDIT2.EDULAV",
    INFOVARIE : "PSEDIT2.INFOVARIE",
    LAV : "PSEDIT2.LAV",
    PS : "PSEDIT2.PS",
    REDDITO : "PSEDIT2.REDDITO",
    SALVA : "PSEDIT2.SALVA",
    VISIONEPOLITICA : "PSEDIT2.VISIONEPOLITICA"
  },
  QUICKPANEL : {
    VOTE: "QUICKPANEL.VOTE",
    VOTEDON: "QUICKPANEL.VOTEDON",
    EDITVOTE: "QUICKPANEL.EDITVOTE",
    DONATION: "QUICKPANEL.DONATION"
  },
  QUICKUSER : {
    ACCOUNT : "QUICKUSER.ACCOUNT",
    AGGIORNAPROFILOEPRIVACY : "QUICKUSER.AGGIORNAPROFILOEPRIVACY",
    IDEEVOTATE : "QUICKUSER.IDEEVOTATE",
    PROCESSIPARTECIPATIVI : "QUICKUSER.PROCESSIPARTECIPATIVI",
    SALUTO : "QUICKUSER.SALUTO",
    SIGNOUT : "QUICKUSER.SIGNOUT",
    VOTI : "QUICKUSER.VOTI"
  },
  REGISTER : {
    ACCETTOLA : "REGISTER.ACCETTOLA",
    ATTENZIONE : "REGISTER.ATTENZIONE",
    BACCEDI : "REGISTER.BACCEDI",
    BACCETTO : "REGISTER.BACCETTO",
    BREGISTRA : "REGISTER.BREGISTRA",
    COGNOMEDIMENTICATO : "REGISTER.COGNOMEDIMENTICATO",
    GIAACCOUNT : "REGISTER.GIAACCOUNT",
    MAILDIMENTICATA : "REGISTER.MAILDIMENTICATA",
    MAILNONVALIDA : "REGISTER.MAILNONVALIDA",
    NEWSLETTER : "REGISTER.NEWSLETTER",
    NOMEDIMENTICATO : "REGISTER.NOMEDIMENTICATO",
    PASSWORDDIMENTICATA : "REGISTER.PASSWORDDIMENTICATA",
    PLACEHOLDERCOGNOME : "REGISTER.PLACEHOLDERCOGNOME",
    PLACEHOLDEREMAIL : "REGISTER.PLACEHOLDEREMAIL",
    PLACEHOLDERNOME : "REGISTER.PLACEHOLDERNOME",
    PLACEHOLDERPASSWORD : "REGISTER.PLACEHOLDERPASSWORD",
    PLACEHOLDERUTENTE : "REGISTER.PLACEHOLDERUTENTE",
    PRIVACY : "REGISTER.PRIVACY",
    TERMINICONDIZIONI : "REGISTER.TERMINICONDIZIONI",
    USERNAMEDIMENTICATO : "REGISTER.USERNAMEDIMENTICATO",
    USERNAMEFORBIDDENCHARACTERS : "REGISTER.USERNAMEFORBIDDENCHARACTERS",
    USERNAMEMINLENGTH : "REGISTER.USERNAMEMINLENGTH"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "REGISTERFROMANONYMOUS.ACCETTO",
    ACCETTOLA : "REGISTERFROMANONYMOUS.ACCETTOLA",
    BCONFERMA : "REGISTERFROMANONYMOUS.BCONFERMA",
    DETTMEX : "REGISTERFROMANONYMOUS.DETTMEX",
    ERR1 : "REGISTERFROMANONYMOUS.ERR1",
    ERR2 : "REGISTERFROMANONYMOUS.ERR2",
    GIAACCOUNT : "REGISTERFROMANONYMOUS.GIAACCOUNT",
    GRAZIE : "REGISTERFROMANONYMOUS.GRAZIE",
    ISCRIVITIPROCESSO : "REGISTERFROMANONYMOUS.ISCRIVITIPROCESSO",
    NEWSLETTER : "REGISTERFROMANONYMOUS.NEWSLETTER",
    PRIVACY : "REGISTERFROMANONYMOUS.PRIVACY",
    REQUIREDFIELD : "REGISTERFROMANONYMOUS.REQUIREDFIELD",
    TERMINI : "REGISTERFROMANONYMOUS.TERMINI",
    TITLE2 : "REGISTERFROMANONYMOUS.TITLE2",
    TITLE3 : "REGISTERFROMANONYMOUS.TITLE3",
    VALIDEMAIL : "REGISTERFROMANONYMOUS.VALIDEMAIL"
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "REGISTERONPUBLIC.ACCETTOLA",
    BACCEDI : "REGISTERONPUBLIC.BACCEDI",
    BACCETTO : "REGISTERONPUBLIC.BACCETTO",
    BCHIUDI : "REGISTERONPUBLIC.BCHIUDI",
    BREGISTRA : "REGISTERONPUBLIC.BREGISTRA",
    COGNOMEDIMENTICATO : "REGISTERONPUBLIC.COGNOMEDIMENTICATO",
    ERR1 : "REGISTERONPUBLIC.ERR1",
    FAIGIAPARTE : "REGISTERONPUBLIC.FAIGIAPARTE",
    ISCRIVITIPROCESSO : "REGISTERONPUBLIC.ISCRIVITIPROCESSO",
    MAILNONVALIDA : "REGISTERONPUBLIC.MAILNONVALIDA",
    NEWSLETTER : "REGISTERONPUBLIC.NEWSLETTER",
    NOMEDIMENTICATO : "REGISTERONPUBLIC.NOMEDIMENTICATO",
    PASSWORDDIMENTICATA : "REGISTERONPUBLIC.PASSWORDDIMENTICATA",
    PRIVACY : "REGISTERONPUBLIC.PRIVACY",
    TERMINICONDIZIONI : "REGISTERONPUBLIC.TERMINICONDIZIONI",
    TITLE1 : "REGISTERONPUBLIC.TITLE1",
    USERNAMEDIMENTICATO : "REGISTERONPUBLIC.USERNAMEDIMENTICATO"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "REGISTERPRIVATEPROCESS.ACCESSORIUSCITO",
    ACCETTO : "REGISTERPRIVATEPROCESS.ACCETTO",
    ACCETTOLA : "REGISTERPRIVATEPROCESS.ACCETTOLA",
    AUTORIZZATO : "REGISTERPRIVATEPROCESS.AUTORIZZATO",
    BCONFERMA : "REGISTERPRIVATEPROCESS.BCONFERMA",
    DETTMEX : "REGISTERPRIVATEPROCESS.DETTMEX",
    ERR1 : "REGISTERPRIVATEPROCESS.ERR1",
    ERR3 : "REGISTERPRIVATEPROCESS.ERR3",
    ERR4 : "REGISTERPRIVATEPROCESS.ERR4",
    ERR5 : "REGISTERPRIVATEPROCESS.ERR5",
    ES : "REGISTERPRIVATEPROCESS.ES",
    GIAACCOUNT : "REGISTERPRIVATEPROCESS.GIAACCOUNT",
    GRAZIE : "REGISTERPRIVATEPROCESS.GRAZIE",
    HTMLMAIL : "REGISTERPRIVATEPROCESS.HTMLMAIL",
    ISCRIVITIPROCESSO : "REGISTERPRIVATEPROCESS.ISCRIVITIPROCESSO",
    NEWSLETTER : "REGISTERPRIVATEPROCESS.NEWSLETTER",
    NIENTEACCOUNT : "REGISTERPRIVATEPROCESS.NIENTEACCOUNT",
    NOME : "REGISTERPRIVATEPROCESS.NOME",
    ORGANIZATION : "REGISTERPRIVATEPROCESS.ORGANIZATION",
    PARTECIPAPROCESSO : "REGISTERPRIVATEPROCESS.PARTECIPAPROCESSO",
    PRIVACY : "REGISTERPRIVATEPROCESS.PRIVACY",
    PROPOSTE : "REGISTERPRIVATEPROCESS.PROPOSTE",
    QUIMAIL : "REGISTERPRIVATEPROCESS.QUIMAIL",
    REGISTRATI : "REGISTERPRIVATEPROCESS.REGISTRATI",
    TERMINI : "REGISTERPRIVATEPROCESS.TERMINI",
    TITLE1 : "REGISTERPRIVATEPROCESS.TITLE1",
    TITLE2 : "REGISTERPRIVATEPROCESS.TITLE2",
    TITLE3 : "REGISTERPRIVATEPROCESS.TITLE3",
    TITOLO : "REGISTERPRIVATEPROCESS.TITOLO"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "REGISTERPROCESSFROMANONYMOUS.ACCEDI",
    ACCETTO : "REGISTERPROCESSFROMANONYMOUS.ACCETTO",
    ACCETTOLA : "REGISTERPROCESSFROMANONYMOUS.ACCETTOLA",
    BPROSEGUI : "REGISTERPROCESSFROMANONYMOUS.BPROSEGUI",
    CAMBIAPASS : "REGISTERPROCESSFROMANONYMOUS.CAMBIAPASS",
    ERR3 : "REGISTERPROCESSFROMANONYMOUS.ERR3",
    ERR4 : "REGISTERPROCESSFROMANONYMOUS.ERR4",
    ERR5 : "REGISTERPROCESSFROMANONYMOUS.ERR5",
    INSERISCIMAIL : "REGISTERPROCESSFROMANONYMOUS.INSERISCIMAIL",
    ISCRIVITIPROCESSO : "REGISTERPROCESSFROMANONYMOUS.ISCRIVITIPROCESSO",
    MEX1 : "REGISTERPROCESSFROMANONYMOUS.MEX1",
    MEX2 : "REGISTERPROCESSFROMANONYMOUS.MEX2",
    MEXLOGIN : "REGISTERPROCESSFROMANONYMOUS.MEXLOGIN",
    NIENTEACCOUNT : "REGISTERPROCESSFROMANONYMOUS.NIENTEACCOUNT",
    NONRICORDIPASS : "REGISTERPROCESSFROMANONYMOUS.NONRICORDIPASS",
    NOTIFICHE : "REGISTERPROCESSFROMANONYMOUS.NOTIFICHE",
    PRIVACY : "REGISTERPROCESSFROMANONYMOUS.PRIVACY",
    REGISTRATI : "REGISTERPROCESSFROMANONYMOUS.REGISTRATI",
    TERMINI : "REGISTERPROCESSFROMANONYMOUS.TERMINI",
    TERMINICONDIZIONI : "REGISTERPROCESSFROMANONYMOUS.TERMINICONDIZIONI",
    TITLE1 : "REGISTERPROCESSFROMANONYMOUS.TITLE1",
    TITLE2 : "REGISTERPROCESSFROMANONYMOUS.TITLE2",
    TITLE3 : "REGISTERPROCESSFROMANONYMOUS.TITLE3",
    UTENTEAUTENTICATO : "REGISTERPROCESSFROMANONYMOUS.UTENTEAUTENTICATO"
  },
  SERVICEDETAIL : {
    ALERT1 : "SERVICEDETAIL.ALERT1",
    ALERT13 : "SERVICEDETAIL.ALERT13",
    ALERT14 : "SERVICEDETAIL.ALERT14",
    ALERT3TITLE : "SERVICEDETAIL.ALERT3TITLE",
    ALERT4 : "SERVICEDETAIL.ALERT4",
    ALERT5 : "SERVICEDETAIL.ALERT5",
    ALERT5TITLE : "SERVICEDETAIL.ALERT5TITLE",
    ALERT6TITLE : "SERVICEDETAIL.ALERT6TITLE",
    ALERT7TITLE : "SERVICEDETAIL.ALERT7TITLE",
    ALERT8TITLE : "SERVICEDETAIL.ALERT8TITLE",
    ALERT9 : "SERVICEDETAIL.ALERT9",
    ALERT9TITLE : "SERVICEDETAIL.ALERT9TITLE",
    ALERTBOZZA : "SERVICEDETAIL.ALERTBOZZA",
    ALERTPUBBLICATA : "SERVICEDETAIL.ALERTPUBBLICATA",
    AUTORE : "SERVICEDETAIL.AUTORE",
    CANVERSION2 : "SERVICEDETAIL.CANVERSION2",
    CLICCAILPULSANTE : "SERVICEDETAIL.CLICCAILPULSANTE",
    EMAILAUTORENONDISPONIBILE : "SERVICEDETAIL.EMAILAUTORENONDISPONIBILE",
    EMAILUTENTENONDISPONIBILE : "SERVICEDETAIL.EMAILUTENTENONDISPONIBILE",
    GRAZIESCELTAIDEA : "SERVICEDETAIL.GRAZIESCELTAIDEA",
    GRAZIESOSTEGNO : "SERVICEDETAIL.GRAZIESOSTEGNO",
    GRAZIESOSTEGNOANCORA : "SERVICEDETAIL.GRAZIESOSTEGNOANCORA",
    IDEAVOTATA : "SERVICEDETAIL.IDEAVOTATA",
    INSERISCICIFRA : "SERVICEDETAIL.INSERISCICIFRA",
    NARRAZIONE : "SERVICEDETAIL.NARRAZIONE",
    PREFERENZEPERPREMIARE : "SERVICEDETAIL.PREFERENZEPERPREMIARE",
    SOSTIENIALTREIDEE : "SERVICEDETAIL.SOSTIENIALTREIDEE",
    TOGLIEREPREFERENZA : "SERVICEDETAIL.TOGLIEREPREFERENZA",
    URLSITO : "SERVICEDETAIL.URLSITO",
    VALUTARIDUZIONEDONAZIONI : "SERVICEDETAIL.VALUTARIDUZIONEDONAZIONI",
    VERSIONIPROPOSTA : "SERVICEDETAIL.VERSIONIPROPOSTA",
    WALLETSOSTIENIIDEA : "SERVICEDETAIL.WALLETSOSTIENIIDEA"
  },
  SERVICELIST : {
    ALERTBOZZA : "SERVICELIST.ALERTBOZZA",
    ALERTVERSIONING2 : "SERVICELIST.ALERTVERSIONING2",
    CREAIDEA : "SERVICELIST.CREAIDEA",
    ELENCOIDEE : "SERVICELIST.ELENCOIDEE",
    IDEAVOTATA : "SERVICELIST.IDEAVOTATA",
    IDEE : "SERVICELIST.IDEE",
    NONCISONOIDEE : "SERVICELIST.NONCISONOIDEE",
    VOTAZIONEATTIVA2 : "SERVICELIST.VOTAZIONEATTIVA2",
    VOTAZIONEATTIVAUTENTEANONIMO : "SERVICELIST.VOTAZIONEATTIVAUTENTEANONIMO"
  },
  STORYDETAIL : {
    ALERT1 : "STORYDETAIL.ALERT1",
    ALERT13 : "STORYDETAIL.ALERT13",
    ALERT14 : "STORYDETAIL.ALERT14",
    ALERT3TITLE : "STORYDETAIL.ALERT3TITLE",
    ALERT4 : "STORYDETAIL.ALERT4",
    ALERT5 : "STORYDETAIL.ALERT5",
    ALERT5TITLE : "STORYDETAIL.ALERT5TITLE",
    ALERT6TITLE : "STORYDETAIL.ALERT6TITLE",
    ALERT7TITLE : "STORYDETAIL.ALERT7TITLE",
    ALERT8TITLE : "STORYDETAIL.ALERT8TITLE",
    ALERT9 : "STORYDETAIL.ALERT9",
    ALERT9TITLE : "STORYDETAIL.ALERT9TITLE",
    ALERTBOZZA : "STORYDETAIL.ALERTBOZZA",
    ALERTPUBBLICATA : "STORYDETAIL.ALERTPUBBLICATA",
    AUTORE : "STORYDETAIL.AUTORE",
    CANVERSION2 : "STORYDETAIL.CANVERSION2",
    CLICCAILPULSANTE : "STORYDETAIL.CLICCAILPULSANTE",
    EMAILAUTORENONDISPONIBILE : "STORYDETAIL.EMAILAUTORENONDISPONIBILE",
    EMAILUTENTENONDISPONIBILE : "STORYDETAIL.EMAILUTENTENONDISPONIBILE",
    GRAZIESCELTAIDEA : "STORYDETAIL.GRAZIESCELTAIDEA",
    GRAZIESOSTEGNO : "STORYDETAIL.GRAZIESOSTEGNO",
    GRAZIESOSTEGNOANCORA : "STORYDETAIL.GRAZIESOSTEGNOANCORA",
    IDEAVOTATA : "STORYDETAIL.IDEAVOTATA",
    INSERISCICIFRA : "STORYDETAIL.INSERISCICIFRA",
    NARRAZIONE : "STORYDETAIL.NARRAZIONE",
    PREFERENZEPERPREMIARE : "STORYDETAIL.PREFERENZEPERPREMIARE",
    SOSTIENIALTREIDEE : "STORYDETAIL.SOSTIENIALTREIDEE",
    TOGLIEREPREFERENZA : "STORYDETAIL.TOGLIEREPREFERENZA",
    URLSITO : "STORYDETAIL.URLSITO",
    VALUTARIDUZIONEDONAZIONI : "STORYDETAIL.VALUTARIDUZIONEDONAZIONI",
    VERSIONIPROPOSTA : "STORYDETAIL.VERSIONIPROPOSTA",
    WALLETSOSTIENIIDEA : "STORYDETAIL.WALLETSOSTIENIIDEA"
  },
  STORYLIST : {
    ALERTBOZZA : "STORYLIST.ALERTBOZZA",
    ALERTVERSIONING2 : "STORYLIST.ALERTVERSIONING2",
    CREAIDEA : "STORYLIST.CREAIDEA",
    ELENCOIDEE : "STORYLIST.ELENCOIDEE",
    IDEAVOTATA : "STORYLIST.IDEAVOTATA",
    IDEE : "STORYLIST.IDEE",
    NONCISONOIDEE : "STORYLIST.NONCISONOIDEE",
    VOTAZIONEATTIVA2 : "STORYLIST.VOTAZIONEATTIVA2",
    VOTAZIONEATTIVAUTENTEANONIMO : "STORYLIST.VOTAZIONEATTIVAUTENTEANONIMO"
  },
  TACTICDETAIL : {
    ALERT1 : "TACTICDETAIL.ALERT1",
    ALERT13 : "TACTICDETAIL.ALERT13",
    ALERT14 : "TACTICDETAIL.ALERT14",
    ALERT3TITLE : "TACTICDETAIL.ALERT3TITLE",
    ALERT4 : "TACTICDETAIL.ALERT4",
    ALERT5 : "TACTICDETAIL.ALERT5",
    ALERT5TITLE : "TACTICDETAIL.ALERT5TITLE",
    ALERT6TITLE : "TACTICDETAIL.ALERT6TITLE",
    ALERT7TITLE : "TACTICDETAIL.ALERT7TITLE",
    ALERT8TITLE : "TACTICDETAIL.ALERT8TITLE",
    ALERT9 : "TACTICDETAIL.ALERT9",
    ALERT9TITLE : "TACTICDETAIL.ALERT9TITLE",
    ALERTBOZZA : "TACTICDETAIL.ALERTBOZZA",
    ALERTPUBBLICATA : "TACTICDETAIL.ALERTPUBBLICATA",
    AUTORE : "TACTICDETAIL.AUTORE",
    CANVERSION2 : "TACTICDETAIL.CANVERSION2",
    CLICCAILPULSANTE : "TACTICDETAIL.CLICCAILPULSANTE",
    EMAILAUTORENONDISPONIBILE : "TACTICDETAIL.EMAILAUTORENONDISPONIBILE",
    EMAILUTENTENONDISPONIBILE : "TACTICDETAIL.EMAILUTENTENONDISPONIBILE",
    GRAZIESCELTAIDEA : "TACTICDETAIL.GRAZIESCELTAIDEA",
    GRAZIESOSTEGNO : "TACTICDETAIL.GRAZIESOSTEGNO",
    GRAZIESOSTEGNOANCORA : "TACTICDETAIL.GRAZIESOSTEGNOANCORA",
    IDEAVOTATA : "TACTICDETAIL.IDEAVOTATA",
    INSERISCICIFRA : "TACTICDETAIL.INSERISCICIFRA",
    NARRAZIONE : "TACTICDETAIL.NARRAZIONE",
    PREFERENZEPERPREMIARE : "TACTICDETAIL.PREFERENZEPERPREMIARE",
    SOSTIENIALTREIDEE : "TACTICDETAIL.SOSTIENIALTREIDEE",
    TOGLIEREPREFERENZA : "TACTICDETAIL.TOGLIEREPREFERENZA",
    URLSITO : "TACTICDETAIL.URLSITO",
    VALUTARIDUZIONEDONAZIONI : "TACTICDETAIL.VALUTARIDUZIONEDONAZIONI",
    VERSIONIPROPOSTA : "TACTICDETAIL.VERSIONIPROPOSTA",
    WALLETSOSTIENIIDEA : "TACTICDETAIL.WALLETSOSTIENIIDEA"
  },
  TACTICLIST : {
    ALERTBOZZA : "TACTICLIST.ALERTBOZZA",
    ALERTVERSIONING2 : "TACTICLIST.ALERTVERSIONING2",
    CREAIDEA : "TACTICLIST.CREAIDEA",
    ELENCOIDEE : "TACTICLIST.ELENCOIDEE",
    IDEAVOTATA : "TACTICLIST.IDEAVOTATA",
    IDEE : "TACTICLIST.IDEE",
    NONCISONOIDEE : "TACTICLIST.NONCISONOIDEE",
    VOTAZIONEATTIVA2 : "TACTICLIST.VOTAZIONEATTIVA2",
    VOTAZIONEATTIVAUTENTEANONIMO : "TACTICLIST.VOTAZIONEATTIVAUTENTEANONIMO"
  },
  TERMS : {
    ATTIVITA : "TERMS.ATTIVITA",
    ATTIVITADESCR1 : "TERMS.ATTIVITADESCR1",
    COMUNICAZIONI : "TERMS.COMUNICAZIONI",
    COMUNICAZIONIDESCR1 : "TERMS.COMUNICAZIONIDESCR1",
    LIMITAZIONI : "TERMS.LIMITAZIONI",
    LIMITAZIONIDESCR1 : "TERMS.LIMITAZIONIDESCR1",
    MODIFICA : "TERMS.MODIFICA",
    MODIFICADESCR1 : "TERMS.MODIFICADESCR1",
    OGGETTO : "TERMS.OGGETTO",
    OGGETTODESCR1 : "TERMS.OGGETTODESCR1",
    REGISTRAZIONE : "TERMS.REGISTRAZIONE",
    REGISTRAZIONEDESCR1 : "TERMS.REGISTRAZIONEDESCR1",
    RESPONSABILITA : "TERMS.RESPONSABILITA",
    RESPONSABILITADESCR1 : "TERMS.RESPONSABILITADESCR1",
    RESPONSABILITADESCR2 : "TERMS.RESPONSABILITADESCR2",
    SOTTOTITOLO : "TERMS.SOTTOTITOLO",
    TITOLO : "TERMS.TITOLO",
    UTILIZZO : "TERMS.UTILIZZO",
    UTILIZZODESCR1 : "TERMS.UTILIZZODESCR1",
    UTILIZZODESCR2 : "TERMS.UTILIZZODESCR2",
    UTILIZZODESCR3 : "TERMS.UTILIZZODESCR3"
  },
  TRANSLATOR : {
    SELECT : "TRANSLATOR.SELECT"
  },
  USERDETAIL : {
    BIOGRAFIA : "USERDETAIL.BIOGRAFIA",
    COMMUNITY : "USERDETAIL.COMMUNITY",
    EDUCAZIONE : "USERDETAIL.EDUCAZIONE",
    EDULAVORO : "USERDETAIL.EDULAVORO",
    ETA : "USERDETAIL.ETA",
    F : "USERDETAIL.F",
    LAVORO : "USERDETAIL.LAVORO",
    M : "USERDETAIL.M",
    PROPOSTEEFFETTUATE : "USERDETAIL.PROPOSTEEFFETTUATE",
    UTENTE : "USERDETAIL.UTENTE"
  }
};
