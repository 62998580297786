// DE
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "EINGEBEN",
    ACCEDICOMM2 : "Klicken Sie hier und melden Sie sich mit Ihrem Konto an",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    REGISTRATICOMM1 : "Ich möchte Teil der Gemeinschaft werden",
    REGISTRATICOMM2 : "Klicken Sie hier, wenn Sie sich auf BiPart mit Ihrer E-Mail registrieren und ein Konto erstellen möchten",
    SOLOVOTO : "Ich möchte nur abstimmen",
    SOLOVOTO2 : "Klicken Sie hier, wenn Sie sich nur mit Ihrem Mobiltelefon bei BiPart anmelden möchten"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "Attenzione",
    INDIETRO : "Zurück",
    INSERISCINUOVONUMERO : "Inserisci<br /> nuovo numero",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    PH1 : "Mobil",
    PHONEERROR1 : "Sie haben vergessen, die Vorwahl anzugeben.",
    PHONEERROR2 : "Haben Sie vergessen, Ihre Mobilfunknummer einzugeben?",
    PHONEERROR3 : "Die von Ihnen eingegebene Zahl ist ungewöhnlich... überprüfen Sie sie bitte noch einmal! :)",
    PRIVACY : "Um fortzufahren lesen Sie bitte die <a href=\"https://bipart.it/terms\" target=\"blank\">Geschäftsbedingungen </a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    PROCEDI : "Registrierung",
    RECUPERAPASSWORD : "Recupera<br/> password",
    TERMINIECONDIZIONI : "Ich habe die <a href=\"https://bipart.it/terms\" target=\"_blank\">Geschäftsbedingungen</a> gelesen und stimme ihnen zu.",
    VERIFICA1 : "Prüfen Sie Ihre Angaben zur Teilnahme",
    VERIFICA2 : "Geben Sie Ihre Mobilfunknummer an, um den OPT-Code per SMS zu erhalten.",
    EXIT: "Logout",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "<h4>Wir haben einen SMS-Verifizierungscode an Ihre Mobilfunknummer gesendet.</h4>",
    ALERT1TITLE : "Infos",
    ERROROTPMAXCHAR : "Numero massimo di cifre inseribili 6",
    INDIETRO : "Zurück",
    OTPDIMENTICATO : "Sie haben vergessen, den otp-Code einzugeben",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    PH1 : "OTP-Code*",
    PROCEDI : "Weiter",
    RICHIEDINUOVOCODICE1 : " <b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti ",
    RICHIEDINUOVOCODICE2 : " richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : " o scrivici a ",
    VERIFICA1 : "Prüfen Sie Ihre Angaben zur Teilnahme",
    VERIFICA2 : "Wir haben den OTP-Verifizierungscode an Ihre Mobiltelefonnummer gesendet. Bitte geben Sie ihn ein, um fortzufahren"
  },
  ADVAUTHROLE : {
    CITTADINO : "Bürger/in",
    INDIETRO : "Zurück",
    INFOREGISTER : "Zusätzlich zu Ihrem <b>Mobiltelefon</b>wo Sie Ihr Passwort für die Anmeldung erhalten, bitten wir Sie um die Eingabe Ihrer  <b>Steuernummer</b>.",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    PROCEDI : "Weiter",
    VERIFICA1 : "Prüfen Sie Ihre Angaben zur Teilnahme",
    VERIFICA2 : "Wählen Sie, wie Sie sich authentifizieren"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "Ups... etwas ist schief gelaufen; Stauts Code:",
    ALERT1TITLE : "Error",
    CFDIVERSO : "Haben Sie einen andere Steuernummer eingegeben als die, die bereits mit Ihrem Konto verknüpft ist?",
    CHIUDI : "Schließen",
    DIMENTICATOCF : "Sie haben vergessen, Ihre Steuernummer einzugeben",
    ERRORECF : "Die Steuernummer ist nicht korrekt eingegeben.",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    PH1 : "Steuernummer",
    PROCEDI : "Weiter",
    VERIFICA1 : "Prüfen Sie Ihre Angaben zur Teilnahme",
    VERIFICA2 : "<b>Geben Sie Ihre Steuernummer an, um fortzufahren</b><br>Wir stellen sicher, dass nur Mitglieder der Gemeinschaft teilnehmen!"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "Ihre Steuernummer darf nicht an dem Verfahren teilnehmen",
    CFNONABILIATO1 : "Prüfen Sie die Leitlinien und schreiben Sie an support@bipart.it, wenn Sie der Meinung sind, dass es sich um einen Fehler handelt.",
    CHIUDI : "Schließen",
    UTENTENONAUTORIZZATO : "  Unbefugter Benutzer"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "Vielen Dank, dass Sie sich bei BiPart registriert haben.",
    CHIUDI : "Schließen",
    COMPLIMENTI : "Herzlichen Glückwunsch",
    UTENTEABILITATO : "Behalten Sie die Zugangsdaten, die Sie per SMS auf Ihr Mobiltelefon erhalten haben, um erneut auf BiPart zuzugreifen!",
    UTENTEINFO : "Von nun an können Sie mit einem verifizierten Konto teilnehmen, um die fortschrittlichsten und wichtigsten Funktionen zu nutzen!"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "Achtung",
    ALERTINFO : "Die von Ihnen eingegebenen Anmeldedaten sind falsch. Bitte überprüfen Sie dies oder schreiben Sie uns an <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    PARTECIPA : "Beteiligen Sie sich an dem Prozess",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    PASSWORDERRATA : "Falsches Passwort",
    PH1 : "Nutzername*",
    PH2 : "Passwort*",
    SUPPORT : "<i class=\"fas fa-info-circle\"></i> Wenn Sie Ihre Anmeldedaten vergessen haben, schreiben Sie bitte an <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    USERNONESISTE : "Der Nutzername existiert nicht"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "Falsche Berechtigungsnachweise",
    ALERT1TITLE : "Achtung",
    CAMPIOBBLIGATORI : "* Markierte Felder sind Pflichtfelder",
    COGNOMEDIMENTICATO : "Sie haben vergessen, Ihren Nachnamen einzugeben",
    INDIETRO : "ZURÜCK",
    MAILDIMENTICATA : "Sie haben vergessen, Ihre E-Mail-Adresse einzugeben",
    MAILNONVALIDA : "Ungültige E-Mail Adresse",
    NEWSLETTER : "Halten Sie mich über die Initiativen von BiPart auf dem Laufenden",
    NOMEDIMENTICATO : "Sie haben vergessen, Ihren Namen einzugeben",
    PARTECIPA : "Beteiligen Sie sich an dem Prozess",
    PARTECIPAPROCESSO : "Sie befinden sich in dem Arbeitsbereich der folgenden Organisation:",
    PASSWORDDIMENTICATA : "Sie haben vergessen, Ihr Passwort einzugeben",
    PH1 : "Name*",
    PH2 : "Nachname*",
    PH3 : "Benutzername*",
    PH4 : "E-Mail*",
    PH5 : "Kennwort*",
    PRIVACY : "Ich habe die <a href=\"https://bipart.it/privacy\" target=\"_blank\">Datenschutzbestimmungen</a> gelesen und stimme ihnen zu.",
    REGISTRA : "ISCRIVITI",
    TERMINIECONDIZIONI : "Ich habe die <a href=\"https://bipart.it/terms\" target=\"_blank\">Geschäftsbedingungen</a> gelesen und stimme ihnen zu.",
    USERNAMEDIMENTICATO : "Sie haben vergessen, Ihren Benutzernamen einzugeben"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "<h4>Dem Vorschlag kann nicht gefolgt werden</h4>",
    ALERTCOMMENTEMPTY : "<h4>Kommentartext einfügen</h4>",
    ALERTCOMMENTSENDED : "<h4>Danke für Ihren Kommentar und Ihren Beitrag zu diesem Vorschlag!</h4>",
    ALERTEMAILBLANK : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't your email address yet</h6><div>",
    ALERTEMAILCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Sie haben Ihre E-Mail Adresse noch nicht bestätigt.</h6><div> Bitte bestätigen, um der Gemeinschaft beizutreten und sich für partizipative Prozesse anzumelden</div><div>Sie haben die E-Mail nicht erhalten? <br><strong> Klicken Sie auf den roten Knopf, um es erneut zu senden. </strong></div>.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "Erstellen und veröffentlichen Sie Ihr Profil, um aktiv an den Prozessen in Ihrer Gemeinschaft teilzunehmen.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "Willkommen bei BiPart!",
    ALERTEMAILMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the community and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    ALERTEMAILRESENDCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\"> Bestätigungsanfrage per E-Mail an Ihren Posteingang </h5><div> Bestätigen Sie, dass Sie der Organisation beitreten<br>und sich an den partizipativen Prozessen beteiligen </div>.",
    ALERTERROR : "Ups... da ist etwas schief gelaufen!",
    ALERTFORMDESCRIPTION : "<h4>Warte auf Aktivierung</h4>.",
    ALERTFORMTITLE : "<b>Fragebogen noch nicht verfügbar</b>",
    ALERTHELPDESCRIPTION : "<h4>Wenn Sie Hilfe benötigen, kontaktieren Sie uns bitte unter <a href=\"mailto:support@bipart.it\">support@bipart.it</a></h4>.",
    ALERTHELPTITLE : "<b>Helpdesk</b>",
    ALERTJAMBOARDDESCRIPTION : "<h4>Warte auf Aktivierung</h4>.",
    ALERTJAMBOARDTITLE : "<b>Jamboard noch nicht verfügbar</b>",
    ALERTMEETDESCRIPTION : "<h4>Warte auf Aktivierung</h4>.",
    ALERTMEETTITLE : "<b>Webinar noch nicht verfügbar</b>",
    ALERTMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the community and sign up for participatory processes</div><div>You didn't receive the message? <br><strong>Click on the button in the red band to resend it</strong></div>",
    ALERTPERMISSIONDENIED : "<h4>Erlaubnis verweigert</h4>",
    ALERTUSERMODULENOTENABLED : "<h4>Hallo! Möglicherweise haben Sie nicht die erforderlichen Berechtigungen, um teilzunehmen. Lesen Sie die Regeln oder schreiben Sie an <a href=\"mailto:support@bipart.it\">support@bipart.it</a> oder an andere verfügbare Kontakte.</h4>.",
    ALERTUSERMODULENOTENABLEDBITMASK : "click <a href=\"/process-guidelines/{process_id}\" target=\"_blank\">here</a> to find out how you can participate, or ",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "<h4>Ops,<br>apparently you don't have permission to leave a comment;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "<h4>Ops,<br>apparently you don't have permission to make {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "<h4>Ops,<br>apparently you don't have permission to delete {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "<h4>Ops,<br>apparently do not follow this proposal;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "<h4>Ops,<br>apparently you don't have permission to send a message;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "<h4>Ops,<br>apparently you don't have permission to edit {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "<h4>Ops,<br>apparently you don't have permission to version {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERREGISTEREDTOORGANIZATION : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Danke, dass Sie sich unserer Gemeinschaft angeschlossen haben </h5><div> Wir haben eine E-Mail an Ihren Posteingang geschickt.<br>Bestätigen Sie diese, um der Organisation beizutreten </div><div> Warnung! Überprüfen Sie auch Ihren Spam-Ordner.",
    ALERTUSERREGISTEREDTOPROCESS : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\"> Danke, dass Sie sich unserer Gemeinschaft angeschlossen haben </h5><div> Wir haben eine E-Mail an Ihren Posteingang geschickt </h5><div>Bestätigen Sie es, um der Organisation beizutreten </div><div> Warnung! Überprüfen Sie auch Ihren Spam-Ordner.</div>.",
    PROPOSAL : "a proposal",
    SERVICE : "a service",
    STORY : "a story",
    TACTICS : "a tactic"
  },
  AUTHORITIES : {
    ASSESSOR : "Assessor",
    EXPERT : "Expert"
  },
  BCPROCESSI : {
    CHALLENGE : "Themen",
    COMMUNITY : "Gemeinschaft",
    ELENCOPROCESSI : "Prozess-Liste",
    PROCESSO : "Prozess",
    SCENARI : "Kontext"
  },
  COMMUNITY : {
    CERCA : "Suche...",
    CHIPARTECIPA : "Teilnehmer",
    GRUPPI : "Gruppen",
    ISCRITTI : "Abonnenten",
    PROFILI : "Profile",
    SOTTOTITOLO : "Hier sind die veröffentlichten Profile: Machen Sie mit!",
    TITOLO : "Gemeinschaft"
  },
  COMMUNITYSPACE : {
    BOZZA : "Entwurf",
    CS : "Gemeinschaftsraum",
    DESCRIZIONEIDEA : "Beschreibung des Vorschlags",
    IDEE : "20 Vorschläge",
    NOMEAUTORE : "Name des Autors",
    TITOLOIDEA : "Titel des Vorschlags",
    VISUALIZZAELENCO : "Liste ansehen",
    VISUALIZZAMAPPA : "Karte ansehen"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "Möchten Sie die Sprachübersetzung widerrufen:",
    ALERT1TITLE : "Achtung",
    ALERT2 : "Sie möchten die Fertigstellung der Sprachübersetzung bestätigen:",
    CANCEL : "CANCEL"
  },
  COOKIE : {
    ANONIMI : "3. Cookie di analisi anonimi:",
    ANONIMIDESCR1 : "Questo sito utilizza cookie di Google Analytics con IP anonimizzato della durata di un anno. Sono definiti anonimi in quanto non possono essere utilizzati per identificare individui specifici. Più in particolare il sito nowhow.it utilizza GoogleAnalytics (Google, Inc.) che genera cookie secondo le modalità illustrate dal documento relativo alle Norme generiche sulla privacy e dal documento specifico in merito all’utilizzo delle informazioni raccolte con Google Analytics. Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da Google stessa. I termini di servizio ai quali tutti i clienti del programma devono aderire, vietano il monitoraggio o la raccolta di informazioni personali (dati di identificazione personale, come ad esempio nome, indirizzo email, dati di fatturazione o altri dati che possono essere ragionevolmente collegati a tali informazioni) mediante l’uso di Google Analytics o l’associazione di queste alle informazioni sull’analisi dei dati web. Questi cookie perseguono esclusivamente scopi statistici e raccolgono informazioni in sola forma aggregata.",
    COLLEGAMENTI : "6. Collegamenti utili",
    COLLEGAMENTIDESCR1 : "Per maggiori informazioni sui cookie ed il loro utilizzo su internet, potrete trovare utili i seguenti collegamenti:<br><br>www.allaboutcookies.org<br>www.microsoft.com/info/cookies.mspx<br>www.google.com/intl/it_it/analytics/privacyoverview.html",
    CONTENUTIESTERNI : "5. Visualizzazione di contenuti da piattaforme esterne",
    CONTENUTIESTERNIDESCR1 : "Questi servizi permettono di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi. Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato. Per maggiori informazioni sulle logiche e le modalità di trattamento dei dati raccolti dai social network, puoi leggere le note informative sulla privacy fornite dai soggetti che forniscono I servizi in questione:<br><br>Widget Video Youtube (Google Inc.) - Terms - Privacy<br>Facebook - Termini e condizioni - Privacy<br>Twitter - Termini e condizioni - Privacy<br>Vimeo - Termini e condizioni - Privacy<br>Slideshare - Termini e condizioni - Privacy<br>Linkedin - Termini e condizioni - Privacy<br>Instagram - Termini e condizioni - Privacy<br><br>Ricordati che puoi gestire le tue preferenze sui cookie anche attraverso il browser Se non conosci il tipo e la versione di browser che stai utilizzando, clicca su “Aiuto” nella finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.<br><br>Se invece conosci il tuo browser clicca su quello che stai utilizzando per accedere alla pagina di gestione dei cookie. Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Windows Explorer.",
    COSASONO : "1. Cosa sono i cookie",
    COSASONODESCR1 : "I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, dove vengono memorizzati per essere ritrasmessi agli stessi siti in occasione di visite successive. I cookie sono utilizzati per diverse finalità, hanno caratteristiche diverse, e possono essere utilizzati sia dal titolare del sito che si sta visitando, sia da terze parti. Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diversa da quella adottata da nowhow.it e che quindi non risponde di questi siti. Di seguito troverai tutte le informazioni sui cookie installati attraverso questo sito, e le indicazioni necessarie su come gestire le tue preferenze riguardo ad essi.",
    DATIRACCOLTI : "O4. Dati personali raccolti: Cookie e Dati di utilizzo.O",
    DATIRACCOLTIDESCR1 : "Tutti i cookie tecnici non richiedono consenso, perciò vengono installati automaticamente a seguito dell’accesso al sito.",
    SOTTOTITOLO : "Gestione dei cookie",
    TITOLO : "Cookie policy",
    UTILIZZATI : "2. Cookie utilizzati da questo sito",
    UTILIZZATIDESCR1 : "Cookie relativi ad attività strettamente necessarie al funzionamento del sito e all’erogazione del servizio. Questo sito utilizza il cookie di sessione PHPSESSIONID generato dalle applicazioni basate sul linguaggio PHP. Questo identificatore viene utilizzato per mantenere le variabili di sessione utente."
  },
  DRIVERS : {
    ACTIVEPROCESSES : "In Bearbeitung",
    CHALLENGE : "Themen",
    CLOSEPROCESSES : "Abgeschlossen",
    CLUSTER : "Cluster",
    DESCRIZIONE : "Beschreibung",
    ELENCOCHALLENGE : "Themen",
    LINK : "Link",
    PROCESSES : "Vorgänge",
    TUTTITEMI : "Alle Teilnahme-Themen in Cluster unterteilt"
  },
  EMAILCONFIRM : {
    AUTOK : "Erfolgreiche Authentifizierung",
    AUTOKDETAIL : "Jetzt können Sie sich anmelden und an unserer Community teilnehmen",
    BLOGIN : "Anmeldung",
    TITOLO : "BiPart"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "Sie wollen aktiv dazu beitragen, das Leben in Ihrer Gemeinde zu verbessern. Sie können dies tun, indem Sie eine eigene Idee vorschlagen oder einfach für die Idee stimmen, die Sie am interessantesten finden. Die vorgeschlagenen Ideen müssen konkret sein",
    DETTAGLIOGLOSSARIO : "\n",
    FAQ : "FAQ",
    FAQ1 : "",
    FAQ10 : "",
    FAQ11 : "",
    FAQ12 : "",
    FAQ13 : "",
    FAQ14 : "",
    FAQ15 : "",
    FAQ16 : "",
    FAQ17 : "",
    FAQ18 : "",
    FAQ19 : "",
    FAQ2 : "",
    FAQ20 : "",
    FAQ21 : "",
    FAQ22 : "",
    FAQ23 : "",
    FAQ24 : "",
    FAQ25 : "",
    FAQ3 : "",
    FAQ4 : "",
    FAQ5 : "",
    FAQ6 : "",
    FAQ7 : "",
    FAQ8 : "",
    FAQ9 : "",
    GLOSSARIO : "Glossar",
    PERCHEPARTECIPARE : "Warum sollten Sie sich an einem Bürgerhaushalt beteiligen?",
    QUALITEMIPROGETTI : "",
    R1 : "",
    R10 : "",
    R11 : "",
    R12 : "",
    R13 : "",
    R14 : "",
    R15 : "",
    R16 : "",
    R17 : "",
    R18 : "",
    R19 : "",
    R2 : "",
    R20 : "",
    R21 : "",
    R22 : "",
    R23 : "",
    R24 : "",
    R25 : "",
    R3 : "",
    R4 : "",
    R5 : "",
    R6 : "",
    R7 : "",
    R8 : "",
    R9 : "",
    TEMI : ""
  },
  GENERALLABEL : {
    AL : "zum",
    ALTERMINEDELLAFASE : "am Ende der Phase",
    DAL : "von",
    FASE : "Phase",
    MANCANOANCORA : "Fehlt noch:",
    TERMINAIL : "Endet am"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "Ich habe gelesen und bin einverstanden mit",
    ACCETTOPROCESSOLINEEGUIDA2 : "die Leitlinien und Regeln des partizipativen Prozesses"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "Gruppenliste",
    F : "weiblich",
    GRUPPO : "Gruppe",
    M : "männlich",
    MEMBRI : "Profile",
    PUBBLICA : "Veröffentlichen Sie Ihr Profil und stellen Sie sich der Gemeinschaft vor"
  },
  GROUPLIST : {
    COMMUNITY : "Gemeinschaft",
    DDC : "Datum der Erstellung",
    DUE : "Zwei",
    GRUPPI : "Gruppen",
    TRE : "Drei",
    UNO : "Eine"
  },
  HOME : {
    ALERT1 : "<h4>In der Rubrik <a href=\"/wie-es-funktioniert\">wie es funktioniert</a> erfahren Sie, was ein Bürgerhaushalt ist</h4>.",
    ALERT1TITLE : "<b>Bevor wir beginnen...</b>.",
    ALERT2 : "<h4> Hier stimmt etwas nicht. Haben Sie Schwierigkeiten oder wollen Sie einfach nur Hallo sagen? :) Schreiben Sie uns jetzt an <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ASSISTENZA : "Unterstützung",
    DATIGRAFICI : "Daten zur Organisation",
    GETSTARTED : "Los geht's",
    GRUPPICOM : "Gruppen und Gemeinschaften",
    OBIETTIVI : "Themen",
    ORGANIZZAZIONE : "Organisation",
    ORGANIZZAZIONI : "Organisations",
    PROCESSIATTIVI : "Aktive Prozesse",
    PROFILO : "Profile",
    PROFILODETT : "Hier können Sie Ihre Aktivitäten finden und Ihr Profil verwalten",
    SCENARI : "Kontext",
    UTENTIREG : "Registrierte Benutzer",
    VOTANTI : "Wähler",
    VOTIRACCOLTI : "Gesammelte Stimmen"
  },
  IDEADETAIL : {
    ALERT1 : "Füllen Sie mindestens eines der Pflichtfelder zwischen dem Status oder der dem Vorschlag zugewiesenen Punktzahl aus.",
    ALERT10TITLE : "Nicht löschen",
    ALERT11 : "Wenn sie einmal gelöscht sind, können Sie sie nicht wiederherstellen.",
    ALERT11TITLE : "Sind Sie sicher, dass Sie diesen Kommentar löschen wollen?",
    ALERT12TITLE : "Der Kommentar konnte nicht gelöscht werden",
    ALERT13 : "Sie haben bereits eine detaillierte Bewertung für diesen Vorschlag eingegeben",
    ALERT13TITLE : "Achtung",
    ALERT14 : "Der Vorschlag wurde bereits bewertet",
    ALERT14TITLE : "Achtung!",
    ALERT15 : "Nach der Veröffentlichung können Sie es nicht mehr bearbeiten.",
    ALERT15B1 : "Bestätigung",
    ALERT15B2 : "Abbrechen",
    ALERT15TITLE : "Sind Sie sicher, dass Sie diese Bewertung einreichen wollen?",
    ALERT16 : "Die eingegebene Zahl darf keine Nachkommastellen enthalten.",
    ALERT16TITLE : "Achtung",
    ALERT17 : "Die von Ihnen eingegebene Nummer darf nicht mehr als 6 Ziffern enthalten.",
    ALERT17TITLE : "Achtung",
    ALERT1TITLE : "Achtung",
    ALERT2 : "<h4> Stimmt etwas nicht, haben Sie Schwierigkeiten oder wollen Sie einfach nur Hallo sagen? :) Schreiben Sie uns jetzt an <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ALERT3 : "Klicken Sie auf den Stern <span class=\"symbol-rounded\">\n<span class=\"symbol-label font-size-h6 font-weight-bold bg-warning text-white\" style=\"width: 25px;height: 25px;\">\n<i class=\"fas fa-star text-white pr-0 pb-1\"></i>\n</span>\n</span> auf der Seite, wenn Sie die Zusammenfassung Ihrer Stimmen sehen möchten",
    ALERT3TITLE : "Danke, dass Sie für diesen Vorschlag gestimmt haben",
    ALERT4 : "Blättern Sie durch die Vorschläge und wählen Sie den Vorschlag aus, für den Sie stimmen möchten",
    ALERT4TITLE : "Sie haben Ihre Präferenz entfernt!",
    ALERT5 : "Sie können weiterhin für andere Vorschläge spenden, indem Sie wählen, wie Sie Ihr Budget aufteilen",
    ALERT5TITLE : "Vielen Dank, dass Sie den Vorschlag unterstützen!",
    ALERT6TITLE : "Sie folgen diesem Vorschlag!",
    ALERT7TITLE : "Sie folgen dem Vorschlag nicht mehr",
    ALERT8 : "Wenn es einmal gelöscht ist, kann es nicht wiederhergestellt werden.",
    ALERT8B1 : "Ja, löschen!",
    ALERT8B2 : "Nein, nicht löschen!",
    ALERT8TITLE : "Sind Sie sicher, dass Sie diesen Vorschlag löschen wollen?",
    ALERT9 : "Der Vorschlag wurde annulliert",
    ALERT9TITLE : "Annullierung des Vorschlags",
    ALERTBOZZA : "Sie haben eine Entwurfsfassung Ihres Vorschlags. Denken Sie daran, ihn zu veröffentlichen",
    ALERTEMPTYVOTE1USERLEFTHTML : "<h4> Die Anzahl der verfügbaren Einstellungen ist erschöpft. <br /> Denken Sie daran, dass Sie Ihre Wahl bis zum Ende der Abstimmung ändern können. </h4>",
    ALERTEMPTYVOTE1USERLEFTTITLE : "Huch!",
    ALERTOWNERMAILEDHTML1 : "<p>Wir haben Ihre Nachricht gesendet an",
    ALERTOWNERMAILEDHTML2 : "die Ihnen über die von Ihnen eingegebenen Kontakte antworten können.<br />In der Zwischenzeit können Sie durch Ihre Teilnahme an der Plattform einen Beitrag leisten.</p>",
    ALERTOWNERMAILEDTITLE : "GESENDETE NACHRICHT",
    ALERTPUBBLICATA : "Vorschlag wird derzeit geprüft. An seiner Entwicklung mitwirken",
    APPROFONDISCI : "Mehr lesen",
    APPROVATA : "Genehmigt",
    AUTORE : "Published by",
    BUDGET : "Geschätzte Kosten:",
    CAMPOMANCANTE : "The field \"{field}\" is missing",
    CANCELLAIDEA : "Löschen",
    CANVERSION : "Sie haben noch einen Entwurf. <br> Veröffentlichen Sie ihn,  um einen neuen kreieren zu können.",
    CANVERSION2 : "Sie erstellen die {n} Version Ihres Vorschlags",
    CANVERSIONTITLE : "Achtung",
    CANVERSIONTITLE2 : "{count} ist die maximale Anzahl von Versionen, die veröffentlicht werden können",
    CHALLENGE : "Themen",
    CIFRAALTA : "Ihre Spende ist zu groß",
    CIFRABASSA : "Ihre Spende ist zu gering",
    CIFRADELBUDGET : "Sie können noch spenden",
    CIFRAMODIFICATA : "Sie haben Ihre Spende bearbeitet",
    CIFRARIMOSSA : "Sie haben Ihre Spende entfernt",
    CIFRASUPERIOREALBUDGET : "Sie können nicht mehr spenden, weil der von Ihnen angegebene Betrag Ihre finanziellen Möglichkeiten übersteigt.",
    CLICCAILPULSANTE : "Klicken Sie auf die Schaltfläche, wenn Sie für diesen Vorschlag stimmen möchten.",
    COMMENTAIDEA : "Kommentar",
    COMMENTI : "Kommentare",
    COMMENTIALERTEMAILCONFIRM : "<h6 class=\"text-primary\"> Sie haben Ihre E-Mail Adresse noch nicht bestätigt </div><div>Bestätigen Sie, dass Sie der Organisation beitreten und sich für partizipative Prozesse anmelden wollen </div><div>Sie haben die E-Mail nicht erhalten?<br><strong>Klicken Sie auf den Knopf im roten Band, um es erneut zu senden.</strong></div>",
    COMMENTIALERTEMAILMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the organization and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    COMMENTIALERTMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the organization and sign up for participatory processes</div><div>You didn't receive the message?<br><strong>Click on the button in the red band to resend it</strong></div>",
    COMMENTICONTRIBUISCI : "Einen Kommentar hinterlassen",
    COMMENTIDISATTIVATI : "Kommentare sind deaktiviert",
    COMMENTIENTRA : "ANMELDEN ",
    COMMENTIREGISTRATI : "REGISTRIEREN",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "Anmelden, um einen Kommentar zu hinterlassen",
    COMPLETABOZZA : "Vervollständigen Sie den Entwurf",
    CONDIVIDI : "Teilen",
    COSTOIDEA : "Kosten",
    CREATAIL : "Erstellt am",
    DESCRIZIONE : "Beschreibung",
    DONA : "SPENDE",
    DONAREDA : "von einem Minimum von",
    DONAREFINOA : "bis zu einem Maximum von",
    DONRACCOLTE : "Gesammelte Spenden",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the proposal does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the proposal because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    FOLLOWERS : "Gemeinschaft",
    FONDIRACCOLTI : "Gesammelte Mittel",
    GRAZIESCELTAIDEA : "Danke, dass Sie sich für diesen Vorschlag entschieden haben!",
    GRAZIESOSTEGNO : "Danke, dass Sie den Vorschlag unterstützen!",
    GRAZIESOSTEGNOANCORA : "Danke, dass Sie den Vorschlag weiterhin unterstützen!",
    HAIANCORA : "Sie haben noch",
    HAIANCORA2 : "Sie haben noch",
    HASCRITTO : "er/sie/es schrieb",
    IDEAVOTATA : "Abgestimmter Vorschlag",
    INBOZZA : "Entwurf",
    INDICACIFRA : "Geben Sie den Betrag an, den Sie spenden möchten",
    INSERISCICIFRA : "Bitte geben Sie den Betrag an, den Sie für diesen Vorschlag spenden möchten.",
    INSERISCICOMMENTO : "Einen Kommentar hinzufügen",
    INVIA : "senden",
    MACCETTODIESSERERICONTATTATO : "Ich bin damit einverstanden, dass ich über die von mir angegebenen Kontakte kontaktiert werde.",
    MAPPE : "Kontext",
    MBCHIUDI : "schließen",
    MBINVIA : "Senden",
    MCONTATTAILPROPONENTE : "Kontaktieren Sie den Autor",
    MEMAIL : "Ihre E-Mail",
    MESSAGEFIELDNAME : "Message",
    METTIPREF : "Wählen Sie",
    MINSERISCIUNMESSAGGIO : "Ihre Nachricht",
    MMESSAGGIOOBBLIGATORIO : "Die Nachricht ist erforderlich",
    MMOBILE : "Ihre Telefonnummer",
    MNOMINATIVO : "Dein Name",
    MODIFICAIDEA : "bearbeiten",
    MTIPOLOGIACOLLABORAZIONELABEL : "Helfen Sie mit, den Vorschlag zu erweitern",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "Wählen Sie die Art der Nachricht",
    NARRAZIONE : "Der Vorschlag",
    NOCOMMENTI : "Es gibt keine Kommentare",
    NOCOMMUNITY : "Es gibt keine Follower",
    NONAPPROVATA : "Nicht genehmigt",
    NONSEGUIRE : "Unfollow",
    NONVALUTATA : "Nicht bewertet",
    NUOVAVERSIONE : "Neue Version erstellen",
    PH1 : "Bewertung eingeben",
    PH2 : "Bewertung eingeben",
    PREFERENZEPERPREMIARE : "Präferenzen ein, um die besten Vorschläge zu belohnen!",
    PREFRACCOLTE : "Gesammelte Präferenzen",
    PUBBLICATAIL : "on",
    PUOIDONARE : "Sie können spenden",
    RICHIEDIASSISTENZA : "Bitten Sie um Unterstützung!",
    RICORDATI3 : "Denken Sie daran, dass Sie Ihre Spende bis zum Ende der Abstimmung ändern können.",
    RIMUOVIPREF : "Präferenz entfernen",
    SEGUIIDEA : "Folgen Sie",
    SOSTENITORI : "Unterstützer",
    SOSTIENIALTREIDEE : "Sie können weiterhin für andere Vorschläge spenden, indem Sie wählen, wie das Budget aufgeteilt werden soll",
    SOTTOCATEGORIA : "Tipologia",
    TOGLIEREPREFERENZA : "Wenn Sie Ihre Idee geändert haben, können Sie Ihre Präferenz entfernen und für andere Vorschläge stimmen.",
    TUOIVOTI : "Ihre Stimmen",
    URLSITO : "Sito web",
    VAIALSITO : "Vai al sito",
    VALUTAIDEA : "Bewerten Sie",
    VALUTARIDUZIONEDONAZIONI : "Erwägen Sie eine Reduzierung der bereits für andere Vorschläge geleisteten Spenden",
    VALUTAZIONEINSERITA : "Bewertung eingegeben",
    VALUTAZIONETITLE1 : "Geben Sie die endgültige Bewertung ein",
    VALUTAZIONETITLE2 : "Geben Sie die detaillierte Bewertung ein",
    VALUTAZIONETITLE3 : "Abschließende Bewertung",
    VALUTAZIONETITLE4 : "Detaillierte Bewertungen",
    VALUTAZIONETITLE5 : "Abschließende Bewertung bereits eingegeben",
    VALUTAZIONETITLE6 : "Detaillierte Bewertung bereits eingefügt",
    VALUTAZIONI : "Bewertungen",
    VERSIONIPROPOSTA : "Versionen des Vorschlags:",
    VOTAZIONECHIUSA : "Die Abstimmung ist abgeschlossen. Die Ergebnisse werden demnächst veröffentlicht",
    WALLETSOSTIENIIDEA : "Unterstützen Sie den Vorschlag. Machen Sie eine Spende!"
  },
  IDEALIST : {
    ALERTBOZZA : "Sie haben eine Entwurfsfassung Ihres Vorschlags. Sie finden ihn in Ihrem",
    ALERTVERSIONING1 : "Die Versionierung ist aktiv!",
    ALERTVERSIONING2 : "Es ist möglich, die Vorschläge zu verbessern, indem Sie Ideen einbringen oder sammeln und neue und aktualisierte Versionen veröffentlichen.",
    ALERTVERSIONING3 : "Max. Anzahl der Versionen",
    ALERTVERSIONING4 : "Zeitliche Begrenzung",
    ALERTVERSIONING5 : "Sie können bis zu {n} Versionen erstellen",
    ASSISTENZA : "Fordern Sie Unterstützung an!",
    CLASSIFICA : "Rangliste",
    CREAIDEA : "Machen Sie Ihren Vorschlag",
    ELENCOIDEE : "Alle Vorschläge",
    FILTRAPERCHALLENGE : "NACH THEMA FILTERN",
    FILTRAPERMODULO : "NACH BEREICHEN FILTERN",
    FILTRAPERSCENARIO : "NACH KONTEXT FILTERN",
    IDEAVOTATA : "Sie haben für diesen Vorschlag gestimmt!",
    IDEE : "Vorschläge",
    IDEECOSTOSE : "Am teuersten",
    IDEEMENOCOSTOSE : "Am billigsten",
    IDEEMENORECENTI : "Weniger neu",
    IDEEMENOSEGUITE : "Am wenigsten populär",
    IDEEPIUSEGUITE : "Am beliebtesten",
    INFORMAZIONI : "Mehr Infos",
    INFORMAZIONISULLAFASE : "Wie man mitmacht",
    JAMBOARD : "Dem Jamboard beitreten",
    NESSUNO : "Keine",
    NONCISONOIDEE : "Es sind noch keine Vorschläge vorhanden",
    ORDINA : "SORTIEREN NACH",
    PREFERENZE : "Vorlieben",
    PS : "Persönlicher Bereich",
    TUOIVOTI : "Deine Stimmen",
    ULTIMEIDEE : "Letzte",
    VOTANTI : "Wähler",
    VOTAZIONEATTIVA : "Sie können abstimmen",
    VOTAZIONEATTIVA2 : "Stöbern Sie in den Vorschlägen und stimmen Sie für Ihre Favoriten",
    VOTAZIONEATTIVA3 : "Sie können spenden",
    VOTAZIONEATTIVABUDG : "durch eine Spende",
    VOTAZIONEATTIVAMAX : "bis zu",
    VOTAZIONEATTIVAMIN : "von",
    VOTAZIONEATTIVAPREF : "Sie können abstimmen",
    VOTAZIONEATTIVAPREF2 : "und Sie haben noch freie",
    VOTAZIONEATTIVAPREF3 : "Präferenzen",
    VOTAZIONEATTIVAPREF4 : "Präferenz",
    VOTAZIONEATTIVAUTENTEANONIMO : "Melden Sie sich an, wenn Sie stimmberechtigt sind, dann können Sie für Ihre Lieblingsvorschläge stimmen",
    VOTAZIONECHIUSA : "Die Abstimmung ist abgeschlossen. Die Ergebnisse werden bald veröffentlicht",
    WEBINAR : "Nehmen Sie am Webinar teil"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "Endgültige Rangliste",
    COMEFUNZIONA : "Wie es funktioniert",
    COSTOIDEA : "Geschätzte Kosten",
    DONATORI : "Spender",
    ELENCOPROCESSI : "Prozess-Liste",
    FASE : "Phase:",
    FILTRAPERBUDGET : "Nach Budget filtern",
    FILTRAPERPREFERENZE : "Nach Präferenzen filtern",
    FINANZIATO : "Spenden",
    PREFERENZE : "Präferenzen",
    PROCESSO : "Prozess",
    RISULTATI : "Ergebnisse",
    RISULTATIVOTAZIONE : "Abstimmungsergebnisse",
    TUTTOSUPROCESSO : "Alles, was Sie über den Partizipationsprozess wissen müssen"
  },
  INTRO : {
    ASSISTENZA : "Unterstützen Sie"
  },
  INTROBASE : {
    ASSISTENZA : "Unterstützen Sie",
    COSA : "Was Sie tun können",
    PARTECIPA : "Machen Sie mit bei",
    PARTECIPADESC : "Beteiligen Sie sich am Prozess in Ihrer Schule",
    SUBIPART : "auf Bipart"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "Vergessen Sie nicht, Ihre E-Mail Adresse zu bestätigen. Wenn Sie keine E-Mail von BiPArt erhalten haben",
    CONFIRMEMAIL2 : "klicken Sie hier",
    CONFIRMEMAIL3 : "um sie erneut zu senden.",
    CONFIRMMOBILE1 : "Remember to confirm your mobile. If you have not received any SMS from BiPArt",
    CONFIRMMOBILE2 : "click here",
    CONFIRMMOBILE3 : "to resend it."
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "schließen",
    CONFERMA : "Bestätigen Sie die Registrierung für den Prozess",
    ERR1 : "Ups... Es ist ein unerwarteter Fehler aufgetreten.",
    ISCRIVITIPROCESSO : "Für den Prozess anmelden",
    PARTECIPAPROCESSO : "Möchten Sie an dem Prozess teilnehmen?",
    PROPOSTE : "Um Beiträge einzureichen, Kommentare abzugeben, Bewertungen anzufordern oder abzustimmen, müssen Sie sich für den Prozess anmelden.",
    TITLE1 : "Fehler"
  },
  LOGIN : {
    ACCEDI : "Anmelden",
    ACCETTO1 : "Ich habe gelesen und akzeptiere die",
    ACCETTO2 : "Ich habe gelesen und akzeptiere die",
    BACKPORTAL : "Back to portal",
    CAMBIAPASS : "Passwort ändern",
    INSERISCIMAIL : "Geben Sie Ihre E-Mail ein",
    MEX1 : "Anmeldung und Akzeptanz der \"Datenschutzbestimmungen\" und der \"Allgemeinen Geschäftsbedingungen\".",
    MEX2 : "",
    MEX3 : "Erfolgreiche Authentifizierung",
    MEX4 : "Es ist ein Fehler aufgetreten",
    MEX5 : "Ungültige E-Mail",
    MEXLOGIN : "<b>Ihre Daten werden unter voller Einhaltung der deutschen und europäischen Vorschriften, mit großer Sorgfalt behandelt, um Ihre Meinung und Ihre Entscheidungen zu schützen. <br><b>",
    NONRICORDIPASS : "Sie können sich nicht an Ihr Passwort erinnern?",
    NOTIFICHE : "*Wenn Sie Benachrichtigungen über den Fortschritt der eingereichten Vorschläge erhalten möchten.",
    PASSWORDERRATA : "Falsches Passwort",
    PRIVACY : "Datenschutzrichtlinien",
    PROSEGUI : "Weiter",
    TERMINI : "Bedingungen und Konditionen",
    TERMINICONDIZIONI : "Um fortzufahren, lesen Sie bitte die<a href=\"https://bipart.it/terms\" target=\"blank\">Bedingungen und Konditionen</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Datenschutzbestimmungen</a>",
    TITLE1 : "Herzlichen Glückwunsch",
    TITLE2 : "Achtung",
    TITLE3 : "Glückwunsch",
    TITLE4 : "Fehler",
    TITLE5 : "Fehler",
    USERNAMENONESISTE : "Der Nutzername existiert nicht",
    UTENTEAUTENTICATO : "Authentifizierter Benutzer"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "Angemeldet",
    ACCETTO : "Ich habe gelesen und akzeptiere die",
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    BPROSEGUI : "Weiter",
    CAMBIAPASS : "Passwort ändern",
    INSERISCIMAIL : "Ihre E-Mail eingeben",
    MEX1 : "Registrierung und Akzeptanz der \"Datenschutzbestimmungen\" und der \"Allgemeinen Geschäftsbedingungen\"",
    MEX2 : "",
    MEX3 : "Erfolgreiche Authentifizierung",
    MEX4 : "Es ist ein Fehler aufgetreten",
    MEX5 : "Ungültige Mail",
    MEXLOGIN : "Die Plattform für Bürgerbeteiligung.</b><br>Ihre Daten werden unter voller Einhaltung der deutschen und europäischen Vorschriften und mit großer Sorgfalt behandelt, um Ihre Meinung und Ihre Entscheidungen zu schützen.<br><b> Wenn Sie fortfahren möchten, lesen und akzeptieren Sie die beiden folgenden Dokumente.</b>",
    NIENTEACCOUNT : "Sie haben noch kein Konto?",
    NONRICORDIPASS : "Sie können sich nicht an Ihr Passwort erinnern?",
    NOTIFICHE : "*Wenn Sie Benachrichtigungen über den Fortschritt der eingereichten Vorschläge erhalten möchten.",
    PRIVACY : "Datenschutzrichtlinien",
    REGISTRATI : "Registrieren",
    TERMINI : "Bedingungen und Konditionen",
    TERMINIECONDIZIONI : "Um fortzufahren, lesen Sie bitte die <a href=\"https://bipart.it/terms\" target=\"blank\">Geschäftsbedingungen </a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Herzlichen Glückwunsch",
    TITLE2 : "Achtung",
    TITLE3 : "Glückwunsch",
    TITLE4 : "Fehler",
    TITLE5 : "Fehler",
    TITOLO : "Bipart",
    UTENTEAUTENTICATO : "Authentifizierter Benutzer"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Schließen",
    CONFERMA : "Bestätigung",
    ISCRIVITIPROCESSO : "Melden Sie sich mit Ihren Anmeldedaten an und nehmen Sie am Prozess teil",
    MEX1 : "Die von Ihnen eingegebenen Anmeldedaten sind falsch. Bitte überprüfen Sie dies oder schreiben Sie uns an <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX2 : "",
    MEX3 : "Huch... Es ist ein Fehler aufgetreten.",
    NONFAIPARTE : "Sie sind noch nicht registriert?",
    NUOVOACCOUNT : "Erstellen Sie ein Konto, um teilzunehmen",
    ORGANIZATION : "Willkommen bei Bipart",
    PASSWORDERRATA : "Falsches Passwort",
    REGISTRATI : "REGISTRIEREN",
    TITLE2 : "Achtung",
    TITLE3 : "Fehler",
    USERNAMENONESISTE : "Der Benutzername existiert nicht"
  },
  MAINMENU : {
    ABOUT : "Über",
    ACCESSIBILITY : "Zugänglichkeit",
    COMMUNITY : "Gemeinschaft",
    COOKIE : "Cookie",
    DEMO : "Community space erstellen",
    DS : "Deliberativer Raum",
    EXPLORE : "Erkunden Sie",
    FAQ : "FAQ",
    FEATURE : "Anleitung & Richtlinien",
    GIOCHI : "Pädagogische Spiele",
    HOME : "Startseite",
    HOWITWORKS : "Wie es funktioniert",
    HOWTOJOIN : "Wie man beitritt",
    JOINCOMMUNITY : "Community-Bereich",
    LISTCHALLENGES : "Themen",
    LISTGROUPS : "Liste der Gruppen",
    LISTPROCESSES : "Vorgänge",
    LISTSCENARIOS : "Kontext",
    LOGIN : "Anmeldung",
    LOGOUT : "Abmelden",
    PORTAL : "Portal",
    PRIVACY : "Datenschutz",
    PROJECT : "Projekt",
    PROJECTS : "Fallstudien",
    PS : "Persönlicher Raum",
    PSACTIVITIES : "Persönliche Aktivitäten",
    PSPROFILE : "Persönliches Profil",
    PSSETTINGS : "Einstellung",
    REGISTER : "Registrieren",
    SERVICES : "Was wir anbieten",
    TERMS : "Bedingungen und Konditionen",
    WORKSPACE : "Arbeitsbereich"
  },
  MANDATORYFLAGS : {
    ACCETTO : "Ich habe gelesen und akzeptiere die",
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    BPROSEGUI : "Weiter",
    DETTWELCOME : "Ihre Daten werden unter voller Einhaltung der deutschen und europäischen Vorschriften und mit größerer Sorgfalt zum Schutz Ihrer Meinungen und Entscheidungen verarbeitet. Wenn Sie fortfahren möchten, lesen und akzeptieren Sie die beiden folgenden Dokumente.",
    INSERISCIMAIL : "Geben Sie Ihre E-Mail ein",
    MEX2 : "",
    MEX3 : "Erfolgreiche Authentifizierung",
    MEX5 : "Die von Ihnen eingegebene E-Mail ist ungültig",
    NOTIFICHE : "*Wenn Sie Benachrichtigungen über den Fortschritt der eingereichten Vorschläge erhalten möchten.",
    PRIVACY : "Datenschutzrichtlinie",
    TERMINIECONDIZIONI : "Um fortzufahren, lesen Sie bitte die <a href=\"https://bipart.it/terms\" target=\"blank\">Geschäftsbedingungen</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Herzlichen Glückwunsch",
    TITLE2 : "Achtung",
    TITLE3 : "Herzlichen Glückwunsch",
    TITOLO : "Bipart",
    TITOLOWELCOME : "Willkommen bei BiPart, der Plattform für Bürgerbeteiligung.",
    UTENTEAUTENTICATO : "Authentifizierter Benutzer"
  },
  MESSAGEAUTH : {
    0 : "Es ist ein allgemeiner Fehler aufgetreten",
    1 : "",
    100 : "Das angegebene Attribut \"organizationDomain\" stimmt mit keiner Organisation überein.",
    105 : "Das Attribut \"verarbeitet\" fehlt.",
    110 : "Das angegebene \"verarbeitet\"-Attribut stimmt mit keinem Prozess überein",
    115 : "Der Prozess gehört nicht zu dieser Gemeinschaft",
    120 : "Hallo!\nDer Prozess ist privat: Um teilzunehmen, müssen Sie einen Zugangscode anfordern.",
    125 : "Das angegebene \"groupId\"-Attribut stimmt mit keiner Gruppe überein.",
    130 : "Oops!\nDiese Gruppe ist privat und es ist nicht möglich, sich zu registrieren.",
    135 : "Die angeforderte Gruppe gehört nicht zu dem gewählten Prozess.",
    140 : "Hallo!\nUm teilzunehmen, müssen Sie angeben, welcher Gruppe Sie angehören oder beitreten möchten.\nWählen Sie diese bitte aus dem Dropdown-Menü aus. Vielen Dank!",
    145 : "Oops!\n Es ist nicht möglich, sich für diesen Prozess zu registrieren. Für weitere Informationen schreiben Sie uns an support@bipart.it.",
    150 : "Oops!\nSie haben einige wichtige Daten für die Registrierung vergessen. Füllen Sie die rot markierten Felder aus, und das war's.",
    155 : "Oops!\nDer von Ihnen eingegebene Benutzername ist bereits in Gebrauch: Vielleicht haben Sie sich bereits angemeldet und erinnern sich nicht mehr daran.\nVersuchen Sie, sich anzumelden oder geben Sie einen anderen Benutzernamen ein.\nDanke!",
    160 : "Oops!\nIhr Benutzer ist bereits auf der Plattform registriert, aber noch nicht mit diesem Prozess verknüpft.\nBenutzen Sie die Schaltfläche \"Anmelden\" und geben Sie Ihre Anmeldedaten ein, um die Verknüpfung abzuschließen!",
    165 : "Ups!\nDer von Ihnen eingegebene Benutzername wird bereits verwendet: Vielleicht haben Sie sich bereits angemeldet und erinnern sich nicht mehr daran.\nVersuchen Sie, sich anzumelden oder geben Sie einen anderen Benutzernamen ein. Dankeschön!",
    170 : "Schade! Jemand war schneller als Sie und hat diesen Benutzernamen bereits gewählt :( Versuchen Sie einen anderen, noch niedlicheren!",
    172 : "Die E-Mail, die Sie eingegeben haben, wird bereits verwendet.\nBitte prüfen Sie, ob Sie bereits ein Konto bei BiPart haben, ansonsten melden Sie das Problem an support@bipart.it",
    175 : "Ups!\nUm mit der Registrierung fortzufahren, müssen Sie unsere Datenschutzbestimmungen und die Allgemeinen Geschäftsbedingungen lesen und uns mitteilen, dass Sie damit einverstanden sind!",
    180 : "Tschüss!\nDie Organisation ist privat: um teilzunehmen, müssen Sie einen Zugangscode anfordern.",
    182 : "Die angeforderte Gruppe gehört nicht zu der gewählten Organisation.",
    185 : "Hallo!\nUm teilzunehmen, müssen Sie angeben, welcher Gruppe Sie angehören oder beitreten möchten.\nWählen Sie diese bitte aus dem Dropdown-Menü aus. Vielen Dank!",
    187 : "Oops!\nDie gewählte Organisation enthält nur private Gruppen, bei denen eine Anmeldung nicht möglich ist.",
    190 : "Der von Ihnen eingegebene Benutzername wird bereits verwendet: Vielleicht haben Sie sich bereits angemeldet und erinnern sich nicht mehr daran.\nVersuchen Sie, sich anzumelden oder geben Sie einen anderen Benutzernamen ein.\nDanke!",
    192 : "Ups! \nIhr Benutzer ist bereits auf der Plattform registriert, aber noch nicht mit dieser Organisation verbunden.\nBenutzen Sie die Schaltfläche \"Anmelden\" und geben Sie Ihre Anmeldedaten ein, um die Verbindung abzuschließen!",
    2 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Vielen Dank für Ihr Abonnement von<br>{value}</h5>",
    200 : "Ups!\nSie haben zu viel Zeit verstreichen lassen!\nDamit wir Ihre E-Mail-Adresse verifizieren können, müssen Sie den Versand einer neuen Nachricht anfordern und so schnell wie möglich auf die in der Nachricht enthaltene Bestätigungsschaltfläche klicken.",
    205 : "Oops!\nDer Vorgang war nicht erfolgreich: Versuchen Sie es erneut!",
    210 : "Ups!\nIhr Konto entspricht keinem in unseren Archiven vorhandenen Benutzer!",
    215 : "Ups!\nWir haben keine Registrierungsanfragen erhalten, die Ihrer E-Mail-Adresse entsprechen.\nSind Sie sicher, dass Sie das entsprechende Verfahren abgeschlossen haben?\nBitte versuchen Sie, sich erneut zu registrieren.\nDanke!",
    220 : "Ups!\nDie E-Mail-Adresse, die Sie zu bestätigen versuchen, ist bereits verifiziert.\nVersuchen Sie, sich anzumelden.\nDanke!",
    225 : "Ups!\nDer Vorgang war nicht erfolgreich: Versuchen Sie es erneut!",
    3 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Sie nehmen am Prozess teil.<br>{value}</h5>",
    300 : "Ihr Benutzer ist noch nicht auf der Plattform registriert.\n Sind Sie sicher, dass Sie das entsprechende Verfahren abgeschlossen haben? \n Bitte versuchen Sie, sich erneut zu registrieren .\n Vielen Dank!",
    305 : "Oops!\nIhr Benutzer ist bereits auf der Plattform registriert, aber noch nicht mit dieser Organisation verbunden. \n Kontaktieren Sie uns, um Zugang zu beantragen",
    310 : "Oops!\nDer von Ihnen eingegebene Benutzername ist bereits in Gebrauch: Vielleicht haben Sie sich bereits angemeldet und erinnern sich nicht mehr daran.\nTry Versuchen Sie, sich anzumelden, oder geben Sie einen anderen Benutzernamen ein.\n Vielen Dank!",
    4 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><div>Wir bestätigen Ihr Abonnement für {Prozessname} Von nun an sind Sie Teil der Gemeinschaft und können in den Prozessen arbeiten.</div>",
    400 : "Das Attribut \"Mobil\" fehlt.",
    405 : "Oops!\nDie von Ihnen eingegebene Mobilfunknummer ist bereits in Gebrauch: Vielleicht haben Sie sich bereits registriert und erinnern sich nicht mehr daran.\nVersuchen Sie, sich anzumelden oder geben Sie eine andere Nummer ein.\nDanke!",
    410 : "Ups! \nDie von Ihnen eingegebene Mobilfunknummer wird bereits verwendet: Vielleicht haben Sie sich bereits registriert und erinnern sich nicht mehr daran.\nVersuchen Sie, sich anzumelden oder geben Sie eine andere Nummer ein.\nDanke!",
    420 : "Ups! \nDie von Ihnen eingegebene Mobilfunknummer wird bereits verwendet.\nSind Sie sicher, dass es Ihre ist? Versuchen Sie, eine andere einzugeben.\nVielen Dank!",
    425 : "Ops!\nL’attributo “mobile” non compare nel database Utenti\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    500 : "Huch! \nEs ist keine Mobilfunknummer mit Ihrem Profil verknüpft. Gehen Sie zurück und geben Sie Ihre Mobilfunknummer ein.",
    502 : "Es gab ein technisches Problem und wir haben Ihnen einen neuen Verifizierungscode per SMS geschickt.\nBitte wiederholen Sie den Vorgang, nachdem Sie ihn in dasselbe Feld eingegeben haben.\nDanke!",
    510 : "Ups!\nDer von Ihnen eingegebene Verifizierungscode ist ungültig.\nÜberprüfen Sie ihn oder lassen Sie sich einen neuen Code zusenden.\nDankeschön!",
    515 : "Ups! \nDer von Ihnen eingegebene Verifizierungscode ist abgelaufen.\nBitte fordern Sie einen neuen Code an.\nDankeschön!",
    605 : "Oops!\nEine Mobilfunknummer ist bereits mit Ihrem Profil verknüpft.",
    700 : "Das angegebene Attribut \"process2ModuleId\" stimmt mit keinem Modul überein.",
    705 : "Das Zielmodul gehört nicht zum Zielprozess.",
    710 : "Das Steuerkennzeichen ist weder deklariert noch ist es bereits dem anfragenden AppUser zugewiesen.",
    715 : "Oops!\nDer von Ihnen eingegebene Steuernummer ist ungültig: Überprüfen Sie ihn bitte.\nDankeschön!",
    720 : "Ups! \nDie von Ihnen eingegebene Steuernummer wird bereits verwendet.\nSind Sie sicher, dass es Ihre ist? Versuchen Sie, eine andere einzugeben.\nDankeschön!",
    725 : "Ups!\nEine Steuernummer ist bereits mit Ihrem Profil verknüpft.",
    ATTENZIONE : "Achtung"
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "Inserisci oppure copia e incolla l'URL del sito ufficiale dell'organizzazione che fornisce il servizio",
    MAXLENGTHPART1 : "Achtung! Sie haben noch",
    MAXLENGTHPART2 : "Zeichen zur Verfügung",
    PH0 : "Maximal 60 Zeichen",
    PH5 : "----",
    SELEZIONASOTTOCATEGORIA : "Indica che tipo di servizio stai pubblicando, selezionando una delle voci che ti compaiono cliccando il campo di inserimento",
    SOTTOCATEGORIA : "Tipologia"
  },
  MODULEIDEACREATE : {
    ALERT1 : "Vergewissern Sie sich, dass Sie die Pflichtfelder ausgefüllt haben: Titel und Beschreibung",
    ALERT1TITLE : "Achtung",
    ALERT2 : "Der Vorschlag ist korrekt eingegeben!",
    ALERT3 : "Geben Sie einen angenommenen Wert ein, um die geschätzten Kosten des Vorschlags anzugeben.",
    ALTROMATERIALE : "Wenn Sie weiteres nützliches Material zur besseren Veranschaulichung Ihres Vorschlags haben (Zeichnungen, Einblicke, technische Spezifikationen...), laden Sie es hier hoch.",
    ANNULLA : "Abbrechen",
    AVANTI : "Weiterleiten",
    BOZZA : "Als Entwurf speichern",
    CHALLENGE : "Themen",
    COSTOIPOTIZZATO : "Geschätzte Kosten",
    CREAIDEA : "Einen Vorschlag schreiben",
    DAIBREVETITOLO : "Geben Sie Ihrem Vorschlag einen kurzen und einprägsamen Titel",
    DESCRIVI : "Fassen Sie Ihren Vorschlag in wenigen Worten zusammen. Sie können ihn dann im nächsten Abschnitt detailliert beschreiben.",
    DESCRIZIONE : "Zusammenfassung",
    DOCALLEGATI : "Dokumente und Anhänge.",
    FILEACCETTATI : "Maximale Dateigröße: 10 MB. Unterstützte Formate: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Maximale Dateigröße: 10 MB. Unterstützte Formate: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximale Dateigröße: 10 MB. Unterstützte Formate: JPG, JPEG, PNG",
    IMMAGINERAPPRESENTATIVA : "Fügen Sie ein Bild ein, das Ihren Vorschlag darstellt",
    IMMAGINI : "Fügen Sie weitere Bilder ein, um Ihr Angebot besser verständlich zu machen.",
    INDICACOSTO : "Geben Sie an, wie viel Ihr Vorschlag voraussichtlich kosten wird.",
    ISTRUZIONI : "Anweisungen",
    LOGO : "Titelbild",
    MAPPEEINFO : "Karten und Infografiken",
    NARRA : "Beschreiben Sie Ihren Vorschlag im Detail",
    NARRAZIONE : "Der Vorschlag",
    OVERVIEW : "Allgemeine Informationen",
    PH1 : "Maximal 600 Zeichen",
    PH2 : "Einbettungscode (nur für youtube)",
    PH3 : "Geben Sie die Adresse ein und klicken Sie auf das beste Ergebnis, um den Pinpoint zu platzieren",
    PH4 : "URL",
    PUBBLICA : "Veröffentlichen",
    SCEGLISFIDE : "Wählen Sie aus, welche(s) Thema(e) Ihr Vorschlag anspricht",
    SELEZIONAMAPPA : "Zeigen Sie an, wo sich Ihr Vorschlag befindet",
    SELEZIONATRA : "Wählen Sie aus dem",
    TITOLO : "Titel",
    TRASCINA : "Ziehen Sie das Bild hierher oder klicken Sie hier, um es hochzuladen",
    TRASCINADOC : "Ziehen Sie die Dateien hierher oder klicken Sie hier, um sie hochzuladen",
    TRASCINAIMMAGINI : "Ziehen Sie die Bilder hierher oder klicken Sie hier, um sie hochzuladen",
    URLSITO : "Sito web",
    VIDEO : "Wenn",
    VIDEOHINT : "Das Video dient dazu, Ihren Vorschlag besser zu veranschaulichen und mehr Menschen anzusprechen."
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "Titel aktualisieren",
    AGGIUNGIALTREIMMAGINI : "Weitere Bilder hinzufügen",
    ALERT1 : "Vergewissern Sie sich, dass Sie die Pflichtfelder ausgefüllt haben: Titel und Beschreibung",
    ALERT1TITLE : "Achtung",
    ALERT2 : "Der Vorschlag ist korrekt eingegeben!",
    ALERT2TITLE : "Sie sind im Begriff, eine neue Version des Vorschlags zu veröffentlichen",
    ALERT3 : "Wählen Sie die Sprache, in der Ihr Vorschlag veröffentlicht werden soll. Sie müssen mindestens eine Sprache auswählen.",
    ALERT4 : "Denken Sie daran, dass Sie den Vorschlag nach der Veröffentlichung nicht mehr ändern können.",
    ALERT5 : "Geben Sie einen korrekten Wert ein, um die geschätzten Kosten des Vorschlags anzugeben.",
    ALTROMATERIALE : "Wenn Sie weiteres nützliches Material zur besseren Veranschaulichung Ihres Vorschlags haben (Zeichnungen, Einblicke, technische Spezifikationen...), laden Sie es hier hoch.), laden Sie es hier hoch.",
    ANNULLA : "Abbrechen",
    AVANTI : "Weiterleiten",
    BOZZA : "Als Entwurf speichern",
    CANCELLA : "Löschen",
    CHALLENGE : "Themen",
    COSTO : "Kosten",
    COSTOIPOTIZZATO : "Geschätzte Kosten aktualisieren",
    DESCRIVI : "Beschreibung aktualisieren",
    DESCRIZIONE : "Beschreibung",
    DOCALLEGATI : "Dokumente und Anhänge.",
    DOCTECNICA : "Technische Dokumentation",
    EDITRESOURCESMODALTITLE : "Titel bearbeiten",
    ERR1 : "Erforderliches Feld",
    FILEACCETTATI : "Maximale Dateigröße: 10 MB. Unterstützte Formate: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Maximale Dateigröße: 10 MB. Unterstützte Formate: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximale Dateigröße: 10 MB. Unterstützte Formate: DOC, XLS, PDF, PPT",
    IMMAGINI : "Bilder",
    ISTRUZIONI : "Anleitungen",
    LINKVIDEO : "Video-Links",
    LOGO : "Logo",
    MAPPEEINFO : "Karten und Infografiken",
    MODIFICA : "bearbeiten",
    MODIFICAIDEA : "Vorschlag bearbeiten",
    MOSTRA : "anzeigen",
    NARRA : "Beschreiben Sie die Taktik im Detail",
    NARRAZIONE : "Der Vorschlag",
    OVERVIEW : "Allgemeine Informationen",
    PH1 : "Maximal 600 Zeichen",
    PH2 : "Einbettungscode (nur bei Youtube)",
    PH3 : "Geben Sie die Adresse ein und klicken Sie auf das beste Ergebnis, um den Pinpoint zu platzieren",
    PH4 : "URL",
    PUBBLICA : "veröffentlichen",
    SCEGLISFIDE : "Wählen Sie aus, welche(s) Thema(e) Ihr Vorschlag anspricht",
    SELEZIONAMAPPA : "Zeigen Sie an, wo sich Ihr Vorschlag befindet",
    SELEZIONATRA : "Wählen Sie zwischen",
    TITLE : "Titel",
    TITOLO : "Titel",
    TRASCINA : "Ziehen Sie das Bild hierher oder klicken Sie hier, um es hochzuladen",
    TRASCINADOC : "Ziehen Sie die Dateien hierher oder klicken Sie hier, um sie hochzuladen",
    TRASCINAIMMAGINI : "Ziehen Sie die Bilder hierher, oder klicken Sie hier, um sie hochzuladen",
    URLSITO : "Sito web",
    VIDEO : "Video aktualisieren",
    VIDEOHINT : "Video (Code zum Einbetten einfügen)"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "Einen Kontext anpassen",
    AGGIORNATITOLO : "Titel aktualisieren",
    AGGIUNGIALTREIMMAGINI : "Weitere Bilder hinzufügen",
    ALERT1 : "Vergewissern Sie sich, dass Sie die Pflichtfelder ausgefüllt haben: Titel und Beschreibung",
    ALERT1TITLE : "Achtung",
    ALERT2 : "Der Vorschlag ist korrekt eingegeben!",
    ALERT2TITLE : "Sie sind im Begriff, eine neue Version des Vorschlags zu veröffentlichen",
    ALERT3 : "Wählen Sie die Sprache, in der Ihr Vorschlag veröffentlicht werden soll. Sie müssen mindestens eine Sprache auswählen.",
    ALERT4 : "Denken Sie daran, dass Sie den Vorschlag nach der Veröffentlichung nicht mehr ändern können.",
    ALERT5 : "Geben Sie einen korrekten Wert ein, um die voraussichtlichen Kosten des Vorschlags anzugeben.",
    ALTROMATERIALE : "Wenn Sie weiteres nützliches Material zur besseren Veranschaulichung Ihres Vorschlags haben (Zeichnungen, Einblicke, technische Spezifikationen...), laden Sie es hier hoch.), laden Sie es hier hoch.",
    ANNULLA : "Abbrechen",
    AVANTI : "Weiterleiten",
    BOZZA : "Als Entwurf speichern",
    CANCELLA : "Abbrechen",
    CHALLENGE : "Themen",
    COSTO : "Kosten",
    COSTOIPOTIZZATO : "Angenommene Kosten aktualisieren",
    DESCRIVI : "Beschreibung aktualisieren",
    DESCRIZIONE : "Kurze Beschreibung",
    DOCALLEGATI : "Dokumente und Anhänge.",
    DOCTECNICA : "Technische Dokumentation",
    EDITRESOURCESMODALTITLE : "Titel bearbeiten",
    ELENCOIDEE : "Alle Vorschläge",
    ERR1 : "Erforderliches Feld",
    FILEACCETTATI : "Maximale Dateigröße: 10 MB. Unterstützte Formate: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Maximale Dateigröße: 10 MB. Unterstützte Formate: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximale Dateigröße: 10 MB. Unterstützte Formate: JPG, JPEG, PNG",
    IMMAGINI : "Bilder",
    ISTRUZIONI : "Anleitungen",
    LINKVIDEO : "Video-Link",
    LOGO : "Logo",
    MAPPEEINFO : "Kontext",
    MODIFICA : "bearbeiten",
    MODIFICAIDEA : "Den Vorschlag bearbeiten",
    MOSTRA : "anzeigen",
    NARRA : "Beschreiben Sie Ihren Vorschlag im Detail",
    NARRAZIONE : "Der Vorschlag",
    NUOVAVERSIONE : "Neue Version erstellen",
    NUOVAVERSIONEMESSAGGIO1 : "Sie erstellen die",
    NUOVAVERSIONEMESSAGGIO2 : "VERSION",
    NUOVAVERSIONEMESSAGGIO3 : "Ihres Vorschlags",
    OVERVIEW : "Beschreibung",
    PH1 : "Maximal 600 Zeichen",
    PH2 : "URL (nur YouTube)",
    PH3 : "Beginnen Sie mit der Eingabe der Adresse und klicken Sie auf das beste Ergebnis, um den Pinpoint zu platzieren",
    PH4 : "URL",
    PROCESSO : "bearbeiten",
    PUBBLICA : "veröffentlichen",
    SCEGLISFIDE : "Wählen Sie die Themen, die Ihr Vorschlag behandeln soll.",
    SELEZIONAMAPPA : "Bestimmen Sie, wo sich Ihr Vorschlag befindet",
    SELEZIONATRA : "Wählen Sie aus",
    TITLE : "Titel",
    TITOLO : "Titel",
    TRASCINA : "Ziehen Sie das Bild hierher oder klicken Sie hier, um es hochzuladen",
    TRASCINADOC : "Ziehen Sie die Dateien hierher oder klicken Sie hier, um sie hochzuladen",
    TRASCINAIMMAGINI : "Ziehen Sie die Bilder hierher, oder klicken Sie hier, um sie hochzuladen",
    URLSITO : "Sito web",
    VIDEO : "Video aktualisieren",
    VIDEOHINT : "Video (URL einfügen)"
  },
  MODULEINFO : {
    ELENCOPROCESSI : "Prozess-Liste",
    INFO : "Prozess-Infos",
    INPROGRESS : "In Arbeit...",
    PROCESSO : "Prozess"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "Prozess Liste",
    INFO : "Modul im Bau",
    INPROGRESS : "In Arbeit...",
    PROCESSO : "Prozess"
  },
  MODULESERVICECREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il servizio è stato inserito correttamente!",
    ALERT3 : "Inserisci il costo del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    CREAIDEA : "Descrivi il tuo servizio",
    DAIBREVETITOLO : "Dai un titolo breve ma accattivante al tuo servizio",
    DESCRIVI : "Descrivi in poche righe il tuo servizio. Puoi dettagliarlo al prossimo step",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine che rappresenti il tuo servizio",
    IMMAGINI : "Pubblica altre immagini utili a far comprendere meglio il tuo servizio",
    INDICACOSTO : "Indica quanto potrebbe costare il tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli a quale o a quali si riferisce il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube che spiega il tuo servizio, rendilo visibile a tutti copiando qui l’URL",
    VIDEOHINT : "Un video aiuta a descrivere meglio un servizio e a raggiungere più persone"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "Il servizio è stato caricato correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione del servizio",
    ALERT3 : "Seleziona la lingua in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    MODIFICAIDEA : "Modifica i contenuti",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il servizio",
    URLSITO : "Sito web"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il tuo servizio è stato inserito correttamente!",
    ALERT2TITLE : "Stai per pubblicare una versione aggiornata del tuo servizio",
    ALERT3 : "Seleziona la lingua o le lingue in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALERT5 : "Inserisci un valore corretto per indicare il costo ipotizzato del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    ELENCOIDEE : "Tutte i servizi",
    MODIFICAIDEA : "Modifica i contenuti del tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    NUOVAVERSIONEMESSAGGIO3 : "del tuo servizio",
    SCEGLISFIDE : "Scegli quale o quali tematiche vuole affrontare il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web"
  },
  MODULESTORYCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Geschichte erfolgreich veröffentlicht!",
    ALERT3 : "Geben Sie einen Wert ein, um die angenommenen Kosten der Taktik anzugeben",
    ALTROMATERIALE : "Wenn Sie weiteres Material haben, um Ihre Geschichte besser zu erzählen, laden Sie es hier hoch.",
    CREAIDEA : "Erzählen Sie Ihre Geschichte",
    DAIBREVETITOLO : "Geben Sie Ihrer Geschichte einen kurzen und aussagekräftigen Titel",
    DESCRIVI : "Beschreiben Sie Ihre Geschichte in ein paar Zeilen",
    IMMAGINERAPPRESENTATIVA : "Fügen Sie ein Bild ein, das Ihre Geschichte beschreibt",
    IMMAGINI : "Stellen Sie weitere Bilder ein, die Ihre Geschichte besser beschreiben.",
    INDICACOSTO : "",
    NARRA : "Erzählen Sie Ihre Geschichte im Detail",
    NARRAZIONE : "Die Geschichte",
    SCEGLISFIDE : "Wählen Sie das/die Thema(s), von dem/denen Ihre Geschichte handelt.",
    SELEZIONAMAPPA : "Geben Sie an, wo Ihre Geschichte stattgefunden hat",
    URLSITO : "Sito web",
    VIDEO : "Wenn es ein Video gibt oder Sie ein Video auf YouTube gemacht haben, in dem Sie Ihre Geschichte erzählen, teilen Sie es mit allen, indem Sie die URL hierher kopieren.",
    VIDEOHINT : "Ein Video hilft Ihnen, eine Geschichte zu erzählen und mehr Menschen zu erreichen."
  },
  MODULESTORYEDIT : {
    ALERT2 : "Geschichte erfolgreich veröffentlicht!",
    ALERT2TITLE : "Sie sind dabei, eine neue Version der Geschichte zu veröffentlichen",
    ALERT3 : "Wählen Sie aus, in welcher Sprache Sie Ihre Geschichte veröffentlichen möchten. Sie müssen mindestens eine Sprache auswählen.",
    ALTROMATERIALE : "Wenn Sie weiteres Material haben, um Ihre Geschichte besser zu erzählen, laden Sie es hier hoch.",
    MODIFICAIDEA : "Bearbeiten Sie die Geschichte",
    NARRA : "Erzählen Sie Ihre Geschichte im Detail",
    NARRAZIONE : "Die Geschichte",
    SCEGLISFIDE : "Wählen Sie das Thema Ihrer Geschichte.",
    SELEZIONAMAPPA : "Gib an, wo deine Geschichte spielt",
    URLSITO : "Sito web"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Geschichte erfolgreich veröffentlicht!",
    ALERT2TITLE : "Sie sind dabei, eine neue Version der Geschichte zu veröffentlichen",
    ALERT3 : "Wählen Sie, in welcher Sprache Sie Ihre Geschichte veröffentlichen möchten. Sie müssen mindestens eine Sprache auswählen.",
    ALERT5 : "",
    ALTROMATERIALE : "Wenn Sie zusätzliches Material haben, um Ihre Geschichte zu erzählen, laden Sie es hier hoch.",
    ELENCOIDEE : "Alle Geschichten",
    MODIFICAIDEA : "Bearbeiten Sie die Geschichte",
    NARRA : "Erzählen Sie Ihre Geschichte im Detail",
    NARRAZIONE : "Die Geschichte",
    NUOVAVERSIONEMESSAGGIO3 : "Ihrer Geschichte",
    SCEGLISFIDE : "Wählen Sie das/die Thema(s), von dem/denen Ihre Geschichte handelt.",
    SELEZIONAMAPPA : "Geben Sie an, wo Ihre Geschichte stattgefunden hat",
    URLSITO : "Sito web"
  },
  MODULETACTICCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Die Taktik ist korrekt eingegeben!",
    ALERT3 : "Geben Sie einen Schätzwert ein, um die geschätzten Kosten der Taktik anzugeben.",
    ALTROMATERIALE : "Wenn Sie anderes nützliches Material haben, um die Taktik besser zu veranschaulichen (Zeichnungen, Einblicke, technische Spezifikationen...), laden Sie es hier hoch.",
    CREAIDEA : "Schreiben Sie eine Taktik",
    DAIBREVETITOLO : "Geben Sie für die Taktik einen kurzen und eingängigen Titel",
    DESCRIVI : "Fassen Sie die Taktik in ein paar Worten zusammen. Sie können sie dann im nächsten Abschnitt detailliert beschreiben.",
    IMMAGINERAPPRESENTATIVA : "Fügen Sie ein Bild ein, das die Taktik darstellt",
    IMMAGINI : "Stellen Sie weitere Bilder ein, damit die Leute die Taktik besser verstehen.",
    INDICACOSTO : "Gibt an, wie viel die Taktik kosten könnte",
    NARRA : "Beschreiben Sie die Taktik im Detail",
    NARRAZIONE : "Die Taktik",
    SCEGLISFIDE : "Wählen Sie, welche Themen die Taktik ansprechen soll.",
    SELEZIONAMAPPA : "Geben Sie an, wo sich die Taktik befindet",
    URLSITO : "Sito web",
    VIDEO : "Wenn es ein Video gibt oder Sie eines zur Erklärung der Taktik erstellt haben, betten Sie es ein, indem Sie den Code hierher kopieren (nicht die URL), damit es für alle sichtbar ist.",
    VIDEOHINT : "Das Video dient dazu, die Taktik besser zu veranschaulichen und mehr Menschen anzusprechen."
  },
  MODULETACTICEDIT : {
    ALERT2 : "Die Taktik ist korrekt eingegeben!",
    ALERT2TITLE : "Sie sind dabei, eine neue Version der Taktik zu veröffentlichen",
    ALERT3 : "Wählen Sie die Sprache, in der die Taktik veröffentlicht werden soll. Sie müssen mindestens eine Sprache auswählen.",
    ALTROMATERIALE : "Wenn Sie anderes nützliches Material zur besseren Veranschaulichung der Taktik haben (Zeichnungen, Einblicke, technische Spezifikationen...), laden Sie es hier hoch.), laden Sie es hier hoch.",
    MODIFICAIDEA : "Taktik bearbeiten",
    NARRA : "Beschreiben Sie die Taktik im Detail",
    NARRAZIONE : "Die Taktik",
    SCEGLISFIDE : "Wählen Sie, welche Themen die Taktik ansprechen soll.",
    SELEZIONAMAPPA : "Bestimmen Sie, wo sich die Taktik befindet",
    URLSITO : "Sito web"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Die Taktik ist korrekt eingereicht!",
    ALERT2TITLE : "Sie sind dabei, eine neue Version der Taktik zu veröffentlichen",
    ALERT3 : "Wählen Sie die Sprache, in der die Taktik veröffentlicht werden soll. Sie müssen mindestens eine Sprache auswählen",
    ALERT5 : "Geben Sie einen Schätzwert ein, um die geschätzten Kosten für die Taktik anzugeben",
    ALTROMATERIALE : "Wenn Sie anderes nützliches Material zur besseren Veranschaulichung der Taktik haben (Zeichnungen, Einblicke, technische Spezifikationen...), laden Sie es hier hoch.",
    ELENCOIDEE : "Alle Taktiken",
    MODIFICAIDEA : "Bearbeiten Sie die Taktik",
    NARRA : "Beschreiben Sie die Taktik im Detail",
    NARRAZIONE : "Die Taktik",
    NUOVAVERSIONEMESSAGGIO3 : "der Taktik",
    SCEGLISFIDE : "Wählen Sie, welche Themen die Taktik ansprechen soll.",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "Community",
    ISCRITTI : "{subscribers} Benutzer ({profiles} profiles)"
  },
  PARTIALSIDEE : {
    APPROVED : "Genehmigt",
    BUDGET : "Budget: €",
    CREATADA : "Erstellt von",
    RANK01 : "Rang 01",
    VOTATA : "ABGESTIMMT"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "<h4>Sie können sich hier nicht mit Ihrem Konto anmelden. Beantragen Sie authentifizierten Zugang unter <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>",
    ALERT1TITLE : "<b>Die Gruppe ist privat</b>",
    ALERT2 : "Sie können sich nach dem Start anmelden!",
    ALERT2TITLE : "Die Phase ist gestartet.",
    ALERT3 : "<h4>Sie können sich nicht mit Ihrem Konto anmelden. Beantragen Sie authentifizierten Zugang unter <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>. ",
    ALERT3TITLE : "",
    COMMENTI : "Kommentare",
    COMMUNITY : "Gemeinschaft",
    CONCLUSA : "Abgeschlossen",
    CONCLUSO : "Abgeschlossen",
    DAATTIVARE : "Noch zu aktivieren",
    DRIVERAMBIENTE : "Antreiber: Umwelt",
    FASE : "Phase",
    INATTIVAZIONE : "In Aktivierung",
    INCORSO : "In Bearbeitung",
    PARTECIPA : "Beitritt",
    PRIVATO : "Privat",
    PUBBLICO : "Öffentlich",
    RICHIEDIACCESSO : "Zugang beantragen",
    VALUTAZIONI : "Auswertungen",
    VISUALIZZA : "Ansicht",
    VOTA : "Abstimmen"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "<h4>Abbiamo inviato un codice SMS di verifica al tuo cellulare.</h4>",
    ALERT1TITLE : "Info",
    ALERTPHONE1HTML : "Il numero fornito non risulta registrato presso l'organizzazione",
    ALERTPHONE1TITLE : "Utente sconosciuto",
    CAMBIAPASSWORD : "Cambia la password",
    CODICEOTP : "Codice OTP*",
    CONFERMA : "Conferma",
    CONFERMAPASSWORD : "Conferma la password",
    ERROROTPMAXCHAR : "Numero massino di cifre inseribili 6",
    ERRORPWEQUAL : "Le password devono essere uguali",
    ERRORPWLENGTH : "La password deve essere lunga almeno 6 caratteri",
    INSERISCICELLULARE : "Inserisci cellulare",
    INSERISCIPASSWORD : "Inserisci la password",
    METODOCELLULARE : "Cellulare",
    METODORECUPERO : "Metodo di recupero",
    OTPDIMENTICATO : "Hai dimenticato di inserire il codice OTP",
    PHONEERROR1 : "Hai dimenticato di inserire il prefisso",
    PHONEERROR2 : "Hai per caso dimenticato di inserire il tuo numero di cellulare?",
    PHONEERROR3 : "Il numero che hai inserito è un po’ strano … controlla bene! :)",
    PROCEDI : "procedi",
    RECUPEROPASSWORD : "Recupera la tua password",
    RECUPERORIUSCITO : "Cambio password completato con successo",
    RICHIEDINUOVOCODICE1 : " <b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti ",
    RICHIEDINUOVOCODICE2 : " richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : " o scrivici a ",
    SCELTAMETODO : "Scegli come recuperarla",
    SCELTAMETODONECESSARIA : "La scelta del metodo è obbligatoria",
    VERIFICA1 : "Verifica il tuo numero di telefono",
    VERIFICA2 : "Ti abbiamo inviato un SMS con un codice di verifica OTP. Inseriscilo per accedere al tuo account e creare la tua nuova password."
  },
  PHASEMODULECARDIDEA : {
    IDEE : "Vorschläge"
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "servizi"
  },
  PHASEMODULECARDSTORY : {
    IDEE : "Geschichten"
  },
  PHASEMODULECARDTACTIC : {
    IDEE : "Taktiken"
  },
  PORTAL : {
    CONTACTUS : "Contact us",
    DELIBERATIVESPACE : "Deliberative space",
    HOME : "Home",
    INSTRUMENTS : "The instruments",
    PROJECT : "The project",
    SUBSCRIBE : "Subscribe"
  },
  PRIVACY : {
    DIRITTO : "6. Diritto di accesso e controllo dei dati personali, diritto di opposizione",
    DIRITTODESCRIZIONE1 : "In ogni momento Lei ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile; può ottenere l’indicazione dell’origine dei Suoi dati personali, delle finalità e modalità del loro trattamento, della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici, degli estremi identificativi del Titolare, dei responsabili (se nominati) e della tipologia dei soggetti ai quali i Suoi dati personali possono essere comunicati, trasferiti, o che possono venirne a conoscenza in qualità di responsabili o incaricati.",
    DIRITTODESCRIZIONE2 : "Lei ha altresì il diritto di accedere a qualsiasi Suo dato personale trattato da BiPart e potrà, chiedere la modifica, correzione, copia, restrizione o estensione della condivisione, anonimizzazione, portabilità o eliminazione (anche parziale) dei Suoi dati personali. Lei ha altresì il diritto di revocare in qualsiasi momento il consenso al trattamento dei Suoi dati da parte del Titolare.<br>Potrà esercitare i predetti diritti inviando una richiesta scritta alla sede legale di BiPart Impresa sociale srl (già BiPart Srls), sita in Milano, via Metauro, 4 mediante lettera raccomandata ovvero email al seguente indirizzo PEC bipart@casellapec.com.\nCon comunicazione da inviarsi al medesimo recapito Lei potrà altresì esercitare il diritto di opporsi in qualsiasi momento, per motivi connessi alla Sua situazione particolare, al trattamento (anche soltanto parziale) dei dati personali che La riguardano, ancorché pertinenti allo scopo della raccolta e al trattamento di dati personali a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale. Da tale data in avanti il Titolare si asterrà dal trattare ulteriormente i Suoi dati personali salvi i diritti riconosciutigli ex lege.\nPer avere ulteriori informazioni in ordine ai Suoi diritti, La invitiamo a visitare il sito web dell’Autorità Garante per la protezione dei dati personali all’indirizzo www.garanteprivacy.it.",
    FINALITA : "3. Finalità del trattamento dei suoi dati",
    FINALITADESCRIZIONE1 : "Il trattamento dei Suoi dati ha la finalità di consentire l’utilizzo della Piattaforma e l’erogazione di tutti i servizi connessi e/o strumentali alla stessa ovvero di tutte le attività legate allo sviluppo e realizzazione dei progetti di bilancio partecipativo e di partecipazione in generale con i soggetti, pubblici e privati, coinvolti in tali progetti gestiti mediante la Piattaforma da BiPart; il trattamento trova quindi la propria base giuridica negli accordi di volta in volta conclusi dal Titolare con i soggetti (pubblici e privati) coinvolti nei progetti di bilancio partecipativo gestiti da BiPart, anche mediante la Piattaforma.",
    FINALITADESCRIZIONE2 : "Per il conseguimento di tali finalità, ed a titolo meramente esemplificativo, i Suoi dati personali potranno essere utilizzati e comunicati a soggetti terzi per l’evasione dell'iscrizione, per la verifica della correttezza dei dati anagrafici inseriti, per l’esecuzione e gestione del servizio, per il data entry, per l’elaborazione in forma statistica della partecipazione alla Piattaforma (utilizzo dei dati socio-demografici e geografici degli iscritti). Inoltre, i Suoi dati personali saranno trattati per assolvere ad obblighi di legge, contabili e fiscali. \nPrecisiamo pertanto che il mancato conferimento dei dati personali a tali fini potrebbe implicare l’impossibilità – totale o parziale – per BiPart di fornire la prestazione richiesta e/o svolgere i relativi servizi connessi e/o strumentali.",
    FINALITADESCRIZIONE3 : "I Suoi dati personali saranno conservati da BiPart fino a quando il Suo account sarà aperto. BiPart, a decorrere dalla disattivazione dell’account, provvederà alla cancellazione dei Suoi dati personali, avendo comunque la facoltà di renderli anonimi e conservarli esclusivamente per la creazione di un modello di utenza (che tenga conto esclusivamente del sesso, dell’età e, se disponibile, dell’area geografica) utile per la ricerca scientifica e statistica legata alla diffusione e sviluppo della prassi di bilancio partecipativo.",
    INTRO : "1. Introduzione",
    INTRODESCRIZIONE1 : "BiPart Impresa sociale srl (già BiPart Srls), C.F. e P.IVA 094444700968, con sede in Milano, via Metauro n. 4, in qualità di titolare del trattamento dei dati (di seguito anche l’“BiPart” o anche il “Titolare”), ai sensi di quanto previsto dal D.Lgs. 30 giugno 2003 n. 196 e ss. modifiche (di seguito “Codice Privacy”) e dal Regolamento UE n. 2016/679 (di seguito “GDPR”), intende informarLa di quali saranno le modalità e le finalità del trattamento dei dati personali da Lei forniti.",
    INTRODESCRIZIONE2 : "Gli utenti iscritti (di seguito i “Membri”) hanno la possibilità di partecipare alle attività rese possibili dalla Piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione) nonché ai vari progetti di bilancio partecipativo, anche interagendo tra loro, mediante scambio di conoscenze, proposte ed idee, sia in modalità privata che pubblica. I contenuti ed i dati su alcuni dei servizi offerti con la Piattaforma sono pertanto visualizzabili dai Membri così come pure da soggetti non iscritti (i “Visitatori”). Lei utilizzando la Piattaforma presta dunque il Suo proprio specifico, preventivo, libero e facoltativo consenso al trattamento dei Suoi dati secondo quanto indicato nella presente informativa.",
    MANIFESTO : "7. Manifesto del consenso",
    MANIFESTODESCRIZIONE1 : "Qualora i termini della presente informativa siano da Lei condivisi, BiPart Le chiede di autorizzazione e acconsentire al trattamento dei Suoi dati personali nelle modalità e finalità qui indicate, mediante approvazione espressa. In segno inequivocabile del Suo consenso Le chiediamo quindi di barrare la presente casella.",
    MODALITA : "4. Modalità di trattamento e sicurezza dei suoi dati",
    MODALITADESCRIZIONE1 : "I Suoi dati personali saranno raccolti e trattati principalmente con strumenti elettronici ed informatici e memorizzati sia su supporti informatici che su supporti cartacei e su ogni altro tipo di supporto idoneo da parte dei soggetti a ciò espressamente autorizzati dal Titolare, tutti debitamente istruiti in materia di sicurezza dei dati personali e del diritto alla privacy. Secondo la normativa indicata dal Codice Privacy e dal GDPR, tale trattamento sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.",
    MODALITADESCRIZIONE2 : "Per evitare l’accesso non autorizzato o la divulgazione e assicurare l’utilizzo appropriato dei Suoi dati personali, BiPart ha messo in atto procedure amministrative, tecniche e fisiche ragionevoli e appropriate che verranno costantemente adeguate alla normativa tempo per tempo vigente in materia di protezione dei dati personali. Al fine di garantire il rispetto degli standard di cui al Codice Privacy e al GDPR il trattamento dei Suoi dati personali sarà svolto esclusivamente in Paesi appartenenti all’Unione Europea.",
    MODALITADESCRIZIONE3 : "BiPart si impegna ad adeguare la propria privacy policy alle disposizioni di legge e regolamentari, pertanto è possibile che la presente informativa subisca, nel corso del tempo, delle modifiche di aggiornamento. In caso di modifiche significative alla nostra informativa sulla privacy, che alterino notevolmente le nostre prassi in materia di privacy, BiPart avrà cura di avvisarLa anche attraverso altri mezzi, ad esempio inviando un'e-mail o pubblicando un avviso sul nostro sito web e/o sulle pagine dei social media prima che le modifiche entrino in vigore.",
    SOGGETTI : "5. Soggetti a cui potranno essere comunicati i dati personali",
    SOGGETTIDESCRIZIONE1 : "I Suoi dati personali potranno essere oggetto di comunicazione o diffusione:",
    SOGGETTIDESCRIZIONE2 : "<li>alle Amministrazioni Pubbliche, agli Enti Locali e più in generale ai soggetti, anche privati, che aderiscono o prendono parte ai progetti di bilancio partecipativo gestiti da BiPart anche mediante la Piattaforma;</li><li>ai soggetti autorizzati che prestino attività di assistenza e consulenza in materia amministrativa, tecnico/informatica, tributaria, contabile, finanziaria e legale;</li><li>a soggetti terzi in adempimento ad obblighi di legge.</li>",
    SOGGETTIDESCRIZIONE3 : "I predetti soggetti opereranno in qualità di Responsabili o comunque di soggetti autorizzati al trattamento dei Suoi dati personali nei limiti qui da Lei autorizzati. Ai soggetti autorizzati e ai Responsabili, ove nominati, BiPart impartirà adeguate istruzioni operative, al fine di poter garantire la riservatezza e la sicurezza dei Suoi dati personali.",
    SOTTOTITOLO : "Trattamento dei dati personali",
    TIPOLOGIA : "2. Tipologia dei dati raccolti",
    TIPOLOGIADESCRIZIONE1 : "Per creare un account attivo nella piattaforma Lei deve fornire alcuni dati personali che includono il Suo nome, cognome, indirizzo email e password (dati essenziali). La Piattaforma Le consente la possibilità di utilizzare le opzioni di implementazione del Suo profilo integrando e fornendo informazioni ulteriori sulla Sua persona che possono includere, a titolo esemplificativo e non esaustivo, l’indicazione dell’area geografica o la specificazione della località in cui risiede, il titolo di studio, o una Sua fotografia. Parimenti, è possibile che nell’utilizzo della Piattaforma Le sia consentito di condividere la Sua rubrica ed i Suoi contatti. Non è necessario che pubblichi o inserisca tali dati personali, precisiamo comunque che è Sua facoltà scegliere espressamente se, una volta inseriti, tali dati possano essere pubblicamente visibili da altri Membri o meno.",
    TIPOLOGIADESCRIZIONE2 : "E’ dunque rimessa esclusivamente a Lei la scelta di comunicare e inserire nella Piattaforma, e nel Suo profilo, informazioni di natura sensibile (idonei a rivelare l’origine razziale ed etnica, le convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l’adesione a partiti, sindacati, associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale, nonché i dati personali idonei a rivelare lo stato di salute e la vita sessuale), così come pure di renderle pubbliche. La invitiamo quindi a non pubblicare dati che non vuole rendere pubblicamente disponibili, dovendosi precisare che con l’inserimento di tali dati nel Suo account e nella Piattaforma, ne autorizza il trattamento.",
    TITOLO : "Informativa sulla privacy"
  },
  PROCESSABOUT : {
    FASE : "Phase",
    TITOLO : "Der partizipative Prozess"
  },
  PROCESSCONTATTI : {
    CONTATTI : "Kontakte"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "Verfügbares Budget:",
    BUDGETPROPOSTO : "Vorgeschlagenes Budget:",
    CONCLUSA : "Abgeschlossen",
    DAATTIVARE : "Noch zu aktivieren",
    FASIPROCESSO : "Prozess-Schritte",
    INCORSO : "In Bearbeitung",
    ITUOIVOTI : "Ihre Stimmen",
    PROGRESS : "Fortschritt"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : "Fragen & Antworten"
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : "Leitlinien"
  },
  PROCESSLIST : {
    BUDGET : "Budget ",
    DATAINIZIO : "Startdatum",
    DATAINS : "Datum der Einreichung",
    PARTECIPANTI : "Anzahl der Teilnehmer",
    PROCESSI : "Prozesse",
    TITOLO : "Partizipative Prozesse"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "Alle Themen zur Reflexion und Lösungsfindung innerhalb Ihrer Gemeinschaft",
    NOTEMATICHE : "Es gibt keine Themen",
    OBIETTIVI : "Zielsetzungen und Themen für die Organisation"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "Gemeinschaft",
    GRUPPI : "Gruppen",
    TUTTI : "Alle kommunalen Gruppen/Klassen."
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "Kontext der Organisation",
    INFO : "Informationen über das/die vom Prozess betroffene(n) Gebiet(e)",
    NOSCENARI : "Es gibt keinen Kontext"
  },
  PROCESSMENU : {
    ABOUT : "Der partizipative Prozess",
    ASSISTENZA : "Support",
    BACK : "Zurück",
    COMMUNITY : "Community",
    FAQ : "FAQ",
    ISTRUZIONI : "Leitlinien",
    NARRAZIONE : "Weitere Informationen",
    POP1 : "Prozess",
    POP1DESCRIPTION : "Informationen zum Prozess"
  },
  PROCESSPHASE : {
    ALERTBOZZA : "Sie haben einen Entwurf erstellt. Sie finden ihn auf Ihrer Seite",
    BREADCRUMBTITLE : "Phase",
    COMMENTIMENONUMEROSI : "Meno commentate",
    COMMENTIPIUNUMEROSI : "Più commentate",
    CONTATOREMODULI : "Bereiche",
    IDEE : "Vorschläge",
    INFORMAZIONI : "Informationen",
    NAVIGATUTTELEPROPOSTE : "Durchsuchen Sie alle Vorschläge",
    NONCISONOPROPOSTE : "Es gibt keine Vorschläge",
    PS : "Persönlicher Bereich",
    SCEGLIDOVEPARTECIPARE : "Wählen Sie, woran Sie teilnehmen möchten"
  },
  PS : {
    ALERTMODIFICADISABILITATA : "<h4>Es ist nicht möglich, das Profil zu bearbeiten.</h4>",
    ALERTMODIFICADISABILITATATITLE : "<b>Die Bearbeitung Ihres Profils ist deaktiviert</b>",
    ANNUALINCOME : "Jährliches Einkommen",
    ATTEGGIAMENTO : "Einstellung",
    BIOGRAPHY : "Lebenslauf",
    BUDGET : "Finanzplan",
    BUDGETTODONATE : "Budget für Spenden",
    COMPORTAMENTO : "Verhalten",
    EDU : "Bildung",
    EDULAV : "Bildung und Arbeit",
    FEMMINA : "Frauen",
    LAV : "Arbeit",
    MASCHIO : "Männlich",
    MODIFICA : "Profil bearbeiten",
    NS : "Keine Angabe",
    POLVIS : "Politische Vision",
    PROFILE : "Persönliches Profil",
    PS : "Persönlicher Bereich"
  },
  PSACCOUNT : {
    ANONIMO : "Anonymer Benutzer",
    AS : "Konto-Einstellungen",
    CITIZENSHIP : "Staatsbürgerschaft",
    COGNOME : "Nachname",
    COGNOMENONPRESENTE : "Nachname nicht vorhanden",
    ELIMINAACCOUNT : "<h4> Schreiben Sie uns an <a href=\"mailto:support@bipart.it\">support@bipart.it</a> und wir werden Ihre Kontoinformationen entfernen.</h4>",
    ELIMINAACCOUNTTITLE : "<b>Möchten Sie Ihr Konto löschen? <b>.",
    EMAIL : "E-Mail",
    EMAILNONPRESENTE : "E-Mail nicht vorhanden",
    NOME : "Vorname",
    NOMENONPRESENTE : "Name nicht vorhanden",
    PASSWORD : "Kennwort",
    PHONE : "Mobiltelefon",
    PHONENONPRESENTE : "Handy nicht vorhanden",
    PRIVATO : "privat",
    PS : "Persönlicher Bereich",
    PUBBLICO : "öffentlich",
    RESIDENCECITY : "Residenzstadt",
    TAXCODE : "MwSt.-Steuernummer",
    TAXCODENONPRESENTE : "Steuernummer/Mehrwertsteuernummer nicht vorhanden",
    TUOPROFILO : "Ihr Profil ist",
    USERNAME : "Benutzername",
    USERNAMENONPRESENTE : "Benutzername nicht vorhanden"
  },
  PSACTIVITIES : {
    ALERTDETAIL : "<h4>In diesem Abschnitt finden Sie Ihre Vorschläge und die Vorschläge, für die Sie gestimmt haben </h4>.",
    ALERTINFO : "</b>Informationen</b>",
    ATTIVITA : "Aktivitäten",
    BUDGETDONATO : "Gespendetes Budget",
    FOLLOWERS : "Follower",
    IDEESEGUITE : " Inhalte denen Sie folgen",
    IDEEVOTATE : "Beiträge zu denen Sie abgestimmt haben",
    INBOZZA : "Entwurf",
    MODIFICAVOTO : "Ändern Sie Ihre Stimme",
    NOPROCESSI : "Sie haben sich noch an keinem partizipativen Prozess beteiligt!",
    NOPROCESSIATTIVI : "Non ci sono processi attivi",
    PS : "Persönlicher Bereich",
    PUBBLICATA : "Veröffentlicht",
    TUEPROPOSTE : "Ihre Beiträge",
    TUOIPROCESSI : "Ihre partizipativen Prozesse",
    TUOIVOTI : "Ihre Abstimmungen",
    VISUALIZZA : "Ansicht",
    VOTIESPRESSI : "Abgegebene Stimmen"
  },
  PSEDIT : {
    AS : "Konto-Einstellungen",
    CONFERMAPASSWORD : "Bestätigen Sie Ihr Passwort",
    DRAGANDDROP : "Ziehen und ablegen, um Ihren Avatar hochzuladen (maximale Größe 5 MB)",
    ERRORE : "Achtung!",
    ERROREPASS : "Die beiden Passwortfelder stimmen nicht überein",
    FORMSUPPORTATI : "Unterstützte Formate sind JPG, GIF, PNG",
    MODAVATAR : "Avatar bearbeiten",
    MODIFICADATI : "Bearbeiten Sie Ihre persönlichen Informationen in Ihrem Persönlichen Bereich",
    MODOK : "Das Benutzerprofil wurde erfolgreich bearbeitet",
    MODPASS : "Passwort ändern",
    NUOVAPASSWORD : "Neues Passwort",
    PRIVACY : "Privatsphäre & Politik",
    PROFILOPUBBLICO : "Öffentliches Profil",
    PS : "Persönlicher Bereich",
    SALVA : "Speichern",
    SUCCESSO : "Erfolg"
  },
  PSEDIT2 : {
    AS : "Konto-Einstellung",
    ATTEGGIAMENTO : "Einstellung",
    BIOGRAFIA : "Biografie",
    BUDGET : "Haushalt",
    BUDGETDADONARE : "Budget für Spenden",
    COMPORTAMENTO : "Verhalten",
    EDU : "Bildung",
    EDULAV : "Bildung und Arbeit",
    INFOVARIE : "Zusätzliche Informationen",
    LAV : "Arbeit",
    PS : "Persönlicher Bereich",
    REDDITO : "Jährliches Einkommen",
    SALVA : "Speichern",
    VISIONEPOLITICA : "Politische Vision"
  },
  QUICKPANEL : {
    VOTE: "Vote {n}",
    VOTEDON: "Voted on {n}",
    EDITVOTE: "Edit vote",
    DONATION: "Donation"
  },
  QUICKUSER : {
    ACCOUNT : "Konto",
    AGGIORNAPROFILOEPRIVACY : "Aktualisieren Sie Ihr Profil und die Datenschutzbestimmungen",
    IDEEVOTATE : "Alle Ideen, für die Sie gestimmt haben",
    PROCESSIPARTECIPATIVI : "Partizipative Prozesse",
    SALUTO : "Hallo",
    SIGNOUT : "Abmelden",
    VOTI : "Ihre Stimmen"
  },
  REGISTER : {
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    ATTENZIONE : "Achtung",
    BACCEDI : "Eintragen",
    BACCETTO : "Ich habe gelesen und akzeptiere die",
    BREGISTRA : "Anmelden",
    COGNOMEDIMENTICATO : "Sie haben vergessen, Ihren Nachnamen einzugeben",
    GIAACCOUNT : "Sie haben bereits ein Konto?",
    MAILDIMENTICATA : "Sie haben vergessen, Ihre E-Mail-Adresse einzugeben",
    MAILNONVALIDA : "Ungültige E-Mail Adresse",
    NEWSLETTER : "Halten Sie mich über die Projekte und Initiativen von BiPart auf dem Laufenden",
    NOMEDIMENTICATO : "Sie haben vergessen, Ihren Namen einzugeben",
    PASSWORDDIMENTICATA : "Sie haben vergessen, Ihr Passwort einzugeben",
    PLACEHOLDERCOGNOME : "Nachname",
    PLACEHOLDEREMAIL : "E-Mail",
    PLACEHOLDERNOME : "Name",
    PLACEHOLDERPASSWORD : "Kennwort",
    PLACEHOLDERUTENTE : "Benutzername",
    PRIVACY : "Datenschutzrichtlinien",
    TERMINICONDIZIONI : "Bedingungen und Konditionen",
    USERNAMEDIMENTICATO : "Sie haben vergessen, Ihren Benutzernamen einzugeben",
    USERNAMEFORBIDDENCHARACTERS : "Der Benutzername darf die folgenden Zeichen nicht enthalten: @",
    USERNAMEMINLENGTH : "der Benutzername muss mindestens 3 Zeichen lang sein"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "Ich habe gelesen und akzeptiere die",
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    BCONFERMA : "Bestätigen",
    DETTMEX : "Wir haben eine E-Mail* an Ihren Posteingang geschickt .<br><span class=\"font-weight-bolder\">Confirm it</span> to join the organization.</p><p class=\"font-weight-bolder\">* Überprüfen Sie auch Ihren Spam-Ordner.",
    ERR1 : "Sie haben die erforderlichen Daten nicht eingegeben. Bitte versuchen Sie es erneut oder schreiben Sie an target  <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR2 : "Oops...es ist ein Fehler aufgetreten; Status code:",
    GIAACCOUNT : "Haben Sie bereits ein Konto?",
    GRAZIE : "Vielen Dank für Ihre Anmeldung",
    ISCRIVITIPROCESSO : "Melden Sie sich für den Prozess an",
    NEWSLETTER : "Halten Sie mich über BiPart-Projekte auf dem Laufenden",
    PRIVACY : "Datenschutzrichtlinien",
    REQUIREDFIELD : "Plichtfeld",
    TERMINI : "Geschäftsbedingungen",
    TITLE2 : "Achtung",
    TITLE3 : "Fehler",
    VALIDEMAIL : "Gültige Emailadresse erforderlich"
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    BACCEDI : "Anmelden",
    BACCETTO : "Ich habe gelesen und akzeptiere die",
    BCHIUDI : "Schliessen",
    BREGISTRA : "Anmelden",
    COGNOMEDIMENTICATO : "Sie haben vergessen, Ihren Nachnamen einzugeben",
    ERR1 : "Oops...es ist ein Fehler aufgetreten; Status code:",
    FAIGIAPARTE : "Sind Sie bereits angemeldet und erfüllen Sie die Teilnahmevorrausetzungen?",
    ISCRIVITIPROCESSO : "Sign up for the organisation and for the process",
    MAILNONVALIDA : "Ungültige E-Mailadresse",
    NEWSLETTER : "Ich möchte über BiPart-Projekte und aktive Initiativen auf dem Laufenden gehalten werden",
    NOMEDIMENTICATO : "Sie haben vergessen, Ihren Namen einzugeben",
    PASSWORDDIMENTICATA : "Sie haben vergessen, Ihr Passwort einzugeben",
    PRIVACY : "Datenschutzrichtlinien",
    TERMINICONDIZIONI : "Geschäftsbedingungen",
    TITLE1 : "Fehler",
    USERNAMEDIMENTICATO : "Sie haben vergessen, Ihren Benutzernamen einzugeben"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "Erfolgreiche Anmeldung",
    ACCETTO : "Ich habe gelesen und akzeptiere die",
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    AUTORIZZATO : "Sie sind berechtigt, an unserer Community teilzunehmen",
    BCONFERMA : "Bestätigung",
    DETTMEX : "Wir haben eine E-Mail* an Ihren Posteingang geschickt <br><span class=\"font-weight-bolder\">Confirm it</span> um der Organisation beizutreten </p><p class=\"font-weight-bolder\">*  Überprüfen Sie auch Ihren Spam-Ordner.",
    ERR1 : "Sie haben die erforderlichen Daten nicht eingegeben. Bitte versuchen Sie es erneut oder schreiben Sie an target  <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR3 : "Oops... Es ist ein unerwarteter Fehler aufgetreten.",
    ERR4 : "Ungültige E-Mail",
    ERR5 : "Anmeldung und Akzeptanz der \"Datenschutzrichtlinie\" und der \"Allgemeinen Geschäftsbedingungen\".",
    ES : "Ex: Faenza Teilnahme 2020/21",
    GIAACCOUNT : "Sie haben bereits ein Konto?",
    GRAZIE : "Danke, dass Sie sich angemeldet haben ",
    HTMLMAIL : "Hier finden Sie html mail",
    ISCRIVITIPROCESSO : "Melden Sie sich für den Prozess an",
    NEWSLETTER : "Halten Sie mich über BiPart-Projekte auf dem Laufenden",
    NIENTEACCOUNT : "Sie haben noch kein Konto?",
    NOME : "Name(optional)",
    ORGANIZATION : "BiPart",
    PARTECIPAPROCESSO : "Möchten Sie an dem Prozess teilnehmen?",
    PRIVACY : "Datenschutzrichtlinien",
    PROPOSTE : "Um Beiträge einzureichen, Kommentare abzugeben, Bewertungen anzufordern und abzustimmen, müssen Sie sich für den Prozess anmelden.",
    QUIMAIL : "Hier finden Sie eine html-E-Mail, die Ihnen zugesandt wird",
    REGISTRATI : "Anmeldung und Zustimmung zur \"Datenschutzrichtlinie\" und zu den \"Allgemeinen Geschäftsbedingungen\".",
    TERMINI : "Geschäftsbedingungen",
    TITLE1 : "Herzlichen Glückwunsch",
    TITLE2 : "Achtung",
    TITLE3 : "Fehler",
    TITOLO : "Bipart"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "Anmelden",
    ACCETTO : "Ich habe gelesen und akzeptiere die",
    ACCETTOLA : "Ich habe gelesen und akzeptiere die",
    BPROSEGUI : "Weiter",
    CAMBIAPASS : "Passwort ändern",
    ERR3 : "Oops... Es ist ein unerwarteter Fehler aufgetreten.",
    ERR4 : "Ungültige Mail",
    ERR5 : "Ungültige Anmeldedaten",
    INSERISCIMAIL : "Geben Sie Ihre E-Mail ein",
    ISCRIVITIPROCESSO : "Registrieren Sie sich für den Prozess",
    MEX1 : "Anmeldung und Akzeptanz der \"Datenschutzrichtlinie\" und der \"Allgemeinen Geschäftsbedingungen\".",
    MEX2 : "",
    MEXLOGIN : "<b>Wilkommen auf BiPart, </b><br>die Plattform für Bürgerbeteiligung. Ihre Daten werden unter voller Einhaltung der italienischen und europäischen Vorschriften behandelt, um Ihre Meinung und Ihre Entscheidungen zu schützen.<br><b> Wenn Sie fortfahren möchten, lesen und akzeptieren Sie die beiden folgenden Dokumente.</b>",
    NIENTEACCOUNT : "Sie haben noch kein Konto?",
    NONRICORDIPASS : "Passwort vergessen?",
    NOTIFICHE : "*Wenn Sie Benachrichtigungen über den Fortschritt der eingereichten Vorschläge erhalten möchten.",
    PRIVACY : "Datenschutzrichtlinien",
    REGISTRATI : "Registrieren",
    TERMINI : "Geschäftsbedingungen",
    TERMINICONDIZIONI : "Um fortzufahren, lesen Sie bitte die <a href=\"https://bipart.it/terms\" target=\"blank\"> Terms and Conditions of Service </a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Erfolg",
    TITLE2 : "Achtung",
    TITLE3 : "Fehler",
    UTENTEAUTENTICATO : "Authentifizierter Benutzer"
  },
  SERVICEDETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato al servizio",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questo servizio",
    ALERT14 : "Il servizio è già stato valutato",
    ALERT3TITLE : "Grazie per aver votato questo servizio",
    ALERT4 : "Naviga tra i servizi e scegli quali altre preferisci di più",
    ALERT5 : "Puoi continuare a donare anche per altri servizi scegliendo come distribuire il tuo budget a disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto il servizio!",
    ALERT6TITLE : "Stai seguendo questo servizio!",
    ALERT7TITLE : "Non stai più seguendo questo servizio",
    ALERT8TITLE : "Sei sicuro di voler cancellare questo servizio?",
    ALERT9 : "Il servizio è stato eliminato",
    ALERT9TITLE : "Eliminazione servizio",
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Ricordati di pubblicarla",
    ALERTPUBBLICATA : "servizio in fase di revisione. Collabora al suo sviluppo",
    AUTORE : "Referente",
    CANVERSION2 : "Stai creando la versione {n} del tuo servizio",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questo servizio",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the service does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the service because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questo servizio!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questo servizio!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questo servizio!",
    IDEAVOTATA : "servizio votato",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questo servizio",
    NARRAZIONE : "Il servizio",
    PREFERENZEPERPREMIARE : "preferenze per premiare i servizi migliori!",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altri servizi, scegliendo come distribuire il tuo budget.",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altri servizi.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per gli altri servizi",
    VERSIONIPROPOSTA : "Versioni del servizio:",
    WALLETSOSTIENIIDEA : "Sostieni questo servizio. Fai una donazione!"
  },
  SERVICELIST : {
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING2 : "È possibile migliorare un servizio fornendo o raccogliendo spunti e descrivendolo tramite nuove versioni sempre più aggiornate",
    CREAIDEA : "Pubblica il tuo servizio",
    ELENCOIDEE : "Tutti i servizi",
    IDEAVOTATA : "Hai votato questo servizio! ",
    IDEE : "servizi",
    NONCISONOIDEE : "Non è presente ancora alcun servizio. Pubblica il tuo!",
    VOTAZIONEATTIVA2 : "Guarda tutti i servizi ed esprimi le tue preferenze",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare i tuoi servizi preferiti"
  },
  STORYDETAIL : {
    ALERT1 : "Sie müssen mindestens eines der Pflichtfelder eingeben",
    ALERT13 : "Sie haben bereits eine detaillierte Bewertung für diese Meldung eingegeben",
    ALERT14 : "Die Geschichte ist bereits bewertet",
    ALERT3TITLE : "Vielen Dank für die Bewertung dieser Geschichte",
    ALERT4 : "Blättern Sie durch die Geschichten und wählen Sie Ihren Favoriten",
    ALERT5 : "Sie können weiterhin für andere Geschichten spenden, indem Sie wählen, wie Sie das Ihnen zur Verfügung stehende Budget verteilen.",
    ALERT5TITLE : "Danke für die Unterstützung dieser Geschichte",
    ALERT6TITLE : "Sie folgen diese Geschichte!",
    ALERT7TITLE : "Du folgst dieser Geschichte nicht mehr",
    ALERT8TITLE : "Sind Sie sicher, dass Sie diese Geschichte löschen möchten?",
    ALERT9 : "Die Geschichte ist gelöscht",
    ALERT9TITLE : "Geschichte gelöscht",
    ALERTBOZZA : "Sie haben eine Entwurfsversion Ihrer Geschichte. Denken Sie daran, sie zu veröffentlichen",
    ALERTPUBBLICATA : "Geschichte in Bearbeitung. Helfen Sie, sie zu verbessern",
    AUTORE : "Written by",
    CANVERSION2 : "Sie erstellen Version {n} Ihrer Geschichte",
    CLICCAILPULSANTE : "Klicke auf die Schaltfläche, wenn du für diese Geschichte stimmen möchtest.",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the story does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the story because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Danke, dass du diese Geschichte gewählt hast!",
    GRAZIESOSTEGNO : "Danke für die Unterstützung dieser Geschichte!",
    GRAZIESOSTEGNOANCORA : "Danke für deine Unterstützung dieser Geschichte!",
    IDEAVOTATA : "Gewählte Geschichte",
    INSERISCICIFRA : "Geben Sie den Betrag ein, den Sie für diese Geschichte spenden möchten.",
    NARRAZIONE : "Die Geschichte",
    PREFERENZEPERPREMIARE : "Stimme ab, um die besten Geschichten zu belohnen!",
    SOSTIENIALTREIDEE : "Sie können weiterhin für andere Geschichten spenden, indem Sie wählen, wie Sie Ihr Restbudget aufteilen möchten.",
    TOGLIEREPREFERENZA : "Wenn Sie Ihre Meinung geändert haben, können Sie Ihre Präferenz löschen und für andere Geschichten stimmen.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Ziehen Sie in Erwägung, die bereits getätigten Spenden für andere Geschichten zu reduzieren",
    VERSIONIPROPOSTA : "Versionen der Geschichte:",
    WALLETSOSTIENIIDEA : "Unterstützen Sie diese Geschichte. Machen Sie eine Spende!"
  },
  STORYLIST : {
    ALERTBOZZA : "Sie haben eine  Geschichte begonnen. Sie können sie von Ihrer Seite abrufen.",
    ALERTVERSIONING2 : "Sie können die Geschichten mit neuen Erfahrungen und Erinnerungen aktualisieren",
    CREAIDEA : "Erzählen Sie Ihre Geschichte",
    ELENCOIDEE : "Alle Geschichten",
    IDEAVOTATA : "Du hast für diese Geschichte gestimmt!",
    IDEE : "Geschichten",
    NONCISONOIDEE : "Es wurde noch keine Geschichte veröffentlicht. Erzählen Sie uns Ihre!",
    VOTAZIONEATTIVA2 : "Sieh dir alle Geschichten zur Abstimmung an und wähle die, die dir am besten gefallen.",
    VOTAZIONEATTIVAUTENTEANONIMO : "Melden Sie sich an. Sobald Sie freigeschaltet sind, können Sie für Ihre Lieblingsgeschichten stimmen."
  },
  TACTICDETAIL : {
    ALERT1 : "Füllen Sie mindestens eines der Pflichtfelder zwischen dem Status oder der Bewertung der Taktik aus.",
    ALERT13 : "Sie haben bereits eine detaillierte Bewertung für diese Taktik eingegeben",
    ALERT14 : "Die Taktik wurde bereits bewertet",
    ALERT3TITLE : "Danke, dass Sie für diese Taktik gestimmt haben",
    ALERT4 : "Blättern Sie durch die Taktiken und wählen Sie die Taktik aus, für die Sie stimmen möchten",
    ALERT5 : "Sie können weiterhin für andere Taktiken spenden, indem Sie wählen, wie Sie Ihr Budget aufteilen möchten",
    ALERT5TITLE : "Danke, dass Sie diese Taktik unterstützen!",
    ALERT6TITLE : "Sie folgen dieser Taktik!",
    ALERT7TITLE : "Sie unterstützen diese Taktik nicht mehr",
    ALERT8TITLE : "Sind Sie sicher, dass Sie diese Taktik löschen möchten?",
    ALERT9 : "Die Taktik wurde entfernt",
    ALERT9TITLE : "Taktik entfernt",
    ALERTBOZZA : "Sie haben einen Entwurf der Taktik erstellt. Denken Sie daran, sie zu veröffentlichen",
    ALERTPUBBLICATA : "Taktik wird überprüft. Arbeiten Sie an der Entwicklung mit",
    AUTORE : "",
    CANVERSION2 : "Sie erstellen gerade die {n} Version der Taktik",
    CLICCAILPULSANTE : "Klicken Sie auf die Schaltfläche, wenn Sie für diese Taktik stimmen möchten.",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the tactic does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the tactic because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Danke, dass Sie diese Taktik gewählt haben!",
    GRAZIESOSTEGNO : "Danke, dass Sie diese Taktik unterstützen!",
    GRAZIESOSTEGNOANCORA : "Danke, dass Sie die Taktik weiterhin unterstützen!",
    IDEAVOTATA : "Gewählte Taktik",
    INSERISCICIFRA : "Bitte geben Sie den Betrag ein, den Sie für diese Taktik spenden möchten",
    NARRAZIONE : "Die Taktik",
    PREFERENZEPERPREMIARE : "Klicken Sie auf die Schaltfläche, wenn Sie für diese Taktik stimmen möchten.",
    SOSTIENIALTREIDEE : "Danke, dass Sie diese Taktik gewählt haben!",
    TOGLIEREPREFERENZA : "Danke, dass Sie diese Taktik unterstützen!",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Danke, dass Sie die Taktik weiterhin unterstützen!",
    VERSIONIPROPOSTA : "Gewählte Taktik",
    WALLETSOSTIENIIDEA : "Bitte geben Sie den Betrag ein, den Sie für diese Taktik spenden möchten"
  },
  TACTICLIST : {
    ALERTBOZZA : "Die Taktik",
    ALERTVERSIONING2 : "Klicken Sie auf die Schaltfläche, wenn Sie für diese Taktik stimmen möchten.",
    CREAIDEA : "Schreiben Sie eine Taktik",
    ELENCOIDEE : "Alle Taktiken",
    IDEAVOTATA : "Sie haben für diese Taktik gestimmt!",
    IDEE : "Taktiken",
    NONCISONOIDEE : "Es gibt noch keine Taktiken, seien Sie der Erste, der eine veröffentlicht!",
    VOTAZIONEATTIVA2 : "Blättern Sie durch die Taktiken und stimmen Sie für Ihren Favoriten",
    VOTAZIONEATTIVAUTENTEANONIMO : "Melden Sie sich an, wenn Sie stimmberechtigt sind, dann können Sie für Ihre Lieblingstaktiken stimmen"
  },
  TERMS : {
    ATTIVITA : "3. ATTIVITA’",
    ATTIVITADESCR1 : "Oggetto delle attività dell'Utente è qualsiasi iniziativa volta a contribuire alla vita, allo sviluppo e al benessere della propria città o ente di appartenenza;\nA titolo esemplificativo, le attività possono riguardare: l’inserimento di idee e segnalazioni, la discussione, la progettazione, la condivisione di idee, di link e di documenti, la votazione di idee e progetti e temi pubblici, eccetera.\nL’attività svolta deve attenersi ad argomenti di pubblica rilevanza e svolgersi nel pieno rispetto della normativa vigente.\nL'Utente dichiara di essere titolare di ogni diritto eventualmente connesso alla propria attività (a titolo meramente esemplificativo e non esaustivo: idee, progetti, fotografie, filmati audio/ video etc.).\nBiPart si riserva il diritto di eliminare dalla Piattaforma BiPart in maniera insindacabile qualsiasi elemento introdotto dall’Utente, come pure modificare o oscurare elementi non essenziali dello stesso qualora essa possa ledere il diritto di soggetti terzi o di una collettività di persone.\nL'Utente registrato accetta di partecipare anche alla eventuale realizzazione di classifiche inerenti alle attività, nel pieno rispetto della normativa sulla privacy (a titolo meramente esemplificativo e non esaustivo: classifiche per numero, tipologia e qualità delle proposte, commenti, etc.).\nL'Utente registrato accetta che le proprie attività possano essere pubblicate da BiPart anche su altri siti web non necessariamente di proprietà di BiPart (a titolo esemplificativo non esaustivo: Facebook, Twitter etc.) ma sempre e solo per le finalità legate al progetto culturale alla base della “Piattaforma BiPart”.",
    COMUNICAZIONI : "7. COMUNICAZIONI",
    COMUNICAZIONIDESCR1 : "L'Utente prende atto ed accetta che tutte le eventuali comunicazioni, notificazioni, informazioni e comunque ogni documentazione relativa alle operazioni eseguite e riferite all'utilizzo della “Piattaforma BiPart” verranno inviate all’indirizzo di posta elettronica ovvero agli eventuali ulteriori recapiti indicati dall'Utente durante la procedura di registrazione.",
    LIMITAZIONI : "6. LIMITAZIONI DI RESPONSABILITA'",
    LIMITAZIONIDESCR1 : "BiPart non risponde dei danni diretti, indiretti o consequenziali subiti dall'Utente o da terzi in dipendenza delle attività svolte all’interno della “Piattaforma BiPart” e/o per l'utilizzo dei Servizi di Comunicazione e/o per danni di qualsiasi genere o a qualsiasi titolo connessi con dette attività e a tal fine l'Utente dichiara di manlevare BiPart da ogni forma di responsabilità. Il materiale inviato dall'Utente non sarà più restituito.",
    MODIFICA : "8. MODIFICA DELLE CLAUSOLE DI UTILIZZO",
    MODIFICADESCR1 : "BiPart si riserva il diritto di modificare i termini, le condizioni, e le comunicazioni ai sensi dei quali viene offerta la “Piattaforma BiPart” ed a cui saranno tenuti a conformarsi anche gli Utenti già registrati.",
    OGGETTO : "1. OGGETTO E DEFINIZIONI",
    OGGETTODESCR1 : "Le presenti Condizioni d'Uso (CdU) regolano e disciplinano l'utilizzo della Piattaforma BiPart da parte dell'Utente nonché le responsabilità di quest'ultimo relativamente all'utilizzo della piattaforma anzidetta.Per BiPart si intende una piattaforma tramite la quale l'Utente può partecipare alle attività rese possibili dalla piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione, eccetera). Detta piattaforma è di esclusiva proprietà di BiPart Impresa sociale srl (già BiPart Srls) (BiPart) con sede in Milano, Via Metauro, 4 (codice fiscale e partita IVA: 09444470968).\nPer Utente si intende la persona fisica registrata ai sensi dell'art. 2 che interagisce con gli strumenti partecipativi messi a disposizione sulla Piattaforma BiPart.<br>Le presenti clausole di utilizzo si applicano ad ogni tipo di attività svolta dall’Utente.",
    REGISTRAZIONE : "2. REGISTRAZIONE",
    REGISTRAZIONEDESCR1 : "Per poter partecipare secondo quanto indicato all'articolo precedente, l'Utente deve necessariamente registrarsi tramite l'apposita procedura di registrazione;\nL'Utente si assume ogni responsabilità civile e penale per l’eventuale falsità o non correttezza delle informazioni e dei dati comunicati.",
    RESPONSABILITA : "4. RESPONSABILITA' DELL'UTENTE",
    RESPONSABILITADESCR1 : "L'Utente si assume ogni responsabilità nonché ogni conseguenza diretta o indiretta derivante dalle attività svolte e pubblicate sulla piattaforma web, nonché riconosce la propria esclusiva responsabilità per eventuali danni cagionati a terzi (a titolo meramente esemplificativo e non esaustivo, per la violazione di diritti d’autore o altri diritti di proprietà, diritti relativi alla riservatezza delle persone, etc.).\nL'Utente si impegna non inserire materiale o estratti di materiale:",
    RESPONSABILITADESCR2 : "<li>coperti da diritto d'autore e di cui non sia esso stesso titolare;</li><li>contrari alla morale e l'ordine pubblico, ovvero in grado di turbare la quiete pubblica o privata o di recare offesa, o danno diretto o indiretto a chiunque o ad una specifica categoria di persone (per esempio è vietato l’inserimento di materiali o estratti di materiale che possano ledere la sensibilità di gruppi etnici o religiosi, etc.);</li><li>contrario al diritto alla riservatezza di soggetti terzi; • lesive dell'onore, del decoro o della reputazione di soggetti terzi; \n</li><li> comunque contrari alla legge.</li>",
    SOTTOTITOLO : "Condizioni d'uso della piattaforma",
    TITOLO : "Termini e condizioni",
    UTILIZZO : "UTILIZZO",
    UTILIZZODESCR1 : "La “Piattaforma BiPart” potrà contenere ulteriori servizi di comunicazione quali bacheche elettroniche, aree chat, newsgroup, pagine web personali, calendari, e/o altri servizi creati per abilitare l’utente a comunicare con altri soggetti o con un gruppo (collettivamente, \"Servizi di Comunicazione\"). L’Utente accetta di usare i Servizi di Comunicazione solamente per spedire, inviare e ricevere messaggi e materiale appropriato e relativo al particolare Servizio di Comunicazione. A titolo di esempio, e non come limitazione, durante l’uso di un Servizio di Comunicazione, l’utente accetta di, e si impegna a, non:",
    UTILIZZODESCR2 : "<li>pubblicare, inviare, distribuire o disseminare nomi, materiali o informazioni non appropriati, profanatori, diffamatori, che violino i diritti altrui, osceni, indecenti o illegali;</li><li>pubblicare file che contengono software o altro materiale protetto da leggi di proprietà intellettuale (o dai diritti di privacy di pubblicità) a meno che l’utente sia titolare di tali diritti o ne possieda il controllo o abbia ricevuto tutti i consensi necessari;</li><li>pubblicare file che contengono virus, file danneggiati, o altri software o programmi simili che potrebbero danneggiare l’operatività dei computer di altri;</li><li>pubblicizzare o offrire per la vendita o comprare qualsiasi prodotto o servizio per qualunque scopo commerciale, salvo il caso in cui tale Servizio di Comunicazione permetta specificamente tali messaggi; </li><li>condurre o inoltrare sondaggi, contenuti, diagrammi a piramide o lettere a catena;</li><li>scaricare qualunque file inviato da un altro utente di un Servizio di Comunicazione che l’utente sappia, o ragionevolmente dovrebbe sapere, che non può essere legalmente distribuito in tale maniera;</li><li>falsificare o cancellare qualunque attribuzione d’autore, le comunicazioni di contenuto legale o altre comunicazioni appropriate o designazioni di proprietà o denominazioni d’origine o fonte del software o altro materiale contenuto in un file che è caricato;</li><li>limitare o inibire ad altri utenti l’utilizzo e il godimento dei Servizi di Comunicazione;</li><li>violare codici di condotta o altre modalità d’utilizzo che potrebbero essere applicabili per qualunque particolare Servizio di Comunicazione;</li><li>raccogliere o mettere insieme informazioni di altri, incluso indirizzi e-mail, senza il loro consenso; </li><li>violare qualunque legge o regolamento applicabile.</li>",
    UTILIZZODESCR3 : "BiPart non ha l’obbligo di monitorare i Servizi di Comunicazione. Tuttavia, BiPart si riserva il diritto di visionare i materiali inviati tramite uno dei Servizi di Comunicazione e di togliere qualunque materiale a sua unica ed insindacabile discrezione. BiPart si riserva il diritto di sospendere l’accesso dell’utente ad uno, a più o atutti i Servizi di Comunicazione in qualunque momento senza preavviso per qualunque motivo. I materiali pubblicati su di uno dei Servizi di Comunicazione possono essere soggetti a limitazioni impostate sul loro utilizzo, riproduzione e/o disseminazione. L’Utente è responsabile per avere aderito a tali limitazioni qualora scarichi i materiali."
  },
  TRANSLATOR : {
    SELECT : "Wählen Sie Ihre Sprache"
  },
  USERDETAIL : {
    BIOGRAFIA : "Biografie",
    COMMUNITY : "Community",
    EDUCAZIONE : "Bildung",
    EDULAVORO : "Ausbildung und Beruf",
    ETA : "Alter",
    F : "Weiblich",
    LAVORO : "Beruf",
    M : "Männlich",
    PROPOSTEEFFETTUATE : "Meine Beiträge",
    UTENTE : "Nutzer"
  }
};
