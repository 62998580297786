import moment from "moment";
// import Vue from "vue";
// import ApiService from "@/core/services/api.service";

// action types
export const SET_CURRENT_PROCESS = "setCurrentProcess";
// export const GET_CURRENT_PHASE = "getCurrentPhase";
// export const GET_PROCESS = "getProcess";

const CURRENT_PROCESS_DATA = "current_process_data";

const state = {
  currentProcess: []
};

const getters = {
  /**
   * Get current process
   * @param state
   * @returns {*}
   */
  getCurrentProcess: state => {
    return state.currentProcess;
  },
  getPercentage: state => {
    let d0 = moment(new Date()).format("YYYYMMDD");
    let d1 = moment(state.currentProcess.startDate).format("YYYYMMDD");
    let d2 = moment(state.currentProcess.endDate).format("YYYYMMDD");

    if (d0 > d2) {
      return 100;
    }
    if (d0 < d1) {
      return 0;
    }

    let range = d2 - d1;
    let fromnow = moment(new Date()).format("YYYYMMDD") - d1;
    let perc = Math.round((fromnow * 100) / range);
    return perc;
  },
  getCurrentPhase: state => currentPhaseId => {
    let currentPhase;
    for (let phases of state.currentProcess.processPhases) {
      if (phases.id == currentPhaseId) {
        currentPhase = phases;
        break;
      }
    }
    return currentPhase;
  }
};

const actions = {
  // [SET_CURRENT_PROCESS](state, payload) {
  //   state.commit(SET_CURRENT_PROCESS, payload);
  //
  //   // Salvo i dati del processo corrente nel localStorage
  //   let current_process = {
  //     "process_id": payload.id,
  //     "data": payload
  //   }
  //   window.localStorage.setItem(CURRENT_PROCESS_DATA, JSON.stringify(current_process));
  //
  // },

  [SET_CURRENT_PROCESS](context, payload) {
    context.commit(SET_CURRENT_PROCESS, payload);

    // Salvo i dati del processo corrente nel localStorage
    let current_process = {
      "process_id": payload.id,
      "time": moment(),
      "is_active": true,
      "data": payload
    }
    window.localStorage.setItem(CURRENT_PROCESS_DATA, JSON.stringify(current_process));

    // // Salvo i dati del processo corrente nel localStorage
    // // let current_process = {
    // //   "process_id": payload.id,
    // //   "data": payload
    // // }
    // // window.localStorage.setItem(CURRENT_PROCESS_DATA, JSON.stringify(current_process));
    // context.commit(SET_CURRENT_PROCESS, payload);

    // return new Promise((resolve, reject) => {
    //   ApiService.get(
    //       Vue.prototype.$apiResource,
    //       "processes-expanded/" + payload.process_id + "/locs/" + payload.lang
    //   )
    //       .then(res => {
    //         console.log('Processo from API nel modulo: ',res.data );
    //         // this.$store.dispatch(SET_CURRENT_PROCESS, res.data);
    //         context.commit(SET_CURRENT_PROCESS, res.data);
    //         resolve(res.data);
    //       })
    //       .catch(() => {
    //         reject(0);
    //       });
    // });

  },

  // [GET_PROCESS](state, payload) {
  //   return new Promise((resolve, reject) => {
  //     ApiService.get(
  //         Vue.prototype.$apiResourceOpen,
  //         "processes-expanded/" + payload + "/locs/it")
  //       .then(res => {
  //         console.log(res);
  //         let currentProcess = res.data;
  //         state.commit(SET_CURRENT_PROCESS, currentProcess);
  //         return process;
  //       })
  //       .catch(() => {
  //         // .catch(({ response }) => {
  //         //   console.log(response);
  //         // context.commit(SET_ERROR, [response.data.error]);
  //         reject(0);
  //       });
  //   });
  // }
};

const mutations = {
  [SET_CURRENT_PROCESS](state, payload) {
    state.currentProcess = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
