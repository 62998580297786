import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./core/services/store";
import ApiService from "./core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import i18nService from "@/core/services/i18n.service";
import { validationConditions } from "./mixins/validationConditions";

// import MockService from "./core/mock/mock.service";
import {
  ANONYMOUS_LOGIN,
  GET_REFRESHED_TOKEN,
  REFRESH_TOKEN,
  SET_HEADER_TOKEN,
  VERIFIY_MANDATORY_FLAGS,
} from "./core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { SET_ORGANIZATION } from "@/core/services/store/organization.module";

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
//import VueFroala from 'vue-froala-wysiwyg'
import VueSilentbox from "vue-silentbox";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import loadingscreen from "vuejs-loading-screen";
// import Flicking from "@egjs/vue-flicking";

//Import Froala Editor
//import 'froala-editor/js/plugins.pkgd.min.js';
//Import third party plugins
//import 'froala-editor/js/third_party/embedly.min';
//import 'froala-editor/js/third_party/font_awesome.min';
//import 'froala-editor/js/third_party/spell_checker.min';
//import 'froala-editor/js/third_party/image_tui.min';

// Vue 3rd party plugins
import i18n from "./core/plugins/vue-i18n";
import vuetify from "./core/plugins/vuetify";
import "./core/plugins/portal-vue";
import "./core/plugins/bootstrap-vue";
import "./core/plugins/perfect-scrollbar";
import "./core/plugins/highlight-js";
import "./core/plugins/inline-svg";
import "./core/plugins/apexcharts";
import "./core/plugins/metronic";

import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/themes/bipart/theme-bipart.css";
// import "@egjs/vue-flicking/dist/flicking.css";

import VueGeolocation from "vue-browser-geolocation";
import * as GmapVue from "gmap-vue";

Vue.config.productionTip = false;

window.PerfectScrollbar = PerfectScrollbar;
window.ClipboardJS = ClipboardJS;

// Liberia per la gestione delle date
Vue.use(loadingscreen, {
  bg: "rgba(29, 30, 45, .900)",
  size: 3,
  icon_color: "white",
});
Vue.use(require("vue-moment"));
Vue.use(VueGeolocation);
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyBxVox2FgBEoGMt6YwMPY2C_RAJreb68pY",
    libraries: "places",
    installComponents: false,
    region: i18nService.getActiveLanguage().toUpperCase(),
    language: i18nService.getActiveLanguage(),
  },
});
//Vue.use(VueFroala)
Vue.use(VueSilentbox);
// Vue.use(Flicking);
Vue.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

// Social sharing
import VueSocialSharing from "vue-social-sharing";
Vue.use(VueSocialSharing);

import VueHead from "vue-head";
Vue.use(VueHead, {
  separator: "",
  // complement: 'My Complement'
});

// Get current host/domain
const host = window.location.host;

// Variabili globali
// Vue.prototype.$baseUrl = "http://18.185.45.70:8080/pbplatform"; // Staging
Vue.prototype.$baseUrl = "https://api.bipart.it/pbplatform"; // Production
Vue.prototype.$apiResource = "/api/v1";
Vue.prototype.$apiResourceOpen = "/api/v1";
Vue.prototype.$currentHost = host;

//Vue.prototype.$currentDomain = 'www.mixthebubbles.eu';
// Vue.prototype.$currentDomain = "stage.mtb.bipart.it";
//Vue.prototype.$currentDomain = 'mtb.bipart.it';
//Vue.prototype.$currentDomain = 'darsenaravenna.bipart.it';
// Vue.prototype.$currentDomain = 'stage.isiszanussi.bipart.it';
//Vue.prototype.$currentDomain = 'savoiabenincasa.bipart.it';
//Vue.prototype.$currentDomain = 'iispertinifalcone.bipart.it';
//Vue.prototype.$currentDomain = 'itsfilangieri.bipart.it';
// Vue.prototype.$currentDomain = 'stage.sdmp.bipart.it';
//Vue.prototype.$currentDomain = 'oibp.bipart.it';
//Vue.prototype.$currentDomain = 'www.partecipo-romagnafaentina.it';
//Vue.prototype.$currentDomain = 'stage.urf.bipart.it';
//Vue.prototype.$currentDomain = 'stage.campibisenzio.bipart.it';
// Vue.prototype.$currentDomain = "stage.federcoopromagna.bipart.it";
//Vue.prototype.$currentDomain = "stage.curopoli.bipart.it";
// Vue.prototype.$currentDomain = "stage.dare.bipart.it";

//Vue.prototype.$currentDomain = 'sdmp.bipart.it';
Vue.prototype.$currentDomain = host.split(":")[0];
Vue.prototype.$defaultCoordinates = { lat: 41.91, lng: 12.4659 };
Vue.prototype.pointToCoordinates = (point) => {
  let firstPass = point.replace("(", "");
  let secondPass = firstPass.split(" ");
  let coordinates = {
    lat: parseFloat(secondPass[2]),
    lng: parseFloat(secondPass[1]),
  };
  return coordinates;
};

Vue.prototype.coordinatesToPoint = (coordinates) => {
  return "POINT (" + coordinates.lng + " " + coordinates.lat + ")";
};

Vue.prototype.$maxDurationTimeProcess = 1000; // 300000 = 5 minuti

Vue.prototype.generateUrl = function (endpoint, locs = false, slash = false) {
  var add_slash = slash ? "/" : "";
  var r_endpoint = endpoint;
  r_endpoint += locs
    ? "/locs/" + i18nService.getActiveLanguage() + add_slash
    : add_slash;
  return r_endpoint;
};

Vue.prototype.getEmbededUrl = function (url) {
  var url_to_embeded = url;

  if (url.includes("<iframe")) {
    url_to_embeded = url.split('src="').pop().split('"')[0];
  }

  var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var match = url_to_embeded.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  } else {
    return "error";
  }
};

Vue.prototype.$imageOptions = {
  defaultLogoUrl: "/assets/media/bipart/logo-default.svg",
  defaultGroupUrl: "/assets/media/bipart/group-avatar-default2.svg",
  defaultAvatarUrl: "/assets/media/bipart/avatar-default2.svg",
  styleSettings: function ({
    width = 50,
    height = 50,
    userImage = this.defaultAvatarUrl,
  }) {
    return `display: inline-block; height: ${height}px; width: ${width}px; background: url(${
      userImage ? userImage : this.defaultAvatarUrl
    }) center no-repeat; background-size: cover`;
  },
};

Vue.prototype.$ideaForm = {
  title: {
    maxLength: 60,
    limitBeforeAlert: 10,
  },
  description: {
    maxLength: 600,
    limitBeforeAlert: 100,
  },
};

// #MODULECONTENTTYPE - start
Vue.prototype.$showElement = (moduleContentType) => {
  return moduleContentType?.code == "PROPOSAL" ? true : false;
};

Vue.prototype.$doNOTShowIfTactic = (moduleContentType) => {
  return moduleContentType?.code == "TACTICS" ? false : true;
};

Vue.prototype.$showIfService = (moduleContentType) => {
  return moduleContentType?.code === "SERVICE" ? true : false;
};

Vue.prototype.$handleContentTypeLabel = (moduleContentType) => {
  let module = "IDEA";
  if (moduleContentType?.code) {
    switch (moduleContentType.code) {
      case "PROPOSAL":
        module = "IDEA";
        break;
      case "STORY":
        module = "STORY";
        break;
      case "TACTICS":
        module = "TACTIC";
        break;
      case "SERVICE":
        module = "SERVICE";
        break;
      default:
        module = "IDEA";
    }
  }
  return module;
};
// #MODULECONTENTTYPE - end

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();
async function dispatchAnonymousLogin() {
  await store.dispatch(ANONYMOUS_LOGIN);
  // await router.push({name: "intro"});
}

// Identificazione asincrona dell'organizzazione
(async () => {
  let exp = JwtService.getToken() ? JwtService.decodeToken().exp : 0;
  let now = Math.floor(Date.now() / 1000);
  let timeUntilRefresh = exp - now;

  // Vue.prototype.$isLoading(true);
  const organization = await store.dispatch(SET_ORGANIZATION);
  if (
    organization.data[0].publiclyVisible &&
    (!JwtService.getToken() || timeUntilRefresh < 0)
  ) {
    await dispatchAnonymousLogin();
  }

  router.beforeEach((to, from, next) => {
    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    if (!store.state.currentOrganization) {
      window.location.reload();
      return;
    }

    // Scroll page to top on every route change
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    //Portal check
    if (
      "redirectedFrom" in to &&
      to.redirectedFrom == "/" &&
      !!store?.state?.currentOrganization?.data?.umbrellaWorkspaceActive
    ) {
      return next("/portal");
    }

    if (
      "portal" in to.meta &&
      !!store?.state?.currentOrganization?.data?.umbrellaWorkspaceActive
    ) {
      return next();
    } else if ("portal" in to.meta) {
      return next("/intro");
    }
    //end:: Portal check

    //Control token
    exp = JwtService.getToken() ? JwtService.decodeToken().exp : 0;
    now = Math.floor(Date.now() / 1000);
    timeUntilRefresh = exp - now;

    // Check mandatory flags in twoLevelLogin organization
    if (
      store.state.currentOrganization !== null &&
      typeof store.state.currentOrganization === "object"
    ) {
      if (store.state.currentOrganization.data.twoLevelLogin) {
        Promise.all([store.dispatch(VERIFIY_MANDATORY_FLAGS)]);
      }
    }

    if (
      to.name == "privacy" &&
      organization.data[0] &&
      organization.data[0].urlPrivacyPolicy
    ) {
      if (from.name == null) {
        window.location.href = organization.data[0].urlPrivacyPolicy;
      } else {
        window.open(organization.data[0].urlPrivacyPolicy, "_blank").focus();
        next(from.path);
      }
    } else if (
      to.name == "terms" &&
      organization.data[0] &&
      organization.data[0].urlTermsConditions
    ) {
      if (from.name == null) {
        window.location.href = organization.data[0].urlTermsConditions;
      } else {
        window.open(organization.data[0].urlTermsConditions, "_blank").focus();
        next(from.path);
      }
    } else if (
      to.name == "cookie" &&
      organization.data[0] &&
      organization.data[0].urlCookiePolicy
    ) {
      if (from.name == null) {
        window.location.href = organization.data[0].urlCookiePolicy;
      } else {
        window.open(organization.data[0].urlCookiePolicy, "_blank").focus();
        next(from.path);
      }
    }

    if (to.name !== "intro" && to.name !== "login") {
      // ### controllo del token ###

      // Token nullo
      if (JwtService.getToken() == null) {
        if (
          store.state.currentOrganization &&
          store.state.currentOrganization.data.publiclyVisible
        ) {
          (async () => {
            await store.dispatch(ANONYMOUS_LOGIN);
          })();
        } else {
          // router.push({name: "login"});
          return next("/login");
        }
      }

      if (timeUntilRefresh > 0 && timeUntilRefresh < 60 * 60) {
        // Token in scadenza: Refresh 60 minutes before it expires
        (async () => {
          let newToken = await store.dispatch(GET_REFRESHED_TOKEN);
          await store.dispatch(REFRESH_TOKEN, newToken.headers.token);
        })();
      } else if (timeUntilRefresh < 0) {
        // Token scaduto => come sul LOGOUT: org.private mando a login e org pubbliche autentico con utente anonimo
        if (
          store.state.currentOrganization &&
          store.state.currentOrganization.data.publiclyVisible
        ) {
          (async () => {
            await store.dispatch(ANONYMOUS_LOGIN);
          })();
        } else {
          // router.push({name: "login"});
          return next("/login");
        }
      }
    }

    Promise.all([store.dispatch(SET_HEADER_TOKEN)]).then(next);

    /////////////////////////////////////////////////////////////////////////////////
    // redirect to login page if not logged in and trying to access a restricted page
    // const publicPages = ['/login'];
    // const authRequired = !publicPages.includes(to.path);
    // const loggedIn = localStorage.getItem('user');
    //
    // if (authRequired && !loggedIn) {
    //   return next('/login');
    // }
    //////////////////////////////////////////////////////////////////////////////////
  });

  Vue.mixin(validationConditions);

  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
