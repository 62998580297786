// ES
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "Ya estoy registrado",
    ACCEDICOMM2 : "Haga clic aquí e inicie sesión con su cuenta",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    REGISTRATICOMM1 : "Quiero formar parte de la comunidad",
    REGISTRATICOMM2 : "Haga clic aquí si desea registrarse en BiPart con su correo electrónico y crear un perfil.",
    SOLOVOTO : "Sólo quiero votar",
    SOLOVOTO2 : "Haga clic aquí si desea registrarse en BiPart utilizando sólo su teléfono móvil"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "Attenzione",
    INDIETRO : "Volver",
    INSERISCINUOVONUMERO : "Inserisci<br /> nuovo numero",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    PH1 : "Móvil",
    PHONEERROR1 : "Olvidaste incluir el código de área",
    PHONEERROR2 : "¿Has olvidado introducir tu número de teléfono móvil?",
    PHONEERROR3 : "El número que has introducido es un poco extraño... ¡compruébalo! :)",
    PRIVACY : "He leído y acepto la <a href=\"https://bipart.it/privacy\" target=\"_blank\">política de privacidad</a>",
    PROCEDI : "Inscríbete",
    RECUPERAPASSWORD : "Recupera<br/> password",
    TERMINIECONDIZIONI : "He leído y acepto los <a href=\"https://bipart.it/terms\" target=\"_blank\">términos y condiciones</a>.",
    VERIFICA1 : "Comprueba tus datos para participar",
    VERIFICA2 : "Introduzca su teléfono móvil para recibir el código OPT por SMS",
    EXIT: "Logout",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "<h4>Hemos enviado un código de verificación por SMS a su teléfono móvil.</h4>.",
    ALERT1TITLE : "Información",
    ERROROTPMAXCHAR : "Numero massimo di cifre inseribili 6",
    INDIETRO : "Volver",
    OTPDIMENTICATO : "Has olvidado introducir el código otp",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    PH1 : "Código OTP*",
    PROCEDI : "Proceda",
    RICHIEDINUOVOCODICE1 : " <b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti ",
    RICHIEDINUOVOCODICE2 : " richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : " o scrivici a ",
    VERIFICA1 : "Comprueba tus datos para participar",
    VERIFICA2 : "Hemos enviado el código de verificación OTP a tu número de móvil. Por favor, introdúcelo para continuar"
  },
  ADVAUTHROLE : {
    CITTADINO : "Ciudadano",
    INDIETRO : "volver",
    INFOREGISTER : "Además de tu <b>teléfono móvil</b>, donde recibirás tu contraseña para iniciar sesión, te pediremos que introduzcas tu <b>código fiscal</b>.",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    PROCEDI : "Proceda",
    VERIFICA1 : "Comprueba tus datos para participar",
    VERIFICA2 : "Elija cómo autentificarse"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "Oops... algo salió mal; código Stauts:",
    ALERT1TITLE : "Error",
    CFDIVERSO : "¿Ha introducido un número de identificación fiscal diferente al que ya está asociado a su cuenta?",
    CHIUDI : "Cerrar",
    DIMENTICATOCF : "Ha olvidado introducir su número de identificación fiscal",
    ERRORECF : "El número de identificación fiscal no está introducido correctamente.",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    PH1 : "Número de identificación fiscal*",
    PROCEDI : "Proceda",
    VERIFICA1 : "Comprueba tus datos para participar",
    VERIFICA2 : "<b>Ingresa tu NÚMERO DE IDENTIFICACIÓN FISCAL para continuar</b><br>¡Nos aseguraremos de que sólo participen miembros de la comunidad!"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "Su número de identificación fiscal no puede participar en el proceso",
    CFNONABILIATO1 : "Revisa las recomendaciones y si piensas que hay un error escribenos a support@bipart.it",
    CHIUDI : "Cerrar",
    UTENTENONAUTORIZZATO : "Usuario no autorizado"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "",
    CHIUDI : "",
    COMPLIMENTI : "",
    UTENTEABILITATO : "",
    UTENTEINFO : "Desde ahora puedes participar con una cuenta verificada que te permite utilizar una configutación más completa"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "Atención",
    ALERTINFO : "No ha introducido los datos necesarios. Por favor, inténtelo de nuevo o escriba a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    PARTECIPA : "Forma parte del proceso",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    PASSWORDERRATA : "Contraseña incorrecta",
    PH1 : "nombre de usuario*",
    PH2 : "contraseña*",
    SUPPORT : "<i class=\"fas fa-info-circle\"></i> Si no recuerdas tus credenciales, escríbenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    USERNONESISTE : "El nombre de usuario no existe"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "Credenciales incorrectas",
    ALERT1TITLE : "Atención",
    CAMPIOBBLIGATORI : "* Los campos marcados con un asterisco son obligatorios.",
    COGNOMEDIMENTICATO : "Has olvidado poner tu apellido",
    INDIETRO : "VOLVER",
    MAILDIMENTICATA : "Has olvidado introducir tu correo electrónico",
    MAILNONVALIDA : "Dirección de correo electrónico no válida.",
    NEWSLETTER : "Quiero estar al día de las iniciativas y procesos activos de BiPart.",
    NOMEDIMENTICATO : "Has olvidado introducir tu nombre",
    PARTECIPA : "Inscríbase y participe en el proceso",
    PARTECIPAPROCESSO : "Estás en el espacio de la organización:",
    PASSWORDDIMENTICATA : "Has olvidado introducir tu contraseña",
    PH1 : "Nombre*",
    PH2 : "Apellido*",
    PH3 : "Nombre de usuario*",
    PH4 : "Correo electrónico*",
    PH5 : "Contraseña*",
    PRIVACY : "He leído y acepto la <a href=\"https://bipart.it/privacy\" target=\"_blank\">política de privacidad</a>",
    REGISTRA : "INSCRIPCIÓN",
    TERMINIECONDIZIONI : "He leído y acepto los <a href=\"https://bipart.it/terms\" target=\"_blank\">términos y condiciones</a>.",
    USERNAMEDIMENTICATO : "Has olvidado introducir tu nombre de usuario"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "<h4>La propuesta no puede ser seguida</h4>",
    ALERTCOMMENTEMPTY : "<h4>Añade un comentario</h4>",
    ALERTCOMMENTSENDED : "<h4>Gracias por añadir un comentario y contribuir con la propuesta!</h4>",
    ALERTEMAILBLANK : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't your email address yet</h6><div>",
    ALERTEMAILCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">Todavía no has confirmado tu dirección de correo electrónico</h6><div>Confírmala para unirte a la organización y apuntarte a los procesos participativos</div><div>¿No has recibido el correo electrónico? <br><strong>Pulsa en el botón de la banda roja para reenviarlo</strong></div>.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "Crea y publica tu perfil para participar activamente en el proceso de tu comunidad",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "Te damos la bienvenida a BiPart!",
    ALERTEMAILMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the community and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    ALERTEMAILRESENDCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Solicitud de confirmación por correo electrónico enviada a tu bandeja de entrada</h5><div>Confírmala para unirte a la organización<br>y suscribirte a los procesos participativos</div>.",
    ALERTERROR : "¡OPS...algo salió mal!",
    ALERTFORMDESCRIPTION : "<h4>En espera de la activación</h4>. ",
    ALERTFORMTITLE : "<b>Cuestionario aún no disponible</b>",
    ALERTHELPDESCRIPTION : "<h4>Si necesita ayuda, póngase en contacto con nosotros en <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>.",
    ALERTHELPTITLE : "<b>Servicio de asistencia</b>",
    ALERTJAMBOARDDESCRIPTION : "<h4>En espera de la activación</h4>.",
    ALERTJAMBOARDTITLE : "<b>Jamboard aún no está disponible</b>",
    ALERTMEETDESCRIPTION : "<h4>En espera de la activación</h4>. ",
    ALERTMEETTITLE : "<b>Webinar aún no disponible</b>",
    ALERTMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the community and sign up for participatory processes</div><div>You didn't receive the message? <br><strong>Click on the button in the red band to resend it</strong></div>",
    ALERTPERMISSIONDENIED : "<h4>Permiso denegador</h4>.",
    ALERTUSERMODULENOTENABLED : "<h4>Hola! Esposible que no tengas los permisos necesarios para participar. Lee las reglas o escribenos a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> o a cualquier otro contacto disponible.</h4>.",
    ALERTUSERMODULENOTENABLEDBITMASK : "click <a href=\"/process-guidelines/{process_id}\" target=\"_blank\">here</a> to find out how you can participate, or ",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "<h4>Ops,<br>apparently you don't have permission to leave a comment;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "<h4>Ops,<br>apparently you don't have permission to make {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "<h4>Ops,<br>apparently you don't have permission to delete {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "<h4>Ops,<br>apparently do not follow this proposal;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "<h4>Ops,<br>apparently you don't have permission to send a message;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "<h4>Ops,<br>apparently you don't have permission to edit {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "<h4>Ops,<br>apparently you don't have permission to version {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERREGISTEREDTOORGANIZATION : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Gracias por unirte a nuestra comunidad</h5><div>Hemos enviado un correo electrónico a tu bandeja de entrada.<br>Confírmalo para unirte a la organización</div><div>¡Atención! Comprueba también tu carpeta de spam.</div>.",
    ALERTUSERREGISTEREDTOPROCESS : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Gracias por unirte a nuestra comunidad</h5><div>Hemos enviado un correo electrónico a tu bandeja de entrada.<br>Confírmalo para unirte a la organización</div><div>¡Atención! Comprueba también tu carpeta de spam.</div>.",
    PROPOSAL : "a proposal",
    SERVICE : "a service",
    STORY : "a story",
    TACTICS : "a tactic"
  },
  AUTHORITIES : {
    ASSESSOR : "Assessor",
    EXPERT : "Expert"
  },
  BCPROCESSI : {
    CHALLENGE : "Desafíos del proceso",
    COMMUNITY : "Comunidad",
    ELENCOPROCESSI : "Lista de procesos",
    PROCESSO : "Proceso",
    SCENARI : "Escenarios del proceso"
  },
  COMMUNITY : {
    CERCA : "Buscar",
    CHIPARTECIPA : "Participantes",
    GRUPPI : "Grupos",
    ISCRITTI : "Suscriptores",
    PROFILI : "Perfiles",
    SOTTOTITOLO : "Encuentra aquí a quienes publicaron sus perfiles: ¡Únete tú también!",
    TITOLO : "Comunidad"
  },
  COMMUNITYSPACE : {
    BOZZA : "Borrador",
    CS : "Espacio comunitario",
    DESCRIZIONEIDEA : "Descripción de la propuesta",
    IDEE : "20 propuestas",
    NOMEAUTORE : "Nombre del autor",
    TITOLOIDEA : "Título de la propuesta",
    VISUALIZZAELENCO : "Ver lista",
    VISUALIZZAMAPPA : "Ver mapa"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "¿Quieres eliminar la traducción?",
    ALERT1TITLE : "Atención",
    ALERT2 : "¿Quieres confirmar la realización de la traducción?",
    CANCEL : "CANCELAR"
  },
  COOKIE : {
    ANONIMI : "3. Cookie di analisi anonimi:",
    ANONIMIDESCR1 : "Questo sito utilizza cookie di Google Analytics con IP anonimizzato della durata di un anno. Sono definiti anonimi in quanto non possono essere utilizzati per identificare individui specifici. Più in particolare il sito nowhow.it utilizza GoogleAnalytics (Google, Inc.) che genera cookie secondo le modalità illustrate dal documento relativo alle Norme generiche sulla privacy e dal documento specifico in merito all’utilizzo delle informazioni raccolte con Google Analytics. Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da Google stessa. I termini di servizio ai quali tutti i clienti del programma devono aderire, vietano il monitoraggio o la raccolta di informazioni personali (dati di identificazione personale, come ad esempio nome, indirizzo email, dati di fatturazione o altri dati che possono essere ragionevolmente collegati a tali informazioni) mediante l’uso di Google Analytics o l’associazione di queste alle informazioni sull’analisi dei dati web. Questi cookie perseguono esclusivamente scopi statistici e raccolgono informazioni in sola forma aggregata.",
    COLLEGAMENTI : "6. Collegamenti utili",
    COLLEGAMENTIDESCR1 : "Per maggiori informazioni sui cookie ed il loro utilizzo su internet, potrete trovare utili i seguenti collegamenti:<br><br>www.allaboutcookies.org<br>www.microsoft.com/info/cookies.mspx<br>www.google.com/intl/it_it/analytics/privacyoverview.html",
    CONTENUTIESTERNI : "5. Visualizzazione di contenuti da piattaforme esterne",
    CONTENUTIESTERNIDESCR1 : "Questi servizi permettono di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi. Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato. Per maggiori informazioni sulle logiche e le modalità di trattamento dei dati raccolti dai social network, puoi leggere le note informative sulla privacy fornite dai soggetti che forniscono I servizi in questione:<br><br>Widget Video Youtube (Google Inc.) - Terms - Privacy<br>Facebook - Termini e condizioni - Privacy<br>Twitter - Termini e condizioni - Privacy<br>Vimeo - Termini e condizioni - Privacy<br>Slideshare - Termini e condizioni - Privacy<br>Linkedin - Termini e condizioni - Privacy<br>Instagram - Termini e condizioni - Privacy<br><br>Ricordati che puoi gestire le tue preferenze sui cookie anche attraverso il browser Se non conosci il tipo e la versione di browser che stai utilizzando, clicca su “Aiuto” nella finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.<br><br>Se invece conosci il tuo browser clicca su quello che stai utilizzando per accedere alla pagina di gestione dei cookie. Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Windows Explorer.",
    COSASONO : "1. Cosa sono i cookie",
    COSASONODESCR1 : "I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, dove vengono memorizzati per essere ritrasmessi agli stessi siti in occasione di visite successive. I cookie sono utilizzati per diverse finalità, hanno caratteristiche diverse, e possono essere utilizzati sia dal titolare del sito che si sta visitando, sia da terze parti. Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diversa da quella adottata da nowhow.it e che quindi non risponde di questi siti. Di seguito troverai tutte le informazioni sui cookie installati attraverso questo sito, e le indicazioni necessarie su come gestire le tue preferenze riguardo ad essi.",
    DATIRACCOLTI : "O4. Dati personali raccolti: Cookie e Dati di utilizzo.O",
    DATIRACCOLTIDESCR1 : "Tutti i cookie tecnici non richiedono consenso, perciò vengono installati automaticamente a seguito dell’accesso al sito.",
    SOTTOTITOLO : "Gestione dei cookie",
    TITOLO : "Cookie policy",
    UTILIZZATI : "2. Cookie utilizzati da questo sito",
    UTILIZZATIDESCR1 : "Cookie relativi ad attività strettamente necessarie al funzionamento del sito e all’erogazione del servizio. Questo sito utilizza il cookie di sessione PHPSESSIONID generato dalle applicazioni basate sul linguaggio PHP. Questo identificatore viene utilizzato per mantenere le variabili di sessione utente."
  },
  DRIVERS : {
    ACTIVEPROCESSES : "En curso",
    CHALLENGE : "Desafío",
    CLOSEPROCESSES : "Concluido",
    CLUSTER : "Agrupación",
    DESCRIZIONE : "Descripción",
    ELENCOCHALLENGE : "Desafíos del proceso",
    LINK : "Enlace",
    PROCESSES : "Lista de procesos",
    TUTTITEMI : "Todos los desafíos de participación divididos en grupos"
  },
  EMAILCONFIRM : {
    AUTOK : "Autenticación exitosa",
    AUTOKDETAIL : "Ahora puede iniciar sesión y participar en nuestra comunidad",
    BLOGIN : "Inicio de sesión",
    TITOLO : "Bipart"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "Para contribuir activamente en mejorar la vida de tu municipio, puedes proponer una idea propia o simplemente votar por una idea que encuentres interesante. Las ideas propuestas deben ser concretas.",
    DETTAGLIOGLOSSARIO : "",
    FAQ : "",
    FAQ1 : "",
    FAQ10 : "",
    FAQ11 : "",
    FAQ12 : "",
    FAQ13 : "",
    FAQ14 : "",
    FAQ15 : "",
    FAQ16 : "",
    FAQ17 : "",
    FAQ18 : "",
    FAQ19 : "",
    FAQ2 : "",
    FAQ20 : "",
    FAQ21 : "",
    FAQ22 : "",
    FAQ23 : "",
    FAQ24 : "",
    FAQ25 : "",
    FAQ3 : "",
    FAQ4 : "",
    FAQ5 : "",
    FAQ6 : "",
    FAQ7 : "",
    FAQ8 : "",
    FAQ9 : "",
    GLOSSARIO : "Glosario",
    PERCHEPARTECIPARE : "¿Porqué unirse a un proceso participativo?",
    QUALITEMIPROGETTI : "",
    R1 : "",
    R10 : "",
    R11 : "",
    R12 : "",
    R13 : "",
    R14 : "",
    R15 : "",
    R16 : "",
    R17 : "",
    R18 : "",
    R19 : "",
    R2 : "",
    R20 : "",
    R21 : "",
    R22 : "",
    R23 : "",
    R24 : "",
    R25 : "",
    R3 : "",
    R4 : "",
    R5 : "",
    R6 : "",
    R7 : "",
    R8 : "",
    R9 : "",
    TEMI : ""
  },
  GENERALLABEL : {
    AL : "hacia",
    ALTERMINEDELLAFASE : "Al final de la fase",
    DAL : "Desde",
    FASE : "Fase",
    MANCANOANCORA : "Aún falta",
    TERMINAIL : "Termina en"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "He leído y estoy de acuerdo con",
    ACCETTOPROCESSOLINEEGUIDA2 : "Las indicaciones y reglas del proceso participativo"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "Lista de grupos",
    F : "mujer",
    GRUPPO : "Grupo",
    M : "hombre",
    MEMBRI : "miembros",
    PUBBLICA : "Publica tu perfil y preséntate a la comunidad."
  },
  GROUPLIST : {
    COMMUNITY : "Comunidad",
    DDC : "fecha de creación",
    DUE : "dos",
    GRUPPI : "grupos",
    TRE : "tres",
    UNO : "un"
  },
  HOME : {
    ALERT1 : "<h4>Consulta la sección <a href=\"/cómo-funciona\">cómo funciona</a> para saber qué es el presupuesto participativo</h4>.",
    ALERT1TITLE : "<b>Antes de empezar...</b>.",
    ALERT2 : "<h4>¿Pasa algo, tienes alguna dificultad o simplemente quieres saludar? :) Escríbanos ahora a <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Servicio de asistencia</b>",
    ASSISTENZA : "Asistencia",
    DATIGRAFICI : "Datos y gráficos de la organización",
    GETSTARTED : "Empezar a trabajar",
    GRUPPICOM : " Grupos/Comunidades",
    OBIETTIVI : "Objetivos y desafíos",
    ORGANIZZAZIONE : "La organización en pocas palabras",
    ORGANIZZAZIONI : "Organizaciones",
    PROCESSIATTIVI : " Procesos activos",
    PROFILO : "Perfil",
    PROFILODETT : "Aquí puedes encontrar tus actividades y gestionar tu perfil",
    SCENARI : "Escenarios",
    UTENTIREG : "Usuarios registrados",
    VOTANTI : "Votantes",
    VOTIRACCOLTI : "Votos recogidos"
  },
  IDEADETAIL : {
    ALERT1 : "Completa al menos uno de los campos obligatorios entre el estado o la puntuación asignada a la propuesta. ",
    ALERT10TITLE : "No borrar",
    ALERT11 : "Una vez que se borra, no se puede recuperar.",
    ALERT11TITLE : "¿Seguro que quieres borrar este comentario?",
    ALERT12TITLE : "El comentario no pudo ser borrado",
    ALERT13 : "Ya has ingresado una evaluación detallada de esta propuesta",
    ALERT13TITLE : "Atención",
    ALERT14 : "La propeusta ya fue evaluada",
    ALERT14TITLE : "Atención",
    ALERT15 : "Una vez publicado, no podrás editarlo",
    ALERT15B1 : "Confirmación",
    ALERT15B2 : "Cancelar",
    ALERT15TITLE : "¿Estás seguro de que quieres añadir esta evaluación?",
    ALERT16 : "El número no puede contener decimales",
    ALERT16TITLE : "Atención",
    ALERT17 : "el numero no puede contener más de 6 dígitos",
    ALERT17TITLE : "Atención",
    ALERT1TITLE : "Atención",
    ALERT2 : "<h4>¿Pasa algo, tienes alguna dificultad o simplemente quieres saludar? :) Escríbenos ahora a <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Servicio de asistencia</b>",
    ALERT3 : "",
    ALERT3TITLE : "Gracias por votar a favor de esta propuesta",
    ALERT4 : "Navegue por las propuestas y elija cuál merece su voto",
    ALERT4TITLE : "¡Has eliminado tu preferencia!",
    ALERT5 : "Puede seguir donando a otras propuestas eligiendo cómo distribuir su presupuesto disponible.",
    ALERT5TITLE : "Gracias por apoyar la propuesta.",
    ALERT6TITLE : "¡Sigue esta propuesta!",
    ALERT7TITLE : "Ya no sigues la propuesta",
    ALERT8 : "Una vez que se borra, no se puede recuperar.",
    ALERT8B1 : "Sí, ¡borrar!",
    ALERT8B2 : "¡No, cancélalo!",
    ALERT8TITLE : "¿Está seguro de que quiere cancelar esta propuesta?",
    ALERT9 : "La propuesta fue cancelada",
    ALERT9TITLE : "Propuesta de eliminación",
    ALERTBOZZA : "",
    ALERTEMPTYVOTE1USERLEFTHTML : "<h4> Has superado el número de preferencias disponibles. <br /> Recuerda que puedes cambiar tu elección antes de que se cierre la votación. </h4>",
    ALERTEMPTYVOTE1USERLEFTTITLE : "¡Ups!",
    ALERTOWNERMAILEDHTML1 : "<p>Hemos enviado tu mensaje a",
    ALERTOWNERMAILEDHTML2 : "Que te contestará al contacto que pusiste.<br />Entre tanto, contribuye participando en la plataforma.</p>",
    ALERTOWNERMAILEDTITLE : "MENSAJE ENVIADO",
    ALERTPUBBLICATA : "Propuesta bajo revisón. Colabora en su desarrollo",
    APPROFONDISCI : "Leer más",
    APPROVATA : "Aprobado",
    AUTORE : "Published by",
    BUDGET : "Coste estimado:",
    CAMPOMANCANTE : "The field \"{field}\" is missing",
    CANCELLAIDEA : "Eliminar la propuesta",
    CANVERSION : "Aún tienes una versión borrador <br> Publícala para crear una nueva.",
    CANVERSION2 : "Estás creando la {n} versión de la propuesta",
    CANVERSIONTITLE : "Atención",
    CANVERSIONTITLE2 : "{count} es el máximo de versiones que pueden publicarse",
    CHALLENGE : "Desafío",
    CIFRAALTA : "Estás donando demasiado dinero",
    CIFRABASSA : "Estás dando muy poco",
    CIFRADELBUDGET : "Todavía puede donar",
    CIFRAMODIFICATA : "Ha modificado su donación",
    CIFRARIMOSSA : "Ha retirado su donación",
    CIFRASUPERIOREALBUDGET : "Ya no puedes donar porque la cantidad que has ingresado supera tu capacidad económica.",
    CLICCAILPULSANTE : "Haz clic en el botón si deseas hacer esta propuesta.",
    COMMENTAIDEA : "Comentario",
    COMMENTI : "Comentarios",
    COMMENTIALERTEMAILCONFIRM : "<h6 class=\"text-primary\">Aún no has confirmado tu correo electrónico</h6><div>Confirmalo para unirte a la organizacion e inscribirte para el proceso participativo </div><div>¿No recibiste el correo electrónico?<br><strong>Pincha en el botón de la franja roja para reenviarlo</strong></div>",
    COMMENTIALERTEMAILMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the organization and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    COMMENTIALERTMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the organization and sign up for participatory processes</div><div>You didn't receive the message?<br><strong>Click on the button in the red band to resend it</strong></div>",
    COMMENTICONTRIBUISCI : "Deja un comentario",
    COMMENTIDISATTIVATI : "Los comentarios están desactivados",
    COMMENTIENTRA : "INGRESA",
    COMMENTIREGISTRATI : "REGISTRATE",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "Inicia sesión para dejar un comentario",
    COMPLETABOZZA : "Completa el borrador",
    CONDIVIDI : "Comparte",
    COSTOIDEA : "Coste de la propuesta",
    CREATAIL : "Creado el ",
    DESCRIZIONE : "Descripción",
    DONA : "DONAR",
    DONAREDA : " de un mínimo de ",
    DONAREFINOA : " hasta un máximo de ",
    DONRACCOLTE : "Donaciones recogidas",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the proposal does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the proposal because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    FOLLOWERS : "Comunidad",
    FONDIRACCOLTI : "Fondos recaudados",
    GRAZIESCELTAIDEA : "Gracias por elegir esta propuesta.",
    GRAZIESOSTEGNO : "Gracias por apoyar la propuesta.",
    GRAZIESOSTEGNOANCORA : "Gracias por seguir apoyando la propuesta.",
    HAIANCORA : "Todavía tienes",
    HAIANCORA2 : "Todavía tienes",
    HASCRITTO : "escribió",
    IDEAVOTATA : "Propuesta votada",
    INBOZZA : "",
    INDICACIFRA : "Indique la cantidad que desea donar",
    INSERISCICIFRA : "Por favor, introduzca la cantidad que le gustaría donar a este proyecto.",
    INSERISCICOMMENTO : " Introduce un comentario",
    INVIA : "enviar",
    MACCETTODIESSERERICONTATTATO : "Acepto ser contactado a los contactos que he proporcionado",
    MAPPE : "Mapas",
    MBCHIUDI : "Cerrar",
    MBINVIA : "Enviar",
    MCONTATTAILPROPONENTE : "Contactar al autor",
    MEMAIL : "Tu Correo electrónico",
    MESSAGEFIELDNAME : "Message",
    METTIPREF : "Votar",
    MINSERISCIUNMESSAGGIO : "Tu mensaje",
    MMESSAGGIOOBBLIGATORIO : "Se requiere un mensaje",
    MMOBILE : "Tu número de teléfono",
    MNOMINATIVO : "Tu Nombre",
    MODIFICAIDEA : "Editar",
    MTIPOLOGIACOLLABORAZIONELABEL : "Ayuda a hacer crecer la propuesta",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "Elije el tipo de mensaje",
    NARRAZIONE : "La propuesta",
    NOCOMMENTI : "No hay comentarios",
    NOCOMMUNITY : "No hay seguidores",
    NONAPPROVATA : "No aprobado",
    NONSEGUIRE : "Dejar de Seguir",
    NONVALUTATA : "No se ha evaluado",
    NUOVAVERSIONE : "Crear una nueva versión",
    PH1 : "Introduzca la puntuación",
    PH2 : "Introduzca la evaluación",
    PREFERENZEPERPREMIARE : "¡preferencias para premiar las mejores propuestas!",
    PREFRACCOLTE : "Preferencias recogidas",
    PUBBLICATAIL : "on",
    PUOIDONARE : "Puedes donar",
    RICHIEDIASSISTENZA : "Solicitar asistencia",
    RICORDATI3 : "Recuerda que puedes cambiar tu donación hasta que se cierre la votación.",
    RIMUOVIPREF : "Eliminar la preferencia",
    SEGUIIDEA : "Seguir la propuesta",
    SOSTENITORI : "Partidarios",
    SOSTIENIALTREIDEE : "También puedes seguir donando a otras propuestas, eligiendo cómo distribuir tu presupuesto disponible.",
    SOTTOCATEGORIA : "Tipologia",
    TOGLIEREPREFERENZA : "Si ha cambiado su propuesta, puede eliminar su preferencia y asignarla a otras propuestas.",
    TUOIVOTI : "Sus votos",
    URLSITO : "Sito web",
    VAIALSITO : "Vai al sito",
    VALUTAIDEA : "Evaluar la propuesta",
    VALUTARIDUZIONEDONAZIONI : "Considerar la posibilidad de reducir las donaciones ya realizadas para otras propuestas",
    VALUTAZIONEINSERITA : "Clasificación ingresada",
    VALUTAZIONETITLE1 : "Ingresar a la evaluación final",
    VALUTAZIONETITLE2 : "Ingresar a la evaluación detallada",
    VALUTAZIONETITLE3 : "Evaluación final",
    VALUTAZIONETITLE4 : "Evaluación detallada",
    VALUTAZIONETITLE5 : "La evaluación final ya ha sido ingresada",
    VALUTAZIONETITLE6 : "La evaluacion detallada ya ha sido ingresada",
    VALUTAZIONI : "Evaluaciones",
    VERSIONIPROPOSTA : "Versión de la propuesta:",
    VOTAZIONECHIUSA : "La votación está cerrada. Los resultados se publicarán pronto.",
    WALLETSOSTIENIIDEA : "Apoya la propuesta. Haz una donación."
  },
  IDEALIST : {
    ALERTBOZZA : "Tienes una versión borrador de tu propuesta. Puedes encontrarla en tu",
    ALERTVERSIONING1 : "¡El versionado está activo!",
    ALERTVERSIONING2 : "Es posible mejorar la propuesta proporcionando o recolectando ideas y publicando nuevas versiones actualizadas",
    ALERTVERSIONING3 : "Máximo n. de versiones",
    ALERTVERSIONING4 : "Limite de tiempo",
    ALERTVERSIONING5 : "Puedes crear hasta {n} versiones",
    ASSISTENZA : "¡Solicita asistencia!",
    CLASSIFICA : "Clasificación / Resultados",
    CREAIDEA : "Escribe una propuesta",
    ELENCOIDEE : "Lista de propuestas",
    FILTRAPERCHALLENGE : "FILTRAR POR DESAFÍO",
    FILTRAPERMODULO : "FILTRAR POR ÁREAS",
    FILTRAPERSCENARIO : "FILTRAR POR CONTEXTO",
    IDEAVOTATA : "¡Votaste por esta propuesta!",
    IDEE : "propuestas",
    IDEECOSTOSE : "Propuestas más caras",
    IDEEMENOCOSTOSE : "Propuestas menos costosas",
    IDEEMENORECENTI : "Propuestas menos recientes",
    IDEEMENOSEGUITE : "Propuestas menos populares",
    IDEEPIUSEGUITE : "Propuestas más populares",
    INFORMAZIONI : "Más información",
    INFORMAZIONISULLAFASE : "Información de la fase",
    JAMBOARD : "Unirse al Jamboard",
    NESSUNO : "Ninguno",
    NONCISONOIDEE : "Todavía no hay propuestas",
    ORDINA : "ORDENAR POR",
    PREFERENZE : "Preferencias",
    PS : "Espacio personal",
    TUOIVOTI : "Sus votos",
    ULTIMEIDEE : "Últimas propuestas",
    VOTANTI : "Votantes",
    VOTAZIONEATTIVA : "La votación está activa",
    VOTAZIONEATTIVA2 : "Introduzca las propuestas y vote",
    VOTAZIONEATTIVA3 : "Puedes donar",
    VOTAZIONEATTIVABUDG : "donando un presupuesto",
    VOTAZIONEATTIVAMAX : " hasta ",
    VOTAZIONEATTIVAMIN : " de ",
    VOTAZIONEATTIVAPREF : "poniendo las preferencias",
    VOTAZIONEATTIVAPREF2 : "Y aún tienes disponible",
    VOTAZIONEATTIVAPREF3 : "preferencias",
    VOTAZIONEATTIVAPREF4 : "preferencia",
    VOTAZIONEATTIVAUTENTEANONIMO : "Inscribete, si eres elegible para votar, podrás votar por tus propuestas favoritas",
    VOTAZIONECHIUSA : "La votación está cerrada. Los resultados se publicarán pronto.",
    WEBINAR : "Unirse al seminario web"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "Clasificación final",
    COMEFUNZIONA : "Cómo funciona",
    COSTOIDEA : "Costo estimado",
    DONATORI : "Donantes",
    ELENCOPROCESSI : "Lista de procesos",
    FASE : "Fase:",
    FILTRAPERBUDGET : "Filtrar por presupuesto ",
    FILTRAPERPREFERENZE : "Filtrar por preferencias",
    FINANZIATO : "Donaciones",
    PREFERENZE : "Preferencias",
    PROCESSO : "Proceso",
    RISULTATI : "Resultados",
    RISULTATIVOTAZIONE : "Resultados de la votación",
    TUTTOSUPROCESSO : "Todo lo que necesita saber sobre el proceso participativo"
  },
  INTRO : {
    ASSISTENZA : "Asistencia"
  },
  INTROBASE : {
    ASSISTENZA : "Asistencia",
    COSA : "Lo que puedes hacer",
    PARTECIPA : "Unirse",
    PARTECIPADESC : "Participe en el proceso de su escuela",
    SUBIPART : "en Bipart"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "Recuerda confirmar tu dirección de correo electrónico. Si no has recibido ningún correo electrónico de BiPart",
    CONFIRMEMAIL2 : "Pincha aqui",
    CONFIRMEMAIL3 : "Para reenviar.",
    CONFIRMMOBILE1 : "Remember to confirm your mobile. If you have not received any SMS from BiPArt",
    CONFIRMMOBILE2 : "click here",
    CONFIRMMOBILE3 : "to resend it."
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Cerrar",
    CONFERMA : "Confirmar la inscripción en la prueba",
    ERR1 : "Oops... Se ha producido un error inesperado.",
    ISCRIVITIPROCESSO : "Inscríbase en la prueba",
    PARTECIPAPROCESSO : "¿Quiere formar parte del proceso?",
    PROPOSTE : "Para realizar envíos, comentarios, solicitar valoraciones, votar, debes inscribirte en el proceso.",
    TITLE1 : "Error"
  },
  LOGIN : {
    ACCEDI : "Acceder",
    ACCETTO1 : "Acepto",
    ACCETTO2 : "Acepto",
    BACKPORTAL : "Back to portal",
    CAMBIAPASS : "Cambiar contraseña",
    INSERISCIMAIL : "Introduzca su correo electrónico",
    MEX1 : "Registro y aceptación de la \"Privacidad\" y de los \"Términos y Condiciones\"",
    MEX2 : "Las credenciales que has introducido son incorrectas. Consulta o escríbenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Autenticación exitosa",
    MEX4 : "Se ha producido un error",
    MEX5 : "Correo electrónico no válido",
    MEXLOGIN : "<b>Bienvenido a BiPart,</b><br>La plataforma de participación ciudadana.<br>Tus datos serán tratados en pleno cumplimiento de la normativa italiana y europea y con mayor cuidado aún para proteger tus opiniones y tus decisiones.<br><b>Si quieres continuar, lee y acepta los dos documentos que aparecen a continuación.</b>",
    NONRICORDIPASS : "¿No recuerdas tu contraseña?",
    NOTIFICHE : "*Si desea recibir notificaciones sobre el progreso de las propuestas presentadas.",
    PASSWORDERRATA : "Contraseña incorrecta",
    PRIVACY : "Política de privacidad",
    PROSEGUI : "Continuar",
    TERMINI : "Condiciones de servicio",
    TERMINICONDIZIONI : "Para continuar, por favor revisa los <a href=\"https://bipart.it/terms\" target=\"blank\">Términos y condiciones del servicio</a><br> y la <a href=\"https://bipart.it/privacy\" target=\"blank\">Política de privacidad.</a>.",
    TITLE1 : "Enhorabuena",
    TITLE2 : "Atención",
    TITLE3 : "Enhorabuena",
    TITLE4 : "Error",
    TITLE5 : "Error",
    USERNAMENONESISTE : "El nombre de usuario no existe",
    UTENTEAUTENTICATO : "-- Usuario autentificado --"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "Acceder",
    ACCETTO : "He leído y acepto",
    ACCETTOLA : "He leído y acepto",
    BPROSEGUI : "Continuar",
    CAMBIAPASS : "Cambiar contraseña",
    INSERISCIMAIL : "Introduzca su correo electrónico",
    MEX1 : "Registro y aceptación de la \"Política de Privacidad\" y de los \"Términos y condiciones\"",
    MEX2 : "Las credenciales que has introducido son incorrectas. Por favor revisa o escríbenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Autentificación exitosa",
    MEX4 : "Se ha producido un error",
    MEX5 : "Correo no válido",
    MEXLOGIN : "<b>Bienvenido a BiPart,</b><br>La Plataforma de participación ciudadana.<br>Tus datos serán tratados con pleno respeto a la normativa italiana y europea y con más cuidado aún para proteger tus opiniones y tus decisiones.<br><b>Si quieres continuar, lee y acepta los dos documentos que aparecen a continuación.</b>",
    NIENTEACCOUNT : "¿Aún no tienes una cuenta?",
    NONRICORDIPASS : "¿No recuerdas tu contraseña?",
    NOTIFICHE : "*Si desea recibir notificaciones sobre el progreso de las propuestas presentadas.",
    PRIVACY : "Política de privacidad",
    REGISTRATI : "Registro",
    TERMINI : "Condiciones del servicio",
    TERMINIECONDIZIONI : "Para continuar por favor revisa los <a href=\"https://bipart.it/terms\" target=\"blank\">Términos y condiciones del servicio </a><br> y la <a href=\"https://bipart.it/privacy\" target=\"blank\">Política de Privacidad</a>",
    TITLE1 : "Enhorabuena",
    TITLE2 : "Atención",
    TITLE3 : "Enhorabuena",
    TITLE4 : "Error",
    TITLE5 : "Error",
    TITOLO : "Bipart",
    UTENTEAUTENTICATO : "-- Usuario autentificado --"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Cerrar",
    CONFERMA : "Confirmación",
    ISCRIVITIPROCESSO : "Inicie sesión con sus credenciales y participe en el proceso",
    MEX1 : "No has introducido los datos necesarios. Por favor, inténtalo de nuevo o escribe a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX2 : "Las credenciales que has introducido son incorrectas. Consulta o escríbenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Ups... Se ha producido un error.",
    NONFAIPARTE : "¿No te has registrado aún?",
    NUOVOACCOUNT : "Crea una cuenta para participar",
    ORGANIZATION : "Bienvenido a Bipart",
    PASSWORDERRATA : "Contraseña incorrecta",
    REGISTRATI : "REGISTRO",
    TITLE2 : "Atención",
    TITLE3 : "Error",
    USERNAMENONESISTE : "El nombre de usuario no existe"
  },
  MAINMENU : {
    ABOUT : "Acerca de",
    ACCESSIBILITY : "Accesibilidad",
    COMMUNITY : "Comunidad",
    COOKIE : "Cookie",
    DEMO : "Crear un espacio comunitario",
    DS : "Espacio deliberativo",
    EXPLORE : "Explore",
    FAQ : "Faq",
    FEATURE : "Tutorial e instrucciones",
    GIOCHI : "Juegos educativos",
    HOME : "Inicio",
    HOWITWORKS : "Cómo funciona",
    HOWTOJOIN : "Cómo participar",
    JOINCOMMUNITY : "Espacio comunitario",
    LISTCHALLENGES : "Lista de desafíos",
    LISTGROUPS : "Lista de grupos",
    LISTPROCESSES : "Lista de procesos",
    LISTSCENARIOS : "Lista de escenarios",
    LOGIN : "Acceder",
    LOGOUT : "Salir",
    PORTAL : "Portal",
    PRIVACY : "Privacidad",
    PROJECT : "El proyecto",
    PROJECTS : "Estudios de caso",
    PS : "Espacio personal",
    PSACTIVITIES : "Actividades",
    PSPROFILE : "Perfil personal",
    PSSETTINGS : "Ajustes",
    REGISTER : "Registro",
    SERVICES : "Lo que ofrecemos",
    TERMS : "Términos y condiciones",
    WORKSPACE : "Espacio de trabajo"
  },
  MANDATORYFLAGS : {
    ACCETTO : "He leído y acepto",
    ACCETTOLA : "He leído y acepto",
    BPROSEGUI : "Continuar",
    DETTWELCOME : "El tratamiento de sus datos se hará respetando plenamente la normativa italiana y europea y con un cuidado aún mayor para proteger sus opiniones y decisiones. Si desea continuar, lea y acepte los dos documentos siguientes.",
    INSERISCIMAIL : "Introduzca su correo electrónico",
    MEX2 : "Las credenciales que has introducido son incorrectas. Consulte o escríbanos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Autenticación exitosa",
    MEX5 : "El correo electrónico que has introducido no es válido",
    NOTIFICHE : "*Si desea recibir notificaciones sobre el progreso de las propuestas presentadas.",
    PRIVACY : "Política de privacidad",
    TERMINIECONDIZIONI : "Para continuar por favor revisa los <a href=\"https://bipart.it/terms\" target=\"blank\">Términos y condiciones del Servicio</a><br> y la<a href=\"https://bipart.it/privacy\" target=\"blank\">Política de Privacidad</a>",
    TITLE1 : "Enhorabuena",
    TITLE2 : "Atención",
    TITLE3 : "Enhorabuena",
    TITOLO : "Bipart",
    TITOLOWELCOME : " Bienvenido a BiPart, la Plataforma para la participación ciudadana.",
    UTENTEAUTENTICATO : "-- Usuario autentificado --"
  },
  MESSAGEAUTH : {
    0 : "",
    1 : "Las credenciales ingresadas son incorrectas. Revisa o escríbenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    100 : "",
    105 : "",
    110 : "",
    115 : "",
    120 : "",
    125 : "",
    130 : "",
    135 : "",
    140 : "",
    145 : "",
    150 : "",
    155 : "",
    160 : "",
    165 : "",
    170 : "",
    172 : "La dirección de correo electrónico ingresada ya está en uso\nPor favor, revisa si ya tienes una cuenta en BiPart, de lo contrario reporta el problema a support@bipart.it",
    175 : "¡Ups!\n¡Para continuar con el registro debes leer y decirnos que estás de aucerdo con nuestra política de privacidad y los términos y condiciones del servicio!",
    180 : "",
    182 : "",
    185 : "",
    187 : "",
    190 : "El nombre de usuario ingresado ya está en uso: quizás ya te registraste y no lo recuerdas.\n Intenta iniciar sesión o ingresar con un usuario distinto.\nGracias!",
    192 : "",
    2 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Gracias por suscribirte a<br>{value}</h5>",
    200 : "",
    205 : "",
    210 : "",
    215 : "",
    220 : "",
    225 : "",
    3 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Estás participando en el proceso<br>{value}</h5>",
    300 : "",
    305 : "",
    310 : "",
    4 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><div>Welcome to {organization_name} </div><h5 class=\"text-primary\">Confirmamos tu suscripción a {process_name} </h5><div>De aquí en adelante formas parte de la comunidad y puedes participar en el proceso al que te has suscrito.</div>",
    400 : "",
    405 : "",
    410 : "",
    420 : "",
    425 : "Ops!\nL’attributo “mobile” non compare nel database Utenti\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    500 : "",
    502 : "",
    510 : "",
    515 : "",
    605 : "",
    700 : "",
    705 : "",
    710 : "",
    715 : "",
    720 : "",
    725 : "",
    ATTENZIONE : ""
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "Inserisci oppure copia e incolla l'URL del sito ufficiale dell'organizzazione che fornisce il servizio",
    MAXLENGTHPART1 : "",
    MAXLENGTHPART2 : "",
    PH0 : "",
    PH5 : "----",
    SELEZIONASOTTOCATEGORIA : "Indica che tipo di servizio stai pubblicando, selezionando una delle voci che ti compaiono cliccando il campo di inserimento",
    SOTTOCATEGORIA : "Tipologia"
  },
  MODULEIDEACREATE : {
    ALERT1 : "Se ha detectado un error en los datos introducidos: comprueba que has completado los campos obligatorios: Título y descripción",
    ALERT1TITLE : "Atención",
    ALERT2 : "¡La propuesta se ha introducido correctamente!",
    ALERT3 : "",
    ALTROMATERIALE : "Si dispone de otro material útil para detallar su propuesta (dibujos, perspectivas, especificaciones técnicas, ...), cárguelo aquí.",
    ANNULLA : "Cancelar",
    AVANTI : "Adelante",
    BOZZA : "Guardar como borrador",
    CHALLENGE : "Desafío",
    COSTOIPOTIZZATO : "Coste estimado",
    CREAIDEA : "Crear una propuesta",
    DAIBREVETITOLO : "Dale a tu propuesta un título corto pero pegadizo",
    DESCRIVI : "Resume tu propuesta en pocas palabras. Luego podrás describirla en detalle en la siguiente sección",
    DESCRIZIONE : "Resumen",
    DOCALLEGATI : "Documentos y anexos.",
    FILEACCETTATI : "Tamaño máximo del archivo: 10 MB. Formatos compatibles: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Tamaño máximo del archivo: 10 MB. Formatos compatibles: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Tamaño máximo del archivo: 10 MB. Formatos compatibles: DOC, XLS, PDF, PPT",
    IMMAGINERAPPRESENTATIVA : "Inserte una imagen que represente su propuesta",
    IMMAGINI : "Publica más fotos para que la gente entienda mejor tu propuesta.",
    INDICACOSTO : "Indica cuánto crees que costará tu propuesta",
    ISTRUZIONI : "Instrucciones",
    LOGO : "Imagen de portada",
    MAPPEEINFO : "Mapas e infografías",
    NARRA : "Describe tu propuesta en detalle",
    NARRAZIONE : "La propuesta",
    OVERVIEW : "Información general",
    PH1 : "Máximo 600 caracteres",
    PH2 : "Código de inserción (sólo en Youtube)",
    PH3 : "Empieza a escribir la dirección y haz clic en el mejor resultado para colocar el punto de referencia",
    PH4 : "URL",
    PUBBLICA : "Publicar",
    SCEGLISFIDE : "Elija qué desafíos(s) quiere abordar su propuesta.",
    SELEZIONAMAPPA : "Seleccione el mapa. Puede georreferenciar su propuesta o vincularla a una de las áreas actuales",
    SELEZIONATRA : "Seleccione entre",
    TITOLO : "Título",
    TRASCINA : "Arrastre la imagen aquí o haga clic aquí para subirla",
    TRASCINADOC : "Arrastre los archivos aquí o haga clic aquí para cargarlos",
    TRASCINAIMMAGINI : "Arrastre las imágenes aquí o haga clic aquí para cargarlas",
    URLSITO : "Sito web",
    VIDEO : "Si hay o has hecho un vídeo para explicar tu propuesta, incrústalo copiando el código aquí (no la URL) para que sea visible para todos.",
    VIDEOHINT : "El vídeo sirve para ilustrar mejor tu proyecto y comprometer a más personas"
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "Actualizar el título",
    AGGIUNGIALTREIMMAGINI : "Añadir más fotos",
    ALERT1 : "Se ha detectado un error en los datos introducidos: compruebe los datos e inténtelo de nuevo",
    ALERT1TITLE : "Atención",
    ALERT2 : "La propuesta se ha introducido correctamente.",
    ALERT2TITLE : "",
    ALERT3 : "",
    ALERT4 : "",
    ALERT5 : "",
    ALTROMATERIALE : "Si dispones de otro material útil para detallar tu propuesta (dibujos, perspectivas, especificaciones técnicas, ...), cárgalo aquí.",
    ANNULLA : "",
    AVANTI : "Adelante",
    BOZZA : "",
    CANCELLA : "Borrar",
    CHALLENGE : "Desafío",
    COSTO : "Coste",
    COSTOIPOTIZZATO : "Actualizar el coste asumido",
    DESCRIVI : "Descripción de la actualización",
    DESCRIZIONE : "Descripción",
    DOCALLEGATI : "Documentos y anexos.",
    DOCTECNICA : "Documentación técnica",
    EDITRESOURCESMODALTITLE : "",
    ERR1 : "",
    FILEACCETTATI : "Tamaño máximo del archivo: 10 MB. Formatos compatibles: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Tamaño máximo del archivo: 10 MB. Formatos compatibles: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Tamaño máximo del archivo: 10 MB. Formatos compatibles: DOC, XLS, PDF, PPT",
    IMMAGINI : "Imágenes",
    ISTRUZIONI : "",
    LINKVIDEO : "Enlaces de vídeo",
    LOGO : "Logotipo",
    MAPPEEINFO : "Mapas e infografías",
    MODIFICA : "",
    MODIFICAIDEA : "Propuesta de enmienda",
    MOSTRA : "Mostrar",
    NARRA : "",
    NARRAZIONE : "",
    OVERVIEW : "Resumen",
    PH1 : "Máximo 600 caracteres",
    PH2 : "código de incrustación (sólo youtube)",
    PH3 : "Empieza a escribir la dirección y haz clic en el mejor resultado para colocar el punto de referencia",
    PH4 : "URL",
    PUBBLICA : "",
    SCEGLISFIDE : "Elija qué reto(s) quiere abordar su propuesta.",
    SELEZIONAMAPPA : "Seleccione el mapa. Puede georreferenciar su propuesta o vincularla a una de las áreas actuales",
    SELEZIONATRA : "Seleccione entre",
    TITLE : "",
    TITOLO : "Título",
    TRASCINA : "Arrastre la imagen aquí o haga clic aquí para subirla",
    TRASCINADOC : "Arrastre los archivos aquí o haga clic aquí para cargarlos",
    TRASCINAIMMAGINI : "Arrastre las imágenes aquí o haga clic aquí para cargarlas",
    URLSITO : "Sito web",
    VIDEO : "Vídeo de actualización",
    VIDEOHINT : "Vídeo (Insertar código de inserción)"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "",
    AGGIORNATITOLO : "",
    AGGIUNGIALTREIMMAGINI : "",
    ALERT1 : "",
    ALERT1TITLE : "",
    ALERT2 : "",
    ALERT2TITLE : "",
    ALERT3 : "",
    ALERT4 : "",
    ALERT5 : "",
    ALTROMATERIALE : "",
    ANNULLA : "",
    AVANTI : "",
    BOZZA : "",
    CANCELLA : "",
    CHALLENGE : "",
    COSTO : "",
    COSTOIPOTIZZATO : "",
    DESCRIVI : "",
    DESCRIZIONE : "",
    DOCALLEGATI : "",
    DOCTECNICA : "",
    EDITRESOURCESMODALTITLE : "",
    ELENCOIDEE : "",
    ERR1 : "",
    FILEACCETTATI : "",
    FILEACCETTATIDOC : "",
    FILEACCETTATIIMMAGINI : "",
    IMMAGINI : "",
    ISTRUZIONI : "",
    LINKVIDEO : "",
    LOGO : "",
    MAPPEEINFO : "",
    MODIFICA : "",
    MODIFICAIDEA : "",
    MOSTRA : "",
    NARRA : "",
    NARRAZIONE : "",
    NUOVAVERSIONE : "",
    NUOVAVERSIONEMESSAGGIO1 : "",
    NUOVAVERSIONEMESSAGGIO2 : "",
    NUOVAVERSIONEMESSAGGIO3 : "",
    OVERVIEW : "",
    PH1 : "",
    PH2 : "",
    PH3 : "",
    PH4 : "URL",
    PROCESSO : "",
    PUBBLICA : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    SELEZIONATRA : "",
    TITLE : "",
    TITOLO : "",
    TRASCINA : "",
    TRASCINADOC : "",
    TRASCINAIMMAGINI : "",
    URLSITO : "Sito web",
    VIDEO : "",
    VIDEOHINT : ""
  },
  MODULEINFO : {
    ELENCOPROCESSI : "Lista de procesos",
    INFO : "Formulario de información del proceso",
    INPROGRESS : " En curso ...",
    PROCESSO : "Proceso"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "Lista de procesos",
    INFO : "Módulo en construcción",
    INPROGRESS : " En curso ...",
    PROCESSO : "Proceso"
  },
  MODULESERVICECREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il servizio è stato inserito correttamente!",
    ALERT3 : "Inserisci il costo del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    CREAIDEA : "Descrivi il tuo servizio",
    DAIBREVETITOLO : "Dai un titolo breve ma accattivante al tuo servizio",
    DESCRIVI : "Descrivi in poche righe il tuo servizio. Puoi dettagliarlo al prossimo step",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine che rappresenti il tuo servizio",
    IMMAGINI : "Pubblica altre immagini utili a far comprendere meglio il tuo servizio",
    INDICACOSTO : "Indica quanto potrebbe costare il tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli a quale o a quali si riferisce il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube che spiega il tuo servizio, rendilo visibile a tutti copiando qui l’URL",
    VIDEOHINT : "Un video aiuta a descrivere meglio un servizio e a raggiungere più persone"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "Il servizio è stato caricato correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione del servizio",
    ALERT3 : "Seleziona la lingua in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    MODIFICAIDEA : "Modifica i contenuti",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il servizio",
    URLSITO : "Sito web"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il tuo servizio è stato inserito correttamente!",
    ALERT2TITLE : "Stai per pubblicare una versione aggiornata del tuo servizio",
    ALERT3 : "Seleziona la lingua o le lingue in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALERT5 : "Inserisci un valore corretto per indicare il costo ipotizzato del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    ELENCOIDEE : "Tutte i servizi",
    MODIFICAIDEA : "Modifica i contenuti del tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    NUOVAVERSIONEMESSAGGIO3 : "del tuo servizio",
    SCEGLISFIDE : "Scegli quale o quali tematiche vuole affrontare il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web"
  },
  MODULESTORYCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "",
    ALERT3 : "",
    ALTROMATERIALE : "",
    CREAIDEA : "",
    DAIBREVETITOLO : "",
    DESCRIVI : "",
    IMMAGINERAPPRESENTATIVA : "",
    IMMAGINI : "",
    INDICACOSTO : "",
    NARRA : "",
    NARRAZIONE : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web",
    VIDEO : "",
    VIDEOHINT : ""
  },
  MODULESTORYEDIT : {
    ALERT2 : "",
    ALERT2TITLE : "",
    ALERT3 : "",
    ALTROMATERIALE : "",
    MODIFICAIDEA : "",
    NARRA : "",
    NARRAZIONE : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "",
    ALERT2TITLE : "",
    ALERT3 : "",
    ALERT5 : "",
    ALTROMATERIALE : "",
    ELENCOIDEE : "",
    MODIFICAIDEA : "",
    NARRA : "",
    NARRAZIONE : "",
    NUOVAVERSIONEMESSAGGIO3 : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  MODULETACTICCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "",
    ALERT3 : "",
    ALTROMATERIALE : "",
    CREAIDEA : "",
    DAIBREVETITOLO : "",
    DESCRIVI : "",
    IMMAGINERAPPRESENTATIVA : "",
    IMMAGINI : "",
    INDICACOSTO : "",
    NARRA : "",
    NARRAZIONE : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web",
    VIDEO : "",
    VIDEOHINT : ""
  },
  MODULETACTICEDIT : {
    ALERT2 : "",
    ALERT2TITLE : "",
    ALERT3 : "",
    ALTROMATERIALE : "",
    MODIFICAIDEA : "",
    NARRA : "",
    NARRAZIONE : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "",
    ALERT2TITLE : "",
    ALERT3 : "",
    ALERT5 : "",
    ALTROMATERIALE : "",
    ELENCOIDEE : "",
    MODIFICAIDEA : "",
    NARRA : "",
    NARRAZIONE : "",
    NUOVAVERSIONEMESSAGGIO3 : "",
    SCEGLISFIDE : "",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "Comunidad",
    ISCRITTI : "{subscribers} usuarios ({profiles} profiles)"
  },
  PARTIALSIDEE : {
    APPROVED : "Aprobado",
    BUDGET : "Presupuesto: €.",
    CREATADA : "Creado por",
    RANK01 : "Rango 01",
    VOTATA : "VOTADO"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "<h4>No puedes iniciar sesión con tu cuenta. Solicite el acceso autentificado en <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>.",
    ALERT1TITLE : "<b>El grupo es privado</b>",
    ALERT2 : "Podrás conectarte cuando esté en marcha.",
    ALERT2TITLE : "La fase está en marcha. ",
    ALERT3 : "<h4>No puedes iniciar sesión con tu cuenta. Solicite el acceso autentificado en <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>. ",
    ALERT3TITLE : "<b>El proceso es privado</b>",
    COMMENTI : "",
    COMMUNITY : "",
    CONCLUSA : "Concluido",
    CONCLUSO : "Concluido",
    DAATTIVARE : "Para ser activado",
    DRIVERAMBIENTE : "Conductores: Medio ambiente",
    FASE : "Fase",
    INATTIVAZIONE : "En activación",
    INCORSO : "En curso",
    PARTECIPA : "Únase a",
    PRIVATO : "Privado",
    PUBBLICO : "Público",
    RICHIEDIACCESSO : "Solicitar acceso",
    VALUTAZIONI : "",
    VISUALIZZA : "Ver",
    VOTA : "Vota"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "<h4>Abbiamo inviato un codice SMS di verifica al tuo cellulare.</h4>",
    ALERT1TITLE : "Info",
    ALERTPHONE1HTML : "Il numero fornito non risulta registrato presso l'organizzazione",
    ALERTPHONE1TITLE : "Utente sconosciuto",
    CAMBIAPASSWORD : "Cambia la password",
    CODICEOTP : "Codice OTP*",
    CONFERMA : "Conferma",
    CONFERMAPASSWORD : "Conferma la password",
    ERROROTPMAXCHAR : "Numero massino di cifre inseribili 6",
    ERRORPWEQUAL : "Le password devono essere uguali",
    ERRORPWLENGTH : "La password deve essere lunga almeno 6 caratteri",
    INSERISCICELLULARE : "Inserisci cellulare",
    INSERISCIPASSWORD : "Inserisci la password",
    METODOCELLULARE : "Cellulare",
    METODORECUPERO : "Metodo di recupero",
    OTPDIMENTICATO : "Hai dimenticato di inserire il codice OTP",
    PHONEERROR1 : "Hai dimenticato di inserire il prefisso",
    PHONEERROR2 : "Hai per caso dimenticato di inserire il tuo numero di cellulare?",
    PHONEERROR3 : "Il numero che hai inserito è un po’ strano … controlla bene! :)",
    PROCEDI : "procedi",
    RECUPEROPASSWORD : "Recupera la tua password",
    RECUPERORIUSCITO : "Cambio password completato con successo",
    RICHIEDINUOVOCODICE1 : " <b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti ",
    RICHIEDINUOVOCODICE2 : " richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : " o scrivici a ",
    SCELTAMETODO : "Scegli come recuperarla",
    SCELTAMETODONECESSARIA : "La scelta del metodo è obbligatoria",
    VERIFICA1 : "Verifica il tuo numero di telefono",
    VERIFICA2 : "Ti abbiamo inviato un SMS con un codice di verifica OTP. Inseriscilo per accedere al tuo account e creare la tua nuova password."
  },
  PHASEMODULECARDIDEA : {
    IDEE : ""
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "servizi"
  },
  PHASEMODULECARDSTORY : {
    IDEE : ""
  },
  PHASEMODULECARDTACTIC : {
    IDEE : ""
  },
  PORTAL : {
    CONTACTUS : "Contact us",
    DELIBERATIVESPACE : "Deliberative space",
    HOME : "Home",
    INSTRUMENTS : "The instruments",
    PROJECT : "The project",
    SUBSCRIBE : "Subscribe"
  },
  PRIVACY : {
    DIRITTO : "6. Diritto di accesso e controllo dei dati personali, diritto di opposizione",
    DIRITTODESCRIZIONE1 : "In ogni momento Lei ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile; può ottenere l’indicazione dell’origine dei Suoi dati personali, delle finalità e modalità del loro trattamento, della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici, degli estremi identificativi del Titolare, dei responsabili (se nominati) e della tipologia dei soggetti ai quali i Suoi dati personali possono essere comunicati, trasferiti, o che possono venirne a conoscenza in qualità di responsabili o incaricati.",
    DIRITTODESCRIZIONE2 : "Lei ha altresì il diritto di accedere a qualsiasi Suo dato personale trattato da BiPart e potrà, chiedere la modifica, correzione, copia, restrizione o estensione della condivisione, anonimizzazione, portabilità o eliminazione (anche parziale) dei Suoi dati personali. Lei ha altresì il diritto di revocare in qualsiasi momento il consenso al trattamento dei Suoi dati da parte del Titolare.<br>Potrà esercitare i predetti diritti inviando una richiesta scritta alla sede legale di BiPart Impresa sociale srl (già BiPart Srls), sita in Milano, via Metauro, 4 mediante lettera raccomandata ovvero email al seguente indirizzo PEC bipart@casellapec.com.\nCon comunicazione da inviarsi al medesimo recapito Lei potrà altresì esercitare il diritto di opporsi in qualsiasi momento, per motivi connessi alla Sua situazione particolare, al trattamento (anche soltanto parziale) dei dati personali che La riguardano, ancorché pertinenti allo scopo della raccolta e al trattamento di dati personali a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale. Da tale data in avanti il Titolare si asterrà dal trattare ulteriormente i Suoi dati personali salvi i diritti riconosciutigli ex lege.\nPer avere ulteriori informazioni in ordine ai Suoi diritti, La invitiamo a visitare il sito web dell’Autorità Garante per la protezione dei dati personali all’indirizzo www.garanteprivacy.it.",
    FINALITA : "3. Finalità del trattamento dei suoi dati",
    FINALITADESCRIZIONE1 : "Il trattamento dei Suoi dati ha la finalità di consentire l’utilizzo della Piattaforma e l’erogazione di tutti i servizi connessi e/o strumentali alla stessa ovvero di tutte le attività legate allo sviluppo e realizzazione dei progetti di bilancio partecipativo e di partecipazione in generale con i soggetti, pubblici e privati, coinvolti in tali progetti gestiti mediante la Piattaforma da BiPart; il trattamento trova quindi la propria base giuridica negli accordi di volta in volta conclusi dal Titolare con i soggetti (pubblici e privati) coinvolti nei progetti di bilancio partecipativo gestiti da BiPart, anche mediante la Piattaforma.",
    FINALITADESCRIZIONE2 : "Per il conseguimento di tali finalità, ed a titolo meramente esemplificativo, i Suoi dati personali potranno essere utilizzati e comunicati a soggetti terzi per l’evasione dell'iscrizione, per la verifica della correttezza dei dati anagrafici inseriti, per l’esecuzione e gestione del servizio, per il data entry, per l’elaborazione in forma statistica della partecipazione alla Piattaforma (utilizzo dei dati socio-demografici e geografici degli iscritti). Inoltre, i Suoi dati personali saranno trattati per assolvere ad obblighi di legge, contabili e fiscali. \nPrecisiamo pertanto che il mancato conferimento dei dati personali a tali fini potrebbe implicare l’impossibilità – totale o parziale – per BiPart di fornire la prestazione richiesta e/o svolgere i relativi servizi connessi e/o strumentali.",
    FINALITADESCRIZIONE3 : "I Suoi dati personali saranno conservati da BiPart fino a quando il Suo account sarà aperto. BiPart, a decorrere dalla disattivazione dell’account, provvederà alla cancellazione dei Suoi dati personali, avendo comunque la facoltà di renderli anonimi e conservarli esclusivamente per la creazione di un modello di utenza (che tenga conto esclusivamente del sesso, dell’età e, se disponibile, dell’area geografica) utile per la ricerca scientifica e statistica legata alla diffusione e sviluppo della prassi di bilancio partecipativo.",
    INTRO : "1. Introduzione",
    INTRODESCRIZIONE1 : "BiPart Impresa sociale srl (già BiPart Srls), C.F. e P.IVA 094444700968, con sede in Milano, via Metauro n. 4, in qualità di titolare del trattamento dei dati (di seguito anche l’“BiPart” o anche il “Titolare”), ai sensi di quanto previsto dal D.Lgs. 30 giugno 2003 n. 196 e ss. modifiche (di seguito “Codice Privacy”) e dal Regolamento UE n. 2016/679 (di seguito “GDPR”), intende informarLa di quali saranno le modalità e le finalità del trattamento dei dati personali da Lei forniti.",
    INTRODESCRIZIONE2 : "Gli utenti iscritti (di seguito i “Membri”) hanno la possibilità di partecipare alle attività rese possibili dalla Piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione) nonché ai vari progetti di bilancio partecipativo, anche interagendo tra loro, mediante scambio di conoscenze, proposte ed idee, sia in modalità privata che pubblica. I contenuti ed i dati su alcuni dei servizi offerti con la Piattaforma sono pertanto visualizzabili dai Membri così come pure da soggetti non iscritti (i “Visitatori”). Lei utilizzando la Piattaforma presta dunque il Suo proprio specifico, preventivo, libero e facoltativo consenso al trattamento dei Suoi dati secondo quanto indicato nella presente informativa.",
    MANIFESTO : "7. Manifesto del consenso",
    MANIFESTODESCRIZIONE1 : "Qualora i termini della presente informativa siano da Lei condivisi, BiPart Le chiede di autorizzazione e acconsentire al trattamento dei Suoi dati personali nelle modalità e finalità qui indicate, mediante approvazione espressa. In segno inequivocabile del Suo consenso Le chiediamo quindi di barrare la presente casella.",
    MODALITA : "4. Modalità di trattamento e sicurezza dei suoi dati",
    MODALITADESCRIZIONE1 : "I Suoi dati personali saranno raccolti e trattati principalmente con strumenti elettronici ed informatici e memorizzati sia su supporti informatici che su supporti cartacei e su ogni altro tipo di supporto idoneo da parte dei soggetti a ciò espressamente autorizzati dal Titolare, tutti debitamente istruiti in materia di sicurezza dei dati personali e del diritto alla privacy. Secondo la normativa indicata dal Codice Privacy e dal GDPR, tale trattamento sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.",
    MODALITADESCRIZIONE2 : "Per evitare l’accesso non autorizzato o la divulgazione e assicurare l’utilizzo appropriato dei Suoi dati personali, BiPart ha messo in atto procedure amministrative, tecniche e fisiche ragionevoli e appropriate che verranno costantemente adeguate alla normativa tempo per tempo vigente in materia di protezione dei dati personali. Al fine di garantire il rispetto degli standard di cui al Codice Privacy e al GDPR il trattamento dei Suoi dati personali sarà svolto esclusivamente in Paesi appartenenti all’Unione Europea.",
    MODALITADESCRIZIONE3 : "BiPart si impegna ad adeguare la propria privacy policy alle disposizioni di legge e regolamentari, pertanto è possibile che la presente informativa subisca, nel corso del tempo, delle modifiche di aggiornamento. In caso di modifiche significative alla nostra informativa sulla privacy, che alterino notevolmente le nostre prassi in materia di privacy, BiPart avrà cura di avvisarLa anche attraverso altri mezzi, ad esempio inviando un'e-mail o pubblicando un avviso sul nostro sito web e/o sulle pagine dei social media prima che le modifiche entrino in vigore.",
    SOGGETTI : "5. Soggetti a cui potranno essere comunicati i dati personali",
    SOGGETTIDESCRIZIONE1 : "I Suoi dati personali potranno essere oggetto di comunicazione o diffusione:",
    SOGGETTIDESCRIZIONE2 : "<li>alle Amministrazioni Pubbliche, agli Enti Locali e più in generale ai soggetti, anche privati, che aderiscono o prendono parte ai progetti di bilancio partecipativo gestiti da BiPart anche mediante la Piattaforma;</li><li>ai soggetti autorizzati che prestino attività di assistenza e consulenza in materia amministrativa, tecnico/informatica, tributaria, contabile, finanziaria e legale;</li><li>a soggetti terzi in adempimento ad obblighi di legge.</li>",
    SOGGETTIDESCRIZIONE3 : "I predetti soggetti opereranno in qualità di Responsabili o comunque di soggetti autorizzati al trattamento dei Suoi dati personali nei limiti qui da Lei autorizzati. Ai soggetti autorizzati e ai Responsabili, ove nominati, BiPart impartirà adeguate istruzioni operative, al fine di poter garantire la riservatezza e la sicurezza dei Suoi dati personali.",
    SOTTOTITOLO : "Trattamento dei dati personali",
    TIPOLOGIA : "2. Tipologia dei dati raccolti",
    TIPOLOGIADESCRIZIONE1 : "Per creare un account attivo nella piattaforma Lei deve fornire alcuni dati personali che includono il Suo nome, cognome, indirizzo email e password (dati essenziali). La Piattaforma Le consente la possibilità di utilizzare le opzioni di implementazione del Suo profilo integrando e fornendo informazioni ulteriori sulla Sua persona che possono includere, a titolo esemplificativo e non esaustivo, l’indicazione dell’area geografica o la specificazione della località in cui risiede, il titolo di studio, o una Sua fotografia. Parimenti, è possibile che nell’utilizzo della Piattaforma Le sia consentito di condividere la Sua rubrica ed i Suoi contatti. Non è necessario che pubblichi o inserisca tali dati personali, precisiamo comunque che è Sua facoltà scegliere espressamente se, una volta inseriti, tali dati possano essere pubblicamente visibili da altri Membri o meno.",
    TIPOLOGIADESCRIZIONE2 : "E’ dunque rimessa esclusivamente a Lei la scelta di comunicare e inserire nella Piattaforma, e nel Suo profilo, informazioni di natura sensibile (idonei a rivelare l’origine razziale ed etnica, le convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l’adesione a partiti, sindacati, associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale, nonché i dati personali idonei a rivelare lo stato di salute e la vita sessuale), così come pure di renderle pubbliche. La invitiamo quindi a non pubblicare dati che non vuole rendere pubblicamente disponibili, dovendosi precisare che con l’inserimento di tali dati nel Suo account e nella Piattaforma, ne autorizza il trattamento.",
    TITOLO : "Informativa sulla privacy"
  },
  PROCESSABOUT : {
    FASE : "Fase",
    TITOLO : " El proceso participativo"
  },
  PROCESSCONTATTI : {
    CONTATTI : "Contacto"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "Presupuesto disponible:",
    BUDGETPROPOSTO : "Presupuesto propuesto:",
    CONCLUSA : "Concluido",
    DAATTIVARE : "Para ser activado",
    FASIPROCESSO : "Pasos del proceso",
    INCORSO : "En curso",
    ITUOIVOTI : "Sus votos",
    PROGRESS : "Progreso"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : ""
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : ""
  },
  PROCESSLIST : {
    BUDGET : "Presupuesto",
    DATAINIZIO : "Fecha de inicio",
    DATAINS : "Fecha de entrada",
    PARTECIPANTI : "N participantes",
    PROCESSI : "Procesos",
    TITOLO : "Procesos participativos"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "Todos los temas para reflexionar y encontrar soluciones en su comunidad",
    NOTEMATICHE : "No hay temas",
    OBIETTIVI : "Objetivos y retos de la organización"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "Comunidad",
    GRUPPI : "grupos",
    TUTTI : "Todos los grupos/clases de la comunidad."
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "Contexto de la organización",
    INFO : "Información sobre la zona o zonas afectadas por el proceso",
    NOSCENARI : "No hay escenarios"
  },
  PROCESSMENU : {
    ABOUT : "",
    ASSISTENZA : "",
    BACK : "",
    COMMUNITY : "",
    FAQ : "",
    ISTRUZIONI : "",
    NARRAZIONE : "",
    POP1 : "",
    POP1DESCRIPTION : ""
  },
  PROCESSPHASE : {
    ALERTBOZZA : "",
    BREADCRUMBTITLE : "",
    COMMENTIMENONUMEROSI : "Meno commentate",
    COMMENTIPIUNUMEROSI : "Più commentate",
    CONTATOREMODULI : "",
    IDEE : "",
    INFORMAZIONI : "",
    NAVIGATUTTELEPROPOSTE : "",
    NONCISONOPROPOSTE : "",
    PS : "",
    SCEGLIDOVEPARTECIPARE : ""
  },
  PS : {
    ALERTMODIFICADISABILITATA : "<h4>No es posible editar el perfil.</h4>.",
    ALERTMODIFICADISABILITATATITLE : "<b>La edición de tu perfil está desactivada</b>.",
    ANNUALINCOME : "Ingresos anuales",
    ATTEGGIAMENTO : "Actitud",
    BIOGRAPHY : "Biografía",
    BUDGET : "Presupuesto",
    BUDGETTODONATE : "Presupuesto para donar",
    COMPORTAMENTO : "Comportamiento",
    EDU : "Educación",
    EDULAV : "Educación y trabajo",
    FEMMINA : "Mujer",
    LAV : "Trabajo",
    MASCHIO : "Hombre",
    MODIFICA : "",
    NS : "No se especifica",
    POLVIS : "Visión política",
    PROFILE : "Su perfil personal",
    PS : "Espacio personal"
  },
  PSACCOUNT : {
    ANONIMO : "",
    AS : "Configuración de la cuenta",
    CITIZENSHIP : "Ciudadanía",
    COGNOME : "",
    COGNOMENONPRESENTE : "",
    ELIMINAACCOUNT : "<h4> Escríbenos a <a href=\"mailto:support@bipart.it\">support@bipart.it</a> y eliminaremos los datos de tu cuenta.</h4>",
    ELIMINAACCOUNTTITLE : "<b>¿Quieres eliminar tu cuenta?</b>.",
    EMAIL : "",
    EMAILNONPRESENTE : "",
    NOME : "",
    NOMENONPRESENTE : "",
    PASSWORD : "",
    PHONE : "",
    PHONENONPRESENTE : "",
    PRIVATO : "privado",
    PS : "Espacio personal",
    PUBBLICO : "público",
    RESIDENCECITY : "Ciudad de residencia",
    TAXCODE : "",
    TAXCODENONPRESENTE : "",
    TUOPROFILO : "Su perfil es",
    USERNAME : "",
    USERNAMENONPRESENTE : ""
  },
  PSACTIVITIES : {
    ALERTDETAIL : "<h4>En este apartado encontrarás la lista de tus propuestas y las propuestas que has votado.</h4>.",
    ALERTINFO : "<b>Información</b>",
    ATTIVITA : "Todas sus actividades",
    BUDGETDONATO : "Presupuesto donado",
    FOLLOWERS : "Seguidores",
    IDEESEGUITE : "Propuestas seguidas",
    IDEEVOTATE : "Propuestas votadas",
    INBOZZA : "Borrador",
    MODIFICAVOTO : "Cambiar el voto",
    NOPROCESSI : "",
    NOPROCESSIATTIVI : "Non ci sono processi attivi",
    PS : "Espacio personal",
    PUBBLICATA : "Publicado",
    TUEPROPOSTE : "Tus contribuciones",
    TUOIPROCESSI : "Tus procesos participativos",
    TUOIVOTI : "Tus votos",
    VISUALIZZA : "Ver",
    VOTIESPRESSI : "Votos emitidos"
  },
  PSEDIT : {
    AS : "Configuración de la cuenta",
    CONFERMAPASSWORD : "Confirmación de la contraseña",
    DRAGANDDROP : "Arrastre y suelte para subir su avatar (tamaño máximo de 5 MB)",
    ERRORE : "",
    ERROREPASS : "Las dos contraseñas deben coincidir y no pueden estar en blanco",
    FORMSUPPORTATI : "Los formatos admitidos son JPG, GIF y PNG",
    MODAVATAR : "Editar avatar",
    MODIFICADATI : "Edita tu información personal en tu Espacio Personal",
    MODOK : "El usuario ha sido editado con éxito",
    MODPASS : "Cambiar la contraseña",
    NUOVAPASSWORD : "Nueva contraseña",
    PRIVACY : "Privacidad y política",
    PROFILOPUBBLICO : "Perfil público",
    PS : "Espacio personal",
    SALVA : "Guardar",
    SUCCESSO : "La contraseña ha sido cambiada con éxito"
  },
  PSEDIT2 : {
    AS : "Ajustes",
    ATTEGGIAMENTO : "Actitud",
    BIOGRAFIA : "Biografía",
    BUDGET : "Presupuesto",
    BUDGETDADONARE : "Presupuesto para donar",
    COMPORTAMENTO : "Comportamiento",
    EDU : "Educación",
    EDULAV : "Educación y trabajo",
    INFOVARIE : "Otros datos",
    LAV : "Trabajo",
    PS : "Espacio personal",
    REDDITO : "Ingresos anuales",
    SALVA : "Guardar",
    VISIONEPOLITICA : "Visión política"
  },
  QUICKPANEL : {
    VOTE: "Vote {n}",
    VOTEDON: "Voted on {n}",
    EDITVOTE: "Edit vote",
    DONATION: "Donation"
  },
  QUICKUSER : {
    ACCOUNT : "Cuenta",
    AGGIORNAPROFILOEPRIVACY : "Actualiza tu perfil y la política de privacidad",
    IDEEVOTATE : "Todas las ideas por las que has votado",
    PROCESSIPARTECIPATIVI : "Procesos participativos",
    SALUTO : "Hola",
    SIGNOUT : "Cerrar Sesión",
    VOTI : "Tus votos"
  },
  REGISTER : {
    ACCETTOLA : "Acepto la",
    ATTENZIONE : "Atención",
    BACCEDI : "Acceder",
    BACCETTO : "He leído y acepto",
    BREGISTRA : "registrar",
    COGNOMEDIMENTICATO : "Has olvidado poner tu apellido",
    GIAACCOUNT : "¿Ya tiene una cuenta?",
    MAILDIMENTICATA : "Has olvidado introducir tu correo electrónico",
    MAILNONVALIDA : "Dirección de correo electrónico no válida.",
    NEWSLETTER : "Quiero estar al día de los proyectos y procesos activos en BiPart",
    NOMEDIMENTICATO : "Has olvidado introducir tu nombre",
    PASSWORDDIMENTICATA : "Has olvidado introducir tu contraseña",
    PLACEHOLDERCOGNOME : "Apellido",
    PLACEHOLDEREMAIL : "Correo electrónico",
    PLACEHOLDERNOME : "Nombre ",
    PLACEHOLDERPASSWORD : "Contraseña",
    PLACEHOLDERUTENTE : "Nombre de usuario",
    PRIVACY : "Política de privacidad",
    TERMINICONDIZIONI : "Condiciones de uso",
    USERNAMEDIMENTICATO : "Has olvidado introducir tu nombre de usuario",
    USERNAMEFORBIDDENCHARACTERS : "El nombre de usuario no debe contener los siguientes caracteres: @",
    USERNAMEMINLENGTH : "El nombre de usuario debe tener al menos 3 caracteres"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "He leído y acepto",
    ACCETTOLA : "He leído y acepto",
    BCONFERMA : "confirmación",
    DETTMEX : "Hemos enviado un correo electrónico* a tu bandeja de entrada.<br><span class=\"font-weight-bolder\">Confírmalo</span> para unirte a la organización.</p><p class=\"font-weight-bolder\">* ¡Ten cuidado! Comprueba también tu carpeta de spam.",
    ERR1 : "No has introducido los datos necesarios. Por favor, inténtalo de nuevo o escribenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR2 : "Oops... algo no funcionó; Código de estado: ",
    GIAACCOUNT : "¿Ya tiene una cuenta?",
    GRAZIE : "Gracias por unirse a nuestra comunidad",
    ISCRIVITIPROCESSO : "Inscríbase en la prueba",
    NEWSLETTER : "Quiero estar al día de los proyectos y procesos activos en BiPart",
    PRIVACY : "Política de privacidad",
    REQUIREDFIELD : "Campo obligatorio",
    TERMINI : "términos y condiciones",
    TITLE2 : "Atención",
    TITLE3 : "Error",
    VALIDEMAIL : "Debe ser una dirección de correo electrónico válida"
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "Acepto la",
    BACCEDI : "Acceder",
    BACCETTO : "Acepto",
    BCHIUDI : "Cerrar",
    BREGISTRA : "INSCRIPCIÓN",
    COGNOMEDIMENTICATO : "Has olvidado poner tu apellido",
    ERR1 : "Oops... algo no funcionó; Código de estado: ",
    FAIGIAPARTE : "¿Ya te registraste o tienes las credenciales para participar?",
    ISCRIVITIPROCESSO : "Regístrate para la organización y para el proceso",
    MAILNONVALIDA : "Dirección de correo electrónico no válida.",
    NEWSLETTER : "Quiero estar al día de los proyectos y procesos activos en BiPart",
    NOMEDIMENTICATO : "Has olvidado introducir tu nombre",
    PASSWORDDIMENTICATA : "Has olvidado introducir tu contraseña",
    PRIVACY : "Política de privacidad",
    TERMINICONDIZIONI : "Condiciones de uso",
    TITLE1 : "Error",
    USERNAMEDIMENTICATO : "Has olvidado introducir tu nombre de usuario"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "Inicio de sesión con éxito",
    ACCETTO : "Acepto",
    ACCETTOLA : "Acepto la",
    AUTORIZZATO : "Está autorizado a participar en nuestra comunidad",
    BCONFERMA : "confirmación",
    DETTMEX : "Hemos enviado un correo electrónico* a tu bandeja de entrada.<br><span class=\"font-weight-bolder\">Confírmalo</span> para unirte a la organización.</p><p class=\"font-weight-bolder\">* ¡Ten cuidado! Comprueba también tu carpeta de spam.",
    ERR1 : "No has introducido los datos necesarios. Por favor, inténtslo de nuevo o escribenos a <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR3 : "Oops... Se ha producido un error inesperado.",
    ERR4 : "Correo electrónico no válido",
    ERR5 : "Aceptación del registro de la \"Privacidad\" y de los \"Términos y condiciones\"",
    ES : "Ex: Faenza Participación 2020/21",
    GIAACCOUNT : "¿Ya tiene una cuenta?",
    GRAZIE : "Gracias por unirse a nuestra comunidad",
    HTMLMAIL : "Aquí encontrará el correo html",
    ISCRIVITIPROCESSO : "Inscríbase en la prueba",
    NEWSLETTER : "Quiero estar al día de los proyectos y procesos activos en BiPart",
    NIENTEACCOUNT : "¿Aún no tienes una cuenta?",
    NOME : "Nombre (opcional)",
    ORGANIZATION : "BiPart",
    PARTECIPAPROCESSO : "¿Quiere formar parte del proceso?",
    PRIVACY : "Política de privacidad",
    PROPOSTE : "Para realizar envíos, comentarios, solicitar valoraciones, votar, debes inscribirte en el proceso.",
    QUIMAIL : "Aquí encontrará el correo electrónico html enviado",
    REGISTRATI : "Registro",
    TERMINI : "términos y condiciones",
    TITLE1 : "Enhorabuena",
    TITLE2 : "Atención",
    TITLE3 : "Error",
    TITOLO : "Bipart"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "Acceder",
    ACCETTO : "Acepto",
    ACCETTOLA : "Acepto la",
    BPROSEGUI : "Continuar",
    CAMBIAPASS : "Cambiar contraseña",
    ERR3 : "Oops... Se ha producido un error inesperado.",
    ERR4 : "Correo no válido",
    ERR5 : "Credenciales no válidas",
    INSERISCIMAIL : "Introduzca su correo electrónico",
    ISCRIVITIPROCESSO : "Inscríbase en la prueba",
    MEX1 : "Aceptación de la inscripción \"Privacidad\" y \"Términos y condiciones\"",
    MEX2 : "Autenticación exitosa",
    MEXLOGIN : "<b>Bienvenido a BiPart,</b><br>La plataforma de participación ciudadana.<br>Tus datos serán tratados con pleno respeto a la normativa italiana y europea y con más cuidado aún para proteger tus opiniones y tus decisiones.<br><b>Si quieres continuar, lee y acepta los dos documentos que aparecen a continuación.</b>",
    NIENTEACCOUNT : "¿Aún no tienes una cuenta?",
    NONRICORDIPASS : "¿No recuerdas tu contraseña?",
    NOTIFICHE : "*Si desea recibir notificaciones sobre el progreso de las propuestas presentadas.",
    PRIVACY : "Política de privacidad",
    REGISTRATI : "Registro",
    TERMINI : "Condiciones de servicio",
    TERMINICONDIZIONI : "Para continuar, por favor revisa los <a href=\"https://bipart.it/terms\" target=\"blank\">Términos y condiciones del servicio </a><br> y la <a href=\"https://bipart.it/privacy\" target=\"blank\">Política de privacidad.</a>.",
    TITLE1 : "Éxito",
    TITLE2 : "Atención",
    TITLE3 : "Error",
    UTENTEAUTENTICATO : "-- Usuario autentificado --"
  },
  SERVICEDETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato al servizio",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questo servizio",
    ALERT14 : "Il servizio è già stato valutato",
    ALERT3TITLE : "Grazie per aver votato questo servizio",
    ALERT4 : "Naviga tra i servizi e scegli quali altre preferisci di più",
    ALERT5 : "Puoi continuare a donare anche per altri servizi scegliendo come distribuire il tuo budget a disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto il servizio!",
    ALERT6TITLE : "Stai seguendo questo servizio!",
    ALERT7TITLE : "Non stai più seguendo questo servizio",
    ALERT8TITLE : "Sei sicuro di voler cancellare questo servizio?",
    ALERT9 : "Il servizio è stato eliminato",
    ALERT9TITLE : "Eliminazione servizio",
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Ricordati di pubblicarla",
    ALERTPUBBLICATA : "servizio in fase di revisione. Collabora al suo sviluppo",
    AUTORE : "Referente",
    CANVERSION2 : "Stai creando la versione {n} del tuo servizio",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questo servizio",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the service does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the service because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questo servizio!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questo servizio!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questo servizio!",
    IDEAVOTATA : "servizio votato",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questo servizio",
    NARRAZIONE : "Il servizio",
    PREFERENZEPERPREMIARE : "preferenze per premiare i servizi migliori!",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altri servizi, scegliendo come distribuire il tuo budget.",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altri servizi.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per gli altri servizi",
    VERSIONIPROPOSTA : "Versioni del servizio:",
    WALLETSOSTIENIIDEA : "Sostieni questo servizio. Fai una donazione!"
  },
  SERVICELIST : {
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING2 : "È possibile migliorare un servizio fornendo o raccogliendo spunti e descrivendolo tramite nuove versioni sempre più aggiornate",
    CREAIDEA : "Pubblica il tuo servizio",
    ELENCOIDEE : "Tutti i servizi",
    IDEAVOTATA : "Hai votato questo servizio! ",
    IDEE : "servizi",
    NONCISONOIDEE : "Non è presente ancora alcun servizio. Pubblica il tuo!",
    VOTAZIONEATTIVA2 : "Guarda tutti i servizi ed esprimi le tue preferenze",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare i tuoi servizi preferiti"
  },
  STORYDETAIL : {
    ALERT1 : "",
    ALERT13 : "",
    ALERT14 : "",
    ALERT3TITLE : "",
    ALERT4 : "",
    ALERT5 : "",
    ALERT5TITLE : "",
    ALERT6TITLE : "",
    ALERT7TITLE : "",
    ALERT8TITLE : "",
    ALERT9 : "",
    ALERT9TITLE : "",
    ALERTBOZZA : "",
    ALERTPUBBLICATA : "",
    AUTORE : "Written by",
    CANVERSION2 : "",
    CLICCAILPULSANTE : "",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the story does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the story because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "",
    GRAZIESOSTEGNO : "",
    GRAZIESOSTEGNOANCORA : "",
    IDEAVOTATA : "",
    INSERISCICIFRA : "",
    NARRAZIONE : "",
    PREFERENZEPERPREMIARE : "",
    SOSTIENIALTREIDEE : "",
    TOGLIEREPREFERENZA : "",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "",
    VERSIONIPROPOSTA : "",
    WALLETSOSTIENIIDEA : ""
  },
  STORYLIST : {
    ALERTBOZZA : "",
    ALERTVERSIONING2 : "",
    CREAIDEA : "",
    ELENCOIDEE : "",
    IDEAVOTATA : "",
    IDEE : "",
    NONCISONOIDEE : "",
    VOTAZIONEATTIVA2 : "",
    VOTAZIONEATTIVAUTENTEANONIMO : ""
  },
  TACTICDETAIL : {
    ALERT1 : "",
    ALERT13 : "",
    ALERT14 : "",
    ALERT3TITLE : "",
    ALERT4 : "",
    ALERT5 : "",
    ALERT5TITLE : "",
    ALERT6TITLE : "",
    ALERT7TITLE : "",
    ALERT8TITLE : "",
    ALERT9 : "",
    ALERT9TITLE : "",
    ALERTBOZZA : "",
    ALERTPUBBLICATA : "",
    AUTORE : "",
    CANVERSION2 : "",
    CLICCAILPULSANTE : "",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the tactic does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the tactic because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "",
    GRAZIESOSTEGNO : "",
    GRAZIESOSTEGNOANCORA : "",
    IDEAVOTATA : "",
    INSERISCICIFRA : "",
    NARRAZIONE : "",
    PREFERENZEPERPREMIARE : "",
    SOSTIENIALTREIDEE : "",
    TOGLIEREPREFERENZA : "",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "",
    VERSIONIPROPOSTA : "",
    WALLETSOSTIENIIDEA : ""
  },
  TACTICLIST : {
    ALERTBOZZA : "",
    ALERTVERSIONING2 : "",
    CREAIDEA : "",
    ELENCOIDEE : "",
    IDEAVOTATA : "",
    IDEE : "",
    NONCISONOIDEE : "",
    VOTAZIONEATTIVA2 : "",
    VOTAZIONEATTIVAUTENTEANONIMO : ""
  },
  TERMS : {
    ATTIVITA : "3. ATTIVITA’",
    ATTIVITADESCR1 : "Oggetto delle attività dell'Utente è qualsiasi iniziativa volta a contribuire alla vita, allo sviluppo e al benessere della propria città o ente di appartenenza;\nA titolo esemplificativo, le attività possono riguardare: l’inserimento di idee e segnalazioni, la discussione, la progettazione, la condivisione di idee, di link e di documenti, la votazione di idee e progetti e temi pubblici, eccetera.\nL’attività svolta deve attenersi ad argomenti di pubblica rilevanza e svolgersi nel pieno rispetto della normativa vigente.\nL'Utente dichiara di essere titolare di ogni diritto eventualmente connesso alla propria attività (a titolo meramente esemplificativo e non esaustivo: idee, progetti, fotografie, filmati audio/ video etc.).\nBiPart si riserva il diritto di eliminare dalla Piattaforma BiPart in maniera insindacabile qualsiasi elemento introdotto dall’Utente, come pure modificare o oscurare elementi non essenziali dello stesso qualora essa possa ledere il diritto di soggetti terzi o di una collettività di persone.\nL'Utente registrato accetta di partecipare anche alla eventuale realizzazione di classifiche inerenti alle attività, nel pieno rispetto della normativa sulla privacy (a titolo meramente esemplificativo e non esaustivo: classifiche per numero, tipologia e qualità delle proposte, commenti, etc.).\nL'Utente registrato accetta che le proprie attività possano essere pubblicate da BiPart anche su altri siti web non necessariamente di proprietà di BiPart (a titolo esemplificativo non esaustivo: Facebook, Twitter etc.) ma sempre e solo per le finalità legate al progetto culturale alla base della “Piattaforma BiPart”.",
    COMUNICAZIONI : "7. COMUNICAZIONI",
    COMUNICAZIONIDESCR1 : "L'Utente prende atto ed accetta che tutte le eventuali comunicazioni, notificazioni, informazioni e comunque ogni documentazione relativa alle operazioni eseguite e riferite all'utilizzo della “Piattaforma BiPart” verranno inviate all’indirizzo di posta elettronica ovvero agli eventuali ulteriori recapiti indicati dall'Utente durante la procedura di registrazione.",
    LIMITAZIONI : "6. LIMITAZIONI DI RESPONSABILITA'",
    LIMITAZIONIDESCR1 : "BiPart non risponde dei danni diretti, indiretti o consequenziali subiti dall'Utente o da terzi in dipendenza delle attività svolte all’interno della “Piattaforma BiPart” e/o per l'utilizzo dei Servizi di Comunicazione e/o per danni di qualsiasi genere o a qualsiasi titolo connessi con dette attività e a tal fine l'Utente dichiara di manlevare BiPart da ogni forma di responsabilità. Il materiale inviato dall'Utente non sarà più restituito.",
    MODIFICA : "8. MODIFICA DELLE CLAUSOLE DI UTILIZZO",
    MODIFICADESCR1 : "BiPart si riserva il diritto di modificare i termini, le condizioni, e le comunicazioni ai sensi dei quali viene offerta la “Piattaforma BiPart” ed a cui saranno tenuti a conformarsi anche gli Utenti già registrati.",
    OGGETTO : "1. OGGETTO E DEFINIZIONI",
    OGGETTODESCR1 : "Le presenti Condizioni d'Uso (CdU) regolano e disciplinano l'utilizzo della Piattaforma BiPart da parte dell'Utente nonché le responsabilità di quest'ultimo relativamente all'utilizzo della piattaforma anzidetta.Per BiPart si intende una piattaforma tramite la quale l'Utente può partecipare alle attività rese possibili dalla piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione, eccetera). Detta piattaforma è di esclusiva proprietà di BiPart Impresa sociale srl (già BiPart Srls) (BiPart) con sede in Milano, Via Metauro, 4 (codice fiscale e partita IVA: 09444470968).\nPer Utente si intende la persona fisica registrata ai sensi dell'art. 2 che interagisce con gli strumenti partecipativi messi a disposizione sulla Piattaforma BiPart.<br>Le presenti clausole di utilizzo si applicano ad ogni tipo di attività svolta dall’Utente.",
    REGISTRAZIONE : "2. REGISTRAZIONE",
    REGISTRAZIONEDESCR1 : "Per poter partecipare secondo quanto indicato all'articolo precedente, l'Utente deve necessariamente registrarsi tramite l'apposita procedura di registrazione;\nL'Utente si assume ogni responsabilità civile e penale per l’eventuale falsità o non correttezza delle informazioni e dei dati comunicati.",
    RESPONSABILITA : "4. RESPONSABILITA' DELL'UTENTE",
    RESPONSABILITADESCR1 : "L'Utente si assume ogni responsabilità nonché ogni conseguenza diretta o indiretta derivante dalle attività svolte e pubblicate sulla piattaforma web, nonché riconosce la propria esclusiva responsabilità per eventuali danni cagionati a terzi (a titolo meramente esemplificativo e non esaustivo, per la violazione di diritti d’autore o altri diritti di proprietà, diritti relativi alla riservatezza delle persone, etc.).\nL'Utente si impegna non inserire materiale o estratti di materiale:",
    RESPONSABILITADESCR2 : "<li>coperti da diritto d'autore e di cui non sia esso stesso titolare;</li><li>contrari alla morale e l'ordine pubblico, ovvero in grado di turbare la quiete pubblica o privata o di recare offesa, o danno diretto o indiretto a chiunque o ad una specifica categoria di persone (per esempio è vietato l’inserimento di materiali o estratti di materiale che possano ledere la sensibilità di gruppi etnici o religiosi, etc.);</li><li>contrario al diritto alla riservatezza di soggetti terzi; • lesive dell'onore, del decoro o della reputazione di soggetti terzi; \n</li><li> comunque contrari alla legge.</li>",
    SOTTOTITOLO : "Condizioni d'uso della piattaforma",
    TITOLO : "Termini e condizioni",
    UTILIZZO : "UTILIZZO",
    UTILIZZODESCR1 : "La “Piattaforma BiPart” potrà contenere ulteriori servizi di comunicazione quali bacheche elettroniche, aree chat, newsgroup, pagine web personali, calendari, e/o altri servizi creati per abilitare l’utente a comunicare con altri soggetti o con un gruppo (collettivamente, \"Servizi di Comunicazione\"). L’Utente accetta di usare i Servizi di Comunicazione solamente per spedire, inviare e ricevere messaggi e materiale appropriato e relativo al particolare Servizio di Comunicazione. A titolo di esempio, e non come limitazione, durante l’uso di un Servizio di Comunicazione, l’utente accetta di, e si impegna a, non:",
    UTILIZZODESCR2 : "<li>pubblicare, inviare, distribuire o disseminare nomi, materiali o informazioni non appropriati, profanatori, diffamatori, che violino i diritti altrui, osceni, indecenti o illegali;</li><li>pubblicare file che contengono software o altro materiale protetto da leggi di proprietà intellettuale (o dai diritti di privacy di pubblicità) a meno che l’utente sia titolare di tali diritti o ne possieda il controllo o abbia ricevuto tutti i consensi necessari;</li><li>pubblicare file che contengono virus, file danneggiati, o altri software o programmi simili che potrebbero danneggiare l’operatività dei computer di altri;</li><li>pubblicizzare o offrire per la vendita o comprare qualsiasi prodotto o servizio per qualunque scopo commerciale, salvo il caso in cui tale Servizio di Comunicazione permetta specificamente tali messaggi; </li><li>condurre o inoltrare sondaggi, contenuti, diagrammi a piramide o lettere a catena;</li><li>scaricare qualunque file inviato da un altro utente di un Servizio di Comunicazione che l’utente sappia, o ragionevolmente dovrebbe sapere, che non può essere legalmente distribuito in tale maniera;</li><li>falsificare o cancellare qualunque attribuzione d’autore, le comunicazioni di contenuto legale o altre comunicazioni appropriate o designazioni di proprietà o denominazioni d’origine o fonte del software o altro materiale contenuto in un file che è caricato;</li><li>limitare o inibire ad altri utenti l’utilizzo e il godimento dei Servizi di Comunicazione;</li><li>violare codici di condotta o altre modalità d’utilizzo che potrebbero essere applicabili per qualunque particolare Servizio di Comunicazione;</li><li>raccogliere o mettere insieme informazioni di altri, incluso indirizzi e-mail, senza il loro consenso; </li><li>violare qualunque legge o regolamento applicabile.</li>",
    UTILIZZODESCR3 : "BiPart non ha l’obbligo di monitorare i Servizi di Comunicazione. Tuttavia, BiPart si riserva il diritto di visionare i materiali inviati tramite uno dei Servizi di Comunicazione e di togliere qualunque materiale a sua unica ed insindacabile discrezione. BiPart si riserva il diritto di sospendere l’accesso dell’utente ad uno, a più o atutti i Servizi di Comunicazione in qualunque momento senza preavviso per qualunque motivo. I materiali pubblicati su di uno dei Servizi di Comunicazione possono essere soggetti a limitazioni impostate sul loro utilizzo, riproduzione e/o disseminazione. L’Utente è responsabile per avere aderito a tali limitazioni qualora scarichi i materiali."
  },
  TRANSLATOR : {
    SELECT : "Selecciona tu idioma"
  },
  USERDETAIL : {
    BIOGRAFIA : "Biografía",
    COMMUNITY : "",
    EDUCAZIONE : "Educación",
    EDULAVORO : "Educación y trabajo",
    ETA : "Edad",
    F : "Mujer",
    LAVORO : "Trabajo",
    M : "Hombre",
    PROPOSTEEFFETTUATE : "",
    UTENTE : "Usuario"
  }
};
