import Vue from "vue";
import ApiService from "@/core/services/api.service";

// action types
export const SET_ORGANIZATION = "setOrganization";

// const ORGANIZATION_DATA = "organization_data";

const state = {
  currentOrganization: []
};

const getters = {
  /**
   * Get organization setting
   * @param state
   * @returns {*}
   */
  getOrganization(state) {
    return state.currentOrganization;
  }
};

const actions = {
  [SET_ORGANIZATION](context) {
    // // Cerco i dati dell'organizzazione nel local storage
    // let current_organization = window.localStorage.getItem("organization_data");
    // let lsrenew = true;
    //
    // if( current_organization != 'undefined' && current_organization !== null ) {
    //   let cod = JSON.parse(current_organization);
    //   if(cod.domain == Vue.prototype.$currentDomain) {
    //     console.log('Valore organization LS; cod_domain:' + cod.domain + ' - CD: ' + Vue.prototype.$currentDomain );
    //     context.commit(SET_ORGANIZATION, cod);
    //     lsrenew = false;
    //   }
    // }
    //
    // if(lsrenew) {
      return new Promise((resolve, reject) => {

          var endpoint = Vue.prototype.generateUrl('public/organizations', true);
          //generateUrl is done
          ApiService.get(Vue.prototype.$apiResourceOpen, endpoint + "?domain.contains=" +  Vue.prototype.$currentDomain)
            .then(resp => {

              // if (resp.data) {
                // Salvo i dati dell'organizzazione nel local storage
                let current_organization = {
                  "domain": Vue.prototype.$currentDomain,
                  "data": (resp.data.length>0) ? resp.data[0] : ''
                }
                // window.localStorage.setItem(ORGANIZATION_DATA, JSON.stringify(current_organization));
                // Valorizzo lo stato
                context.commit(SET_ORGANIZATION, current_organization);
              // }
              resolve(resp);
            })
            .catch(({e}) => {
              console.log("ERRORE:", e.response);
              reject(0);
            });
      });
    // }
  }
};

const mutations = {
  [SET_ORGANIZATION](state, payload) {
    state.currentOrganization = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
