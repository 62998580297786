<template>
  <router-view></router-view>
</template>

<script>
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";

export default {
  name: "MetronicVue",
  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.multiselect__tags {
  min-height: calc(1.5em + 1.65rem + 2px) !important;
  display: block;
  padding: 12px 40px 0 8px !important;
  border-radius: 5px;
  border: 1px solid #eef0f8 !important;
  background: #fff;
  font-size: 14px;
}
.multiselect__select {
  height: 40px !important;
}

// 3rd party plugins css
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");

// Check documentation for RTL css
/*@import "assets/css/style.vue.rtl";*/

.form-control-solid.is-invalid {
  border-color: #f64e60 !important;
}

.form-control-solid.is-valid {
  border-color: #1BC5BD !important;
}
</style>
