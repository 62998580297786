// EL
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "Είμαι ήδη εγγεγραμμένος",
    ACCEDICOMM2 : "Κάντε κλικ εδώ και συνδεθείτε με το λογαριασμό σας",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    REGISTRATICOMM1 : "Θέλω να είμαι μέρος της κοινότητας",
    REGISTRATICOMM2 : "Κάντε κλικ εδώ αν θέλετε να συνδεθείτε στο BiPart με το email σας και να δημιουργήσετε ένα προφίλ.",
    SOLOVOTO : "Θέλω μόνο να ψηφίσω",
    SOLOVOTO2 : "Κάντε κλικ εδώ αν θέλετε να εγγραφείτε στο BiPart χρησιμοποιώντας μόνο το κινητό σας τηλέφωνο"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "Attenzione",
    INDIETRO : "Πίσω",
    INSERISCINUOVONUMERO : "Inserisci<br /> nuovo numero",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    PH1 : "Κινητό",
    PHONEERROR1 : "Ξεχάσατε να συμπεριλάβετε τον ταχυδρομικό κώδικα",
    PHONEERROR2 : "Ξεχάσατε να εισάγετε τον αριθμό του κινητού σας τηλεφώνου;",
    PHONEERROR3 : "Ο αριθμός που πληκτρολογήσατε είναι λίγο περίεργος... ελέγξτε τον! :)",
    PRIVACY : "Έχω διαβάσει και συμφωνώ με τους <a href=\"https://bipart.it/privacy\" target=\"_blank\"> Όρους και Προϋποθέσεις της Υπηρεσίας </a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\"> και την Πολιτική απορρήτου</a>.",
    PROCEDI : "Συνδεθείτε",
    RECUPERAPASSWORD : "Recupera<br/> password",
    TERMINIECONDIZIONI : "Έχω διαβάσει και συμφωνώ με τους <a href=\"https://bipart.it/terms\" target=\"_blank\">όρους και προϋποθέσεις</a>.",
    VERIFICA1 : "Ελέγξτε τα στοιχεία σας για να συμμετάσχετε",
    VERIFICA2 : "Πληκτρολογήστε το κινητό σας τηλέφωνο για να λάβετε τον κωδικό OPT μέσω SMS",
    EXIT: "Logout",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "<h4>Στείλαμε έναν κωδικό επαλήθευσης μέσω SMS στο κινητό σας τηλέφωνο.</h4>.",
    ALERT1TITLE : "Πληροφορίες",
    ERROROTPMAXCHAR : "Numero massimo di cifre inseribili 6",
    INDIETRO : "πίσω",
    OTPDIMENTICATO : "Ξεχάσατε να εισαγάγετε τον κωδικό otp",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    PH1 : "Κωδικός OTP*",
    PROCEDI : "Προχωρήστε",
    RICHIEDINUOVOCODICE1 : " <b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti ",
    RICHIEDINUOVOCODICE2 : " richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : " o scrivici a ",
    VERIFICA1 : "Ελέγξτε τα στοιχεία σας για να συμμετάσχετε",
    VERIFICA2 : "Έχουμε στείλει τον κωδικό επαλήθευσης OTP στον αριθμό του κινητού σας. Παρακαλώ εισάγετε τον για να συνεχίσετε"
  },
  ADVAUTHROLE : {
    CITTADINO : "Πολίτης",
    INDIETRO : "πίσω",
    INFOREGISTER : "Εκτός από το <b>κινητό σας τηλέφωνο</b>, στο οποίο θα λάβετε τον κωδικό πρόσβασης για να συνδεθείτε, θα σας ζητήσουμε να εισάγετε το <b>ΑΦΜ σας</b>.",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    PROCEDI : "Προχωρήστε",
    VERIFICA1 : "Ελέγξτε τα στοιχεία σας για να συμμετάσχετε",
    VERIFICA2 : "Επιλέξτε τον τρόπο επαλήθευσης της ταυτότητάς σας"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "Ουπς... κάτι πήγε στραβά- κωδικός Stauts:",
    ALERT1TITLE : "Σφάλμα",
    CFDIVERSO : "Εισάγατε διαφορετικό φορολογικό κώδικα από αυτόν που είναι ήδη συνδεδεμένος με το λογαριασμό σας;",
    CHIUDI : "Κλείσιμο",
    DIMENTICATOCF : "Ξεχάσατε να εισάγετε το ΑΦΜ σας",
    ERRORECF : "το ΑΦΜ δεν έχει εισαχθεί σωστά.",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    PH1 : "ΑΦΜ*",
    PROCEDI : "Προχωρήστε",
    VERIFICA1 : "Ελέγξτε τα στοιχεία σας για να συμμετάσχετε",
    VERIFICA2 : "<b>Γράψτε το ΑΦΜ σας για να συνεχίσετε</b><br>θα διασφαλίσουμε ότι θα συμμετέχουν μόνο μέλη της κοινότητας!"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "το ΑΦΜ σας δεν επιτρέπεται να συμμετέχει στη διαδικασία",
    CFNONABILIATO1 : "Ελέγξτε τους κανόνες της διαδικασίας και αν εντοπίσετε κάποιο λάθος, γράψτε στο support@bipart.it.",
    CHIUDI : "Κλείσιμο",
    UTENTENONAUTORIZZATO : "Μη εξουσιοδοτημένος χρήστης"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "Σας ευχαριστούμε για την εγγραφή σας στο BiPart.",
    CHIUDI : "Κλείσιμο",
    COMPLIMENTI : "Καλώς ήρθατε",
    UTENTEABILITATO : "Κρατήστε τα διαπιστευτήρια που στάλθηκαν στο κινητό σας τηλέφωνο μέσω SMS για να έχετε ξανά πρόσβαση στο BiPart!",
    UTENTEINFO : "Από τώρα και στο εξής μπορείτε να συμμετέχετε με έναν επαληθευμένο λογαριασμό, ώστε να αξιοποιείτε τα σημαντικότερα και πιο προχωρημένα χαρακτηριστικά"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "Προσοχή",
    ALERTINFO : "Δεν έχετε εισάγει τα απαιτούμενα στοιχεία. Προσπαθήστε ξανά ή γράψτε στη διεύθυνση <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    PARTECIPA : "Είσοδος και συμμετοχή στη διαδικασία",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    PASSWORDERRATA : "Λανθασμένος κωδικός",
    PH1 : "όνομα χρήστη*",
    PH2 : "κωδικός*",
    SUPPORT : "<i class=\"fas fa-info-circle\"></i> Εάν δεν θυμάστε τα διαπιστευτήριά σας, παρακαλούμε γράψτε στη διεύθυνση <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    USERNONESISTE : "Το όνομα χρήστη δεν υπάρχει"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "Λανθασμένα διαπιστευτήρια",
    ALERT1TITLE : "Προσοχή",
    CAMPIOBBLIGATORI : "* Τα πεδία που σημειώνονται με αστερίσκο είναι υποχρεωτικά.",
    COGNOMEDIMENTICATO : "Ξεχάσατε να εισάγετε το επώνυμό σας",
    INDIETRO : "ΠΙΣΩ",
    MAILDIMENTICATA : "Ξεχάσατε να εισάγετε το email σας",
    MAILNONVALIDA : "Μη έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου.",
    NEWSLETTER : "Θέλω να ενημερώνομαι για τις πρωτοβουλίες και τις ενεργές διεργασίες της BiPart.",
    NOMEDIMENTICATO : "Ξεχάσατε να εισάγετε το όνομά σας",
    PARTECIPA : "Εγγραφείτε και συμμετάσχετε στη διαδικασία",
    PARTECIPAPROCESSO : "Βρίσκεστε στον χώρο εργασίας της ακόλουθης οργάνωσης:",
    PASSWORDDIMENTICATA : "Ξεχάσατε να πληκτρολογήσετε τον κωδικό πρόσβασής σας",
    PH1 : "Όνομα*",
    PH2 : "Επώνυμο*",
    PH3 : "Όνομα χρήστη*",
    PH4 : "Email*",
    PH5 : "Κωδικός πρόσβασης*",
    PRIVACY : "Έχω διαβάσει και συμφωνώ με την <a href=\"https://bipart.it/privacy\" target=\"_blank\">Πολιτική απορρήτου</a>.",
    REGISTRA : "Εγγραφή",
    TERMINIECONDIZIONI : "Έχω διαβάσει και συμφωνώ με τους <a href=\"https://bipart.it/terms\" target=\"_blank\">όρους και προϋποθέσεις</a>.",
    USERNAMEDIMENTICATO : "Ξεχάσατε να εισάγετε το όνομα χρήστη σας"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "<h4>Η πρόταση δε μπορεί να ακολουθηθεί</h4>",
    ALERTCOMMENTEMPTY : "<h4>Κάνε εισαγωγή του κειμένου του σχόλιου</h4>",
    ALERTCOMMENTSENDED : "<h4>Σε ευχαριστούμε για την εισαγωγή του σχολίου σου και τη συνεισφορά σου στην πρόταση!</h4>",
    ALERTEMAILBLANK : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't your email address yet</h6><div>",
    ALERTEMAILCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Λογότυπο\" /></div><br /><h6 class=\"text-primary\">Δεν έχετε επιβεβαιώσει ακόμα τη διεύθυνση του ηλεκτρονικού ταχυδρομείου σας</h6><div>Επιβεβαιώστε την για να γίνετε μέλος της οργάνωσης και να εγγραφείτε στις συμμετοχικές διαδικασίες</div><div>Δε λάβατε το μήνυμα ηλεκτρονικού ταχυδρομείου; <br><strong>Κάντε κλικ στο κουμπί στην κόκκινη ζώνη για να σταλεί ξανά</strong></div>.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "Δημιούργησε και δημοσιοποίησε το προφίλ σου για να συμμετέχεις ενεργά στις διεργασίες της κοινότητάς σου",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "Καλωσήρθες στο BiPart!",
    ALERTEMAILMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the community and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    ALERTEMAILRESENDCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Λογότυπο\" /></div><br /><h5 class=\"text-primary\">Το email επιβεβαίωσης εστάλη εκ νέου στα εισερχόμενά σας</h5><div>Επιβεβαιώστε το για να γίνετε μέλος της οργάνωσης<br>και να εγγραφείτε στις συμμετοχικές διαδικασίες</div>.",
    ALERTERROR : "Ουπς... κάτι πήγε στραβά!",
    ALERTFORMDESCRIPTION : "<h4>Αναμονή ενεργοποίησης</h4>.",
    ALERTFORMTITLE : "<b>Το ερωτηματολόγιο δεν είναι ακόμη διαθέσιμο</b>",
    ALERTHELPDESCRIPTION : "<h4>Αν χρειάζεσαι βοήθεια, επικοινώνησε μαζί μας στο <a href=\"mailto:support@bipart.it\">support@bipart.it</a></h4>.",
    ALERTHELPTITLE : "<b>Helpdesk</b>",
    ALERTJAMBOARDDESCRIPTION : "<h4>Αναμονή ενεργοποίησης</h4>.",
    ALERTJAMBOARDTITLE : "<b>Το Jamboard δεν είναι ακόμη διαθέσιμο</b>",
    ALERTMEETDESCRIPTION : "<h4>Αναμονή ενεργοποίησης</h4>.",
    ALERTMEETTITLE : "<b>Το διαδικτυακό σεμινάριο δεν είναι ακόμη διαθέσιμο</b>",
    ALERTMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the community and sign up for participatory processes</div><div>You didn't receive the message? <br><strong>Click on the button in the red band to resend it</strong></div>",
    ALERTPERMISSIONDENIED : "<h4>Η άδεια δεν έγινε δεκτή</h4>.",
    ALERTUSERMODULENOTENABLED : "<h4>Γεια σου! Ίσως δεν έχεις τις απαραίτητες άδειες για να συμμετάσχεις. Διιάβασε τους κανόνες ή γράψε στο <a href=\"mailto:support@bipart.it\">support@bipart.it</a> ή σε άλλους διαθέσιμους διαύλους επικοινωνίας.</h4>.",
    ALERTUSERMODULENOTENABLEDBITMASK : "click <a href=\"/process-guidelines/{process_id}\" target=\"_blank\">here</a> to find out how you can participate, or ",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "<h4>Ops,<br>apparently you don't have permission to leave a comment;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "<h4>Ops,<br>apparently you don't have permission to make {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "<h4>Ops,<br>apparently you don't have permission to delete {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "<h4>Ops,<br>apparently do not follow this proposal;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "<h4>Ops,<br>apparently you don't have permission to send a message;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "<h4>Ops,<br>apparently you don't have permission to edit {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "<h4>Ops,<br>apparently you don't have permission to version {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERREGISTEREDTOORGANIZATION : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Λογότυπο\" /></div><br /><h5 class=\"text-primary\">Σας ευχαριστούμε που γίνατε μέλος της κοινότητάς μας</h5><div>Στείλαμε ένα email στα εισερχόμενά σας.<br>Επιβεβαιώστε το για να γίνετε μέλος της οργάνωσης</div><div>Προσοχή! Ελέγξτε επίσης το φάκελο spam.</div>.",
    ALERTUSERREGISTEREDTOPROCESS : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Λογότυπο\" /></div><br /><h5 class=\"text-primary\">Σας ευχαριστούμε που γίνατε μέλος της κοινότητάς μας</h5><div>Στείλαμε ένα email στα εισερχόμενά σας.<br>Επιβεβαιώστε το για να γίνετε μέλος της οργάνωσης</div><div>Προσοχή! Ελέγξτε επίσης το φάκελο spam.</div>.",
    PROPOSAL : "a proposal",
    SERVICE : "a service",
    STORY : "a story",
    TACTICS : "a tactic"
  },
  AUTHORITIES : {
    ASSESSOR : "Εκτιμητής",
    EXPERT : "Εμπειρογνώμονας"
  },
  BCPROCESSI : {
    CHALLENGE : "Θεματικές",
    COMMUNITY : "Κοινότητα",
    ELENCOPROCESSI : "Λίστα διεργασιών",
    PROCESSO : "Διεργασία",
    SCENARI : "Πλαίσιο"
  },
  COMMUNITY : {
    CERCA : "Αναζήτηση...",
    CHIPARTECIPA : "Συμμετέχοντες/-ουσες",
    GRUPPI : "Ομάδες",
    ISCRITTI : "Εγγεγραμμένα μέλη",
    PROFILI : "Προφίλ",
    SOTTOTITOLO : "Εδώ είναι τα προφίλ που έχουν δημοσιοποιηθεί: μπορείς να συμμετάσχεις κι εσύ!",
    TITOLO : "Κοινότητα"
  },
  COMMUNITYSPACE : {
    BOZZA : "Προσχέδιο",
    CS : "Χώρος κοινότητας",
    DESCRIZIONEIDEA : "Περιγραφή πρότασης",
    IDEE : "20 προτάσεις",
    NOMEAUTORE : "Όνομα συγγραφέα",
    TITOLOIDEA : "Τίτλος πρότασης",
    VISUALIZZAELENCO : "Προβολή λίστας",
    VISUALIZZAMAPPA : "Προβολή χάρτη"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "Θέλετε να ανακαλέσετε την ολοκλήρωση της γλωσσικής μετάφρασης;",
    ALERT1TITLE : "Προσοχή",
    ALERT2 : "Θέλετε να επιβεβαιώσετε την ολοκλήρωση της γλωσσικής μετάφρασης;",
    CANCEL : "ΑΚΥΡΩΣΗ"
  },
  COOKIE : {
    ANONIMI : "3. Cookie di analisi anonimi:",
    ANONIMIDESCR1 : "Questo sito utilizza cookie di Google Analytics con IP anonimizzato della durata di un anno. Sono definiti anonimi in quanto non possono essere utilizzati per identificare individui specifici. Più in particolare il sito nowhow.it utilizza GoogleAnalytics (Google, Inc.) che genera cookie secondo le modalità illustrate dal documento relativo alle Norme generiche sulla privacy e dal documento specifico in merito all’utilizzo delle informazioni raccolte con Google Analytics. Google non assocerà il vostro indirizzo IP a nessun altro dato posseduto da Google stessa. I termini di servizio ai quali tutti i clienti del programma devono aderire, vietano il monitoraggio o la raccolta di informazioni personali (dati di identificazione personale, come ad esempio nome, indirizzo email, dati di fatturazione o altri dati che possono essere ragionevolmente collegati a tali informazioni) mediante l’uso di Google Analytics o l’associazione di queste alle informazioni sull’analisi dei dati web. Questi cookie perseguono esclusivamente scopi statistici e raccolgono informazioni in sola forma aggregata.",
    COLLEGAMENTI : "6. Collegamenti utili",
    COLLEGAMENTIDESCR1 : "Per maggiori informazioni sui cookie ed il loro utilizzo su internet, potrete trovare utili i seguenti collegamenti:<br><br>www.allaboutcookies.org<br>www.microsoft.com/info/cookies.mspx<br>www.google.com/intl/it_it/analytics/privacyoverview.html",
    CONTENUTIESTERNI : "5. Visualizzazione di contenuti da piattaforme esterne",
    CONTENUTIESTERNIDESCR1 : "Questi servizi permettono di visualizzare contenuti ospitati su piattaforme esterne direttamente dalle pagine di questa Applicazione e di interagire con essi. Nel caso in cui sia installato un servizio di questo tipo, è possibile che, anche nel caso gli Utenti non utilizzino il servizio, lo stesso raccolga dati di traffico relativi alle pagine in cui è installato. Per maggiori informazioni sulle logiche e le modalità di trattamento dei dati raccolti dai social network, puoi leggere le note informative sulla privacy fornite dai soggetti che forniscono I servizi in questione:<br><br>Widget Video Youtube (Google Inc.) - Terms - Privacy<br>Facebook - Termini e condizioni - Privacy<br>Twitter - Termini e condizioni - Privacy<br>Vimeo - Termini e condizioni - Privacy<br>Slideshare - Termini e condizioni - Privacy<br>Linkedin - Termini e condizioni - Privacy<br>Instagram - Termini e condizioni - Privacy<br><br>Ricordati che puoi gestire le tue preferenze sui cookie anche attraverso il browser Se non conosci il tipo e la versione di browser che stai utilizzando, clicca su “Aiuto” nella finestra del browser in alto, da cui puoi accedere a tutte le informazioni necessarie.<br><br>Se invece conosci il tuo browser clicca su quello che stai utilizzando per accedere alla pagina di gestione dei cookie. Google Chrome, Mozilla Firefox, Apple Safari e Microsoft Windows Explorer.",
    COSASONO : "1. Cosa sono i cookie",
    COSASONODESCR1 : "I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, dove vengono memorizzati per essere ritrasmessi agli stessi siti in occasione di visite successive. I cookie sono utilizzati per diverse finalità, hanno caratteristiche diverse, e possono essere utilizzati sia dal titolare del sito che si sta visitando, sia da terze parti. Il Sito potrebbe contenere collegamenti ad altri siti Web che dispongono di una propria informativa sulla privacy che può essere diversa da quella adottata da nowhow.it e che quindi non risponde di questi siti. Di seguito troverai tutte le informazioni sui cookie installati attraverso questo sito, e le indicazioni necessarie su come gestire le tue preferenze riguardo ad essi.",
    DATIRACCOLTI : "O4. Dati personali raccolti: Cookie e Dati di utilizzo.O",
    DATIRACCOLTIDESCR1 : "Tutti i cookie tecnici non richiedono consenso, perciò vengono installati automaticamente a seguito dell’accesso al sito.",
    SOTTOTITOLO : "Gestione dei cookie",
    TITOLO : "Cookie policy",
    UTILIZZATI : "2. Cookie utilizzati da questo sito",
    UTILIZZATIDESCR1 : "Cookie relativi ad attività strettamente necessarie al funzionamento del sito e all’erogazione del servizio. Questo sito utilizza il cookie di sessione PHPSESSIONID generato dalle applicazioni basate sul linguaggio PHP. Questo identificatore viene utilizzato per mantenere le variabili di sessione utente."
  },
  DRIVERS : {
    ACTIVEPROCESSES : "Σε εξέλιξη",
    CHALLENGE : "Θέματα",
    CLOSEPROCESSES : "Συμπερασματικά",
    CLUSTER : "Ομαδοποίηση (cluster)",
    DESCRIZIONE : "Περιγραφή",
    ELENCOCHALLENGE : "Θεματικές",
    LINK : "Σύνδεσμος",
    PROCESSES : "Κατάλογος διεργασιών",
    TUTTITEMI : "Όλα τα θέματα συμμετοχής χωρίζονται σε ομάδες"
  },
  EMAILCONFIRM : {
    AUTOK : "Επιτυχής ταυτοποίηση",
    AUTOKDETAIL : "Τώρα μπορείς να συνδεθείς και να συμμετάσχεις στην κοινότητά μας",
    BLOGIN : "Σύνδεση",
    TITOLO : "BiPart"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "Να συμβάλλετε ενεργά στη βελτίωση της ζωής του δήμου σας. Μπορείτε να το κάνετε προτείνοντας μια δική σας ιδέα ή απλώς ψηφίζοντας αυτήν που βρίσκετε πιο ενδιαφέρουσα. Οι προτεινόμενες ιδέες πρέπει να είναι συγκεκριμένες",
    DETTAGLIOGLOSSARIO : "",
    FAQ : "ΣΥΧΝΈΣ ΕΡΩΤΉΣΕΙΣ",
    FAQ1 : "",
    FAQ10 : "",
    FAQ11 : "",
    FAQ12 : "",
    FAQ13 : "",
    FAQ14 : "",
    FAQ15 : "",
    FAQ16 : "",
    FAQ17 : "",
    FAQ18 : "",
    FAQ19 : "",
    FAQ2 : "",
    FAQ20 : "",
    FAQ21 : "",
    FAQ22 : "",
    FAQ23 : "",
    FAQ24 : "",
    FAQ25 : "",
    FAQ3 : "",
    FAQ4 : "",
    FAQ5 : "",
    FAQ6 : "",
    FAQ7 : "",
    FAQ8 : "",
    FAQ9 : "",
    GLOSSARIO : "",
    PERCHEPARTECIPARE : "",
    QUALITEMIPROGETTI : "",
    R1 : "",
    R10 : "",
    R11 : "",
    R12 : "",
    R13 : "",
    R14 : "",
    R15 : "",
    R16 : "",
    R17 : "",
    R18 : "",
    R19 : "",
    R2 : "",
    R20 : "",
    R21 : "",
    R22 : "",
    R23 : "",
    R24 : "",
    R25 : "",
    R3 : "",
    R4 : "",
    R5 : "",
    R6 : "",
    R7 : "",
    R8 : "",
    R9 : "",
    TEMI : ""
  },
  GENERALLABEL : {
    AL : "στο",
    ALTERMINEDELLAFASE : "στο τέλος της φάσης",
    DAL : "από το",
    FASE : "φάση",
    MANCANOANCORA : "Εξακολουθεί να λείπει",
    TERMINAIL : "Τελειώνει στο"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "Έχω διαβάσει και συμφωνώ",
    ACCETTOPROCESSOLINEEGUIDA2 : "Οδηγίες και κανόνες της συμμετοχικής διαδικασίας"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "Λίστα ομάδων",
    F : "θηλυκό",
    GRUPPO : "Ομάδα",
    M : "αρσενικό",
    MEMBRI : "Προφιλ",
    PUBBLICA : "Δημοσιεύστε το προφίλ σας και συστηθείτε στην κοινότητα!"
  },
  GROUPLIST : {
    COMMUNITY : "Κοινότητα",
    DDC : "ημερομηνία δημιουργίας",
    DUE : "δύο",
    GRUPPI : "ομάδες",
    TRE : "τρία",
    UNO : "ένα"
  },
  HOME : {
    ALERT1 : "<h4>Δείτε στην ενότητα <a href=\"/πώς λειτουργεί\">πώς λειτουργεί</a> για να μάθετε τι είναι ο συμμετοχικός προϋπολογισμός</h4>.",
    ALERT1TITLE : "<b>Πριν ξεκινήσουμε...</b>.",
    ALERT2 : "<h4>Κάτι δεν πάει καλά. Έχετε κάποια δυσκολία ή απλά θέλετε να πείτε ένα γεια; :) Γράψτε μας τώρα στο <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ASSISTENZA : "Βοήθεια",
    DATIGRAFICI : "Δεδομένα οργάνωσης",
    GETSTARTED : "Ξεκίνησε",
    GRUPPICOM : "Ομάδες/Κοινότητες",
    OBIETTIVI : "Θεματικές",
    ORGANIZZAZIONE : "Οργάνωση",
    ORGANIZZAZIONI : "Οργανώσεις",
    PROCESSIATTIVI : "Ενεργές διεργασίες",
    PROFILO : "Προφιλ",
    PROFILODETT : "Εδώ μπορείς να βρεις τις δραστηριότητές σου και να διαχειριστείς το προφίλ σου",
    SCENARI : "Γενικό πλαίσιο",
    UTENTIREG : "Εγγεγραμμένα μέλη",
    VOTANTI : "Ψηφοφόροι",
    VOTIRACCOLTI : "Συγκεντρωθείσες ψήφοι"
  },
  IDEADETAIL : {
    ALERT1 : "Συμπληρώστε τουλάχιστον ένα από τα υποχρεωτικά πεδία μεταξύ του status ή της βαθμολογίας που αποδίδεται στην πρόταση.",
    ALERT10TITLE : "Μη διαγράψετε",
    ALERT11 : "Αφού διαγραφεί, δεν είναι δυνατό να το επαναφέρετε.",
    ALERT11TITLE : "Θέλετε σίγουρα να διαγράψετε αυτό το σχόλιο;",
    ALERT12TITLE : "Το σχόλιο δεν ήταν δυνατό να διαγραφεί",
    ALERT13 : "Έχετε ήδη υποβάλει λεπτομερή αξιολόγηση για την παρούσα πρόταση.",
    ALERT13TITLE : "Προσοχή",
    ALERT14 : "Η πρόταση έχει ήδη αξιολογηθεί",
    ALERT14TITLE : "Προσοχή",
    ALERT15 : "Μόλις δημοσιευτεί, δεν θα μπορείτε να το επεξεργαστείτε.",
    ALERT15B1 : "Επιβεβαίωση",
    ALERT15B2 : "Ακύρωση",
    ALERT15TITLE : "Είστε σίγουροι ότι θέλετε να συμμετάσχετε σε αυτή την αξιολόγηση;",
    ALERT16 : "Ο αριθμός που εισάγετε δε μπορεί να περιέχει δεκαδικά",
    ALERT16TITLE : "Προσοχή",
    ALERT17 : "Ο αριθμός που εισάγετε δε μπορεί να περιέχει περισσότερα από 6 ψηφία",
    ALERT17TITLE : "Προσοχή",
    ALERT1TITLE : "Προσοχή",
    ALERT2 : "<h4>Κάτι δεν πάει καλά, έχετε κάποιες δυσκολίες ή απλά θέλετε να πείτε ένα γεια; :) Γράψτε μας τώρα στο <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ALERT3 : "Κάντε κλικ στο αστέρι <span class=\"symbol-rounded\">\n<span class=\"symbol-label font-size-h6 font-weight-bold bg-warning text-white\" style=\"width: 25px;height: 25px;\">\n<i class=\"fas fa-star text-white pr-0 pb-1\"></i>\n</span>\n</span> στο πλάι αν θέλετε να δείτε τη σύνοψη των ψήφων σας",
    ALERT3TITLE : "Σας ευχαριστούμε που ψηφίσατε την παρούσα πρόταση",
    ALERT4 : "Περιηγηθείτε στις προτάσεις και επιλέξτε αυτήν που θα θέλατε να ψηφίσετε",
    ALERT4TITLE : "Αφαιρέσατε την προτίμησή σας!",
    ALERT5 : "Μπορείτε να συνεχίσετε να κάνετε δωρεές σε άλλες προτάσεις, επιλέγοντας πώς θα διανέμετε το ποσό σας",
    ALERT5TITLE : "Σας ευχαριστούμε για την υποστήριξη της πρότασης!",
    ALERT6TITLE : "Ακολουθείτε αυτή την πρόταση!",
    ALERT7TITLE : "Δεν ακολουθείτε πλέον την πρόταση",
    ALERT8 : "Αφού διαγραφεί, δεν είναι δυνατό να το επαναφέρετε.",
    ALERT8B1 : "Ναι, να διαγραφεί!",
    ALERT8B2 : "Όχι, να μη διαγραφεί!",
    ALERT8TITLE : "Θέλετε σίγουρα να διαγράψετε αυτή την πρόταση;",
    ALERT9 : "Η πρόταση ακυρώθηκε",
    ALERT9TITLE : "Πρόταση εξάλειψης",
    ALERTBOZZA : "Έχετε ένα προσχέδιο της πρότασής σας. Θυμηθείτε να το δημοσιεύσετε",
    ALERTEMPTYVOTE1USERLEFTHTML : "<h4>Έχετε εξαντλήσει τον αριθμό των διαθέσιμων προτιμήσεων.<br />Θυμηθείτε ότι μπορείτε να αλλάξετε τις επιλογές σας μέχρι το τέλος της ψηφοφορίας.</h4>.",
    ALERTEMPTYVOTE1USERLEFTTITLE : "Ουπς!",
    ALERTOWNERMAILEDHTML1 : "<p>Στείλαμε το μήνυμά σας στο",
    ALERTOWNERMAILEDHTML2 : "που θα μπορεί να σας απαντήσει μέσω των στοιχείων επικοινωνίας που εισάγατε.<br />Στο μεταξύ, μπορείτε να συνεισφέρετε μέσω της συμμετοχής σας στην πλατφόρμα.</p>",
    ALERTOWNERMAILEDTITLE : "ΤΟ ΜΗΝΥΜΑ ΣΤΑΛΘΗΚΕ",
    ALERTPUBBLICATA : "Πρόταση υπό αναθεώρηση. Συμβολή στην ανάπτυξή του",
    APPROFONDISCI : "Διαβάστε περισσότερα",
    APPROVATA : "Εγκεκριμένο",
    AUTORE : "Published by",
    BUDGET : "Εκτιμώμενο κόστος:",
    CAMPOMANCANTE : "The field \"{field}\" is missing",
    CANCELLAIDEA : "Διαγραφή",
    CANVERSION : "Έχει επιτευχθεί ο μέγιστος αριθμός εκδόσεων",
    CANVERSION2 : "Δημιουργείτε την έκδοση {n} της πρότασής σας",
    CANVERSIONTITLE : "Προσοχή",
    CANVERSIONTITLE2 : "{count} είναι ο μέγιστος αριθμός εκδόσεων που μπορούν να δημοσιευθούν",
    CHALLENGE : "Θέματα",
    CIFRAALTA : "Η δωρεά σας είναι πολύ μεγάλη",
    CIFRABASSA : "Η δωρεά σας είναι πολύ μικρή",
    CIFRADELBUDGET : "Μπορείτε ακόμα να κάνετε δωρεά",
    CIFRAMODIFICATA : "Επεξεργαστήκατε τη δωρεά σας",
    CIFRARIMOSSA : "Αφαιρέσατε τη δωρεά σας",
    CIFRASUPERIOREALBUDGET : "Δε μπορείτε πλέον να κάνετε δωρεά επειδή το ποσό που δηλώσατε υπερβαίνει τις οικονομικές σας δυνατότητες.",
    CLICCAILPULSANTE : "Κάντε κλικ στο κουμπί αν θέλετε να ψηφίσετε αυτή την πρόταση.",
    COMMENTAIDEA : "Σχόλιο",
    COMMENTI : "Σχόλια",
    COMMENTIALERTEMAILCONFIRM : "<h6 class=\"text-primary\">Δεν έχετε επιβεβαιώσει ακόμα τη διεύθυνση του ηλεκτρονικού ταχυδρομείου σας</h6><div>Επιβεβαιώστε την για να γίνετε μέλος της οργάνωσης και να εγγραφείτε στις συμμετοχικές διαδικασίες</div><div>Δε λάβατε το μήνυμα;<br><strong>Κάντε κλικ στο κουμπί στην κόκκινη ζώνη για να σταλεί ξανά</strong></div>",
    COMMENTIALERTEMAILMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the organization and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    COMMENTIALERTMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the organization and sign up for participatory processes</div><div>You didn't receive the message?<br><strong>Click on the button in the red band to resend it</strong></div>",
    COMMENTICONTRIBUISCI : "Αφήστε ένα σχόλιο",
    COMMENTIDISATTIVATI : "Τα σχόλια είναι απενεργοποιημένα",
    COMMENTIENTRA : "Συνδεθείτε",
    COMMENTIREGISTRATI : "Συνδεθείτε",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "Συνδεθείτε για να αφήσετε ένα σχόλιο",
    COMPLETABOZZA : "Ολοκλήρωση του προσχεδίου",
    CONDIVIDI : "Κοινοποίηση",
    COSTOIDEA : "Κόστος",
    CREATAIL : "Δημιουργήθηκε στις",
    DESCRIZIONE : "Περιγραφή",
    DONA : "ΔΩΡΕΑ",
    DONAREDA : "από τουλάχιστον",
    DONAREFINOA : "έως ένα μέγιστο όριο",
    DONRACCOLTE : "Συγκεντρωμένες δωρεές",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the proposal does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the proposal because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    FOLLOWERS : "Κοινότητα",
    FONDIRACCOLTI : "Συγκεντρωθέντα κεφάλαια",
    GRAZIESCELTAIDEA : "Σας ευχαριστούμε που επιλέξατε αυτή την πρόταση!",
    GRAZIESOSTEGNO : "Σας ευχαριστούμε για την υποστήριξη της πρότασης!",
    GRAZIESOSTEGNOANCORA : "Σας ευχαριστούμε που συνεχίζετε να υποστηρίζετε την πρόταση!",
    HAIANCORA : "Έχετε ακόμα",
    HAIANCORA2 : "Έχετε ακόμα",
    HASCRITTO : "έγραψε",
    IDEAVOTATA : "Ψηφισθείσα πρόταση",
    INBOZZA : "Σχέδιο",
    INDICACIFRA : "Αναφέρετε το ποσό που θέλετε να δωρίσετε",
    INSERISCICIFRA : "Παρακαλούμε εισάγετε το ποσό που θα θέλατε να δωρίσετε για την πρόταση αυτή.",
    INSERISCICOMMENTO : "Εισάγετε ένα σχόλιο",
    INVIA : "αποστολή",
    MACCETTODIESSERERICONTATTATO : "Συμφωνώ να έχω επικοινωνία μέσω των στοιχείων που παραχώρησα",
    MAPPE : "Γενικό πλαίσιο",
    MBCHIUDI : "Κλείσιμο",
    MBINVIA : "Αποστολή",
    MCONTATTAILPROPONENTE : "Επικοινωνήστε με τον/την συγγραφέα",
    MEMAIL : "Το e-mail σας",
    MESSAGEFIELDNAME : "Message",
    METTIPREF : "Ψήφος",
    MINSERISCIUNMESSAGGIO : "Το μήνυμά σας",
    MMESSAGGIOOBBLIGATORIO : "Το μήνυμα απαιτείται",
    MMOBILE : "Ο αριθμός τηλεφώνου σας",
    MNOMINATIVO : "Το όνομά σας",
    MODIFICAIDEA : "Τροποποίηση",
    MTIPOLOGIACOLLABORAZIONELABEL : "Βοηθήστε στην ανάπτυξη της πρότασης",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "Επιλέξτε τύπο μηνύματος",
    NARRAZIONE : "Η πρόταση",
    NOCOMMENTI : "Δεν υπάρχουν σχόλια",
    NOCOMMUNITY : "Δεν υπάρχουν ακόλουθοι",
    NONAPPROVATA : "Δεν έχει εγκριθεί",
    NONSEGUIRE : "Unfollow",
    NONVALUTATA : "Δεν έχει αξιολογηθεί",
    NUOVAVERSIONE : "Δημιουργήστε μια νέα έκδοση",
    PH1 : "Εισάγετε το αποτέλεσμα",
    PH2 : "Εισάγετε την αξιολόγηση",
    PREFERENZEPERPREMIARE : "Προτιμήσεις για την επιβράβευση των καλύτερων προτάσεων!",
    PREFRACCOLTE : "Συγκεντρωμένες προτιμήσεις",
    PUBBLICATAIL : "on",
    PUOIDONARE : "Μπορείτε να δωρίσετε",
    RICHIEDIASSISTENZA : "Ζητήστε βοήθεια!",
    RICORDATI3 : "Θυμηθείτε, μπορείτε να αλλάξετε τη δωρεά σας μέχρι τη λήξη της ψηφοφορίας.",
    RIMUOVIPREF : "Κατάργηση προτίμησης",
    SEGUIIDEA : "Ακολουθήστε",
    SOSTENITORI : "Υποστηρικτές",
    SOSTIENIALTREIDEE : "Μπορείτε να συνεχίσετε να κάνετε δωρεές σε άλλες προτάσεις, επιλέγοντας πώς θα κατανεμηθεί το ποσό",
    SOTTOCATEGORIA : "Tipologia",
    TOGLIEREPREFERENZA : "Εάν έχετε αλλάξει την πρότασή σας, μπορείτε να αφαιρέσετε την προτίμησή σας και να την αναθέσετε σε άλλες προτάσεις.",
    TUOIVOTI : "Οι ψήφοι σας",
    URLSITO : "Sito web",
    VAIALSITO : "Vai al sito",
    VALUTAIDEA : "Αξιολογήστε",
    VALUTARIDUZIONEDONAZIONI : "Εξετάστε το ενδεχόμενο μείωσης των δωρεών που έχουν ήδη γίνει για άλλες προτάσεις",
    VALUTAZIONEINSERITA : "Βαθμολόγηση καταχωρήθηκε",
    VALUTAZIONETITLE1 : "Εισάγετε την τελική αξιολόγηση",
    VALUTAZIONETITLE2 : "Εισάγετε την λεπτομερή αξιολόγηση",
    VALUTAZIONETITLE3 : "Τελική αξιολόγηση",
    VALUTAZIONETITLE4 : "Λεπτομερείς αξιολογήσεις",
    VALUTAZIONETITLE5 : "Η τελική αξιολόγηση έχει ήδη εισαχθεί",
    VALUTAZIONETITLE6 : "Η λεπτομερής αξιολόγηση έχει ήδη εισαχθεί",
    VALUTAZIONI : "Εκτιμήσεις",
    VERSIONIPROPOSTA : "Εκδόσεις της πρότασης:",
    VOTAZIONECHIUSA : "Η ψηφοφορία έχει κλείσει. Τα αποτελέσματα θα δημοσιευθούν σύντομα",
    WALLETSOSTIENIIDEA : "Υποστηρίξτε την πρόταση. Κάντε μια δωρεά!"
  },
  IDEALIST : {
    ALERTBOZZA : "Έχετε ένα προσχέδιο της πρότασής σας. Μπορείτε να το βρείτε στο",
    ALERTVERSIONING1 : "Οι εκδόσεις είναι ενεργές!",
    ALERTVERSIONING2 : "μπορείτε να βελτιώσετε τις προτάσεις παρέχοντας ή συλλέγοντας ιδέες και δημοσιεύοντας νέες και επικαιροποιημένες εκδόσεις",
    ALERTVERSIONING3 : "Μέγιστος αριθμός εκδόσεων",
    ALERTVERSIONING4 : "Προθεσμία",
    ALERTVERSIONING5 : "Μπορείτε να δημιουργήσετε έως {n} εκδόσεις",
    ASSISTENZA : "Ζητήστε βοήθεια!",
    CLASSIFICA : "Κατάταξη",
    CREAIDEA : "Γράψτε μια πρόταση",
    ELENCOIDEE : "Όλες οι προτάσεις",
    FILTRAPERCHALLENGE : "ΦΙΛΤΡΑΡΙΣΜΑ ΑΝΑ ΘΕΜΑ",
    FILTRAPERMODULO : "ΦΙΛΤΡΑΡΙΣΜΑ ΑΝΑ ΠΕΡΙΟΧΗ",
    FILTRAPERSCENARIO : "ΦΙΛΤΡΑΡΙΣΜΑ ΑΝΑ ΠΕΡΙΕΧΟΜΕΝΟ",
    IDEAVOTATA : "Ψηφίσατε υπέρ αυτής της πρότασης!",
    IDEE : "προτάσεις",
    IDEECOSTOSE : "Πιο ακριβές",
    IDEEMENOCOSTOSE : "Λιγότερο ακριβές",
    IDEEMENORECENTI : "Λιγότερο πρόσφατες",
    IDEEMENOSEGUITE : "Λιγότερο δημοφιλείς",
    IDEEPIUSEGUITE : "Πιο δημοφιλείς",
    INFORMAZIONI : "Περισσότερες πληροφορίες",
    INFORMAZIONISULLAFASE : "Πώς να συμμετέχετε",
    JAMBOARD : "Εγγραφείτε στο Jamboard",
    NESSUNO : "Κανένα",
    NONCISONOIDEE : "Δεν υπάρχουν ακόμη προτάσεις",
    ORDINA : "ΤΑΞΙΝΟΜΗΣΗ ΚΑΤΑ",
    PREFERENZE : "προτιμήσεις",
    PS : "Προσωπικός χώρος",
    TUOIVOTI : "Οι ψήφοι σας",
    ULTIMEIDEE : "Νεότερες",
    VOTANTI : "ψηφοφόροι",
    VOTAZIONEATTIVA : "Η ψηφοφορία είναι ενεργή",
    VOTAZIONEATTIVA2 : "Περιηγηθείτε στις προτάσεις και ψηφίστε τις αγαπημένες σας",
    VOTAZIONEATTIVA3 : "Μπορείτε να δωρίσετε",
    VOTAZIONEATTIVABUDG : "με τη δωρεά ενός προϋπολογισμού",
    VOTAZIONEATTIVAMAX : "έως",
    VOTAZIONEATTIVAMIN : "από το",
    VOTAZIONEATTIVAPREF : "Μπορείτε ακόμα να ψηφίσετε",
    VOTAZIONEATTIVAPREF2 : "και έχετε ακόμα διαθέσιμα",
    VOTAZIONEATTIVAPREF3 : "προτιμήσεις",
    VOTAZIONEATTIVAPREF4 : "προτίμηση",
    VOTAZIONEATTIVAUTENTEANONIMO : "Συνδεθείτε. Αν έχετε διακίωμα ψήφου, θα μπορείτε να ψηφίσετε τις αγαπημένες σας προτάσεις",
    VOTAZIONECHIUSA : "Η ψηφοφορία έχει κλείσει. Τα αποτελέσματα θα δημοσιευθούν σύντομα",
    WEBINAR : "Εγγραφείτε στο Webinar"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "Τελική κατάταξη",
    COMEFUNZIONA : "Πώς λειτουργεί",
    COSTOIDEA : "Εκτιμώμενο κόστος",
    DONATORI : "Δωρητές",
    ELENCOPROCESSI : "Λίστα διεργασιών",
    FASE : "Φάση:",
    FILTRAPERBUDGET : "Φίλτρο ανά προϋπολογισμό καλλιέργειας",
    FILTRAPERPREFERENZE : "Φίλτρο με βάση τις προτιμήσεις",
    FINANZIATO : "Δωρεές",
    PREFERENZE : "Προτιμήσεις",
    PROCESSO : "Διαδικασία",
    RISULTATI : "Αποτελέσματα",
    RISULTATIVOTAZIONE : "Αποτελέσματα ψηφοφορίας",
    TUTTOSUPROCESSO : "Όλα όσα πρέπει να γνωρίζετε για τη συμμετοχική διαδικασία"
  },
  INTRO : {
    ASSISTENZA : "Υποστήριξη"
  },
  INTROBASE : {
    ASSISTENZA : "Βοήθεια",
    COSA : "Τι μπορείς να κάνεις",
    PARTECIPA : "Εγγραφείτε στο",
    PARTECIPADESC : "Συμμετέχετε στη διαδικασία του σχολείου σας",
    SUBIPART : "για την Bipart"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "Θυμηθείτε να επιβεβαιώσετε τη διεύθυνση του email σας. Αν δεν έχετε λάβει κανένα email από το BiPArt",
    CONFIRMEMAIL2 : "κάντε κλικ εδώ",
    CONFIRMEMAIL3 : "για να σταλεί ξανά.",
    CONFIRMMOBILE1 : "Remember to confirm your mobile. If you have not received any SMS from BiPArt",
    CONFIRMMOBILE2 : "click here",
    CONFIRMMOBILE3 : "to resend it."
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Κλείστε το",
    CONFERMA : "Επιβεβαίωση εγγραφής στη δοκιμή",
    ERR1 : "Ουπς... Εμφανίστηκε ένα απροσδόκητο σφάλμα.",
    ISCRIVITIPROCESSO : "Εγγραφείτε για τη δοκιμή",
    PARTECIPAPROCESSO : "Θέλετε να συμμετάσχετε στη διαδικασία;",
    PROPOSTE : "Για να υποβάλλετε προτάσεις, να σχολιάσετε, να ζητήσετε αξιολογήσεις, να ψηφίσετε, πρέπει να εγγραφείτε στη διαδικασία.",
    TITLE1 : "Σφάλμα"
  },
  LOGIN : {
    ACCEDI : "Συνδεθείτε",
    ACCETTO1 : "Δέχομαι",
    ACCETTO2 : "Αποδέχομαι την",
    BACKPORTAL : "Back to portal",
    CAMBIAPASS : "Αλλαγή κωδικού πρόσβασης",
    INSERISCIMAIL : "Γράψε το email σου",
    MEX1 : "Εγγραφή και αποδοχή του «Απορρήτου» και των «Όρων και Προϋποθέσεων»",
    MEX2 : "Τα διαπιστευτήρια που καταχώρησες είναι λάθος. Έλεγξέ τα ή γράψε μας στο <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Επιτυχής έλεγχος ταυτότητας",
    MEX4 : "Εμφανίστηκε σφάλμα",
    MEX5 : "Μη έγκυρο email",
    MEXLOGIN : "<b>Καλώς ήρθατε στο BiPart,</b><br>το κοινωνικό δίκτυο της συμμετοχής των πολιτών.<br>Τα δεδομένα σας θα αντιμετωπίζονται σε πλήρη συμμόρφωση με τους ιταλικούς και ευρωπαϊκούς κανονισμούς και με ακόμη μεγαλύτερη προσοχή για την προστασία των απόψεων και των αποφάσεών σας.<br><b>Αν θέλετε να συνεχίσετε, διαβάστε και αποδεχτείτε τα δύο παρακάτω έγγραφα.</b>",
    NONRICORDIPASS : "Δεν μπορείς να θυμηθείς τον κωδικό πρόσβασής σου;",
    NOTIFICHE : "*Εάν θέλεις να λαμβάνεις ειδοποιήσεις σχετικά με την πρόοδο των υποβληθεισών προτάσεων",
    PASSWORDERRATA : "Λανθασμένος κωδικός πρόσβασης",
    PRIVACY : "Πολιτική απορρήτου",
    PROSEGUI : "Συνέχισε",
    TERMINI : "Όροι και προϋποθέσεις παροχής υπηρεσιών",
    TERMINICONDIZIONI : "Για να συνεχίσεις, παρακαλούμε να διαβάσεις τους <a href=\"https://bipart.it/terms\" target=\"blank\">Όρους και προϋποθέσεις παροχής υπηρεσιών</a><br> και την <a href=\"https://bipart.it/privacy\" target=\"blank\">Πολιτική προστασίας προσωπικών δεδομένων</a>.",
    TITLE1 : "Συγχαρητήρια",
    TITLE2 : "Προσοχή",
    TITLE3 : "Συγχαρητήρια",
    TITLE4 : "Σφάλμα",
    TITLE5 : "Σφάλμα",
    USERNAMENONESISTE : "Το όνομα χρήστη δεν υπάρχει",
    UTENTEAUTENTICATO : "-- Πιστοποιημένος χρήστης --"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "Συνδέσου",
    ACCETTO : "Δέχομαι",
    ACCETTOLA : "Αποδέχομαι την",
    BPROSEGUI : "Συνέχισε",
    CAMBIAPASS : "Αλλαγή κωδικού πρόσβασης",
    INSERISCIMAIL : "Γράψε το email σου",
    MEX1 : "Εγγραφή αποδοχή του «Απορρήτου» και των «Όρων και προϋποθέσεων»",
    MEX2 : "Τα διαπιστευτήρια που καταχώρησες είναι λάθος. Έλεγξέ τα ή γράψε μας στο <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Επιτυχής έλεγχος ταυτότητας",
    MEX4 : "Εμφανίστηκε σφάλμα",
    MEX5 : "Μη έγκυρο μήνυμα",
    MEXLOGIN : "<b>Καλώς ήρθατε στο BiPart,</b><br>Το κοινωνικό δίκτυο της συμμετοχής των πολιτών.<br>Τα δεδομένα σας θα αντιμετωπίζονται με πλήρη σεβασμό στους ιταλικούς και ευρωπαϊκούς κανονισμούς και με ακόμη μεγαλύτερη προσοχή για την προστασία των απόψεων και των αποφάσεών σας.<br><b>Αν θέλετε να συνεχίσετε, διαβάστε και αποδεχτείτε τα δύο παρακάτω έγγραφα.</b>",
    NIENTEACCOUNT : "Δεν έχεις ακόμη λογαριασμό;",
    NONRICORDIPASS : "Δεν μπορείς να θυμηθείς τον κωδικό πρόσβασής σου;",
    NOTIFICHE : "*Εάν θέλεις να λαμβάνεις ειδοποιήσεις σχετικά με την πρόοδο των υποβληθεισών προτάσεων",
    PRIVACY : "Πολιτική απορρήτου",
    REGISTRATI : "Εγγραφή",
    TERMINI : "Όροι και προϋποθέσεις παροχής υπηρεσιών",
    TERMINIECONDIZIONI : "Για να συνεχίσεις, παρακαλούμε διάβασε τους <a href=\"https://bipart.it/terms\" target=\"blank\">Όρους και προϋποθέσεις παροχής υπηρεσιών </a><br> και την <a href=\"https://bipart.it/privacy\" target=\"blank\">Πολιτική απορρήτου.</a>.",
    TITLE1 : "Συγχαρητήρια",
    TITLE2 : "Προσοχή",
    TITLE3 : "Συγχαρητήρια",
    TITLE4 : "Σφάλμα",
    TITLE5 : "Σφάλμα",
    TITOLO : "BiPart",
    UTENTEAUTENTICATO : "-- Πιστοποιημένο μέλος --"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Κλείστε το",
    CONFERMA : "Επιβεβαίωση",
    ISCRIVITIPROCESSO : "Συνδεθείτε με τα στοιχεία σας και λάβετε μέρος στις διεργασίες",
    MEX1 : "Δεν έχετε εισάγει τα απαιτούμενα δεδομένα. Προσπαθήστε ξανά ή γράψτε στη διεύθυνση <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX2 : "Τα διαπιστευτήρια που καταχωρήσατε είναι λάθος. Ελέγξτε ή γράψτε μας στο <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Ουπς... Εμφανίστηκε σφάλμα.",
    NONFAIPARTE : "Δεν είστε ήδη εγγεγραμμένοι;",
    NUOVOACCOUNT : "Δημιουργήστε έναν λογαριασμό για να συμμετέχετε",
    ORGANIZATION : "Καλώς ήρθατε στην Bipart",
    PASSWORDERRATA : "Λανθασμένος κωδικός πρόσβασης",
    REGISTRATI : "ΕΓΓΡΑΦΕΙΤΕ",
    TITLE2 : "Προσοχή",
    TITLE3 : "Σφάλμα",
    USERNAMENONESISTE : "Το όνομα χρήστη δεν υπάρχει"
  },
  MAINMENU : {
    ABOUT : "Σχετικά με το",
    ACCESSIBILITY : "Προσβασιμότητα",
    COMMUNITY : "Κοινότητα",
    COOKIE : "Cookie",
    DEMO : "Δημιουργήστε τον χώρο της κοινότητας",
    DS : "Διαβουλετικός χώρος",
    EXPLORE : "Εξερευνήστε",
    FAQ : "FAQ / Συχνές Ερωτήσεις",
    FEATURE : "Tutorial & Guidelines",
    GIOCHI : "Εκπαιδευτικά παιχνίδια",
    HOME : "Αρχική σελίδα",
    HOWITWORKS : "Πώς λειτουργεί",
    HOWTOJOIN : "Πώς να συμμετάσχετε",
    JOINCOMMUNITY : "Χώρος Κοινότητας",
    LISTCHALLENGES : "Προκλήσεις",
    LISTGROUPS : "Λίστα ομάδων",
    LISTPROCESSES : "Κατάλογος διεργασιών",
    LISTSCENARIOS : "Σενάρια",
    LOGIN : "Συνδεθείτε",
    LOGOUT : "Έξοδος",
    PORTAL : "Portal",
    PRIVACY : "Απόρρητο",
    PROJECT : "Το έργο",
    PROJECTS : "Case Studies",
    PS : "Προσωπικός χώρος",
    PSACTIVITIES : "Δραστηριότητες",
    PSPROFILE : "Προσωπικό προφίλ",
    PSSETTINGS : "Ρύθμιση",
    REGISTER : "Κάνε Εγγραφή",
    SERVICES : "Μελέτη περιπτώσεων",
    TERMS : "Όροι και προϋποθέσεις",
    WORKSPACE : "Χώρος εργασίας"
  },
  MANDATORYFLAGS : {
    ACCETTO : "Δέχομαι",
    ACCETTOLA : "Αποδέχομαι την",
    BPROSEGUI : "Συνέχισε",
    DETTWELCOME : "Η επεξεργασία των δεδομένων σου θα γίνεται σε πλήρη συμμόρφωση με τους ιταλικούς και ευρωπαϊκούς κανονισμούς και με ακόμη μεγαλύτερη προσοχή για την προστασία των απόψεων και των αποφάσεών σου. Αν θέλεις να συνεχίσεις, διάβασε και αποδέξου τα δύο παρακάτω έγγραφα.",
    INSERISCIMAIL : "Γράψε το email σου",
    MEX2 : "Τα διαπιστευτήρια που καταχώρησες είναι λάθος. Έλεγξέ τα ή γράψε μας στο <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    MEX3 : "Επιτυχής έλεγχος ταυτότητας",
    MEX5 : "Το email που καταχώρησες δεν είναι έγκυρο",
    NOTIFICHE : "*Εάν θέλεις να λαμβάνεις ειδοποιήσεις σχετικά με την πρόοδο των υποβληθεισών προτάσεων.",
    PRIVACY : "Πολιτική απορρήτου",
    TERMINIECONDIZIONI : "Για να συνεχίσεις, παρακαλούμε διάβασε τους <a href=\"https://bipart.it/terms\" target=\"blank\">Όρους και προϋποθέσεις παροχής υπηρεσιών </a><br> και την <a href=\"https://bipart.it/privacy\" target=\"blank\">Πολιτική απορρήτου.</a>.",
    TITLE1 : "Συγχαρητήρια",
    TITLE2 : "Προσοχή",
    TITLE3 : "Συγχαρητήρια",
    TITOLO : "BiPart",
    TITOLOWELCOME : "Καλώς ήρθες στο BiPart, το κοινωνικό δίκτυο για τη συμμετοχή των πολιτών.",
    UTENTEAUTENTICATO : "-- Πιστοποιημένος χρήστης/μέλος --"
  },
  MESSAGEAUTH : {
    0 : "Έχει προκύψει κάποιο γενικό σφάλμα",
    1 : "Τα διαπιστευτήρια που εισάγατε είναι λανθασμένα. Ελέγξτε τα ή γράψτε μας στο <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    100 : "Το καθορισμένο \"organizationDomain\" δεν ταιριάζει σε κανέναν Οργανισμό.",
    105 : "Το χαρακτηριστικό \"processId\" λείπει.",
    110 : "Το καθορισμένο χαρακτηριστικό \"processId\" δεν ταιριάζει σε καμία διαδικασία",
    115 : "Η Διεργασία δεν ανήκει στην καθορισμένη Οργάνωση.",
    120 : "Γεια σας!\nΗ διεργασία είναι ιδιωτική: για να λάβετε μέρος πρέπει να ζητήσετε έναν κωδικό πρόσβασης.",
    125 : "Το καθορισμένο χαρακτηριστικό \"groupId\" δεν αντιστοιχεί σε καμία Ομάδα.",
    130 : "Ουπς!\nΑυτή η Ομάδα είναι ιδιωτική και δεν είναι δυνατό να εγγραφείτε.",
    135 : "Η Ομάδα που ζητήθηκε δεν ανήκει στην επιλεγμένη Διεργασία.",
    140 : "Γεια σας!\nΓια να λάβετε μέρος πρέπει να δηλώσετε σε ποια Ομάδα ανήκετε ή θέλετε να συμμετέχετε.\nΕπιλέξτε την από τον παρακάτω κατάλογο. Ευχαριστούμε!",
    145 : "Ουπς!\nΗ επιλεγμένη Διεργασία περιλαμβάνει μόνο Ιδιωτικές Ομάδες, στις οποίες δεν είναι δυαντό να εγγραφείτε.",
    150 : "Ουπς!\nΈχετε ξεχάσει κάποια σημαντικά στοιχεία για την εγγραφή. Συμπληρώστε τα πεδία που έχουν επισημανθεί με κόκκινο και είστε έτοιμοι.",
    155 : "Ουπς!\nΤο όνομα χρήστη που έχετε εισάγει χρησιμοποιείται ήδη: ίσως έχετε ήδη εγγραφεί και δεν το θυμάστε.\nΔοκιμάστε να συνδεθείτε ή εισάγετε ένα διαφορετικό.\nΕυχαριστούμε!",
    160 : "Ουπς!\nΟ Χρήστης σας είναι ήδη εγγεγραμμένος στην πλατφόρμα αλλά δεν έχει συνδεθεί ακόμα με αυτήν την Διεργασία.\nΠατήστε το \"Login\" και εισάγετε τα στοιχεία σας για να ολοκληρώσετε τη σύνδεση!",
    165 : "Ουπς!\nΤο όνομα χρήστη που έχετε εισάγει χρησιμοποιείται ήδη: ίσως έχετε ήδη εγγραφεί και δεν το θυμάστε.\nΔοκιμάστε να συνδεθείτε ή εισάγετε ένα διαφορετικό. Ευχαριστούμε!",
    170 : "Κρίμα! Κάποιος ήταν ταχύτερος από εσάς και διάλεξε ήδη αυτό το όνομα χρήστη. :( Δοκιμάστε κάποιο ακόμα καλύτερο!",
    172 : "Το email που εισάγατε χρησιμοποιείται ήδη.\nΠαρακαλώ ελέγξτε εάν έχετε ήδη λογαριασμό στο BiPart, διαφορετικά αναφέρατε το πρόβλημα στο support@bipart.it",
    175 : "Ουπς!\nΓια να προχωρήσετε με την εγγραφή πρέπει να διαβάσετε και να μας ενημερώσετε ότι συμφωνείτε με την πολιτική απορρήτου και τους όρους και τις προϋποθέσεις της υπηρεσίας μας!",
    180 : "Γεια σας!\nΗ Οργάνωση είναι ιδιωτική: για να λάβετε μέρος πρέπει να ζητήσετε έναν κωδικό πρόσβασης.",
    182 : "Η Ομάδα που ζητήθηκε δεν ανήκει στην επιλεγμένη Οργάνωση.",
    185 : "Γεια σας!\nΓια να λάβετε μέρος πρέπει να δηλώσετε σε ποια Ομάδα ανήκετε ή θέλετε να συμμετέχετε.\nΕπιλέξτε την από τον παρακάτω κατάλογο. Ευχαριστούμε!",
    187 : "Ουπς!\nΗ επιλεγμένη οργάνωση περιλαμβάνει μόνο ιδιωτικές ομάδες, στις οποίες δεν είναι δυνατό να εγγραφείτε.",
    190 : "Το όνομα χρήστη που εισάγατε χρησιμοποιείται ήδη: ίσως έχετε συνδεθεί και δεν το θυμάστε.\nΔοκιμάστε να συνδεθείτε ή εισάγετε ένα διαφορετικό.\nΕυχαριστούμε!",
    192 : "Ουπς!\nΟ Χρήστης σας είναι ήδη εγγεγραμμένος στην πλατφόρμα αλλά δεν έχει συνδεθεί ακόμα με αυτή την Οργάνωση.\nΠατήστε το \"Login\" και εισάγετε τα στοιχεία σας για να ολοκληρώσετε τη σύνδεση!",
    2 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Σας ευχαριστούμε για την εγγραφή σας στο<br>{value}</h5>",
    200 : "Ουπς!\nΑφήσατε αρκετό χρόνο να περάσει!\nΓια να επαληθεύσουμε τη διεύθυνση του ηλεκτρονικού ταχυδρομείου σας, πρέπει να ζητήσετε να σταλεί ένα νέο μήνυμα και να κάνετε κλικ όσο το δυνατόν συντομότερα στο κουμπί επιβεβαίωσης που περιλαμβάνεται στο μήνυμα.",
    205 : "Ουπς!\nΗ ενέργεια δεν ήταν επιτυχημένη: προσπαθήστε ξανά!",
    210 : "Ουπς!\nΟ λογαριασμός σας δεν αντιστοιχεί σε κανέναν ενεργό Χρήστη στα αρχεία μας!",
    215 : "Ουπς!\nΔεν έχουμε λάβει κάποια αίτηση εγγραφής που να αντιστοιχεί στη διεύθυνση του ηλεκτρονικού ταχυδρομείου σας.\nΈχετε σίγουρα ολοκληρώσει τη σχετική διαδικασία;\nΠαρακαλούμε προσπαθήστε να εγγραφείτε ξανά.\nΕυχαριστούμε!",
    220 : "Ουπς!\nΗ διεύθυνση ηλεκτρονικού ταχυδρομείου που πρσπαθείτε να επιβεβαιώσετε έχει ήδη επαληθευτεί.\nΔοκιμάστε να συνδεθείτε.\nΕυχαριστούμε!",
    225 : "Ουπς!\nΗ ενέργεια δεν ήταν επιτυχημένη: προσπαθήστε ξανά!",
    3 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Συμμετέχετε στη διαδικασία<br>{value}</h5>",
    300 : "Ουπς!\nΟ Χρήστης σας δεν είναι ακόμα εγγεγραμμένος στην πλατφόρμα.\nΈχετε σίγουρα ολοκληρώσει τη σχετική διαδικασία;\nΠαρακαλούμε δοκιμάστε να συνδεθείτε ξανά.\nΕυχαριστούμε!",
    305 : "Ουπς!\nΟ Χρήστης σας είναι ήδη εγγεγραμμένος στην πλατφόρμα αλλά δεν έχει συνδεθεί ακόμα με αυτή την Οργάνωση.\nΕπικοινωνήστε μαζί μας για να ζητήσετε πρόσβαση.",
    310 : "Ουπς!\nΤο όνομα χρήστη που έχετε εισάγει χρησιμοποιείται ήδη: ίσως έχετε ήδη εγγραφεί και δεν το θυμάστε.\nΔοκιμάστε να συνδεθείτε ή εισάγετε ένα διαφορετικό.\nΕυχαριστούμε!",
    4 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><div>Welcome to {organization_name} </div><h5 class=\"text-primary\">Επιβεβαιώνουμε την εγγραφή σας στο {process_name} </h5><div>Από τώρα και στο εξής είστε μέλος της κοινότητας και μπορείτε να λάβετε μέρος στις διαδικασίες που έχετε εγγραφεί.</div>",
    400 : "Το πεδίο \"κινητό τηλέφωνο\" λείπει.",
    405 : "Ουπς!\nΟ αριθμός κινητού τηλεφώνου που εισάγατε χρησιμοποιείται ήδη: ίσως έχετε ήδη εγγραφεί και δεν το θυμάστε.\nΔοκιμάστε να συνδεθείτε ή εισάγετε έναν διαφορετικό.\nΕυχαριστούμε!",
    410 : "Ουπς!\nΟ αριθμός κινητού τηλεφώνου που εισάγατε χρησιμοποιείται ήδη: ίσως έχετε ήδη εγγραφεί και δεν το θυμάστε.\nΔοκιμάστε να συνδεθείτε ή εισάγετε έναν διαφορετικό.\nΕυχαριστούμε!",
    420 : "Ουπς!\nΟ αριθμός κινητού τηλεφώνου που εισάγατε χρησιμοποιείται ήδη.\nΕίναι σίγουρα δικός σας; Δοκιμάστε να εισάγετε έναν διαφορετικό.\nΕυχαριστούμε!",
    425 : "Ops!\nL’attributo “mobile” non compare nel database Utenti\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    500 : "Ουπς!\nΔεν υπάρχει αριθμός κινητού τηλεφώνου συνδεδεμένος με το προφίλ σας.\nΕπιστρέψτε στον προηγούμενο πίνακα για να τον εισάγετε.\nΕυχαριστούμε!",
    502 : "Λόγω ενός τεχνικού προβλήματος σάς στείλαμε έναν νέο κωδικό επαλήθευσης μέσω SMS.\nΠαρακαλούμε επαναλάβετε την ενέργεια αφού τον πληκτρολογήσετε στον ίδιο πίνακα.\nΕυχαριστούμε!",
    510 : "Ουπς!\nΟ κωδικός επαλήθευσης που εισάγατε δεν είναι έγκυρος.\nΕλέγξτε τον ή ζητήστε να σας σταλεί νέος κωδικός.\nΕυχαριστούμε!",
    515 : "Ουπς!\nΟ κωδικός επαλήθευσης που εισάγατε έχει λήξει.\nΠαρακαλούμε ζητήστε να σάς σταλεί νέος κωδικός.\nΕυχαριστούμε!",
    605 : "Ουπς!\nΈνας αριθμός κινητού τηλεφώνου έχει ήδη συνδεθεί με το προφίλ σας.",
    700 : "Το συγκεκριμένο \"process2ModuleId\" χαρακτηριστικό δε συνάδει με καμία Ενότητα (Module).",
    705 : "Η Ενότητα προορισμού δε σχετίζεται με τη προσδοκώμενη διαδικασία",
    710 : "Το ΑΦΜ δεν έχει δηλωθεί ούτε αποδίδεται στον AppUser που ζητήθηκε.",
    715 : "Ουπς!\nΤο ΑΦΜ που εισάγατε δεν είναι έγκυρος: παρακαλούμε, ελέγξτε τον.\nΕυχαριστούμε!",
    720 : "Ουπς!\nΤο ΑΦΜ  που εισάγατε χρησιμοποιείται ήδη.\nΕίναι σίγουρα δικός σας; Δοκιμάστε να εισάγετε έναν διαφορετικό.\nΕυχαριστούμε!",
    725 : "Ουπς!\nΤο ΑΦΜ  είναι ήδη συνδεδεμένος με το προφίλ σας.",
    ATTENZIONE : "Προσοχή"
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "Inserisci oppure copia e incolla l'URL del sito ufficiale dell'organizzazione che fornisce il servizio",
    MAXLENGTHPART1 : "Προσοχή! Έχετε ακόμα",
    MAXLENGTHPART2 : "χαρακτήρες διαθέσιμοι",
    PH0 : "Έως 60 χαρακτήρες",
    PH5 : "----",
    SELEZIONASOTTOCATEGORIA : "Indica che tipo di servizio stai pubblicando, selezionando una delle voci che ti compaiono cliccando il campo di inserimento",
    SOTTOCATEGORIA : "Tipologia"
  },
  MODULEIDEACREATE : {
    ALERT1 : "Σιγουρευτείτε ότι έχετε συμπληρώσει τα απαιτούμενα πεδία: Τίτλο και Περιγραφή",
    ALERT1TITLE : "Προσοχή",
    ALERT2 : "Η πρόταση καταχωρήθηκε επιτυχώς!",
    ALERT3 : "Εισάγετε την πιθανή αξία για να δηλώσετε το Εκτιμώμενο Κόστος της πρότασης",
    ALTROMATERIALE : "Εάν έχετε επιπλέον χρήσιμο υλικό για να παρουσιάσατε καλύτερα τη πρότασή σας (σχέδια, τεχνικές προδιαγραφές, πληροφορίες...), ανεβάστε το εδώ.",
    ANNULLA : "Ακύρωση",
    AVANTI : "Προχωρήστε",
    BOZZA : "Αποθήκευση ως προσχέδιο",
    CHALLENGE : "Θεματικές",
    COSTOIPOTIZZATO : "Εκτιμώμενο κόστος",
    CREAIDEA : "Γράψτε μια πρόταση",
    DAIBREVETITOLO : "Δώστε στην πρότασή σας έναν σύντομο αλλά αξιοπρόσεκτο τίτλο",
    DESCRIVI : "Συνοψίστε την πρότασή σας σε λίγες λέξεις. Μπορείτε στη συνέχεια να την περιγράψετε αναλυτικά στην επόμενη ενότητα.",
    DESCRIZIONE : "Σύνοψη",
    DOCALLEGATI : "Έγγραφα και συνημμένα.",
    FILEACCETTATI : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: JPG, JPEG, PNG",
    IMMAGINERAPPRESENTATIVA : "Εισάγετε μια εικόνα που αντιπροσωπεύει την πρότασή σας",
    IMMAGINI : "Δημοσιεύστε περισσότερες φωτογραφίες για να βοηθήσετε τους άλλους να κατανοήσουν καλύτερα την πρότασή σας.",
    INDICACOSTO : "Αναφέρετε πόσο πιστεύετε ότι θα κοστίσει η πρότασή σας",
    ISTRUZIONI : "Οδηγίες",
    LOGO : "Εικόνα εξωφύλλου",
    MAPPEEINFO : "Χάρτες και infographics",
    NARRA : "Περιγράψτε λεπτομερώς την πρότασή σας",
    NARRAZIONE : "Η πρόταση",
    OVERVIEW : "Γενικές πληροφορίες",
    PH1 : "Μέγιστο 600 χαρακτήρες",
    PH2 : "Κώδικας ενσωμάτωσης (μόνο για το youtube)",
    PH3 : "Ξεκινήστε να πληκτρολογείτε τη διεύθυνση και κάντε κλικ στο καλύτερο αποτέλεσμα για να τοποθετήσετε το σημείο εντοπισμού.",
    PH4 : "URL",
    PUBBLICA : "Δημοσίευση",
    SCEGLISFIDE : "Επιλέξτε ποια(-ες) πρόκληση(-ες) θα θέλατε να αντιμετωπίσει η πρότασή σας.",
    SELEZIONAMAPPA : "Σημειώστε πού βρίσκεται η πρότασή σας",
    SELEZIONATRA : "Επιλέξτε από",
    TITOLO : "Τίτλος",
    TRASCINA : "Σύρετε την εικόνα εδώ ή κάντε κλικ εδώ για να την ανεβάσετε",
    TRASCINADOC : "Σύρετε τα αρχεία εδώ ή κάντε κλικ εδώ για να τα ανεβάσετε",
    TRASCINAIMMAGINI : "Σύρετε τις εικόνες εδώ ή κάντε κλικ εδώ για να τις ανεβάσετε",
    URLSITO : "Sito web",
    VIDEO : "Αν υπάρχει ή αν δημιουργήσατε κάποιο βίντεο που να εξηγεί την πρότασή σας, εισάγετε το αντιγράφοντας τον κώδικα εδώ (όχι το URL) για να το κάνετε ορατό σε όλους.",
    VIDEOHINT : "Το βίντεο ενισχύει την καλύτερη οπτικοποίηση της πρότασής σας και τη συναναστροφή σας με περισσότερους ανθρώπους."
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "Ενημερώστε τον τίτλο",
    AGGIUNGIALTREIMMAGINI : "Προσθέστε περισσότερες φωτογραφίες",
    ALERT1 : "Σιγουρευτείτε ότι έχετε συμπληρώσει τα απαιτούμενα πεδία: Τίτλο και Περιγραφή",
    ALERT1TITLE : "Προσοχή",
    ALERT2 : "Η πρόταση έχει εισαχθεί σωστά!",
    ALERT2TITLE : "Πρόκειται να δημοσιεύσετε μια νέα έκδοση της πρότασης",
    ALERT3 : "Διαλέξτε τη γλώσσα στην οποία θέλετε να δημοσιευτεί η πρότασή σας. Πρέπει να επιλέξετε τουλάχιστον μία γλώσσα",
    ALERT4 : "Να θυμάστε ότι από τη στιγμή που θα δημοσιευτεί, δεν θα μπορείτε να το επεξεργαστείτε.",
    ALERT5 : "Εισάγετε τη σωστή αξία για να δηλώσετε το Εκτιμώμενο Κόστος της πρότασης",
    ALTROMATERIALE : "Αν έχετε άλλο χρήσιμο υλικό για την καλύτερη απεικόνιση της πρότασής σας (σχέδια, πληροφορίες...), ανεβάστε το εδώ.",
    ANNULLA : "Ακύρωση",
    AVANTI : "Προχωρήστε",
    BOZZA : "Αποθήκευση ως προσχέδιο",
    CANCELLA : "Διαγραφή",
    CHALLENGE : "Πρόκληση",
    COSTO : "Κόστος",
    COSTOIPOTIZZATO : "Ενημέρωση υποτιθέμενου κόστους",
    DESCRIVI : "Ενημερώστε την περιγραφή",
    DESCRIZIONE : "Περιγραφή",
    DOCALLEGATI : "Έγγραφα και συνημμένα.",
    DOCTECNICA : "Τεχνική τεκμηρίωση",
    EDITRESOURCESMODALTITLE : "Επεξεργασία τίτλου",
    ERR1 : "Υποχρεωτικό πεδίο",
    FILEACCETTATI : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: DOC, XLS, PDF, PPT",
    IMMAGINI : "Εικόνες",
    ISTRUZIONI : "Οδηγίες",
    LINKVIDEO : "Σύνδεσμοι βίντεο",
    LOGO : "Λογότυπο",
    MAPPEEINFO : "Χάρτες και infographics",
    MODIFICA : "Επεξεργασία",
    MODIFICAIDEA : "Επεξεργαστείτε την πρόταση",
    MOSTRA : "Εμφάνιση",
    NARRA : "Περιγράψτε λεπτομερώς την τακτική",
    NARRAZIONE : "Η πρόταση",
    OVERVIEW : "Γενικές πληροφορίες",
    PH1 : "Μέγιστο 600 χαρακτήρες",
    PH2 : "κωδικός ενσωμάτωσης (μόνο για το youtube)",
    PH3 : "Ξεκινήστε να πληκτρολογείτε τη διεύθυνση και κάντε κλικ στο καλύτερο αποτέλεσμα για να τοποθετήσετε το σημείο εντοπισμού.",
    PH4 : "URL",
    PUBBLICA : "Δημοσίευση",
    SCEGLISFIDE : "Επιλέξτε ποια(-ες) πρόκληση(-ες) θα θέλατε να αντιμετωπίσει η πρότασή σας.",
    SELEZIONAMAPPA : "Σημειώστε πού βρίσκεται η πρότασή σας",
    SELEZIONATRA : "Επιλέξτε από",
    TITLE : "Τίτλος",
    TITOLO : "Τίτλος",
    TRASCINA : "Σύρετε την εικόνα εδώ ή κάντε κλικ εδώ για να την ανεβάσετε",
    TRASCINADOC : "Σύρετε τα αρχεία εδώ ή κάντε κλικ εδώ για να τα ανεβάσετε",
    TRASCINAIMMAGINI : "Σύρετε τις εικόνες εδώ ή κάντε κλικ εδώ για να τις ανεβάσετε",
    URLSITO : "Sito web",
    VIDEO : "Ενημέρωση βίντεο",
    VIDEOHINT : "Βίντεο (Εισαγωγή κωδικού ενσωμάτωσης)"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "Αντιστοιχίστε ένα πλαίσιο",
    AGGIORNATITOLO : "Τίτλος ενημέρωσης",
    AGGIUNGIALTREIMMAGINI : "Προσθέστε περισσότερες φωτογραφίες",
    ALERT1 : "Σιγουρευτείτε ότι έχετε συμπληρώσει τα απαιτούμενα πεδία: Τίτλο και Περιγραφή",
    ALERT1TITLE : "Προσοχή",
    ALERT2 : "Η πρόταση έχει εισαχθεί σωστά!",
    ALERT2TITLE : "Πρόκειται να δημοσιεύσετε μια νέα έκδοση της πρότασης",
    ALERT3 : "Διαλέξτε τη γλώσσα στην οποία θέλετε να δημοσιευτεί η πρότασή σας. Πρέπει να επιλέξετε τουλάχιστον μία γλώσσα",
    ALERT4 : "Να θυμάστε ότι από τη στιγμή που θα δημοσιευτεί, δεν θα μπορείτε να το επεξεργαστείτε.",
    ALERT5 : "Εισάγετε μια πιθανή αξία για να δηλώσετε το εκτιμώμενο κόστος της πρότασης",
    ALTROMATERIALE : "Αν έχετε άλλο χρήσιμο υλικό για την καλύτερη απεικόνιση της πρότασής σας (σχέδια, πληροφορίες...), ανεβάστε το εδώ.",
    ANNULLA : "Ακύρωση",
    AVANTI : "Επόμενο",
    BOZZA : "Αποθήκευση ως προσχέδιο",
    CANCELLA : "Διαγραφή",
    CHALLENGE : "Προκλήσεις",
    COSTO : "Κόστος",
    COSTOIPOTIZZATO : "Ενημέρωση υποτιθέμενου κόστους",
    DESCRIVI : "Περιγραφή ενημέρωσης",
    DESCRIZIONE : "Σύντομη περιγραφή",
    DOCALLEGATI : "Έγγραφα και παραρτήματα.",
    DOCTECNICA : "Τεχνική τεκμηρίωση",
    EDITRESOURCESMODALTITLE : "Επεξεργασία τίτλου",
    ELENCOIDEE : "Όλες οι προτάσεις",
    ERR1 : "Υποχρεωτικό πεδίο",
    FILEACCETTATI : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Μέγιστο μέγεθος αρχείου: 10 MB. Υποστηριζόμενες μορφές: JPG, JPEG, PNG",
    IMMAGINI : "Εικόνες",
    ISTRUZIONI : "Οδηγίες",
    LINKVIDEO : "Σύνδεσμοι βίντεο",
    LOGO : "Λογότυπο",
    MAPPEEINFO : "Ιστορικό",
    MODIFICA : "Επεξεργασία",
    MODIFICAIDEA : "Επεξεργαστείτε την πρόταση",
    MOSTRA : "Εμφάνιση",
    NARRA : "Περιγράψτε με λεπτομέρειες την πρόταση σας",
    NARRAZIONE : "Η πρόταση",
    NUOVAVERSIONE : "Δημιουργία νέας έκδοσης",
    NUOVAVERSIONEMESSAGGIO1 : "Δημιουργείτε το",
    NUOVAVERSIONEMESSAGGIO2 : "ΕΚΔΟΣΗ",
    NUOVAVERSIONEMESSAGGIO3 : "της πρότασής σας",
    OVERVIEW : "Περιγραφή",
    PH1 : "Μέγιστο 600 χαρακτήρες",
    PH2 : "URL (μόνο στο YouTube)",
    PH3 : "Αρχίστε να πληκτρολογείτε τη διεύθυνση και κάντε κλικ στο καλύτερο αποτέλεσμα για να τοποθετήσετε το σημείο εντοπισμού.",
    PH4 : "URL",
    PROCESSO : "Διαδικασία",
    PUBBLICA : "Δημοσίευση",
    SCEGLISFIDE : "Επιλέξτε τα θέματα που αφορά η πρότασή σας.",
    SELEZIONAMAPPA : "Σημειώστε πού βρίσκεται η πρότασή σας",
    SELEZIONATRA : "Επιλέξτε από",
    TITLE : "Τίτλος",
    TITOLO : "Τίτλος",
    TRASCINA : "Σύρετε την εικόνα εδώ ή κάντε κλικ εδώ για να την ανεβάσετε",
    TRASCINADOC : "Σύρετε τα αρχεία εδώ ή κάντε κλικ εδώ για να τα ανεβάσετε",
    TRASCINAIMMAGINI : "Σύρετε τις εικόνες εδώ ή κάντε κλικ εδώ για να τις ανεβάσετε",
    URLSITO : "Sito web",
    VIDEO : "Ενημέρωση βίντεο",
    VIDEOHINT : "Βίντεο (εισάγετε τη διεύθυνση URL)"
  },
  MODULEINFO : {
    ELENCOPROCESSI : "Λίστα διεργασιών",
    INFO : "Φόρμα πληροφοριών διαδικασίας",
    INPROGRESS : "Σε εξέλιξη ...",
    PROCESSO : "Διαδικασία"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "Λίστα διεργασιών",
    INFO : "Ενότητα υπό κατασκευή",
    INPROGRESS : "Σε εξέλιξη ...",
    PROCESSO : "Διαδικασία"
  },
  MODULESERVICECREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il servizio è stato inserito correttamente!",
    ALERT3 : "Inserisci il costo del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    CREAIDEA : "Descrivi il tuo servizio",
    DAIBREVETITOLO : "Dai un titolo breve ma accattivante al tuo servizio",
    DESCRIVI : "Descrivi in poche righe il tuo servizio. Puoi dettagliarlo al prossimo step",
    IMMAGINERAPPRESENTATIVA : "Inserisci un'immagine che rappresenti il tuo servizio",
    IMMAGINI : "Pubblica altre immagini utili a far comprendere meglio il tuo servizio",
    INDICACOSTO : "Indica quanto potrebbe costare il tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli a quale o a quali si riferisce il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web",
    VIDEO : "Se esiste o hai realizzato un video su Youtube che spiega il tuo servizio, rendilo visibile a tutti copiando qui l’URL",
    VIDEOHINT : "Un video aiuta a descrivere meglio un servizio e a raggiungere più persone"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "Il servizio è stato caricato correttamente!",
    ALERT2TITLE : "Stai per pubblicare una nuova versione del servizio",
    ALERT3 : "Seleziona la lingua in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    MODIFICAIDEA : "Modifica i contenuti",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    SCEGLISFIDE : "Scegli quale o quali temi riguarda il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il servizio",
    URLSITO : "Sito web"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo, Sintesi e Tipologia",
    ALERT2 : "Il tuo servizio è stato inserito correttamente!",
    ALERT2TITLE : "Stai per pubblicare una versione aggiornata del tuo servizio",
    ALERT3 : "Seleziona la lingua o le lingue in cui vuoi pubblicare il tuo servizio. Devi selezionarne almeno una",
    ALERT5 : "Inserisci un valore corretto per indicare il costo ipotizzato del servizio",
    ALTROMATERIALE : "Se hai altro materiale utile a dettagliare il tuo servizio (disegni, approfondimenti, specifiche tecniche, ...), caricalo qui",
    ELENCOIDEE : "Tutte i servizi",
    MODIFICAIDEA : "Modifica i contenuti del tuo servizio",
    NARRA : "Descrivi in modo dettagliato il tuo servizio",
    NARRAZIONE : "Il servizio",
    NUOVAVERSIONEMESSAGGIO3 : "del tuo servizio",
    SCEGLISFIDE : "Scegli quale o quali tematiche vuole affrontare il tuo servizio",
    SELEZIONAMAPPA : "Indica dove si colloca il tuo servizio",
    URLSITO : "Sito web"
  },
  MODULESTORYCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Η ιστορία δημοσιεύθηκε επιτυχώς!",
    ALERT3 : "Εισάγετε μια αξία για να δηλώσετε το υποθετικό κόστος της τακτικής",
    ALTROMATERIALE : "Αν έχετε άλλο υλικό για να διηγηθείτε καλύτερα την ιστορία σας, ανεβάστε το εδώ.",
    CREAIDEA : "Πείτε την ιστορία σας",
    DAIBREVETITOLO : "Δώστε στην ιστορία σας έναν τίτλο σύντομο και με νόημα",
    DESCRIVI : "Περιγράψτε την ιστορία σας σε λίγες γραμμές",
    IMMAGINERAPPRESENTATIVA : "Εισάγετε μια εικόνα που περιγράφει την ιστορία σας",
    IMMAGINI : "Δημοσιεύστε άλλες εικόνες για να διηγηθείτε καλύτερα την ιστορία σας.",
    INDICACOSTO : "",
    NARRA : "Πείτε την ιστορία σας λεπτομερώς",
    NARRAZIONE : "Η ιστορία",
    SCEGLISFIDE : "Επιλέξτε ποιο/ποια θέμα(τα) αφορά η ιστορία σας.",
    SELEZIONAMAPPA : "Δείξτε πού έλαβε χώρα η ιστορία σας",
    URLSITO : "Sito web",
    VIDEO : "Αν υπάρχει ή αν δημιουργήσατε κάποιο βίντεο στο οποίο λέτε την ιστορία σας στο YouTube, μοιραστείτε το με όλους, αντιγράφοντας το URL εδώ.",
    VIDEOHINT : "Ένα βίντεο σας βοηθάει να διηγηθείτε μια ιστορία και να φτάσετε σε περισσότερους ανθρώπους."
  },
  MODULESTORYEDIT : {
    ALERT2 : "Η ιστορία δημοσιεύθηκε επιτυχώς!",
    ALERT2TITLE : "Πρόκειται να αναρτήσετε μια νέα έκδοση της ιστορίας",
    ALERT3 : "Επιλέξτε σε ποια γλώσσα θέλετε να δημοσιεύσετε την ιστορία σας. Πρέπει να επιλέξετε τουλάχιστον μία",
    ALTROMATERIALE : "Αν έχετε άλλο υλικό για να διηγηθείτε καλύτερα την ιστορία σας, ανεβάστε το εδώ.",
    MODIFICAIDEA : "Επεξεργαστείτε την ιστορία",
    NARRA : "Πείτε την ιστορία σας λεπτομερώς",
    NARRAZIONE : "Η ιστορία",
    SCEGLISFIDE : "Επιλέξτε ποιο/ποια θέμα(τα) αφορά η ιστορία σας.",
    SELEZIONAMAPPA : "Δείξτε πού έλαβε χώρα η ιστορία σας",
    URLSITO : "Sito web"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Η ιστορία δημοσιεύθηκε επιτυχώς!",
    ALERT2TITLE : "Πρόκειται να αναρτήσετε μια νέα έκδοση της ιστορίας",
    ALERT3 : "Επιλέξτε σε ποια γλώσσα θέλετε να δημοσιεύσετε την ιστορία σας. Πρέπει να επιλέξετε τουλάχιστον μία",
    ALERT5 : "",
    ALTROMATERIALE : "Εάν έχετε άλλο χρήσιμο υλικό για να πείτε την ιστορία σας, ανεβάστε το εδώ.",
    ELENCOIDEE : "Όλες οι ιστορίες",
    MODIFICAIDEA : "Επεξεργαστείτε την ιστορία",
    NARRA : "Πείτε την ιστορία σας λεπτομερώς",
    NARRAZIONE : "Η ιστορία",
    NUOVAVERSIONEMESSAGGIO3 : "της ιστορίας σας",
    SCEGLISFIDE : "Επιλέξτε ποιο/ποια θέμα(τα) αφορά η ιστορία σας.",
    SELEZIONAMAPPA : "Δείξτε πού έλαβε χώρα η ιστορία σας",
    URLSITO : "Sito web"
  },
  MODULETACTICCREATE : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Η στρατηγική έχει μπει σωστά!",
    ALERT3 : "Εισαγάγετε μια υποτιθέμενη τιμή για να υποδείξετε το Εκτιμώμενο κόστος της στρατηγικής",
    ALTROMATERIALE : "Εάν έχετε άλλο χρήσιμο υλικό για την καλύτερη απεικόνιση της στρατηγικής (σχέδια, πληροφορίες, τεχνικές προδιαγραφές...), ανεβάστε το εδώ",
    CREAIDEA : "Γράψτε τη στρατηγική",
    DAIBREVETITOLO : "Δώστε στην στρατηγική έναν σύντομο και πιασάρικο τίτλο",
    DESCRIVI : "Συνοψίστε την στρατηγική με λίγα λόγια. Στη συνέχεια, μπορείτε να το περιγράψετε λεπτομερώς στην επόμενη ενότητα",
    IMMAGINERAPPRESENTATIVA : "Εισάγετε μια φωτογραφία που αντιπροσωπεύει τη στρατηγική",
    IMMAGINI : "Ανεβάστε περισσότερες εικόνες για να βοηθήσετε τον κόσμο να καταλάβει καλύτερα τη στρατηγική",
    INDICACOSTO : "Υποδείξτε το κόστος της στρατηγικής",
    NARRA : "Περιγράψτε τη στρατηγική με λεπτομέρειες",
    NARRAZIONE : "Η στρατηγική",
    SCEGLISFIDE : "Διαλέξτε με ποια θέματα θα ασχοληθεί η στρατηγική",
    SELEZIONAMAPPA : "Εντοπείστε που βρίσκεται η στρατηγική",
    URLSITO : "Sito web",
    VIDEO : "Εάν υπάρχει ένα βίντεο ή το φτιάξατε για να εξηγήσετε την στρατηγική, ενσωματώστε το αντιγράφοντας τον κώδικα εδώ (όχι τη διεύθυνση URL) για να είναι ορατό σε όλους",
    VIDEOHINT : "Το βίντεο χρησιμεύει για να απεικονίσει καλύτερα την στρατηγική και να αλληλεπιδράσετε με περισσότερους ανθρώπους."
  },
  MODULETACTICEDIT : {
    ALERT2 : "Η στρατηγική έχει μπει σωστά!",
    ALERT2TITLE : "Είστε έτοιμοι να δημοσιεύσετε μια νέα εκδοχή της στρατηγικής",
    ALERT3 : "Επιλέξτε τη γλώσσα στην οποία θέλετε να δημοσιεύσετε τη στρατηγική σας. Πρέπει να διαλέξετε τουλάχιστον μία γλώσσα",
    ALTROMATERIALE : "Εάν έχετε άλλο χρήσιμο υλικό για την καλύτερη απεικόνιση της στρατηγικής (σχέδια, πληροφορίες, τεχνικές προδιαγραφές...), ανεβάστε το εδώ",
    MODIFICAIDEA : "Επεξεργαστείτε τη στρατηγική",
    NARRA : "Περιγράψτε τη στρατηγική με λεπτομέρειες",
    NARRAZIONE : "Η στρατηγική",
    SCEGLISFIDE : "Διαλέξτε με ποια θέματα θα ασχοληθεί η στρατηγική",
    SELEZIONAMAPPA : "Εντοπείστε που βρίσκεται η στρατηγική",
    URLSITO : "Sito web"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "Controlla di aver inserito i dati obbligatori: Titolo e Sintesi",
    ALERT2 : "Η στρατηγική έχει μπει σωστά!",
    ALERT2TITLE : "Είστε έτοιμοι να δημοσιεύσετε μια νέα εκδοχή της στρατηγικής",
    ALERT3 : "Επιλέξτε τη γλώσσα στην οποία θέλετε να δημοσιεύσετε τη στρατηγική σας. Πρέπει να διαλέξετε τουλάχιστον μία γλώσσα",
    ALERT5 : "Εισαγάγετε μια υποτιθέμενη τιμή για να υποδείξετε το Εκτιμώμενο κόστος της στρατηγικής",
    ALTROMATERIALE : "Εάν έχετε άλλο χρήσιμο υλικό για την καλύτερη απεικόνιση της στρατηγικής (σχέδια, πληροφορίες, τεχνικές προδιαγραφές...), ανεβάστε το εδώ",
    ELENCOIDEE : "Όλες οι στρατηγικές",
    MODIFICAIDEA : "Επεξεργαστείτε τη στρατηγική",
    NARRA : "Περιγράψτε τη στρατηγική με λεπτομέρειες",
    NARRAZIONE : "Η στρατηγική",
    NUOVAVERSIONEMESSAGGIO3 : "της στρατηγικής",
    SCEGLISFIDE : "Διαλέξτε με ποια θέματα θα ασχοληθεί η στρατηγική",
    SELEZIONAMAPPA : "",
    URLSITO : "Sito web"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "Κοινότητα",
    ISCRITTI : "{subscribers} εγγεγραμμένα ({profiles} προφίλ)"
  },
  PARTIALSIDEE : {
    APPROVED : "Εγκεκριμένο",
    BUDGET : "Προϋπολογισμός: €",
    CREATADA : "Δημιουργήθηκε από",
    RANK01 : "Κατάταξη 01",
    VOTATA : "ΨΗΦΊΣΤΗΚΕ"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "<h4>Δεν μπορείτε να συνδεθείτε με το λογαριασμό σας. Ζητήστε πιστοποιημένη πρόσβαση στη διεύθυνση <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>.",
    ALERT1TITLE : "<b>Η ομάδα είναι ιδιωτική</b>",
    ALERT2 : "Θα μπορέσετε να συνδεθείτε όταν ξεκινήσει!",
    ALERT2TITLE : "Η φάση είναι σε εξέλιξη.",
    ALERT3 : "<h4>Δεν μπορείτε να συνδεθείτε με το λογαριασμό σας. Ζητήστε πιστοποιημένη πρόσβαση στη διεύθυνση <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>. ",
    ALERT3TITLE : "<b>Η διαδικασία είναι ιδιωτική</b>",
    COMMENTI : "Σχόλια",
    COMMUNITY : "Κοινότητα",
    CONCLUSA : "Συμπερασματικά",
    CONCLUSO : "Συμπερασματικά",
    DAATTIVARE : "Προς ενεργοποίηση",
    DRIVERAMBIENTE : "Οδηγοί: Περιβάλλον",
    FASE : "Φάση",
    INATTIVAZIONE : "Ενεργοποίηση",
    INCORSO : "Σε εξέλιξη",
    PARTECIPA : "Εγγραφείτε στο",
    PRIVATO : "Ιδιωτικό",
    PUBBLICO : "Δημόσιο",
    RICHIEDIACCESSO : "Αίτηση πρόσβασης",
    VALUTAZIONI : "Εκτιμήσεις",
    VISUALIZZA : "Προβολή",
    VOTA : "Ψηφίστε"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "<h4>Abbiamo inviato un codice SMS di verifica al tuo cellulare.</h4>",
    ALERT1TITLE : "Info",
    ALERTPHONE1HTML : "Il numero fornito non risulta registrato presso l'organizzazione",
    ALERTPHONE1TITLE : "Utente sconosciuto",
    CAMBIAPASSWORD : "Cambia la password",
    CODICEOTP : "Codice OTP*",
    CONFERMA : "Conferma",
    CONFERMAPASSWORD : "Conferma la password",
    ERROROTPMAXCHAR : "Numero massino di cifre inseribili 6",
    ERRORPWEQUAL : "Le password devono essere uguali",
    ERRORPWLENGTH : "La password deve essere lunga almeno 6 caratteri",
    INSERISCICELLULARE : "Inserisci cellulare",
    INSERISCIPASSWORD : "Inserisci la password",
    METODOCELLULARE : "Cellulare",
    METODORECUPERO : "Metodo di recupero",
    OTPDIMENTICATO : "Hai dimenticato di inserire il codice OTP",
    PHONEERROR1 : "Hai dimenticato di inserire il prefisso",
    PHONEERROR2 : "Hai per caso dimenticato di inserire il tuo numero di cellulare?",
    PHONEERROR3 : "Il numero che hai inserito è un po’ strano … controlla bene! :)",
    PROCEDI : "procedi",
    RECUPEROPASSWORD : "Recupera la tua password",
    RECUPERORIUSCITO : "Cambio password completato con successo",
    RICHIEDINUOVOCODICE1 : " <b>Non hai ricevuto il codice?</b> <br>Torna indietro e verifica di aver inserito correttamente il tuo numero di cellulare. Altrimenti ",
    RICHIEDINUOVOCODICE2 : " richiedi un nuovo codice",
    RICHIEDINUOVOCODICE3 : " o scrivici a ",
    SCELTAMETODO : "Scegli come recuperarla",
    SCELTAMETODONECESSARIA : "La scelta del metodo è obbligatoria",
    VERIFICA1 : "Verifica il tuo numero di telefono",
    VERIFICA2 : "Ti abbiamo inviato un SMS con un codice di verifica OTP. Inseriscilo per accedere al tuo account e creare la tua nuova password."
  },
  PHASEMODULECARDIDEA : {
    IDEE : "προτάσεις"
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "servizi"
  },
  PHASEMODULECARDSTORY : {
    IDEE : "ιστορίες"
  },
  PHASEMODULECARDTACTIC : {
    IDEE : "tactics"
  },
  PORTAL : {
    CONTACTUS : "Contact us",
    DELIBERATIVESPACE : "Deliberative space",
    HOME : "Home",
    INSTRUMENTS : "The instruments",
    PROJECT : "The project",
    SUBSCRIBE : "Subscribe"
  },
  PRIVACY : {
    DIRITTO : "6. Diritto di accesso e controllo dei dati personali, diritto di opposizione",
    DIRITTODESCRIZIONE1 : "In ogni momento Lei ha diritto di ottenere la conferma dell’esistenza o meno di dati personali che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile; può ottenere l’indicazione dell’origine dei Suoi dati personali, delle finalità e modalità del loro trattamento, della logica applicata in caso di trattamento effettuato con l’ausilio di strumenti elettronici, degli estremi identificativi del Titolare, dei responsabili (se nominati) e della tipologia dei soggetti ai quali i Suoi dati personali possono essere comunicati, trasferiti, o che possono venirne a conoscenza in qualità di responsabili o incaricati.",
    DIRITTODESCRIZIONE2 : "Lei ha altresì il diritto di accedere a qualsiasi Suo dato personale trattato da BiPart e potrà, chiedere la modifica, correzione, copia, restrizione o estensione della condivisione, anonimizzazione, portabilità o eliminazione (anche parziale) dei Suoi dati personali. Lei ha altresì il diritto di revocare in qualsiasi momento il consenso al trattamento dei Suoi dati da parte del Titolare.<br>Potrà esercitare i predetti diritti inviando una richiesta scritta alla sede legale di BiPart Impresa sociale srl (già BiPart Srls), sita in Milano, via Metauro, 4 mediante lettera raccomandata ovvero email al seguente indirizzo PEC bipart@casellapec.com.\nCon comunicazione da inviarsi al medesimo recapito Lei potrà altresì esercitare il diritto di opporsi in qualsiasi momento, per motivi connessi alla Sua situazione particolare, al trattamento (anche soltanto parziale) dei dati personali che La riguardano, ancorché pertinenti allo scopo della raccolta e al trattamento di dati personali a fini di invio di materiale pubblicitario o di vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale. Da tale data in avanti il Titolare si asterrà dal trattare ulteriormente i Suoi dati personali salvi i diritti riconosciutigli ex lege.\nPer avere ulteriori informazioni in ordine ai Suoi diritti, La invitiamo a visitare il sito web dell’Autorità Garante per la protezione dei dati personali all’indirizzo www.garanteprivacy.it.",
    FINALITA : "3. Finalità del trattamento dei suoi dati",
    FINALITADESCRIZIONE1 : "Il trattamento dei Suoi dati ha la finalità di consentire l’utilizzo della Piattaforma e l’erogazione di tutti i servizi connessi e/o strumentali alla stessa ovvero di tutte le attività legate allo sviluppo e realizzazione dei progetti di bilancio partecipativo e di partecipazione in generale con i soggetti, pubblici e privati, coinvolti in tali progetti gestiti mediante la Piattaforma da BiPart; il trattamento trova quindi la propria base giuridica negli accordi di volta in volta conclusi dal Titolare con i soggetti (pubblici e privati) coinvolti nei progetti di bilancio partecipativo gestiti da BiPart, anche mediante la Piattaforma.",
    FINALITADESCRIZIONE2 : "Per il conseguimento di tali finalità, ed a titolo meramente esemplificativo, i Suoi dati personali potranno essere utilizzati e comunicati a soggetti terzi per l’evasione dell'iscrizione, per la verifica della correttezza dei dati anagrafici inseriti, per l’esecuzione e gestione del servizio, per il data entry, per l’elaborazione in forma statistica della partecipazione alla Piattaforma (utilizzo dei dati socio-demografici e geografici degli iscritti). Inoltre, i Suoi dati personali saranno trattati per assolvere ad obblighi di legge, contabili e fiscali. \nPrecisiamo pertanto che il mancato conferimento dei dati personali a tali fini potrebbe implicare l’impossibilità – totale o parziale – per BiPart di fornire la prestazione richiesta e/o svolgere i relativi servizi connessi e/o strumentali.",
    FINALITADESCRIZIONE3 : "I Suoi dati personali saranno conservati da BiPart fino a quando il Suo account sarà aperto. BiPart, a decorrere dalla disattivazione dell’account, provvederà alla cancellazione dei Suoi dati personali, avendo comunque la facoltà di renderli anonimi e conservarli esclusivamente per la creazione di un modello di utenza (che tenga conto esclusivamente del sesso, dell’età e, se disponibile, dell’area geografica) utile per la ricerca scientifica e statistica legata alla diffusione e sviluppo della prassi di bilancio partecipativo.",
    INTRO : "1. Introduzione",
    INTRODESCRIZIONE1 : "BiPart Impresa sociale srl (già BiPart Srls), C.F. e P.IVA 094444700968, con sede in Milano, via Metauro n. 4, in qualità di titolare del trattamento dei dati (di seguito anche l’“BiPart” o anche il “Titolare”), ai sensi di quanto previsto dal D.Lgs. 30 giugno 2003 n. 196 e ss. modifiche (di seguito “Codice Privacy”) e dal Regolamento UE n. 2016/679 (di seguito “GDPR”), intende informarLa di quali saranno le modalità e le finalità del trattamento dei dati personali da Lei forniti.",
    INTRODESCRIZIONE2 : "Gli utenti iscritti (di seguito i “Membri”) hanno la possibilità di partecipare alle attività rese possibili dalla Piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione) nonché ai vari progetti di bilancio partecipativo, anche interagendo tra loro, mediante scambio di conoscenze, proposte ed idee, sia in modalità privata che pubblica. I contenuti ed i dati su alcuni dei servizi offerti con la Piattaforma sono pertanto visualizzabili dai Membri così come pure da soggetti non iscritti (i “Visitatori”). Lei utilizzando la Piattaforma presta dunque il Suo proprio specifico, preventivo, libero e facoltativo consenso al trattamento dei Suoi dati secondo quanto indicato nella presente informativa.",
    MANIFESTO : "7. Manifesto del consenso",
    MANIFESTODESCRIZIONE1 : "Qualora i termini della presente informativa siano da Lei condivisi, BiPart Le chiede di autorizzazione e acconsentire al trattamento dei Suoi dati personali nelle modalità e finalità qui indicate, mediante approvazione espressa. In segno inequivocabile del Suo consenso Le chiediamo quindi di barrare la presente casella.",
    MODALITA : "4. Modalità di trattamento e sicurezza dei suoi dati",
    MODALITADESCRIZIONE1 : "I Suoi dati personali saranno raccolti e trattati principalmente con strumenti elettronici ed informatici e memorizzati sia su supporti informatici che su supporti cartacei e su ogni altro tipo di supporto idoneo da parte dei soggetti a ciò espressamente autorizzati dal Titolare, tutti debitamente istruiti in materia di sicurezza dei dati personali e del diritto alla privacy. Secondo la normativa indicata dal Codice Privacy e dal GDPR, tale trattamento sarà improntato ai principi di correttezza, liceità, trasparenza e di tutela della Sua riservatezza e dei Suoi diritti.",
    MODALITADESCRIZIONE2 : "Per evitare l’accesso non autorizzato o la divulgazione e assicurare l’utilizzo appropriato dei Suoi dati personali, BiPart ha messo in atto procedure amministrative, tecniche e fisiche ragionevoli e appropriate che verranno costantemente adeguate alla normativa tempo per tempo vigente in materia di protezione dei dati personali. Al fine di garantire il rispetto degli standard di cui al Codice Privacy e al GDPR il trattamento dei Suoi dati personali sarà svolto esclusivamente in Paesi appartenenti all’Unione Europea.",
    MODALITADESCRIZIONE3 : "BiPart si impegna ad adeguare la propria privacy policy alle disposizioni di legge e regolamentari, pertanto è possibile che la presente informativa subisca, nel corso del tempo, delle modifiche di aggiornamento. In caso di modifiche significative alla nostra informativa sulla privacy, che alterino notevolmente le nostre prassi in materia di privacy, BiPart avrà cura di avvisarLa anche attraverso altri mezzi, ad esempio inviando un'e-mail o pubblicando un avviso sul nostro sito web e/o sulle pagine dei social media prima che le modifiche entrino in vigore.",
    SOGGETTI : "5. Soggetti a cui potranno essere comunicati i dati personali",
    SOGGETTIDESCRIZIONE1 : "I Suoi dati personali potranno essere oggetto di comunicazione o diffusione:",
    SOGGETTIDESCRIZIONE2 : "<li>alle Amministrazioni Pubbliche, agli Enti Locali e più in generale ai soggetti, anche privati, che aderiscono o prendono parte ai progetti di bilancio partecipativo gestiti da BiPart anche mediante la Piattaforma;</li><li>ai soggetti autorizzati che prestino attività di assistenza e consulenza in materia amministrativa, tecnico/informatica, tributaria, contabile, finanziaria e legale;</li><li>a soggetti terzi in adempimento ad obblighi di legge.</li>",
    SOGGETTIDESCRIZIONE3 : "I predetti soggetti opereranno in qualità di Responsabili o comunque di soggetti autorizzati al trattamento dei Suoi dati personali nei limiti qui da Lei autorizzati. Ai soggetti autorizzati e ai Responsabili, ove nominati, BiPart impartirà adeguate istruzioni operative, al fine di poter garantire la riservatezza e la sicurezza dei Suoi dati personali.",
    SOTTOTITOLO : "Trattamento dei dati personali",
    TIPOLOGIA : "2. Tipologia dei dati raccolti",
    TIPOLOGIADESCRIZIONE1 : "Per creare un account attivo nella piattaforma Lei deve fornire alcuni dati personali che includono il Suo nome, cognome, indirizzo email e password (dati essenziali). La Piattaforma Le consente la possibilità di utilizzare le opzioni di implementazione del Suo profilo integrando e fornendo informazioni ulteriori sulla Sua persona che possono includere, a titolo esemplificativo e non esaustivo, l’indicazione dell’area geografica o la specificazione della località in cui risiede, il titolo di studio, o una Sua fotografia. Parimenti, è possibile che nell’utilizzo della Piattaforma Le sia consentito di condividere la Sua rubrica ed i Suoi contatti. Non è necessario che pubblichi o inserisca tali dati personali, precisiamo comunque che è Sua facoltà scegliere espressamente se, una volta inseriti, tali dati possano essere pubblicamente visibili da altri Membri o meno.",
    TIPOLOGIADESCRIZIONE2 : "E’ dunque rimessa esclusivamente a Lei la scelta di comunicare e inserire nella Piattaforma, e nel Suo profilo, informazioni di natura sensibile (idonei a rivelare l’origine razziale ed etnica, le convinzioni religiose, filosofiche o di altro genere, le opinioni politiche, l’adesione a partiti, sindacati, associazioni od organizzazioni a carattere religioso, filosofico, politico o sindacale, nonché i dati personali idonei a rivelare lo stato di salute e la vita sessuale), così come pure di renderle pubbliche. La invitiamo quindi a non pubblicare dati che non vuole rendere pubblicamente disponibili, dovendosi precisare che con l’inserimento di tali dati nel Suo account e nella Piattaforma, ne autorizza il trattamento.",
    TITOLO : "Informativa sulla privacy"
  },
  PROCESSABOUT : {
    FASE : "Φάση",
    TITOLO : "Η συμμετοχική διαδικασία"
  },
  PROCESSCONTATTI : {
    CONTATTI : "Επικοινωνία"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "Διαθέσιμος προϋπολογισμός:",
    BUDGETPROPOSTO : "Προτεινόμενος προϋπολογισμός:",
    CONCLUSA : "Συμπερασματικά",
    DAATTIVARE : "Προς ενεργοποίηση",
    FASIPROCESSO : "Βήματα διαδικασίας",
    INCORSO : "Σε εξέλιξη",
    ITUOIVOTI : "Οι ψήφοι σας",
    PROGRESS : "Πρόοδος"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : "Ερωτήσεις και Απαντήσεις"
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : "Οδηγίες"
  },
  PROCESSLIST : {
    BUDGET : "Προϋπολογισμός",
    DATAINIZIO : "Ημερομηνία έναρξης",
    DATAINS : "Ημερομηνία εισόδου",
    PARTECIPANTI : "Ν συμμετέχοντες",
    PROCESSI : "διεργασίες",
    TITOLO : "Συμμετοχικές διαδικασίες"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "Όλα τα θέματα για να προβληματιστείτε και να βρείτε λύσεις στην κοινότητά σας",
    NOTEMATICHE : "Δεν υπάρχουν θέματα",
    OBIETTIVI : "Στόχοι και προκλήσεις για τον οργανισμό"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "Κοινότητα",
    GRUPPI : "ομάδες",
    TUTTI : "Όλες οι κοινοτικές ομάδες/τάξεις."
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "Πλαίσιο του οργανισμού",
    INFO : "Πληροφορίες σχετικά με την περιοχή(ες) που επηρεάζονται από τη διαδικασία",
    NOSCENARI : "Δεν υπάρχουν σενάρια"
  },
  PROCESSMENU : {
    ABOUT : "Η συμμετοχική διαδικασία",
    ASSISTENZA : "Help Desk",
    BACK : "Πίσω",
    COMMUNITY : "Κοινότητα",
    FAQ : "FAQ / Συχνές Ερωτήσεις",
    ISTRUZIONI : "Κατευθυντήριες γραμμές",
    NARRAZIONE : "Περισσότερες πληροφορίες",
    POP1 : "Διαδικασία",
    POP1DESCRIPTION : "Πληροφορίες διαδικασίας"
  },
  PROCESSPHASE : {
    ALERTBOZZA : "Έχετε ένα προσχέδιο. Μπορείτε να το βρείτε στο",
    BREADCRUMBTITLE : "Φάση",
    COMMENTIMENONUMEROSI : "Meno commentate",
    COMMENTIPIUNUMEROSI : "Più commentate",
    CONTATOREMODULI : "περιοχές",
    IDEE : "προτάσεις",
    INFORMAZIONI : "πληροφορίες",
    NAVIGATUTTELEPROPOSTE : "Αναζήτηση όλων των προτάσεων",
    NONCISONOPROPOSTE : "Δεν υπάρχουν προτάσεις",
    PS : "Προσωπικός χώρος",
    SCEGLIDOVEPARTECIPARE : "Επιλέξτε πού θα συμμετάσχετε"
  },
  PS : {
    ALERTMODIFICADISABILITATA : "<h4>Δεν είναι δυνατή η επεξεργασία του προφίλ.</h4>.",
    ALERTMODIFICADISABILITATATITLE : "<b>Η επεξεργασία του προφίλ σας είναι απενεργοποιημένη</b>.",
    ANNUALINCOME : "Ετήσιο εισόδημα",
    ATTEGGIAMENTO : "Στάση",
    BIOGRAPHY : "Βιογραφία",
    BUDGET : "Προϋπολογισμός",
    BUDGETTODONATE : "Προϋπολογισμός για δωρεές",
    COMPORTAMENTO : "Συμπεριφορά",
    EDU : "Εκπαίδευση",
    EDULAV : "Εκπαίδευση και εργασία",
    FEMMINA : "Γυναίκα",
    LAV : "Εργασία",
    MASCHIO : "Άντρας",
    MODIFICA : "Επεξεργασία προφίλ",
    NS : "Δεν προσδιορίζεται",
    POLVIS : "Πολιτικό όραμα",
    PROFILE : "Το προσωπικό σας προφίλ",
    PS : "Προσωπικός χώρος"
  },
  PSACCOUNT : {
    ANONIMO : "Ανώνυμος χρήστης",
    AS : "Ρυθμίσεις λογαριασμού",
    CITIZENSHIP : "Ιθαγένεια",
    COGNOME : "Επώνυμο",
    COGNOMENONPRESENTE : "Δεν υπάρχει αυτό το επώνυμο",
    ELIMINAACCOUNT : "<h4> Γράψτε μας στο <a href=\"mailto:support@bipart.it\">support@bipart.it</a> και θα αφαιρέσουμε τα στοιχεία του λογαριασμού σας.</h4>",
    ELIMINAACCOUNTTITLE : "<b>Θέλετε να διαγράψετε το λογαριασμό σας; </b>.",
    EMAIL : "Ηλεκτρονικό ταχυδρομείο",
    EMAILNONPRESENTE : "Δεν υπάρχει αυτό το email",
    NOME : "Όνομα",
    NOMENONPRESENTE : "Δεν υπάρχει αυτό το όνομα",
    PASSWORD : "Κωδικός πρόσβασης",
    PHONE : "Κινητό",
    PHONENONPRESENTE : "Δεν υπάρχει αυτό το κινητό τηλέφωνο",
    PRIVATO : "ιδιωτικό",
    PS : "Προσωπικός χώρος",
    PUBBLICO : "δημόσιo",
    RESIDENCECITY : "Πόλη κατοικίας",
    TAXCODE : "Φορολογικός κωδικός/αριθμός ΦΠΑ",
    TAXCODENONPRESENTE : "Φορολογικός κωδικός/αριθμός ΦΠΑ",
    TUOPROFILO : "Το προφίλ σας είναι",
    USERNAME : "Όνομα χρήστη",
    USERNAMENONPRESENTE : "Δεν υπάρχει αυτό το όνομα χρήστη"
  },
  PSACTIVITIES : {
    ALERTDETAIL : "<h4>Στην ενότητα αυτή θα βρεις τον κατάλογο των προτάσεών σου και των προτάσεων που ψήφισες.</h4>.",
    ALERTINFO : "<b>Πληροφορίες</b>",
    ATTIVITA : "Όλες οι δραστηριότητές σας",
    BUDGETDONATO : "Προϋπολογισμός δωρεά",
    FOLLOWERS : "Ακόλουθοι",
    IDEESEGUITE : "Ακολούθησαν προτάσεις",
    IDEEVOTATE : "Ψηφισθείσες προτάσεις",
    INBOZZA : "Σχέδιο",
    MODIFICAVOTO : "Άλλαξε την ψήφο σου",
    NOPROCESSI : "Δε συμμετέχετε σε καμία διαδικασία ως τώρα!",
    NOPROCESSIATTIVI : "Non ci sono processi attivi",
    PS : "Προσωπικός χώρος",
    PUBBLICATA : "Δημοσιευμένο",
    TUEPROPOSTE : "Οι συνεισφορές σου",
    TUOIPROCESSI : "Οι συμμετοχικές σου διαδικασίες",
    TUOIVOTI : "Οι ψήφοι σας",
    VISUALIZZA : "Προβολή",
    VOTIESPRESSI : "Ψήφοι"
  },
  PSEDIT : {
    AS : "Ρυθμίσεις λογαριασμού",
    CONFERMAPASSWORD : "Επιβεβαίωση κωδικού πρόσβασης",
    DRAGANDDROP : "Σύρετε και αφήστε για να αναρτήσετε το avatar σας (Μέγιστο μέγεθος 5 MB)",
    ERRORE : "Προσοχή!",
    ERROREPASS : "Οι δύο κωδικοί πρόσβασης πρέπει να ταιριάζουν και δεν μπορούν να είναι κενοί",
    FORMSUPPORTATI : "Οι υποστηριζόμενες μορφές είναι JPG, GIF, PNG",
    MODAVATAR : "Επεξεργασία avatar",
    MODIFICADATI : "Αλλαγή των δεδομένων της",
    MODOK : "Ο χρήστης έχει επεξεργαστεί επιτυχώς",
    MODPASS : "Αλλαγή κωδικού πρόσβασης",
    NUOVAPASSWORD : "Νέος κωδικός πρόσβασης",
    PRIVACY : "Απόρρητο & Πολιτική",
    PROFILOPUBBLICO : "Δημόσιο προφίλ",
    PS : "Προσωπικός χώρος",
    SALVA : "Αποθήκευση",
    SUCCESSO : "Επιτυχία"
  },
  PSEDIT2 : {
    AS : "Το προσωπικό σας προφίλ",
    ATTEGGIAMENTO : "Στάση",
    BIOGRAFIA : "Βιογραφία",
    BUDGET : "Προϋπολογισμός",
    BUDGETDADONARE : "Προϋπολογισμός για δωρεές",
    COMPORTAMENTO : "Συμπεριφορά",
    EDU : "Εκπαίδευση",
    EDULAV : "Εκπαίδευση και εργασία",
    INFOVARIE : "Διάφορες πληροφορίες",
    LAV : "Εργασία",
    PS : "Προσωπικός χώρος",
    REDDITO : "Ετήσιο εισόδημα",
    SALVA : "Αποθήκευση",
    VISIONEPOLITICA : "Πολιτικό όραμα"
  },
  QUICKPANEL : {
    VOTE: "Vote {n}",
    VOTEDON: "Voted on {n}",
    EDITVOTE: "Edit vote",
    DONATION: "Donation"
  },
  QUICKUSER : {
    ACCOUNT : "Λογαριασμός",
    AGGIORNAPROFILOEPRIVACY : "Ενημερώστε το προφίλ σας και την πολιτική απορρήτου",
    IDEEVOTATE : "Όλες οι ιδέες που έχετε ψηφίσει",
    PROCESSIPARTECIPATIVI : "Συμμετοχικές διαδικασίες",
    SALUTO : "Γεια",
    SIGNOUT : "Αποσύνδεση",
    VOTI : "Οι ψήφοι σας"
  },
  REGISTER : {
    ACCETTOLA : "Αποδέχομαι την",
    ATTENZIONE : "Προσοχή",
    BACCEDI : "Συνδέσου",
    BACCETTO : "Δέχομαι",
    BREGISTRA : "Μητρώο",
    COGNOMEDIMENTICATO : "Ξέχασες να βάλεις το επώνυμό σου",
    GIAACCOUNT : "Έχεις ήδη λογαριασμό;",
    MAILDIMENTICATA : "Ξέχασες να εισάγεις το email σου",
    MAILNONVALIDA : "Μη έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου.",
    NEWSLETTER : "Θέλω να ενημερώνομαι για έργα και ενεργές διαδικασίες στο BiPart",
    NOMEDIMENTICATO : "Ξέχασες να εισάγεις το όνομά σου",
    PASSWORDDIMENTICATA : "Ξέχασες να πληκτρολογήσεις τον κωδικό πρόσβασής σου",
    PLACEHOLDERCOGNOME : "Επίθετο",
    PLACEHOLDEREMAIL : "Email",
    PLACEHOLDERNOME : "Όνομα",
    PLACEHOLDERPASSWORD : "Κωδικός",
    PLACEHOLDERUTENTE : "Όνομα χρήστη",
    PRIVACY : "Πολιτική απορρήτου",
    TERMINICONDIZIONI : "Όροι και προϋποθέσεις χρήσης",
    USERNAMEDIMENTICATO : "Ξέχασες να εισάγεις το όνομα χρήστη σου",
    USERNAMEFORBIDDENCHARACTERS : "Το όνομα χρήστη δε μπορεί να περιέχει τους ακόλουθους χαρακτήρες: @",
    USERNAMEMINLENGTH : "Το όνομα χρήστη πρέπει να περιέχει τουλάχιστον 3 χαρακτήρες"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "Αποδέχομαι το",
    ACCETTOLA : "Συμφωνώ με το",
    BCONFERMA : "Επιβεβαίωση",
    DETTMEX : "Έχουμε στείλει ένα email* στα εισερχόμενά σου.<br><span class=\"font-weight-bolder\">Επιβεβαίωσέ το</span> για να γίνεις μέλος της οργάνωσης.</p><p class=\"font-weight-bolder\">* Προσοχή! 'Ελεγξε επίσης το φάκελο spam.",
    ERR1 : "Δεν έχεις εισάγει τα απαιτούμενα δεδομένα. Προσπάθησε ξανά ή γράψε στη διεύθυνση <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR2 : "Ουπς... κάτι δεν λειτούργησε; Κωδικός κατάστασης:",
    GIAACCOUNT : "Έχεις ήδη λογαριασμό;",
    GRAZIE : "Σε ευχαριστούμε που έγινες μέλος της κοινότητάς μας",
    ISCRIVITIPROCESSO : "Εγγραφή για τη δοκιμή",
    NEWSLETTER : "Θέλω να ενημερώνομαι για τα έργα του BiPart",
    PRIVACY : "Πολιτική απορρήτου",
    REQUIREDFIELD : "Υποχρεωτικό πεδίο",
    TERMINI : "Οροι και προϋποθέσεις",
    TITLE2 : "Προσοχή",
    TITLE3 : "Σφάλμα",
    VALIDEMAIL : "Πρέπει να είναι έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου"
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "Αποδέχομαι την",
    BACCEDI : "Συνδεθείτε",
    BACCETTO : "Δέχομαι",
    BCHIUDI : "Κλείστε το",
    BREGISTRA : "ΕΓΓΡΑΦΕΙΤΕ",
    COGNOMEDIMENTICATO : "Ξέχασες να βάλεις το επώνυμό σου",
    ERR1 : "Ουπς... κάτι δεν λειτούργησε; Κωδικός κατάστασης:",
    FAIGIAPARTE : "Έχετε ήδη εγγραφεί ή έχετε τα διαπιστευτήρια για να συμμετέχετε;",
    ISCRIVITIPROCESSO : "Εγγραφείτε στην οργάνωση και στις διεργασίες",
    MAILNONVALIDA : "Μη έγκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου.",
    NEWSLETTER : "Θέλω να ενημερώνομαι για τα έργα και τις ενεργές πρωτοβουλίες του BiPart",
    NOMEDIMENTICATO : "Ξεχάσατε να εισάγετε το όνομά σας",
    PASSWORDDIMENTICATA : "Ξεχάσατε να πληκτρολογήσετε τον κωδικό πρόσβασής σας",
    PRIVACY : "Πολιτική απορρήτου",
    TERMINICONDIZIONI : "Όροι και προϋποθέσεις χρήσης",
    TITLE1 : "Σφάλμα",
    USERNAMEDIMENTICATO : "Ξεχάσατε να εισάγετε το όνομα χρήστη σας"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "Επιτυχής σύνδεση",
    ACCETTO : "Διάβασα και αποδέχομαι",
    ACCETTOLA : "Διάβασα και αποδέχομαι",
    AUTORIZZATO : "Είσαι ξουσιοδοτημένος/η να συμμετέχεις στην κοινότητά μας",
    BCONFERMA : "Επιβεβαίωση",
    DETTMEX : "Έχουμε στείλει ένα email* στα εισερχόμενά σου.<br><span class=\"font-weight-bolder\">Επιβεβαίωσέ το</span> για να γίνεις μέλος της οργάνωσης.</p><p class=\"font-weight-bolder\">* Προσοχή! Έλεγξε επίσης το φάκελο spam.",
    ERR1 : "Δεν έχεις εισάγει τα απαιτούμενα δεδομένα. Προσπάθησε ξανά ή γράψε στη διεύθυνση <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR3 : "Ουπς... Εμφανίστηκε ένα απροσδόκητο σφάλμα.",
    ERR4 : "Μη έγκυρο email",
    ERR5 : "Εγγραφή αποδοχή του «Απορρήτου» και των «Όρων και προϋποθέσεων»",
    ES : "Παράδειγμα: Faenza Συμμετοχή 2020/21",
    GIAACCOUNT : "Έχεις ήδη λογαριασμό;",
    GRAZIE : "Σε ευχαριστούμε που έγινες μέλος της κοινότητάς μας",
    HTMLMAIL : "Εδώ θα βρεις html mail",
    ISCRIVITIPROCESSO : "Εγγράψου για τη δοκιμή",
    NEWSLETTER : "Θέλω να ενημερώνομαι για τα έργα του BiPart",
    NIENTEACCOUNT : "Δεν έχεις ακόμη λογαριασμό;",
    NOME : "Όνομα (προαιρετικό)",
    ORGANIZATION : "BiPart",
    PARTECIPAPROCESSO : "Θέλεις να συμμετάσχεις στη διαδικασία;",
    PRIVACY : "Πολιτική απορρήτου",
    PROPOSTE : "Για να υποβάλλεις προτάσεις, να σχολιάσεις, να ζητήσεις αξιολογήσεις, να ψηφίσεις, πρέπει να εγγραφείς στη διαδικασία.",
    QUIMAIL : "Εδώ θα βρεις html email που εστάλη",
    REGISTRATI : "Εγγραφή",
    TERMINI : "Οροι και προϋποθέσεις",
    TITLE1 : "Συγχαρητήρια",
    TITLE2 : "Προσοχή",
    TITLE3 : "Σφάλμα",
    TITOLO : "BiPart"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "Συνδεθείτε",
    ACCETTO : "Δέχομαι",
    ACCETTOLA : "Αποδέχομαι την",
    BPROSEGUI : "Συνεχίστε",
    CAMBIAPASS : "Αλλαγή κωδικού πρόσβασης",
    ERR3 : "Ουπς... Εμφανίστηκε ένα απροσδόκητο σφάλμα.",
    ERR4 : "Μη έγκυρο μήνυμα",
    ERR5 : "Μη έγκυρα διαπιστευτήρια",
    INSERISCIMAIL : "Εισάγετε το email σας",
    ISCRIVITIPROCESSO : "Εγγραφείτε για τη δοκιμή",
    MEX1 : "Αποδοχή εγγραφής \"Απόρρητο\" και \"Όροι και προϋποθέσεις\"",
    MEX2 : "Επιτυχής έλεγχος ταυτότητας",
    MEXLOGIN : "<b>Καλώς ήρθατε στο BiPart,</b><br>το κοινωνικό δίκτυο της συμμετοχής των πολιτών.<br>Τα δεδομένα σας θα αντιμετωπίζονται με πλήρη σεβασμό στους ιταλικούς και ευρωπαϊκούς κανονισμούς και με ακόμη μεγαλύτερη προσοχή για την προστασία των απόψεων και των αποφάσεών σας.<br><b>Αν θέλετε να συνεχίσετε, διαβάστε και αποδεχτείτε τα δύο παρακάτω έγγραφα.</b>",
    NIENTEACCOUNT : "Δεν έχετε ακόμη λογαριασμό;",
    NONRICORDIPASS : "Δεν μπορείτε να θυμηθείτε τον κωδικό πρόσβασής σας;",
    NOTIFICHE : "*Εάν θέλετε να λαμβάνετε ειδοποιήσεις σχετικά με την πρόοδο των υποβληθεισών προτάσεων.",
    PRIVACY : "Πολιτική απορρήτου",
    REGISTRATI : "Εγγραφή",
    TERMINI : "Όροι και προϋποθέσεις παροχής υπηρεσιών",
    TERMINICONDIZIONI : "Για να συνεχίσετε, παρακαλούμε να διαβάσετε τους <a href=\"https://bipart.it/terms\" target=\"blank\">Όρους και προϋποθέσεις παροχής υπηρεσιών </a><br> και την <a href=\"https://bipart.it/privacy\" target=\"blank\">Πολιτική προστασίας προσωπικών δεδομένων</a>.",
    TITLE1 : "Επιτυχία",
    TITLE2 : "Προσοχή",
    TITLE3 : "Σφάλμα",
    UTENTEAUTENTICATO : "-- Πιστοποιημένος χρήστης --"
  },
  SERVICEDETAIL : {
    ALERT1 : "Devi inserire almeno un campo obbligatorio tra lo stato o il punteggio assegnato al servizio",
    ALERT13 : "Hai già inserito una valutazione di dettaglio per questo servizio",
    ALERT14 : "Il servizio è già stato valutato",
    ALERT3TITLE : "Grazie per aver votato questo servizio",
    ALERT4 : "Naviga tra i servizi e scegli quali altre preferisci di più",
    ALERT5 : "Puoi continuare a donare anche per altri servizi scegliendo come distribuire il tuo budget a disposizione.",
    ALERT5TITLE : "Grazie per aver sostenuto il servizio!",
    ALERT6TITLE : "Stai seguendo questo servizio!",
    ALERT7TITLE : "Non stai più seguendo questo servizio",
    ALERT8TITLE : "Sei sicuro di voler cancellare questo servizio?",
    ALERT9 : "Il servizio è stato eliminato",
    ALERT9TITLE : "Eliminazione servizio",
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Ricordati di pubblicarla",
    ALERTPUBBLICATA : "servizio in fase di revisione. Collabora al suo sviluppo",
    AUTORE : "Referente",
    CANVERSION2 : "Stai creando la versione {n} del tuo servizio",
    CLICCAILPULSANTE : "Clicca il pulsante se vuoi votare questo servizio",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the service does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the service because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Grazie per aver scelto questo servizio!",
    GRAZIESOSTEGNO : "Grazie per aver sostenuto questo servizio!",
    GRAZIESOSTEGNOANCORA : "Grazie per aver continuato a sostenere questo servizio!",
    IDEAVOTATA : "servizio votato",
    INSERISCICIFRA : "Inserisci la cifra che vuoi donare per questo servizio",
    NARRAZIONE : "Il servizio",
    PREFERENZEPERPREMIARE : "preferenze per premiare i servizi migliori!",
    SOSTIENIALTREIDEE : "Puoi continuare a donare anche per altri servizi, scegliendo come distribuire il tuo budget.",
    TOGLIEREPREFERENZA : "Se hai cambiato idea puoi rimuovere la tua preferenza e votare altri servizi.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Valuta se ridurre le donazioni già effettuate per gli altri servizi",
    VERSIONIPROPOSTA : "Versioni del servizio:",
    WALLETSOSTIENIIDEA : "Sostieni questo servizio. Fai una donazione!"
  },
  SERVICELIST : {
    ALERTBOZZA : "Hai una versione del tuo servizio in bozza. Puoi recuperarla dal tuo",
    ALERTVERSIONING2 : "È possibile migliorare un servizio fornendo o raccogliendo spunti e descrivendolo tramite nuove versioni sempre più aggiornate",
    CREAIDEA : "Pubblica il tuo servizio",
    ELENCOIDEE : "Tutti i servizi",
    IDEAVOTATA : "Hai votato questo servizio! ",
    IDEE : "servizi",
    NONCISONOIDEE : "Non è presente ancora alcun servizio. Pubblica il tuo!",
    VOTAZIONEATTIVA2 : "Guarda tutti i servizi ed esprimi le tue preferenze",
    VOTAZIONEATTIVAUTENTEANONIMO : "Iscriviti, se sei abilitata o abilitato potrai votare i tuoi servizi preferiti"
  },
  STORYDETAIL : {
    ALERT1 : "Πρέπει να συμπληρώσετε τουλάχιστον ένα από τα υποχρεωτικά πεδία μεταξύ του status ή της βαθμολογίας που αποδίδεται στην ιστορία",
    ALERT13 : "Έχετε ήδη εισάγει μια λεπτομερή αξιολόγηση για αυτή την ιστορία",
    ALERT14 : "Η ιστορία έχει ήδη αξιολογηθεί",
    ALERT3TITLE : "Ευχαριστούμε που ψηφίσατε αυτή την ιστορία",
    ALERT4 : "Περιηγηθείτε στις ιστορίες και επιλέξτε αυτές που προτιμάτε",
    ALERT5 : "Μπορείτε να συνεχίσετε να κάνετε δωρεές σε άλλες ιστορίες, επιλέγοντας πώς θα διανέμετε το ποσό που έχετε στη διάθεσή σας.",
    ALERT5TITLE : "Ευχαριστούμε για την υποστήριξη αυτής της ιστορίας",
    ALERT6TITLE : "Ακολουθείτε αυτή την ιστορία!",
    ALERT7TITLE : "Δεν ακολουθείτε πλέον αυτή την ιστορία",
    ALERT8TITLE : "Θέλετε σίγουρα να διαγράψετε αυτή την ιστορία;",
    ALERT9 : "Η ιστορία έχει διαγραφεί",
    ALERT9TITLE : "Απομάκρυνση ιστορίας",
    ALERTBOZZA : "Έχετε μια έκδοση της ιστορίας σας σε προσχέδιο. Θυμηθείτε να το δημοσιεύσετε.",
    ALERTPUBBLICATA : "Ιστορία υπό αναθεώρηση. Βοηθήστε να βελτιωθεί.",
    AUTORE : "Written by",
    CANVERSION2 : "Δημιουργείτε την έκδοση με αριθμό {n} της ιστορίας σας",
    CLICCAILPULSANTE : "Κάντε κλικ στο κουμπί αν θέλετε να ψηφίσετε αυτήν την ιστορία.",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the story does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the story because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Ευχαριστούμε για την επιλογή αυτής της ιστορίας!",
    GRAZIESOSTEGNO : "Ευχαριστούμε για την υποστήριξη αυτής της ιστορίας!",
    GRAZIESOSTEGNOANCORA : "Ευχαριστούμε για τη στήριξή σας σε αυτή την ιστορία!",
    IDEAVOTATA : "Ιστορία που έχει ψηφιστεί",
    INSERISCICIFRA : "Εισάγετε το ποσό που θέλετε να δωρίσετε σε αυτή την ιστορία.",
    NARRAZIONE : "Η ιστορία",
    PREFERENZEPERPREMIARE : "Ψηφίστε για να επιβραβεύσετε τις καλύτερες ιστορίες!",
    SOSTIENIALTREIDEE : "Μπορείτε να συνεχίσετε να κάνετε δωρεές σε άλλες ιστορίες, επιλέγοντας πώς θα διανέμετε το υπολοιπόμενο ποσό σας.",
    TOGLIEREPREFERENZA : "Αν αλλάξατε γνώμη, μπορείτε να αποσύρετε την επιλογή σας και να ψηφίσετε άλλες ιστορίες.",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Σκεφτείτε την περίπτωση περιορισμού των δωρεών που έχουν ήδη γίνει σε άλλες ιστορίες",
    VERSIONIPROPOSTA : "Εκδόσεις της ιστορίας:",
    WALLETSOSTIENIIDEA : "Υποστηρίξτε αυτή την ιστορία. Κάντε μια δωρεά!"
  },
  STORYLIST : {
    ALERTBOZZA : "Έχετε μια έκδοση της ιστορίας σας σε προσχέδιο. Μπορείτε να την ανακτήσετε από το",
    ALERTVERSIONING2 : "Μπορείτε να ενημερώσετε τις ιστορίες με νέες εμπειρίες και αναμνήσεις",
    CREAIDEA : "Πες την ιστορία σου",
    ELENCOIDEE : "Όλες οι ιστορίες",
    IDEAVOTATA : "Ψηφίσατε αυτή την ιστορία!",
    IDEE : "Ιστορίες",
    NONCISONOIDEE : "Δεν έχει δημοσιευθεί ακόμα κάποια ιστορία. Πείτε μας τη δική σας!",
    VOTAZIONEATTIVA2 : "Δείτε όλες τις ιστορίες και διαλέξτε αυτές που προτιμάτε.",
    VOTAZIONEATTIVAUTENTEANONIMO : "Συνδεθείτε. Από τη στιγμή που θα είστε ενεργός, θα μπορείτε να ψηφίσετε τις αγαπημένες σας ιστορίες."
  },
  TACTICDETAIL : {
    ALERT1 : "Συμπλήρωσε τουλάχιστον ένα υποχρεωτικό πεδίο είτε του status είτε του σκορ που δόθηκε στη στρατηγική ",
    ALERT13 : "Έχετε ήδη εισάγει μια λεπτομερή αξιολόγησή για αυτή τη στρατηγική",
    ALERT14 : "Η στρατηγική έχει ήδη αξιολογηθεί",
    ALERT3TITLE : "Ευχαριστούμε που ψηφίσατε αυτή την στρατηγική",
    ALERT4 : "Περιηγηθείτε στις στρατηγικές και επιλέξτε αυτή που θέλετε να ψηφίσετε",
    ALERT5 : "Μπορείς να συνεχίζεις να δωρίζεις σε άλλες στρατηγικές διαλέγοντας πώς θα διαμοιράσεις τον προυπολογισμό σου",
    ALERT5TITLE : "Ευχαριστούμε που υποστήριξες αυτή την στρατηγική!",
    ALERT6TITLE : "Ακολουθείς αυτή τη στρατηγική!",
    ALERT7TITLE : "Δεν ακολουθείτε πλέον αυτή τη στρατηγική!",
    ALERT8TITLE : "Είστε σίγουρος/η ότι θέλτε να διαγραψετε αυτή τη στρατηγική?",
    ALERT9 : "Η στρατηγική ακυρώθηκε",
    ALERT9TITLE : "Ακύρωση στρατηγικής",
    ALERTBOZZA : "Έχετε ένα προσχέδιο της στρατηγικής σας. Θυμηθείτε να το δημοσιεύσετε.",
    ALERTPUBBLICATA : "Η στρατηγική είναι υπό έλεγχο. Συνεργαστείτε για την εξέλιξη.",
    AUTORE : "",
    CANVERSION2 : "Δημιουργείς τη {n} εκδοχή της στρατηγικής σου",
    CLICCAILPULSANTE : "Πάτα το κουμπί αν θα ήθελες να ψηφίσεις αυτή τη στρατηγική",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the tactic does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the tactic because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Ευχαριστούμε που διαλέξατε αυτή τη στρατηγική!",
    GRAZIESOSTEGNO : "Ευχαριστούμε που στηρίζετε αυτή τη στρατηγική!",
    GRAZIESOSTEGNOANCORA : "Ευχαριστούμε που συνεχίζετε να υποστηρίζετε αυτή την στρατηγική!",
    IDEAVOTATA : "Ψηφισμένη στρατηγική",
    INSERISCICIFRA : "Παρακαλώ εισάγετε το ποσό που θα θέλατε να δωρίσετε σε αυτή τη στρατηγική",
    NARRAZIONE : "Η στρατηγική",
    PREFERENZEPERPREMIARE : "Προτιμήσεις για να επιβραβεύσετε τις καλύτερες στρατηγικές!",
    SOSTIENIALTREIDEE : "Μπορείτε να συνεχίσετε να δωρίζετε σε άλλες στρατηγικές διαλέγοντας πώς θα διαμοιράσετε τον προοϋπολογισμό σας",
    TOGLIEREPREFERENZA : "Αν αλλάξατε γνώμη μπορείτε να αφαιρέσετε την προτίμησή σας και να τη μεταθέσετε σε άλλη στρατηγική",
    URLSITO : "Sito web",
    VALUTARIDUZIONEDONAZIONI : "Σκεφτείτε να μειώσετε τις δωρέες που έχετε ήδη κάνει σε άλλες στρατηγικές",
    VERSIONIPROPOSTA : "Εκδοχές της στρατηγικής:",
    WALLETSOSTIENIIDEA : "Υποστήριξε την στρατηγική. Κάνε μια δωρεά!"
  },
  TACTICLIST : {
    ALERTBOZZA : "Έχεις ένα προσχέδιο της στρατηγικής. Μπορείς να τη βρεις στο",
    ALERTVERSIONING2 : "Μπορείς να βελτιώσεις την στρατηγική με το να παρέχεις ή να συλλέγεις ιδέες και να δημοσιεύεις ανανεωμένες εκδοχές",
    CREAIDEA : "Γράψε μία στρατηγική",
    ELENCOIDEE : "Όλες οι στρατηγικές",
    IDEAVOTATA : "Ψήφισες για αυτή τη στρατηγική!",
    IDEE : "Στρατηγικές",
    NONCISONOIDEE : "Δεν υπάρχουν ακόμα στρατηγικές, γίνε ο πρώτος που θα δημοσιεύσεις!",
    VOTAZIONEATTIVA2 : "Περιηγήσου στις στρατηγικές και ψήφισε την αγαπημένη σου",
    VOTAZIONEATTIVAUTENTEANONIMO : "Κάνε εγγραφή, αν είσαι επιλέξιμος/η για να ψηφίσεις, θα μπορείς και να ψηφίσεις την αγαπημένη σου στρατηγική"
  },
  TERMS : {
    ATTIVITA : "3. ATTIVITA’",
    ATTIVITADESCR1 : "Oggetto delle attività dell'Utente è qualsiasi iniziativa volta a contribuire alla vita, allo sviluppo e al benessere della propria città o ente di appartenenza;\nA titolo esemplificativo, le attività possono riguardare: l’inserimento di idee e segnalazioni, la discussione, la progettazione, la condivisione di idee, di link e di documenti, la votazione di idee e progetti e temi pubblici, eccetera.\nL’attività svolta deve attenersi ad argomenti di pubblica rilevanza e svolgersi nel pieno rispetto della normativa vigente.\nL'Utente dichiara di essere titolare di ogni diritto eventualmente connesso alla propria attività (a titolo meramente esemplificativo e non esaustivo: idee, progetti, fotografie, filmati audio/ video etc.).\nBiPart si riserva il diritto di eliminare dalla Piattaforma BiPart in maniera insindacabile qualsiasi elemento introdotto dall’Utente, come pure modificare o oscurare elementi non essenziali dello stesso qualora essa possa ledere il diritto di soggetti terzi o di una collettività di persone.\nL'Utente registrato accetta di partecipare anche alla eventuale realizzazione di classifiche inerenti alle attività, nel pieno rispetto della normativa sulla privacy (a titolo meramente esemplificativo e non esaustivo: classifiche per numero, tipologia e qualità delle proposte, commenti, etc.).\nL'Utente registrato accetta che le proprie attività possano essere pubblicate da BiPart anche su altri siti web non necessariamente di proprietà di BiPart (a titolo esemplificativo non esaustivo: Facebook, Twitter etc.) ma sempre e solo per le finalità legate al progetto culturale alla base della “Piattaforma BiPart”.",
    COMUNICAZIONI : "7. COMUNICAZIONI",
    COMUNICAZIONIDESCR1 : "L'Utente prende atto ed accetta che tutte le eventuali comunicazioni, notificazioni, informazioni e comunque ogni documentazione relativa alle operazioni eseguite e riferite all'utilizzo della “Piattaforma BiPart” verranno inviate all’indirizzo di posta elettronica ovvero agli eventuali ulteriori recapiti indicati dall'Utente durante la procedura di registrazione.",
    LIMITAZIONI : "6. LIMITAZIONI DI RESPONSABILITA'",
    LIMITAZIONIDESCR1 : "BiPart non risponde dei danni diretti, indiretti o consequenziali subiti dall'Utente o da terzi in dipendenza delle attività svolte all’interno della “Piattaforma BiPart” e/o per l'utilizzo dei Servizi di Comunicazione e/o per danni di qualsiasi genere o a qualsiasi titolo connessi con dette attività e a tal fine l'Utente dichiara di manlevare BiPart da ogni forma di responsabilità. Il materiale inviato dall'Utente non sarà più restituito.",
    MODIFICA : "8. MODIFICA DELLE CLAUSOLE DI UTILIZZO",
    MODIFICADESCR1 : "BiPart si riserva il diritto di modificare i termini, le condizioni, e le comunicazioni ai sensi dei quali viene offerta la “Piattaforma BiPart” ed a cui saranno tenuti a conformarsi anche gli Utenti già registrati.",
    OGGETTO : "1. OGGETTO E DEFINIZIONI",
    OGGETTODESCR1 : "Le presenti Condizioni d'Uso (CdU) regolano e disciplinano l'utilizzo della Piattaforma BiPart da parte dell'Utente nonché le responsabilità di quest'ultimo relativamente all'utilizzo della piattaforma anzidetta.Per BiPart si intende una piattaforma tramite la quale l'Utente può partecipare alle attività rese possibili dalla piattaforma stessa (segnalazioni, proposte, priorità, voti, commenti, condivisione, eccetera). Detta piattaforma è di esclusiva proprietà di BiPart Impresa sociale srl (già BiPart Srls) (BiPart) con sede in Milano, Via Metauro, 4 (codice fiscale e partita IVA: 09444470968).\nPer Utente si intende la persona fisica registrata ai sensi dell'art. 2 che interagisce con gli strumenti partecipativi messi a disposizione sulla Piattaforma BiPart.<br>Le presenti clausole di utilizzo si applicano ad ogni tipo di attività svolta dall’Utente.",
    REGISTRAZIONE : "2. REGISTRAZIONE",
    REGISTRAZIONEDESCR1 : "Per poter partecipare secondo quanto indicato all'articolo precedente, l'Utente deve necessariamente registrarsi tramite l'apposita procedura di registrazione;\nL'Utente si assume ogni responsabilità civile e penale per l’eventuale falsità o non correttezza delle informazioni e dei dati comunicati.",
    RESPONSABILITA : "4. RESPONSABILITA' DELL'UTENTE",
    RESPONSABILITADESCR1 : "L'Utente si assume ogni responsabilità nonché ogni conseguenza diretta o indiretta derivante dalle attività svolte e pubblicate sulla piattaforma web, nonché riconosce la propria esclusiva responsabilità per eventuali danni cagionati a terzi (a titolo meramente esemplificativo e non esaustivo, per la violazione di diritti d’autore o altri diritti di proprietà, diritti relativi alla riservatezza delle persone, etc.).\nL'Utente si impegna non inserire materiale o estratti di materiale:",
    RESPONSABILITADESCR2 : "<li>coperti da diritto d'autore e di cui non sia esso stesso titolare;</li><li>contrari alla morale e l'ordine pubblico, ovvero in grado di turbare la quiete pubblica o privata o di recare offesa, o danno diretto o indiretto a chiunque o ad una specifica categoria di persone (per esempio è vietato l’inserimento di materiali o estratti di materiale che possano ledere la sensibilità di gruppi etnici o religiosi, etc.);</li><li>contrario al diritto alla riservatezza di soggetti terzi; • lesive dell'onore, del decoro o della reputazione di soggetti terzi; \n</li><li> comunque contrari alla legge.</li>",
    SOTTOTITOLO : "Condizioni d'uso della piattaforma",
    TITOLO : "Termini e condizioni",
    UTILIZZO : "UTILIZZO",
    UTILIZZODESCR1 : "La “Piattaforma BiPart” potrà contenere ulteriori servizi di comunicazione quali bacheche elettroniche, aree chat, newsgroup, pagine web personali, calendari, e/o altri servizi creati per abilitare l’utente a comunicare con altri soggetti o con un gruppo (collettivamente, \"Servizi di Comunicazione\"). L’Utente accetta di usare i Servizi di Comunicazione solamente per spedire, inviare e ricevere messaggi e materiale appropriato e relativo al particolare Servizio di Comunicazione. A titolo di esempio, e non come limitazione, durante l’uso di un Servizio di Comunicazione, l’utente accetta di, e si impegna a, non:",
    UTILIZZODESCR2 : "<li>pubblicare, inviare, distribuire o disseminare nomi, materiali o informazioni non appropriati, profanatori, diffamatori, che violino i diritti altrui, osceni, indecenti o illegali;</li><li>pubblicare file che contengono software o altro materiale protetto da leggi di proprietà intellettuale (o dai diritti di privacy di pubblicità) a meno che l’utente sia titolare di tali diritti o ne possieda il controllo o abbia ricevuto tutti i consensi necessari;</li><li>pubblicare file che contengono virus, file danneggiati, o altri software o programmi simili che potrebbero danneggiare l’operatività dei computer di altri;</li><li>pubblicizzare o offrire per la vendita o comprare qualsiasi prodotto o servizio per qualunque scopo commerciale, salvo il caso in cui tale Servizio di Comunicazione permetta specificamente tali messaggi; </li><li>condurre o inoltrare sondaggi, contenuti, diagrammi a piramide o lettere a catena;</li><li>scaricare qualunque file inviato da un altro utente di un Servizio di Comunicazione che l’utente sappia, o ragionevolmente dovrebbe sapere, che non può essere legalmente distribuito in tale maniera;</li><li>falsificare o cancellare qualunque attribuzione d’autore, le comunicazioni di contenuto legale o altre comunicazioni appropriate o designazioni di proprietà o denominazioni d’origine o fonte del software o altro materiale contenuto in un file che è caricato;</li><li>limitare o inibire ad altri utenti l’utilizzo e il godimento dei Servizi di Comunicazione;</li><li>violare codici di condotta o altre modalità d’utilizzo che potrebbero essere applicabili per qualunque particolare Servizio di Comunicazione;</li><li>raccogliere o mettere insieme informazioni di altri, incluso indirizzi e-mail, senza il loro consenso; </li><li>violare qualunque legge o regolamento applicabile.</li>",
    UTILIZZODESCR3 : "BiPart non ha l’obbligo di monitorare i Servizi di Comunicazione. Tuttavia, BiPart si riserva il diritto di visionare i materiali inviati tramite uno dei Servizi di Comunicazione e di togliere qualunque materiale a sua unica ed insindacabile discrezione. BiPart si riserva il diritto di sospendere l’accesso dell’utente ad uno, a più o atutti i Servizi di Comunicazione in qualunque momento senza preavviso per qualunque motivo. I materiali pubblicati su di uno dei Servizi di Comunicazione possono essere soggetti a limitazioni impostate sul loro utilizzo, riproduzione e/o disseminazione. L’Utente è responsabile per avere aderito a tali limitazioni qualora scarichi i materiali."
  },
  TRANSLATOR : {
    SELECT : "Επιλέξτε τη γλώσσα σας"
  },
  USERDETAIL : {
    BIOGRAFIA : "Βιογραφία",
    COMMUNITY : "Κοινότητα",
    EDUCAZIONE : "Εκπαίδευση",
    EDULAVORO : "Εκπαίδευση και εργασία",
    ETA : "Ηλικία",
    F : "Γυναίκα",
    LAVORO : "Εργασία",
    M : "Αρσενικό",
    PROPOSTEEFFETTUATE : "Η συνεισφορά μου",
    UTENTE : "Χρήστης"
  }
};
