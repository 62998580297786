import Vue from "vue";
import store from "@/core/services/store";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

import {ANONYMOUS_LOGIN, LOGOUT} from "@/core/services/store/auth.module";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // console.log("Variabile in ApiService: ", process.env.VUE_APP_ENDPOINTBASEURL);
    // Vue.axios.defaults.baseURL = process.env.VUE_APP_ENDPOINTBASEURL;
    Vue.axios.defaults.baseURL = Vue.prototype.$baseUrl; // "http://18.197.92.87:8080";

    // Apply interceptor on request
    // Vue.axios.interceptors.request.use(config => {
    //   console.log("Request interceptors: ", config);
    //   return config;
    // });

    // Apply interceptor on response
    Vue.axios.interceptors.response.use(
      response => {
        // Do something with response

        //#########################################################
        //
        // Aggiornamento del token
        //
        // Se la risposta contiene il token lo vado ad aggiornare
        //
        //#########################################################

        return response;
      },
      error => {
        // Do something with error
        // check for errorHandle config
        // console.log('Response interceptors: ', error);

        // if has response show the error
        if (error.response) {
          // console.log('Error: ', JSON.stringify(error.response));
          // console.log('Error message: ', error.message);
          // console.log('Error response status: ', error.response.status);
          // console.log('Error response data message: ', error.response.data.message);
          if (error.response.status === 404 || error.response.status === 400) {
            // toast.error(error.response.data.message)
          }
          if (
            error.response.status === 401 &&
            error.response.data.message.includes("JWTException")
            //error.response.data.message.includes("JWTExceptionExpired")
          ) {
            // if you ever get an unauthorized, logout the user
            store.dispatch(LOGOUT)
              // .then(() => this.$router.push({ name: "login", query: {confirmedEmail: 'true'} }));
              .then(() => {
                if (!store.state.currentOrganization.data.publiclyVisible) {
                  this.$router.push({name: "login", query: {confirmedEmail: 'true'}});
                } else {
                  let result = store.dispatch(ANONYMOUS_LOGIN);
                  console.log('AL result: ', result);
                  location.reload();
                }

                // you can also redirect to /login if needed !
                // window.location.href = "/login";
              })
          }
        }
        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      // console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch(error => {
      // console.log("in api error - " + JSON.stringify(error));
      // console.log("message - " + error.message);
      // console.log("status - " + error.response.status);

      if (error.response && error.response.status === 401) {
        // window.location.href = "login";
      } else {
        // Handle error however you want
      }

      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiService;
