import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as el } from "@/core/config/i18n/el";
import { locale as es } from "@/core/config/i18n/es";
import { locale as pl } from "@/core/config/i18n/pl";
import { locale as it } from "@/core/config/i18n/it";
import { locale as fr } from "@/core/config/i18n/fr";
import { locale as de } from "@/core/config/i18n/de";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, el, es, pl, it, fr, de };

// get current selected language
const lang = localStorage.getItem("language") || navigator.language.split("-")[0].toLowerCase();

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
