// EN
/*eslint-disable no-useless-escape*/
export const locale = {
  ADVAUTHMETHOD : {
    ACCEDICOMM1 : "ENTER",
    ACCEDICOMM2 : "Click here and log in with your account",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    REGISTRATICOMM1 : "I want to be part of the community",
    REGISTRATICOMM2 : "Click here if you would like to sign up on BiPart with your email and create an account",
    SOLOVOTO : "I just want to vote",
    SOLOVOTO2 : "Click here if you want to register only with your mobile phone or you need to recover your credentials"
  },
  ADVAUTHMOBILE : {
    ATTENZIONE : "Attention",
    INDIETRO : "Back",
    INSERISCINUOVONUMERO : "Insert<br /> a new number",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    PH1 : "Mobile",
    PHONEERROR1 : "You forgot to include the area code",
    PHONEERROR2 : "Did you forget to enter your mobile phone number?",
    PHONEERROR3 : "The number you entered is a bit odd...check it out again please! :)",
    PRIVACY : "To continue please read and agree to the <a href=\"https://bipart.it/terms\" target=\"blank\">Terms and Conditions</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    PROCEDI : "Sign up",
    RECUPERAPASSWORD : "Retrieve<br/> password",
    TERMINIECONDIZIONI : "I have read and I agree to the <a href=\"https://bipart.it/terms\" target=\"_blank\">terms and conditions</a>",
    VERIFICA1 : "Check your details to participate",
    VERIFICA2 : "Enter your mobile phone to receive the OPT code via SMS",
    EXIT: "Logout",
  },
  ADVAUTHMOBILEVERIFY : {
    ALERT1 : "<h4>We have sent an SMS verification code to your mobile phone.</h4>.",
    ALERT1TITLE : "Info",
    ERROROTPMAXCHAR : "Maximum number of digits allowed: 6",
    INDIETRO : "Back",
    OTPDIMENTICATO : "You forgot to enter the otp code",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    PH1 : "OTP Code*",
    PROCEDI : "Proceed",
    RICHIEDINUOVOCODICE1 : "<b>Did you not receive the code?</b> <br>Go back and make sure you have correctly entered your mobile number. Otherwise,",
    RICHIEDINUOVOCODICE2 : "request a new code",
    RICHIEDINUOVOCODICE3 : "or write to",
    VERIFICA1 : "Check your details to participate",
    VERIFICA2 : "We have sent the OTP verification code to your mobile number. Please enter it to continue"
  },
  ADVAUTHROLE : {
    CITTADINO : "Citizen",
    INDIETRO : "Back",
    INFOREGISTER : "In addition to your <b>cell phone</b>, where you will receive your password to log in, we will ask you to enter your <b>tax code</b>.",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    PROCEDI : "Next",
    VERIFICA1 : "Check your details to participate",
    VERIFICA2 : "Choose how to authenticate"
  },
  ADVAUTHTAXCODE : {
    ALERT1 : "Oops... something went wrong; Stauts code:",
    ALERT1TITLE : "Error",
    CFDIVERSO : "Did you enter a different tax code than the one already associated with your account?",
    CHIUDI : "Close",
    DIMENTICATOCF : "You forgot to enter your tax code",
    ERRORECF : "The tax code is not entered correctly.",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    PH1 : "Tax code*",
    PROCEDI : "Next",
    VERIFICA1 : "Check your details to participate",
    VERIFICA2 : "<b>Enter your TAX NUMBER to continue</b><br>we will ensure that only members of the community participate!"
  },
  ADVAUTHTAXCODENOTVALID : {
    CFNONABILIATO : "Your tax code is not allowed to participate in the process",
    CFNONABILIATO1 : "Check the guidelines and if you think it's a mistake write to support@bipart.it",
    CHIUDI : "Close",
    UTENTENONAUTORIZZATO : "Unauthorized user"
  },
  ADVAUTHTAXCODEVERIFIED : {
    ADESSOPUOIVOTARE : "Thank you for signing up on BiPart.",
    CHIUDI : "Close",
    COMPLIMENTI : "Congratulations!",
    UTENTEABILITATO : "Keep with you the credentials sent to your mobile via SMS to access the platform again.",
    UTENTEINFO : "From now on, you can participate with a verified account enabled for the use of more advanced and important features!"
  },
  ADVLOGINONPUBLIC : {
    ALERT : "Attention",
    ALERTINFO : "The credentials you entered are wrong. Please check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    PARTECIPA : "Take part in the process",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    PASSWORDERRATA : "Incorrect password",
    PH1 : "username*",
    PH2 : "password*",
    SUPPORT : "<i class=\"fas fa-info-circle\"></i> If you cannot remember your credentials, please write to <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    USERNONESISTE : "The username does not exist"
  },
  ADVREGISTERONPUBLIC : {
    ALERT1 : "Incorrect credentials",
    ALERT1TITLE : "Attention",
    CAMPIOBBLIGATORI : "* Marked fields are required",
    COGNOMEDIMENTICATO : "You forgot to enter your last name",
    INDIETRO : "BACK",
    MAILDIMENTICATA : "You forgot to enter your email",
    MAILNONVALIDA : "Invalid email address",
    NEWSLETTER : "Keep me updated on BiPart's initiatives",
    NOMEDIMENTICATO : "You forgot to enter your name",
    PARTECIPA : "Take part in the process",
    PARTECIPAPROCESSO : "You're in the workspace of the following organization:",
    PASSWORDDIMENTICATA : "You forgot to enter your password",
    PH1 : "Name*",
    PH2 : "Last name*",
    PH3 : "Username*",
    PH4 : "Email*",
    PH5 : "Password*",
    PRIVACY : "I have read and I agree to the <a href=\"https://bipart.it/privacy\" target=\"_blank\"> privacy policy</a>",
    REGISTRA : "SIGN UP",
    TERMINIECONDIZIONI : "I have read and I agree to the <a href=\"https://bipart.it/terms\" target=\"_blank\">terms and conditions</a>",
    USERNAMEDIMENTICATO : "You forgot to enter your username"
  },
  ALERT : {
    ALERTCANNOTFOLLOW : "<h4>The proposal cannot be followed</h4>",
    ALERTCOMMENTEMPTY : "<h4>Inser comment text</h4>",
    ALERTCOMMENTSENDED : "<h4>Thank you for entering your comment and contributing to the proposal!</h4>",
    ALERTEMAILBLANK : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't your email address yet</h6><div>",
    ALERTEMAILCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your email address yet</h6><div>Confirm it to join the community and sign up for participatory processes</div><div>You didn't receive the email? <br><strong>Click on the button in the red band to resend it</strong></div>.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONDESCRIPTION : "Create and publish your profile to actively participate in the processes of your community.",
    ALERTEMAILCONFIRMEDREGISTERTOORGANIZATIONTITLE : "Welcome to BiPart!",
    ALERTEMAILMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the community and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    ALERTEMAILRESENDCONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Confirmation request email sent back to your inbox</h5><div>Confirm it to join the organization<br>and subscribe to the participatory processes</div>.",
    ALERTERROR : "Ops... something went wrong!",
    ALERTFORMDESCRIPTION : "<h4>Awaiting activation</h4>.",
    ALERTFORMTITLE : "<b>Questionnaire not yet available</b>",
    ALERTHELPDESCRIPTION : "<h4>If you need help, please contact us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a></h4>.",
    ALERTHELPTITLE : "<b>Helpdesk</b>",
    ALERTJAMBOARDDESCRIPTION : "<h4>Awaiting activation</h4>.",
    ALERTJAMBOARDTITLE : "<b>Jamboard not yet available</b>",
    ALERTMEETDESCRIPTION : "<h4>Awaiting activation</h4>.",
    ALERTMEETTITLE : "<b>Webinar not yet available</b>",
    ALERTMOBILECONFIRM : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-50px\" alt=\"Logo\" /></div><br /><h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the community and sign up for participatory processes</div><div>You didn't receive the message? <br><strong>Click on the button in the red band to resend it</strong></div>",
    ALERTPERMISSIONDENIED : "<h4>Permission denied</h4>",
    ALERTUSERMODULENOTENABLED : "<h4>Hello! You may not have the necessary permissions to participate. Read the rules or write to <a href=\"mailto:support@bipart.it\">support@bipart.it</a> or other available contacts.</h4>.",
    ALERTUSERMODULENOTENABLEDBITMASK : "click <a href=\"/process-guidelines/{process_id}\" target=\"_blank\">here</a> to find out how you can participate, or",
    ALERTUSERMODULENOTENABLEDBITMASKCOMMENT : "<h4>Ops,<br>apparently you don't have permission to leave a comment;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKCREATE : "<h4>Ops,<br>apparently you don't have permission to make {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKDELETE : "<h4>Ops,<br>apparently you don't have permission to delete {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKFOLLOW : "<h4>Ops,<br>apparently do not follow this proposal;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKMESSAGE : "<h4>Ops,<br>apparently you don't have permission to send a message;<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKUPDATE : "<h4>Ops,<br>apparently you don't have permission to edit {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERMODULENOTENABLEDBITMASKVERSION : "<h4>Ops,<br>apparently you don't have permission to version {type};<br>{exist_guidelines}write to us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> for more information.</h4>",
    ALERTUSERREGISTEREDTOORGANIZATION : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Thank you for joining our community</h5><div>We have sent an email to your inbox.<br>Confirm it to join the organization</div><div>Warning! Also check your spam folder.</div>.",
    ALERTUSERREGISTEREDTOPROCESS : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Thank you for joining our community</h5><div>We have sent an email to your inbox.<br>Confirm it to join the organization</div><div>Warning! Also check your spam folder.</div>.",
    PROPOSAL : "a proposal",
    SERVICE : "a service",
    STORY : "a story",
    TACTICS : "a tactic"
  },
  AUTHORITIES : {
    ASSESSOR : "Assessor",
    EXPERT : "Expert"
  },
  BCPROCESSI : {
    CHALLENGE : "Topics",
    COMMUNITY : "Community",
    ELENCOPROCESSI : "Process List",
    PROCESSO : "Process",
    SCENARI : "Context"
  },
  COMMUNITY : {
    CERCA : "Search...",
    CHIPARTECIPA : "Participants",
    GRUPPI : "Groups",
    ISCRITTI : "Subscribers",
    PROFILI : "Profiles",
    SOTTOTITOLO : "Here is who published their profile: join too!",
    TITOLO : "Community"
  },
  COMMUNITYSPACE : {
    BOZZA : "Draft",
    CS : "Community Space",
    DESCRIZIONEIDEA : "Proposal description",
    IDEE : "20 proposals",
    NOMEAUTORE : "Author name",
    TITOLOIDEA : "Proposal title",
    VISUALIZZAELENCO : "View List",
    VISUALIZZAMAPPA : "View Map"
  },
  CONFIRMCOMPLETELANGUAGES : {
    ALERT1 : "Do you want to revoke the language translation:",
    ALERT1TITLE : "Attention",
    ALERT2 : "You want to confirm the completion of the language translation:",
    CANCEL : "CANCEL"
  },
  COOKIE : {
    ANONIMI : "3. Anonymous analytics cookies:",
    ANONIMIDESCR1 : "This site uses Google Analytics cookies with anonymized IP addresses lasting for one year. They are considered anonymous as they cannot be used to identify specific individuals. Specifically, the site nowhow.it uses Google Analytics (Google, Inc.), which generates cookies according to the methods outlined in the document regarding General Privacy Policies and the specific document regarding the use of information collected with Google Analytics. Google will not associate your IP address with any other data owned by Google. The terms of service that all program clients must adhere to prohibit the monitoring or collection of personal information (personal identification data, such as name, email address, billing information, or other data reasonably linked to such information) through the use of Google Analytics or the association of such information with web data analysis information. These cookies serve exclusively for statistical purposes and collect information in aggregate form only.",
    COLLEGAMENTI : "6. Useful links",
    COLLEGAMENTIDESCR1 : "For more information about cookies and their use on the internet, you may find the following links helpful:<br><br>www.allaboutcookies.org<br>www.microsoft.com/info/cookies.mspx<br>www.google.com/intl/it_it/analytics/privacyoverview.html",
    CONTENUTIESTERNI : "O4. Displaying content from external platforms",
    CONTENUTIESTERNIDESCR1 : "These services allow the display of content hosted on external platforms directly from the pages of this Application and interaction with them. In case these services are installed, even if Users do not use the service, it may still collect traffic data related to the pages where it is installed. For more information on the logic and methods of processing data collected by social networks, you can read the privacy disclosures provided by the entities that provide the services:<br><br>Youtube Video Widget (Google Inc.) - Terms - Privacy<br>Facebook - Terms and Conditions - Privacy<br>Twitter - Terms and Conditions - Privacy<br>Vimeo - Terms and Conditions - Privacy<br>Slideshare - Terms and Conditions - Privacy<br>Linkedin - Terms and Conditions - Privacy<br>Instagram - Terms and Conditions - Privacy<br><br>Remember that you can manage your cookie preferences through the browser. If you do not know the type and version of the browser you are using, click on \"Help\" in the browser window above, where you can access all the necessary information.<br><br>If you know your browser, click on the one you are using to access the cookie management page: Google Chrome, Mozilla Firefox, Apple Safari, and Microsoft Windows Explorer.",
    COSASONO : "1. What are cookies",
    COSASONODESCR1 : "Cookies are small text files that websites visited by users send to their terminals, where they are stored to be transmitted to the same sites on subsequent visits. Cookies are used for various purposes, have different characteristics, and can be used by both the owner of the site being visited and third parties. The Site may contain links to other websites that have their own privacy policy, which may differ from that adopted by nowhow.it and is not responsible for these sites. Below, you will find all the information about the cookies installed through this site, and the necessary indications on how to manage your preferences regarding them.",
    DATIRACCOLTI : "O4. Personal data collected: Cookies and Usage Data.O",
    DATIRACCOLTIDESCR1 : "All technical cookies do not require consent, so they are automatically installed following access to the site.",
    SOTTOTITOLO : "Cookie management",
    TITOLO : "Cookie Policy",
    UTILIZZATI : "2. Cookies used by this site",
    UTILIZZATIDESCR1 : "Cookies related to activities strictly necessary for the operation of the site and the provision of the service. This site uses the PHPSESSIONID session cookie generated by applications based on the PHP language. This identifier is used to maintain user session variables."
  },
  DRIVERS : {
    ACTIVEPROCESSES : "In progress",
    CHALLENGE : "Topics",
    CLOSEPROCESSES : "Concluded",
    CLUSTER : "Cluster",
    DESCRIZIONE : "Description",
    ELENCOCHALLENGE : "Topics",
    LINK : "Link",
    PROCESSES : "Processes",
    TUTTITEMI : "All participation topics divided into clusters"
  },
  EMAILCONFIRM : {
    AUTOK : "Successful authentication",
    AUTOKDETAIL : "Now you can login and participate in our community",
    BLOGIN : "Log in",
    TITOLO : "BiPart"
  },
  FAQCUSTOM : {
    DESCRIZIONE : "To actively contribute to improving the life of your municipality. You can do so by proposing an idea of your own or by simply voting for the one you find most interesting. The proposed ideas must be concrete",
    DETTAGLIOGLOSSARIO : "\n",
    FAQ : "FAQ",
    FAQ1 : "",
    FAQ10 : "",
    FAQ11 : "",
    FAQ12 : "",
    FAQ13 : "",
    FAQ14 : "",
    FAQ15 : "",
    FAQ16 : "",
    FAQ17 : "",
    FAQ18 : "",
    FAQ19 : "",
    FAQ2 : "",
    FAQ20 : "",
    FAQ21 : "",
    FAQ22 : "",
    FAQ23 : "",
    FAQ24 : "",
    FAQ25 : "",
    FAQ3 : "",
    FAQ4 : "",
    FAQ5 : "",
    FAQ6 : "",
    FAQ7 : "",
    FAQ8 : "",
    FAQ9 : "",
    GLOSSARIO : "Glossary",
    PERCHEPARTECIPARE : "Why join participatory budgeting?",
    QUALITEMIPROGETTI : "",
    R1 : "",
    R10 : "",
    R11 : "",
    R12 : "",
    R13 : "",
    R14 : "",
    R15 : "",
    R16 : "",
    R17 : "",
    R18 : "",
    R19 : "",
    R2 : "",
    R20 : "",
    R21 : "",
    R22 : "",
    R23 : "",
    R24 : "",
    R25 : "",
    R3 : "",
    R4 : "",
    R5 : "",
    R6 : "",
    R7 : "",
    R8 : "",
    R9 : "",
    TEMI : ""
  },
  GENERALLABEL : {
    AL : "to the",
    ALTERMINEDELLAFASE : "at the end of the phase",
    DAL : "from",
    FASE : "phase",
    MANCANOANCORA : "Still lacking:",
    TERMINAIL : "Ends on"
  },
  GENERALMODAL : {
    ACCETTOPROCESSOLINEEGUIDA1 : "I have read and I agree to",
    ACCETTOPROCESSOLINEEGUIDA2 : "the guidelines and the rules of the participatory process"
  },
  GROUPDETAIL : {
    ELENCOGRUPPI : "Groups List",
    F : "Female",
    GRUPPO : "Group",
    M : "Male",
    MEMBRI : "Profiles",
    PUBBLICA : "Post your profile and introduce yourself to the community!"
  },
  GROUPLIST : {
    COMMUNITY : "Community",
    DDC : "Date of creation",
    DUE : "Two",
    GRUPPI : "Groups",
    TRE : "Three",
    UNO : "One"
  },
  HOME : {
    ALERT1 : "<h4>Check out the <a href=\"/how-it-works\">how it works</a> section to learn what participatory budgeting is</h4>.",
    ALERT1TITLE : "<b>Before we begin...</b>.",
    ALERT2 : "<h4>Something is wrong. Are you having some difficulties or do you just want to say hello? :) Write us now at <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ASSISTENZA : "Support",
    DATIGRAFICI : "Organization data",
    GETSTARTED : "Get Started",
    GRUPPICOM : "Groups and Communities",
    OBIETTIVI : "Topics",
    ORGANIZZAZIONE : "Organization",
    ORGANIZZAZIONI : "Organizations",
    PROCESSIATTIVI : "Active processes",
    PROFILO : "Profile",
    PROFILODETT : "Here you can find your activities and manage your profile",
    SCENARI : "Context",
    UTENTIREG : "Registered Users",
    VOTANTI : "Voters",
    VOTIRACCOLTI : "Votes collected"
  },
  IDEADETAIL : {
    ALERT1 : "Fill in at least one of the mandatory field between the status or the score assigned to the proposal.",
    ALERT10TITLE : "Do not delete",
    ALERT11 : "Once it's deleted, you will not be able to recover it",
    ALERT11TITLE : "Are you sure you want to delete this comment?",
    ALERT12TITLE : "The comment could not be deleted",
    ALERT13 : "You have already entered a detailed evaluation for this proposal",
    ALERT13TITLE : "Attention",
    ALERT14 : "The proposal has already been evaluated",
    ALERT14TITLE : "Attention",
    ALERT15 : "Once published, you will not be able to edit it",
    ALERT15B1 : "Confirmation",
    ALERT15B2 : "Cancel",
    ALERT15TITLE : "Are you sure you want to put in this evaluation?",
    ALERT16 : "The number you enter cannot contain decimals",
    ALERT16TITLE : "Attention",
    ALERT17 : "The number you enter cannot contain more than 6 digits",
    ALERT17TITLE : "Attention",
    ALERT1TITLE : "Attention",
    ALERT2 : "<h4>Is something wrong, are you having some difficulties or do you just want to say hello? :) Write us now at <a href=\"mailto:support@bipart.it\">support@bipart.it</a>!</h4>.",
    ALERT2TITLE : "<b>Helpdesk</b>",
    ALERT3 : "Click on the star <span class=\"symbol-rounded\">\n<span class=\"symbol-label font-size-h6 font-weight-bold bg-warning text-white\" style=\"width: 25px;height: 25px;\">\n<i class=\"fas fa-star text-white pr-0 pb-1\"></i>\n</span>\n</span> on the side if you want to see the summary of your votes",
    ALERT3TITLE : "Thank you for voting for this proposal",
    ALERT4 : "Browse through the proposals and choose the one you want to vote for",
    ALERT4TITLE : "You removed your preference!",
    ALERT5 : "You can continue to donate to other proposals by choosing how to allocate your budget",
    ALERT5TITLE : "Thank you for supporting the proposal!",
    ALERT6TITLE : "You're following this proposal!",
    ALERT7TITLE : "You're no longer following the proposal",
    ALERT8 : "Once it's deleted, you will not be able to recover it",
    ALERT8B1 : "Yes, delete!",
    ALERT8B2 : "No, do not delete!",
    ALERT8TITLE : "Are you sure you want to delete this proposal?",
    ALERT9 : "The proposal was cancelled",
    ALERT9TITLE : "Proposal cancellation",
    ALERTBOZZA : "You have a draft version of your proposal. Remember to publish it",
    ALERTEMPTYVOTE1USERLEFTHTML : "<h4> You have run out of the number of preferences available. <br /> Remember that you can change your choices until the voting ends. </h4>",
    ALERTEMPTYVOTE1USERLEFTTITLE : "Oops!",
    ALERTOWNERMAILEDHTML1 : "<p>We have sent your message to",
    ALERTOWNERMAILEDHTML2 : "who will be able to answer you through the contacts you entered.<br />In the meantime, contribute by participating on the platform.</p>",
    ALERTOWNERMAILEDTITLE : "MESSAGE SENT",
    ALERTPUBBLICATA : "Proposal under review. Collaborate in its development",
    APPROFONDISCI : "Read more",
    APPROVATA : "Approved",
    AUTORE : "Published by",
    BUDGET : "Estimated cost:",
    CAMPOMANCANTE : "The field \"{field}\" is missing",
    CANCELLAIDEA : "Delete",
    CANVERSION : "You still have a draft version. <br> Publish it so you can create a new one",
    CANVERSION2 : "You are creating the {n} version of your proposal",
    CANVERSIONTITLE : "Attention",
    CANVERSIONTITLE2 : "{count} is the maximum number of versions that can be published",
    CHALLENGE : "Topics",
    CIFRAALTA : "Your donation is too big",
    CIFRABASSA : "Your donation is too small",
    CIFRADELBUDGET : "You can still donate",
    CIFRAMODIFICATA : "You edited your donation",
    CIFRARIMOSSA : "You removed your donation",
    CIFRASUPERIOREALBUDGET : "You can no longer donate because the amount you entered is beyond your financial ability.",
    CLICCAILPULSANTE : "Click the button if you would like to vote for this proposal.",
    COMMENTAIDEA : "Comment",
    COMMENTI : "Comments",
    COMMENTIALERTEMAILCONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your email address yet</h6><div>Confirm it to join the organization and sign up for participatory processes</div><div>You didn't receive the email?<br><strong>Click on the button in the red band to resend it</strong></div>",
    COMMENTIALERTEMAILMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile and/or email address yet</h6><div>Confirm them to join the organization and sign up for participatory processes</div><div>You didn't receive the messages?<br><strong>Click on the button in the red band to resend them</strong></div>",
    COMMENTIALERTMOBILECONFIRM : "<h6 class=\"text-primary\">You haven't confirmed your mobile yet</h6><div>Confirm it to join the organization and sign up for participatory processes</div><div>You didn't receive the message?<br><strong>Click on the button in the red band to resend it</strong></div>",
    COMMENTICONTRIBUISCI : "Leave a comment",
    COMMENTIDISATTIVATI : "Comments are disabled",
    COMMENTIENTRA : "LOG IN",
    COMMENTIREGISTRATI : "SIGN UP",
    COMMENTIREGISTRATIALPROCESSOCONTRIBUISCI : "Sign up to leave a comment",
    COMPLETABOZZA : "Complete the draft",
    CONDIVIDI : "Share",
    COSTOIDEA : "Cost",
    CREATAIL : "Created on the",
    DESCRIZIONE : "Description",
    DONA : "DONATE",
    DONAREDA : "from a minimum of",
    DONAREFINOA : "up to a maximum of",
    DONRACCOLTE : "Collected donations",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the proposal does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the proposal because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    FOLLOWERS : "Community",
    FONDIRACCOLTI : "Funds raised",
    GRAZIESCELTAIDEA : "Thank you for choosing this proposal!",
    GRAZIESOSTEGNO : "Thank you for supporting the proposal!",
    GRAZIESOSTEGNOANCORA : "Thank you for continuing to support the proposal!",
    HAIANCORA : "You still have",
    HAIANCORA2 : "You still have",
    HASCRITTO : "wrote",
    IDEAVOTATA : "Voted proposal",
    INBOZZA : "Draft",
    INDICACIFRA : "Indicate the amount you want to donate",
    INSERISCICIFRA : "Please enter the amount you would like to donate to this proposal.",
    INSERISCICOMMENTO : "Add a comment",
    INVIA : "send",
    MACCETTODIESSERERICONTATTATO : "I agree to be contacted through the contacts I provided",
    MAPPE : "Context",
    MBCHIUDI : "Close",
    MBINVIA : "Send",
    MCONTATTAILPROPONENTE : "Contact the author",
    MEMAIL : "Your e-mail",
    MESSAGEFIELDNAME : "Message",
    METTIPREF : "Vote",
    MINSERISCIUNMESSAGGIO : "Your message",
    MMESSAGGIOOBBLIGATORIO : "The message is required",
    MMOBILE : "Your phone number",
    MNOMINATIVO : "Your name",
    MODIFICAIDEA : "Edit",
    MTIPOLOGIACOLLABORAZIONELABEL : "Help grow the proposal",
    MTIPOLOGIACOLLABORAZIONEPLACEHOLDER : "Choose the type of message",
    NARRAZIONE : "The proposal",
    NOCOMMENTI : "There are no comments",
    NOCOMMUNITY : "There are no followers",
    NONAPPROVATA : "Not approved",
    NONSEGUIRE : "Unfollow",
    NONVALUTATA : "Not evaluated",
    NUOVAVERSIONE : "Create new version",
    PH1 : "Enter score",
    PH2 : "Enter evaluation",
    PREFERENZEPERPREMIARE : "preferences to reward the best proposals!",
    PREFRACCOLTE : "Collected preferences",
    PUBBLICATAIL : "on",
    PUOIDONARE : "You can donate",
    RICHIEDIASSISTENZA : "Ask for support!",
    RICORDATI3 : "Remember, you can change your donation until the voting closes.",
    RIMUOVIPREF : "Remove preference",
    SEGUIIDEA : "Follow",
    SOSTENITORI : "Supporters",
    SOSTIENIALTREIDEE : "You can continue to donate to other proposals by choosing how to allocate the budget",
    SOTTOCATEGORIA : "Type",
    TOGLIEREPREFERENZA : "If you have changed idea, you can remove your preference and vote for other proposals.",
    TUOIVOTI : "Your votes",
    URLSITO : "Website",
    VAIALSITO : "Go to the website",
    VALUTAIDEA : "Evaluate",
    VALUTARIDUZIONEDONAZIONI : "Consider reducing donations already made for other proposals",
    VALUTAZIONEINSERITA : "Rating entered",
    VALUTAZIONETITLE1 : "Enter the final evaluation",
    VALUTAZIONETITLE2 : "Enter the detailed evaluation",
    VALUTAZIONETITLE3 : "Final assessment",
    VALUTAZIONETITLE4 : "Detailed evaluations",
    VALUTAZIONETITLE5 : "Final evaluation already inserted",
    VALUTAZIONETITLE6 : "Detailed assessment already entered",
    VALUTAZIONI : "Evaluations",
    VERSIONIPROPOSTA : "Versions of the proposal:",
    VOTAZIONECHIUSA : "The vote is closed. The results will be published soon",
    WALLETSOSTIENIIDEA : "Support the proposal. Make a donation!"
  },
  IDEALIST : {
    ALERTBOZZA : "You have a draft version of your proposal. You can find it in your",
    ALERTVERSIONING1 : "The Versioning is active!",
    ALERTVERSIONING2 : "it is possible to improve the proposals by providing or collecting ideas and publishing new and updated versions",
    ALERTVERSIONING3 : "Max n. of versions",
    ALERTVERSIONING4 : "Time limit",
    ALERTVERSIONING5 : "You can create up to {n} versions",
    ASSISTENZA : "Ask for support!",
    CLASSIFICA : "Ranking",
    CREAIDEA : "Make your proposal",
    ELENCOIDEE : "All the proposals",
    FILTRAPERCHALLENGE : "FILTER BY TOPIC",
    FILTRAPERMODULO : "FILTER BY AREAS",
    FILTRAPERSCENARIO : "FILTER BY CONTEXT",
    IDEAVOTATA : "You voted for this proposal!",
    IDEE : "proposals",
    IDEECOSTOSE : "Most expensive",
    IDEEMENOCOSTOSE : "Least expensive",
    IDEEMENORECENTI : "Less recent",
    IDEEMENOSEGUITE : "Least popular",
    IDEEPIUSEGUITE : "Most popular",
    INFORMAZIONI : "More info",
    INFORMAZIONISULLAFASE : "How to participate",
    JAMBOARD : "Join the Jamboard",
    NESSUNO : "None",
    NONCISONOIDEE : "There are no proposals yet",
    ORDINA : "SORT BY",
    PREFERENZE : "preferences",
    PS : "Personal space",
    TUOIVOTI : "Your votes",
    ULTIMEIDEE : "Latest",
    VOTANTI : "voters",
    VOTAZIONEATTIVA : "You can vote",
    VOTAZIONEATTIVA2 : "Browse through the proposals and vote for your favorite ones",
    VOTAZIONEATTIVA3 : "You can donate",
    VOTAZIONEATTIVABUDG : "by making a donation",
    VOTAZIONEATTIVAMAX : "up to",
    VOTAZIONEATTIVAMIN : "from",
    VOTAZIONEATTIVAPREF : "You can vote",
    VOTAZIONEATTIVAPREF2 : "and you have still available",
    VOTAZIONEATTIVAPREF3 : "preferences",
    VOTAZIONEATTIVAPREF4 : "preference",
    VOTAZIONEATTIVAUTENTEANONIMO : "Sign up, if you're eligible to vote, you'll be able to vote your favorite proposals",
    VOTAZIONECHIUSA : "The vote is closed. The results will be published soon",
    WEBINAR : "Join the Webinar"
  },
  IDEARESULT : {
    CLASSIFICAFINALE : "Final ranking",
    COMEFUNZIONA : "How it works",
    COSTOIDEA : "Estimated cost",
    DONATORI : "Donors",
    ELENCOPROCESSI : "Process List",
    FASE : "Phase:",
    FILTRAPERBUDGET : "Filter by budget",
    FILTRAPERPREFERENZE : "Filter by preferences",
    FINANZIATO : "Donations",
    PREFERENZE : "Preferences",
    PROCESSO : "Process",
    RISULTATI : "Results",
    RISULTATIVOTAZIONE : "Voting results",
    TUTTOSUPROCESSO : "Everything you need to know about the participatory process"
  },
  INTRO : {
    ASSISTENZA : "Support"
  },
  INTROBASE : {
    ASSISTENZA : "Support",
    COSA : "What you can do",
    PARTECIPA : "Join",
    PARTECIPADESC : "Participate in your school's process",
    SUBIPART : "on Bipart"
  },
  LAYOUT : {
    CONFIRMEMAIL1 : "Remember to confirm your email address. If you have not received any email from BiPArt",
    CONFIRMEMAIL2 : "click here",
    CONFIRMEMAIL3 : "to send it again.",
    CONFIRMMOBILE1 : "Remember to confirm your mobile. If you have not received any SMS from BiPArt",
    CONFIRMMOBILE2 : "click here",
    CONFIRMMOBILE3 : "to send it again."
  },
  LOGGEDREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Close",
    CONFERMA : "Confirm your registration for the process",
    ERR1 : "Oops... An unexpected error has occurred.",
    ISCRIVITIPROCESSO : "Sign up for the process",
    PARTECIPAPROCESSO : "Do you want to take part in the process?",
    PROPOSTE : "To make submissions, comment, request ratings, vote you must sign up for the process.",
    TITLE1 : "Error"
  },
  LOGIN : {
    ACCEDI : "Log in",
    ACCETTO1 : "I have read and agreed to the",
    ACCETTO2 : "I have read and agreed to the",
    BACKPORTAL : "Back to portal",
    CAMBIAPASS : "Change password",
    INSERISCIMAIL : "Enter your email",
    MEX1 : "Registration and acceptance of the\"Privacy Policy\" and of the \"Terms and Conditions\"",
    MEX2 : "The credentials you entered are wrong. Please check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Successful authentication",
    MEX4 : "An error has occurred",
    MEX5 : "Invalid email",
    MEXLOGIN : "<b>Welcome on BiPart,</b><br>The platform of civic participation.<br>Your data will be treated in full respect of Italian and European regulations and with even more care to protect your opinions and your decisions.<br><b>If you want to continue, read and accept the two documents below.</b>",
    NONRICORDIPASS : "Can't remember your password?",
    NOTIFICHE : "*If you would like to receive notifications on the progress of submitted proposals.",
    PASSWORDERRATA : "Incorrect password",
    PRIVACY : "Privacy Policy",
    PROSEGUI : "Continue",
    TERMINI : "Terms and conditions of service",
    TERMINICONDIZIONI : "To continue please read and agree to the <a href=\"https://bipart.it/terms\" target=\"blank\">Terms and Conditions</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Congratulations",
    TITLE2 : "Attention",
    TITLE3 : "Congratulations",
    TITLE4 : "Error",
    TITLE5 : "Error",
    USERNAMENONESISTE : "The username does not exist",
    UTENTEAUTENTICATO : "Authenticated user"
  },
  LOGINFROMANONYMOUS : {
    ACCEDI : "Log in",
    ACCETTO : "I have read and agreed to the",
    ACCETTOLA : "I have read and agreed to the",
    BPROSEGUI : "Continue",
    CAMBIAPASS : "Change password",
    INSERISCIMAIL : "Enter your email",
    MEX1 : "Registration and acceptance of the \"Privacy Policy\" and of the \"Terms and conditions\"",
    MEX2 : "The credentials you entered are wrong. Please check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Successful authentication",
    MEX4 : "An error has occurred",
    MEX5 : "Invalid mail",
    MEXLOGIN : "<b>Welcome on BiPart,</b><br>The platform of civic participation.<br>Your data will be treated in full respect of Italian and European regulations and with even more care to protect your opinions and your decisions.<br><b>If you want to continue, read and accept the two documents below.</b>",
    NIENTEACCOUNT : "Don't have an account yet?",
    NONRICORDIPASS : "Can't remember your password?",
    NOTIFICHE : "*If you would like to receive notifications on the progress of submitted proposals.",
    PRIVACY : "Privacy Policy",
    REGISTRATI : "Sign up",
    TERMINI : "Terms and conditions of service",
    TERMINIECONDIZIONI : "To continue please read and agree to the <a href=\"https://bipart.it/terms\" target=\"blank\">Terms and Conditions</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Congratulations",
    TITLE2 : "Attention",
    TITLE3 : "Congratulations",
    TITLE4 : "Error",
    TITLE5 : "Error",
    TITOLO : "Bipart",
    UTENTEAUTENTICATO : "Authenticated user"
  },
  LOGINREGISTERPROCESSONPUBLIC : {
    CHIUDI : "Close",
    CONFERMA : "Confirmation",
    ISCRIVITIPROCESSO : "Log in with your credentials and participate in the process",
    MEX1 : "The credentials you entered are wrong. Please check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX2 : "The credentials you entered are wrong. Please check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Oops... An error has occurred.",
    NONFAIPARTE : "Not registered yet?",
    NUOVOACCOUNT : "Create an account to participate",
    ORGANIZATION : "Welcome to Bipart",
    PASSWORDERRATA : "Incorrect password",
    REGISTRATI : "SIGN UP",
    TITLE2 : "Attention",
    TITLE3 : "Error",
    USERNAMENONESISTE : "The username does not exist"
  },
  MAINMENU : {
    ABOUT : "About",
    ACCESSIBILITY : "Accessibility",
    COMMUNITY : "Community",
    COOKIE : "Cookie",
    DEMO : "Create community space",
    DS : "Deliberative space",
    EXPLORE : "Explore",
    FAQ : "FAQ",
    FEATURE : "Tutorial & Guidelines",
    GIOCHI : "Educational Games",
    HOME : "Home",
    HOWITWORKS : "How it works",
    HOWTOJOIN : "How to join",
    JOINCOMMUNITY : "Community Space",
    LISTCHALLENGES : "Topics",
    LISTGROUPS : "List of groups",
    LISTPROCESSES : "Processes",
    LISTSCENARIOS : "Context",
    LOGIN : "Login",
    LOGOUT : "Logout",
    PORTAL : "Portal",
    PRIVACY : "Privacy",
    PROJECT : "Project",
    PROJECTS : "Case Studies",
    PS : "Personal space",
    PSACTIVITIES : "Personal Activities",
    PSPROFILE : "Personal Profile",
    PSSETTINGS : "Setting",
    REGISTER : "Sign up",
    SERVICES : "What we offer",
    TERMS : "Terms and conditions",
    WORKSPACE : "Workspace"
  },
  MANDATORYFLAGS : {
    ACCETTO : "I have read and agreed to the",
    ACCETTOLA : "I have read and agreed to the",
    BPROSEGUI : "Continue",
    DETTWELCOME : "Your data will be processed in full compliance with Italian and European regulations and with even greater care to protect your opinions and decisions. If you want to continue read and accept the two documents below.",
    INSERISCIMAIL : "Enter your email",
    MEX2 : "The credentials you entered are wrong. Please check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    MEX3 : "Successful authentication",
    MEX5 : "The email you entered is not valid",
    NOTIFICHE : "*If you would like to receive notifications on the progress of submitted proposals.",
    PRIVACY : "Privacy Policy",
    TERMINIECONDIZIONI : "To continue please read and agree to the <a href=\"https://bipart.it/terms\" target=\"blank\">Terms and Conditions</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Congratulations",
    TITLE2 : "Attention",
    TITLE3 : "Congratulations",
    TITOLO : "Bipart",
    TITOLOWELCOME : "Welcome to BiPart, the platform for civic participation.",
    UTENTEAUTENTICATO : "Authenticated user"
  },
  MESSAGEAUTH : {
    0 : "A generic error has occurred",
    1 : "The credentials entered are wrong. Check or write us at <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>",
    100 : "The specified \"organizationDomain\" attribute does not match any Organization.",
    105 : "The \"processId\" attribute is missing.",
    110 : "The specified \"processId\" attribute does not match any Process",
    115 : "The Process does not belong to this Community",
    120 : "Hello!\nThe process is private: to participate you must request an access code.",
    125 : "The specified \"groupId\" attribute does not match any Group.",
    130 : "Oops!\nThis Group is private and it is not possible to enter.",
    135 : "The requested Group does not belong to the chosen Process.",
    140 : "Hello!\nTo participate you must indicate which Group you belong to or want to join.\nSelect it from the drop-down menu, please. Thank you!",
    145 : "Oops!\n It is not possible to sign up for this process. For more information write to us at support@bipart.it",
    150 : "Oops!\nYou have forgotten some important data for registration. Complete the fields highlighted in red and that's it.",
    155 : "Oops!\nThe username you entered is already in use: maybe you've already signed up and don't remember.\nTry logging in or enter a different one.\nThank you!",
    160 : "Oops!\nYour User is already registered on the platform but not yet associated with this Process.\nUse the \"Login\" button and enter your credentials to complete the association!",
    165 : "Oops!\nThe username you entered is already in use: maybe you've already signed up and don't remember.\nTry logging in or enter a different one. Thank you!",
    170 : "Pity! Someone was faster than you and has already chosen this username. :( Try another even cuter one!",
    172 : "The email that you inserted is already used.\nPlease check if you have already an account in BiPart, otherwise report the problem to support@bipart.it",
    175 : "Oops!\nTo proceed with the registration you must read and let us know that you agree with our privacy policy and with the terms and conditions of service!",
    180 : "Bye!\nThe Organization is private: to participate you must request an access code.",
    182 : "The requested Group does not belong to the chosen Organization.",
    185 : "Hello!\nTo participate you must indicate which Group you belong to or want to join.\nSelect it from the drop-down menu, please. Thank you!",
    187 : "Oops!\nThe chosen organization contains only private groups, to which it is not possible to register.",
    190 : "The username you entered is already in use: maybe you've already signed up and don't remember.\nTry logging in or enter a different one.\nThank you!",
    192 : "Oops!\nYour User is already registered on the platform but not yet associated with this Organization.\nUse the \"Login\" button and enter your credentials to complete the association!",
    2 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">Thank you for subscribing to<br>{value}</h5>",
    200 : "Oops!\nYou let too much time go by!\nIn order for us to verify your e-mail address, you must request the sending of a new message and click as soon as possible on the confirmation button contained in the message itself.",
    205 : "Oops!\nThe operation was not successful: try again!",
    210 : "Oops!\nYour account does not correspond to any User present in our archives!",
    215 : "Oops!\nWe have not received any registration requests corresponding to your e-mail address.\nAre you sure you have completed the relevant procedure?\nPlease try to register again.\nThank you!",
    220 : "Oops!\nThe email address you are trying to confirm is already verified.\nTry to login.\nThank you!",
    225 : "Oops!\nThe operation was not successful: try again!",
    3 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><h5 class=\"text-primary\">You are participating in the process<br>{value}</h5>",
    300 : "Oops!\nYour User is not yet registered on the platform.\nAre you sure you have completed the relevant procedure?\nPlease try to register again.\nThank you!",
    305 : "Oops!\nYour User is already registered on the platform, but not yet associated with this Organization.\nContact us to request access.",
    310 : "Oops!\nThe username you entered is already in use: maybe you've already signed up and don't remember.\nTry logging in or enter a different one.\nThank you!",
    4 : "<div><img src=\"/assets/media/bipart/logo-bipart-brand.svg\" class=\"h-60px\" alt=\"Logo\" /></div><br /><div>Welcome to {organization_name} </div><h5 class=\"text-primary\">We confirm your subscription to {process_name} </h5><div>From now on you are part of the community and you can operate in the processes you are subscribed to.</div>",
    400 : "The \"mobile\" attribute is missing.",
    405 : "Oops!\nThe mobile number you entered is already in use: maybe you have already registered and don't remember.\nTry logging in or enter a different one.\nThank you!",
    410 : "Oops!\nThe mobile number you entered is already in use: maybe you have already registered and don't remember.\nTry logging in or enter a different one.\nThank you!",
    420 : "Oops!\nThe telephone number you entered is already in use.\nIf you have participated other times, click on the LOGOUT button and recover your old credentials BY REGISTERING VIA MOBILE.\nThank you!",
    425 : "Ops!\nL’attributo “mobile” non compare nel database Utenti\nSei sicuro che sia il tuo? Prova a inserirne uno differente.\nGrazie!",
    500 : "Oops! \nThere is no mobile number associated with your profile. Go back and enter your mobile number",
    502 : "There was a technical problem and we have sent you a new verification code via SMS.\nPlease repeat the operation after typing it in this same panel.\nThank you!",
    510 : "Oops!\nThe verification code you entered is invalid.\nCheck it or request a new code to be sent.\nThank you!",
    515 : "Oops!\nThe verification code you entered has expired.\nPlease request a new code to be sent.\nThank you!",
    605 : "Oops!\nA mobile number is already associated with your profile.",
    700 : "The specified \"process2ModuleId\" attribute does not match any Module.",
    705 : "The Destination Module does not belong to the Target Process.",
    710 : "The tax code is not declared nor is it already attributed to the requesting AppUser.",
    715 : "Oops!\nThe tax code you entered is not valid: check it, please.\nThank you!",
    720 : "Oops!\nThe tax code you entered is already in use.\nAre you sure it's yours? Try entering a different one.\nThank you!",
    725 : "Oops!\nA tax code is already associated with your profile.",
    ATTENZIONE : "Attention"
  },
  MODULEIDEACOMMONS : {
    AGGIUNGIURL : "Insert or copy and paste the URL of the official website of the organization providing the service",
    MAXLENGTHPART1 : "Attention! You still have",
    MAXLENGTHPART2 : "characters available",
    PH0 : "Max 60 characters",
    PH5 : "---",
    SELEZIONASOTTOCATEGORIA : "Indicate the type of service you are publishing by selecting one of the options that appear by clicking the input field",
    SOTTOCATEGORIA : "Type"
  },
  MODULEIDEACREATE : {
    ALERT1 : "Make sure that you have filled the mandatory fields: Title and Description",
    ALERT1TITLE : "Attention",
    ALERT2 : "The proposal is correctly entered!",
    ALERT3 : "Enter an assumed value to indicate the Estimated Cost of the proposal",
    ALTROMATERIALE : "If you have other useful material to better illustrate your proposal (drawings, insights, tech specs...), upload it here.",
    ANNULLA : "Cancel",
    AVANTI : "Forward",
    BOZZA : "Save as draft",
    CHALLENGE : "Topics",
    COSTOIPOTIZZATO : "Estimated cost",
    CREAIDEA : "Write a proposal",
    DAIBREVETITOLO : "Give your proposal a short and catchy title",
    DESCRIVI : "Summarize your proposal in a few words. You can then describe it in detail in the next section.",
    DESCRIZIONE : "Summary",
    DOCALLEGATI : "Documents and attachments.",
    FILEACCETTATI : "Maximum file size: 10 MB. Supported formats: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Maximum file size: 10 MB. Supported formats: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximum file size: 10 MB. Supported formats: JPG, JPEG, PNG",
    IMMAGINERAPPRESENTATIVA : "Insert an image that represents your proposal",
    IMMAGINI : "Post more pictures to help people better understand your proposal.",
    INDICACOSTO : "Indicate how much you think your proposal will cost",
    ISTRUZIONI : "Instructions",
    LOGO : "Cover image",
    MAPPEEINFO : "Maps and infographics",
    NARRA : "Describe your proposal in detail",
    NARRAZIONE : "The proposal",
    OVERVIEW : "General information",
    PH1 : "Max 600 characters",
    PH2 : "Embedding code (youtube only)",
    PH3 : "Start typing the address and click the best result to place the pinpoint",
    PH4 : "URL",
    PUBBLICA : "Publish",
    SCEGLISFIDE : "Choose which topic(s) your proposal addresses",
    SELEZIONAMAPPA : "Pinpoint where your proposal is located",
    SELEZIONATRA : "Select from the",
    TITOLO : "Title",
    TRASCINA : "Drag the image here or click here to upload it",
    TRASCINADOC : "Drag the files here or click here to upload them",
    TRASCINAIMMAGINI : "Drag the images here or click here to upload them",
    URLSITO : "Website",
    VIDEO : "If there is a video or you made one to explain your proposal, embed it by copying the code here (not the URL) to make it visible to everyone.",
    VIDEOHINT : "The video serves to better illustrate your proposal and engage with more people."
  },
  MODULEIDEAEDIT : {
    AGGIORNATITOLO : "Update title",
    AGGIUNGIALTREIMMAGINI : "Add more pictures",
    ALERT1 : "Make sure that you have filled the mandatory fields: Title and Description",
    ALERT1TITLE : "Attention",
    ALERT2 : "The proposal is correctly entered!",
    ALERT2TITLE : "You are about to publish a new version of the proposal",
    ALERT3 : "Select the language in which you want your proposal to be published. You have to choose at least one language",
    ALERT4 : "Remember that once published you will not be able to change it",
    ALERT5 : "Enter a correct value to indicate the Estimated Cost of the proposal",
    ALTROMATERIALE : "If you have other useful material to better illustrate your proposal (drawings, insights, tech specs...), upload it here.), upload it here.",
    ANNULLA : "Cancel",
    AVANTI : "Forward",
    BOZZA : "Save as draft",
    CANCELLA : "Delete",
    CHALLENGE : "Topics",
    COSTO : "Cost",
    COSTOIPOTIZZATO : "Update estimated cost",
    DESCRIVI : "Update description",
    DESCRIZIONE : "Description",
    DOCALLEGATI : "Documents and attachments.",
    DOCTECNICA : "Technical documentation",
    EDITRESOURCESMODALTITLE : "Edit title",
    ERR1 : "Required field",
    FILEACCETTATI : "Maximum file size: 10 MB. Supported formats: DOC, XLS, PDF, PPT",
    FILEACCETTATIDOC : "Maximum file size: 10 MB. Supported formats: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximum file size: 10 MB. Supported formats: DOC, XLS, PDF, PPT",
    IMMAGINI : "Images",
    ISTRUZIONI : "Instructions",
    LINKVIDEO : "Video links",
    LOGO : "Logo",
    MAPPEEINFO : "Maps and infographics",
    MODIFICA : "Edit",
    MODIFICAIDEA : "Edit proposal",
    MOSTRA : "Show",
    NARRA : "Describe the tactic in detail",
    NARRAZIONE : "The proposal",
    OVERVIEW : "General information",
    PH1 : "Max 600 characters",
    PH2 : "embedding code (youtube only)",
    PH3 : "Start typing the address and click the best result to place the pinpoint",
    PH4 : "URL",
    PUBBLICA : "Publish",
    SCEGLISFIDE : "Choose which topic(s) your proposal addresses",
    SELEZIONAMAPPA : "Pinpoint where your proposal is located",
    SELEZIONATRA : "Select between",
    TITLE : "Title",
    TITOLO : "Title",
    TRASCINA : "Drag the image here or click here to upload it",
    TRASCINADOC : "Drag the files here or click here to upload them",
    TRASCINAIMMAGINI : "Drag the images here or click here to upload them",
    URLSITO : "Website",
    VIDEO : "Update video",
    VIDEOHINT : "Video (Insert embedding code)"
  },
  MODULEIDEAVERSIONING : {
    ABBINACONTESTO : "Match a context",
    AGGIORNATITOLO : "Update title",
    AGGIUNGIALTREIMMAGINI : "Add more images",
    ALERT1 : "Make sure that you have filled the mandatory fields: Title and Description",
    ALERT1TITLE : "Attention",
    ALERT2 : "The proposal is correctly entered!",
    ALERT2TITLE : "You are about to publish a new version of the proposal",
    ALERT3 : "Select the language in which you want your proposal to be published. You have to choose at least one language",
    ALERT4 : "Remember that once published you will not be able to change it",
    ALERT5 : "Enter a correct value to indicate the assumed cost of the proposal",
    ALTROMATERIALE : "If you have other useful material to better illustrate your proposal (drawings, insights, tech specs...), upload it here.), upload it here.",
    ANNULLA : "Cancel",
    AVANTI : "Forward",
    BOZZA : "Save as draft",
    CANCELLA : "Cancel",
    CHALLENGE : "Topics",
    COSTO : "Cost",
    COSTOIPOTIZZATO : "Update assumed cost",
    DESCRIVI : "Update description",
    DESCRIZIONE : "Short description",
    DOCALLEGATI : "Documents and attachments.",
    DOCTECNICA : "Technical documentation",
    EDITRESOURCESMODALTITLE : "Edit title",
    ELENCOIDEE : "All the proposals",
    ERR1 : "Required field",
    FILEACCETTATI : "Maximum file size: 10 MB. Supported formats: JPG, JPEG, PNG",
    FILEACCETTATIDOC : "Maximum file size: 10 MB. Supported formats: DOC, XLS, PDF, PPT",
    FILEACCETTATIIMMAGINI : "Maximum file size: 10 MB. Supported formats: JPG, JPEG, PNG",
    IMMAGINI : "Images",
    ISTRUZIONI : "Instructions",
    LINKVIDEO : "Video link",
    LOGO : "Logo",
    MAPPEEINFO : "Context",
    MODIFICA : "Edit",
    MODIFICAIDEA : "Edit the proposal",
    MOSTRA : "Show",
    NARRA : "Describe in detail your proposal",
    NARRAZIONE : "The proposal",
    NUOVAVERSIONE : "Create new version",
    NUOVAVERSIONEMESSAGGIO1 : "You're creating the",
    NUOVAVERSIONEMESSAGGIO2 : "VERSION",
    NUOVAVERSIONEMESSAGGIO3 : "of your proposal",
    OVERVIEW : "Description",
    PH1 : "Max 600 characters",
    PH2 : "URL (YouTube only)",
    PH3 : "Start typing the address and click the best result to place the pinpoint",
    PH4 : "URL",
    PROCESSO : "Process",
    PUBBLICA : "Publish",
    SCEGLISFIDE : "Choose which topics your proposal wants to address.",
    SELEZIONAMAPPA : "Pinpoint where your proposal is located",
    SELEZIONATRA : "Select from",
    TITLE : "Title",
    TITOLO : "Title",
    TRASCINA : "Drag the image here or click here to upload it",
    TRASCINADOC : "Drop files here or click here to upload",
    TRASCINAIMMAGINI : "Drag the images here or click here to upload them",
    URLSITO : "Website",
    VIDEO : "Update video",
    VIDEOHINT : "Video (insert URL)"
  },
  MODULEINFO : {
    ELENCOPROCESSI : "Process List",
    INFO : "Process Info",
    INPROGRESS : "In progress...",
    PROCESSO : "Process"
  },
  MODULEINPROGRESS : {
    ELENCOPROCESSI : "Process List",
    INFO : "Module under construction",
    INPROGRESS : "In progress...",
    PROCESSO : "Process"
  },
  MODULESERVICECREATE : {
    ALERT1 : "Check that you have entered the mandatory data: Title, Summary, and Type",
    ALERT2 : "The service has been entered correctly!",
    ALERT3 : "Enter the cost of the service",
    ALTROMATERIALE : "If you have other material useful to detail your service (drawings, insights, technical specifications, ...), upload it here",
    CREAIDEA : "Describe your service",
    DAIBREVETITOLO : "Give a short but catchy title to your service",
    DESCRIVI : "Describe your service in a few lines. You can detail it in the next step",
    IMMAGINERAPPRESENTATIVA : "Insert an image representing your service",
    IMMAGINI : "Publish additional images that help better understand your service",
    INDICACOSTO : "Indicate how much your service might cost",
    NARRA : "Describe your service in detail",
    NARRAZIONE : "The service",
    SCEGLISFIDE : "Choose to which service or services yours refers to",
    SELEZIONAMAPPA : "Indicate where your service is located",
    URLSITO : "Website",
    VIDEO : "If there is or you have created a YouTube video that explains your service, make it visible to everyone by copying the URL here",
    VIDEOHINT : "A video helps to better describe a service and reach more people"
  },
  MODULESERVICEEDIT : {
    ALERT2 : "The service has been loaded correctly!",
    ALERT2TITLE : "You are about to publish a new version of the service",
    ALERT3 : "Select the language in which you want to publish your service. You must select at least one",
    ALTROMATERIALE : "If you have other material useful to detail your service (drawings, insights, technical specifications, ...), upload it here",
    MODIFICAIDEA : "Edit the contents",
    NARRA : "Describe your service in detail",
    NARRAZIONE : "The service",
    SCEGLISFIDE : "Choose which theme(s) your service relates to",
    SELEZIONAMAPPA : "Indicate where the service is located",
    URLSITO : "Website"
  },
  MODULESERVICEVERSIONING : {
    ALERT1 : "Make sure you have entered the required data: Title, Summary, and Type",
    ALERT2 : "Your service has been successfully added!",
    ALERT2TITLE : "You are about to publish an updated version of your service",
    ALERT3 : "Select the language or languages in which you want to publish your service. You must select at least one.",
    ALERT5 : "Enter a correct value to indicate the hypothetical cost of the service",
    ALTROMATERIALE : "If you have other useful materials to detail your service (drawings, insights, technical specifications, ...), upload them here",
    ELENCOIDEE : "All services",
    MODIFICAIDEA : "Edit the contents of your service",
    NARRA : "Describe your service in detail",
    NARRAZIONE : "The service",
    NUOVAVERSIONEMESSAGGIO3 : "of your service",
    SCEGLISFIDE : "Choose which or which themes your service wants to address",
    SELEZIONAMAPPA : "Indicate where the service is located",
    URLSITO : "Website"
  },
  MODULESTORYCREATE : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "Story posted successfully!",
    ALERT3 : "Enter a value to indicate the assumed cost of the tactic",
    ALTROMATERIALE : "If you have other material to better tell your story, upload it here.",
    CREAIDEA : "Tell your story",
    DAIBREVETITOLO : "Give your story a short and meaningful title",
    DESCRIVI : "Describe your story in a few lines",
    IMMAGINERAPPRESENTATIVA : "Insert an image that describes your story",
    IMMAGINI : "Post other images that better tell your story.",
    INDICACOSTO : "",
    NARRA : "Tell your story in detail",
    NARRAZIONE : "The story",
    SCEGLISFIDE : "Choose which theme(s) your story is about.",
    SELEZIONAMAPPA : "Indicate where your story took place",
    URLSITO : "Website",
    VIDEO : "If there is or if you made a video where you tell your story on YouTube , share it with everyone by copying the URL here.",
    VIDEOHINT : "A video helps you tell about a story and reach more people."
  },
  MODULESTORYEDIT : {
    ALERT2 : "Story posted successfully!",
    ALERT2TITLE : "You are about to post a new version of the story",
    ALERT3 : "Select in which language you want to publish your story. You must select at least one",
    ALTROMATERIALE : "If you have other material to better tell your story, upload it here.",
    MODIFICAIDEA : "Edit the story",
    NARRA : "Tell your story in detail",
    NARRAZIONE : "The story",
    SCEGLISFIDE : "Choose which theme(s) your story is about.",
    SELEZIONAMAPPA : "Indicate where your story took place",
    URLSITO : "Website"
  },
  MODULESTORYVERSIONING : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "Story posted successfully!",
    ALERT2TITLE : "You are about to post a new version of the story",
    ALERT3 : "Select in which language you want to publish your story. You must select at least one",
    ALERT5 : "",
    ALTROMATERIALE : "If you have additional material to help tell your story, upload it here.",
    ELENCOIDEE : "All the stories",
    MODIFICAIDEA : "Edit the story",
    NARRA : "Tell your story in detail",
    NARRAZIONE : "The story",
    NUOVAVERSIONEMESSAGGIO3 : "of your story",
    SCEGLISFIDE : "Choose which theme(s) your story is about.",
    SELEZIONAMAPPA : "Indicate where your story took place",
    URLSITO : "Website"
  },
  MODULETACTICCREATE : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "The tactic is correctly entered!",
    ALERT3 : "Enter an assumed value to indicate the Estimated Cost of the tactic",
    ALTROMATERIALE : "If you have other useful material to better illustrate the tactic (drawings, insights, tech specs...), upload it here.",
    CREAIDEA : "Write a tactic",
    DAIBREVETITOLO : "Give the tactic a short and catchy title",
    DESCRIVI : "Summarize the tactic in a few words. You can then describe it in detail in the next section.",
    IMMAGINERAPPRESENTATIVA : "Insert an image that represents the tactic",
    IMMAGINI : "Post more pictures to help people better understand the tactic.",
    INDICACOSTO : "Indicates how much the tactic might cost",
    NARRA : "Describe the tactic in detail",
    NARRAZIONE : "The tactic",
    SCEGLISFIDE : "Choose which topics the tactic wants to address.",
    SELEZIONAMAPPA : "Pinpoint where the tactic is located",
    URLSITO : "Website",
    VIDEO : "If there is a video or you made one to explain the tactic embed it by copying the code here (not the URL) to make it visible to everyone.",
    VIDEOHINT : "The video serves to better illustrate the tactic and engage with more people."
  },
  MODULETACTICEDIT : {
    ALERT2 : "The tactic is correctly entered!",
    ALERT2TITLE : "You are about to publish a new version of the tactic",
    ALERT3 : "Select the language in which you want the tactic to be published. You have to choose at least one language",
    ALTROMATERIALE : "If you have other useful material to better illustrate the tactic (drawings, insights, tech specs...), upload it here.), upload it here.",
    MODIFICAIDEA : "Edit tactic",
    NARRA : "Describe the tactic in detail",
    NARRAZIONE : "The tactic",
    SCEGLISFIDE : "Choose which topics the tactic wants to address.",
    SELEZIONAMAPPA : "Pinpoint where the tactic is located",
    URLSITO : "Website"
  },
  MODULETACTICVERSIONING : {
    ALERT1 : "Check that you have entered the mandatory data: Title and Summary",
    ALERT2 : "The tactic is correctly entered!",
    ALERT2TITLE : "You are about to publish a new version of the tactic",
    ALERT3 : "Select the language in which you want the tactic to be published. You have to choose at least one language",
    ALERT5 : "Enter an assumed value to indicate the Estimated Cost of the tactic",
    ALTROMATERIALE : "If you have other useful material to better illustrate the tactic (drawings, insights, tech specs...), upload it here.), upload it here.",
    ELENCOIDEE : "All the tactics",
    MODIFICAIDEA : "Edit the tactic",
    NARRA : "Describe in detail the tactic",
    NARRAZIONE : "The tactic",
    NUOVAVERSIONEMESSAGGIO3 : "of the tactic",
    SCEGLISFIDE : "Choose which topics the tactic wants to address.",
    SELEZIONAMAPPA : "",
    URLSITO : "Website"
  },
  PARTIALSCOMMUNITY : {
    COMMUNITY : "Community",
    ISCRITTI : "{subscribers} users ({profiles} profiles)"
  },
  PARTIALSIDEE : {
    APPROVED : "Approved",
    BUDGET : "Budget: €",
    CREATADA : "Created by",
    RANK01 : "Rank 01",
    VOTATA : "VOTED"
  },
  PARTIALSPROCESSI : {
    ALERT1 : "<h4>You cannot login with your account. Request authenticated access at <a href=\"mailto:how-it-works\">support@bipart.it</a></h4>.",
    ALERT1TITLE : "<b>The group is private</b>",
    ALERT2 : "You'll be able to login when it will start!",
    ALERT2TITLE : "The phase is on.",
    ALERT3 : "<h4>You cannot access with your account. Request authenticated access at <a href=\"mailto:support@bipart.it\">support@bipart.it</a></h4>",
    ALERT3TITLE : "<b>The process is private</b>",
    COMMENTI : "Comments",
    COMMUNITY : "Community",
    CONCLUSA : "Concluded",
    CONCLUSO : "Concluded",
    DAATTIVARE : "To be activated",
    DRIVERAMBIENTE : "Drivers: Environment",
    FASE : "Phase",
    INATTIVAZIONE : "In activation",
    INCORSO : "In progress",
    PARTECIPA : "Join",
    PRIVATO : "Private",
    PUBBLICO : "Public",
    RICHIEDIACCESSO : "Request access",
    VALUTAZIONI : "Evaluations",
    VISUALIZZA : "View",
    VOTA : "Vote"
  },
  PASSWORDRECOVERY : {
    ALERT1 : "<h4>We have sent a verification SMS code to your mobile phone.</h4>",
    ALERT1TITLE : "Info",
    ALERTPHONE1HTML : "The provided number is not registered with the organization.",
    ALERTPHONE1TITLE : "Unknown user",
    CAMBIAPASSWORD : "Change the password",
    CODICEOTP : "OTP code*",
    CONFERMA : "Confirm",
    CONFERMAPASSWORD : "Confirm the password",
    ERROROTPMAXCHAR : "Maximum number of digits enterable 6",
    ERRORPWEQUAL : "Passwords must be the same",
    ERRORPWLENGTH : "The password must be at least 6 characters long.",
    INSERISCICELLULARE : "Enter mobile",
    INSERISCIPASSWORD : "Enter the password",
    METODOCELLULARE : "Mobile",
    METODORECUPERO : "Recovery method",
    OTPDIMENTICATO : "You forgot to enter the OTP code.",
    PHONEERROR1 : "You forgot to enter the prefix.",
    PHONEERROR2 : "Have you by any chance forgotten to enter your mobile number?",
    PHONEERROR3 : "The number you entered is a bit strange... double-check it! :)",
    PROCEDI : "proceed",
    RECUPEROPASSWORD : "Recover your password",
    RECUPERORIUSCITO : "Password change completed successfully.",
    RICHIEDINUOVOCODICE1 : "<b>Didn't receive the code?</b> <br>Go back and make sure you entered your mobile number correctly. Otherwise,",
    RICHIEDINUOVOCODICE2 : "request a new code",
    RICHIEDINUOVOCODICE3 : "or write to us at",
    SCELTAMETODO : "or choose how to recover it",
    SCELTAMETODONECESSARIA : "The choice of the method is mandatory",
    VERIFICA1 : "Verify your phone number",
    VERIFICA2 : "We have sent you an SMS with a verification code OTP. Enter it to access your account and create your new password."
  },
  PHASEMODULECARDIDEA : {
    IDEE : "proposals"
  },
  PHASEMODULECARDSERVICE : {
    IDEE : "services"
  },
  PHASEMODULECARDSTORY : {
    IDEE : "stories"
  },
  PHASEMODULECARDTACTIC : {
    IDEE : "tactics"
  },
  PORTAL : {
    CONTACTUS : "Contact us",
    DELIBERATIVESPACE : "Deliberative space",
    HOME : "Home",
    INSTRUMENTS : "The instruments",
    PROJECT : "The project",
    SUBSCRIBE : "Subscribe"
  },
  PRIVACY : {
    DIRITTO : "6. Right of access and control of personal data, right of objection",
    DIRITTODESCRIZIONE1 : "At any time, you have the right to confirm the existence of personal data concerning you, even if not yet recorded, and their communication in an intelligible form; you can obtain the indication of the origin of your personal data, the purposes and methods of their processing, the logic applied in case of processing carried out with the aid of electronic tools, the identification details of the Data Controller, the processors (if appointed), and the types of subjects to whom your personal data may be communicated, transferred, or who may become aware of it as designated or appointed representatives.",
    DIRITTODESCRIZIONE2 : "You also have the right to access any of your personal data processed by BiPart and may request the modification, correction, copy, restriction, or extension of sharing, anonymization, portability, or deletion (even partial) of your personal data. You also have the right to withdraw your consent to the processing of your data by the Owner at any time. You can exercise the aforementioned rights by sending a written request to the registered office of BiPart Impresa sociale srl (formerly BiPart Srls), located in Milan, via Metauro, 4 by registered mail or email to the following PEC address bipart@casellapec.com. With communication to be sent to the same address, you can also exercise the right to object at any time, for reasons related to your particular situation, to the processing (even only partial) of personal data concerning you, even if relevant to the purpose of collecting and processing personal data for sending advertising or direct sales material or for carrying out market research or commercial communication. From that date onwards, the Owner will refrain from further processing your personal data, saving the rights granted to him by law. For further information on your rights, we invite you to visit the website of the Privacy Guarantor at www.garanteprivacy.it.",
    FINALITA : "3. Purposes of processing your data",
    FINALITADESCRIZIONE1 : "The processing of your data is intended to allow the use of the Platform and the provision of all services connected and/or instrumental to it or all activities related to the development and implementation of participatory budgeting projects and participation in general with the subjects, public and private, involved in such projects managed through the Platform by BiPart; the processing therefore finds its legal basis in the agreements concluded from time to time by the Owner with the subjects (public and private) involved in the participatory budgeting projects managed by BiPart, also through the Platform.",
    FINALITADESCRIZIONE2 : "For the achievement of these purposes, and merely by way of example, your personal data may be used and communicated to third parties for registration processing, for the verification of the correctness of the entered personal data, for the execution and management of the service, for data entry, for the statistical processing of participation in the Platform (use of socio-demographic and geographical data of the registered users). In addition, your personal data will be processed to fulfill legal, accounting, and tax obligations. \nTherefore, please note that the failure to provide personal data for these purposes may imply the impossibility – total or partial – for BiPart to provide the requested service and/or perform the related connected and/or instrumental services.",
    FINALITADESCRIZIONE3 : "Your personal data will be kept by BiPart until your account is open. BiPart, from the deactivation of the account, will delete your personal data, having the right to make them anonymous and keep them only for the creation of a user model (which takes into account only gender, age, and, if available, geographical area) useful for scientific and statistical research related to the dissemination and development of participatory budgeting practices.",
    INTRO : "1. Introduction",
    INTRODESCRIZIONE1 : "BiPart Impresa sociale srl (formerly BiPart Srls), VAT and tax code 094444700968, with registered office in Milan, via Metauro n. 4, as data controller (hereinafter also \"BiPart\" or \"Holder\"), pursuant to the provisions of Legislative Decree 30 June 2003 n. 196 and subsequent amendments (hereinafter \"Privacy Code\") and EU Regulation no. 2016/679 (hereinafter \"GDPR\"), intends to inform you about the methods and purposes of the processing of personal data provided by you.",
    INTRODESCRIZIONE2 : "Registered users (hereinafter \"Members\") have the opportunity to participate in activities made possible by the Platform itself (reports, proposals, priorities, votes, comments, sharing) as well as various participatory budgeting projects, also interacting with each other through the exchange of knowledge, proposals, and ideas, both privately and publicly. The content and data on some of the services offered with the Platform are therefore viewable by both Members and non-registered individuals (the \"Visitors\"). By using the Platform, you give your specific, prior, free, and optional consent to the processing of your data as indicated in this notice.",
    MANIFESTO : "7. Expression of consent",
    MANIFESTODESCRIZIONE1 : "If you agree with the terms of this notice, BiPart asks you to authorize and consent to the processing of your personal data in the ways and for the purposes indicated here, by express approval. As a clear sign of your consent, please check this box.",
    MODALITA : "4. Processing methods and security of your data",
    MODALITADESCRIZIONE1 : "Your personal data will be collected and processed primarily with electronic and computerized tools and stored on both electronic and paper supports and any other suitable support by subjects expressly authorized by the Data Controller, all duly trained in the security of personal data and the right to privacy. According to the regulations indicated by the Privacy Code and the GDPR, this processing will be based on the principles of fairness, lawfulness, transparency, and the protection of your confidentiality and rights.",
    MODALITADESCRIZIONE2 : "To prevent unauthorized access or disclosure and ensure the appropriate use of your personal data, BiPart has implemented reasonable and appropriate administrative, technical, and physical procedures that will be constantly adapted to the regulations in force regarding the protection of personal data. In order to ensure compliance with the standards of the Privacy Code and the GDPR, the processing of your personal data will be carried out exclusively in countries belonging to the European Union.",
    MODALITADESCRIZIONE3 : "BiPart undertakes to adapt its privacy policy to legal and regulatory provisions, therefore, it is possible that this information may undergo changes over time. In the event of significant changes to our privacy policy that significantly alter our privacy practices, BiPart will take care to notify you through other means, such as sending an email or posting a notice on our website and/or social media pages before the changes take effect.",
    SOGGETTI : "5. Entities to whom personal data may be communicated",
    SOGGETTIDESCRIZIONE1 : "Your personal data may be subject to communication or dissemination:",
    SOGGETTIDESCRIZIONE2 : "<li>to Public Administrations, Local Authorities and more generally to subjects, even private, who adhere to or take part in participatory budgeting projects managed by BiPart also through the Platform;</li><li>to authorized subjects who provide administrative, technical/informatics, tax, accounting, financial, and legal assistance and consultancy;</li><li>to third parties in compliance with legal obligations.</li>",
    SOGGETTIDESCRIZIONE3 : "The aforementioned subjects will operate as Data Processors or, in any case, as subjects authorized to process your personal data within the limits authorized by you. To the authorized subjects and Data Processors, if appointed, BiPart will provide appropriate operational instructions to ensure the confidentiality and security of your personal data.",
    SOTTOTITOLO : "Processing of personal data",
    TIPOLOGIA : "2. Type of data collected",
    TIPOLOGIADESCRIZIONE1 : "To create an active account on the platform, you must provide some personal data, including your name, surname, email address, and password (essential data). The Platform allows you to use the profile implementation options by integrating and providing additional information about yourself that may include, for example, but not limited to, indicating the geographical area or specifying the location where you reside, the title of study, or a photo of yourself. Similarly, it is possible that in using the Platform, you may be allowed to share your address book and contacts. It is not necessary for you to publish or enter this personal data; however, please note that it is your choice to expressly choose whether, once entered, this data may be publicly visible to other Members or not.",
    TIPOLOGIADESCRIZIONE2 : "It is therefore exclusively up to you to choose to communicate and enter into the Platform, and in your profile, sensitive information (suitable for revealing racial and ethnic origin, religious, philosophical, or other beliefs, political opinions, membership in parties, unions, associations or organizations of a religious, philosophical, political, or union nature, as well as personal data suitable for revealing health status and sexual life), as well as to make them public. We therefore invite you not to publish data that you do not want to make publicly available, specifying that by entering such data into your account and in the Platform, you authorize its processing.",
    TITOLO : "Privacy Policy"
  },
  PROCESSABOUT : {
    FASE : "Phase",
    TITOLO : "The participatory process"
  },
  PROCESSCONTATTI : {
    CONTATTI : "Contacts"
  },
  PROCESSDETAIL : {
    BUDGETDISPONIBILE : "Available Budget:",
    BUDGETPROPOSTO : "Proposed Budget:",
    CONCLUSA : "Concluded",
    DAATTIVARE : "To be activated",
    FASIPROCESSO : "Process steps",
    INCORSO : "In progress",
    ITUOIVOTI : "Your votes",
    PROGRESS : "Progress"
  },
  PROCESSFAQ : {
    DOMANDERISPOSTE : "Questions & Answers"
  },
  PROCESSGUIDELINES : {
    LINEEGUIDA : "Guidelines"
  },
  PROCESSLIST : {
    BUDGET : "Budget",
    DATAINIZIO : "Start date",
    DATAINS : "Date of entry",
    PARTECIPANTI : "Participants number",
    PROCESSI : "processes",
    TITOLO : "Participatory processes"
  },
  PROCESSLISTCHALLENGES : {
    ARGOMENTI : "All topics to reflect on and find solutions within your community",
    NOTEMATICHE : "There are no topics",
    OBIETTIVI : "Objectives and topics for the organisation"
  },
  PROCESSLISTGROUPS : {
    COMMUNITY : "Community",
    GRUPPI : "groups",
    TUTTI : "All community groups/classes."
  },
  PROCESSLISTSCENARIOS : {
    CONTESTO : "Context of the organization",
    INFO : "Information on the area(s) affected by the process",
    NOSCENARI : "There is no context"
  },
  PROCESSMENU : {
    ABOUT : "The participatory process",
    ASSISTENZA : "Help Desk",
    BACK : "Back",
    COMMUNITY : "Community",
    FAQ : "FAQ",
    ISTRUZIONI : "Guidelines",
    NARRAZIONE : "Further information",
    POP1 : "Process",
    POP1DESCRIPTION : "Process information"
  },
  PROCESSPHASE : {
    ALERTBOZZA : "You have a draft version. You can find it in your",
    BREADCRUMBTITLE : "Phase",
    COMMENTIMENONUMEROSI : "Less commented",
    COMMENTIPIUNUMEROSI : "Più commentate",
    CONTATOREMODULI : "areas",
    IDEE : "proposals",
    INFORMAZIONI : "information",
    NAVIGATUTTELEPROPOSTE : "Browse through all the proposals",
    NONCISONOPROPOSTE : "There are no proposals",
    PS : "Personal space",
    SCEGLIDOVEPARTECIPARE : "Choose where to participate"
  },
  PS : {
    ALERTMODIFICADISABILITATA : "<h4>It is not possible to edit the profile.</h4>.",
    ALERTMODIFICADISABILITATATITLE : "<b>Editing your profile is disabled</b>.",
    ANNUALINCOME : "Annual income",
    ATTEGGIAMENTO : "Attitude",
    BIOGRAPHY : "Biography",
    BUDGET : "Budget",
    BUDGETTODONATE : "Budget for donations",
    COMPORTAMENTO : "Behaviour",
    EDU : "Education",
    EDULAV : "Education and work",
    FEMMINA : "Female",
    LAV : "Work",
    MASCHIO : "Male",
    MODIFICA : "Edit profile",
    NS : "Not specified",
    POLVIS : "Political Vision",
    PROFILE : "Personal profile",
    PS : "Personal space"
  },
  PSACCOUNT : {
    ANONIMO : "Anonymous user",
    AS : "Account settings",
    CITIZENSHIP : "Citizenship",
    COGNOME : "Last name",
    COGNOMENONPRESENTE : "Surname not present",
    ELIMINAACCOUNT : "<h4> Write us at <a href=\"mailto:support@bipart.it\">support@bipart.it</a> and we'll remove your account information.</h4>",
    ELIMINAACCOUNTTITLE : "<b>Do you want to delete your account? </b>.",
    EMAIL : "E-mail",
    EMAILNONPRESENTE : "Email not present",
    NOME : "First name",
    NOMENONPRESENTE : "Name not present",
    PASSWORD : "Password",
    PHONE : "Mobile phone",
    PHONENONPRESENTE : "Mobile not present",
    PRIVATO : "private",
    PS : "Personal space",
    PUBBLICO : "public",
    RESIDENCECITY : "Residence city",
    TAXCODE : "VAT tax code",
    TAXCODENONPRESENTE : "Tax code / VAT number not present",
    TUOPROFILO : "Your profile is",
    USERNAME : "Username",
    USERNAMENONPRESENTE : "Username not present"
  },
  PSACTIVITIES : {
    ALERTDETAIL : "<h4>In this section you will find your proposals and the proposals you voted for.</h4>.",
    ALERTINFO : "<b>Information</b>",
    ATTIVITA : "Activities",
    BUDGETDONATO : "Donated budget",
    FOLLOWERS : "Followers",
    IDEESEGUITE : "Your followed contents",
    IDEEVOTATE : "Your voted contents",
    INBOZZA : "Draft",
    MODIFICAVOTO : "Change your vote",
    NOPROCESSI : "You haven't joined any participatory process yet!",
    NOPROCESSIATTIVI : "No active processes",
    PS : "Personal space",
    PUBBLICATA : "Published",
    TUEPROPOSTE : "Your contributions",
    TUOIPROCESSI : "Your participatory processes",
    TUOIVOTI : "Your votes",
    VISUALIZZA : "View",
    VOTIESPRESSI : "Votes cast"
  },
  PSEDIT : {
    AS : "Account settings",
    CONFERMAPASSWORD : "Confirm password",
    DRAGANDDROP : "Drag and drop to upload your avatar (Max size 5 MB)",
    ERRORE : "Attention!",
    ERROREPASS : "The two password fields don't match",
    FORMSUPPORTATI : "Supported formats are JPG, GIF, PNG",
    MODAVATAR : "Edit avatar",
    MODIFICADATI : "Edit your personal informations in your Personal Space",
    MODOK : "User profile has been successfully edited",
    MODPASS : "Change Password",
    NUOVAPASSWORD : "New password",
    PRIVACY : "Privacy & Policy",
    PROFILOPUBBLICO : "Public Profile",
    PS : "Personal space",
    SALVA : "Save",
    SUCCESSO : "Success"
  },
  PSEDIT2 : {
    AS : "Account Setting",
    ATTEGGIAMENTO : "Attitude",
    BIOGRAFIA : "Biography",
    BUDGET : "Budget",
    BUDGETDADONARE : "Budget for donations",
    COMPORTAMENTO : "Behaviour",
    EDU : "Education",
    EDULAV : "Education and Work",
    INFOVARIE : "Extra info",
    LAV : "Work",
    PS : "Personal space",
    REDDITO : "Annual income",
    SALVA : "Save",
    VISIONEPOLITICA : "Political vision"
  },
  QUICKPANEL : {
    VOTE: "Vote {n}",
    VOTEDON: "Voted on {n}",
    EDITVOTE: "Edit vote",
    DONATION: "Donation"
  },
  QUICKUSER : {
    ACCOUNT : "Account",
    AGGIORNAPROFILOEPRIVACY : "Update your profile and the privacy policy",
    IDEEVOTATE : "All the ideas you have voted",
    PROCESSIPARTECIPATIVI : "Participatory processes",
    SALUTO : "Hi",
    SIGNOUT : "Sign out",
    VOTI : "Your votes"
  },
  REGISTER : {
    ACCETTOLA : "I have read and agreed to the",
    ATTENZIONE : "Attention",
    BACCEDI : "Log in",
    BACCETTO : "I have read and agreed to the",
    BREGISTRA : "Sign up",
    COGNOMEDIMENTICATO : "You forgot to enter your last name",
    GIAACCOUNT : "Already have an account?",
    MAILDIMENTICATA : "You forgot to enter your email",
    MAILNONVALIDA : "Invalid email address",
    NEWSLETTER : "Keep me updated on BiPart's projects and initiatives",
    NOMEDIMENTICATO : "You forgot to enter your name",
    PASSWORDDIMENTICATA : "You forgot to enter your password",
    PLACEHOLDERCOGNOME : "Last name",
    PLACEHOLDEREMAIL : "Email",
    PLACEHOLDERNOME : "Name",
    PLACEHOLDERPASSWORD : "Password",
    PLACEHOLDERUTENTE : "Username",
    PRIVACY : "Privacy Policy",
    TERMINICONDIZIONI : "Terms and conditions of use",
    USERNAMEDIMENTICATO : "You forgot to enter your username",
    USERNAMEFORBIDDENCHARACTERS : "The username cannot contain the following characters: @",
    USERNAMEMINLENGTH : "the username must be at least 3 characters long"
  },
  REGISTERFROMANONYMOUS : {
    ACCETTO : "I have read and agreed to the",
    ACCETTOLA : "I have read and agreed to the",
    BCONFERMA : "Confirm",
    DETTMEX : "We have sent an email* to your inbox.<br><span class=\"font-weight-bolder\">Confirm it</span> to join the organization.</p><p class=\"font-weight-bolder\">* Be careful! Also check your spam folder.",
    ERR1 : "You have not entered the required data. Please try again or write to <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR2 : "Oops... something didn't work; Status code:",
    GIAACCOUNT : "Already have an account?",
    GRAZIE : "Thank you for joining our community",
    ISCRIVITIPROCESSO : "Sign up for the process",
    NEWSLETTER : "Keep me updated on BiPart projects",
    PRIVACY : "Privacy Policy",
    REQUIREDFIELD : "Required field",
    TERMINI : "Terms and conditions",
    TITLE2 : "Attention",
    TITLE3 : "Error",
    VALIDEMAIL : "Must be a valid email address"
  },
  REGISTERONPUBLIC : {
    ACCETTOLA : "I have read and agreed to the",
    BACCEDI : "Log in",
    BACCETTO : "I have read and agreed to the",
    BCHIUDI : "Close",
    BREGISTRA : "SIGN UP",
    COGNOMEDIMENTICATO : "You forgot to enter your last name",
    ERR1 : "Oops... something didn't work; Status code:",
    FAIGIAPARTE : "Are you already registered or do you have the credentials to participate?",
    ISCRIVITIPROCESSO : "Sign up for the organisation and for the process",
    MAILNONVALIDA : "Invalid email address",
    NEWSLETTER : "I want to be kept updated on BiPart projects and active initiatives",
    NOMEDIMENTICATO : "You forgot to enter your name",
    PASSWORDDIMENTICATA : "You forgot to enter your password",
    PRIVACY : "Privacy Policy",
    TERMINICONDIZIONI : "Terms and conditions",
    TITLE1 : "Error",
    USERNAMEDIMENTICATO : "You forgot to enter your username"
  },
  REGISTERPRIVATEPROCESS : {
    ACCESSORIUSCITO : "Successful login",
    ACCETTO : "I have read and agreed to the",
    ACCETTOLA : "I have read and agreed to the",
    AUTORIZZATO : "You are authorized to participate in our community",
    BCONFERMA : "Confirmation",
    DETTMEX : "We have sent an email* to your inbox.<br><span class=\"font-weight-bolder\">Confirm it</span> to join the organization.</p><p class=\"font-weight-bolder\">* Be careful! Also check your spam folder.",
    ERR1 : "You have not entered the required data. Please try again or write to <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a>.",
    ERR3 : "Oops... An unexpected error has occurred.",
    ERR4 : "Invalid email",
    ERR5 : "Registration and acceptance of the \"Privacy Policy\" and of the \"Terms and conditions\"",
    ES : "Ex: Faenza Participation 2020/21",
    GIAACCOUNT : "Already have an account?",
    GRAZIE : "Thank you for joining our community",
    HTMLMAIL : "Here you will find html mail",
    ISCRIVITIPROCESSO : "Sign up for the process",
    NEWSLETTER : "Keep me updated on BiPart projects",
    NIENTEACCOUNT : "Don't have an account yet?",
    NOME : "Name(optional)",
    ORGANIZATION : "BiPart",
    PARTECIPAPROCESSO : "Do you want take part in the process?",
    PRIVACY : "Privacy Policy",
    PROPOSTE : "To make submissions, comment, request ratings, vote you must sign up for the process.",
    QUIMAIL : "Here you will find html email sent",
    REGISTRATI : "Registration and acceptance of the \"Privacy Policy\" and of the \"Terms and conditions\"",
    TERMINI : "Terms and conditions",
    TITLE1 : "Congratulations",
    TITLE2 : "Attention",
    TITLE3 : "Error",
    TITOLO : "Bipart"
  },
  REGISTERPROCESSFROMANONYMOUS : {
    ACCEDI : "Log in",
    ACCETTO : "I have read and agreed to the",
    ACCETTOLA : "I have read and agreed to the",
    BPROSEGUI : "Continue",
    CAMBIAPASS : "Change password",
    ERR3 : "Oops... An unexpected error has occurred.",
    ERR4 : "Invalid mail",
    ERR5 : "Invalid credentials",
    INSERISCIMAIL : "Enter your email",
    ISCRIVITIPROCESSO : "Sign up for the process",
    MEX1 : "Registration and acceptance of the \"Privacy Policy\" and of the \"Terms and conditions\"",
    MEX2 : "Successful authentication",
    MEXLOGIN : "<b>Welcome on BiPart,</b><br>The platform of civic participation.<br>Your data will be treated in full respect of Italian and European regulations and with even more care to protect your opinions and your decisions.<br><b>If you want to continue, read and accept the two documents below.</b>",
    NIENTEACCOUNT : "Don't have an account yet?",
    NONRICORDIPASS : "Can't remember your password?",
    NOTIFICHE : "*If you would like to receive notifications on the progress of submitted proposals.",
    PRIVACY : "Privacy Policy",
    REGISTRATI : "Sign up",
    TERMINI : "Terms and conditions",
    TERMINICONDIZIONI : "To continue please read and agree to the <a href=\"https://bipart.it/terms\" target=\"blank\">Terms and Conditions</a><br> and the <a href=\"https://bipart.it/privacy\" target=\"blank\">Privacy Policy</a>",
    TITLE1 : "Success",
    TITLE2 : "Attention",
    TITLE3 : "Error",
    UTENTEAUTENTICATO : "Authenticated user"
  },
  SERVICEDETAIL : {
    ALERT1 : "You must enter at least one mandatory field among the state or the score assigned to the service",
    ALERT13 : "You have already entered a detailed evaluation for this service",
    ALERT14 : "The service has already been evaluated",
    ALERT3TITLE : "Thank you for voting for this service",
    ALERT4 : "Browse through services and choose your other favorites",
    ALERT5 : "You can continue to donate for other services by choosing how to distribute your available budget.",
    ALERT5TITLE : "Thank you for supporting the service!",
    ALERT6TITLE : "You are following this service!",
    ALERT7TITLE : "You are no longer following this service",
    ALERT8TITLE : "Are you sure you want to delete this service?",
    ALERT9 : "The service has been deleted",
    ALERT9TITLE : "Service deletion",
    ALERTBOZZA : "You have a draft version of your service. Remember to publish it",
    ALERTPUBBLICATA : "Service in the review phase. Collaborate in its development",
    AUTORE : "Contact",
    CANVERSION2 : "You are creating version {n} of your service",
    CLICCAILPULSANTE : "Click the button if you want to rate this service",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the service does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the service because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Thank you for choosing this service!",
    GRAZIESOSTEGNO : "Thank you for supporting this service!",
    GRAZIESOSTEGNOANCORA : "Thank you for continuing to support this service!",
    IDEAVOTATA : "Voted service",
    INSERISCICIFRA : "Enter the amount you want to donate for this service",
    NARRAZIONE : "The service",
    PREFERENZEPERPREMIARE : "preferences to reward the best services!",
    SOSTIENIALTREIDEE : "You can continue to donate for other services, choosing how to distribute your budget.",
    TOGLIEREPREFERENZA : "If you change your mind, you can remove your preference and vote for other services.",
    URLSITO : "Website",
    VALUTARIDUZIONEDONAZIONI : "Evaluate whether to reduce donations already made for other services",
    VERSIONIPROPOSTA : "Service versions:",
    WALLETSOSTIENIIDEA : "Support this service. Make a donation!"
  },
  SERVICELIST : {
    ALERTBOZZA : "You have a draft version of your service. You can retrieve it from your",
    ALERTVERSIONING2 : "You can improve a service by providing or collecting insights and describing it through new, increasingly updated versions",
    CREAIDEA : "Publish your service",
    ELENCOIDEE : "All services",
    IDEAVOTATA : "You have voted for this service!",
    IDEE : "Services",
    NONCISONOIDEE : "There is still no service. Post yours!",
    VOTAZIONEATTIVA2 : "View all services and express your preferences",
    VOTAZIONEATTIVAUTENTEANONIMO : "Sign up, if you are enabled, you can vote for your favorite services"
  },
  STORYDETAIL : {
    ALERT1 : "You must enter at least one of the mandatory field between the status or the score assigned to the story",
    ALERT13 : "You have already entered a detailed evaluation for this story",
    ALERT14 : "The story is already evaluated",
    ALERT3TITLE : "Thanks for voting this story",
    ALERT4 : "Browse through the stories and choose your favorite",
    ALERT5 : "You can keep donating for other stories by choosing how to distribute the budget at your disposal.",
    ALERT5TITLE : "Thanks for supporting this story",
    ALERT6TITLE : "You are following this story!",
    ALERT7TITLE : "You are not following this story anymore",
    ALERT8TITLE : "Are you sure you want to delete this story?",
    ALERT9 : "The story is deleted",
    ALERT9TITLE : "Story’s elimination",
    ALERTBOZZA : "You have a draft version of your story. Remember to publish it",
    ALERTPUBBLICATA : "Story under revision. Help improving it",
    AUTORE : "Author",
    CANVERSION2 : "You are creating version number {n} of your story",
    CLICCAILPULSANTE : "Click on the button if you want to vote for this story.",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the story does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the story because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Thanks for choosing this story!",
    GRAZIESOSTEGNO : "Thanks for supporting this story!",
    GRAZIESOSTEGNOANCORA : "Thanks for your ongoing support to this story!",
    IDEAVOTATA : "Voted story",
    INSERISCICIFRA : "Enter the amount you want to donate for this story.",
    NARRAZIONE : "The story",
    PREFERENZEPERPREMIARE : "vote to reward the best stories!",
    SOSTIENIALTREIDEE : "You can keep donating for other stories by chosing how to distribute you residual budget.",
    TOGLIEREPREFERENZA : "If you changed your mind, you can remove your preference and vote for other stories.",
    URLSITO : "Website",
    VALUTARIDUZIONEDONAZIONI : "Consider reducing the donations already made for other stories",
    VERSIONIPROPOSTA : "Versions of the story:",
    WALLETSOSTIENIIDEA : "Support this story. Make a donation!"
  },
  STORYLIST : {
    ALERTBOZZA : "You have a draft version of your story. You can retrieve it from your",
    ALERTVERSIONING2 : "You can update the stories with new experiences and memories",
    CREAIDEA : "Tell your story",
    ELENCOIDEE : "All the stories",
    IDEAVOTATA : "You voted for this story!",
    IDEE : "Stories",
    NONCISONOIDEE : "No story has been published yet. Tell us yours!",
    VOTAZIONEATTIVA2 : "Look at all the stories to the vote and choose the ones you prefer.",
    VOTAZIONEATTIVAUTENTEANONIMO : "Sign up, once you are enabled you will be able to vote for you favourite stories."
  },
  TACTICDETAIL : {
    ALERT1 : "Fill in at least one of the mandatory field between the status or score assigned to the tactic.",
    ALERT13 : "You have already entered a detailed evaluation for this tactic",
    ALERT14 : "The tactic has already been evaluated",
    ALERT3TITLE : "Thank you for voting for this tactic",
    ALERT4 : "Browse through the tactics and choose the one you want to vote for",
    ALERT5 : "You can continue to donate to other tactics by choosing how to allocate your budget",
    ALERT5TITLE : "Thank you for supporting the tactic!",
    ALERT6TITLE : "You're following this tactic!",
    ALERT7TITLE : "You're no longer following the tactic",
    ALERT8TITLE : "Are you sure you want to delete this tactic?",
    ALERT9 : "The tactic was canceled",
    ALERT9TITLE : "Tactic cancellation",
    ALERTBOZZA : "You have a draft version of the tactic. Remember to publish it",
    ALERTPUBBLICATA : "Tactic under review. Collaborate in its development",
    AUTORE : "",
    CANVERSION2 : "You are creating the {n} version of the tactic",
    CLICCAILPULSANTE : "Click the button if you would like to vote for this tactic.",
    EMAILAUTORENONDISPONIBILE : "It is not possible to send a message because the author of the tactic does not have an email address",
    EMAILUTENTENONDISPONIBILE : "You cannot send messages to the author of the tactic because your account does not have an associated email address. Contact <a href=\"mailto:support@bipart.it\" target=\"blank\">support@bipart.it</a> for more information. Thank you!",
    GRAZIESCELTAIDEA : "Thank you for choosing this tactic!",
    GRAZIESOSTEGNO : "Thank you for supporting the tactic!",
    GRAZIESOSTEGNOANCORA : "Thank you for continuing to support the tactic!",
    IDEAVOTATA : "Voted tactic",
    INSERISCICIFRA : "Please enter the amount you would like to donate to this tactic",
    NARRAZIONE : "The tactic",
    PREFERENZEPERPREMIARE : "preferences to reward the best tactics!",
    SOSTIENIALTREIDEE : "You can continue to donate to other tactics by choosing how to allocate the budget",
    TOGLIEREPREFERENZA : "If you have changed idea you can remove your preference and assign it to other tactics",
    URLSITO : "Website",
    VALUTARIDUZIONEDONAZIONI : "Consider reducing donations already made for other tactics",
    VERSIONIPROPOSTA : "Versions of the tactic:",
    WALLETSOSTIENIIDEA : "Support the tactic. Make a donation!"
  },
  TACTICLIST : {
    ALERTBOZZA : "You have a draft version of the tactic. You can find it in your",
    ALERTVERSIONING2 : "it is possible to improve the tactics by providing or collecting ideas and publishing new and updated versions",
    CREAIDEA : "Write a tactic",
    ELENCOIDEE : "All the tactics",
    IDEAVOTATA : "You voted for this tactic!",
    IDEE : "tactics",
    NONCISONOIDEE : "There are no tactics yet, be the first to publish!",
    VOTAZIONEATTIVA2 : "Browse through the tactics and vote for your favorite",
    VOTAZIONEATTIVAUTENTEANONIMO : "Sign up, if you're eligible to vote, you'll be able to vote your favorite tactics"
  },
  TERMS : {
    ATTIVITA : "3. ACTIVITIES",
    ATTIVITADESCR1 : "Subject of the User's activities is any initiative aimed at contributing to the life, development, and well-being of their city or organization;\nBy way of example, activities may include: inserting ideas and reports, discussion, planning, sharing ideas, links, and documents, voting on ideas and projects, and public themes, etc.\nThe activity carried out must adhere to topics of public relevance and comply with current regulations.\nThe User declares to be the owner of every right possibly connected to their activity (by way of example and not exhaustive: ideas, projects, photographs, audio/video recordings, etc.).\nBiPart reserves the right to unilaterally remove any element introduced by the User from the BiPart Platform, as well as to modify or obscure non-essential elements if it could harm the rights of third parties or a community of people.",
    COMUNICAZIONI : "7. COMMUNICATIONS",
    COMUNICAZIONIDESCR1 : "The user acknowledges and accepts that all eventual communications, notifications, information, and any documentation related to operations carried out and referred to the use of the \"BiPart Platform\" will be sent to the email address or to any additional contact details provided by the user during the registration procedure. BiPart is not responsible for direct, indirect, or consequential damages suffered by the user or third parties as a result of activities within the \"BiPart Platform\" and/or the use of Communication Services and/or damages of any kind or nature connected with said activities. For this purpose, the user declares to indemnify BiPart from any form of liability. The material sent by the user will not be returned. BiPart reserves the right to modify the terms, conditions, and communications under which the \"BiPart Platform\" is offered, and users who are already registered are required to comply with them.",
    LIMITAZIONI : "6. LIMITATIONS OF LIABILITY",
    LIMITAZIONIDESCR1 : "BiPart disclaims any responsibility for direct, indirect, or consequential damages suffered by the user or third parties due to activities within the \"BiPart Platform\" and/or the use of Communication Services and/or damages of any kind or nature connected with said activities. For this purpose, the user declares to indemnify BiPart from any form of liability. The material sent by the user will not be returned.",
    MODIFICA : "8. MODIFICATION OF USAGE TERMS",
    MODIFICADESCR1 : "BiPart reserves the right to modify the terms, conditions, and communications under which the \"BiPart Platform\" is offered, and users who are already registered are required to comply with them.",
    OGGETTO : "1. OBJECT AND DEFINITIONS",
    OGGETTODESCR1 : "These Terms of Use (ToU) regulate and govern the use of the BiPart Platform by the user as well as the user's responsibilities regarding the use of the aforementioned platform. BiPart refers to a platform through which the user can participate in activities made possible by the platform itself (reports, proposals, priorities, votes, comments, sharing, etc.). This platform is the exclusive property of BiPart Impresa sociale srl (formerly BiPart Srls) (BiPart) with headquarters in Milan, Via Metauro, 4 (tax code and VAT number: 09444470968). The term \"User\" refers to the individual registered under Article 2 who interacts with the participatory tools made available on the BiPart Platform. These usage clauses apply to every type of activity carried out by the user.",
    REGISTRAZIONE : "2. REGISTRATION",
    REGISTRAZIONEDESCR1 : "To participate as indicated in the previous article, the user must necessarily register through the appropriate registration procedure. The user assumes all civil and criminal liability for any falsity or incorrectness of the information and data communicated.",
    RESPONSABILITA : "4. USER'S RESPONSIBILITY",
    RESPONSABILITADESCR1 : "Platform usage conditions",
    RESPONSABILITADESCR2 : "<li>covered by copyright and of which it is not itself the owner;</li><li>contrary to morality and public order, or capable of disturbing public or private peace or causing offense, or direct or indirect harm to anyone or to a specific category of people (for example, the insertion of materials or extracts of materials that may offend the sensibility of ethnic or religious groups, etc.);</li><li>contrary to the right to privacy of third parties; • harmful to the honor, decorum, or reputation of third parties; \n</li><li> in any case contrary to the law.</li>",
    SOTTOTITOLO : "Terms and conditions",
    TITOLO : "Terms and conditions",
    UTILIZZO : "USE",
    UTILIZZODESCR1 : "The \"BiPart Platform\" may contain additional communication services such as bulletin boards, chat areas, newsgroups, personal web pages, calendars, and/or other services created to enable the user to communicate with other subjects or with a group (collectively, \"Communication Services\"). The User agrees to use the Communication Services only to send, submit, and receive appropriate messages and material related to the particular Communication Service. For example, and not as a limitation, during the use of a Communication Service, the user agrees to, and undertakes to, not:",
    UTILIZZODESCR2 : "<li>publish, send, distribute, or disseminate names, materials, or information that are inappropriate, profane, defamatory, violate the rights of others, obscene, indecent, or illegal;</li><li>publish files containing software or other material protected by intellectual property laws (or privacy or publicity rights) unless the user owns those rights or has control over them or has received all necessary consents;</li><li>publish files that contain viruses, damaged files, or other similar software or programs that could damage the operation of other computers;</li><li>advertise or offer for sale or buy any product or service for any commercial purpose, unless the Communication Service specifically allows such messages; </li><li>conduct or forward surveys, content, pyramid schemes, or chain letters;</li><li>download any file sent by another user of a Communication Service that the user knows or reasonably should know cannot be legally distributed in that manner;</li><li>falsify or delete any author attribution, legal content communications, or other appropriate communications or designations of ownership or origin or source of software or other material contained in a file that is uploaded;</li><li>restrict or inhibit other users from using and enjoying Communication Services;</li><li>violate codes of conduct or other usage terms that may apply to any particular Communication Service;</li><li>collect or compile information about others, including email addresses, without their consent; </li><li>violate any applicable law or regulation.</li>",
    UTILIZZODESCR3 : "BiPart is not obliged to monitor the Communication Services. However, BiPart reserves the right to view materials sent through any of the Communication Services and to remove any material at its sole and absolute discretion. BiPart reserves the right to suspend the user's access to one, more, or all Communication Services at any time without notice for any reason. Materials posted on any of the Communication Services may be subject to limitations set on their use, reproduction, and/or dissemination. The user is responsible for adhering to such limitations when downloading the materials."
  },
  TRANSLATOR : {
    SELECT : "Select your language"
  },
  USERDETAIL : {
    BIOGRAFIA : "Biography",
    COMMUNITY : "Community",
    EDUCAZIONE : "Education",
    EDULAVORO : "Education and work",
    ETA : "Age",
    F : "Female",
    LAVORO : "Job",
    M : "Male",
    PROPOSTEEFFETTUATE : "My contributions",
    UTENTE : "User"
  }
};
